import React, { Component } from 'react';
import axios from 'axios';
import { API_URL, addToAssetList } from './../../../Actions/index';
import AssetMvt from './Action/AssetMvt';
import history from './../../../history';
import ManageMenu from './../ManageMenu'

import AssetSummary from './AssetSummary'
import { Button } from 'react-bootstrap/lib';
import ScanAsset from './Action/ScanAsset'
import TransferAsset from './Action/TransferAsset'
import DefectiveAsset from './Action/DefectiveAsset'
import ConfirmAsset from './Action/ConfirmAsset'
import RestoreAsset from './Action/RestoreAsset'
import AmendAsset from './Action/AmendAsset'
import AssetMap from './../Google-GeoLocation/AssetMap'

import { connect } from 'react-redux';
import './asset.css';

class ConnectedAsset extends Component {
  constructor(props) {
		super(props)
		this.state = {
      showScan: false,
      showTransfer: false,
      showDelete: false,
      showRestore: false,
      showDefective: false,
      showAmend: false
    };
  }
  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0)
  }

  goBack() {
    window.history.back();
  }

	getAssetDetails = () => {
    axios.get(API_URL + '/assets/byNumber/' + this.props.user.supplier +'/'+ this.props.match.params.assetNumber)
			.then((ret) => {
        this.setState({
          asset: ret.data.asset,
          events: ret.data.events
        })
      })
  }

	getAssetEvents = () => {
    axios.get(API_URL + '/events/assets/'+ this.props.user.supplier +'/'+ this.props.match.params.assetNumber)
			.then((ret) => { this.setState({ events: ret.data }) })
  }

  closeScan() {
    this.setState({ showScan: false });
  }

  closeTransfer() {
    this.setState({ showTransfer: false });
  }

  closeDelete() {
    this.setState({ showDelete: false });
    this.goBack()
  }

  closeDefective() {
    this.getAssetDetails();
    this.getAssetEvents()
    this.setState({ showDefective: false });
  }

  closeAmend() {
    this.setState({ showAmend: false });
  }
  
  closeRestore() {
    this.getAssetDetails();
    this.getAssetEvents()
    this.setState({ showRestore: false });
  }

  loadData() {
    this.getAssetDetails();
  }

  render() {    
    let mappedMvts, allEvents = null;
    if( this.state.events && this.state.events.length > 0 ){
      allEvents = this.state.events
      mappedMvts = this.state.events.sort( (a, b) => {
        return new Date(b.date) - new Date(a.date);
      }).map( (mvt, index) => { 
        return (
          <AssetMvt 
            mvt={ mvt } 
            key={ mvt._id } 
            contractNumber={ this.state.asset.contract? this.state.asset.contract.contractNumber : undefined }
          />
        )
      })
    }
    let user = this.props.user
    let permission = false
    if( user.permissions.transferAssets !== false ) permission = true
    let asset = this.state.asset

    return (
      <div className='background'>
        { asset?
          <div >  
            <div id="pageTitle">Asset <span className="subTitle">{ asset.assetNumber}</span></div>  
            <ManageMenu user={this.props.user}/>
            <div id="clientActionLine">
              <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
            </div>
            <div id="clientBackground">

              <div id="contractSection">
                <AssetSummary asset = { asset }/>
                <div id="assetActionZone">
                  <Button 
                    id="assetAction" 
                    disabled={ asset.status =='PENDING'? false: true} 
                    onClick={()=>this.setState({ showScan: !this.state.showScan })}
                  >
                    Scan
                  </Button>

                  { permission &&
                    <Button 
                      id="assetAction" 
                      disabled={ asset.status == 'INSTOCK' || asset.status == 'DEFECTIVE'? false: true} 
                      onClick={()=>this.setState({ showTransfer: !this.state.showTransfer })}
                    >
                      Transfer
                    </Button> }

                  <Button 
                    id="assetAction" 
                    disabled={ asset.status =='PENDING'? false: true} 
                    onClick={()=>this.setState({ showAmend: !this.state.showAmend })}
                  >
                    Amend
                  </Button>

                  { asset.status !== 'DEFECTIVE' && permission &&
                    <Button 
                      id="assetAction" 
                      disabled={ asset.status =='INSTOCK'? false: true}
                      onClick={()=>this.setState({ showDefective: !this.state.showDefective })}
                    >
                      Faulty
                    </Button> }

                  { asset.status == 'DEFECTIVE' && permission &&
                    <Button 
                      id="assetAction" 
                      disabled={ asset.status =='DEFECTIVE'? false: true}
                      onClick={()=> {
                        this.props.addToAssetList( asset.assetNumber )
                        this.setState({ showRestore: !this.state.showRestore })
                      }}
                    >
                      Restore
                    </Button>  }

                  { asset.status == 'INSTOCK' && ['MainAdmin', 'Core'].indexOf(user.role) > -1 &&
                    <Button 
                      id="assetAction" 
                      onClick={()=> {
                        this.props.addToAssetList( asset.assetNumber )
                        this.setState({ showDelete: !this.state.showDelete })
                      }}
                    >
                      Delete
                    </Button> }

                </div>
                { asset.gps && asset.gps.latitude && !isNaN(parseFloat(asset.gps.latitude)) &&
                  <div id="assetMapZone">
                    <AssetMap events={ allEvents } asset={ asset }/>
                  </div> }
              </div>
              <div id="assetTransfers">
                <div id="clientContractsTitle">History of events</div>
                { mappedMvts }
              </div>
              
              { this.state.showScan &&
                <ScanAsset 
                  show={this.state.showScan} 
                  onHide={this.closeScan.bind(this)}
                  getEvents={this.getAssetEvents}
                  user={ user }
                  asset={ asset }
                /> }

              <TransferAsset 
                show={this.state.showTransfer} 
                onHide={this.closeTransfer.bind(this)}
                refresh={this.getAssetEvents}
                user={ user }
                asset={ asset }
              /> 

              <AmendAsset 
                show={this.state.showAmend} 
                onHide={this.closeAmend.bind(this)}
                getEvents={this.getAssetEvents}
                refresh={this.getAssetDetails}
                asset={ asset }
                user={ user }
              /> 

              { this.state.showDefective &&
                <DefectiveAsset 
                  show={this.state.showDefective} 
                  onHide={this.closeDefective.bind(this)}
                  refresh={this.getAssetDetails}
                  getEvents={this.getAssetEvents}
                  asset={ asset }
                  user={ user }
                /> } 

              { this.state.showRestore &&
                <RestoreAsset 
                  show={this.state.showRestore} 
                  onHide={this.closeRestore.bind(this)}
                  refresh={this.getAssetDetails}
                  getEvents={this.getAssetEvents}
                  asset={ asset }
                  action={ "restore" }
                  supplier={ user.supplier }
                  user={ user }
                /> }  
              
              { this.state.showDelete &&
                <ConfirmAsset 
                  show={this.state.showDelete} 
                  onHide={this.closeDelete.bind(this)}
                  asset={ asset }
                  action={ "delete" }
                  user={ user }
                /> }  

            </div>
          </div>

          : null
        }
      </div>
    );
  }
}

const Asset = connect(
  function mapStateToProps(state) {
    return { 
      selectedAssets: state.selectedAssets
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToAssetList: asset => dispatch(addToAssetList(asset)),
    };
  },
)(ConnectedAsset);


export default Asset