import React, { Component } from 'react';
import './groupPricing.css'

export class Header extends Component {
  
  render() {

    return (
      <div className="groupPricingsTitle">       
        <div id="groupPricingName">Group name</div>
        <div id="groupPricingSize">Min. size</div>
        <div id="groupPricingSize">Max. size</div>
        <div id="groupPricingSize">Solidary</div>
        <div id="groupPricingSize">Target</div>
        <div id="groupPricingProduct">Product</div>
      </div>
    )
  }  
}