import React, { Component } from 'react';
//import {RateHeader} from './RateHeader'
import { RatesListItem } from './RatesListItem'
import { RateHeader } from './RateHeader'
import './rates.css';

class RatesList extends Component {
  
  render() {    
    let mappedRates = null;
    let rates = this.props.rates
    if(rates){
      rates.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      mappedRates = rates.map((rate, index) =>{ 
        return (
          <RatesListItem 
            key={ rate._id }
            rate={ rate } 
          />
        )
      })
    }

    return (
      <div>
        <RateHeader/>
        { mappedRates }
      </div>
    );
  }
}

export default RatesList