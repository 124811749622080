import React, { Component } from 'react';
import { AnswerQuestion } from './AnswerQuestion'
import { connect } from 'react-redux';
import './../credit.css';

class Fill extends Component {

  render() {    
    console.log(this.props.questionnaire.questions)
    let mappedCreditQuestions = this.props.questionnaire.questions
      .sort((a, b) => a.rank - b.rank )
      .map((question, index) =>{ 
        return (
          <div key={index}>
            { this.props.turn >= index && index > 0 &&
            <AnswerQuestion 
                question={ question } 
                client={ this.props.client }
                contract={ this.props.contract } 
                questionnaire={ this.props.questionnaire }
                creditScoreId={ this.props.creditScoreId }
                supplier={ this.props.supplier }
                index={ index }
              />  }
          </div>            
        )
      })
    let show = false
    if( this.props.turn === this.props.questionnaire.questions.length ) show = true
    return (
      <div>
        { mappedCreditQuestions }      
        { show && <div id="qCompleted">Questionnaire completed</div> }
      </div>
    );
  }
}


const FillCreditScore = connect(
  function mapStateToProps(state) {
    return { 
      turn: state.turn
    };
  },
)(Fill);

export default FillCreditScore

