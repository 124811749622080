import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import List from './SituationsList/SituationsList'
import Header from './SituationsList/Header'
import './item.css'

class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
    };
  }

  componentDidUpdate(prevProps) {
    if( 
      this.props.status !== prevProps.status ||
      this.props.count !== prevProps.count ||
      this.props.product !== prevProps.product ||
      this.props.auxiliary !== prevProps.auxiliary ||
      this.props.agent !== prevProps.agent ||
      this.props.quality !== prevProps.quality
    ) this.getStock(this.props.status, this.props.product, this.props.auxiliary, this.props.agent, this.props.quality )
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0);
  }
   
	getStock = (status, product, auxiliary, agent, quality) => {
     axios
      .post(API_URL + '/items/find/' + this.props.user.supplier, {
        status,
        product,
        auxiliary: auxiliary? auxiliary._id:undefined,
        agent,
        quality
      })
      .then((ret) => this.setState({ 
        items: ret.data
      }))
  }

  loadData(){
    this.getStock(this.props.status, undefined, this.props.auxiliary, undefined, this.props.quality )
  }

  render() {    
    let items = this.state.items;
    let { agents, auxiliary, agent, status, quality, breakdown } = this.props

    return (  
      <div>
          
        { items && <List 
          items={items} 
          agents={agents} 
          auxiliary={auxiliary}
          agent={agent} 
          status={status}
          quality={quality}
          breakdown={breakdown}
        /> }

      </div>

    );
  }
}

export default Controller