import React from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class EditScanning extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      product: {
        createByScan: undefined,
        scannableItemLength: undefined,
        alternativeLength: undefined,
        supplier: this.props.supplier
      },       
      status: undefined,
      data: undefined,
      disabled: false
    };
  } 

  componentDidMount() {
    let product = Object.assign( this.state.product, this.props.product )
    this.setState({ product })
  }

  edit() {
    axios.put(API_URL + '/products/edit/'+ this.props.product.supplier+ '/' +this.props.product._id, 
        this.state.product
      ).then((ret) => this.setState({ 
        status: ret.data.message, 
        data: ret.data.data
      }) )      
      .catch(err => {
        this.setState({status: 'Error'})
      }); 
  }
  
  
  over(){
    this.props.onHide();
    this.props.refresh()
    this.setState({
      product: {},       
      status: undefined,
      disabled: false
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }  

  render() {

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalEditTitle">Edit scanning properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
           
            <div id="productSection">
              <div id="productEditLabel" className="longLabel">Length of scannable number (optional)</div>
              <div id="productEditDetail" className="shortDetail">
               <FormControl 
                  type="text" 
                  className="productDetailsInput"
                  value={ this.state.product.scannableItemLength }
                  placeholder={'Type length'}
                  onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.scannableItemLength = e.target.value; this.setState({ product }) }}
                />
              </div>
            </div>   

            { this.state.product.scannableItemLength || this.props.product.scannableItemLength
              ?  <div id="productSection">
                  <div id="productEditLabel" className="longLabel">Alternative length (optional)</div>
                  <div id="productEditDetail"  className="shortDetail">
                    <FormControl 
                        type="text" 
                        className="productDetailsInput"
                        value={ this.state.product.alternativeLength }
                        placeholder={'Type'}
                        onChange={(e)=>{ 
                          let product = Object.assign({}, this.state.product); 
                          product.alternativeLength = e.target.value; 
                          this.setState({ product }) 
                        }}
                      />
                  </div>
                </div>
              : null }

            <div id="productSection">
              <div id="productEditLabel" className="longLabel">Can be created by scan</div>
              <div id="productEditDetail" className="shortDetail">
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="productDetailsInput"
                  onChange={(e)=>{ 
                    let product = Object.assign({}, this.state.product); 
                    product.createByScan = e.target.value; 
                    this.setState({ product }) 
                  }}
                  > 
                  <option value="">
                    { this.state.product.createByScan === true
                      ? 'True'
                      : 'False'
                    }
                  </option>
                  <option value={ true }>True</option>
                  <option value={ false }>False</option>
                </FormControl>
              </div>
            </div>   

            { this.state.product.scannableItemLength > 0 &&
              <div id="productWarning">Warning: only serialNumbers with {this.state.product.scannableItemLength} {this.state.product.alternativeLength? 'or ' + this.state.product.alternativeLength : '' } digits will be recognized by the scanner. Others will be ignored.</div> }

            { this.state.product.createByScan &&
              <div>
                <div id="productWarning">Warning: new units will be created in the system when administrators or managers scan in units that were not previously uploaded on the asset page.</div>
                <div id="productWarningLower">If your product is lockable and requires a secret key to generate activation codes, this will lead to problems.</div>
                <div id="productWarningLower">Scanning by agents will not create new units.</div>
              </div> }

            { this.renderMessage() }

            <div id="modal_action">
              { this.state.status !== 'Success' && <Button id="action_signup" onClick={this.edit.bind(this)}>Edit</Button> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EditScanning;