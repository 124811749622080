import React from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { API_URL, selectContracts, setTitle } from '../../../../Actions/index';
import { connect } from 'react-redux';
import history from '../../../../history';

class Graph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      label: [],
      data: [],
      result: undefined
    };
    this.loadData = this.getGraph()
  };
  
  componentDidUpdate(prevProps) {
    if( 
      this.props.freq !== prevProps.freq 
      || this.props.filter !== prevProps.filter
      || this.props.type !== prevProps.type
    ) {
      this.getGraph();
    }
  }

  getGraph = async () => {
    axios
      .post(API_URL +'/assets/'+ this.props.freq+'/' + this.props.user.supplier, {
        type: this.props.type,
        criteria: this.props.filter? this.props.filter : {},
        userId: this.props.user._id
      })
      .then((res) => {
        this.setState({
          label: res.data.label,
          data: res.data.values,
          result: res.data.data
        })
      })
  }
  handleNext(path){
    history.push(path)
  }

  render() {
    let action = 'deployed'
    if(this.props.type === 'DEFECTIVE') action = 'defective'
    let options = {
      onClick: (e, item) => {
        if( item[0] && item[0]._index){
          this.props.selectContracts( this.state.result[item[0]._index].items )
          let title = `All assets ${action} on ${this.state.label[item[0]._index]}`
          this.props.setTitle( title )
          this.handleNext('/assets-analyze')
        }
      },
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          }
        }],
        yAxes: [{
          gridLines: {
            display:false
          }, 
          ticks: {
            min: 0,
            callback: function(value, index, values) {
              return  value;
            }
          }
        }]
      }
    }

    return (
      <div>
        <Bar
          data={{
            labels: this.state.label, 
            datasets: [
              {
                label: 'Number',
                backgroundColor: this.props.color,
                borderColor: this.props.color,
                borderWidth: 1,
                hoverBackgroundColor: this.props.hoverColor,
                hoverBorderColor: this.props.hoverColor,
                data: this.state.data
              }
            ]
          }}
          width={ this.props.width }
          height={ this.props.height }
          options={ options }
        /> 
      </div>
    );
  }
}

export const AssetAnalysis = connect(
  function mapStateToProps(state) {
    return { 
      listOfContracts: state.listOfContracts,
      title: state.title
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      selectContracts: (list) => dispatch(selectContracts(list)),
      setTitle: (title) => dispatch( setTitle(title) )
    };
  },
)(Graph);
