import React, { Component } from 'react';
import AddNote from './Actions/AddNote'
import NotesList from './NotesList/NotesList'
import { FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';

class NotesController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }
  
  close() {
    this.setState({ show: false });
    this.getNotes()
  }

  componentDidMount() {
    this.getNotes()
  }

	getNotes = ( ) => {
    axios.get(API_URL + '/notes/forcontract/' + this.props.user.supplier + '/' + this.props.contract._id )
			.then( ret => this.setState({ notes: ret.data }))
  }

  render() { 
    let { notes, type, show } = this.state;
    let { contract, user, refresh } = this.props;

    return (
     
      <div id="notesList">
      <div className="noteItem">
        <div onClick={()=>this.setState({ show: !show })}>
          <div id="noteText">Add note or task</div>
          <div id="noteAddOne"><i className="fas fa-plus"></i></div>
        </div>
        <div id="noteFilter">
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="agentEditInput"
            onChange={(e)=>{ 
              this.setState({ type: e.target.value }) 
            }}
          > 
            <option value={undefined}> Filter </option>
            <option value="Task">Task</option>
            <option value="Info">Info</option>
            <option value="Internal">Internal</option>
            <option value="Ticket">Ticket</option>
          </FormControl>
        </div>
      </div>
      
      { notes && notes.length > 0 &&
        <NotesList 
          notes={ notes }            
          refresh={ refresh }
          user={ user }
          filter={ type } 
          contract={ contract }
        /> }

      { show &&
        <AddNote 
          show={ show } 
          onHide={ this.close.bind(this) }
          refresh={ refresh }
          user={ user }
          contract={ contract }
          agent={ contract.agent }
        /> }

    </div>

    );
  }
}

export default NotesController