import React, { Component } from 'react';
import CreditScoreItem from './List/CreditScoreItem';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import './credit.css';
import { AnswerQuestion } from './Action/AnswerQuestion'

class CreditScore extends Component {
  constructor(props) {
		super(props)
		this.state = {};
  }  

  componentDidUpdate(prevProps) {
    if( this.props.creditScoreId !== prevProps.creditScoreId  ) {
      this.getCreditDetails();
    }
  }

  componentDidMount() {
    this.getCreditDetails()
  }

	getCreditDetails = () => {
    axios.get(API_URL + '/creditscores/scoreById/' + this.props.supplier + '/' + this.props.creditScoreId)
			.then((ret) => this.setState({ creditScore: ret.data }) )
  }
  
  render() {    
    let missingQuestions = null;
    let creditScore = this.state.creditScore
    let listOfMissingQuestions = []
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let date = '-'
    if( creditScore ) {
      date = new Date( creditScore.date )
      date = date.toLocaleDateString("en-EN", options)
    }

    let mappedCreditItems =  creditScore && creditScore.creditItems && creditScore.creditItems.map((item, index) =>{ 
      return (
        <CreditScoreItem 
          item={ item } 
          key={ index }
          rank={ index +1 }
          creditScore={ creditScore }
          refresh={ this.getCreditDetails }
          supplier={ this.props.client.supplier }
        />
      )
    })

    if( creditScore && creditScore.questionnaire ){
      let questions = creditScore.questionnaire.questions
      let answeredQuestions = creditScore.creditItems.map( elem => elem.question._id )
      questions.forEach( elem => {
        if( answeredQuestions.indexOf( elem._id ) < 0 ) listOfMissingQuestions.push( elem )
      })
      missingQuestions = listOfMissingQuestions.map((question, index) =>{ 
        return (
          <AnswerQuestion 
            question={ question } 
            key={ index }
            client={ this.props.client }
            supplier={ this.props.client.supplier }
            contract={ creditScore.contract } 
            questionnaire={ creditScore.questionnaire }
            creditScoreId={ creditScore._id }
            index={ index }
          />      
        )
      })
    }
    

    return (
      <div id="creditScoreList">
        <div id="clientContractsTitle" style={{ marginTop: '10px' }}>Details of questionnaire from { date }</div>
        { mappedCreditItems }
        { missingQuestions }
      </div>
    );
  }
}

export default CreditScore