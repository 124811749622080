import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap/lib';
import { findAgents } from  '../../../../services/AgentService'

class EnableAgents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      restrictedTo: this.props.restrictedTo,
      selectedAgents: this.props.agents ,
      agents: undefined,  
    }
  }; 

  componentDidMount() {
    this.setState({ 
      restrictedTo: this.props.restrictedTo, 
      selectAgents: this.props.agents 
    }, () => this.getAgentsList() )
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  submit(){
    this.props.selectAgents( this.state.restrictedTo, this.state.selectedAgents );
    this.over()
  }

  over(){
    this.props.onHide()
    this.setState({
      restrictedTo: [],
      agents: undefined,
    })
  }

  handleList( agent ){
    let list = this.state.restrictedTo
    let agents = this.state.selectedAgents
    if( list.indexOf( agent._id ) < 0 ) {
      this.setState({ 
        restrictedTo: [...this.state.restrictedTo, agent._id], 
        selectedAgents: [...this.state.selectedAgents, agent], 
      })
    }
    else {
      this.setState({ 
        restrictedTo: list.filter( elem => elem !== agent._id ),
        selectedAgents: agents.filter( elem => elem._id !== agent._id ) 
      })
    }
  }
  
  render() {

    let agents  = null;
    let ids = this.state.restrictedTo

    if( this.state.agents ){
      agents = this.state.agents
        .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
        .map( (agent, index) =>{ 
          return ( 
            <div 
              key={ agent._id } 
              className={ ids.indexOf( agent._id ) > -1 ? "managerList selectedAgent" : "managerList" }
              onClick={ () => this.handleList( agent ) 
            }>
              <div id="mListNumber">{ agent.agentNumber }</div>
              <div id="mListFirstName">{ agent.profile.firstName }</div>
              <div id="mListLastName">{ agent.profile.lastName }</div>
              <div id="mListRole">{ agent.role }</div>
              <div id="mListStatus">
                { ids.indexOf( agent._id ) > -1
                  ? <i className="fas fa-check-square"></i>
                  : <i className="far fa-check-square"></i> }
              </div>
            
            </div> 
        )
      })
      
    }

    return (
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="large"
          aria-labelledby="contained-modal-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Agents allowed to sell deal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight" className='shiftedUp'>

              { agents }

            </div>
            <div id="modal_action">
              <Button id="action_signup" className='small' onClick={this.submit.bind(this)}>Edit list</Button>
              <Button id="action_cancel" className='small' onClick={()=>this.over()}>Abort</Button>
            </div>
          </Modal.Body>
        </Modal>
    );
  }
}

export default EnableAgents