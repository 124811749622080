import React from 'react';
import { Doughnut } from 'react-chartjs-2';

var differenceInDays = require("date-fns/difference_in_days");

class GenderShot extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
      },
      data: undefined
    };
  };


  render() {
    let data = this.props.data
    let label = [ 'Male', 'Female' ]
    let dataset = []

    let male = 0
    let female = 0
    
    if( data ){
      male = data
        .filter( elem => ['male', 'Male'].indexOf( elem._id.gender ) > -1 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      female = data
        .filter( elem => ['female', 'Female'].indexOf( elem._id.gender ) > -1 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
    
      dataset = [ male, female ]
    }
      
    return (
      <div>   
        <Doughnut 
          data={{
            labels: label, 
            datasets: [
              {
                label: 'Gender',
                backgroundColor: [
                  '#007FBA',
                  'rgb(255, 192, 203)'
                ],
                borderColor: '#26455A',
                borderWidth: 1,
                hoverBackgroundColor: [
                  '#007FBA',
                  'rgb(255, 192, 203)'
                ],
                hoverBorderColor: [
                  '#007FBA',
                  'rgb(255, 192, 203)'
                ],
                data: dataset
              }
            ]
          }}
          width={ this.props.width }
          height={ this.props.height }
          options={ this.state.options }
        />
      </div>      
    );
  }
}

export default GenderShot