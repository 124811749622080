import { FormControl } from 'react-bootstrap/lib';
import React from 'react';
import { findAgents } from './../../../../services/AgentService'
import { filterByEntity } from  '../../../../services/EntityService'

export class SelectAgent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {};
  } 

  componentDidMount() {
    this.loadData()
  } 

  loadData = async () =>{

    try {
      let { supplierId, userId, role, _id, entity } = this.props
      let query = {
        "$or": [{ role }, { _id }]
      }

      let agents = await findAgents(
        query, 
        userId, 
        supplierId, 
        entity? entity._id : undefined 
      )
      this.setState({ agents })

    } catch(err){
      console.log(err)
    }
    return;
  }


  render() {
    let { agents } = this.state
    let { dropDownFormat, format, title } = this.props

    let mappedAgents = agents && agents
      .filter( elem => elem._id !== this.props._id && elem.role == 'Agent' )
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return <option key={agent._id} value={agent._id} className={dropDownFormat}>{agent.profile.lastName} {agent.profile.firstName}</option>
      })

    let respAgent = agents && agents
      .filter( elem => elem._id == this.props._id )
      .map( agent =>{ 
        return <option key={agent._id} value={agent._id} className={dropDownFormat}>{agent.profile.lastName} {agent.profile.firstName}</option>
      })

    let mappedOperators = agents && agents
      .filter( elem => elem._id !== this.props._id && elem.role == 'Operator' )
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return <option key={agent._id} value={agent._id} className={dropDownFormat}>{agent.profile.lastName} {agent.profile.firstName}</option>
      })

    let mappedCust = agents && agents
      .filter( elem => elem._id !== this.props._id && elem.role == 'CustomerService' )
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return <option key={agent._id} value={agent._id} className={dropDownFormat}>{agent.profile.lastName} {agent.profile.firstName}</option>
      })

    let mappedManagers = agents && agents
      .filter( elem => elem._id !== this.props._id && elem.role == 'Manager' )
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return <option key={agent._id} value={agent._id} className={dropDownFormat}>{agent.profile.lastName} {agent.profile.firstName}</option>
      })

    let mappedAdmin = agents && agents
      .filter( elem => elem._id !== this.props._id && elem.role == 'Administrator' )
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return <option key={agent._id} value={agent._id} className={dropDownFormat}>{agent.profile.lastName} {agent.profile.firstName}</option>
      })



    return (
      <div>
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className={format}
          onChange={(e)=> this.props.selectAgent(e.target.value) }
          >
          <option value="" className={dropDownFormat}>{ title? title : 'Select agent'}</option>
          { respAgent && respAgent.length && <option value="" className="agentOptionHeader">------ Res. agent ------</option> }
          { respAgent }
          { mappedAgents && mappedAgents.length && <option value=""  className="agentOptionHeader">------ Agents ------</option> }
          { mappedAgents }
          { mappedCust && mappedCust.length && <option value=""  className="agentOptionHeader">------ Customer Service ------</option> }
          { mappedCust }
          { mappedOperators && mappedOperators.length && <option value=""  className="agentOptionHeader">------ Operators ------</option> }
          { mappedOperators }
          { mappedManagers && mappedManagers.length && <option value=""  className="agentOptionHeader">------ Managers ------</option> }
          { mappedManagers }
          { mappedAdmin && mappedAdmin.length && <option value=""  className="agentOptionHeader">------ Warehouses ------</option> }
          { mappedAdmin }
        </FormControl> 
      </div>

    );
  }
}

  