import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';

class ReconcilePayments extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false
    };
  } 
 
  componentDidUpdate(prevProps) {
    if (this.props.selectedPayments !== prevProps.selectedPayments) {
      this.setState({status: undefined, disabled: false});
    }
  }

  componentDidMount() {
    this.setState({status: undefined, disabled: false})
  }

  handleChange(){
    var body = {
      payments: this.props.payments,
      note: this.state.note,
      user: this.props.user.userNumber
    }
    this.setState({disabled: true, status: 'One second...'})

    axios.put(API_URL + '/payments/reconcile/' + this.props.user.supplier, body)
			.then((ret) => {
        this.setState({ status: ret.data })
      })
  }

  over(){
    this.props.onHide();
    this.setState({ status: undefined, disabled: false });
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={ ['Success', 'One second...'].indexOf(this.state.status) > -1 ?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  render() {
    let payments = this.props.payments
    let mappedReconciledAgents = null
    let error = false
    let ccyError = null
    let ccy = payments[0].ccy
    let listOfAgents = []
    let total = 0

    payments.forEach( elem => {
      total += elem.amount
      if( elem.ccy != ccy ) error = true
      if( listOfAgents.map(elem => elem._id).indexOf(elem.agent._id) === -1 ) listOfAgents.push(elem.agent);
    })
    if( error ){
      ccyError = <div>Warning: your payments are of multiple currencies</div>
    }

    if( listOfAgents.length > 0 ){
      mappedReconciledAgents = listOfAgents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <div key={ index } value={ agent._id }>
            <div id="paymentRecapSpacer" className="agentHeader alignLeft">Agent {index + 1 }</div>
            <div id="paymentRecapAgentName">{ agent.profile.firstName } { agent.profile.lastName }</div>
            <div id="paymentRecapAgentId">{ agent.agentNumber }</div>
            <div id="paymentRecapAgentOwed">{ agent.unreconciledAmount && agent.unreconciledAmount > 0? agent.unreconciledAmount.toLocaleString(): 0 }</div>
          </div>
        )
      })
    }
    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Confirm payment reconciliation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="paymentRecapWrapper">
          <div id="paymentRecapTitle">Recap</div>
          <div className='long'>
            <div id="paymentRecapLabel">Number of payments</div>
            <div id="paymentRecapData">{ payments.length }</div>
          </div>
          <div className='long'>
            <div id="paymentRecapLabel">Total value (in { ccy })</div>
            <div id="paymentRecapData">{ total.toLocaleString() }</div>
          </div>
          <div id="paymentRecapList">
            <div id="paymentRecapSpacer">Payment(s) sent by</div>
            <div id="paymentRecapAgentName" className="agentHeader">Agent name</div>
            <div id="paymentRecapAgentId" className="agentHeader">Agent number</div>
            <div id="paymentRecapAgentOwed" className="agentHeader">Total un-reconciled</div>
            { mappedReconciledAgents }
          </div>
          <div id="paymentRecapList">
            <div id="paymentRecapLabel" className="paymentHigh">Add note</div>
            <textarea
              type="text" 
              className="paymentRecapNote"     
              placeholder={'Type'}
              value={ this.state.note }
              onChange={(e)=>{ this.setState({ note: e.target.value}) }}
            />
          </div>


        </div>
        { this.renderMessage() }            
        <br/>
        <div id="modal_action">
          { this.state.status == 'Success!' || error
            ? null
            : <Button id="action_signup" disabled={this.state.disabled} onClick={()=>this.handleChange()}>Reconcile</Button>
          }
          { ccyError }
          <Button id="action_cancel" onClick={() => this.over()}>
            { this.state.status == 'Success!'? 'Close':'Abort' }
          </Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default ReconcilePayments;