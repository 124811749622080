import React, { Component } from 'react';

class Header extends Component {
  
  render() {
    
    return (
      <div>
        <div id="itemHeader">{ this.props.auxiliary.name } - {this.props.quality}</div>
        <div className="itemTitle">
          <div id="itemAgent">Agent</div>
          <div id="itemQty">Quantity</div>
        </div>
      </div>

    )
  }  
}

export default Header 