import React from 'react';
import {Bar} from 'react-chartjs-2';

var startOfDay = require("date-fns/start_of_day")

export class Payments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
                display:false
            }
          }],
          yAxes: [{
            gridLines: {
                display:false
            }, 
            ticks: {
              min: 0,
              callback: function(value, index, values) {
                        return  value;
                    }
            }
          }]
        }
      },
      
    };
  };


  render() {

    var options = { year: 'numeric', month: 'short', day: 'numeric' }; 
    let data = [];
    let label = [];
    let payments = this.props.contract.payments

    payments.forEach( payment => {
      let date = startOfDay(new Date( payment.date ))
      date = date.toLocaleDateString("en-EN", options)
      let index = label.indexOf( date )
      if( index < 0){
        data.push( payment.amount );
        label.push( date )
      } else {
        data[ label.indexOf( date ) ] += payment.amount
      }
    })

    return (
      <div className="contractGraph">
        <div className="contractGraphTitle">Payment history ({this.props.contract.ccy})</div>
        <div className="contractGraphWrapper">
          <Bar
            data={{
              labels: label, 
              datasets: [
                {
                  label: '$',
                  backgroundColor: 'white',
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                  borderWidth: 0.5,
                  hoverBackgroundColor: '#008B8B',
                  hoverBorderColor: '#008B8B',
                  data: data
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ this.state.options }
          /> 
        </div>

      </div>
    );
  }
}
