import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL, emptyList } from '../../../../Actions/index';
import { I18n } from 'react-i18next';
import './../../Utils/utils.css';
import './../asset.css'
import { connect } from 'react-redux';
import { findAgents } from  '../../../../services/AgentService'

class AssetAction extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false,
    };
  } 
  
  componentDidMount() {
    this.loadData()
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getAgents();
  }  

  handleChange(){
    var query = {
      assets: this.props.selectedAssets,
      agentId: this.state.agentId,
      userNumber: this.props.user.userNumber
    }
    this.setState({ 
      disabled: true, 
      status: 'One second...' 
    })
    axios.put(API_URL + '/assets/'+this.props.action +'/'+ this.props.supplier, query)
			.then((ret) => {
        this.setState({ status: 'Success' })
      })
      .then(()=>{
        this.props.emptyList()
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status =='Success'?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   

  over(){
    this.props.onHide();
    this.setState({disabled: false, status: undefined});
    this.props.emptyList();
  }

  render() {
    let mappedAgents = null
    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} value={ agent._id }>
            {agent.profile.lastName} {agent.profile.firstName}
          </option>
        )
      })
    }
    
    let isDisabled = this.state.agentId ? false: true
    if(this.state.disabled){ isDisabled=true }

    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Add these {this.props.selectedAssets.length} asset(s) back in stock</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">
              <div id="assetActionSubT">Assign to agent (mandatory)</div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="contractInput"
                onChange={(e)=>{ 
                  this.setState({ agentId: e.target.value });
                  }}
                >
                <option value="">Choose an agent</option>
                {mappedAgents}
              </FormControl>        
            </div>
            {this.renderMessage()}            
            <br/>
            <div id="modal_action">
              { this.state.status==='Success'
                ? null
                : <Button id="confirm_action" disabled={isDisabled} onClick={()=>this.handleChange()}>Confirm</Button>
              }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

const RestoreAsset = connect(
  function mapStateToProps(state) {
    return { 
      selectedAssets:state.selectedAssets
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(AssetAction);

export default RestoreAsset;