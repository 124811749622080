import React, { Component } from 'react';
import './activations.css'

export class ActivationHeader extends Component {
  
  render() {
    return (
      <div className="activationsTitle">
        <div id="activationsMed" >
          Generated on
        </div> 
        <div id="activationsMed" >
          Start on
        </div> 
        <div id="activationsMed" >
          Valid for
        </div> 
        <div id="activationsSmall" >
          Status
        </div> 
        <div id="activationsLarge" >
          Code
        </div>    
        <div id="activationsLarge" >
          Sent to
        </div>                        
      </div>
    )
  }  
}