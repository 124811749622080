import React, { Component } from 'react';
import './../contract.css';
import InfoWrapper from '../../Utils/InfoWrapper'
import DoubleWrapper from '../../Utils/DoubleWrapper';
import history from '../../../../history';

var differenceInDays = require("date-fns/difference_in_days");


class ContractSummary extends Component {
  
  handleNext(path){
    history.push(path)
  }

  render() {    

    let contract = this.props.contract

    var options = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'  };
    let signingDate = '-'
    let lastStatusUpdate = '-'
    let nextStatusUpdate = '-'
    let days = null
    let count = 0
    if(contract.signingDate){
      signingDate = new Date(contract.signingDate)
      signingDate = signingDate.toLocaleDateString("en-EN", options)
    }
    if(contract.lastStatusUpdate){
      lastStatusUpdate = new Date(contract.lastStatusUpdate)
      lastStatusUpdate = lastStatusUpdate.toLocaleDateString("en-EN", options)
    }
    if(contract.nextStatusUpdate){
      nextStatusUpdate = new Date(contract.nextStatusUpdate)
      nextStatusUpdate = nextStatusUpdate.toLocaleDateString("en-EN", options)
      count = differenceInDays( nextStatusUpdate, new Date() )
      if( count >= 0 ) days = 'In ' + count + ' days'
      else if( count < 0 ) days = -count + ' days ago' 
    }

    let status = contract.onboardingStatus==='Signed'? contract.status : contract.onboardingStatus 

    let colorCode = 'egreen'
    if(['LOCKED', 'Approved'].indexOf(status) > -1) colorCode = 'ewarning'
    if(['REPOSSESSED', 'WRITEOFF', 'Rejected'].indexOf(status) > -1) colorCode = 'edanger'
    if(['Pending'].indexOf(status) > -1) colorCode = 'eneutral'
 
    return (
      <div id="contractSummary">
        <div id="contractStatus" className={colorCode}>{status}</div>
        { contract.linkedTo
          ? <div id="contractPct" onClick={()=>this.handleNext('/contract/id=' + contract.linkedTo.contractNumber)}>LINKED</div>
          : contract.type == 'PERPETUITY' 
            ? <div>PERPETUITY</div>
            : <div id="contractPct">{contract.totalCost && contract.totalPaid? (Math.round(contract.totalPaid * 100 / contract.totalCost)):'0'}% paid</div>
        }
        <InfoWrapper 
          label={"Contract number"}
          data={contract.contractNumber}
        />
        <InfoWrapper 
          label={"Total cost"}
          data={contract.type == 'PERPETUITY' ? 'Perpetuity': contract.ccy + ' ' + contract.totalCost.toLocaleString()}
        />

        { contract.type == 'PERPETUITY'
          ? <InfoWrapper 
              label={"Already paid"}
              data={contract.ccy + ' '+ contract.totalPaid.toLocaleString() + ' paid'}
            />
          : <DoubleWrapper 
              label={"Already paid"}
              data1={contract.ccy + ' '+ contract.totalPaid.toLocaleString() + ' paid'}
              data2={contract.ccy + ' ' + contract.remainingDebt.toLocaleString() + ' left'}
            />
        }
       
        { contract.linkedTo
          ? <div id="linkedToBox" onClick={()=>this.handleNext('/contract/id=' + contract.linkedTo.contractNumber)}>
              Linked to contract {contract.linkedTo.contractNumber}
            </div>
          : <div>
              <InfoWrapper 
                label={"Last update"}
                data={lastStatusUpdate}
              />
              <div className="infoWrapper">
                <p id="infoLabel">Next update</p>
                <p id="infoDataTwoThird">{ nextStatusUpdate }</p>
                { ['LOCKED', 'ENABLED'].indexOf(contract.status) > -1 && contract.onboardingStatus == 'Signed'
                  ? <p id="infoDataOneThird" className={ count >= 0? 'EnabledWarning': 'LockedWarning' }>{ days }</p>
                  : null }
              </div>

            </div> }

        { contract.agent &&
          <div className="active" onClick={()=>this.handleNext('/agent/id='+contract.agent.agentNumber)}>
            <InfoWrapper 
              label={"Agent"}
              isActive={ true }
              data={contract.agent.profile.firstName + ' ' +contract.agent.profile.lastName}
            />
          </div>
        }
        
        <InfoWrapper 
          label={"Signing date"}
          data={signingDate}
        />
        <InfoWrapper 
          label={"Deal name"}
          data={contract.deal?contract.deal.dealName: contract.dealName}
        />
        { contract.pricingSchedule &&
          <DoubleWrapper 
            label={"Upfront payment"}
            data1={ contract.pricingSchedule.upfrontPayment? contract.pricingSchedule.upfrontPayment.toLocaleString() : 0 }
            data2={' with ' +contract.pricingSchedule.upfrontDays +' days'}
            />
        }
        { contract.pricingSchedule &&
          <DoubleWrapper 
            label={"Pricing schedule"}
            data1={ contract.pricingSchedule.recurrentPayment? contract.pricingSchedule.recurrentPayment.toLocaleString() : 0 }
            data2={' for ' +contract.pricingSchedule.freq +' days'}
            />
        }
        { contract.pricingSchedule &&
          <InfoWrapper 
            label={"Min payment"}
            data={ contract.pricingSchedule.minPayment? contract.pricingSchedule.minPayment.toLocaleString() : 0 }
          />
        }
      </div>
    );
  }
}

export default ContractSummary