import React, { Component } from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import './../questionnaires.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class Create extends Component {
  constructor(props) {
		super(props)
		this.state = {
      selectedQuestions: [],
      questionnaire: {
        createdBy: this.props.user._id,
      },
      showDetails: false,
    };
  }
  
  submit(){
    axios.post(API_URL + '/questionnaires/create/' + this.props.user.supplier, this.state.questionnaire)
			.then((ret) => {
        this.setState({ status: 'Success' })
      })
  }

  over(){
    this.setState({
      selectedQuestions: [],
      name: undefined,
      showDetails: false,
      status: undefined
    })
    this.props.onHide()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  render() {  

    let entities = this.props.user.entityOptions
    let entity = undefined

    let mappedEntities = entities && entities
      .sort((a, b) => a.name.localeCompare(b.name))  
      .map( entity =>{ 
        return <option key={ entity._id } value={ entity._id }>{entity.name}</option>
      })
    
    if( this.state.questionnaire.entity && entities ) entity = entities.find( elem => elem._id + '' == this.state.questionnaire.entity + '' )

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="medium"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">Create new questionnaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modalBody">

          { entities && entities.length > 1 &&
            <div className="dealCreate">
              <div id="dealSubTitle">Accessibility</div>
              <div id="newDealLabel">Entity</div>
              <div id="newDealHalf" className="dealCreateLeft">
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="dealDetailsInput"
                  onChange={(e)=>{ 
                    let questionnaire = Object.assign({}, this.state.questionnaire); 
                    questionnaire.entity = e.target.value
                    this.setState({ questionnaire }) 
                  }}
                > 
                  <option value="">Choose entity</option>
                  { mappedEntities }
                </FormControl>  
              </div>
              { ! entity 
                ? <div id="newDealHalf" className="dealComment">
                    (leave empty to create a global deal)
                  </div>
                : <div id="newDealHalf" className="dealComment redText">
                    will only be accessible to {entity.name}
                  </div>
              }
            </div> }   
          
          <div className="dealCreate">
            <div id="dealSubTitle">Description</div>
            <div id="newDealLabel">
              Set Name
            </div>
            <div id="newDeal">
              <FormControl 
                type="text" 
                className="dealDetailsInput"
                placeholder='Type'
                onChange={(e)=>{ 
                  let questionnaire = Object.assign({}, this.state.questionnaire); 
                  questionnaire.name = e.target.value; 
                  this.setState({ questionnaire });
                }}
              />
            </div>  
            <div id="newDealLabel">
              Is a survey (*)
            </div>
            <div id="newDeal">
              <div
                className="option"
                id={ !this.state.questionnaire.survey? 'activePaymentOption': '' }
                onClick={(e)=>{ 
                  let questionnaire = Object.assign({}, this.state.questionnaire); 
                  questionnaire.survey = undefined; 
                  this.setState({ questionnaire });
                  }}
                >
                No
              </div>
              <div
                className="option marginleft"
                id={ this.state.questionnaire.survey == true? 'activePaymentOption': '' }
                onClick={(e)=>{ 
                  let questionnaire = Object.assign({}, this.state.questionnaire); 
                  questionnaire.survey = true; 
                  this.setState({ questionnaire });
                  }}
                >
                Yes
              </div>
            </div>

          </div> 
          <div id="newDealExpl">
            (*) Surveys are questionnaires that are not linked to any deal and that can be ran by any existing client at any time
          </div>


          </div>
          
          { this.renderMessage() }            

          <div id="modal_action">
            { this.state.status !== 'Success' &&
              <Button id="action_signup" onClick={()=>this.submit()}>Create</Button> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Create