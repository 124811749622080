import React, { Component } from 'react';
import history from './../../../../history';
import { addToClientList, removeFromClientList } from './../../../../Actions/index';
import { connect } from 'react-redux';
import './clients.css'

export class ClientsListItem extends Component {

  constructor(props) {
		super(props)
		this.state = {
      toApprove: false,
    };
  }


  handleNext(path){
    history.push(path)
  }
  
  handleSelect(){
    this.setState({ toApprove: !this.state.toApprove })
    if(this.props.selectedClients.indexOf(this.props.client._id)<0){
      this.props.addToClientList(this.props.client._id)
    }
    else this.props.removeFromClientList(this.props.client._id)
  }

  render() {
    
    let client = this.props.client

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let entryDate = '-'
    if(client.entryDate){
      entryDate = new Date(client.entryDate)
      entryDate = entryDate.toLocaleDateString("en-EN", options)
    }

    let creditDecisionDate = '-'
    if( client.creditDecisionDate ){
      creditDecisionDate = new Date(client.creditDecisionDate)
      creditDecisionDate = creditDecisionDate.toLocaleDateString("en-EN", options)
    }

    let signingDate = '-'
    if( client.signingDate ){
      signingDate = new Date(client.signingDate)
      signingDate = signingDate.toLocaleDateString("en-EN", options)
    }

    let firstName = ''
    let lastName = ''
    let profile = client? client.profile : undefined
    if( profile && profile.firstName ) {
      firstName = profile.firstName.toLowerCase();
      firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    }
    if( profile && profile.lastName ) {
      lastName = profile.lastName.toLowerCase();
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    }
    

    return (
      <div>
        <div className="clientItem">
          <div className="clientsSmall active" onClick={()=>this.handleNext('client/id='+client.clientNumber)}>
            {client.clientNumber}
          </div>  
          <div className="clientsMedium" >
            { firstName }
          </div>
          <div className="clientsMedium" >
            { lastName }
          </div> 
          <div className="clientsEntity onlyLarge" >
            { client.entity
              ? client.entity.name? client.entity.name : '-'
              : client.country? client.country : '-'
            }
          </div> 
          <div className="clientsExtraSmall onlyLarge" >
            {client.score? client.score:'-'}
          </div>  
          <div className="clientsExtraSmall onlyLarge" >
            {client.status? client.status:'-'}
          </div> 

          { this.props.status == 'Pending'
            ? <div className="clientsSmall onlyLarge" >
                {entryDate}
              </div>  
            : null }

          { this.props.status == 'Approved' || this.props.status == 'Rejected'
            ? <div className="clientsSmall onlyLarge" >
                {creditDecisionDate}
              </div>  
            : null }

          { this.props.status == 'Signed'
            ? <div className="clientsSmall onlyLarge" >
                {signingDate}
              </div>   
            : null }
          
          {client.contracts.length===1?  
            <div className="clientsMedium active" onClick={()=>this.handleNext('contract/id='+client.contracts[0].contractNumber)}>
              {client.contracts[0].contractNumber}
            </div>
            :   
            <div className="clientsMedium" >
              {client.contracts.length>1 ? client.contracts.length + ' contracts':'-'}
            </div>  
          }    

          <div className="clientsMedium active onlyLarge" >
            { client.agent&&client.agent.profile
              ? <div onClick={()=>this.handleNext('agent/id='+client.agent.agentNumber)}>{client.agent.profile.firstName} {client.agent.profile.lastName}</div>
              : null }
          </div>  

          { this.props.status == 'Pending' || this.props.selectable
            ? <div className="clientsTiny active" onClick={()=>this.handleSelect()}>
                { this.props.selectedClients.indexOf(client._id)>-1
                  ? <i className="fas fa-check-square"></i>
                  : <i className="far fa-check-square"></i> }              
              </div>  
            : null }
        </div>   

      </div>
    )
  }  
}
 
export const SelectableClientsListItem = connect(
  function mapStateToProps(state) {
    return { 
      selectedClients:state.selectedClients
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToClientList: client => dispatch(addToClientList(client)),
      removeFromClientList: client => dispatch(removeFromClientList(client))
    };
  },
)(ClientsListItem);

