import React, { Component } from 'react';
import './event.css';
import history from './../../../../history';

class Event extends Component {

  handleNext(path){
    history.push(path)
  }

  render() {    
    let event = this.props.event;
    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let eventDay = new Date(event.date)
    eventDay = eventDay.toLocaleDateString("en-EN", options)

    let colorCode = 'egreen'
    if(['LOCKED', 'MODIFIED', 'TRANSFER', 'PART REPL.'].indexOf(event.type) > -1) colorCode = 'ewarning'
    if(['REPLACED', 'REPOSSESSED', 'WRITEOFF', 'REJECTED', 'REVERSAL', 'CANCELLED', 'RESET'].indexOf(event.type) > -1) colorCode = 'edanger'
    if(['SUBMITTED', 'MESSAGED'].indexOf(event.type) > -1){colorCode = 'eneutral'}

    return (
      <div id="cEvent">
        <div id="cEventHeader" >
          <div id="cEventType" className={colorCode}>{event.type}</div>
          <div id="cEventDate">{eventDay}</div>
        </div>
        <div id="cEventMain">
          <div id="cEventLabel">
            {event.info? event.info: '-'}
          </div>
          {event.agent
            ? <div id="cEventDate" className="active" onClick={()=>this.handleNext('/agent/id='+ event.agent.agentNumber)}>
                Agent {event.agent.agentNumber}
              </div>
            : <div>
                {event.user
                  ? <div id="cEventDate">
                      Admin {event.user.profile? event.user.profile.firstName +' '+ event.user.profile.lastName: '-'}
                    </div>
                  : <div>
                      { event.additionalInfo &&
                        <div id="cEventDate">{ event.additionalInfo }</div>
                      }
                    </div>
                }
              </div>
          }
        </div>
      </div>
    );
  }
}

export default Event