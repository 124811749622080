import React, { Component } from 'react';
import ManageMenu from './ManageMenu'
import './home.css';

class AdminHome extends Component {

  
  render() {

    return ( 
      <div className='background'>
        <div id="pageTitle">Welcome, { this.props.user.profile? this.props.user.profile.firstName : '' }</div>
        <ManageMenu user={this.props.user}/>
        <div id="home_background">
        </div>
      </div>
    );
  }
}

export default AdminHome
