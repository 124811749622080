import React from 'react';
import {Bar} from 'react-chartjs-2';
import axios from 'axios';
import { API_URL, selectContracts, setTitle } from './../../../Actions/index';
import './graph.css'
import { connect } from 'react-redux';
import history from '../../../history';

export class Graph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    label:[],
    data:[],
    result: undefined
    };
    this.loadData = this.getGraph()
  };
 
  handleNext(path){
    history.push(path)
  }

  componentDidUpdate(prevProps) {
    if( 
      this.props.granularity !== prevProps.granularity  
      || this.props.filter !== prevProps.filter
      ) {
      this.setState({ label: undefined, data: undefined, result: undefined }, () => {
        this.getGraph();
      })

    }
  }

  getGraph = () => {
    let body = {
      criteria: this.props.filter,
      userId: this.props.user._id
    }
    axios.post(API_URL + '/payments/' + this.props.granularity + '/' + this.props.user.supplier, body)
      .then((res) => {
        this.setState({
          label: res.data.label,
          data: res.data.values,
          result: res.data.data
        })
      })
  }


  click = async (item) => {
    if( item[0] && item[0]._index){
      let contracts = this.state.result[item[0]._index].items.map( elem => elem )
      let detailedContracts = []
      let title = `Contracts for which payments were received on ${this.state.label[item[0]._index]}`
      let query = {
        _id: { $in: contracts }
      }
      try { 
        let res = await axios.put(API_URL + '/contracts/search/' + this.props.user.supplier, { query })
        detailedContracts = res.data
      } catch(err){ console.log(err) }
      this.props.selectContracts( detailedContracts )
      this.props.setTitle( title )
      this.handleNext('/contracts-analyze')
    }
  }

  render() {
    let options = {
      onClick: (e, item) => this.click(item),
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          },
          ticks: {
            fontColor: 'rgba(255, 255, 255, 0.8)',
            fontSize: 10
          },
        }],
        yAxes: [{
          gridLines: {
            display:false
          }, 
          ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
                return (value / 1000).toLocaleString() + 'k';
            },
            fontColor: 'rgba(255, 255, 255, 0.8)',
            beginAtZero: true,
            fontSize: 10
          },
        }]
      }
    }
    return (
      <div>
        { this.state.data
          ? <Bar
              data={{
                labels: this.state.label, 
                datasets: [
                  {
                    label: '$',
                    backgroundColor: this.props.color,
                    borderColor: 'white',
                    borderWidth: 1,
                    hoverBackgroundColor: this.props.hoverColor,
                    hoverBorderColor: 'white',
                    data: this.state.data
                  }
                ]
              }}
              width={ this.props.width }
              height={ this.props.height }
              options={ options }
            /> 
          : <div className="genericLoader graphLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }
      </div>
    );
  }
}

export const Revenues = connect(
  function mapStateToProps(state) {
    return { 
      listOfContracts: state.listOfContracts,
      title: state.title
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      selectContracts: (list) => dispatch(selectContracts(list)),
      setTitle: (title) => dispatch( setTitle(title) )
    };
  },
)(Graph);
