import React, { Component } from 'react';

class Header extends Component {
  
  render() {
    
    return (
      <div className="itemTitle">
        
        <div id="itemDate">Date</div>
        <div id="itemFrom">From/to</div>
        <div id="itemQty">Quantity</div>
      </div>
    )
  }  
}

export default Header 