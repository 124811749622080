import React from 'react';
import {Line} from 'react-chartjs-2';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';

var label = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
var data = [7, 3.5, 6, 3, 5, 5, 6, 6, 7, 7, 8, 6]

export class CO2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }},
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
                display:false
            }
          }],
          yAxes: [{
            gridLines: {
                display:false
            }, 
            ticks: {
              min: 0,
              callback: function(value, index, values) {
                        return  value;
                    }
            }
          }]
        }
      },
    label:[],
    data:[]
    };
    this.loadData = this.getGraph(this.props.reference)
  };

  
  componentDidUpdate(prevProps) {
    if (this.props.reference !== prevProps.reference|| this.props.d !== prevProps.d) {
      this.getGraph(this.props.reference);
    }
  }

  getGraph = (reference) => {
    axios.post(API_URL +'/contracts/newsigning/daily',{"product":""})
      .then((res) => {
        console.log(res)
        this.setState({
          label: res.data.label,
          data: res.data.values,
        })
      })
  }

  render() {

    return (
      <div>
        <Line
          data={{
            labels: label, 
            datasets: [
              {
                label: 'Hier (Wh)',
                backgroundColor: 'rgba(252,203,57, 0.8)',
                borderColor: 'rgba(252,203,57, 0.9)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(252,203,57, 1)',
                hoverBorderColor: 'rgba(252,203,57, 1)',
                data: data
              }
            ]
          }}
          width={100}
          height={350}
          options={this.state.options}
        /> 
      </div>
    );
  }
}
