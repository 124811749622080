

import axios from 'axios';
import { API_URL } from '../Actions/index';

export function findQuestionnaires( query, userId, supplierId ){
  return new Promise ( function(resolve, reject){
  axios
    .post(API_URL + '/questionnaires/all/' + supplierId, {
      query,
      userId
    })
    .then( ret => resolve(ret.data) )
    .catch(err => reject(err) )
  })
}

