import React, { Component } from 'react';
import {Button, FormControl} from 'react-bootstrap/lib';
import './../contract.css';
import './../../Utils/utils.css'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class ContractEditTerms extends Component {

  constructor(props) {
    super(props);
    this.state= {
      contract: {
        totalCost: undefined,
        pricingSchedule:{
          recurrentPayment: undefined,
          freq: undefined,
          minPayment: undefined
        },
      }, 
      disabled: true, 
      activationCode: undefined,    
      status: undefined,
    }
  }; 

  amendContract() {
    var query = {
      contract: this.state.contract,
      userId: this.props.user._id,
    }
    axios.put(API_URL + '/contracts/amend/'+ this.props.contract.supplier+ '/' + this.props.contract.contractNumber, query)
      .then((ret) => {
          this.setState({ 
            status: ret.data.message,
            activationCode: ret.data
          });
      })
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if( this.state.status ) {
      return (
        <div id={this.state.status == 'Success!'?'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  } 

  render() {    
    let contract = this.props.contract
    let user = this.props.user

    return (
      <div >
        <div>
          <div id="contractEditTitle">Edit contract terms</div>
          <div>
            <div id="contractEditLabel">
              Total cost
            </div>
            <div id="contractLabel">
              <FormControl 
                type="text" 
                className="contractEditInput"
                placeholder={ contract? contract.totalCost:'Total cost'}
                value= { this.state.contract.totalCost != undefined
                  ? this.state.contract.totalCost
                  : contract.totalCost
                }
                onChange={(e)=>{ let contract = Object.assign({}, this.state.contract); contract.totalCost = e.target.value; this.setState({ contract }) }}
              />
            </div>
            <div id="contractLabelShort">
              {contract.ccy} 
            </div>  
            <div style={{width: '100%', float: 'left'}}>
              <div id="contractEditLabel">
                Recurring payment
              </div>
              <div id="contractLabel">
                <FormControl 
                  type="number" 
                  className="contractEditInput"
                  placeholder={ contract && contract.pricingSchedule?contract.pricingSchedule.recurrentPayment:'Recurrent payment'}
                  value= { this.state.contract.pricingSchedule.recurrentPayment != undefined
                    ? this.state.contract.pricingSchedule.recurrentPayment
                    : contract.pricingSchedule.recurrentPayment
                  }
                  onChange={(e)=>{ let contract = Object.assign({}, this.state.contract); contract.pricingSchedule.recurrentPayment = e.target.value; this.setState({ contract }) }}
                />
              </div>
              <div id="contractLabelShort">
                for 
              </div>  
              <div id="contractLabel">
                <FormControl 
                  type="number" 
                  className="contractEditInput"
                  placeholder={contract&&contract.pricingSchedule?contract.pricingSchedule.freq:'x'}
                  value= { this.state.contract.pricingSchedule.freq != undefined
                    ? this.state.contract.pricingSchedule.freq
                    : contract.pricingSchedule.freq
                  }
                  onChange={(e)=>{ let contract = Object.assign({}, this.state.contract); contract.pricingSchedule.freq = e.target.value; this.setState({ contract }) }}
                />
              </div>
              <div id="contractLabelShort">
                days 
              </div>  
              <div id="contractEditLabel">
                Min payment
              </div>
              <div id="contractLabel">
                <FormControl 
                  type="text" 
                  className="contractEditInput"
                  placeholder={contract && contract.pricingSchedule?contract.pricingSchedule.minPayment:'Min payment'}
                  value= { this.state.contract.pricingSchedule.minPayment != undefined
                    ? this.state.contract.pricingSchedule.minPayment
                    : contract.pricingSchedule.minPayment
                  }
                  onChange={(e)=>{ let contract = Object.assign({}, this.state.contract); contract.pricingSchedule.minPayment = e.target.value; this.setState({ contract }) }}
                />
              </div>
              <div id="contractEditWrapper">
                <Button id="updateButton" onClick={()=>this.amendContract()}>Update contract</Button>
              </div>
            </div>
          </div>

        </div>
        {this.renderMessage()} 
      </div>
    );
  }
}

export default ContractEditTerms