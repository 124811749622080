import React, { Component } from 'react';
import './deals.css';
import EditAccessibility from '../Action/EditAccessibility';
import EditDetails from '../Action/EditDetails';
import EditSchedule from '../Action/EditSchedule';
import EditCommission from '../Action/EditCommission';
import EditPricings from '../Action/EditPricings';

import MonthViewer from '../Action/MonthViewer'
import History from '../Action/History'
import SyncApplications from '../Action/SyncApplications'
import { setFilter } from '../../../../Actions/index';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';
import { connect } from 'react-redux'; 
import history from './../../../../history';
import { hasPermission } from './../../../../services/UserService'

export class Item extends Component {
  constructor(props) {
    super(props);
    this.state= {
      history: false,
      show: false,
      agents: [],
      sync: false,
      edit: false,
      details: false,
      pricings: false,
      schedule: false,
      access: false,
      commission: false
    }
  };  

  show = async (showStatus) => {

    let deal = this.props.deal
    if( deal.restricted == true && deal.restrictedTo && deal.restrictedTo.length ){
      try {
        let ret = await axios
          .post(API_URL + '/agents/light-search/' + this.props.user.supplier, {
            query: {
              _id: { $in: deal.restrictedTo }
            }
          })
        this.setState({
          show: !showStatus,
          agents: ret.data
        })
      } catch(err){
        console.log(err)
        this.setState({ show: !showStatus })
      }
    } else this.setState({ show: !showStatus })
  }

  closeHistory() {
    this.setState({ history: false });
  }

  edit() {
    this.setState({ edit: false });
    this.refresh()
  }

  sync() {
    this.setState({ sync: false });
    this.refresh()
  }

  access() {
    this.setState({ access: false });
    this.props.refresh();
  }
  commission() {
    this.setState({ commission: false });
    this.props.refresh();
  }
  details() {
    this.setState({ details: false });
    this.props.refresh();
  }
  schedule() {
    this.setState({ schedule: false });
    this.props.refresh();
  }
  pricings() {
    this.setState({ pricings: false });
    this.props.refresh();
  }

  refresh = () => {
    this.props.refresh();
    this.setState({ show: false });
  }

  handleNext(path){
    let filter = Object.assign({}, {
      deal: this.props.deal._id
    });
    this.props.setFilter( filter )
    history.push(path)
  }

  render() {

    let { deal, user, questionnaires } = this.props;
    let options = user.options
    let schedule = deal.pricingSchedule
    
    let questionnaire = questionnaires && questionnaires.find( elem => elem._id === deal.questionnaire ) 
    
    let qName = ''

    if( deal.noApprovalNeeded ) qName = 'No approval needed'     
    if( questionnaire ) qName = questionnaire.name
    else if( !questionnaire && questionnaires && questionnaires[0] ) qName = questionnaires[0].name
    else qName = '-'

    var dayOptions = {year: 'numeric', month: 'short', day: 'numeric' };
    let date = deal.setUpOn? new Date(deal.setUpOn) : new Date(deal.createdAt)
    date = date.toLocaleDateString("en-EN", dayOptions)

    let mappedAgents = null
    if( deal.restricted == true &&
      this.state.agents &&
      this.state.agents.length > 0
    ) {
      mappedAgents = this.state.agents
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return ( 
          <div 
            key={ agent._id } 
            className="allowed"
          >
            <div id="allowedNumber">{ agent.agentNumber }</div>
            <div id="allowedRole">{ agent.role }</div>
            <div id="allowedName">{ agent.profile.firstName } { agent.profile.lastName }</div>
          </div> 
        )
      })
    }

    let mappedPricings = deal && deal.auxiliaryPricings && deal.auxiliaryPricings
      .filter( elem => !elem.guarantee && elem.auxiliaries[0] && elem._id )
      .sort((a, b) => a.auxiliaries[0].name.localeCompare(b.auxiliaries[0].name))
      .map( elem => { 
        return ( 
          <div 
            key={ elem._id } 
            className="auxPricing"
          >
            <div id="auxPricingRef">{ elem.reference }</div>
            <div id="auxPricingName">{ elem.auxiliaries[0].name }</div>
            <div id="auxPricingCost"><span id="auxPricingLabel">Upfront</span>{ elem.ccy } { elem.cost }</div>
            <div id="auxPricingCost"><span id="auxPricingLabel">Total</span>{ elem.ccy } { elem.totalCost? elem.totalCost: 0 }</div>
          </div> 
        )
      })

    let mappedGuarantees = deal && deal.auxiliaryPricings && deal.auxiliaryPricings
      .filter( elem => elem.guarantee )
      .map( elem => { 
        let parts = <span id="auxPricingGuranteeParts">All</span>
        if( elem.auxiliaries && elem.type == 'Partial' ) parts = elem.auxiliaries.map( aux => {
          return (
            <span id="auxPricingGuranteeParts">{aux.name}</span>
          )
        })
        return ( 
          <div 
            key={ elem._id } 
            className="auxPricingGuarantee"
          >
            <div id="auxPricingGuarantee">{ elem.lifeInMonths } months</div>
            <div id="auxPricingGuranteeLabel">covering parts:</div>
            { parts }
          </div> 
        )
      })

    let permission = hasPermission( user, deal )

    return (
      <div className="dealItem">

        <div id="dealsEntity" >{ 
          deal.entity
          ? deal.entity.name? deal.entity.name:'-' 
          : deal.country? deal.country : 'Global'
        }</div>

        <div id="dealsName" >{ deal.dealName }</div>  
        <div id="dealsSmall" >{ deal.type }</div>

        { deal.type !== 'PERPETUITY'
          ? <div id="dealsSmall" >{deal.ccy} {deal.totalCost.toLocaleString()} </div>
          : <div id="dealsSmall" >Perpetuity</div> }

        <div id="dealsProduct" >{ deal.eligibleProduct? deal.eligibleProduct.name : '-' }</div>      

        <div id="dealsExtraSmall" className="onlyLarge">
          { deal.status == 'Active'
            ? deal.status
            : <i className="far fa-pause-circle"></i> }
        </div>  
              
        <div id="dealsExtraSmall2" className="active"  onClick={()=>this.show(this.state.show)}>
          <i className="fas fa-eye"></i>
        </div>  

        <div id="dealsExtraSmall2" className="active"  onClick={()=>this.handleNext('/analytics')}>
          <i className="fas fa-chart-line"></i>
        </div> 

        { this.state.show &&
          <div className="dealDetails">
            
            <div id="dealSection">
              <div id="productSubTitle">Versioning</div>
              <div id="dealDetailLabel">Deal version</div>
              <div id="dealDetailShort">{ deal.dealVersion? deal.dealVersion : 1 }</div>
              <div id="dealDetailDate"><span style={{color: 'white'}}>Set up on </span> &nbsp;&nbsp; { date } </div>
              {  deal.dealVersion && deal.dealVersion > 1 &&
                <div id="dealDetailLonger">
                  <div 
                    className="dealDetailHistory"
                    onClick={()=>this.setState({ history: !this.state.history })}
                  >
                    <div id="dealDetailHistoryLabel">View history</div>
                    <div id="dealDetailHistoryGraphic"><i className="fas fa-history"></i></div>
                  </div>
                  { permission &&
                    <div 
                      className="dealDetailHistory"
                      onClick={()=>this.setState({ sync: !this.state.sync })}
                    >
                      <div id="dealDetailHistoryLabel">Update applications</div>
                      <div id="dealDetailHistoryGraphic"><i className="fas fa-sync-alt"></i></div>
                    </div>
                  }
                </div>
              }
            </div>
            
            <div id="productSubTitle">Details</div>
            
            { permission &&
              <div id="productSubAction" onClick={()=>this.setState({ details: true })}><i className="fas fa-pen"></i></div>}

            <div id="dealSection">
              <div id="dealDetailLabel">Name</div>
              <div id="dealDetailLonger">{ deal.dealName }</div>    
            </div>
            <div id="dealSection">
              <div id="dealDetailLabel">Product</div>
              <div id="dealDetailLonger">{ deal.eligibleProduct.name }</div>    
            </div>
            <div id="dealSection">
              <div id="dealDetailLabel">Questionnaire</div>
              <div id="dealDetailLonger">{ qName }</div>
            </div>
            <div id="dealSection">
              <div id="dealDetailLabel">Approval needed</div>
              <div id="dealDetailShort">{ deal.noApprovalNeeded? 'False' : 'True' }</div>
            </div>

            <div id="productSubTitle">Pricing schedule</div>

            { permission &&
            <div id="productSubAction" onClick={()=>this.setState({ schedule: true })}><i className="fas fa-pen"></i></div> }

            <div id="dealSection">
              <div id="dealDetailLabel">Total cost</div>
              { deal.type == 'PERPETUITY'
                ? <div id="dealDetailShort">Perpetuity</div>            
                : <div id="dealDetailShort">{ deal.ccy } { deal.totalCost.toLocaleString() }</div>
              }
            </div>

            { schedule && schedule.upfrontPayment &&
              <div id="dealSection">
                <div id="dealDetailLabel">Upfront payment</div>
                <div id="dealDetailShort">{deal.ccy} { schedule.upfrontPayment.toLocaleString() }</div>
                <div id="dealDetailShort"><span style={{color: 'white'}}> for </span>&nbsp;&nbsp; {schedule.upfrontDays} &nbsp;&nbsp;<span style={{color: 'white'}}> days</span></div>
              </div> }
            { schedule && schedule.recurrentPayment &&
              <div id="dealSection">
                <div id="dealDetailLabel">Recurring payment</div>
                <div id="dealDetailShort">{deal.ccy} {schedule && schedule.recurrentPayment? schedule.recurrentPayment.toLocaleString() : 0}</div>
                <div id="dealDetailShort"><span style={{color: 'white'}}> for </span>&nbsp;&nbsp; {schedule.freq} &nbsp;&nbsp;<span style={{color: 'white'}}> days</span></div>
              </div> }
            { schedule && schedule.minPayment &&
              <div id="dealSection">
                <div id="dealDetailLabel">Min payment</div>
                <div id="dealDetailShort">{deal.ccy} {schedule && schedule.minPayment? schedule.minPayment.toLocaleString() : 0}</div>
                <div id="dealDetailShort">{deal.backPay==='On'?'With':'Without'} &nbsp;&nbsp;<span style={{color: 'white'}}> backpay</span></div>
              </div> }

            { options.floatingDeals &&
              <div id="dealSection">
                <div id="dealDetailLabel">Variable exchange rate</div>
                <div id="dealDetailShort">{ deal.floating? 'True':'False' }</div>
                <div id="dealDetailShort"><span style={{color: 'white'}}>Other accepted currency:</span>&nbsp;&nbsp; { deal.secondaryCCY }</div>
              </div> }

            { options.flexibleDeals &&
              <div id="dealSection">
                <div id="dealDetailLabel">Flexible schedule</div>
                <div id="dealDetail">{schedule.isFlexible?'True':'False'}</div>
              </div> }

            { schedule.isFlexible && options.flexibleDeals &&
              <div>
                <div id="dealSection">
                  <div id="dealDetailLabel">High Rate</div>
                  <div id="dealDetail">{deal.ccy} {schedule.flexibleRates.highRate}</div>
                  <div id="dealsMonths">
                    <MonthViewer months={schedule.flexibleMonths.highRateMonths}/>
                  </div>
                </div>
                <div id="dealSection">
                  <div id="dealDetailLabel">Medium Rate</div>
                  <div id="dealDetail">{deal.ccy} {schedule.flexibleRates.mediumRate}</div>
                  <div id="dealsMonths">
                    <MonthViewer months={schedule.flexibleMonths.mediumRateMonths}/>
                  </div>
                </div>
                <div id="dealSection">
                  <div id="dealDetailLabel">Low Rate</div>
                  <div id="dealDetail">{deal.ccy} {schedule.flexibleRates.lowRate}</div>
                  <div id="dealsMonths">
                    <MonthViewer months={schedule.flexibleMonths.lowRateMonths}/>
                  </div>
                </div>              
              </div> }

            <div id="dealSection">
              <div id="productSubTitle">Cost of auxiliaries and guarantees</div>
              
              { permission &&
                <div id="productSubAction" onClick={()=>this.setState({ pricings: true })}><i className="fas fa-pen" ></i></div>}
                
              <div id="dealSection">
                <div id="dealDetailLabelLong">Guarantees</div>
                <div id="dealSection">
                { mappedGuarantees && mappedGuarantees.length > 0 ? mappedGuarantees : <div id="dealDetailShort">None</div> }
              </div>
              </div>
              <div id="dealDetailLabelLong">Deal specific costs of auxiliaries</div>
              <div id="dealSection">
                { mappedPricings && mappedPricings.length > 0 ? mappedPricings : <div id="dealDetailShort">None</div> }
              </div>
   
            </div>

            { options.manageAgents &&
              <div id="dealSection">
                <div id="productSubTitle">Commissions</div>
                
                { permission &&
                  <div id="productSubAction" onClick={()=>this.setState({ commission: true })}><i className="fas fa-pen" ></i></div>}
                
                <div id="dealDetailLabel">Agent commission</div>
                { deal.commission &&
                  <div>
                    <div id="dealDetailShort">{ options.ccys[0] } { deal.commission.toLocaleString() }</div>
                    <div id="dealDetailShort" style={{color: 'white'}}>per new sale</div>
                  </div> }
                { deal.pctCommission &&
                  <div>
                    <div id="dealDetailShort">{ options.ccys[0] } { deal.pctCommission *100 }%</div>
                    <div id="dealDetailShort" style={{color: 'white'}}>of generated revenues</div>
                  </div> }
                { !deal.commission && !deal.pctCommission &&
                  <div id="dealDetailShort">Not specified</div> }
              </div> }

            { options.restrictedDeals &&
              <div id="dealSection">
                <div id="productSubTitle">Accessibility</div>
                
                { permission &&
                  <div id="productSubAction" onClick={()=>this.setState({ access: true })}><i className="fas fa-pen" ></i></div>}
                
                <div id="dealDetailLabel">Restricted deal</div>
                { ( !deal.restricted || deal.restricted == false ) &&
                  <div>
                    <div id="dealDetailShort">False</div>
                    <div id="dealDetailLonger" style={{color: 'white'}}>Accessible to every agent</div>
                  </div> }
                { deal.restricted == true &&  <div id="dealDetailShort">True</div> }
              </div> }

              { deal.restricted == true && <div id="dealDetailLabelLong">Only accessible to below agents:</div> }
              <div id="dealSection">
                { mappedAgents ? mappedAgents : <div id="dealDetailShort">{ deal.restricted? 'None' : ''}</div> }
              </div>

          </div> }

        { this.state.access &&
          <EditAccessibility 
            show={ this.state.access } 
            onHide={ this.access.bind(this) }
            agents={ this.state.agents }
            user={ user }
            refresh={ this.refresh }
            deal={ deal }
          /> }

        { this.state.history &&
          <History 
            show={ this.state.history } 
            supplier={ user.supplier }
            deal={ deal }
            onHide={ this.closeHistory.bind(this) }
          /> }

        { this.state.sync &&
          <SyncApplications 
            show={ this.state.sync } 
            user={ user }
            deal={ deal }
            onHide={ this.sync.bind(this) }
          /> }

        { this.state.details &&
          <EditDetails 
            show={ this.state.details } 
            onHide={ this.details.bind(this) }
            user={ user }
            questionnaires={ questionnaires }
            deal={ deal }
          /> }

        { this.state.schedule &&
          <EditSchedule 
            show={ this.state.schedule } 
            onHide={ this.schedule.bind(this) }
            user={ user }
            questionnaires={ questionnaires }
            deal={ deal }
          /> }

        { this.state.pricings &&
          <EditPricings 
            show={ this.state.pricings } 
            onHide={ this.pricings.bind(this) }
            refresh={ this.props.refresh }
            user={ user }
            deal={ deal }
          /> }

        { this.state.commission &&
          <EditCommission 
            show={ this.state.commission } 
            onHide={ this.commission.bind(this) }
            user={ user }
            questionnaires={ questionnaires }
            deal={ deal }
          /> }


      </div>
    )
  }  
}

export const DealsListItem = connect(
  function mapStateToProps(state) {
    return { 
      filter: state.filter
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      setFilter: filter => dispatch(setFilter(filter)),
    };
  },
)(Item);