import React, { Component } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap/lib';
import LastItem from './Elems/LastItem'
import ItemLine from './Elems/ItemLine'
import './../agentInvoice.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
var DatePicker = require("react-bootstrap-date-picker");

class AgentInvoicesCalc extends Component {

  constructor(props) {
    super(props);
    this.state= {
      item: {
        dateFrom: undefined,
        dateTo: undefined,
        paid: undefined,
        freq: undefined,
        agent: this.props.agent._id,
        createdBy: this.props.user._id,
        comment: '',
        amount: 0,
        ccy: this.props.agent.ccy,
      },       
      targets: undefined,
      included: false,
      status: undefined,
      isDisabled: false,
    }
  }; 

  componentDidMount() {
    this.loadData();
  }

  submit() {
    let supplier = this.props.agent.supplier
    let item = Object.assign( this.state.item, {
      agent: this.props.agent._id,
      ccy: this.props.agent.ccy,
      agentInvoiceNumber: Date.now(),
      createdBy: this.props.userNumber,
      editedBy: this.props.userNumber,
    } )
    axios.post(API_URL + '/agents/createInvoice/' + supplier, item)
      .then((ret) => this.setState({ status: 'Success!'}) )
      .catch(err => {
        console.error(err);
        this.setState({status: err.message})
      }); 
  }

	getAgentTargetsList = () => {
    axios.post(API_URL + '/agents/getTargets/'+ this.props.agent.supplier, {
      agent: this.props.agent._id
    }).then((ret) => this.setState({ targets: ret.data }) )
  }
  
  loadData(){
    this.getAgentTargetsList()
  }

  selectFreq = (ref) => {
    let item = Object.assign({}, this.state.item); 
    item.freq = ref; 
    item.amount = 0;
    item.comment = '';
    this.setState({ item }) 
    this.setState({ included: !this.state.included })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success!'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  }  

  include = (payoff, text) => {
    let item = Object.assign({}, this.state.item); 
    item.amount += payoff; 
    item.comment =  this.state.item.comment + ' ****' + text
    this.setState({ item })
  }
  remove = (payoff, text) => {
    let item = Object.assign({}, this.state.item); 
    item.amount = this.state.item.amount - payoff; 
    let ht = ' ****'+text
    item.comment = this.state.item.comment.replace(ht,'');
    this.setState({ item })

  }

  selectPaid = (bool) => {
    let item = Object.assign({}, this.state.item); 
    item.paid = bool; 
    this.setState({ item })
  }
  
  over(){
    this.props.onHide()
    this.setState({
      item: {
        dateFrom: undefined,
        freq: undefined,
        dateTo: undefined,
        paid: undefined,
        agent: undefined,
        createdBy: undefined,
        amount: undefined,
        comment: undefined,
        agent: this.props.agent._id,
        createdBy: this.props.userNumber,
        ccy: this.props.agent.ccy
      },   
      targets: undefined,    
      status: undefined,
      isDisabled: false,
    })
  }

  render() {
    
    let isDisabled = true
    let mappedItems, lastItem = null
    let item = this.state.item
    let targets = []    

    if( item.freq && item.amount > 0 && item.dateFrom && item.dateTo && item.comment ) isDisabled = false
    if(this.state.disabled) isDisabled = true
    let navigate = <Button id="action_signup" disabled={ isDisabled } onClick={this.submit.bind(this)}>Create</Button>
    if( this.props.edit ) navigate = <Button id="action_signup" onClick={this.submit.bind(this)}>Edit</Button>

    if( this.state.targets && item.freq ){
      this.state.targets.forEach( elem => {
        if( elem.freq == item.freq && elem.active == true && elem.remunerated == true ) targets.push(elem)
      })
      mappedItems = targets.map( ( target, index ) => {
        return (
          <ItemLine 
            key={ index }
            target={ target }
            contracts={ this.props.contracts }
            teamContracts= { this.props.teamContracts }     
            include={ this.include } 
            remove={ this.remove }
            dateFrom={ item.dateFrom }
            dateTo={ item.dateTo }      
            included={ this.state.included } 
          />
        )
      })
    }

    if( item.freq ){
      let filteredInvoices = this.props.agentInvoices.filter( elem => elem.freq == item.freq ).sort(function(a, b) {
        return new Date(b.dateTo) - new Date(a.dateTo)
      })
      if( filteredInvoices[0] ) lastItem = <LastItem item={ filteredInvoices[0] }/>
      else lastItem = <div id="noPrevItem">No previous payroll item</div>
    }


    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">
            <div>Calculate latest payroll item </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">

            <div className="agentTargetInputLabel">Choose period</div>
            <div 
              id="agentTargetOption" 
              className={ item.freq == 'day'?'activeTOption': ''}
              onClick={()=>this.selectFreq('day')}
            >
              Daily item
            </div>
            <div 
              id="agentTargetOption" 
              className={ item.freq == 'week'?'activeTOption': ''}
              onClick={()=>this.selectFreq('week')}
            >
              Weekly item
            </div>
            <div 
              id="agentTargetOption" 
              className={ item.freq == 'month'?'activeTOption': ''}
              onClick={()=>this.selectFreq('month')}
            >
              Monthly item
            </div>

            <div className="agentInvoiceInputLabel">Exact days</div>
            <div className="dateInvoice"  >
              <DatePicker 
                className ="agentInvoiceDateInput"
                placeholder="From"
                value={ this.state.item.dateFrom }  
                onChange={(e)=>{ 
                  let item = Object.assign({}, this.state.item); 
                  item.dateFrom = e; 
                  item.amount = 0;
                  item.comment = '';
                  this.setState({ item }) 
                  this.setState({ included: !this.state.included })
                }}
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />          
            </div>  
            <div className="dateInvoice"  >
              <DatePicker 
                className ="agentInvoiceDateInput"
                placeholder="To"
                value={ this.state.item.dateTo }  
                onChange={(e)=>{ 
                  let item = Object.assign({}, this.state.item); 
                  item.dateTo = e; 
                  item.amount = 0;
                  item.comment = '';
                  this.setState({ item }) 
                  this.setState({ included: !this.state.included })
                }}
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />          
            </div>   

            { item.freq
              ? <div>
                  <div className='fullLine'>
                    <div className="agentTargetInputLabel">Last item</div>
                    { lastItem }
                  </div>
                  <div className='fullLine'>
                    <div className="agentTargetInputLabel">{targets? targets.length: 0} related targets</div>
                    <div id="mappedItems">{ mappedItems }</div>
                  </div>
                  <div className='fullLine'>
                    <div className="agentTargetInputLabel">Total</div>
                  </div>
                </div>
              : null }


            <div className="agentInvoiceInputLabel">Total</div>
            <div  className="itemGrandTotal">{ item.ccy } { item.amount }</div>

            <div className="agentTargetInputLabel">Paid</div>
            <div 
              id="agentTargetOption" 
              className={ this.state.item.paid == true?'activeTOption halfInvoice': 'halfInvoice'}
              onClick={()=>this.selectPaid(true)}
            >
              True
            </div>
            <div 
              id="agentTargetOption" 
              className={ this.state.item.paid == false?'activeTOption halfInvoice': 'halfInvoice'}
              onClick={()=>this.selectPaid(false)}
            >
              False
            </div>

            <div className="agentInvoiceInputLabel">Comment</div>
            <textarea 
              type="number" 
              className="agentInvoiceInputLong"     
              placeholder={'Type'}
              value={ this.state.item.comment }
              onChange={(e)=>{ let item = Object.assign({}, this.state.item); item.comment = e.target.value; this.setState({ item }) }}
            />


          </div>


          { this.renderMessage() }            
          <br/>
          <div id="modal_action">
            { this.state.status==='Success!'
              ? null
              : <div>{ navigate }</div> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AgentInvoicesCalc