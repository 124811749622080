import React, { Component } from 'react';
import history from './../../../../history';
import './../List/assets.css'
var differenceInCalendarDays = require('date-fns/difference_in_calendar_days')


export class AssetsListItem extends Component {
  
  handleNext(path){
    history.push(path)
  }

  render() {
    let asset = this.props.asset
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let heldSince = new Date( asset.heldSince )
    heldSince = heldSince.toLocaleDateString("en-EN", options)

    return (
      <div className="assetItem long">  
        <div id="assetsSmallLight" >
          { asset.productDetails? asset.productDetails.name: '-' }
        </div>  
        <div id="assetsSmallLight"  className="active" onClick={()=>this.handleNext('/asset/id=' + asset.assetNumber)}>
          { asset.paygNumber }
        </div>  
        <div id="assetsSmallLight" >
          { asset.status }
        </div>
        <div id="assetsSmallLight" >
          { asset.serialNumber }
        </div>      
        <div id="assetsSmallLight" >
          { heldSince }
        </div>  
        <div id="assetsExtraSmallLight">
          { differenceInCalendarDays(new Date(), heldSince) } days
        </div>          
      </div>
    )
  }  
}