import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';
import SelectAudience from './SelectAudience'
import './sender.css';

class SenderController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      create: false,
      chooseTemplate: true,
      chooseAudiance: false,
    };
  }

  componentDidMount() {
    this.loadData()
  }

	getTemplatesList = () => {
    axios.post(API_URL + '/templates/search/' + this.props.user.supplier, {
      query: {
        type: 'Group'
      },
      user: this.props.user._id
    })
			.then((ret) =>  this.setState({ templates: ret.data }) )
  }
  
  loadData() {
    this.getTemplatesList();
  }

  render() {    
    let navigate = null;
    if(this.state.chooseTemplate && this.state.templates && !this.state.chooseAudiance ){
      navigate = this.state.templates.filter( elem => ['Core', 'Automated'].indexOf(elem.type) < 0 ).map((template, index) =>{ 
        return (
          <div key={index} id="template">
            <div id="templateCode">{template.code}</div>
            <div id="templateText">{template.text}</div>
            <div id="templateSelect" className='active' onClick={()=>this.setState({template: template, chooseAudiance: true, chooseTemplate: false})}><i className="far fa-check-square"></i></div>
          </div>            
        )
      })
    }

    return (
      <div id="senderZone">
        <div id="senderWrapper">
          <div 
            id="senderTitle" 
            className={this.state.chooseTemplate?'activeStep':''} 
            onClick={()=>this.setState({chooseTemplate: !this.state.chooseTemplate, chooseAudiance: false, template: undefined })}
          >
            <i className="fas fa-envelope-open-text"></i> &nbsp; &nbsp; Select template
          </div>
        </div>

        { this.state.template && 
          <div>
            <div id="selectedMessageText">{this.state.template.text}</div>
            <div id="senderWrapper">
              <div id="senderTitle" className={this.state.chooseAudiance?'activeStep':''}>
                <i className="fas fa-users"></i>&nbsp; &nbsp; Select audience  
              </div>
            </div>
          </div>}

        { !this.state.chooseAudiance && navigate }

        { this.state.chooseAudiance &&
          <SelectAudience template = {this.state.template} supplier={this.props.supplier} user={ this.props.user }/> }

      </div>
    );
  }
}

export default SenderController