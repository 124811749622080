import React, { Component } from 'react';
import './../templates.css'
import EditTemplate from '../TemplateAction/EditTemplate'

export class TemplatesListItem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      showModal: false,
      show: false,
    }
  }; 

  close() {
    this.setState({ showModal: false });
    this.props.refresh()
  }
  
  render() {
    let template = this.props.template
    let navigate = null;
    if( this.state.show ){
      navigate = 
      <div id="templateShowDetails">

        { template.paused && <div id="templatePaused">Template paused</div> }

        <div id="templateShowDetailsL">
          Text actually sent (translation)
        </div>
        <div id="templateShowDetailsD">
          { template.localText ? template.localText:'No translation available - original message is in English' }
        </div>
        <div id="templateShowDetailsL">
          Description
        </div>
        <div id="templateShowDetailsD">
          { template.description ? template.description:'-' }
        </div>
        
        <div id="templateShowDetailsL">
          Base/reference text
        </div>
        <div id="templateShowDetailsD">
          { template.baseText ? template.baseText:'-' }
        </div>
        <div id="templateShowDetailsL">
          Text sent
        </div>
        <div id="templateShowDetailsD">
          { template.validFor ? 'Up to ' + template.validFor + ' days after signing date' : "Throughout contract's life" }
        </div>
      </div>
    }
    return (
      <div>
        <div className="templateItem">
          <div id="templatesMedium" >
            { template.code }
          </div>  
          <div id="templatesSmall" >
            { template.type }
          </div>  
          <div id="templatesExtraLarge" >
            { template.text }
          </div>
          
          <div id="templatesExtraSmall">
            { template.text? template.text.length : 0 }
          </div> 
          <div id="templatesLarge" >
            { template.type === 'Automated'
              ? template.days + ' days ' + template.direction + ' contract locks' 
              : '-' }
          </div>
          <div id="templatesExtraSmall" className="active" onClick={()=>this.setState({showModal: true})}>
            <i className="far fa-edit"></i>
          </div> 
          { template.paused
          ? <div id="templatesExtraSmall" className="active" onClick={()=>this.setState({ show: !this.state.show })}>
              <i className="far fa-pause-circle"></i>
            </div>
          : <div id="templatesExtraSmall" className="active" onClick={()=>this.setState({ show: !this.state.show })}>
              <i className="far fa-eye"></i>
            </div> } 
        </div>

        { this.state.showModal &&
          <EditTemplate 
            show={ this.state.showModal } 
            onHide={ this.close.bind(this) }
            template= { template }
          /> }

        { navigate }
        
      </div>
    )
  }  
}