import React, { Component } from 'react';
import './events.css'

export class ContractEventHeader extends Component {
  
  render() {


    return (
      <div className="eventsTitle">
        
        <div className="eventDate">Date</div>
        <div className="eventType">Type</div>
        <div className="eventUdl">Underlying</div>
        <div className="eventInfo">Info</div>
        <div className="eventInfo"></div>
        
      </div>
    )
  }  
}