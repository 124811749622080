import React, { Component } from 'react';
import AgentInvoicesAction from '../Action/AgentInvoicesAction'
import AgentInvoicesDelete from '../Action/AgentInvoicesDelete'
import './agentInvoices.css';

export class AgentInvoicesListItem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      showModal: false,
      delete: false
    }
  };  

  close() {
    this.setState({ showModal: false });
    this.props.refresh()
  }

  delete() {
    this.setState({ delete: false });
    this.props.refresh()
  }

  render() {
    let item = this.props.agentInvoice;

    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let dateFrom = '-'
    let dateTo = '-'
    
    if( item.dateTo ){
      dateTo = new Date(item.dateTo)
      dateTo = dateTo.toLocaleDateString("en-EN", options)
    }

    if( item.dateFrom ){
      dateFrom = new Date(item.dateFrom)
      dateFrom = dateFrom.toLocaleDateString("en-EN", options)
    }
    
    let comment = ''
    if( item.comment ) comment = item.comment + '; '
    if( item.items && item.items.length ){
      item.items.forEach( elem => {
        comment += elem + '; '
      })
    }

    return (
      <div>
        <div className="agentInvoiceItem">

          { this.props.edit
          ? <div id="agentInvoiceActionWrapper">  
              <div className="agentInvoiceEdit" onClick={() => this.setState({ showModal: !this.state.showModal })}><i className="fas fa-pen"></i></div>
              <div className="agentInvoiceDelete" onClick={() => this.setState({ delete: !this.state.delete })}><i className="fas fa-trash"></i></div>
            </div>
          : null }

          <div id={ this.props.edit? 'agentInvoiceEditMode': 'agentInvoiceFull' }>
            <div className={ item.paid == true ? 'agentInvoiceWrapper itemPaid': 'agentInvoiceWrapper itemToPay' }>
              <div className='agentInvoiceItemLabel'>Paid</div>
              <div className={item.paid == true ? 'agentInvoiceItemData': 'agentInvoiceItemData itemToPayData'}>{ item.paid ? 'True': 'False' }</div>
            </div>
            <div className="agentInvoiceWrapper">
              <div className="agentInvoiceItemLabel">Period from</div>
              <div className="agentInvoiceItemData">{ dateFrom }</div>
            </div>
            <div className="agentInvoiceWrapper">
              <div className="agentInvoiceItemLabel">To</div>
              <div className="agentInvoiceItemData">{ dateTo }</div>
            </div>
            <div className="agentInvoiceSuperWrapper">
              <div className="agentInvoiceWrapperFull">
                <div className="agentInvoiceItemLabel">Amount</div>
                <div className="agentInvoiceItemData">{ item.ccy }{ item.amount }</div>
              </div>
              <div className="agentInvoiceWrapperFull">
                <div className="agentInvoiceItemLabel">Paid by</div>
                <div className="agentInvoiceItemData">
                { item.paidBy && item.paidBy.profile
                  ? item.paidBy.profile.firstName.slice(0,1) + '. ' +  item.paidBy.profile.lastName
                  : '-' }
                </div>
              </div>
            </div>
            <div className="agentInvoiceWrapperLong">
              <div className="agentInvoiceItemLabel">Comment</div>
              <div className="agentInvoiceItemData">{ comment }</div>
            </div>
          </div>
          

        </div>
        
        { this.state.showModal
          ? <AgentInvoicesAction
              show={ this.state.showModal } 
              supplier={ this.props.supplier }
              onHide={ this.close.bind(this) }
              agentInvoice={ item }
              edit={ true }
              user={ this.props.user }
            />  
          : null }
        
        { this.state.delete
          ? <AgentInvoicesDelete
              show={ this.state.delete } 
              supplier={ this.props.supplier }
              onHide={ this.delete.bind(this) }
              item={ item }
              user={ this.props.user }
            />  
          : null }
      
      </div>
    )
  }  
}