import React, { Component } from 'react';
import './Footer.css'
import './../../Assets/css/font-awesome.css';
import { Col, Row } from 'react-bootstrap/lib';
import history from './../../history';
import { I18n } from 'react-i18next';

class Footer extends Component {

  handleClick(path) {
    history.push(path)
    window.scrollTo(0, 0)
  }
 
  render() {
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <footer>
            <div className="container-fluid" id="main_footer">
              <Row className="text-center">                      
              </Row>
              <Row>
                <Col xs={10} xsOffset={1}>
                  <div id="lab_social_icon_footer">
                    <div className="text-center center-block">
                      <h5>&copy;UpyaTech Ltd 2020</h5>
                      <br/>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

          </footer>
        )}
    </I18n>  
    );
  }
}

export default Footer;