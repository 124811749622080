import React, { Component } from 'react';
import history from '../../../../history';
import './groups.css'
import { addToGroupList, removeFromGroupList } from './../../../../Actions/index';
import { connect } from 'react-redux';

export class Item extends Component {

  constructor(props) {
		super(props)
		this.state = {
      show: false,
      transfer: false,
      reverseDownGroup: false,
      assign: false,
      cash: false,
      cancel: false
    }
  }
  
  handleNext(path){
    history.push(path)
  }

  handleSelect(){
    if(this.props.selectedGroups.indexOf(this.props.group._id) < 0){
      this.props.addToGroupList(this.props.group._id)
    } else this.props.removeFromGroupList(this.props.group._id)
  }

  render() {

    let group = this.props.group
    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    let last = '-'
    if( group.lastStatusUpdate) {
      last = new Date(group.lastStatusUpdate)
      last = last.toLocaleDateString("en-EN", options)
    }
    let next = '-'
    if( group.nextStatusUpdate) {
      next = new Date(group.nextStatusUpdate)
      next = next.toLocaleDateString("en-EN", options)
    }
 
    return (
      <div className="groupItem">
        <div id="groupsNumber" className="active" onClick={()=>this.handleNext('/group/id=' + group.groupNumber )}>{ group.groupNumber }</div> 
        <div id="groupsName">{ group.groupName }</div>     
        <div id="groupsData">{ group.type }</div>
        <div id="groupsData">{ group.contracts.length }</div> 
        <div id="groupsProgress">{ group.ccy } { group.target }</div>
        <div id="groupsProgress">{ group.ccy } { group.paid }</div>        
        <div id="groupsProgress">{ group.ccy } { group.totalPaid }</div>
        <div id="groupsDate">{ last }</div>
        <div id="groupsDate">{ next }</div>
        { this.props.onboardingStatus == 'Pending' && 
          <div className="groupsExtraSmall active" onClick={()=>this.handleSelect()}>
            { this.props.selectedGroups.indexOf(group._id) > -1 
              ? <i className="fas fa-check-square"></i>
              : <i className="far fa-check-square"></i> }              
          </div>  
        }
      </div>
    )
  }  
}

export const SelectableItem = connect(
  function mapStateToProps(state) {
    return { 
      selectedGroups: state.selectedGroups
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToGroupList: contract => dispatch(addToGroupList(contract)),
      removeFromGroupList: contract => dispatch(removeFromGroupList(contract))
    };
  },
)(Item);