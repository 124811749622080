import { combineReducers } from 'redux';  
import { reducer as formReducer } from 'redux-form';  
import authReducer from './auth_reducer';
import communicationReducer from './communication_reducer';
import { 
    chooseSearchReducer, 
    setActiveViewReducer, 
    chooseMonthsReducer, 
    addToClientListReducer, 
    addToContractListReducer,
    addToAgentListReducer,
    addToAssetListReducer, 
    addToPayrollListReducer, 
    selectContractsReducer,
    addToGroupListReducer,
    addToPaymentListReducer,
    setTitleReducer,
    setFilterReducer,
    setAssetFilterReducer,
    answerQuestionReducer,
    loadDealsReducer,
    loadProductsReducer,
    entitiesReducer
} from './reducers';


const rootReducer = combineReducers({  
    auth: authReducer,
    form: formReducer,
    communication: communicationReducer,
    search: chooseSearchReducer,
    activeView: setActiveViewReducer, 
    assetFilter: setAssetFilterReducer,
    filter: setFilterReducer,
    selectedMonths: chooseMonthsReducer,
    selectedClients: addToClientListReducer,
    selectedPayments: addToPaymentListReducer,
    selectedContracts: addToContractListReducer,
    selectedGroups: addToGroupListReducer,
    selectedPayrolls: addToPayrollListReducer,
    listOfContracts: selectContractsReducer,
    selectedAgents: addToAgentListReducer,
    selectedAssets: addToAssetListReducer,
    allDeals: loadDealsReducer,
    allProducts: loadProductsReducer,
    title: setTitleReducer,
    selectedEntities: entitiesReducer,
    turn: answerQuestionReducer
});

export default rootReducer;  