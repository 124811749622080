import React, { Component } from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import './../questionnaires.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class Delete extends Component {
  constructor(props) {
		super(props)
		this.state = {
      status: undefined
    };
  }
  submit(){

    axios.put(API_URL + '/questionnaires/delete/' + this.props.questionnaire._id)
			.then((ret) => {
        this.setState({
          status: 'Success'
        })
      })
  }
  over(){
    this.setState({
      status: undefined,
    })
    this.props.refresh()
    this.props.onHide()
  }
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  render() {   
    let disabled = true;
    if( !this.state.disabled && this.state.delete == 'Delete me'){
      disabled = false
    }
    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-sm">Are you sure you want to delete the questionnaire {this.props.questionnaire.name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modalBody">
              <div id="reversalDetails">
                Warning. This is action cannot be undone. Please type "Delete me" to confirm deletion.
              </div>
              <FormControl 
                type="text" 
                className="contractEditInput"
                placeholder="Delete me"
                onChange={(e)=>{ this.setState({ delete: e.target.value }) }}
              />
          </div>
          {this.renderMessage()}            
          <div id="modal_action">
            { this.state.status !=='Success' &&
              <Button id="action_signup" disabled={disabled} onClick={()=>this.submit()}>Delete</Button> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Delete