import React, { Component } from 'react';

var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

class MonthViewer extends Component {


  render() {    
    let mappedMonths = months.map((month, index) =>{ 
      return (
        <div 
          key={index}
          id="monthViewer" 
          className={this.props.months.indexOf(months[index]) >= 0?'selectedMonth':null}
        >
          {months[index]}
        </div>          
      )
    })

    return (
      <div id="monthWrapper">
        {mappedMonths}
      </div>
    );
  }
}

export default MonthViewer