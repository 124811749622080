import React, { Component } from 'react';
import history from '../../history';
import './home.css';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URL, setActiveView } from '../../Actions/index';

class Admin extends Component {

  constructor(props) {
		super(props)
		this.state = {
      count: 0
    };
  }

  componentDidMount() {
    this.countNotes();
    
  }

	countNotes = () => {
    axios.get( API_URL + '/notes/count/' + this.props.user.supplier + '/' + this.props.user._id )
			.then((ret) => this.setState({ count: ret.data }) )
  }
  
  markAsRead(){
    axios.put(API_URL + '/notes/read/' + this.props.user.supplier, { 
      note: { newRead: this.props.user._id }
    }).catch( err =>  console.log(err) )
  }


  handleNext(path){
    this.props.setActiveView( path );
    this.countNotes();
    if( path == 'internal-messages' ) this.markAsRead()
    history.push( '/' + path )
  }
  
  render() {
    let options = this.props.user.options

    return ( 
      <div className="homeMenu">
        <div id="home_menu">  
          
          <div onClick={()=>this.handleNext('clients')} className={ this.props.activeView==='clients'?"home_menu_item active_home_menu topMenu":"home_menu_item topMenu" }>
            <i className="fas fa-users"></i>
          </div>

          <div onClick={()=>this.handleNext('contracts')} className={ this.props.activeView==='contracts'?"home_menu_item active_home_menu":"home_menu_item" }>
            <i className="fas fa-file-signature"></i>
          </div>

          { options.groups && 
            <div onClick={()=>this.handleNext('groups')} className={ this.props.activeView==='groups'?"home_menu_item active_home_menu":"home_menu_item" }>
              <i className="fas fa-people-arrows"></i>
            </div> }

          <div onClick={()=>this.handleNext('assets')} className={ this.props.activeView==='assets'?"home_menu_item active_home_menu":"home_menu_item" }>
            <i className="fas fa-barcode"></i>
          </div>

          <div onClick={()=>this.handleNext('agents')} className={this.props.activeView==='agents'?"home_menu_item active_home_menu":"home_menu_item"}>
            <i className="fas fa-male"></i>
          </div>
          
          { options && options.internalMessaging !== false &&
            <div onClick={()=>this.handleNext('internal-messages')} className={this.props.activeView==='internal-messages'?"home_menu_item active_home_menu":"home_menu_item"}>
              <div id={ this.state.count > 0 ? 'newMessages' : '' }><i className="far fa-comments"></i></div>
              { this.state.count > 0 &&
                <div id='NbNewMessages'>{this.state.count}</div> 
              }
            </div> }

          { options.manageAgents &&
            <div onClick={()=>this.handleNext('payrolls')} className={this.props.activeView==='payrolls'?"home_menu_item active_home_menu onlyLarge":"home_menu_item onlyLarge"}>
              <i className="fas fa-coins"></i>
            </div> }  

          { options && options.texts !== false &&   
            <div onClick={()=>this.handleNext('texts')} className={this.props.activeView==='texts'?"home_menu_item active_home_menu onlyLarge":"home_menu_item onlyLarge"}>
              <i className="far fa-envelope"></i>
            </div> }
            
          { options && options.payments !== false &&   
            <div onClick={()=>this.handleNext('payments')} className={this.props.activeView==='payments'?"home_menu_item active_home_menu bottomMenu":"home_menu_item bottomMenu"}>
              <i className="fas fa-dollar-sign"></i>
            </div> }

          <div id="home_item" onClick={()=>this.handleNext('search')}>
            <i style={{cursor:'pointer'}} className="fas fa-search"></i>
          </div>
        </div>
      </div>
    );
  }
}


const ManageMenu = connect(
  function mapStateToProps(state) {
    return { 
      activeView: state.activeView
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      setActiveView: view => dispatch(setActiveView(view))
    };
  },
)(Admin);

export default ManageMenu
