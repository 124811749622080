import React, { Component } from 'react';
import { Header } from './Header'
import { Item } from './Item'
import './payrollcontracts.css';

class Agent extends Component {
  
  render() {    
    let agent = this.props.agent

    return (
      <div id="payrollSmallWrapper">
        <div id="payrollDetailsTitle">Agent's details</div>
        <div id="payrollAgent">
          <div className="payrollAgentWrapper fullLine">
            <div id="payrollAgentLabel">Full name</div>
            <div id="payrollAgentData">{ agent.profile.firstName } { agent.profile.lastName }</div>
          </div>
          <div className="payrollAgentWrapper fullLine">
            <div id="payrollAgentLabel">Email</div>
            <div id="payrollAgentData">{ agent.email }</div>
          </div>
          <div className="payrollAgentWrapper">
            <div id="payrollAgentLabel">Agent number</div>
            <div id="payrollAgentData">{ agent.agentNumber }</div>
          </div>
         
          <div className="payrollAgentWrapper">
            <div id="payrollAgentLabel">Mobile</div>
            <div id="payrollAgentData">{ agent.contact.mobile }</div>
          </div>
          { agent.payment
            ? <div>
              <div id="payrollDetailsSubTitle">Payment details</div>
              <div className="payrollAgentWrapper">
                <div id="payrollAgentLabel">Bank details</div>
                <div id="payrollAgentData">{ agent.payment.bankAccount? agent.payment.bankAccount: '-' }</div>
              </div>
              <div className="payrollAgentWrapper">
                <div id="payrollAgentLabel">Mobile money details</div>
                <div id="payrollAgentData">{ agent.payment.mmAccount }</div>
              </div>
              <div className="payrollAgentWrapper">
                <div id="payrollAgentLabel">Note</div>
                <div id="payrollAgentData">{ agent.payment.note? agent.payment.note: '-' }</div>
              </div>
            </div>
            : null }
        </div>
  
      </div>
       );
  }
}

export default Agent