import React, { Component } from 'react';

class Header extends Component {
  
  render() {
    
    return (
      <div className="itemTitle">
        
        <div id="itemDeployDate">Date</div>
        <div id="itemDeploy">Contract</div>
        <div id="itemDeploy">Asset</div>
        <div id="itemDeploy">Received from</div>
        <div id="itemDeployQty">Quantity</div>
      </div>
    )
  }  
}

export default Header 