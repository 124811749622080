import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { API_URL, selectContracts, setTitle } from '../../../Actions/index';
import './graph.css'
import { connect } from 'react-redux';
import history from '../../../history';

class Graph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: undefined
    };
    this.loadData = this.getGraph()
  };

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) {
      this.getGraph();
    }
  }

  handleNext(path){
    history.push(path)
  }

  getGraph = () => {
    let body = {
      criteria: this.props.filter,
      userId: this.props.user._id
    }
    axios
      .post(API_URL + '/contracts/getshotratio/' + this.props.user.supplier, body)
      .then((res) => {
        this.setState({
          data: res.data
        })
      })
  }

  click = async (item, data, label) => {
    let detailedContracts = []

    try { 

      if( item[0] ){

        let status = label[item[0]._index]
        let subtitle = data[item[0]._index] > 100 ? 'First 100' : 'All ' + data[item[0]._index]

        let title = subtitle + ' ' + label[item[0]._index] + ` contracts`

        let query = this.props.filter? this.props.filter : {}
        query.status = status

        var body = {
          query,
          fromPage: 1,
          pageSize: 100
        }

        let res = await axios.post(API_URL + '/contracts/get/' + this.props.user.supplier, body)
        detailedContracts = res.data? res.data.docs : []
        this.props.selectContracts( detailedContracts )
        this.props.setTitle( title )
        this.handleNext('/contracts-analyze')
      }
    } catch(err){ console.log(err) }

  }

  render() {

    let data = this.state.data 
    let label = ['ENABLED', 'LOCKED', 'PAIDOFF', 'REPOSSESSED', 'WRITEOFF']
    let enabled = 0
    let locked = 0
    let paidoff = 0
    let repossessed = 0
    let writeoff = 0
    let total = 1
    let activePct = 1
    if( data ){
      total = data.reduce( function(acc, elem) { return acc + elem.count; }, 0)
      data.map( elem => {
        if( elem._id.status == 'ENABLED' ) enabled = Math.round( elem.count / total * 100, 2) / 100
        if( elem._id.status == 'LOCKED' ) locked =  Math.round(elem.count / total * 100, 2) / 100
        if( elem._id.status == 'PAIDOFF' ) paidoff =  Math.round(elem.count / total * 100, 2) / 100
        if( elem._id.status == 'REPOSSESSED' ) repossessed =  Math.round(elem.count / total * 100, 2) / 100
        if( elem._id.status == 'WRITEOFF' ) writeoff =  Math.round(elem.count / total * 100, 2) / 100
      })
    }

    let dataset = [ enabled, locked, paidoff, repossessed, writeoff ]
    
    let options = {
      onClick: (e, item) => this.click(item, dataset, label),
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
    }


    return (
      <div>
        { this.state.data 
          ? <Doughnut 
            data={{
              labels: label, 
              datasets: [
                {
                  label: '$',
                  backgroundColor: [
                    'white',
                    'rgba(252, 198, 48, 0.9)',
                    'rgba(0,139,139, 0.8)',
                    '#CA193F',
                    'red'
                  ],
                  borderColor: '#26455A',
                  borderWidth: 1,
                  hoverBackgroundColor: [
                    'white',
                    'rgba(252, 198, 48, 1)',
                    'rgba(0,139,139, 1)',
                    '#CA193F',
                    'red'
                  ],
                  hoverBorderColor: [
                    'white',
                    'rgba(252, 198, 48, 1)',
                    'rgba(0,139,139, 1)',
                    '#CA193F',
                    'red'
                  ],
                  data: dataset
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ options }
          /> 
          : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }
        
      </div>      
    );
  }
}

export const Shotsplit = connect(
  function mapStateToProps(state) {
    return { 
      listOfContracts: state.listOfContracts,
      title: state.title
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      selectContracts: (list) => dispatch(selectContracts(list)),
      setTitle: (title) => dispatch( setTitle(title) )
    };
  },
)(Graph);
