import React, { Component } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { I18n } from 'react-i18next';
import { connect } from 'react-redux';
import '../client.css'

class Approve extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status: undefined,
      approveContracts: undefined,
      note: undefined,
      disabled: false
    };
  } 
  
  componentDidUpdate(prevProps) {
    if (this.props.selectedClients !== prevProps.selectedClients) {
      this.setState({ 
        status: undefined, 
        disabled: false, 
        note: undefined,
        approveContracts: undefined 
      });    
    }
  }

  over(){
    this.props.onHide();
    this.setState({ 
      status: undefined, 
      disabled: false, 
      note: undefined,
      approveContracts: undefined 
    });
  }

  handleChange(){
    var query = {
      action: this.props.action,
      clients: this.props.selectedClients,
      approveContracts: this.state.approveContracts,
      note: this.state.note,
      userNumber: this.props.userNumber
    }
    this.setState({ 
      disabled: true, 
      status: 'One second...'
    })
    axios.put(API_URL + '/clients/credit/' + this.props.supplier, query)
			.then((ret) => {
        this.setState({ status: 'Success!' })
      })
  }

  renderMessage() {
    if (this.state.status && this.state.status !== 'One second...') {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  render() {
    let disabled = true
    if( !this.state.disabled && 
      this.state.approveContracts !== undefined || this.props.action == 'Reject'
    ) disabled = false
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">{this.props.action} {this.props.selectedClients.length} clients - confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.props.action == 'Approve'
              ? <div id="approveClientsModal">
                  <div id="approveContractsQuestion">
                    Approve all linked contracts?
                  </div>
                  <div
                    id="approveContracts"
                    className={ this.state.approveContracts == true? "approveSelected":"approveUnselected" } 
                    onClick={() => this.setState({ approveContracts: true })}
                  >
                    Yes
                  </div>
                  <div 
                    id="approveContracts"
                    className={ this.state.approveContracts == false? "approveSelected":"approveUnselected" } 
                    onClick={() => this.setState({ approveContracts: false })}
                  >
                    No
                  </div>
                </div>
              : <div id="approveClientsModal">
                  <textarea
                    type="text"
                    className="refusalNote"
                    placeholder="Add note for agents"
                    onChange={(e)=> this.setState({ note: e.target.value }) }
                  />
                </div> }
            { this.renderMessage() }            
            <br/>
            <div id="modal_action">
            { this.state.status==='Success!'
              ? null
              : <Button id="confirm_action" disabled={ disabled } onClick={()=>this.handleChange()}>{this.props.action}</Button>
            }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

const ApproveClient = connect(
  function mapStateToProps(state) {
    return { 
      selectedClients:state.selectedClients
    };
  }
)(Approve);

export default ApproveClient;