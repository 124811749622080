import React, { Component } from 'react';
import { Route} from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux'
import './i18n';
import history from './history'
import * as firebase from 'firebase';

//Public pages
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'

//Landing pages

//Details pages
import ManageHome from './Components/Pages/ManageHome';
import AdminHome from './Components/Pages/AdminHome';

import Dashboard from './Components/Pages/Dashboard';
import RevenuesController from './Components/Pages/DashboardFinance/RevenuesController'
import UsageController from './Components/Pages/DashboardFinance/UsageController'
import ProgressController from './Components/Pages/DashboardFinance/ProgressController'

import ClientAnalysisController from './Components/Pages/DashboardClients/ClientAnalysisController'

import ImpactDashboard from './Components/Pages/DashboardImpact/ImpactDashboard'

import Client from './Components/Pages/Clients/Client'
import { Search } from './Components/Pages/Search/Search'
import ClientActionController from './Components/Pages/Clients/Action/ClientActionController'
import Contract from './Components/Pages/Contracts/Contract'

import ContractsSelection from './Components/Pages/DashboardFinance/ContractsSelection'
import AssetsSelection from './Components/Pages/DashboardAsset/AssetsSelection'

import InvoicesController from './Components/Pages/Invoices/InvoicesController'
import PayrollsController from './Components/Pages/Payrolls/PayrollsController'
import RatesController from './Components/Pages/Rates/RatesController'

import Clients from './Components/Pages/Clients/Clients'
import ClientsSearch from './Components/Pages/Clients/ClientsSearch'
import ClientsController from './Components/Pages/Clients/ClientsController'

import Contracts from './Components/Pages/Contracts/Contracts'
import ContractsSearch from './Components/Pages/Contracts/ContractsSearch'
import ContractActionController from './Components/Pages/Contracts/Action/ContractActionController'
import ContractsController from './Components/Pages/Contracts/ContractsController'
import ContractsAnalytics from './Components/Pages/Contracts/ContractsAnalytics'

import Assets from './Components/Pages/Assets/Assets'
import AssetsSearch from './Components/Pages/Assets/AssetsSearch'
import AssetsController from './Components/Pages/Assets/AssetsController'
import Asset from './Components/Pages/Assets/Asset'
import LoadAssets from './Components/Pages/Assets/Action/LoadAssets'

import ItemsController from './Components/Pages/Items/ItemsController'

import EntitiesController from './Components/Pages/Entities/EntitiesController'

import Agent from './Components/Pages/Agents/Agent'
import DealsController from './Components/Pages/Deals/DealsController'
import PaymentsController from './Components/Pages/Payments/PaymentsController'
import TasksController from './Components/Pages/Notes/TasksController'
import BatchesController from './Components/Pages/Batches/BatchController'
import ProductsController from './Components/Pages/Products/ProductsController'
import AgentsController from './Components/Pages/Agents/AgentsController'
import MapsController from './Components/Pages/Google-GeoLocation/MapsController'

import GroupsController from './Components/Pages/Groups/GroupsController'
import GroupActionController from './Components/Pages/Groups/Action/GroupActionController'
import Group from './Components/Pages/Groups/Group'

import MessagesController from './Components/Pages/Messages/MessagesController'
import UsersController from './Components/Pages/Users/UsersController'
import Load from './Components/Pages/Load'
import QuestionnairesController from './Components/Pages/Questionnaires/QuestionnairesController'

// Import higher order Components
import RequireAuth from './Components/Auth/require_auth';

//Log-in pages
import { Login, Logout, ForgotPassword, ResetPassword, UpdatePassword } from './Components/Auth';

class App extends Component {

  componentDidMount() {
    this.initializeFirebase()
  }

  initializeFirebase = () => {
    const firebaseConfig = {
      apiKey: "AIzaSyA3pYqmPtyGIqgtPxOmT-vgv5BDRMjxUv4",
      authDomain: "upyapp-4f376.firebaseapp.com",
      databaseURL: "https://upyapp-4f376.firebaseio.com",
      projectId: "upyapp-4f376",
      storageBucket: "upyapp-4f376.appspot.com",
      messagingSenderId: "469042534280",
      appId: "1:469042534280:web:6d37a9b4e73ea7f4ee8c3d",
      measurementId: "G-B9RKEWCM33"
    };
    firebase.initializeApp(firebaseConfig);
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <div className="App" >                  
          <Header/>                                
          <Route exact path='/' component={Login}/> 
          <Route exact path='/home' component={RequireAuth(ManageHome)}/> 
          <Route exact path='/admin' component={RequireAuth(AdminHome)}/> 
          <Route exact path='/dashboard' component={RequireAuth(Dashboard)}/> 

          <Route exact path='/analytics' component={RequireAuth(RevenuesController)}/> 
          <Route exact path='/analytics/usage' component={RequireAuth(UsageController)}/> 

          <Route exact path='/portfolio-analytics' component={RequireAuth(ProgressController)}/> 
          <Route exact path='/assets-analyze' component={RequireAuth(AssetsSelection)}/> 
          <Route exact path='/population-analytics' component={RequireAuth(ClientAnalysisController)}/> 
          <Route exact path='/impact-analytics' component={RequireAuth(ImpactDashboard)}/> 
          <Route exact path='/maps' component={RequireAuth(MapsController)}/> 

          <Route exact path='/deals' component={RequireAuth(DealsController)}/> 
          <Route exact path='/products' component={RequireAuth(ProductsController)}/> 
          <Route exact path='/users' component={RequireAuth(UsersController)}/> 
          <Route exact path='/search' component={RequireAuth(Search)}/> 
          <Route exact path='/payments' component={RequireAuth(PaymentsController)}/>

          <Route exact path='/clients' component={RequireAuth(Clients)}/> 
          <Route exact path='/clients-view' component={RequireAuth(ClientsController)}/> 
          <Route exact path='/clients-addone' component={RequireAuth(ClientActionController)}/> 
          <Route exact path='/clients-search' component={RequireAuth(ClientsSearch)}/> 
          <Route exact path='/client/id=:clientNumber' component={RequireAuth(Client)}/> 
          <Route exact path='/client/add' component={RequireAuth(ClientActionController)}/> 

          <Route exact path='/contracts' component={RequireAuth(Contracts)}/> 
          <Route exact path='/contracts-view' component={RequireAuth(ContractsController)}/> 
          <Route exact path='/contracts-analytics' component={RequireAuth(ContractsAnalytics)}/> 
          <Route exact path='/contracts-search' component={RequireAuth(ContractsSearch)}/> 
          <Route exact path='/contract/id=:contractNumber' component={RequireAuth(Contract)}/> 
          <Route exact path='/contract/add/id=:clientNumber' component={RequireAuth(ContractActionController)}/> 

          <Route exact path='/assets' component={RequireAuth(Assets)}/> 
          <Route exact path='/assets-search' component={RequireAuth(AssetsSearch)}/> 
          <Route exact path='/assets-view' component={RequireAuth(AssetsController)}/> 
          <Route exact path='/assets-add' component={RequireAuth(LoadAssets)}/> 
          <Route exact path='/asset/id=:assetNumber' component={RequireAuth(Asset)}/> 
          <Route exact path='/assets/batch/id=:batchNumber' component={RequireAuth(BatchesController)}/> 

          <Route exact path='/items/type=:type' component={RequireAuth(ItemsController)}/> 

          <Route exact path='/texts' component={RequireAuth(MessagesController)}/> 
          <Route exact path='/internal-messages' component={RequireAuth(TasksController)}/> 
          <Route exact path='/questionnaires' component={RequireAuth(QuestionnairesController)}/> 
          <Route exact path='/contracts-analyze' component={RequireAuth(ContractsSelection)}/> 
          <Route exact path='/invoices' component={RequireAuth(InvoicesController)}/> 
          <Route exact path='/rates' component={RequireAuth(RatesController)}/> 
          <Route exact path='/payrolls' component={RequireAuth(PayrollsController)}/>

          <Route exact path='/org-structure' component={RequireAuth(EntitiesController)}/> 
         
          <Route exact path='/agents' component={RequireAuth(AgentsController)}/> 
          <Route exact path='/agent/id=:agentNumber' component={RequireAuth(Agent)}/> 
          <Route exact path='/loader' component={RequireAuth(Load)}/> 


          <Route exact path='/group/id=:groupNumber' component={RequireAuth(Group)}/> 
          <Route exact path='/groups' component={RequireAuth(GroupsController)}/> 
          <Route exact path='/group/add' component={RequireAuth(GroupActionController)}/> 

          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/update-password" component={UpdatePassword} />

          <Footer/>          
        </div>
      </ConnectedRouter>
    );
  }
}


export default App;
