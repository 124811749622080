import React, { Component } from 'react';
import {Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap/lib';
import { IndexLinkContainer } from 'react-router-bootstrap';
import { I18n } from 'react-i18next';

class HeaderOut extends Component {

  render() {
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <div>
            <Navbar collapseOnSelect fixedTop style={{background:"transparent"}}>
              <Navbar.Header>
                <Navbar.Brand>
                  <a href="/"><img src={require('./../Assets/upya.png')} alt="logo"/></a>
                </Navbar.Brand>
              <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse>

              </Navbar.Collapse>
            </Navbar>
          </div>
        )}
      </I18n>
    );
  }
}

export default HeaderOut;