import React from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class AddEntity extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      entity: {
        type: this.props.type,
        supplier: this.props.user.supplier,
        country: this.props.country,
        organisation: this.props.organisation,
        branch: this.props.branch,
        createdBy: this.props.user._id
      },       
      disabled: false
    };
  } 


  create() {
    axios.post(API_URL + '/entities/create/'+ this.props.user.supplier, 
        this.state.entity
      ).then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        console.log(err)
        this.setState({status: 'Error'})
      }); 
  }
  
  over(){
    this.props.onHide();
    this.props.refresh()
    this.setState({
      entity: {},       
      status: undefined,
      disabled: false
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }  

  render() {
    let entity = this.state.entity
    let isDisabled = ( !entity.name )? true : false

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="medium"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">
              Create new {this.props.type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
            
            <div className="entityLabel">Name</div>
            <FormControl 
              type="text" 
              className="itemPricingInputLong"
              value={ this.state.entity.name }
              placeholder={'Type'}                
              onChange={(e)=>{ 
                let entity = Object.assign({}, this.state.entity); 
                entity.name = e.target.value; 
                this.setState({ entity }) 
              }}
            />          

            { this.renderMessage() }

            <div id="modal_action">
              { this.state.status !== 'Success' &&
                <Button id="action_signup" disabled={isDisabled} onClick={this.create.bind(this)}>Create</Button> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddEntity;