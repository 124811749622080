import React, { Component } from 'react';
import CreateGroup from './CreateGroup'
import ManageMenu from '../../ManageMenu'
import history from '../../../../history';

class GroupActionController extends Component {

  goBack() {
    window.history.back();
  }
 
  render() {    

    return (
      <div className='background'>
        <div id="pageTitle">Create new group</div>
        <ManageMenu user={this.props.user}/>
        <div id="clientActionLine">
          <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
        </div>
        <div id="clientBackground">
          <CreateGroup user={this.props.user}/>
        </div>
      </div>
    );
  }
}

export default GroupActionController