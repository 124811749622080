import React, { Component } from 'react';
import {AssetHeader} from './AssetHeader'
import {AssetsListItem} from './AssetsListItem'
import './../List/assets.css'


class AssetsList extends Component {

  render() {    

    let { assets, assetStatus, search } = this.props
    if( this.props.assetStatus ) assets = assets.filter( asset => asset.status == assetStatus)

    let mappedAssets = assets && assets.map( asset =>{ 
      return <AssetsListItem asset={asset} key={ asset._id } search={ search } />
    })
    
    return (
      <div>
        { assets && assets.length > 0? <AssetHeader assetStatus={ assetStatus } search={ search } />:null}
        { mappedAssets }
      </div>
    );
  }
}

export default AssetsList