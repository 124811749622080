import React, { Component } from 'react';
import { Image } from 'react-bootstrap/lib';
import SDG4 from './sdg4'
import SDG7 from './sdg7'
import SDG1 from './sdg1'

class ImpactOverview extends Component {
  constructor(props) {
		super(props)
		this.state = {
      nav:-1,
    };
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
  }
  
  render() {    

    return (
      <div className="impactPageWrapper">
        <div className="full">
          <div className="sdgWrapper">
            <Image  src={require('./Assets/E-WEB-Goal-07.png')} responsive rounded /> 
          </div>
          <div className="sdgData">
            <SDG7 supplier={ this.props.supplier }/>
          </div>
        </div>
        
        <div className="full">

          <div className="sdgWrapper">
            <Image  src={require('./Assets/E-WEB-Goal-04.png')} responsive rounded /> 
          </div>
          <div className="sdgData">
            <SDG4 supplier={ this.props.supplier }/>
          </div>
        </div>

        <div className="full">

          <div className="sdgWrapper">
            <Image  src={require('./Assets/E-WEB-Goal-01.png')} responsive rounded /> 
          </div>
          <div className="sdgData">
            
            <SDG1 supplier={ this.props.supplier }/>
          </div>   
        </div>

      </div>
    );
  }
}

export default ImpactOverview