import React, { Component } from 'react';
import { Button } from 'react-bootstrap/lib';
import { SearchContracts } from './../../Search/SearchItems/SearchContracts'
import { connect } from 'react-redux';
import { API_URL } from './../../../../Actions/index';
import axios from 'axios';
import './sender.css';
import { emptyList } from './../../../../Actions/index';

class Audience extends Component {
  constructor(props) {
		super(props)
		this.state = {
      status: undefined, 
      answer: undefined
    };
  }

  componentWillUnmount(){
    this.props.emptyList()
  }

  sendMany = () => {
    let body = {
      listOfContracts: this.props.selectedContracts,
      templateId: this.props.template._id
    }

    axios.post(API_URL + '/messages/sendTemplate/' + this.props.supplier, body)
			.then((ret) => {
        this.setState({
          status: 'Success!',
          answer: ret.data
        })
      })
      .then( () => this.props.emptyList() )
      .catch( err =>  this.setState({ status: 'Error' }) )
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success!' ?'successfulSent':'unSuccessfulCreation'}>
          { this.state.status } { this.state.answer }
        </div>
      );
    }
  }  

  render() {    

    return (
      <div >
        <div>
          <Button 
            disabled={ this.props.selectedContracts.length===0 || this.state.status ? true : false } 
            id={ this.props.selectedContracts.length > 0? 'sendGroupMessage': 'sendGroupMessageStandBy'}
            onClick={ ()=> this.sendMany()}
          >
            Send to { this.props.selectedContracts.length } clients
          </Button>
        </div>
        {this.renderMessage()}
        <div id="selectedFilter">Select audience</div>
        <div id="selectClientsSection">
          <SearchContracts user={this.props.user} search={'contracts'} selectable={true}/>
        </div>
      </div>
    );
  }
}

const SelectAudience = connect(
  function mapStateToProps(state) {
    return { 
      selectedContracts: state.selectedContracts
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Audience);

export default SelectAudience

