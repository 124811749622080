import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@google/markerclusterer'
import './marker.css'

let options =[
  {
      "featureType": "landscape.natural",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#405c6eb3"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "hue": "#1900ff"
          },
          {
              "color": "#26455A"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
          {
              "lightness": 100
          },
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "lightness": 700
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "color": "#00253D"
          }
      ]
  }
]
 
class AssetListMap extends Component {
  componentDidMount () {
    const script = document.createElement('script')
    script.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js'
    script.async = true
    document.body.appendChild(script)
  }

  componentDidUpdate(prevProps) {
    if( this.props.assets !== prevProps.assets ) {
      console.log(this.props.assets)
    }
  }

  pinSymbol(color, strokeColor) {
    return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: strokeColor,
        strokeWeight: 2,
        scale: 1,
   };
}

  setGoogleMapRef (map, maps) {
    this.googleMapRef = map
    this.googleRef = maps

    let assets = this.props.assets

    let markers = assets && assets.map((asset) => {
      let location = {
        lat: parseFloat( asset.gps.latitude ),
        lng: parseFloat( asset.gps.longitude )
      }
      let marker = new this.googleRef.Marker({ 
        position: location,
        title: asset.contract
          ? 'Unit ' + asset.assetNumber + ' - ' + asset.contract.status
          : 'Unit ' + asset.assetNumber + ' - INSTOCK',
        icon: asset.contract && asset.contract.status == 'LOCKED' 
          ? this.pinSymbol("#FCC30B", 'white') 
          : this.pinSymbol("white", "#008B8B"),
        clickable: true,
      })
      this.googleRef.event.addDomListener(marker, 'click', function() {
        window.open(
          'https://manage.upya.io/asset/id='+ asset.assetNumber,
          '_blank'
        );
    });
      return marker
    })
    let markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      gridSize: 50,
      minimumClusterSize: 5
    })
  }

  render() {

    return (
      // Important! Always set the container height explicitly
      <div id="assetListMap">
        <GoogleMapReact
          bootstrapURLKeys={{ 
            key: 'AIzaSyAZb4Vrv1pxm9-MEQrYLsq7oYqQNJvi7Es',
            language: 'en',
          }}
          options={{
            streetViewControl: true,
            styles: options,
        }}
          defaultCenter={this.props.GPScenter}
          defaultZoom={7}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => this.setGoogleMapRef(map, maps)}
        >
              
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default AssetListMap;