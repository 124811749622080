import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap/lib';
import './../agentTarget.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class AgentTargetsEdit extends Component {

  constructor(props) {
    super(props);
    this.state= {
      status: undefined,
      target:{
        targetId: this.props.target,
        active: false,
        dateTo: new Date(),
        closedBy: this.props.user._id
      }
    }
  }; 

  submit() {
    let supplier = this.props.supplier
    axios.put(API_URL + '/agents/editTarget/'+ supplier, this.state.target)
      .then((ret) => this.setState({ status: 'Success!'}) )
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success!'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  }  
  
  over(){
    this.props.onHide()
    this.setState({   
      status: undefined,
      showIllustration: false,
      isDisabled: false,
    })
  }


  render() {
    
    let isDisabled = false
    if(this.state.disabled){ isDisabled=true }    

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Pause confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.renderMessage() }            
          <br/>
          <div id="modal_action">
            { this.state.status==='Success!'
              ? null
              : <Button id="action_signup" disabled={isDisabled} onClick={this.submit.bind(this)}>Pause</Button> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AgentTargetsEdit