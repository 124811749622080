import React, { Component } from 'react';

class Item extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }

  select(status, quality){
    this.props.showDetails( this.props.auxiliary, status, quality )
  }
  
  render() {
    var { items, auxiliary } = this.props
    let instockNew = items
      .filter( elem => elem._id.status == 'INSTOCK' &&  elem._id.quality == 'New')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let instockSecond = items
      .filter( elem => elem._id.status == 'INSTOCK' &&  elem._id.quality == 'SecondHand')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let instockTocheck = items
      .filter( elem => elem._id.status == 'INSTOCK' &&  elem._id.quality == 'ToCheck')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let instockFaulty = items
      .filter( elem => elem._id.status == 'INSTOCK' &&  elem._id.quality == 'Faulty')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let agentNew = items
      .filter( elem => elem._id.status == 'WITH_AGENT' &&  elem._id.quality == 'New')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let agentSecond = items
      .filter( elem => elem._id.status == 'WITH_AGENT' &&  elem._id.quality == 'SecondHand')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let agentTocheck = items
      .filter( elem => elem._id.status == 'WITH_AGENT' &&  elem._id.quality == 'ToCheck')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    return (
      <div className="itemItem" >
        <div id="itemAux">{ auxiliary? auxiliary.product.name + ' -- ' + auxiliary.name: '-' }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select('INSTOCK', 'New')}>{ instockNew }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select('INSTOCK', 'SecondHand')}>{instockSecond}</div>
        <div id="itemTotal" className="active" onClick={()=>this.select('INSTOCK', 'ToCheck')}>{instockTocheck}</div>
        <div id="itemTotal" className="active" onClick={()=>this.select('INSTOCK', 'Faulty')}>{ instockFaulty }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select('WITH_AGENT', 'New')}>{ agentNew }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select('WITH_AGENT', 'SecondHand')}>{ agentSecond }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select('WITH_AGENT', 'ToCheck')}>{ agentTocheck }</div>
      </div>
    )
  }  
}

export default Item 