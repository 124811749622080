import React from 'react';
import {Bar} from 'react-chartjs-2';
import history from '../../../../history';

class PerfGraph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      label: [],
      data: [],
      result: undefined
    };
  };
  
  render() {
    
    let title = this.props.title

    let data = this.props.data
    let labels = this.props.labels
    let targets = this.props.targets

    let options = {
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            },
            labels: labels, 

          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
              display:false
            }, 
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                if( title == 'New sales' ) return value
                else return (value / 1000).toLocaleString() + 'k';
              },
              fontColor: 'rgba(255, 255, 255, 0.8)',
              fontSize: 10
            },
          }
        ]
      }
    }

    return (
      <div id="perfGraph">

        { Math.max( ...targets) > 0
        ? <Bar
            data={{
              labels: labels, 
              datasets: [
                {
                  label: this.props.title == 'New sales'? 'Sales' : '$',
                  backgroundColor: 'white',
                  borderColor: 'white',
                  borderWidth: 1,
                  hoverBackgroundColor: this.props.color,
                  hoverBorderColor: 'white',
                  data: data
                },
                {
                  label: 'Targets',                  
                  pointRadius: 0,
                  steppedLine: true,
                  borderWidth: 1.5,                  
                  type:'line',
                  fill: false,
                  backgroundColor: 'rgba(250, 215, 0, 0.8)',
                  borderColor: 'rgba(250, 215, 0, 0.8)',
                  borderWidth: 1,
                  hoverBackgroundColor: 'rgba(250, 215, 0, 1)',
                  hoverBorderColor: 'rgba(250, 215, 0, 1)',
                  data: targets
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ options }
          />
        : <Bar
            data={{
              labels: labels, 
              datasets: [
                {
                  label: this.props.title == 'New sales'? 'Sales' : '$',
                  backgroundColor: 'white',
                  borderColor: 'white',
                  borderWidth: 1,
                  hoverBackgroundColor: this.props.color,
                  hoverBorderColor: 'white',
                  data: data
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ options }
          />  }
      </div>
    );
  }
}


export default PerfGraph
