import React, { Component } from 'react';
import * as firebase from 'firebase';
import './images.css'

class ClientPhoto extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
      url: undefined
    };
  }

  componentDidMount() {
    this.getURL()
  }

  getURL = async () => {
    let user = this.props.user
    if( this.props.client ){
      var storage = firebase.storage();
      var client = this.props.client;
      var storageRef = storage.ref();
      let URL, ref, number, oldRef, oldNumber = undefined;

      ref = 'Clients/' + user.supplierReference + '_' + client.agentReference;
      number = 'Clients/' + user.supplierReference + '_' + client.clientNumber;
  
      oldRef = 'Clients/' + client.supplier + '_' + client.agentReference;
      oldNumber = 'Clients/' + client.supplier + '_' + client.clientNumber;

      if( client.pictureTag ){
        try { URL = await storageRef.child(client.pictureTag).getDownloadURL()
        } catch(err){ 
          console.log(err); 
        }
      }
      if( !URL && user && user.supplierReference ){
        try { URL = await storageRef.child(ref).getDownloadURL()
        } catch(err){ console.log(err); }
      }
      if( !URL && user && user.supplierReference ){
        try { URL = await storageRef.child(number).getDownloadURL()
        } catch(err){ }
      }      
      if( !URL ){
        try { URL = await storageRef.child(oldRef).getDownloadURL()
        } catch(err){ }
      }  
      if( !URL ){
        try { URL = await storageRef.child(oldNumber).getDownloadURL()
        } catch(err){ }
      }

      if( URL ){ this.setState({ show: true, url: URL })
      } else return;
    }

  }

  render() {    
    let navigate = <div className="clientPlaceholder">No picture<br/>available</div>
    if( this.state.show && this.state.url ){
      navigate = <img 
        className="clientImg" 
        src={this.state.url} 
      /> 
    }
    return (
      <div >
        { navigate }
      </div>
    );
  }
}

export default ClientPhoto