import React, { Component } from 'react';
import history from './../../../../history';
import './contracts.css';
import { addToContractList, removeFromContractList } from './../../../../Actions/index';
import { connect } from 'react-redux';

export class ContractsListItem extends Component {
  
  handleNext(path){
    history.push(path)
  }

  handleSelect(){
    if(this.props.selectedContracts.indexOf(this.props.contract._id) < 0){
      this.props.addToContractList(this.props.contract._id)
    } else this.props.removeFromContractList(this.props.contract._id)
  }

  render() {
    let contract = this.props.contract

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let nextUpdate = '-'
    let submissionDate = '-'
    let creditDecisionDate = '-'
    let lastStatusUpdate = '-'
    
    if(contract.nextStatusUpdate){
      nextUpdate = new Date(contract.nextStatusUpdate)
      nextUpdate = nextUpdate.toLocaleDateString("en-EN", options)
    }
 
    if(contract.creditDecisionDate){
      creditDecisionDate = new Date(contract.creditDecisionDate)
      creditDecisionDate = creditDecisionDate.toLocaleDateString("en-EN", options)
    } 
    
    if(contract.submissionDate){
      submissionDate = new Date(contract.submissionDate)
      submissionDate = submissionDate.toLocaleDateString("en-EN", options)
    }

    if(contract.lastStatusUpdate){
      lastStatusUpdate = new Date(contract.lastStatusUpdate)
      lastStatusUpdate = lastStatusUpdate.toLocaleDateString("en-EN", options)
    }

    let status = contract.status
    status = status.toLowerCase();
    status = status.charAt(0).toUpperCase() + status.slice(1);

    let aFirstName = ''
    let aLastName = ''
    let aProfile = contract.agent? contract.agent.profile : undefined
    if( aProfile && aProfile.firstName ) aFirstName = aProfile.firstName.charAt(0).toUpperCase() + '.'
    if( aProfile && aProfile.lastName ) aLastName = aProfile.lastName

    let   navigate = null;
    if(this.props.contractStatus==='Signed'){
      navigate = 
        <div>        
          { contract.agent
            ? <div className="contractsSmall active onlyLarge" onClick={()=>this.handleNext('agent/id='+contract.agent.agentNumber)}>
                { aFirstName } { aLastName }
              </div>  
            : <div className="contractsSmall" > - </div> } 
          <div className="contractsSmall onlyLarge" >
            {contract.ccy} {contract.totalPaid.toLocaleString()}
          </div>  
          <div className="contractsSuperSmall" >
            { contract.type != 'PERPETUITY'
              ? contract.totalCost && contract.totalPaid ? (Math.round(contract.totalPaid * 100 / contract.totalCost)) + '%':'0%'
              : contract.ccy +' '+ contract.totalPaid.toLocaleString() }
          </div>  
          <div className="contractsSuperSmall" >
            { status }
          </div>
        </div>
    }
    else if (this.props.contractStatus =='Approved' ){
      navigate = 
        <div>
          <div className="contractsSmall onlyLarge" >
            {submissionDate}
          </div>  
          <div className="contractsSmall" >
            {creditDecisionDate}
          </div>              
          <div className="contractsExtraSmall onlyLarge"  >
            {contract.onboardingStatus}
          </div>
          { contract.agent
            ? <div className="contractsSmall active onlyLarge" onClick={()=>this.handleNext('agent/id='+contract.agent.agentNumber)}>
               { aFirstName } { aLastName }
              </div>  
            : <div className="contractsSmall"> - </div> }
        </div>
    }
    else {
      navigate = 
        <div>
          <div className="contractsSmall onlyLarge" >
            {submissionDate}
          </div>  
          <div className="contractsSmall" >
            {contract.onboardingStatus === 'Signed'? contract.status: contract.onboardingStatus}
          </div>
          { contract.agent 
            ? <div className="contractsSmall active onlyLarge"  onClick={()=>this.handleNext('agent/id='+contract.agent.agentNumber)}>
                { aFirstName } { aLastName }
              </div>  
            : <div className="contractsSmall" > - </div> }
          <div className="contractsExtraSmall active" onClick={()=>this.handleSelect()}>
            { this.props.selectedContracts.indexOf(contract._id) > -1 
              ? <i className="fas fa-check-square"></i>
              : <i className="far fa-check-square"></i> }              
          </div>  
        </div>
    }

    let firstName = ''
    let lastName = ''
    let profile = contract.client? contract.client.profile : undefined
    if( profile && profile.firstName ) {
      firstName = profile.firstName.toLowerCase();
      firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    }
    if( profile && profile.lastName ) {
      lastName = profile.lastName.toLowerCase();
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    }
    
    return (
      <div className="contractItem">
        <div className="contractsNumber active" onClick={()=>this.handleNext('/contract/id='+contract.contractNumber)}>
          { contract.contractNumber }
        </div>  
        <div className="contractsDeal onlyLarge" >
          { contract.dealName
            ? contract.dealName 
            : contract.deal? contract.deal.dealName : '-' }
        </div>
        <div className="contractsExtraSmall onlyLarge" >
          { contract.type? contract.type:'-' }
        </div> 
        <div className="contractsEntity onlyLarge" >
          { contract.entity
            ? contract.entity.name? contract.entity.name : '-'
            : contract.country? contract.country : '-'
          }
        </div>                
        <div className="contractsMedium active" onClick={()=>this.handleNext('client/id='+contract.client.clientNumber)}>
          { firstName } { lastName }
        </div>   

        { navigate }  

        <div className="contractsDate">
          { lastStatusUpdate }
        </div>
        
        { this.props.contractStatus==='Signed'
          ? <div className="contractsDate" >
              { nextUpdate }
            </div>
          : null }      
      </div>
    )
  }  
}
 
export const SelectableContractsListItem = connect(
  function mapStateToProps(state) {
    return { 
      selectedContracts: state.selectedContracts
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToContractList: contract => dispatch(addToContractList(contract)),
      removeFromContractList: contract => dispatch(removeFromContractList(contract))
    };
  },
)(ContractsListItem);