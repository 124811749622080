import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import AuxiliaryPricingsList from './List/List'

class AuxiliaryPricingsController extends Component {
  constructor(props) {
		super(props)
		this.state = {};
  }

  componentDidMount() {
    this.getList();
    window.scrollTo(0, 0);
  }
   
	getList = () => {
     axios
      .post(API_URL + '/auxiliaries/search-pricing/' + this.props.user.supplier, {})
      .then((ret) => this.setState({ auxiliaryPricings: ret.data.filter( elem => elem.auxiliaries) }))
  }

  render() {    
    let auxiliaryPricings = this.state.auxiliaryPricings

    return (
      <div >  
        
        { auxiliaryPricings != undefined
          ? <div id="auxiliaryPricingsList">
              <AuxiliaryPricingsList auxiliaryPricings={auxiliaryPricings} user={this.props.user } refresh={this.getList} />
            </div>
          : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }

      </div>
    );
  }
}

export default AuxiliaryPricingsController