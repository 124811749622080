import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { updatePassword } from '../../Actions/auth';
import { Carousel } from 'react-bootstrap/lib';
import './auth.css';
import Background2 from './Background2.jpg'
import Background3 from './Background3.jpg'
import Background0 from './Background0.jpg'
import Background5 from './Background5.jpg'

const form = reduxForm({
  form: 'updatePassword',
});

class UpdatePassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      request: {
        oldPassword: '',
        newPassword: '',
      },
      confirmPassword: ''
    }
  }

  handleSubmit(e) {    
    e.preventDefault();
    this.props.updatePassword(this.state.request.oldPassword, this.state.request.newPassword);
  }

  renderAlert(){

  }

  render() {

    return (
      <div className="carMain">

        <div id="login_zone">
          <div id="loginHeader" >Login credentials</div>
          <form id="loginForm"  onSubmit={ (e) => this.handleSubmit(e) }>
              <p className="text-center"> {this.renderAlert()}</p>

              <label id="donate_label">&nbsp; Temporary password</label>
              <input 
                onChange={ (e) => { 
                  let request = Object.assign({}, this.state.request); 
                  request.oldPassword = e.target.value; 
                  this.setState({request}) 
                  }} 
                className="form-control" 
                id="a" 
                component="input" 
                type="password" 
                style={{
                  height:"35px", 
                  marginBottom: "5px",
                  border: "0.5px solid white",
                  paddingLeft: "15px",
                  fontSize: "14px"
                }}
              />

              <label id="donate_label">&nbsp; New password</label>
              <input 
                onChange={ (e) => { 
                  let request = Object.assign({}, this.state.request); 
                  request.newPassword = e.target.value; 
                  this.setState({request}) 
                }} 
                className="form-control" 
                id="b" 
                component="input" 
                type="password"  
                style={{
                  height:"35px", 
                  marginBottom: "5px",
                  border: "0.5px solid white",
                  paddingLeft: "15px",
                  fontSize: "14px"
                }}
              />

              <label id="donate_label">&nbsp; Confirm password</label>
              <input 
                onChange={ (e) => this.setState({confirmPassword: e.target.value})} 
                className="form-control" 
                id="c" 
                component="input" 
                type="password" 
                style={{
                  height:"35px", 
                  marginBottom: "5px",
                  border: "0.5px solid white",
                  paddingLeft: "15px",
                  fontSize: "14px"
                }}
              />

              <button type="submit" id="login_button">Submit</button>

            </form>
        </div>
            
        <Carousel controls={false} interval={12000} slide={true} indicators={false}> 
        <Carousel.Item>
            <img className="carItem responsive" alt="900x500" src={Background2} />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carItem responsive" alt="900x500" src={Background0} />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carItem responsive" alt="900x500" src={Background5} />
          </Carousel.Item> 
          <Carousel.Item>
            <img className="carItem responsive" alt="900x500" src={Background3} />
          </Carousel.Item>             
        </Carousel>;

      </div>    
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
    reset: state.auth.reset
  };
}

export default connect(mapStateToProps, { updatePassword })(form(UpdatePassword));