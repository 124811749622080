import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { Button, FormControl } from 'react-bootstrap/lib';
import './../search.css';
import { connect } from 'react-redux';
import ContractsList from './../../Contracts/List/ContractsList'
import { addToContractList, emptyList } from './../../../../Actions/index';
import { findAgents } from  './../../../../services/AgentService'
import { findDeals } from  './../../../../services/DealService'
import { findProducts } from  './../../../../services/ProductService'

var DatePicker = require("react-bootstrap-date-picker");
var startOfDay = require("date-fns/start_of_day")
var endOfDay = require("date-fns/end_of_day")

class SearchFonction extends Component {
  constructor(props) {
		super(props)
		this.state = {
      query: {},
      processing: false,
      allSelected: false,
      page: 1,
      pages: 1,
      total: 0,
    };
  }
  componentDidMount() {
    this.loadData()
  }


  loadData() {
    this.getAgents();
    this.getDeals();
    this.getProducts();
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  getDeals = async () => {
    try {
      let deals = await findDeals( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ deals })
    } catch(err){ console.log(err) }
  }

  componentWillUnmount(){
    this.props.emptyList()
  }

  getProducts = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products })
    } catch(err){ console.log(err) }
  }


  selectAll(){
    if(this.state.contracts){
      this.setState({ allSelected: !this.state.allSelected})
      this.state.contracts.forEach( contract => {
        if(this.props.selectedContracts.indexOf(contract._id)<0){
          this.props.addToContractList(contract._id)
        }
      })
    }
  }

  deselectAll(){
    this.setState({ allSelected: false })
    this.props.emptyList()
  }

  getResults(fromPage){
    var body = {
      query: this.state.query,
      user: this.props.user._id,
      fromPage,
      pageSize: 200
    }
    axios.post(API_URL + '/contracts/get/'+this.props.user.supplier, body)
    .then((ret) => {
      this.setState({
        contracts: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total  
      })
    })
  }

  downloadApplications(){
    this.setState({ processing: true })
    var body = { 
      query: this.state.query,
      user: this.props.user._id,
      export: true,
      pending: true
    }
    axios
      .put(API_URL + '/creditscores/search/' + this.props.user.supplier, body )
      .then(response => {
          const url = window.URL.createObjectURL( new Blob([response.data], {type: 'text/csv'}) );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'applications.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.state.query.export = false
      })  
  }

  download(){
    this.setState({ processing: true })
    var body = { 
      query: this.state.query,
      user: this.props.user._id,
      export: true
    }
    axios.put(API_URL + '/contracts/search/' + this.props.user.supplier, body)
      .then(response => {
        const url = window.URL.createObjectURL( new Blob([response.data], {type: 'text/csv'}) );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'contracts.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.state.query.export = false
      })  
  }

  render() {    
    let { page, pages, deals, products, agents } = this.state

    let mappedDeals = deals && deals.map((deal, index) =>{ 
      return (
        <option key={index} value={deal._id}>{deal.dealName}</option>
      )
    })

    let mappedProducts = products && products.map((product, index) =>{ 
      return (
        <option key={index} value={product._id}>{product.name} by {product.manufacturer}</option>
      )
    })

    let mappedAgents = agents && agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
      return (
        <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
      )
    })

    return (
      <div>  
        <div className="activeSearchSection">   
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Contract number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.contractNumber = e.target.value; this.setState({ query }) }}
          />    
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.status = e.target.value; this.setState({ query }) }}
            >
            <option value="">Choose status</option>
            <option value="ENABLED">Enabled</option>
            <option value="PAIDOFF">Paid off</option>
            <option value="REPOSSESSED">Repossessed</option>
            <option value="LOCKED">Locked</option>
            <option value="WRITEOFF">Written off</option>
          </FormControl>
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.onboardingStatus = e.target.value; this.setState({ query }) }}
            >
            <option value="">Choose onboarding status</option>
            <option value="Signed">Signed</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
            <option value="Pending">Pending</option>
          </FormControl>
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Signed after"
              value={this.state.signingDateMin}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.signingDate = { $gte: startOfDay(new Date(e)) }; 
                if( this.state.signingDateMax ) query.signingDate = { $gte: startOfDay(new Date(e)), $lte: endOfDay(new Date(this.state.signingDateMax)) }
                this.setState({ query, signingDateMin: e }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>   
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Signed before"
              value={this.state.signingDateMax}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.signingDate = { $lte: endOfDay(new Date(e)) }; 
                if( this.state.signingDateMin ) query.signingDate = { $lte: endOfDay(new Date(e)), $gte: startOfDay(new Date(this.state.signingDateMin)) }
                this.setState({ query, signingDateMax: e }) 
              }}            
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>                   
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.agent = e.target.value; this.setState({ query }) }}
            >
            <option value="">Resp.agent name</option>
            {mappedAgents}
          </FormControl>
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Resp. agent number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.agentNumber = e.target.value; this.setState({ query }) }}
          />   
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Unit payg number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.paygNumber = e.target.value; this.setState({ query }) }}
          />  
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.deal = e.target.value; this.setState({ query }) }}
            >
            <option value="">Select a deal</option>
            {mappedDeals}
          </FormControl>
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.product = e.target.value; this.setState({ query }) }}
            >
            <option value="">Select a product</option>
            {mappedProducts}
          </FormControl>

          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Last update before"
              value={this.state.lastUpdateBefore}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.lastStatusUpdate = { $lte: endOfDay(new Date(e)) }; 
                if( this.state.lastUpdateAfter ) query.lastStatusUpdate = { $lte: endOfDay(new Date(e)), $gte: startOfDay(new Date(this.state.lastUpdateAfter)) }
                this.setState({ query, lastUpdateBefore: e }) 
              }}               
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>   
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Last update after"
              value={this.state.lastUpdateAfter}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.lastStatusUpdate = { $gte: startOfDay(new Date(e)) }; 
                if( this.state.lastUpdateBefore ) query.lastStatusUpdate = { $gte: startOfDay(new Date(e)), $lte: endOfDay(new Date(this.state.lastUpdateBefore)) }
                this.setState({ query, lastUpdateAfter: e }) 
              }}  
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>   
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Next update before"
              value={this.state.nextUpdateBefore}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.lastStatusUpdate = { $lte: endOfDay(new Date(e)) }; 
                if( this.state.lastUpdateAfter ) query.lastStatusUpdate = { $lte: endOfDay(new Date(e)), $gte: startOfDay(new Date(this.state.lastUpdateAfter)) }
                this.setState({ query, nextUpdateBefore: e }) 
              }}              
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>   
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Next update after"
              value={this.state.nextUpdateAfter}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.nextStatusUpdate = { $gte: startOfDay(new Date(e)) }; 
                if( this.state.nextUpdateBefore ) query.nextStatusUpdate = { $gte: startOfDay(new Date(e)), $lte: endOfDay(new Date(this.state.nextUpdateBefore)) }
                this.setState({ query, nextUpdateAfter: e }) 
              }}              
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>     
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Entered before"
              value={this.state.entryDateBefore}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.entryDate = { $lte: endOfDay(new Date(e)) }; 
                if( this.state.nextUpdateAfter ) query.entryDate = { $lte: endOfDay(new Date(e)), $gte: startOfDay(new Date(this.state.nextUpdateAfter)) }
                this.setState({ query, entryDateBefore: e }) 
              }}              
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>    
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Entered after"
              value={this.state.entryDateAfter}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.entryDate = { $gte: startOfDay(new Date(e)) }; 
                if( this.state.entryDateBefore ) query.entryDate = { $gte: startOfDay(new Date(e)), $lte: endOfDay(new Date(this.state.entryDateBefore)) }
                this.setState({ query, entryDateAfter: e }) 
              }}              
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>  

          <FormControl
            type="text"
            className="searchInput"
            placeholder="Payroll number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.payrollNumber = e.target.value; this.setState({ query }) }}
          />  
        
          <div id="searchZone">
            
            <Button id="searchButton" onClick={()=>this.getResults(this.state.page)}>Search</Button>

            { this.props.selectable || ( this.props.options && ( this.props.options.export == false || this.props.options.locked ) )
              ? null
              : <Button id="searchButton" className="downloadButton" onClick={()=>this.download(this.state.query)} disabled={ this.state.processing }>
                  { this.state.processing
                    ? <i className="fas fa-clock"></i> 
                    : <i className="fas fa-file-download"></i> }
                </Button> }
            
            { this.props.options && ( this.props.options.export == false || this.props.options.locked ) || this.props.selectable
              ? null
              : <Button id="searchButton" onClick={()=>this.downloadApplications(this.state.query)} disabled={ this.state.processing }>
                  { this.state.processing? 'Processing...' : 'With credit' }
                </Button> }


            { this.state.allSelected
              ? <div id="bulkSection" className="onlyLarge" onClick={()=>this.deselectAll()}>
                  <i className="fas fa-eraser"></i>
                </div>
              : <div id="bulkSection" className="onlyLarge"  onClick={()=>this.selectAll()}>
                  <i className="fas fa-check-double"></i>
                </div> }

          </div>
        </div>

        { this.state.contracts && this.state.contracts.length > 0 &&
          <div id="resultHeader">
            <div className="contractNavigator">
              <div className="navButton" onClick={() => {
                this.setState({ page: 1 })
                this.getResults( 1 )
              }}>
                <i className="fas fa-angle-double-left"></i>
              </div>
              <div className="navButton" onClick={() => {
                this.setState({ page: Math.max( 1, page -1 ) })
                this.getResults( Math.max( 1, page -1 ) )
              }}>
                <i className="fas fa-angle-left"></i>
              </div>
              <div className="navLegend">{ this.state.total } contracts</div>
              <div className="navPage">page {page} / {pages} </div>
              <div className="navButton" onClick={() => {
                this.setState({ page: Math.min( pages, page + 1 )})
                this.getResults( Math.min( pages, page + 1 ) )
              }}>
                <i className="fas fa-angle-right"></i>
              </div>
              <div className="navButton" onClick={() => { 
                this.setState({ page: pages })
                this.getResults( pages )
              }}>
                <i className="fas fa-angle-double-right"></i>
              </div>
            </div>
          </div> } 

        <div id="searchOutcome">
          { this.state.contracts &&
            <ContractsList contracts={ this.state.contracts } selectable={true}/>}
        </div>

      </div>
    );
  }
}


export const SearchContracts = connect(
  function mapStateToProps(state) {
    return { 
      selectedContracts: state.selectedContracts,
      search: state.search
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToContractList: contract => dispatch(addToContractList(contract)),
      emptyList: () => dispatch(emptyList())    
    };
  },
)(SearchFonction);
