import React, { Component } from 'react';
import history from './../../../../history';
import './users.css';
import UserAction from './../UsersAction/UserAction'

export class UsersListItem extends Component {
  
  constructor(props) {
		super(props)
		this.state = {
      showModal: false,
    };
  }

  handleNext(path){
    history.push(path)
  }

  close() {
    this.setState({ showModal: false });
    this.props.refresh()
  }  

  render() {
    let user = this.props.user
    
    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let createdAt = new Date(user.createdAt)
    createdAt = createdAt.toLocaleDateString("en-EN", options)

    let latestConnexion = '-'
    if(user.latestConnexion){
      latestConnexion = new Date(user.latestConnexion)
      latestConnexion = latestConnexion.toLocaleDateString("en-EN", options)
    }

    return (
      <div className="userItem">
        <div id="usersMedium" className="onlyLarge">
          { user.entities && user.entities[0]
            ? user.entities[0].name? user.entities[0].name: '-'
            : 'Global' }
        </div>
        <div id="usersMedium" className="onlyLarge" >
          { user.userNumber?user.userNumber:'-' }
        </div> 
        <div id="usersMedium" >
          { user.profile?user.profile.firstName:'-' }
        </div>  
        <div id="usersMedium" >
          { user.profile?user.profile.lastName:'-' }
        </div>
        <div id="usersLarge" >
          { user.email }
        </div>
        <div id="usersMedium" className="onlyLarge">
          { user.username? user.username: '-' }
        </div>
        <div id="usersMedium" className="onlyLarge">
          { user.role }
        </div>
        <div id="usersMedium" className="onlyLarge">
          { createdAt }
        </div>  
        <div id="usersMedium" >
          { latestConnexion }
        </div>  
        <div id="usersSmall" className="edit onlyLarge" onClick={()=>this.setState({showModal: !this.state.showModal})}>
          <i className="fas fa-edit"></i>
        </div> 

        { this.state.showModal &&
          <UserAction 
            show={ this.state.showModal } 
            onHide={ this.close.bind(this) }
            user={ user }
            activeUser={ this.props.activeUser }
            viewerRole={ this.props.viewerRole }
            edit={true}
          />  }
      
      </div>
    )
  }  
}