import React, { Component } from 'react';
import AnalyticsMenu from '../AnalyticsMenu'
import Gender from './Graphs/gender';
import Age from './Graphs/age';
import history from '../../../history'

class ClientAnalysisController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      granularity: 'daily',
    };
  }
  
  goBack() {
    window.history.back();
  }

  handleNext(path){
    history.push(path)
  }
  
  render() {    
    let { user, filter } = this.props

    return (
      <div className='background'>
        <div id="pageTitle">Client population analysis</div>
        <AnalyticsMenu user={ user}/>
        <div id="clientBackground">
          <div id="dash_menu">  
            <div id="dash_menu_item"  onClick={()=>this.setState({granularity: 'daily'})} className={this.state.granularity == 'daily'?"active_dash_menu leftMenu rightMenu":"leftMenu rightMenu"}>Overview</div>
            <div className="contractBack" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
          </div>

          <div id="graphSection">     
            <Gender 
              user={ user } 
              filter={ filter }
              color= {'rgba(255, 255, 255, 0.7)'}
              width={ 100 }
              height={ 350 }
            />
          </div>
          
          <div id="graphSection">     
            <Age 
              user={ user } 
              filter={ filter }
              color= {'rgba(255, 255, 255, 0.7)'}
              width={ 100 }
              height={ 350 }
            />
          </div>

        </div>
      </div>
    );
  }
}

export default ClientAnalysisController