import React from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import './../auxiliaryPricing.css'

class AddStandalonePricing extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      auxiliaryPricing: {
        auxiliaries: [],
        supplier: this.props.user.supplier
      },       
      disabled: false
    };
  } 


  componentDidMount() {
    this.getAuxiliaries()
  }

  getAuxiliaries = () => {
    axios.post(API_URL + '/auxiliaries/search/'+ this.props.user.supplier, {})
    .then( ret => this.setState({ auxiliaries: ret.data }) )
  }

  create() {
    axios.post(API_URL + '/auxiliaries/create-pricing/'+ this.props.user.supplier, 
        this.state.auxiliaryPricing
      ).then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        console.log(err)
        this.setState({status: 'Error'})
      }); 
  }
  
  over(){
    this.props.onHide();
    this.setState({
      auxiliaryPricing: {},       
      status: undefined,
      disabled: false
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }  

  render() {
    let { auxiliaryPricing, auxiliaries } = this.state
    let { user, auxiliaryPricings } = this.props

    let mappedAuxiliaries = auxiliaries && auxiliaries.map( aux =>{ 
      return (
        <option key={aux._id} value={aux._id}>{ aux.product.name } -- { aux.name }</option>
      )
    })
    
    let ccyOptions = user.options && user.options.ccys.map( (ccy, index) =>{ 
      return ( <option key={index} value={ccy}>{ccy}</option> )
    })

    let refRefused = undefined
    if( auxiliaryPricing.reference  && auxiliaryPricing.reference.length !== 4 ) refRefused = 'Reference must be 4-character long'
    if( 
      auxiliaryPricing.reference && 
      auxiliaryPricings.find( elem => elem.reference == auxiliaryPricing.reference )
    ) refRefused = 'Reference must be unique'
    if( auxiliaryPricing.reference && !auxiliaryPricing.reference.charAt(0).match(/[A-Z]/g) ) refRefused = 'Reference must start with a letter'
    let isDisabled = ( 
      !auxiliaryPricing.cost ||
      !auxiliaryPricing.totalCost ||
      !auxiliaryPricing.ccy ||
      auxiliaryPricing.auxiliaries.length === 0 ||
      refRefused
    )? true : false


    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="medium"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">
            Create new standalone pricing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">

            <div className="itemPricingLabal">Auxiliary</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="itemPricingInputLong"
              onChange={(e)=>{ 
                let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); 
                auxiliaryPricing.auxiliaries.push( e.target.value );
                let chosenAux = auxiliaries.find( elem => elem._id + '' == e.target.value )
                auxiliaryPricing.product = chosenAux.product._id;
                this.setState({ auxiliaryPricing });
              }}
            >
              <option value="">Select one</option>
              { mappedAuxiliaries }
            </FormControl>

            <div className="itemPricingLabal">Currency</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="itemPricingInput"
              onChange={(e)=>{ 
                let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); 
                auxiliaryPricing.ccy = e.target.value;
                this.setState({ auxiliaryPricing });
              }}
            >
              <option value="">Select one</option>
              { ccyOptions }
            </FormControl>

            <div className="itemPricingLabal">Reference</div>
            <FormControl 
               type="text" 
               className="itemPricingInput"
               value={ auxiliaryPricing.reference }
               placeholder={'Ref (Optional)'}
               onChange={(e)=>{ 
                let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing);
                auxiliaryPricing.reference = e.target.value.toUpperCase(); 
                this.setState({ auxiliaryPricing }) 
              }}
             
            />

            { refRefused && <div id="productWarning" style={{marginBottom: '5px'}}>{refRefused}</div> }

            { auxiliaryPricing.ccy && auxiliaryPricing.auxiliaries.length > 0 &&
              <div>
                <div className="itemPricingLabal">Upfront cost</div>
                <FormControl 
                  type="text" 
                  className="itemPricingInput"
                  value={ auxiliaryPricing.cost || auxiliaryPricing.cost == ''
                  ? auxiliaryPricing.ccy + ' ' + auxiliaryPricing.cost
                  : auxiliaryPricing.ccy + ' ' + 0 
                }
                  onChange={(e)=>{ 
                    let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); 
                    auxiliaryPricing.cost = e.target.value.replace( /^\D+/g, ''); 
                    auxiliaryPricing.totalCost = e.target.value.replace( /^\D+/g, ''); 
                    this.setState({ auxiliaryPricing }) 
                  }}
                />   
                <div className="itemPricingLabal">Total cost</div>
                <FormControl 
                  type="text" 
                  className="itemPricingInput"
                  value={ auxiliaryPricing.totalCost || auxiliaryPricing.totalCost == ''
                  ? auxiliaryPricing.ccy + ' ' + auxiliaryPricing.totalCost
                  : auxiliaryPricing.ccy + ' ' + 0 
                }
                  onChange={(e)=>{ 
                    let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); 
                    auxiliaryPricing.totalCost = e.target.value.replace( /^\D+/g, ''); 
                    this.setState({ auxiliaryPricing }) 
                  }}
                /> 

                <div className="itemPricingLabal">Name</div>
                <FormControl 
                  type="text" 
                  className="itemPricingInputLong"
                  value={ auxiliaryPricing.name }
                  placeholder={'Name (Optional)'}
                  onChange={(e)=>{ let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); auxiliaryPricing.name = e.target.value; this.setState({ auxiliaryPricing }) }}
                />

                <div className="itemPricingLabal">Description</div>
                <textarea 
                  type="textera" 
                  className="itemPricingInputLong highInput"
                  placeholder={'Short description (Optional)'}
                  onChange={(e)=>{ let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); auxiliaryPricing.description = e.target.value; this.setState({ auxiliaryPricing }) }}
                />

              </div> }

            { this.renderMessage() }

            <div id="modal_action">
              { this.state.status !== 'Success' &&
                <Button id="action_signup" disabled={isDisabled} onClick={this.create.bind(this)}>Create</Button> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddStandalonePricing;