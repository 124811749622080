import React, { Component } from 'react';
import axios from 'axios';
import { API_URL, emptyList } from './../../../Actions/index';
import { FormControl } from 'react-bootstrap/lib';
import ContractsList from './List/ContractsList'
import ManageMenu from './../ManageMenu'
import { connect } from 'react-redux';
import ApproveContract from './Action/ApproveContract'
import { findDeals } from  './../../../services/DealService'
import { hasPermission } from '../../../services/UserService'
import { SelectAgent } from './../Agents/Action/SelectAgent'

class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      contractStatus: 'Signed',
      showModal: false,
      page: 1,
      pages: 1,
      total: 0,
    };
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0)
  }

  handleSelect(contractStatus){
    this.setState({ contractStatus, status: undefined, contracts: undefined }, 
      () => this.getContractsList(contractStatus, undefined, this.state.deal, 1, this.state.agent) );
    this.props.emptyList()
  }

	getContractsList(onboardingStatus, status, deal, fromPage, agent) {
    let query = {
      onboardingStatus,
      status,
      agent,
      dealName: deal,
    }
    var body = {
      query,
      fromPage,
      pageSize: 50,
      user: this.props.user._id
    }
    axios.post(API_URL + '/contracts/get/' + this.props.user.supplier, body)
			.then((ret) => this.setState({ 
        contracts: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total  
      }) )
  } 	

  getDeals = async () => {
    try {
      let deals = await findDeals( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ deals })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getContractsList('Signed', undefined, this.state.deal, 1, this.state.agent);
    this.getDeals()
  }
  
  close() {
    this.setState({ showModal: false, status: undefined });
    this.getContractsList('Pending', undefined, this.state.deal, 1, this.state.agent);
    this.props.emptyList()
  }

  open(action){
    this.setState({ showModal: true, action: action });
  }

  search(){
    let query = {
      contractNumber: this.state.contractNumber
    }
    if( this.state.contractNumber ){
      axios.put(API_URL + '/contracts/search/' + this.props.user.supplier, { query })
        .then((ret) => this.setState({ contracts: ret.data }) )
    }
  }

  selectAgent = (selection) => {
    let agent = selection? selection: undefined
    this.setState({ agent });
    this.getContractsList( this.state.contractStatus, this.state.status, this.state.deal, 1, agent )
  }

  render() { 

    let navigate = null;
    let user = this.props.user

    let permission = hasPermission( user, undefined, 'approveClient' )

    let { deals, contractStatus, page, pages, status, agent, deal, contracts } = this.state

    let mappedDeals = deals && deals.map( deal =>{ 
      return <option className='agentOption' key={deal._id} value={deal.dealName}>{deal.dealName}</option>
    })
    
    if( contractStatus == 'Pending' && permission ){
      navigate =     
        <div>        
          <div id="accept" onClick={()=>this.open('Approve')}>Approve</div>
          <div id="refuse" onClick={()=>this.open('Reject')} >Reject</div>
        </div>
    }

    return (
      <div className='background'>   

        <div id="pageTitle">Contracts</div>
        <ManageMenu user={ user }/>

        <div id="clientBackground">
          
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Signed')} className={contractStatus==='Signed'?"active_clientSubMenu leftMenu":"leftMenu"}>Signed</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Approved')} className={contractStatus==='Approved'?"active_clientSubMenu":""}>Approved</div>
            <div id="clientSubMenu_item"  onClick={()=>this.handleSelect('Pending')} className={contractStatus==='Pending'?"active_clientSubMenu":""}>Pending</div>
            <div id="clientSubMenu_item"  onClick={()=>this.handleSelect('Rejected')} className={contractStatus==='Rejected'?"active_clientSubMenu rightMenu":"rightMenu"}>Rejected</div>
            
            <div className="quickSearch onlyLarge">
              <FormControl
                type="text"
                className="quickSearchInput"
                placeholder="Search by number"
                onChange={(e)=> this.setState({ contractNumber: e.target.value }) }
              />
              <div className="searchAction" onClick={ ()=>this.search()}>
                <i className="fas fa-search"></i>
              </div>
            </div>
            

            <div className='onlyLarge'>

              <div id="contractSelectorWrapper" className='cStatus'>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="assetSelector"
                  onChange={ (e) => {
                    this.setState({ deal: e.target.value });
                    this.getContractsList( contractStatus, status, e.target.value, 1, agent )
                  }}
                >
                  <option value={undefined} className='agentOptionLight'>{ deal ? "Remove filter": "Filter on deal" }</option>
                  { mappedDeals }
                </FormControl>
              </div>  
              { contractStatus == 'Signed'
                ?  <div id="contractSelectorWrapper" className='cStatus'>
                    <FormControl 
                      controlId="formControlsSelect"
                      componentClass="select" 
                      className="assetSelector"
                      onChange={ (e) => {
                        this.setState({ status: e.target.value });
                        this.getContractsList(contractStatus, e.target.value, deal, 1, agent)
                      }}
                    >
                      <option value={undefined} className='agentOptionLight'>{ status ? "Remove filter" : "Filter on status" }</option>
                      <option value="PAIDOFF" className='agentOption'>Paid-off</option>
                      <option value="ENABLED" className='agentOption'>Enabled</option>
                      <option value="LOCKED" className='agentOption'>Locked</option>
                      <option value="REPOSSESSED" className='agentOption'>Repossessed</option>
                      <option value="WRITEOFF" className='agentOption'>Write-off</option>
                    </FormControl>
                  </div>
                : null }

              <div id="contractSelectorWrapper" className='cStatus'>
                <SelectAgent
                  selectAgent={ this.selectAgent } 
                  format={ "assetSelector" }
                  dropDownFormat={ "agentOption" }
                  supplierId={ user.supplier }
                  userId={ user._id }
                  title={ "Filter on agent" }
                />
              </div>  

            </div>

            { navigate }
          </div>

          { contracts && contracts.length > 0 &&
            <div id="contractsList">
              <div className="contractNavigator">
                <div className="navButton" onClick={() => {
                  this.setState({ page: 1 })
                  this.getContractsList(contractStatus, status, deal, 1, agent)
                }}>
                  <i className="fas fa-angle-double-left"></i>
                </div>
                <div className="navButton" onClick={() => {
                  this.setState({ page: Math.max( 1, page -1 ) })
                  this.getContractsList(contractStatus, status, deal, Math.max( 1, page -1 ), agent)
                }}>
                  <i className="fas fa-angle-left"></i>
                </div>
                <div className="navLegend">{ this.state.total } contracts</div>
                <div className="navPage">page {page} / {pages} </div>
                <div className="navButton" onClick={() => {
                  this.setState({ page: Math.min( pages, page + 1 )})
                  this.getContractsList(contractStatus, status, deal, Math.min( pages, page + 1 ), agent )
                }}>
                  <i className="fas fa-angle-right"></i>
                </div>
                <div className="navButton" onClick={() => { 
                  this.setState({ page: pages })
                  this.getContractsList(contractStatus, status, deal, pages, agent)
                }}>
                  <i className="fas fa-angle-double-right"></i>
                </div>
              </div>
            </div>
          } 

          { contracts 
            ? <div id="contractsList">
                <ContractsList 
                  contracts={contracts} 
                  contractStatus={contractStatus} 
                  refresh={this.getContractsList}
                />
              </div>
            : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }

        </div>

        { this.state.showModal &&
          <ApproveContract 
            show={this.state.showModal} 
            onHide={this.close.bind(this)}
            modalStatus={undefined}
            selectedContracts={this.props.selectedContracts}
            action={this.state.action}
            user={ user} 
          /> 
        }

      </div>
    );
  }
}

const ContractsController = connect(
  function mapStateToProps(state) {
    return { 
      selectedContracts: state.selectedContracts
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Controller);

export default ContractsController