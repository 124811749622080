import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';
import { findAgents } from  '../../../../services/AgentService'

import './../item.css'

class AddItem extends Component {

  constructor(props) {
    super(props);
    this.state= {
      item: {
        type: this.props.type,
        supplier: this.props.user.supplier,
        "tracking.enteredBy": this.props.user._id
      },       
      disabled: false
    }
  }; 

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }

  componentDidMount() {
    this.getAgents()
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }
  
  createItem() {
    this.setState({disabled: true})
    axios.post(API_URL + '/items/create/'+ this.props.user.supplier, this.state.item)
      .then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        this.setState({status: err.data.message })
      }); 
  }

  render() {    
 
    let isDisabled = ( 
      !this.state.item.auxiliary || 
      !this.state.item.quantity || 
      !this.state.item.status || 
      !this.state.item.quality || 
      !this.state.item.agent 
      )? true : false
    if( this.state.disabled ) isDisabled = true

    let mappedAux = this.props.auxiliaries && this.props.auxiliaries
      .sort((a, b) => a.product.name.localeCompare(b.product.name))
      .map( aux =>{ 
        return (
          <option key={aux._id} value={aux._id} >{ aux.product.name } -- { aux.name }</option>
        )
      })

    let mappedAgents = this.state.agents && this.state.agents
      .filter(agent => this.state.item.status != 'INSTOCK' || agent.role == 'Administrator')
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return (
          <option key={agent._id} value={ agent._id }>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">Create a new item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="modalBody">
            
            <div className="addItemLabel">Auxiliary</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="addItemInput"
              onChange={(e)=>{ 
                let item = Object.assign({}, this.state.item); 
                item.auxiliary = e.target.value; 
                this.setState({ item }) 
              }}
              > 
              <option value="">Auxiliary</option>
              { mappedAux }
            </FormControl>

            <div className="addItemLabel">Status</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="addItemInput addItemShort"
              onChange={(e)=>{ let item = Object.assign({}, this.state.item); item.status = e.target.value; this.setState({ item }) }}
              > 
              <option value="">Status</option>
              <option value="INSTOCK">In stock</option>
              <option value="WITH_AGENT">With agent</option>
              <option value="DEPLOYED">Deployed</option>
              <option value="LOST">Lost</option>
            </FormControl>

            <div className="addItemLabel addLabelShort">Quality</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="addItemInput addItemShort"
              onChange={(e)=>{ let item = Object.assign({}, this.state.item); item.quality = e.target.value; this.setState({ item }) }}
              > 
              <option value="">Quality</option>
              <option value="New">New</option>
              <option value="SecondHand">Second hand</option>
              <option value="ToCheck">To check</option>
              <option value="Faulty">Faulty</option>
            </FormControl>

            <div className="addItemLabel addLabelShort">Quantity</div>
            <FormControl 
              type="number" 
              className="addItemInput addItemMargin"
              onChange={(e)=>{ let item = Object.assign({}, this.state.item); item.quantity = e.target.value; this.setState({ item }) }}
            />

            <div className="addItemLabel">Held by</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="addItemInput"
              onChange={(e)=>{ 
                let item = Object.assign({}, this.state.item); 
                item.agent = e.target.value; 
                this.setState({ item }) 
              }}
              > 
              <option value="">Agent</option>
              { mappedAgents }
            </FormControl>
              
            <textarea 
              type="textera" 
              className="productFull"
              placeholder={'Note (optional)'}
              onChange={(e)=>{ let item = Object.assign({}, this.state.item); item.description = e.target.value; this.setState({ item }) }}
            />

            {this.renderMessage()}  

            { this.state.status !== 'Success' &&
              <div id="productsExtraSmall2">
                <Button id="createProduct" disabled={isDisabled} onClick={()=>this.createItem(this.state.item)}>Create</Button>
              </div> }

          </div>
        
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddItem