import React, { Component } from 'react';
import { Item } from './Item'
import './entities.css'
import AddEntity from './../Action/AddEntity'

class List extends Component {
  constructor(props) {
		super(props)
		this.state = {
      create: false
    };
  }

  close(){
    this.setState({ create: false });
    this.props.refresh()
  }

  render() {    

    let { entities, user, type, refresh, select, entity, secondEntity } = this.props

    let mappedEntities = entities && entities
      .sort((a, b) => a.name.localeCompare(b.name))  
      .filter( elem => elem.type == type )
      .map( elem =>{ 
        return <Item entity={elem} selectedEntity={entity} key={ elem._id } user={ user } refresh={ refresh } select={ select }/>
      })

    if( entity && entity.type == 'Country'){
      mappedEntities = entities && entities
        .sort((a, b) => a.name.localeCompare(b.name))  
        .filter( elem => 
          elem.type == 'Organisation' && elem.country && elem.country._id+''== entity._id+''
        )
        .map( elem =>{ 
          return <Item entity={elem} key={ elem._id } user={ user } refresh={ refresh } select={ select }/>
        })
    }

    if( entity && entity.type == 'Organisation'){
      mappedEntities = entities && entities
        .sort((a, b) => a.name.localeCompare(b.name))  
        .filter( elem => 
          elem.type == 'Branch' && elem.organisation && elem.organisation._id+''== entity._id+''
        )
        .map( elem =>{ 
          return <Item entity={elem} key={ elem._id } user={ user } refresh={ refresh } select={ select } secondEntity={ secondEntity }/>
        })
    }

    if( entity && entity.type == 'Branch'){
      mappedEntities = entities && entities
        .sort((a, b) => a.name.localeCompare(b.name))  
        .filter( elem => 
          elem.type == 'Shop' && elem.branch && elem.branch._id+''== entity._id+''
        )
        .map( elem =>{ 
          return <Item entity={elem} key={ elem._id } user={ user } refresh={ refresh } select={ select }/>
        })
    }

    return (
      <div id="entitiesList">
        { mappedEntities }

        { !entity && ( !mappedEntities || mappedEntities.length === 0 ) && <div id="entityHeader">No { type } yet added </div> }

        { entity && ( !mappedEntities || mappedEntities.length === 0 ) && <div id="entityHeader">No { type } yet added to { entity.name }</div> }

        { ( 
            ['Admin', 'MainAdmin', 'Core'].indexOf( user.role ) > -1 ||
            ( user.role == 'CountryAdmin' && type !== 'Country' ) ||
            ( user.role == 'OrgAdmin' && ['Country', 'Organisation'].indexOf( type ) < 0 ) ||
            ( user.role == 'BranchAdmin' && ['Country', 'Organisation', 'Branch'].indexOf( type ) < 0 )
          ) &&
          <div className="entityAction" onClick={()=>this.setState({ create: !this.state.create })}>
            <i className="fas fa-plus-circle"></i>
          </div> }

        { this.state.create && 
          ( 
            ['Admin', 'MainAdmin', 'Core'].indexOf( user.role ) > -1 ||
            ( user.role == 'CountryAdmin' && type !== 'Country' ) ||
            ( user.role == 'OrgAdmin' && ['Country', 'Organisation'].indexOf( type ) < 0 ) ||
            ( user.role == 'BranchAdmin' && ['Country', 'Organisation', 'Branch'].indexOf( type ) < 0 )
          ) &&
          <AddEntity 
            user={ user }
            refresh={ refresh }
            country={ entity && entity.type == 'Country' ? entity : undefined }
            organisation={ entity && entity.type == 'Organisation' ? entity : undefined }
            branch={ entity && entity.type == 'Branch' ? entity : undefined }
            type={ type }
            show={ this.state.create } 
            onHide={this.close.bind(this)}
          /> }


      </div>
    );
  }
}

export default List