import React, { Component } from 'react';
import Event from './Event'
import './event.css'

class EventController extends Component {

  render() { 
    let events = this.props.events
    let mappedEvents = null;

    if(events){

      mappedEvents = events
      .sort( (a, b) => {
        return new Date(b.date) - new Date(a.date);
      }).map((event, index) => { 
        return (
          <Event event={event} key={index}/>
        )
      })
    }

    return (
      <div id="eventList">  
        <div id="eventListTitle">Summary of events</div>
        {mappedEvents} 
      </div>
    );
  }
}

export default EventController