import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { registerUser } from '../../Actions/auth';
import {Row, Col, Grid, FormGroup, ControlLabel, FormControl, Button} from 'react-bootstrap/lib';
import { I18n } from 'react-i18next';

class Register extends Component {

  constructor(props) {
    super(props);
    this.state= {
      user: {
        profile:{
          firstName: '',
          lastName:'',
        },
        password:'',
        email:'',
        role: "user",
      },      
      passwordConfirm:'',
      registerError: {
        lastName: false,
        firstName: false,
        email: false,
        password: false,
        passwordConfirm: false
      },
      loginError: {
        email: false,
        password: false
      },
      isValid: null,
      isEnabled: true,
      status: undefined
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSubmit() {
    if (this.registerValid()) {
      let user = Object.assign({}, this.state.user);
      let locale = navigator.language;

      if (locale && locale.length > 2) {
          locale = locale.substring(0, 2);
      }
      user.locale = locale;

      this.props.registerUser({user}, '/home');
      this.setState({status:'Success!'})
    }
  }

  loginValidate(auth) {

    let isValid = true;

    let loginError = {
      email: false,
      password: false
    };

    if (!auth.email || auth.email === '' ) {
      isValid = false;
      loginError.email = true;
    }

    if (!auth.password || auth.password === '' ) {
      isValid = false;
      loginError.password = true;
    }

    this.setState({loginError});

    return isValid;
  }

  registerValid() {
    let user = Object.assign({}, this.state.user);

    let registerError = {
      lastName: false,
      firstName: false,
      email: false,
      password: false,
      passwordConfirm: false
    };
    
    let isValid = true;
    if (!user.password || user.password.length < 5) {
      registerError.password = true;
      isValid = false;
    } 

    if (user.password !== this.state.passwordConfirm) {
      registerError.passwordConfirm = true;
      isValid = false;
    }
       
    if (!user.email && user.email.length < 1) {
      registerError.email = true;
      isValid = false; 
    }

    if (!user.profile.firstName && user.profile.firstName.length < 1) {
      registerError.firstName = true;
      isValid = false; 
    }
      
    if (!user.profile.lastName && user.profile.lastName.length < 1) {
      registerError.lastName = true;
      isValid = false; 
    }
      
    this.setState({registerError});
    
    return isValid;
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success!'?'successfulCreation':'unSuccessfulCreation'}>
          <p> {this.state.status}</p>
        </div>
      );
    }
  }   

  render() {

    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <div id="register_space">
            <form id="register_border">
              <p className="text-center">{this.props.message}</p>
              <p className="text-center">{t(this.props.errorMessage)}</p>
              <Row >
                <Col md={12}>
                  <FormGroup controlId="formControlsTextarea">
                    <ControlLabel className="designed_label">{t('REGISTER_FIRSTNAME')} (*)</ControlLabel>
                    <br/><p className="signin_error">{ this.state.registerError.lastName &&  <label>{t('DONATE_REGISTER_LASTNAME_ERROR')}</label> }</p>
                    <FormControl
                      type="text"
                      className="signin_input"
                      placeholder="Bruce"
                      onChange={(e) => { let user = Object.assign({}, this.state.user); user.profile.firstName = e.target.value; this.setState({ user }) }}
                    />
                  </FormGroup>
                  <FormGroup controlId="formControlsTextarea">
                    <ControlLabel className="designed_label">{t('REGISTER_LASTNAME')} (*)</ControlLabel>
                    <br/><p className="signin_error">{ this.state.registerError.lastName &&  <label>{t('DONATE_REGISTER_LASTNAME_ERROR')}</label> }</p>
                    <FormControl
                      type="text"
                      className="signin_input"
                      placeholder="Wayne"
                      onChange={(e) => { let user = Object.assign({}, this.state.user); user.profile.lastName = e.target.value; this.setState({ user }) }}
                    />
                  </FormGroup>
                  <FormGroup controlId="formControlsTextarea">
                    <ControlLabel className="designed_label">{t('REGISTER_EMAIL')}</ControlLabel>
                    <br/><p className="signin_error">{ this.state.registerError.email && <label>{t('DONATE_REGISTER_EMAIL_ERROR')}</label> }</p>
                    <FormControl
                      type="text"
                      className="signin_input"
                      placeholder="bruce.wayne@capitole.energie.fr"
                      onChange={(e) => { let user = Object.assign({}, this.state.user, { email: e.target.value }); this.setState({ user }) }}
                    />
                  </FormGroup>
                  <FormGroup controlId="formControlsTextarea" validationState={this.state.isValid}>
                    <ControlLabel className="designed_label">{t('REGISTER_PWD1')}</ControlLabel>
                    <br/><p className="signin_error">{ this.state.registerError.password && <label>{t('DONATE_REGISTER_PASSWORD_ERROR')}</label> }</p>
                    <FormControl
                      type="password"
                      className="signin_input"
                      placeholder="*****"
                      onChange={(e) => { let user = Object.assign({}, this.state.user, { password: e.target.value }); this.setState({ user }) }}
                    />
                  </FormGroup>
                  <FormGroup controlId="formControlsTextarea">
                    <ControlLabel className="designed_label">{t('REGISTER_PWD2')}</ControlLabel>
                    <br/><p className="signin_error">{ this.state.registerError.passwordConfirm && <label>{t('DONATE_REGISTER_PASSWORDCONFIRM_ERROR')}</label> }</p>
                    <FormControl
                      type="password"
                      className="signin_input"
                      placeholder="*****"
                      onChange={(e) => this.setState({ passwordConfirm: e.target.value })}
                    />
                  </FormGroup>
                </Col>   
              </Row>
              <Row >
                <Col xs={12}>
                  <Button id="login_button" disabled={!this.state.isEnabled} onClick={()=>this.handleSubmit()}>Créer</Button>
                </Col>
                {this.renderMessage()}
              </Row>      
            </form>                
          </div> 
        )}
      </I18n> 
    );
  }
}


function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, { registerUser })(Register);