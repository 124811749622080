import React, { Component } from 'react';
import './messages.css'

export class MessageHeader extends Component {
  
  render() {
    return (
      <div className="messagesTitle">
        <div id={ this.props.all ? "messagesDateLarge": "messagesDate"} >
          Sent on
        </div>  
        { this.props.all && <div id="deliveryDate">Delivered</div> } 
        <div id={ this.props.all ? "messagesStatusSmall" : "messagesStatus"} >
          Status
        </div>
        <div id={ this.props.all? "messagesExtraLarge": "messagesLarge" } >
          Message
        </div>   
        <div id={ this.props.all? "messagesExtraSmall": "messagesMedium"}>
          Sent to
        </div>             
        { this.props.all && <div id="messagesExtraSmall">Contract</div> } 
        { this.props.all && <div id="messageCost">Cost</div> } 

      </div>
    )
  }  
}