import React, { Component } from 'react';
import './client.css';
import history from './../../../history';
import ClientPhoto from './../Images/ClientPhoto'

class ClientProfile extends Component {

  handleNext(path){
    history.push(path)
  }
  
  render() {    
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let client = this.props.client
    
    let birthday = '-'
    if(client.profile && client.profile.birthday){
      birthday = new Date(client.profile.birthday)
      birthday = birthday.toLocaleDateString("en-EN", options)
    }
    
    let creditDecisionDate = '-'
    if( client.creditDecisionDate ){
      creditDecisionDate = new Date(client.creditDecisionDate)
      creditDecisionDate = creditDecisionDate.toLocaleDateString("en-EN", options)
    }

    let colorCode = 'cgreen'
    if( ['Approved'].indexOf(client.status) > -1 ) colorCode = 'cwarning'
    if( ['Rejected'].indexOf(client.status) > -1 ) colorCode = 'cdanger'
    if( ['Pending'].indexOf(client.status) > -1 ) colorCode = 'cneutral'


    return (
      <div id="clientProfile">
        <div id="clientPictureWrapper">
          <div id="clientPicture">
            <ClientPhoto client={ client } user={ this.props.user }/>
          </div>
          <div style={{ marginTop: "-5px" }}>            
            <div className="clientNumberLabel">Status</div>
            <div id="clientNumber" className={colorCode}>{client.status?client.status:'-'}</div>
          </div>
          <div className="active" onClick={()=>this.handleNext('/client/id='+client.clientNumber)}>
            <div className="clientNumberLabel">Number</div>
            <div id="clientNumber">{ client.clientNumber }</div>
          </div>
          <div id="clientCredit">
            <div id="clientCreditTitle">Rating</div>
            <div id="clientCreditScore">{client.score? client.score:'-'}</div>
          </div>
          <div id="clientCredit">
            <div id="clientCreditTitle">Approved by</div>
            <div id="clientCreditScore">{client.creditDecisionerId? client.creditDecisionerId:'-'}</div>
          </div>
          <div id="clientCredit">
            <div id="clientCreditTitle">Approved on</div>
            <div id="clientCreditScore">{ creditDecisionDate }</div>
          </div>

        </div>
       
        <div id="iW">
          <p id="iL">Name</p>
          <p className="iD L H">{client.profile&&client.profile.firstName?client.profile.firstName:'-'}</p>
          <p className="iD R H">{client.profile&&client.profile.lastName?client.profile.lastName:'-'}</p>
        </div>   
        <div id="iW">
          <p id="iL">Bday - gender</p>
          <p className="iD L H">{birthday}</p>
          <p className="iD R H">{client.profile?client.profile.gender:'-'}</p>
        </div> 
        <div id="iW">
          <p id="iL">Lat - lon</p>
          <p className="iD L H">{client.profile.gps? client.profile.gps.latitude: '-'}</p>
          <p className="iD R H">{client.profile.gps? client.profile.gps.longitude: '-'}</p>
        </div> 
        <div id="iW">
          <p id="iL">Village - County</p>
          <p className="iD L H">{client.profile.village? client.profile.village: '-'}</p>
          { client.profile.county

            ? <p className="iD R H">{ client.profile.county }</p>
            : client.profile.district 
              ? <p className="iD R H">{ client.profile.district }</p>
              : <p className="iD R H">-</p>
          }
        </div>   
        <div className="iW">
          <p id="iL">Mobiles</p>
          <p className="countryCode">{client.contact?client.contact.countryCode:'-'}</p>
          <p className="phone1">{client.contact?client.contact.mobile:'-'}</p>
          <p className="phone2">{client.contact?client.contact.secondaryMobile:'-'}</p>
        </div>     
        <div id="iW">
          <p id="iL">Neighbor - NextOfKin</p>
          <p className="countryCode">{client.contact?client.contact.countryCode:'-'}</p>
          <p className="phone1">{client.contact&&client.contact.neighbour?client.contact.neighbour:'-'}</p>
          <p className="phone2">{client.contact&&client.contact.nextOfKin?client.contact.nextOfKin:'-'}</p>
        </div>                  
      </div>
    );
  }
}

export default ClientProfile