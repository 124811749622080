import React, { Component } from 'react';
import {Button, FormControl} from 'react-bootstrap/lib';
import ClientProfile from './../../Clients/ClientProfile'
import './../contract.css';
import './../../Utils/utils.css'
import axios from 'axios';
import history from './../../../../history';
import { API_URL } from './../../../../Actions/index';
import { connect } from 'react-redux';
import { findProducts } from  './../../../../services/ProductService'
import { filterByEntity, findEntitiesList } from  '../../../../services/EntityService'
import { findDeals } from  './../../../../services/DealService'
import { SelectAgent } from './../../Agents/Action/SelectAgent'

var DatePicker = require("react-bootstrap-date-picker");

class Action extends Component {

  constructor(props) {
    super(props);
    this.state= {
      contract: {
        clientNumber: this.props.clientNumber,
        supplier: this.props.supplier,
      },    
      disabled: false
    }
  }; 

  componentDidMount() {
    this.loadData()
  }
  
  handleNext(path){
    history.push(path)
  } 
  
  loadData = async () => {
    
    try {

      let user = this.props.user
      let products = await findProducts( { status: 'Active' }, user._id, user.supplier )
      let res = await axios.get(API_URL + '/clients/byNumber/' + this.props.user.supplier + '/' + this.props.clientNumber)
      let client = res.data.client
      let entities = undefined
      
      if( user.entityOptions && user.entityOptions.length > 0 && client.entity ){
        entities = await findEntitiesList( user._id, user.supplier )
        products = filterByEntity( products, entities, client.entity._id ) 
      }

      this.setState({ products, client })

    } catch(err){ console.log(err) }
  }

  getDeals = async (product) => {
    try {
      let query = {
        eligibleProduct: product,
        status: 'Active'
      }
      let deals = await findDeals( query, this.props.user._id, this.props.user.supplier )
      this.setState({ deals })
    } catch(err){ console.log(err) }
  }

  createContract() {
    this.setState({disabled: true})
    var query = {
      contract : this.state.contract,
      agentId: this.state.agentId,
      user: this.props.user._id
    }
    axios.post(API_URL + '/contracts/create/'+ this.props.user.supplier, query)
      .then((ret) => this.setState({ status: ret.data.message }) )
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  selectAgent = (agent) =>{
    let contract = Object.assign({}, this.state.contract); 
    contract.agent = agent; 
    this.setState({ contract, agentId: agent });
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulAction':'failedAction'} className='alignedContract'>
          { this.state.status }
        </div>
      );
    }
  } 

  render() {    

    let { deals, products, contract, client, agentId, disabled } = this.state
    let { user } = this.props

    let mappedProducts = products && products.map( product =>{ 
      return <option key={product._id} value={product._id}>{product.name} by {product.manufacturer}</option>
    })

    let mappedDeals = deals && deals.map( deal =>{ 
      return <option key={deal._id} value={deal._id}>{deal.dealName}</option>
    })

    let isDisabled = ( contract.product && contract.deal && agentId ) ? false : true
    if( disabled ) isDisabled = true

    return (
      <div id="contract"> 
        <div id="contractSection">
          <div id="assetSummary">
            <div id="contractActionTitle">Choose a product</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="contractInput"
              onChange={(e)=>{ 
                let contract = Object.assign({}, this.state.contract); 
                contract.product = e.target.value; 
                this.setState({ contract });
                this.getDeals(e.target.value) 
                }}
              >
              <option value="">Choose a product</option>
              {mappedProducts}
            </FormControl>
          </div>
        </div>
        <div id="contractSection">
          { client &&
            <ClientProfile client={client} user={ user }/> }
        </div>
        <div id="contractSection">
          <div id="contractSummary">
            <div id="contractActionTitle">Choose a deal</div>
            { deals && deals.length > 0
              ? <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="contractInput"
                  onChange={(e)=>{ 
                    let contract = Object.assign({}, this.state.contract); 
                    contract.deal = e.target.value; 
                    this.setState({ contract });
                    }}
                  >
                  <option value="">Choose a deal</option>
                  { mappedDeals }
                </FormControl>
              : null }
          
            { deals && deals.length === 0 &&
              <div id="dealWarning">Please set up a deal for this product in the Deals page (under the "Admin" menu) before proceeding</div>
            }

            { deals && contract.deal &&
              <div>
                <div id="contractActionTitle" style={{marginTop: '15px'}}>
                  Selling agent
                </div>
                <SelectAgent
                  selectAgent={ this.selectAgent } 
                  format={ "contractInput" }
                  dropDownFormat={ "agentOption" }
                  entity={ client.entity }
                  supplierId={ user.supplier }
                  userId={ user._id }
                  title={ 'Select responsible agent' }
                />  
                <div className="contractLabel">
                  Signing Date (optional)
                </div>
                  <DatePicker 
                    className ="contractInput"
                    value={contract.signingDate}  
                    onChange={(e)=>{ let contract = Object.assign({}, this.state.contract); contract.signingDate = e; this.setState({ contract }) }}
                    showClearButton= {false}
                    dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                    monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                  />
              </div> }
          </div>
        </div>
        <div id="contractActionWrapper">
          <Button id="createButton" disabled={isDisabled} onClick={()=>this.createContract(contract)}>Create contract</Button>
        </div>  
        { this.renderMessage() } 
      </div>
    );
  }

}

const ContractAction = connect(
  function mapStateToProps(state) {
    return { 
      selectedAssets: state.selectedAssets
    };
  }
)(Action);

export default ContractAction