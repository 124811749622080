import React, { Component } from 'react';
import AdminMenu from './AdminMenu'
import './home.css';

class AdminHome extends Component {

  
  render() {

    return ( 
      <div className='background'>
        <AdminMenu user={this.props.user}/>
        <div id="home_background">
        </div>
      </div>
    );
  }
}

export default AdminHome
