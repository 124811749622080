import React, { Component } from 'react';
import { SearchContracts } from '../Search/SearchItems/SearchContracts'
import ManageMenu from '../ManageMenu'
import history from '../../../history';

class ContractsSearch extends Component {
  constructor(props) {
		super(props)
		this.state = {
      contracts: undefined,
      dealers: undefined,
      contracts: undefined,
      search: 'contracts',
    };
  }

  handleChangeSearch(search){
    this.props.chooseSearch(search)
    this.setState({ search: search })
  }

  componentDidMount() {
    if( this.props.search && this.props.search.length > 0 ) {
      this.setState({ search: this.props.search })
    } 
  }
  
  goBack() {
    window.history.back();
  }

  render() {    
    
    let user = this.props.user
    let options = this.props.user.options
      
    return (
      <div className='background'>
        <div id="pageTitle">
          Search contracts
          <span id="returnButton" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></span>
        </div>
        <ManageMenu user={ user }/>
        <div id="clientBackground">
          <div id="individualSearchMenu">  
            <SearchContracts user={ user } search={ 'contracts' } options={ options }/>
          </div>
        </div>
      </div>
    );
  }
}

export default ContractsSearch