import React from 'react';
import { Button, Modal } from 'react-bootstrap/lib';
import AssetSummary from '../AssetSummary';
import RepossessedAssetSummary from '../RepossessedAssetSummary'
import RepossessAsset from './RepossessAsset';
import ReplacePart from './ReplacePart';
import UnRepossessAsset from './UnRepossessAsset';
import AddAsset from './AddAsset';
import ReplaceAsset from './ReplaceAsset';
import SyncUnit from './SyncUnit'
import './../asset.css'
import './../../Utils/utils.css';

class AssetEdit extends React.Component {

  constructor(props) { 
		super(props)
		this.state = {
    };
  } 

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          <p>{this.state.status}</p>
        </div>
      );
    }
  }  

  render() {
    let navigate = null;
    let { user, contract } = this.props
    let asset = contract.asset
    let permissions = user.permissions? user.permissions : {}

    if( this.state.action == 'repossess'){
      navigate = <RepossessAsset 
        asset={ asset} 
        contract={ contract }
        user={ user }
      />
    }

    
    if( this.state.action == 'unrepossess'){
      navigate = <UnRepossessAsset 
        contract={ contract }
        userId={ user._id }
      />
    }

    if( this.state.action == 'replace' ){
      navigate = <ReplaceAsset 
        asset={ asset } 
        user={ user }
        contract={ contract }
      />
    }

    if( this.state.action == 'sync' ){
      navigate = <SyncUnit 
        asset={ asset } 
        user={ user }
        contract={ contract }
      />
    }

    if(this.state.action == 'add') navigate = <AddAsset asset={ asset } userID={ user._id }/>
    if(this.state.action == 'part') navigate = <ReplacePart asset={ asset } contract={ contract } user={ user }/>

    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="large"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Edit asset linked to contract</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">
          <div id="editSpace">
            { asset
              ? <AssetSummary asset={ asset } userID={ user._id }/>
              : <RepossessedAssetSummary contract={ contract } userID={ user._id }/> }
            <div id="modalEditContract">
              <Button id="action_cancel" onClick={ this.props.onHide }>Close</Button>
            </div>
          </div>
          <div id="editSpace">
            <div id="assetSummary">

              { contract.status !== 'REPOSSESSED' && ( ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1 || permissions.repossessAsset != false )
                ? <div id="assetOption" onClick={()=>this.setState({ action: 'repossess' })}>Repossess</div>
                : null }
              
              { contract.status == 'REPOSSESSED' && ( ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1 || permissions.repossessAsset != false )
                ? <div id="assetOption" onClick={()=>this.setState({ action: 'unrepossess' })}>Un-repossess</div>
                : null }

              { ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1 || permissions.replaceAsset != false
                ? <div id="assetOption"  onClick={()=>this.setState({ action: 'replace' })}>Replace</div>
                : null }
              
              { asset && 
                asset.productDetails && 
                ['Omnivoltaic', 'Biolite', 'Biolite_test'].indexOf(asset.productDetails.manufacturer) > -1 &&
                <div id="assetOption"  onClick={()=>this.setState({ action: 'sync' })}>Sync unit</div> }

              { asset && asset.productDetails && 
                <div id="assetOption"  onClick={()=>this.setState({ action: 'part' })}>Replace part</div> }
                
              { navigate }
            </div>
          </div>
          
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default AssetEdit;