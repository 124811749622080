import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import ItemsList from './ItemsList'
import './conversation.css'

class ConversationPopUp extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      note: {
        author: this.props.user._id,
        writtenBy: this.props.user.profile.firstName.substring(0, 1) + ' ' + this.props.user.profile.lastName,
        supplier: this.props.supplierId,
        contract: this.props.note.contract? this.props.note.contract: null,
        title: this.props.note.title,
        partOfDiscussion: this.props.note.partOfDiscussion? this.props.note.partOfDiscussion: this.props.note._id,
        type: this.props.note? this.props.note.type: 'Chat'
      },
      ticketaction: false,
      clickednote: this.props.note,
      action: ''
    };
  } 

  componentDidMount() {
    if( this.props.contract && this.props.notes ) this.setState({ relatedNotes: this.props.notes })
    else this.getHistory()
  } 

  send(){
    let agentTo = undefined
    if( this.state.relatedNotes ){
      let involvedAgent = this.state.relatedNotes.filter( item => item.agentTo || item.agentFrom )
      if( involvedAgent[0] && involvedAgent[0].agentTo ){ 
        agentTo = involvedAgent[0].agentTo
      } else if( involvedAgent[0] && involvedAgent[0].agentFrom ){
        agentTo = involvedAgent[0].agentFrom
      }
    }
    let noteToSend = Object.assign( this.state.note, { agentTo: agentTo } )
    let note = Object.assign({}, this.state.note); 
    note.text = undefined; 
    this.setState({ note }) 
    axios.put(API_URL + '/notes/sendnote/' + this.props.supplierId, noteToSend)
      .then((ret) => { this.setState({ status: 'Success' }) })
      .then( () => this.getHistory())

  }

  getHistory = () => {
    let discussionId = ''
    if( this.props.note.partOfDiscussion ) discussionId = this.props.note.partOfDiscussion
    else discussionId = this.props.note._id
    axios.get(API_URL + '/notes/conversation/' + this.props.supplierId + '/' + discussionId )
      .then((ret) => { this.setState({ relatedNotes: ret.data }) })
      .catch(err => console.log(err) )
  }  

  closeTicket = () => {
    let note = this.props.note
    let partOfDiscussion = note.partOfDiscussion ? note.partOfDiscussion : this.props.note._id
    let initialNote = this.state.relatedNotes[0] ? this.state.relatedNotes[0] : note

    let newDetails = {
      author: this.props.user._id,
      writtenBy: this.props.user.profile.firstName.substring(0, 1) + ' ' + this.props.user.profile.lastName,
      supplier: this.props.supplierId,
      contract: initialNote.contract,
      type: initialNote.type,
      title: initialNote.title,  
      client: initialNote.client,
      agentTo: initialNote.agentTo,
      tempAgent: initialNote.tempAgent,
      partOfDiscussion,
      action: 'close',    
    }

    axios.put(API_URL + '/notes/close/' + this.props.supplierId + '/' + partOfDiscussion, newDetails )
      .then(() => { this.setState({ status: 'Success' })  })
      .then( () => this.getHistory() )
      .then( () => {
        let clickednote = Object.assign({}, this.state.clickednote); 
        clickednote.closedOn = new Date(); 
        this.setState({ clickednote }) 
      })
      .catch(err => console.log(err) )
  }  

  over(){
    this.setState({
      note: {
        text: undefined,
        agent: undefined,
        title: undefined,
        author: undefined,
        title: undefined,
        contract: undefined,
        partOfDiscussion: undefined,
        type: undefined
      },
      status: undefined,
    })
    this.props.onHide()
  }
  
  ticketAction = (action) => {
    if( action == 'close') this.closeTicket()
    if( action == 'change' ) this.setState({ ticketAction: !this.state.ticketaction })
  }

  render() {
    let { note, relatedNotes } = this.state
    let originalNote = {}

    if( relatedNotes ) originalNote = relatedNotes.find( elem => elem._id == note.partOfDiscussion )
    else originalNote = note

    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="large"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-sm">
          <div id="convoTitle">{ note.type == 'Ticket' ? 'Ticket ' + originalNote.ticketNumber : note.title }</div>

          { ( note.type == 'Task' || note.type == 'Ticket' ) && !originalNote.closedOn &&
            <div>
              <div id="ticketAction" className="ticketSpace" onClick={ () => this.ticketAction('close') }>
                { note.type == 'Task'? 'Close task' : 'Close ticket'}
              </div>
            </div> }

          { originalNote.closedOn && <div id='closedOn'>Closed</div> }

          <Button id="convoClose" onClick={()=>this.over()}><i className="fas fa-times"></i></Button>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">
        { ! originalNote.closedOn &&
          <div id='addToConversation'>
            <textarea 
              type="text" 
              className="convoInput"
              placeholder={"Type message"}
              value={ note.text? note.text: '' }
              onChange={(e)=>{ 
                let note = Object.assign({}, this.state.note); 
                note.text = e.target.value; 
                this.setState({ note }) 
              }}
            />  
            <Button id="convoSend" disabled={ note.text? false: true } onClick={()=>this.send()}>Send</Button>
          </div> }
          <div id='conversationWrapper'>
            <ItemsList 
              originalNote={ note }
              notes={ relatedNotes } 
              contract={ note.contract? note.contract._id : undefined }
              userId={ this.props.user._id }
              supplierId={ note.supplier }
            />
          </div>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default ConversationPopUp;