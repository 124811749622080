import React, { Component } from 'react';
import EditAgent from '../Action/EditAgent'
import MessageAgent from '../../Notes/MessageAgent'
import TextAgent from '../Action/TextAgent'
import MoveEntity from '../Action/MoveEntity'
import TransferClients from '../Action/TransferClients'
import './../agent.css';
import InfoWrapper from '../../Utils/InfoWrapper';
import { hasPermission } from '../../../../services/UserService'

class AgentProfile extends Component {
  constructor(props) {
		super(props)
		this.state = {
      moveEntity: false,
      showEdit: false,
      showText: false,
      showMessage: false,
      showTransfer: false
    };
  }

  closeEdit() {
    this.setState({ showEdit: false });
    this.props.refresh()
  }

  closeMessage() {
    this.setState({ showMessage: false });
    this.props.refresh()
  }

  closeText() {
    this.setState({ showText: false });
    this.props.refresh()
  }

  closeMove() {
    this.setState({ moveEntity: false });
    this.props.refresh()
  }

  closeTransfer() {
    this.setState({ showTransfer: false });
    this.props.refresh()
  }

  render() {    
    let { agent, options, user } = this.props
    let permission = hasPermission( user, agent )
    let entityOptions = user.entityOptions

    return (
      <div id="agentProfile">
        <div id="agentSectionTitle">Profile </div>
        <InfoWrapper 
          label={"Agent number"}
          data={agent.agentNumber}
          />
        <InfoWrapper 
          label={"First name"}
          data={agent.profile.firstName}
          />
        <InfoWrapper 
          label={"Last name"}
          data={agent.profile.lastName}
          />  
        { agent.district && <InfoWrapper 
          label={"District"}
          data={ agent.district }
        />  }     
        <InfoWrapper 
          label={"Email"}
          data={agent.email}
          />   
        <div className="infoWrapper">
          <p id="infoLabel">Mobile</p>
          <p id="infoDataLeft">{agent.contact?agent.contact.countryCode:'-'}</p>
          <p id="infoDataRight">{agent.contact?agent.contact.mobile:'-'}</p>
        </div>    
        <InfoWrapper 
          label={"Role"}
          data={agent.role}
          />  

        { agent && agent.entity &&
          <InfoWrapper 
            label={"Entity"}
            data={ agent.entity.name? agent.entity.name: '-' }
          /> }

        { options && options.agentManagers && agent.manager && agent.manager !== agent.agentNumber &&
          <InfoWrapper 
            label={"Managed by"}
            data={ agent.manager }
            isActive={ true }
            agentLink={ true }
          /> }
        
        { options && options.agentManagers && agent.role == 'Manager' &&
          <InfoWrapper 
            label={"CanRegisterAgents"}
            data={ agent.canRegisterAgents? 'TRUE': 'FALSE' }
          /> }
      
        { agent.cashCollection &&
          <div>
            <div className="infoWrapper" >
              <p id="infoLabel">Cashcollection</p>
              { agent.cashLimit 
                ? <div>
                    <p id="infoDataShortLeft">Limited to</p>
                    <div id="infoDataShort"><span>{ agent.ccy }{ agent.cashLimit }</span></div>
                  </div>
                : <div>
                    <p id="infoDataShortLeft">Permitted</p> 
                    <div id="infoDataShort">No cap</div>
                  </div>
                }
            </div>
            <InfoWrapper 
              label={"Currency"}
              data={agent.ccy}
            /> 
          </div> }
          
        <div id="agentActionZone">
          <div 
            id="agentAction" 
            onClick={()=>this.setState({ showEdit: !this.state.showEdit })}
          >
            Edit
          </div>
          <div 
            id="agentAction" 
            onClick={()=>this.setState({ showMessage: !this.state.showMessage })}
          >
            Message
          </div>
          <div 
            id="agentAction" 
            onClick={()=>this.setState({ showText: !this.state.showText })}
          >
            Text
          </div>

          { permission &&
            <div 
              id="agentAction" 
              onClick={()=>this.setState({ showTransfer: !this.state.showTransfer })}
            >
              Transfer clients
            </div> }

          { entityOptions && entityOptions.length > 0 && permission &&
            <div 
              id="agentAction" 
              onClick={()=>this.setState({ moveEntity: !this.state.moveEntity })}
            >
              Move entity
            </div> }

          { this.state.showEdit &&
            <EditAgent 
              show={this.state.showEdit} 
              onHide={this.closeEdit.bind(this)}
              supplierId={ agent.supplier }
              agent={agent}
            />  }

          { this.state.moveEntity &&
            <MoveEntity 
              show={this.state.moveEntity} 
              onHide={this.closeMove.bind(this)}
              user={ user }
              agent={ agent }
            />  }

          { this.state.showTransfer && 
            <TransferClients 
              show={this.state.showTransfer} 
              onHide={this.closeTransfer.bind(this)}
              agent={agent}
              user={ this.props.user }
            />  }
          { this.state.showMessage &&
            <MessageAgent 
              show={ this.state.showMessage } 
              onHide={ this.closeMessage.bind(this) }
              agent={ agent }
              user={ this.props.user }
            /> }
          { this.state.showText &&
            <TextAgent 
              show={this.state.showText} 
              onHide={this.closeText.bind(this)}
              agent={ agent }
            /> } 
        </div>

        { agent.role==='Disabled' &&<div id='disabled'>DISABLED</div> }             
      
      </div>
    );
  }
}

export default AgentProfile