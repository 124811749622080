import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import AgentInvoicesAction from './Action/AgentInvoicesAction'
import AgentInvoicesCalc from './Action/AgentInvoicesCalc'
import AgentInvoicesList from './List/AgentInvoicesList'
import './agentInvoice.css'

class AgentInvoicesController extends Component {

  constructor(props) {
		super(props)
		this.state = {
      agentInvoices: undefined,
      showModal: false,
      calc: false,
      edit: false
    };
  }
  
  componentDidMount() {
    this.loadData();
  }
  
	getAgentInvoicesList = () => {
    axios.post(API_URL + '/agents/getInvoices/'+ this.props.agent.supplier, {
      agent: this.props.agent._id
    }).then((ret) => this.setState({ agentInvoices: ret.data }) )
  }
  
  calc(){
    this.setState({ calc: false })
    this.getAgentInvoicesList();
  }

  close(){
    this.setState({ showModal: false })
    this.getAgentInvoicesList();
  }

  loadData() {
    this.getAgentInvoicesList()
  }

  render() {    
    let user = this.props.user
    let options = user.options
    let permission = false
    if( options.manageAgents && ( ['MainAdmin', 'Core', 'Admin'].indexOf(user.role ) > -1 || user.permissions.manageAgents ) ) permission = true
    
    return (
      <div >
        <div>
          { false
            ? <div className="addInvoices" onClick={() => this.setState({ edit: !this.state.edit })}>
                <span style={{ paddingRight: '10px' }}><i className="fas fa-pen"></i></span>Manage 
              </div>
            : null }
          { this.state.edit
            ? <div className="createItem" onClick={() => this.setState({ showModal: !this.state.showModal })}>
                <span style={{ paddingRight: '10px' }}><i className="fas fa-plus-square"></i></span>Create new item 
              </div>
            : null }

          { this.state.edit
            ? <div className="createItem" onClick={() => this.setState({ calc: !this.state.calc })}>
                <span style={{ paddingRight: '10px' }}><i className="fas fa-plus-square"></i></span>Calculate new item 
              </div>
            : null }
          { this.state.agentInvoices && this.state.agentInvoices.length > 0
            ? <AgentInvoicesList 
                agentInvoices={ this.state.agentInvoices } 
                refresh={ this.getAgentInvoicesList } 
                supplier= { user.supplier } 
                userNumber={ user.userNumber }
                edit={ this.state.edit }
              />
            : <div className='noManagedAgents'>No payroll item at this time</div> }
        </div>

        { this.state.showModal
          ? <AgentInvoicesAction 
              show={this.state.showModal} 
              agent={ this.props.agent }
              onHide={this.close.bind(this)}
              user={ user }
              supplier= { user.supplier }
              edit={false}
            /> 
          : null }

        { this.state.calc
          ? <AgentInvoicesCalc 
              show={this.state.calc} 
              agent={ this.props.agent }
              onHide={this.calc.bind(this)}
              user={ user }
              contracts={ this.props.contracts }
              teamContracts= { this.props.teamContracts }
              supplier= { user.supplier }
              agentInvoices={ this.state.agentInvoices }
            /> 
          : null }

      </div>
    );
  }
}

export default AgentInvoicesController