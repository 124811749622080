import React, { Component } from 'react';
import './elem.css'
var isAfter = require('date-fns/is_after')

class ItemLine extends Component {
  
  constructor(props) {
		super(props)
		this.state = {
      include: false,
    };
  }

  componentDidUpdate(prevProps) {
    if( this.props.included !== prevProps.included ) this.setState({ include: false });
  }

  render() {    
    let target = this.props.target

    let contracts = this.props.contracts
    if( target.deal ) contracts.filter( elem => elem.deal == target.deal )
    let agentPayments = []
    let sales = 0
    let revenues = 0
    let utilisation = 0
    let payoff = 0
    let enabled = 0
    let text = ''
    let locked = 0
    let startDate = this.props.dateFrom
    let endDate = this.props.dateTo

    if( contracts && contracts.length ){
      contracts.forEach( elem => {
        if( isAfter( new Date( elem.signingDate ), new Date( startDate ) ) && isAfter( new Date( endDate ), new Date(elem.signingDate) )) sales += 1
        if( elem.payments ) elem.payments.forEach( p => agentPayments.push(p) )
        if( elem.status == 'ENABLED' ) enabled += 1
        if( elem.status == 'LOCED' && elem.onboardingStatus == 'Signed' ) locked += 1
      })
      agentPayments.forEach( elem => {
        if( isAfter( new Date( elem.date ), new Date( startDate ) ) && isAfter( new Date( endDate ), new Date(elem.date) )) revenues += elem.amount
      })
      if( enabled + locked > 0 ) utilisation = enabled / ( enabled + locked )
    }

    if( target.category == "Revenues" ) payoff = target.base + revenues * target.share / 100
    if( target.category == "Sales" ) payoff = target.base + sales * target.share
    if( target.category == "Utilisation" && target.utilisation < utilisation ) payoff = target.digital

    if( target.category == "Revenues" ) text = target.share + ' % of ' + target.ccy + revenues + ' of generated revenues + ' + target.ccy + target.base + ' of base' 
    if( target.category == "Sales" ) text = target.ccy + target.share * sales + ' for ' + sales + ' new signings + ' + target.ccy + target.base + ' of base'
    if( target.category == "Utilisation" && target.utilisation < utilisation ) text = target.ccy + target.digital + ' for reached utilisation target'
    if( target.category == "Sales" && target.deal ){
      text = target.ccy + target.share * sales + ' for ' + sales + ' new ' + target.deal.dealName + ' signings + ' + target.ccy + target.base + ' of base'
    }

    return (
      <div id="itemLineWrapper">
        
        <div id="itemLine">

          <div id="itemLineDes">
            <div>{ target.category } target { target.deal? 'for ' + target.deal.dealName + ' deals' : '' }</div>
          </div>
          <div id="itemLineCalc">

            { target.category == 'Revenues'
              ? <div id="itemLineExpl">
                  <div id="itemLineExplLabel">Revenues during period</div> 
                  <div id="itemLineExplData">{ revenues }</div> 
                </div>
              : null }
            { target.category == 'Sales' 
              ? <div id="itemLineExpl">
                  <div id="itemLineExplLabel">New sales during period</div> 
                  <div id="itemLineExplData">{ sales }</div> 
                </div> 
              : null }
            { target.category == 'Utilisation' 
              ? <div id="itemLineExpl">
                  <div id="itemLineExplLabel">Utilisation at period end</div> 
                  <div id="itemLineExplData">{ utilisation }</div> 
                </div> 
              : null }


            { target.category == 'Revenues'
              ? <div id="itemLineP">
                  <div id="itemLinePLabel">Payoff</div> 
                  <div id="itemLinePData">{ target.ccy }{ target.base } + { target.ccy }{ revenues } x { target.share}%</div> 
                  <div id="itemLinePSep"></div>
                  <div id="itemLinePTotal">{ target.ccy }{ payoff }</div>
                </div>
              : null }

            { target.category == 'Sales'
              ? <div id="itemLineP">
                  <div id="itemLinePLabel">Payoff</div> 
                  <div id="itemLinePData">{ target.ccy }{ target.base } + { sales } x { target.ccy }{ target.share}</div> 
                  <div id="itemLinePSep"></div>
                  <div id="itemLinePTotal">{ target.ccy }{ payoff }</div>
                </div>
              : null }

            { target.category == 'Utilisation'
              ? <div id="itemLineP">
                  <div id="itemLinePLabel">Payoff</div> 
                  <div id="itemLinePData">{ target.ccy }{ target.digital}</div> 
                  <div id="itemLinePSep"></div>
                  <div id="itemLinePTotal">{ target.ccy }{ payoff }</div>
                </div>
              : null }

          </div>
          
        </div>
        
        <div id="itemLineRemove" className={ this.state.include ? "itemLineRemoveActive":"" } onClick={()=>{
          if( !this.state.include) this.props.include( payoff, text )
          else this.props.remove( payoff, text )
          this.setState({ include: !this.state.include })
        }}>
          <div><i className="fas fa-check"></i></div>
        </div>
      </div>
    );
  }
}

export default ItemLine