import React, { Component } from 'react';
import {Button, FormControl} from 'react-bootstrap/lib';
import './../contract.css';
import './../../Utils/utils.css'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
var DatePicker = require("react-bootstrap-date-picker");

class Reset extends Component {

  constructor(props) {
    super(props);
    this.state= {
      update: {}, 
      disabled: true, 
      status: undefined,
    }
  }; 

  componentDidMount() {
    let update = {
      totalPaid: this.props.contract.totalPaid,
      totalCost: this.props.contract.totalCost,
      debt: this.props.contract.debt,
      remainingDebt: this.props.contract.remainingDebt,
      nextStatusUpdate: this.props.contract.nextStatusUpdate,
    }
    this.setState({ update })
  }

  reset() {
    var query = {
      update: this.state.update,
      contractNumber: this.props.contract.contractNumber,
      userId: this.props.user._id,
    }
    axios.put(API_URL + '/contracts/reset/'+ this.props.contract.supplier, query)
      .then((ret) => {
          this.setState({ status: ret.data.message });
      })
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if( this.state.status ) {
      return (
        <div id={this.state.status == 'Success'?'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  } 

  render() {    
    let contract = this.props.contract
    console.log(this.state.update)
    return (
      <div >
        <div>
          <div id="contractEditTitle">Reset contract's economics</div>

          <div id="contractFull">
            <div id="contractEditLabel">
              Total cost
            </div>
            <div id="contractLabel" style={{ paddingTop: "8px"}}>
              { contract.totalCost }
            </div>
            <div id="contractLabelShort">
              { contract.ccy } 
            </div>  
          </div>

          <div id="contractFull">
            <div id="contractEditLabel">
              Total paid
            </div>
            <div id="contractLabel">
              <FormControl 
                type="text" 
                className="contractEditInput"
                value= { this.state.update.totalPaid != undefined
                  ? this.state.update.totalPaid
                  : contract.totalPaid
                }
                onChange={(e)=>{ 
                  let update = Object.assign({}, this.state.update); 
                  update.totalPaid = e.target.value; 
                  update.remainingDebt = contract.totalCost - e.target.value
                  update.debt = this.props.contract.debt
                  update.debt += this.props.contract.totalPaid - e.target.value
                  this.setState({ update }) 
                }}
              />
            </div>
            <div id="contractLabelShort">
              {contract.ccy} 
            </div>  
          </div>

          <div id="contractFull">

            <div id="contractEditLabel">
              Remaing debt
            </div>
            <div id="contractLabel">
              <FormControl 
                type="text" 
                className="contractEditInput"
                value= { this.state.update.remainingDebt != undefined
                  ? this.state.update.remainingDebt
                  : contract.remainingDebt
                }
                onChange={(e)=>{ 
                  let update = Object.assign({}, this.state.update); 
                  update.remainingDebt = e.target.value; 
                  update.totalPaid = contract.totalCost - e.target.value
                  update.debt = this.props.contract.debt
                  update.debt -= this.props.contract.remainingDebt - e.target.value
                  this.setState({ update }) 
                }}
              />
            </div>
            <div id="contractLabelShort">
              {contract.ccy} 
            </div>  
          </div>

          { contract.linkedContracts && contract.linkedContracts.length > 0 &&
            <div id="contractFull">
              <div id="contractEditLabel">
                Contract debt
              </div>
              <div id="contractLabel">
                <FormControl 
                  type="text" 
                  className="contractEditInput"
                  value= { this.state.update.debt != undefined
                    ? this.state.update.debt
                    : contract.debt
                  }
                  onChange={(e)=>{ 
                    let update = Object.assign({}, this.state.update); 
                    update.debt = e.target.value; 
                    this.setState({ update }) 
                  }}
                />
              </div>
              <div id="contractLabelShort">
                {contract.ccy} 
              </div>  
            </div>
          }

          { ['Core'].indexOf( this.props.user.role ) > -1 && 
             <div className="agentSelectorDate">
             <DatePicker 
               className ="graphSelector"
               value={this.state.update.nextStatusUpdate}  
               onChange={(e)=>{ 
                 let update = Object.assign({}, this.state.update); 
                 update.nextStatusUpdate = e; 
                 this.setState({ update }) 
               }}  
               showClearButton= {false}
               dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
               monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
             />
           </div> }
         
          <div id="contractEditWrapper">
            <Button id="updateButton" onClick={()=>this.reset()}>Reset contract</Button>
          </div>

        </div>
        {this.renderMessage()} 
      </div>
    );
  }
}

export default Reset