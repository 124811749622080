import React, { Component } from 'react';
import history from './../../../../history';
import { addToAssetList, removeFromAssetList } from './../../../../Actions/index';
import { connect } from 'react-redux';
import './assets.css'

var differenceInCalendarDays = require('date-fns/difference_in_calendar_days')

export class Item extends Component {
  constructor(props) {
		super(props)
		this.state = {
      toApprove: false,
    };
  }

  handleNext(path){
    history.push(path)
  }
  
  handleSelect(){
    this.setState({toApprove: !this.state.toApprove})
    if(this.props.selectedAssets.indexOf(this.props.asset.assetNumber)<0){
      this.props.addToAssetList(this.props.asset.assetNumber)
    } else this.props.removeFromAssetList(this.props.asset.assetNumber)
  }

  render() {
    var asset = this.props.asset

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let dateAdded = new Date(asset.dateAdded)
    dateAdded = dateAdded.toLocaleDateString("en-EN", options)

    let dateDist = '-'
    if(asset.distributed){
      dateDist = new Date(asset.distributed.date)
      dateDist = dateDist.toLocaleDateString("en-EN", options)
    }

    let dateStock = '-'
    if(asset.inStock){
      dateStock = new Date(asset.inStock.date)
      dateStock = dateStock.toLocaleDateString("en-EN", options)
    }

    let dateDef = '-'
    if(asset.defect){
      dateDef = new Date(asset.defect.date)
      dateDef = dateDef.toLocaleDateString("en-EN", options)
    }
    let lastUpdate = '-'
    if( asset.lastUpdate ){
      lastUpdate = new Date(asset.lastUpdate)
      lastUpdate = lastUpdate.toLocaleDateString("en-EN", options)
    }
    let heldSince = '-'
    if( asset.heldSince ){
      heldSince = new Date(asset.heldSince)
      heldSince = heldSince.toLocaleDateString("en-EN", options)
    }

    let firstName = ''
    let lastName = ''
    let profile = asset.ownedBy? asset.ownedBy.profile : undefined
    if( profile && profile.firstName ) firstName = profile.firstName.charAt(0).toUpperCase() + '.'
    if( profile && profile.lastName ) {
      lastName = profile.lastName.toLowerCase();
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    }
    let aFirstName = ''
    let aLastName = ''
    let aProfile = asset.heldBy? asset.heldBy.profile : undefined
    if( aProfile && aProfile.firstName ) aFirstName = aProfile.firstName.charAt(0).toUpperCase() + aProfile.firstName.slice(1);
    if( aProfile && aProfile.lastName ) aLastName = aProfile.lastName

    return (
      <div className="assetItem">

        <div id="assetsEntity" >
          { asset.entity
              ? asset.entity.name? asset.entity.name : '-'
              : asset.country? asset.country : 'Global'
            }          
        </div> 

        <div id="assetsNumber"  className="active" onClick={()=>this.handleNext('/asset/id='+asset.assetNumber)}>
          {asset.paygNumber? asset.paygNumber: asset.shortNumber}
        </div> 

        { asset.batchReference || asset.batchNumber
          ? <div id="assetsSmall"  className="active onlyLarge" onClick={()=>this.handleNext('assets/batch/id=' + asset.batchNumber )}>
              { asset.batchNumber ? asset.batchNumber: asset.batchReference }
            </div>  
          : <div id="assetsSmall"  className="active onlyLarge">-</div> } 

        <div id="assetsSmall" >{ asset.status }</div>    
        
        <div id="assetsProduct" >{ asset.productDetails? asset.productDetails.name:'-' }</div>   
        
        <div id="assetsMedium"  className='onlyLarge'>{ asset.serialNumber }</div>

        { asset.status == 'PENDING' && <div id="assetsSmall" >{ dateAdded }</div> } 

        { asset.status == 'INSTOCK' && <div id="assetsInstockSince" > { dateStock } </div>} 

        { asset.status == 'DEPLOYED' && <div id="assetsSmall" className='onlyLarge'>{ dateDist }</div>} 

        { asset.status == 'WRITEOFF' && <div id="assetsSmall" className='onlyLarge'>{ lastUpdate }</div>} 

        { asset.status == 'DEFECTIVE' && <div id="assetsSmall" className='onlyLarge'>{ dateDef }</div>} 

        { asset.status =='DEPLOYED'
          ? <div>
              { asset.ownedBy 
                ? <div id="assetsMedium"  className="active onlyLarge" onClick={()=>this.handleNext('client/id='+asset.ownedBy.clientNumber)}>
                  { firstName } { lastName }
                </div> 
                : <div id="assetsMedium" > - </div> }
            </div>
          : <div>
              { asset.heldBy 
                ? <div id="assetsMedium"  className="active onlyLarge" onClick={()=>this.handleNext('agent/id='+asset.heldBy.agentNumber)}>
                   { aFirstName } { aLastName }
                  </div> 
                : <div id="assetsMedium" className='onlyLarge'>- </div> }
            </div> }  

        { asset.contract && asset.status !== 'DEFECTIVE'
          ? <div id="assetsSmall"  className="active" onClick={()=>this.handleNext('contract/id='+asset.contract.contractNumber)}>
              {asset.contract.contractNumber}
            </div> 
          : null }    

        { asset.status == 'DEFECTIVE' && this.props.search && <div id="assetsSmall">-</div> }    

        { asset.status == 'INSTOCK' && <div id="assetsInstockSince" className='onlyLarge'>{ heldSince }</div> } 

        { asset.status !== 'PENDING' &&
          <div id="assetsExtraSmall">
            { asset.status == 'INSTOCK'
              ? differenceInCalendarDays(new Date(), heldSince)
              : differenceInCalendarDays(new Date(), dateDist) }
          </div>  }

        { ( asset.status == 'PENDING' || asset.status == 'DEFECTIVE' ) && !this.props.search &&
          <div id="assetsAction" className="active" onClick={()=>this.handleSelect()}>
            { this.state.toApprove? <i className="fas fa-check-square"></i>:<i className="far fa-check-square"></i> }
          </div> }    

        { asset.status == 'INSTOCK' && !this.props.search &&
          <div id="assetsSecondHand">{ asset.stockEntryCount > 0 ? <i className="fas fa-check"></i>: '-' }</div> }  

      </div>
    )
  }  
}

export const AssetsListItem = connect(
  function mapStateToProps(state) {
    return { 
      selectedAssets:state.selectedAssets
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToAssetList: asset => dispatch(addToAssetList(asset)),
      removeFromAssetList: asset => dispatch(removeFromAssetList(asset))
    };
  },
)(Item);
