import React, { Component } from 'react';
import { Modal } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class History extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deals: [],
    }
  }; 

  componentDidMount() {
    this.getDeals();
  }

	getDeals = () => {
    axios.post(API_URL + '/deals/search/'+ this.props.supplier, {
      query:{
        status: 'Old_version',
        dealName: this.props.deal.dealName
      }
    })
      .then((ret) => this.setState({ deals: ret.data }) )
  }

  over(){
    this.props.onHide()
    this.setState({
      deals: [],
    })
  }

  render() {
    
    let deals  = null;
    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    if( this.state.deals && this.state.deals.length > 0 ){
      deals = this.state.deals
        .map( (deal, index) => { 
          let dateFrom = '-'
          let dateTo = '-'
          if( deal.setUpOn ){
            dateFrom = new Date( deal.setUpOn )
            dateFrom = dateFrom.toLocaleDateString("en-EN", options)
          }
          if( deal.closedOn ){
             dateTo = new Date( deal.closedOn )
            dateTo = dateTo.toLocaleDateString("en-EN", options)
          }

          return ( 
            <div 
              key={ deal._id } 
              className="dealHistoryItem" 
            >
              <div id="dealHistoryItemVersion">{ deal.dealVersion? deal.dealVersion : '-' }</div>
              <div id="dealHistoryItemDate">{ dateFrom }</div>
              <div id="dealHistoryItemDate">{ dateTo }</div>
              <div id="dealHistoryItemCost">{ deal.backPay }</div>
              <div id="dealHistoryItemCost">{ deal.ccy } { deal.totalCost }</div>
              <div id="dealHistoryItemCost">{ deal.ccy } { deal.pricingSchedule? deal.pricingSchedule.upfrontPayment:'-' }</div>
              <div id="dealHistoryItemCost">{ deal.ccy } { deal.pricingSchedule? deal.pricingSchedule.recurrentPayment:'-' }</div>
              <div id="dealHistoryItemCost">{ deal.ccy } { deal.pricingSchedule? deal.pricingSchedule.minPayment:'-' }</div>
            
            </div> 
        )
      })
      
    }

    return (
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="large"
          aria-labelledby="contained-modal-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Previous versions of { this.props.deal.dealName }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight" className='shiftedUp'>

              <div className="dealHistoryTitle">

                <div id="dealHistoryItemVersion">Version</div>
                <div id="dealHistoryItemDate">From</div>
                <div id="dealHistoryItemDate">To</div>
                <div id="dealHistoryItemCost">Backpay</div>
                <div id="dealHistoryItemCost">Total Cost</div>
                <div id="dealHistoryItemCost">Upfront</div>
                <div id="dealHistoryItemCost">Recurring</div>
                <div id="dealHistoryItemCost">Min payment</div>
              
            </div>

            { deals }

            </div>
            
          </Modal.Body>
        </Modal>
    );
  }
}

export default History