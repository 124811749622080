import React, { Component } from 'react';
import './finance.css'
import { FormControl } from 'react-bootstrap/lib';
import { setFilter } from '../../../Actions/index';
import { connect } from 'react-redux';
import history from '../../../history';
import { findAgents } from  '../../../services/AgentService'
import { findDeals } from  '../../../services/DealService'
import { findProducts } from  '../../../services/ProductService'

var DatePicker = require("react-bootstrap-date-picker");
var addDays = require('date-fns/add_days');

class Filter extends Component {
  constructor(props) {
		super(props)
		this.state = {
      filter:{
        endDate: (new Date()).toISOString(),
        startDate: (addDays(new Date(), -30)).toISOString(),
        product: this.props.filter.product,
        agent: this.props.filter.agent,
        deal: this.props.filter.deal,
      },
    };
  }

  componentDidMount() {
    this.loadData()
  }

  goBack() {
    window.history.back();
  }

  handleNext(path){
    history.push(path)
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents, filteredAgents: agents })
    } catch(err){ console.log(err) }
  }

  getProducts = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products, filteredProducts: products })
    } catch(err){ console.log(err) }
  }

  getDeals = async () => {
    try {
      let deals = await findDeals( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ deals, filteredDeals: deals })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getProducts();
    this.getDeals();
    this.getAgents();
  }

  filterEntity = ( entities, entity ) => {
    let selectedEntities = entities.filter( elem => 
      elem._id === entity ||
      elem.country === entity ||
      elem.organisation === entity ||
      elem.branch === entity
    ).map( elem => elem._id )
    let filteredAgents = this.state.agents.filter( elem => selectedEntities.indexOf( elem.entity ) > -1 || !elem.entity )
    let filteredDeals = this.state.deals.filter( elem => selectedEntities.indexOf( elem.entity ) > -1 || !elem.entity )
    let filteredProducts = this.state.products.filter( elem => selectedEntities.indexOf( elem.entity ) > -1 || !elem.entity )
    this.setState({ filteredAgents, filteredDeals, filteredProducts })
  }

  render() {    
    let { products, agents, deals, filteredProducts, filteredDeals, filteredAgents } = this.state
    let { user, entities, filter } = this.props

    let mappedTeams, mappedEntities = null

    let mappedProducts = filteredProducts && filteredProducts.map((product, index) =>{ 
        return (
          <option key={index} value={product._id} className={'agentOption'}>{product.name} by {product.manufacturer}</option>
        )
      })

    let mappedAgents = filteredAgents && filteredAgents
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return (
          <option key={agent._id} className={'agentOption'} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
    })

    let mappedDeals = filteredDeals && filteredDeals.map((deal, index) =>{ 
      return (
        <option key={index} value={deal._id} className={'agentOption'}>{deal.dealName}</option>
      )
    })

    if( agents && user.options && user.options.agentManagers ){
      mappedTeams = this.state.agents.filter( elem => elem.role == 'Manager').map((agent, index) =>{ 
        return (
          <option key={agent._id} className={'agentOption'} value={agent.agentNumber}>Team {agent.profile.lastName}</option>
        )
      })
    }

    if( entities && entities.length > 1 ){
      mappedEntities = entities
        .sort((a, b) => a.name.localeCompare(b.name))  
        .map( entity =>{ 
          return <option  className={'agentOption'} key={ entity._id } value={ entity._id }>{entity.name}</option>
        })
    }

    let deal = 'All deals'
    if( filter.deal && deals ) {
      let filteredDeal = deals.find( elem => elem._id === filter.deal )
      deal = filteredDeal? filteredDeal.dealName : ''
    }

    let agent = 'All agents'
    if( filter.agent && agents ) {
      let filtered = agents.find( elem => elem._id === filter.agent )
      agent = filtered? filtered.profile.firstName + ' ' + filtered.profile.lastName : ''
    }

    let product = 'All products'
    if( filter.product && products ) {
      let filtered = products.find( elem => elem._id === filter.product )
      product = filtered? filtered.name : ''
    }

    let team = 'All teams'
    if( filter.manager && agents) {
      let filtered = agents.find( elem => elem.agentNumber == filter.manager )
      team = filtered? 'Team ' + filtered.profile.lastName : ''
    }

    let selectedEntity = 'All entities'
    if( filter.entity && entities) {
      let filtered = entities.find( elem => elem._id == filter.entity )
      selectedEntity = filtered? filtered.name : ''
    }

    return (
      <div id="agentSelectSection">

        { mappedEntities &&
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="graphSelector"
            onChange={(e)=>{ 
              let filter = Object.assign({}, this.state.filter); 
              filter = {}
              filter.entity = e.target.value; 
              this.setState({ filter })
              this.filterEntity(entities, e.target.value) 
              this.props.setFilter( filter )
            }}
          >
            <option value='' className={'agentOption'}>{ selectedEntity }</option>
            <option value='' className={'agentOption'}>Remove filter</option>
            { mappedEntities }
          </FormControl>
        }

        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="graphSelector"
          onChange={(e)=>{ 
            let filter = Object.assign({}, this.state.filter); 
            filter.deal = e.target.value; 
            this.setState({ filter }) 
            this.props.setFilter( filter )
          }}
        >
          <option value='' className={'agentOption'}>{ deal }</option>
          <option value='' className={'agentOption'}>Remove filter</option>
          { mappedDeals }
        </FormControl>

        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="graphSelector"
          onChange={ (e) => {
            let filter = Object.assign({}, this.state.filter); 
            filter.agent = e.target.value; 
            this.setState({ filter }) 
            this.props.setFilter( filter )
          }}
        >
          <option value='' className={'agentOption'}>{ agent }</option>
          <option value='' className={'agentOption'}>Remove filter</option>
          { mappedAgents }
        </FormControl>

        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="graphSelector"
          onChange={ (e) => {
            let filter = Object.assign({}, this.state.filter); 
            filter.product = e.target.value; 
            this.setState({ filter }) 
            this.props.setFilter( filter )
          }}
        >
          <option value='' className={'agentOption'}>{ product }</option>
          <option value='' className={'agentOption'}>Remove filter</option>

          { mappedProducts }
        </FormControl>
        

      { !this.props.noDate && 
        <div>
          <div className="graphSelectorDate">
            <DatePicker 
              className ="graphSelector"
              value={this.state.filter.startDate}  
              onChange={(e) => {
                let filter = Object.assign({}, this.state.filter); 
                filter.startDate = e; 
                this.setState({ filter }) 
                this.props.setFilter( filter )
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />
          </div>
          <div className="graphSelectorDate">
            <DatePicker 
              className ="graphSelector"
              value={this.state.filter.endDate}  
              calendarPlacement ={'left'}
              onChange={(e) => {
                let filter = Object.assign({}, this.state.filter); 
                filter.endDate = e; 
                this.setState({ filter }) 
                this.props.setFilter( filter )
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />
          </div>
        </div> }
        
      </div>

    );
  }
}

const GraphFilter = connect(
  function mapStateToProps(state) {
    return { 
      filter: state.filter
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      setFilter: filter => dispatch(setFilter(filter)),
    };
  },
)(Filter);

export default GraphFilter