import React, { Component } from 'react';
import './agents.css'

export class AgentHeader extends Component {
  
  render() {
    return (
      <div className="agentsTitle">
        <div id="agentsEntity" >
          Entity
        </div>  
        <div id="agentsId" >
          Agent id
        </div>  
        <div id="agentsMedium" >
          First Name
        </div>
        <div id="agentsMedium" >
          Last Name
        </div>
        <div id="agentsMedium" className='onlyLarge'>
          Role
        </div>   
        <div id="agentsLarge" className='onlyLarge'>
          Email
        </div>     
        <div id="agentsSmall" >
          Contracts
        </div>  
        <div id="agentsSmall" >
          Stock
        </div>  
        <div id="agentsSmall" >
          Defective
        </div>  
        <div id="agentsSmall" className='onlyLarge'>
          Prospects
        </div> 
        <div id="agentsSmall" className='onlyLarge'>
          Rejected
        </div>    
        
        <div id="agentsSync" className='onlyLarge'>
          Last sync'ed
        </div> 
        { ! this.props.hideEdit 
          ? <div id="agentsExtraSmall">Edit</div>      
          : null }    
      </div>
    )
  }  
}