import React, { Component } from 'react';
import axios from 'axios';
import ClientsList from './List/ClientsList'
import { FormControl } from 'react-bootstrap/lib';
import { API_URL, emptyList } from './../../../Actions/index';
import ManageMenu from './../ManageMenu'
import history from './../../../history';
import { connect } from 'react-redux';
import ApproveClient from './Action/ApproveClient'

class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      status:'Signed',
      showModal: false,
      page: 1,
      pages: 1,
      total: 0,
      nav:1,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeView !== prevProps.activeView) {
      this.getClientsList('Signed', 1);
    }
  }
  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0)
  }


	getClientsList = ( status, fromPage, lastName ) => {
    let query = { status }
    if( lastName ) query = {
      status,
      "profile.lastName" : { $regex: lastName, $options: 'i' }
    }
    var body={
      query,
      fromPage,
      pageSize: 50,
      user: this.props.user._id
    }
    axios.post(API_URL + '/clients/get/' + this.props.user.supplier, body)
			.then((ret) => {
        this.setState({
          clients: ret.data.docs,
          page: ret.data.page,
          pages: ret.data.pages,
          total: ret.data.total  
        })
      })
  }
  
  handleNext(path){
    history.push(path)
  }
  goBack() {
    window.history.back();
  }

  handleSelect(status){
    this.setState({ status, clients: undefined }, () => {
      this.getClientsList( status, 1, this.state.lastName )
    });
  }

  close() {
    this.setState({ showModal: false });
    this.getClientsList('Pending', 1);
    this.props.emptyList()
  }
  
  loadData() {
    this.getClientsList('Signed', 1);
  }
  
  render() {  

    let navigate = null;
    let user = this.props.user
    let permissions = user.permissions? user.permissions : {}
    
    if( this.state.status==='Pending' && ( ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1 || permissions.approveClient != false )){
      navigate =     
      <div>        
        <div id="clientSubMenuSpacer">&nbsp;</div>
        <div id="accept" onClick={()=>this.setState({ action: 'Approve', showModal: true })}>Approve</div>
        <div id="refuse" onClick={()=>this.setState({ action: 'Reject', showModal: true })} >Reject</div>
      </div>
    }
    
    let page = this.state.page;
    let pages = this.state.pages;
    
    return (
      <div className='background'>
        
        <div id="pageTitle">View clients
          <span id="returnButton" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></span>
        </div>
        <ManageMenu user={ user }/>
        
        <div id="clientBackground">         
          
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Signed')} className={this.state.status==='Signed'?"active_clientSubMenu leftMenu":"leftMenu"}>Signed</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Approved')} className={this.state.status==='Approved'?"active_clientSubMenu":""}>Approved</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Pending')} className={this.state.status==='Pending'?"active_clientSubMenu":""}>Pending</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Rejected')} className={this.state.status==='Rejected'?"active_clientSubMenu rightMenu":"rightMenu"}>Rejected</div>
            <div id="clientAction" onClick={()=>this.handleNext('client/add')}>
              <i className="fas fa-user-plus"></i>
            </div>
            
            <div className="quickSearch onlyLarge">
              <FormControl
                type="text"
                className="quickSearchInput"
                placeholder="Search lastname"
                onChange={(e)=> this.setState({ lastName: e.target.value }) }
              />
              <div className="searchAction" onClick={ ()=>this.getClientsList(this.state.status, this.state.page, this.state.lastName)}>
                <i className="fas fa-search"></i>
              </div>
            </div>

            { navigate }
          </div>
          
          { this.state.clients && this.state.clients.length > 0
            ? <div id="assetsList" >
                <div className="clientNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getClientsList(this.state.status, 1, this.state.lastName)
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getClientsList(this.state.status, Math.max( 1, page -1 ), this.state.lastName)
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="navLegend">{ this.state.total } clients</div>
                  <div className="navPage">page {page} / {pages} </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getClientsList(this.state.status, Math.min( pages, page + 1 ), this.state.lastName)
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getClientsList(this.state.status, pages, this.state.lastName)
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>
              </div>
            : null } 

          { this.state.clients? 
            <div className="clientsList"> 
              <ClientsList clients={this.state.clients} status={this.state.status}/>
            </div>
            : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }

        </div>

        { this.state.showModal &&
          <ApproveClient 
            show={this.state.showModal} 
            onHide={this.close.bind(this)}
            userNumber={ user.userNumber }
            selectedClients={this.props.selectedClients}
            action={this.state.action}
            supplier={ user.supplier }
          /> }

      </div>
    );
  }
}

const ClientsController = connect(
  function mapStateToProps(state) {
    return { 
      selectedClients:state.selectedClients
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Controller);

export default ClientsController