import { FormControl } from 'react-bootstrap/lib';
import React from 'react';
import { findEntitiesList } from '../../../../services/EntityService'

export class SelectEntity extends React.Component {

  constructor(props) {
    super(props)
    this.state = {};
  } 

  componentDidMount() {
    let { supplierId, userId, entities } = this.props
    if( !entities ){
      findEntitiesList( userId, supplierId)
      .then( entities => this.setState({ entities }))
    } else this.setState({ entities })
  } 


  render() {
    let { entities } = this.state

    let mappedCountries = entities && entities
      .filter( elem => elem.type == 'Country' )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map( entity =>{ 
        return <option key={entity._id} value={entity._id}>{entity.name}</option>
      })

    let mappedOrganisations = entities && entities
      .filter( elem => elem.type == 'Organisation' )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map( entity =>{ 
        return <option key={entity._id} value={entity._id}>{entity.name}</option>
      })

    let mappedBranches = entities && entities
      .filter( elem => elem.type == 'Branch' )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map( entity =>{ 
        return <option key={entity._id} value={entity._id}>{entity.name}</option>
      })

    let mappedShops = entities && entities
      .filter( elem => elem.type == 'Shop' )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map( entity =>{ 
        return <option key={entity._id} value={entity._id}>{entity.name}</option>
      })


    return (
      <div>
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className={this.props.format}
          onChange={(e)=> this.props.selectEntity(e.target.value) }
          >
          <option value="">Select entity</option>
          { mappedCountries && mappedCountries.length && <option value=""  className="agentOptionHeader">------ Countries ------</option> }
          { mappedCountries }
          { mappedOrganisations && mappedOrganisations.length && <option value=""  className="agentOptionHeader">------ Organisations ------</option> }
          { mappedOrganisations }
          { mappedBranches && mappedBranches.length && <option value=""  className="agentOptionHeader">------ Branches ------</option> }
          { mappedBranches }
          { mappedShops && mappedShops.length && <option value=""  className="agentOptionHeader">------ Shops ------</option> }
          { mappedShops }
        </FormControl> 
      </div>

    );
  }
}

  