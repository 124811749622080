import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getForgotPasswordToken } from '../../Actions/auth';
import Background2 from './Background2.jpg'
import Background3 from './Background3.jpg'
import Background0 from './Background0.jpg'
import Background5 from './Background5.jpg';
import { Carousel } from 'react-bootstrap/lib';
import './auth.css';

import history from './../../history';

const form = reduxForm({
  form: 'forgotPassword',
});

class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      forgotPasswordError: false
    }
  }

  static contextTypes = {
    router: React.PropTypes.object,
  }

  componentWillMount() {
    if (this.props.authenticated) {
      this.context.router.push('/analytics');
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.authenticated) {
      this.context.router.push('/analytics');
    }
  }

  handleNext(path){
    history.push(path)
  } 

  handleFormSubmit(e) {
    e.preventDefault();

    if (this.isValid()) {
      this.props.getForgotPasswordToken({email: this.state.email});
    }
  }

  isValid() {
    let valid = true;

    if (!this.state.email || this.state.email === '') {
      valid = false;
      this.setState({forgotPasswordError: true});
    } else {
      this.setState({forgotPasswordError:false})
    }

    return valid;
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div>
          <span><strong>Error!</strong> {this.props.errorMessage}</span>
        </div>
      );
    }
  }


  render() {

    return (
      <div className="carMain">

        <div id="login_zone">
          <div id="loginHeader">Reset credentials</div>
          <form id="loginForm" onSubmit={(e) => this.handleFormSubmit(e)}>
            <div>
              <div>
                { this.props.message && <p id="forgotInstructions">Email sent. Please check your emails and follow instructions. Thank you!</p> }
              </div>

              {this.renderAlert()}
              { this.state.forgotPasswordError && <label></label> }
              <Field 
                style={{
                  height:"35px", 
                  marginBottom: "15px",
                  border: "0.5px solid white",
                  paddingLeft: "15px",
                  fontSize: "14px"
                }}
                className="form-control"  
                onChange={(e) => {this.setState({email: e.target.value})} } 
                name="email" placeholder={'Please enter your email'}
                component="input" 
                type="text"
              />
            </div>
            <button type="submit" id="login_button">Send reset request</button>
          </form>
          <div id="forgotPwd" onClick={()=>this.handleNext('/')}>Login</div>
        </div>

        <Carousel controls={false} interval={12000} slide={true} indicators={false}> 
          <Carousel.Item>
              <img className="carItem responsive" alt="900x500" src={Background2} />
            </Carousel.Item>
            <Carousel.Item>
              <img className="carItem responsive" alt="900x500" src={Background0} />
            </Carousel.Item>
            <Carousel.Item>
              <img className="carItem responsive" alt="900x500" src={Background5} />
            </Carousel.Item> 
            <Carousel.Item>
              <img className="carItem responsive" alt="900x500" src={Background3} />
            </Carousel.Item>             
          </Carousel>;

    </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("state", state)
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, { getForgotPasswordToken })(form(ForgotPassword));