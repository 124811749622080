import React, { Component } from 'react';
import EditEntity from '../Action/EditEntity'
import './entities.css'
import { selectEntity, deselectEntity } from './../../../../Actions/index';
import { hasPermission } from './../../../../services/UserService'
import { connect } from 'react-redux';

class Elem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      entity: this.props.entity,
      selected: false,
      edit: false
    }
  };  

  closeEdit() {
    this.setState({ edit: false });
    this.props.refresh()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedEntities !== prevProps.selectedEntities || 
      this.props.secondEntity !== prevProps.secondEntity ||
      this.props.entity !== prevProps.entity
    ) {
      this.setState({ entity: this.props.entity });
    }
  }

  select(select){
    let entity = this.props.entity
    if( select ){
      this.setState({ selected: true })
      this.props.selectEntity(entity);
      this.props.select(entity)
    } else {
      this.setState({ selected: false })  
      this.props.deselectEntity(entity);
      this.props.select(undefined)
    }

  }

  edit = () => {
    let permission = hasPermission( this.props.user, undefined, undefined, true )
    if( permission ) this.setState({ edit: !this.state.edit })
  }

  render() {
    let { selectedEntities, user } = this.props

    let entity = this.state.entity
    let country, organisation, branch, shop = undefined
    if( selectedEntities ){
      country = selectedEntities.country
      organisation = selectedEntities.organisation
      branch = selectedEntities.branch
      shop = selectedEntities.shop
    }

    return (
      <div 
        className={ entity.type == 'Country' && country && country._id == entity._id 
          ? "entityItem activeEntity"
          : entity.type == 'Organisation' && organisation && organisation._id == entity._id
            ? "entityItem activeEntity"
            : entity.type == 'Branch' && branch && branch._id == entity._id
              ? "entityItem activeEntity"
              : "entityItem"
        } 
      >
        <div id="entityType" onClick={() => this.edit()}>
          { entity.type == 'Country' && <i className="fas fa-flag"></i> }
          { entity.type == 'Organisation' && <i className="fas fa-building"></i> }
          { entity.type == 'Branch' && <i className="fas fa-warehouse"></i> }
          { entity.type == 'Shop' && <i className="fas fa-store"></i> }
        </div>

        <div id="entityName" onClick={() => this.select(!this.state.selected)}>
          {entity.name? entity.name: '-'}
        </div>
        
        { this.state.edit &&
          <EditEntity
            show={ this.state.edit } 
            onHide={ this.closeEdit.bind(this) }
            user={ user }
            entity={ entity }
          /> }

      </div>
    )
  }  
}

export const Item = connect(
  function mapStateToProps(state) {
    return { 
      selectedEntities: state.selectedEntities,
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      selectEntity: entity => dispatch(selectEntity(entity)),
      deselectEntity: entity => dispatch(deselectEntity(entity))
    };
  },
)(Elem);
