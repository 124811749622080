import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../../Actions/index';
import { I18n } from 'react-i18next';
import './createTemplate.css'

class CreateTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state= {
      template: {
        description: false,
        supplier: this.props.user.supplier,
        createdBy: this.props.user._id
      },       
    }
  }; 
  
  createTemplate() {
    axios.post(API_URL + '/templates/create/'+ this.props.user.supplier, this.state.template)
      .then((ret) => {
        this.setState({ status: 'Success'});
        this.props.refresh()
      })
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  } 

  over(){
    this.props.onHide()
    this.setState({
      template: {
        code: undefined,
        text: undefined,
        type: undefined,
        localText: undefined,
        description: undefined,
        direction: undefined,
        validFor: undefined,
        days: undefined,
        supplier: this.props.user.supplier
      },       
      status: undefined,
    })
  }
  
  render() {
    let disabled = true;
    let template = this.state.template

    if( 
      template.code && 
      template.text && 
      template.type && 
      ( template.type != 'Automated' || ( template.direction && template.days )) && !this.state.status 
    ){
      disabled = false
    }

    
    let preview = template.localText? template.localText : template.text
    if( preview && preview.indexOf('{%ccy}') > -1 ) preview = preview.replace( /{%ccy}/g, 'USD' )
    if( preview && preview.indexOf('{%amount}') > -1 ) preview = preview.replace( '{%amount}', '50' )
    if( preview && preview.indexOf('{%paygNumber}') > -1 ) preview = preview.replace( '{%paygNumber}', '847921' )
    if( preview && preview.indexOf('{%balance}') > -1 ) preview = preview.replace( '{%balance}', '100' )

    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Create a new message template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">

              <FormControl 
                type="text" 
                className="templateDetailsInput"
                placeholder={'Internal code'}
                onChange={(e)=>{ 
                  let template = Object.assign({}, this.state.template); 
                  template.code = e.target.value; 
                  this.setState({ template }) 
                }}
              />

              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="templateDetailsAutomated"
                onChange={(e)=>{ 
                  let template = Object.assign({}, this.state.template); 
                  template.type = e.target.value; 
                  this.setState({ template });
                  }}
                >
                <option value={''}>Type</option>
                <option value={'Automated'}>Automated</option>
                <option value={'Group'}>Group</option>
              </FormControl>

              <textarea 
                className="templateDetailsTextInput"
                placeholder={'English text'}
                onChange={(e)=>{ 
                  let template = Object.assign({}, this.state.template); 
                  template.text = e.target.value; 
                  this.setState({ template }) 
                }}
              />

              <textarea 
                className="templateDetailsTextInput"
                placeholder={'Local translation'}
                onChange={(e)=>{ 
                  let template = Object.assign({}, this.state.template); 
                  template.localText = e.target.value; 
                  this.setState({ template }) 
                }}
              />

              <textarea 
                className="templateDetailsTextInput"
                placeholder={'Description (optional)'}
                onChange={(e)=>{ 
                  let template = Object.assign({}, this.state.template); 
                  template.description = e.target.value; 
                  this.setState({ template }) 
                }}
              />

              { this.state.template.type !== 'Automated'
              ? null
              : <div className="automationDetails">
                  <div className="automationLabelLong">Automatically send</div>
                  <FormControl 
                    type="text" 
                    className="detailsTemplateInputShort"                
                    placeholder={'#'}
                    onChange={(e)=>{ 
                      let template = Object.assign({}, this.state.template); 
                      template.days = e.target.value; 
                      this.setState({ template }) 
                    }}
                  />
                  <div className="automationLabelShort">days</div>
                  <FormControl 
                    controlId="formControlsSelect"
                    componentClass="select" 
                    className="detailsTemplateInput"
                    onChange={(e)=>{ 
                      let template = Object.assign({}, this.state.template); 
                      template.direction = e.target.value; 
                      this.setState({ template });
                      }}
                    >
                    <option value={''}>Choose</option>
                    <option value={'before'}>before</option>
                    <option value={'after'}>after</option>
                  </FormControl>
                  <div className="automationLabelLong majleft">device is locked</div>
                  <div className="automationLabelShort">Up to </div>
                  <FormControl 
                    type="text" 
                    className="detailsTemplateInputShort"                
                    placeholder={'#'}
                    onChange={(e)=>{ 
                      let template = Object.assign({}, this.state.template); 
                      template.validFor = e.target.value; 
                      this.setState({ template }) 
                    }}
                  />
                  <div className="automationLabelMedium">days after signing date</div>
               </div>
              }

            { this.state.template.type !== 'Automated'
              ? null
              : <div id="messageVariables">
                  <div id="messageVariableTitle">Available variables</div>
                  <div id="messageVariable">&#123;%paygNumber&#125;</div>
                  <div id="messageVariableLabel">Reminder to the client of his/her paygNumber</div>
                  <div id="messageVariable">&#123;%balance&#125;</div>
                  <div id="messageVariableLabel">Contract's remaining balance</div>
                  <div id="messageVariable">&#123;%amount&#125;</div>
                  <div id="messageVariableLabel">Monthly payment due</div>
                  <div id="messageVariable">&#123;%ccy&#125;</div>
                  <div id="messageVariableLabel">Currency of the payment</div>
                  <div id="messageVariableTitle">Message preview</div>
                  <div id="messagePreview">{ preview }</div>
                </div> }

            </div>
            {this.renderMessage()}            
            <br/> 
            <div id="modal_action">
            { this.state.status==='Success'
              ? null
              : <Button id="action_signup" disabled={disabled} onClick={ () => this.createTemplate() }>Create</Button> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'? 'Close': 'Abort'}</Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default CreateTemplate;