import React from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import GenderEvolution from './genderEvolution'
import GenderShot from './genderShot'

class Gender extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: undefined
    };
  };

  componentDidMount() {
    this.getGraph()
  }

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) this.getGraph();
  }

  getGraph = () => {
    let body = {
      criteria: {},
      userId: this.props.user._id
    }
    axios
      .post(API_URL + '/clients/analysis/' + this.props.user.supplier, body)
      .then((res) => {
        this.setState({
          data: res.data
        })
      })
  }

  render() {
    let data = this.state.data
      
    return (
      <div>   
        <div id="graphTitle">Gender split</div>
        <div id="graphWrapper">
         { data
          ? <div>
              <div>
                <GenderShot data={ data }/>
              </div>
              <div>
                <GenderEvolution data={ data }/>
              </div>
            </div>
          : <div>Loading</div>
         }
        </div>  
      </div>      
    );
  }
}

export default Gender