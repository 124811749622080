import React from 'react';
import {Bar} from 'react-chartjs-2';
import axios from 'axios';
import { API_URL, selectContracts, setTitle } from '../../../Actions/index';
import './graph.css'
import { connect } from 'react-redux';
import history from '../../../history';

var addDays = require('date-fns/add_days');
var eachDay = require('date-fns/each_day')
var format = require("date-fns/format")

class Graph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      label: [],
      data: [],
      result: undefined
    };
    this.loadData = this.getGraph()
  };
  
  componentDidUpdate(prevProps) {
    if( 
      this.props.freq !== prevProps.freq 
      || this.props.filter !== prevProps.filter
      || this.props.type !== prevProps.type
    ) {
      this.setState({ label: [], data: [], result: undefined }, () => {
        this.getGraph();
      })
    }
  }

  getGraph = () => {

    let criteria = Object.assign( {}, this.props.filter)
    criteria.type = this.props.type
    criteria.freq = this.props.freq
    let body = {
      criteria,
      userId: this.props.user._id
    }
    axios
      .post(API_URL +'/contracts/analysis/' + this.props.user.supplier, body)
      .then((res) => {
        this.setState({  data: res.data  })
      })
  }

  handleNext(path){
    history.push(path)
  }

  click = async (item, label, enrichedData ) => {
    let action = 'deployed'
    if(this.props.type === 'REPOSSESSED') action = 'repossessed'
    if(this.props.type === 'PAIDOFF') action = 'paid-off'
    if(this.props.type === 'WRITEOFF') action = 'written-off'

    if( item[0] && item[0]._index){
      let contracts = enrichedData[item[0]._index]
      let detailedContracts = []
      let title = `All contracts ${action} on ${label[item[0]._index]}`
      let query = {
        _id: { $in: contracts }
      }
      try { 
        let res = await axios.put(API_URL + '/contracts/search/' + this.props.user.supplier, { query })
        detailedContracts = res.data
      } catch(err){ 
        console.log(err) 
      }
      this.props.selectContracts( detailedContracts )
      this.props.setTitle( title )
      this.handleNext('/contracts-analyze')
    }
  }

  render() { 
    
    let data = this.state.data

    let dataset = []
    let enrichedData = []
    let label = []
    let graphLabel = []

    if( data && data.length ){

      if( this.props.freq == 'daily'){

        let start = this.props.filter.startDate? new Date( this.props.filter.startDate ): addDays( new Date(), -30 ) 
        let end = this.props.filter.endDate? new Date( this.props.filter.endDate ): new Date()
        let allDays = eachDay( start, end )
        graphLabel = allDays.map( elem => format(elem, 'DD-MM') );
        label = allDays.map( elem => format(elem, 'YYYY-MM-DD') );
      } else {
        let allDates = data
          .map( elem => elem._id.date )
          .sort(function(a, b) {
            return new Date(a) - new Date(b)
          })
        label = [ ...new Set(allDates) ]
        graphLabel = label
      }

      label.forEach( date => {
        
        let dataPoint = data.filter( elem => elem._id.date == date )
        let number = dataPoint.reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(number) && number > 0 ) {
          dataset.push(number)
          enrichedData.push( dataPoint[0].items )
        }
        else {
          dataset.push(0)
          enrichedData.push([])
        }
      })

    }

    
    let options = {
      onClick: (e, item) => this.click(item, label, enrichedData),
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          },
          
          ticks: {
            fontColor: 'rgba(255, 255, 255, 0.8)',
            fontSize: 10
          },
        }],
        yAxes: [{
          gridLines: {
            display:false
          }, 

          ticks: {
            fontColor: 'rgba(255, 255, 255, 0.8)',
            beginAtZero: true,
            fontSize: 10
          },
        }]
      }
    }

    return (
      <div>
        { dataset
          ? <Bar
            data={{
              labels: graphLabel, 
              datasets: [
                {
                  label: 'Number',
                  backgroundColor: this.props.color,
                  borderColor: this.props.color,
                  borderWidth: 1,
                  hoverBackgroundColor: this.props.hoverColor,
                  hoverBorderColor: this.props.hoverColor,
                  data: dataset
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ options }
          /> 
          : <div className="genericLoader graphLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }
      </div>
    );
  }
}

export const Analysis = connect(
  function mapStateToProps(state) {
    return { 
      listOfContracts: state.listOfContracts,
      title: state.title
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      selectContracts: (list) => dispatch(selectContracts(list)),
      setTitle: (title) => dispatch( setTitle(title) )
    };
  },
)(Graph);
