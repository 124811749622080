import React, { Component } from 'react';
import {ClientHeader} from './ClientHeader'
import {SelectableClientsListItem} from './ClientsListItem'
import './clients.css';

class ClientsList extends Component {

  render() {    
    let mappedClients = null;
    let clients = this.props.clients;
    let status = this.props.status
    clients.sort(function(a, b) {
      if( status === 'Signed' ) return new Date(b.signingDate) - new Date(a.signingDate);
      if( status === 'Approved' || status == 'Rejected' ) return new Date(b.creditDecisionDate) - new Date(a.creditDecisionDate);
      return new Date(b.createdAt) - new Date(a.createdAt)
    })
    if(clients){
     
      mappedClients = clients.map((client, index) =>{ 
        return (
          <SelectableClientsListItem key={ client._id } client={client} status={ status } selectable={this.props.selectable}/>
        )
      })
    }

    return (
      <div>
        { clients && clients.length > 0? <ClientHeader status={ status }/>:null}
        { mappedClients }
      </div>
    );
  }
}

export default ClientsList