import React, { Component } from 'react';
import EditAgent from './../Action/EditAgent'
import history from './../../../../history';
import './agents.css';
import { addToAgentList, removeFromAgentList } from './../../../../Actions/index';
import { connect } from 'react-redux';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state= {
      showModal: false
    }
  };  

  close() {
    this.setState({ showModal: false });
    let page = this.props.page? this.props.page : 1
    this.props.refresh(this.props.agent.role, page)
  }

  handleNext(path){
    history.push(path)
  }

  handleSelect(){
    if(this.props.selectedAgents.indexOf(this.props.agent.agentNumber) < 0){
      this.props.addToAgentList(this.props.agent.agentNumber)
    } else this.props.removeFromAgentList(this.props.agent.agentNumber)
  }

  render() {
    let agent = this.props.agent;

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let lastSync = '-'
    
    if(agent.lastSyncDate){
      lastSync = new Date(agent.lastSyncDate)
      lastSync = lastSync.toLocaleDateString("en-EN", options)
    }
 
    return (
      <div>
        <div className="agentItem">
          <div id="agentsEntity" >
            { agent.entity
                ? agent.entity.name? agent.entity.name : '-'
                : agent.country? agent.country : '-'
              }          
          </div> 
          <div id="agentsId"  className="active" onClick={()=>this.handleNext('/agent/id='+agent.agentNumber)}>
            { agent.agentNumber }
          </div>  
          <div id="agentsMedium" >
            { agent.profile ? agent.profile.firstName: '-'}
          </div>
          <div id="agentsMedium" >
            {agent.profile ? agent.profile.lastName: '-'}
          </div>
          <div id="agentsMedium" className='onlyLarge'>
            {agent.role
              ? agent.role == 'Administrator' ? 'Warehouse' : agent.role
              : '-'}
          </div>
          <div id="agentsLarge" className='onlyLarge'>
            {agent.email? agent.email:'-'}
          </div>     
          <div id="agentsSmall"  >
            { agent.contracts }
          </div>       
          
          <div id="agentsSmall"  >
            { agent.assets }
          </div>  
          <div id="agentsSmall">
            { agent.defective }
          </div>  
          <div id="agentsSmall" className='onlyLarge' >
            { agent.prospects }
          </div>  

          <div id="agentsSmall" className='onlyLarge' >
            { agent.rejections }
          </div>  
             
          <div id="agentsSync" className='onlyLarge' >
            { lastSync }
          </div> 

          { ! this.props.selectable && !this.props.hideEdit
            ? <div id="agentsExtraSmall" className="edit" onClick={()=>this.setState({showModal: !this.state.showModal})}>
                <i className="fas fa-edit"></i>
              </div>
            : null }

          { this.props.selectable && !this.props.hideEdit
            ? <div id="agentsExtraSmall" className="active" onClick={()=>this.handleSelect()}>
                { this.props.selectedAgents.indexOf(agent.agentNumber) > -1
                  ? <i className="fas fa-check-square"></i>
                  : <i className="far fa-check-square"></i> }  
              </div> 
            : null }

        </div>
        { this.state.showModal
          ? <EditAgent 
              show={ this.state.showModal } 
              supplierId={ this.props.supplier }
              onHide={ this.close.bind(this) }
              agent= {agent}
            />  
          : null }
      </div>
    )
  }  
}

export const AgentsListItem = connect(
  function mapStateToProps(state) {
    return { 
      selectedAgents: state.selectedAgents
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToAgentList: agent => dispatch(addToAgentList(agent)),
      removeFromAgentList: agent => dispatch(removeFromAgentList(agent))
    };
  },
)(Item);