import React, { Component } from 'react';
import { FormControl, Button } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL, answerQuestion } from '../../../../Actions/index';
import { connect } from 'react-redux';
import './../credit.css';

class Answer extends Component {
  constructor(props) {
		super(props)
		this.state = {
      creditItem: {
        question: this.props.question._id,
        textQuestion: this.props.question.question,
        translatedQuestion: this.props.question.translatedQuestion,
        questionnaire: this.props.questionnaire,
        contract: this.props.contract,
        client: this.props.client,
        creditScore: this.props.creditScoreId,
      },
    };
  }  

  answerQuestion(){
    axios.post(API_URL + '/creditscores/addItem/' + this.props.supplier + '/'+ this.props.creditScoreId, this.state.creditItem)
			.then((ret) => {
        this.setState({ status: 'Success!' })
      })
  }


  handleSubmit(){
    this.answerQuestion();
    this.props.answerQuestion(this.props.index + 1)
  }

  render() {    
    let navigate, mappedOptions = null;
    let question = this.props.question
    let { possibleAnswers, options } = question
    
    let disabled = true
    if( this.state.creditItem.answer ) disabled = false

    if( question.selectable && possibleAnswers ){
      mappedOptions = possibleAnswers.map((answer,index) =>{
        return <option key={index} value={answer}>{answer}</option>
      })
    }

    if( question.isNumeric ){
      navigate =           
        <FormControl 
          type="number" 
          className="modalInput"
          placeholder="Type answer"
          onChange={(e)=>{ 
            let creditItem = Object.assign({}, this.state.creditItem); 
            creditItem.answer = e.target.value; 
            this.setState({ creditItem });
            }}
        />
    }
    if( question.selectable && possibleAnswers ){
      navigate =           
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="modalInput"
          onChange={(e)=>{ 
            let creditItem = Object.assign({}, this.state.creditItem); 
            creditItem.answer = e.target.value; 
            if( options ){
              let pair = options.find( elem => elem.answer == e.target.value )
              let translatedAnswer = pair? pair.translatedAnswer : undefined
              creditItem.translatedAnswer = translatedAnswer
            }
            this.setState({ creditItem });
            }}        >
          <option value="">Choose</option>
          {mappedOptions}
        </FormControl>
    }
    else navigate =           
      <FormControl 
        type="text" 
        className="modalInput"
        placeholder="Type answer"
        onChange={(e)=>{ 
          let creditItem = Object.assign({}, this.state.creditItem); 
          creditItem.answer = e.target.value; 
          this.setState({ creditItem });
          }}
      />

    return (
      <div id="fullQuestion">
        <div id="cat">{ question.category }</div>
        <div id="que">{ question.question }</div>
        <div id="ans">{ navigate }</div>
        { this.props.index===this.props.turn||!this.props.turn
          ? <Button disabled={disabled} id="next" onClick={()=>this.handleSubmit()}>Answer</Button>
          : <div id="done">Done</div> }
      </div>
    );
  }
}


export const AnswerQuestion = connect(
  function mapStateToProps(state) {
    return { 
      turn:state.turn
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      answerQuestion: turn => dispatch(answerQuestion(turn)),
    };
  },
)(Answer);
