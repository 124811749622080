import React, { Component } from 'react';
import './contracts.css'

export class ContractHeader extends Component {
  
  render() {

    let navigate = null;
    if(this.props.contractStatus==='Signed'){
      navigate = 
      <div>
        <div className="contractsSmall onlyLarge" >
          Agent
        </div> 
        <div className="contractsSmall onlyLarge" >
          Total paid
        </div>  
        <div className="contractsSuperSmall" >
          %
        </div> 
        <div className="contractsSuperSmall" >
          Status
        </div>   
      </div>
    }
    else if (this.props.contractStatus==='Approved' ){
      navigate = 
      <div>
        <div className="contractsSmall onlyLarge" >
          Submitted
        </div>  
        <div className="contractsSmall" >
          Approval date
        </div> 
        <div className="contractsExtraSmall onlyLarge" >
          Status
        </div>   
        <div className="contractsSmall onlyLarge" >
          Agent
        </div>             
      </div>
    }
    else {
      navigate = 
      <div>
        <div className="contractsSmall" >
          Submitted
        </div>  
        <div className="contractsSmall onlyLarge" >
          Status
        </div>  
        <div className="contractsSmall onlyLarge" >
          Agent
        </div> 
        <div className="contractsExtraSmall" >
          Action
        </div>   
      </div>
    }

    return (
      <div className="contractsTitle">
        <div className="contractsNumber" >
          Contract id
        </div>  
        <div className="contractsDeal onlyLarge" >
          Deal
        </div>
        <div className="contractsExtraSmall onlyLarge" >
          Deal type
        </div>  
        <div className="contractsEntity onlyLarge" >
          Entity
        </div>             
        <div className="contractsMedium" >
          Client name
        </div>   
        { navigate }  
        <div className="contractsDate">
          Last update
        </div>     
        { this.props.contractStatus==='Signed'
          ? <div className="contractsDate" >  Next update </div>
          : null }   
      </div>
    )
  }  
}