import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';

class CashTransfer extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false,
    };
  } 

  reverse(){

    var query = {
      newContractNumber: this.state.newContractNumber,
      reference: this.state.reference,
      contractNumber: this.props.contractNumber,
      amount: this.state.amount,
      userId: this.props.user._id
    }

    this.setState({disabled: true, status: 'One second...'})

    axios.post(API_URL + '/payments/movecash/' + this.props.supplierId, query)
			.then( ret => this.setState({ status: ret.data.message }) )
			.catch( err => {
        console.log(err)
      })
    }

  over(){
    this.props.onHide();
    this.setState({status: undefined, disabled: false});
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  render() {
    let disabled = true;
    if( !this.state.disabled && this.state.newContractNumber && this.state.reference && this.state.amount){
      disabled = false
    }
    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Cash transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">
          <div id="reversalDetails">
            Please enter the contractNumber of the contract to which the cash should be transfered
          </div>
          <FormControl 
            type="text" 
            className="contractEditInput"
            placeholder="ContractNumber"
            onChange={(e)=>{ this.setState({ newContractNumber: e.target.value }) }}
          />
          <div id="reversalDetails">
            Please enter the amount you wish to transfer
          </div>
          <FormControl 
            type="number" 
            className="contractEditInput"
            placeholder="Amount"
            onChange={(e)=>{ this.setState({ amount: e.target.value }) }}
          />
          <div id="reversalDetails">
            Please enter the reference of the new payment (e.g. asssetNumber@contractNumber for a down-payment)
          </div>
          <FormControl 
            type="text" 
            className="contractEditInput"
            placeholder="New reference"
            onChange={(e)=>{ this.setState({ reference: e.target.value }) }}
          />
        </div>
        <div id="reversalDetails" style={{marginTop: '15px'}}>
          Upon successful transfer, both contracts will be updated.
          <br/><br/>
        </div>
        { this.renderMessage() }            
        <br/>
        <div id="modal_action">
          { this.state.status
            ? null
            : <Button id="confirm_action" disabled={disabled} onClick={()=>this.reverse()}>Transfer</Button>
          }
          <Button id="action_cancel" onClick={() => this.over()}>
            { this.state.status ?'Close':'Abort' }
          </Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default CashTransfer;