import React, { Component } from 'react';
import {Button, Modal, FormControl} from 'react-bootstrap/lib';
import { I18n } from 'react-i18next';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';

var DatePicker = require("react-bootstrap-date-picker");

class SignContract extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status: undefined,
      assets: undefined,
      contract:{
        signingDate: undefined, 
        asset: undefined,
        contractId: this.props.contract._id
      }, 
      disabled: false
    };
  } 

  componentDidMount() {
    this.loadData()
  }

  getSerialNumbers(product) {
    var body = {
      product: product,
    }
    axios.post(API_URL + '/products/byProduct/'+ this.props.supplierID, body)
			.then((ret) => {
        this.setState({
          assets: ret.data
        })
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  signContract(){
    this.setState({disabled: true, status: 'One second...'})
    let body = {
      signingDetails : [this.state.contract],
      user: this.props.userID
    }
    axios.put(API_URL + '/contracts/sign/' + this.props.supplierID, body)
			.then( ret => {
        this.setState({ status: ret.data.message })
      }).catch( err => this.setState({ status: 'Error' }))
  }

  loadData() {
    this.getSerialNumbers(this.props.contract.product);
  } 
  
  render() {
    let mappedSerialNumbers = null
    if(this.state.assets){
      mappedSerialNumbers = this.state.assets.map((asset, index) =>{ 
        return (
          <option key={index} value={asset.assetNumber}>{asset.paygNumber}</option>
        )
      })
    }
    let isDisabled = ( this.state.contract.signingDate && this.state.contract.asset ) ? false : true
    if(this.state.disabled){isDisabled=true}
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Finalize contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">
              <div className="contractLabel">
                Select an asset
              </div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="contractInput"
                onChange={(e)=>{ 
                  let contract = Object.assign({}, this.state.contract); 
                  contract.asset = e.target.value; 
                  this.setState({ contract });
                  }}
                >
                <option value="">Choose a serial number</option>
                { mappedSerialNumbers }
              </FormControl> 
              <div className="contractLabel">Signing date</div>
              <DatePicker 
                className ="contractInput"
                value={this.state.contract.signingDate}  
                onChange={(e)=>{ let contract = Object.assign({}, this.state.contract); contract.signingDate = e; this.setState({ contract }) }}
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />
              
              { this.renderMessage() }

              <div id="modal_action">
                { this.state.status !== 'Success' &&
                  <Button id="action_signup" disabled={isDisabled} onClick={this.signContract.bind(this)}>Sign</Button>
                }
                <Button id="action_cancel" onClick={this.props.onHide}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
              </div>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default SignContract;