import React from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import { Kids } from './Graphs/kids'

class SDG4 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      error: undefined
    };
  };

  componentDidMount() {
    this.getGraph()
  }

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) this.getGraph();
  }

  getGraph = () => {
    
    axios
      .post(API_URL + '/creditScores/analysis/' + this.props.supplier, {
        codes: ['children_younger_than_18_in_household', "children_under_18_in_household", "Number_of_children_under_18", "children"]
      })
      .then( res => this.setState({ data: res.data }) )
      .catch( err => this.setState({ error: err.message }) )
  }

  render() {
    let data = this.state.data
    let label = []
    let dataset = []

    if( data && !this.state.error ){
      let signed = data.filter( elem => elem._id.status == 'Signed' )
      
      let allMonths = signed
        .map( elem => elem._id.signingDate )
        .filter( elem => elem && elem != '2018-05' )
        .sort(function(a, b) {
          return new Date(a) - new Date(b)
        })
      label = [ ...new Set(allMonths) ]

      label.forEach( month => {
        
        let kids = signed
          .filter( elem => elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.sum; }, 0)
        if( !isNaN(kids) && kids > 0 ) dataset.push(kids)
        else dataset.push(0)

      })
    }
      
    return (
      <div >   
        <div id="impactGraphWrapper">
          { !this.state.error
            ? data
              ? <Kids data={ dataset } label={ label } title={"Children impacted"} multiplier={1} />             
              : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div>
            : <div id="impactError"> 
                <div>Data unavailable</div>
                <div id="impactErrorLogo"><i className="fas fa-exclamation-circle"></i></div>
                <div id="impactErrorInfo">To report on SDG 4, add a question with code "Number_of_children_under_18" in your questionnaire</div>
            </div> }
        </div>  
        <div id="impactGraphWrapper">
          { !this.state.error
            ? data
              ? <Kids data={ dataset } label={ label } title={"Hours of study enabled"} multiplier={1.9} />              
              : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div>
            : <div id="impactError"> 
                <div>Data unavailable</div>
                <div id="impactErrorLogo"><i className="fas fa-exclamation-circle"></i></div>
                <div id="impactErrorInfo">To report on SDG 4, add a question with code "Number_of_children_under_18" in your questionnaire</div>
            </div> }
            
        </div>  
      </div>      
    );
  }
}

export default SDG4