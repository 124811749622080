import React from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class AddAuxiliary extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      auxiliary: {
        type: this.props.type,
        product: this.props.product._id,
        supplier: this.props.user.supplier
      },       
      status: undefined,
      data: undefined,
      disabled: false
    };
  } 


  create() {
    axios.post(API_URL + '/auxiliaries/create/'+ this.props.user.supplier, 
        this.state.auxiliary
      ).then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        console.log(err)
        this.setState({status: 'Error'})
      }); 
  }
  
  over(){
    this.props.onHide();
    this.props.refresh()
    this.setState({
      auxiliary: {},       
      status: undefined,
      disabled: false
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }  

  render() {
    let product = this.props.product
    let auxiliary = this.state.auxiliary
    let isDisabled = ( !auxiliary.name || !auxiliary.sku )? true : false

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="medium"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">
            Add new {this.props.type} to {product.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">

            <div id="productAddFieldMedium">
              <FormControl 
                type="text" 
                className="productDetailsInput"
                value={ this.state.auxiliary.name }
                placeholder={'Name'}                
                onChange={(e)=>{ let auxiliary = Object.assign({}, this.state.auxiliary); auxiliary.name = e.target.value; this.setState({ auxiliary }) }}
              />
            </div>   
            <div id="productAddFieldMedium">
              <FormControl 
                type="text" 
                className="productDetailsInput"
                value={ this.state.auxiliary.sku }
                placeholder={'SKU'}                
                onChange={(e)=>{ let auxiliary = Object.assign({}, this.state.auxiliary); auxiliary.sku = e.target.value; this.setState({ auxiliary }) }}
              />
            </div>   
            <div id="productAddFieldLong">
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="productDetailsInput"
                onChange={(e)=>{ let auxiliary = Object.assign({}, this.state.auxiliary); auxiliary.hasSerialNumber = e.target.value; this.setState({ auxiliary }) }}
                > 
                <option value={""}>Items have own unique serial number</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </FormControl>
            </div>                  
         
            <textarea 
              type="textera" 
              className="productFull"
              placeholder={'Short description (Optional)'}
              onChange={(e)=>{ let auxiliary = Object.assign({}, this.state.auxiliary); auxiliary.description = e.target.value; this.setState({ auxiliary }) }}
            />

            { this.renderMessage() }

            <div id="modal_action">
              { this.state.status !== 'Success' &&
                <Button id="action_signup" disabled={isDisabled} onClick={this.create.bind(this)}>Create</Button> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddAuxiliary;