import React, { Component } from 'react';
import './utils.css';

class DoubleWrapper extends Component {
  
  render() {    

    return (
      <div>
        <div className="infoWrapper">
          <p id="infoLabel">{this.props.label}</p>
          <p id="infoDataShortLeft">{this.props.data1}</p>
          <p id="infoDataShort">{this.props.data2}</p>
        </div>
      </div>
    );
  }
}

export default DoubleWrapper