import React, { Component } from 'react';
import './assets.css'
import { filterAssets } from './../../../../Actions/index';

import { connect } from 'react-redux';

class Header extends Component {

  render() {

    return (
      <div className="assetsTitle">
        <div id="assetsEntity" >Entity</div> 
        <div id="assetsNumber" >PaygNumber</div>     
        <div id="assetsSmall" className='onlyLarge'>Batch</div> 
        <div id="assetsSmall" >Status</div> 
        <div id="assetsProduct" >Product</div>   
        <div id="assetsMedium" className='onlyLarge'>Serial number</div>   

        { this.props.search && <div id="assetsSmall" >Last update</div> } 
        { this.props.assetStatus == 'PENDING' && <div id="assetsSmall" >Added on</div> } 
        { this.props.assetStatus == 'INSTOCK' && <div id="assetsInstockSince" >Instock since</div> } 
        { this.props.assetStatus == 'DEPLOYED' && <div id="assetsSmall" className='onlyLarge'>Distributed on</div> } 

        { this.props.assetStatus == 'DEFECTIVE' && <div id="assetsSmall" className='onlyLarge'> Defective on </div>} 

        <div id="assetsMedium"  className='onlyLarge'>
          {this.props.assetStatus==='DEPLOYED'? 'Owned by' : 'Held by'}
        </div>  

        { this.props.assetStatus ==='DEPLOYED' && !this.props.search && <div id="assetsSmall" >Contract </div> }

        { this.props.search && <div id="assetsSmall" >Contract / Instock since</div> }

        { !this.props.assetStatus || this.props.search
          ? <div id="assetsExtraSmall" >Days </div> 
          : null }

        { this.props.assetStatus == 'INSTOCK' && <div id="assetsInstockSince" className='onlyLarge'>Held since</div> } 

        { this.props.assetStatus !=='PENDING' && !this.props.search
          ? <div id="assetsExtraSmall" >
              { this.props.assetStatus==='INSTOCK'
                ? 'Days held'
                : 'Days' }
            </div>   
          : null }

        { this.props.assetStatus == 'PENDING' || this.props.assetStatus == 'DEFECTIVE'
          ? <div id="assetsAction">
              Action
            </div> 
          : null } 

        { this.props.assetStatus == 'INSTOCK' && !this.props.search &&
          <div id="assetsSecondHand">2nd</div> }  

      </div>
    )
  }  
}

export const AssetHeader = connect(
  function mapStateToProps(state) {
    return { 
      assetFilter:state.assetFilter
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      filterAssets: view => dispatch(filterAssets(view))
    };
  },
)(Header);
