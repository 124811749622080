import React, { Component } from 'react';

export class Header extends Component {
  
  render() {
    return ( 
      <div className="auxiliaryPricingsTitle">
        <div id="auxiliaryPricingReference">Reference</div>
        <div id="auxiliaryPricingType">Type</div>
        <div id="auxiliaryPricingAux">Auxiliary</div>
        <div id="auxiliaryPricingName">Name</div>
        <div id="auxiliaryPricingProduct">Associated product</div>
        <div id="auxiliaryPricingDeal">Associated deal</div>
        <div id="auxiliaryPricingCost">Upfront cost</div>
        <div id="auxiliaryPricingCost">Total cost</div>
      </div>
    )
  }  
}
