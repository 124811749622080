import React, { Component } from 'react';
import {Button, FormControl} from 'react-bootstrap/lib';
import './../contract.css';
import './../../Utils/utils.css'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class SendBonus extends Component {

  constructor(props) {
    super(props);
    this.state= {
      bonus:{
        numberOfDays: undefined,
        userId: this.props.user._id
      }, 
      disabled: true, 
      activationCode: undefined,    
      status: undefined,
    }
  }; 

  sendBonus() {
    this.setState({ disabled: true })
    axios.put(API_URL + '/contracts/sendbonus/'+ this.props.contract.supplier+ '/' + this.props.contract.contractNumber, this.state.bonus)
      .then((ret) => {
          this.setState({ status: 'Success!'});
      })
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if( this.state.status ) {
      return (
        <div id={this.state.status == 'Success!'?'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  } 

  render() {    

    return (
      <div>
        <div>
          <div id="contractEditTitle">Send one-off activation code</div>
          <div id="bonusEditInput">
            <FormControl 
                type="number" 
                className="contractEditInput"
                placeholder="Type"
                onChange={(e)=>{ 
                  let bonus = Object.assign({}, this.state.bonus); 
                  bonus.numberOfDays = e.target.value; 
                  this.setState({ bonus });
                  this.setState({ disabled: false }) 
                }}
              />
          </div>
          <div id="bonusEditLabel2">days of energy</div>
          <div id="bonusEditLabel">
            <Button id="bonusSend" disabled={this.state.disabled} onClick={()=>this.sendBonus()}>Send</Button>
          </div>
        </div>
        {this.renderMessage()} 
      </div>
    );
  }
}

export default SendBonus