import React, { Component } from 'react';
import AgentTargetsDelete from './../Action/AgentTargetsDelete'
import AgentTargetsEdit from './../Action/AgentTargetsEdit'
import './agentTargets.css';

export class AgentTargetsListItem extends Component {
  
  constructor(props) {
		super(props)
		this.state = {
      delete: false,
      pause: false
    };
  }

  close(){
    this.setState({ delete: false })
    this.props.refresh();
  }

  pause(){
    this.setState({ pause: false })
    this.props.refresh();
  }

  render() {
    let target = this.props.agentTarget;

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let dateFrom = '-'
    let dateTo = '-'
    
    if(target.dateTo){
      dateTo = new Date(target.dateTo)
      dateTo = dateTo.toLocaleDateString("en-EN", options)
    }

    if(target.dateFrom){
      dateFrom = new Date(target.dateFrom)
      dateFrom = dateFrom.toLocaleDateString("en-EN", options)
    }
    let category = target.category
    if( category == 'Utilisation') category = 'Utilisation'

    let freq = <span id="freq">- monthly</span>
    if( target.freq == 'day' ) freq = <span id="freq">- daily</span>
    if( target.freq == 'week' ) freq = <span id="freq">- weekly</span>
    return (
      <div>
        <div className="targetItem">

          <div className="targetItemTitle">{ category } target { freq }</div>

          { this.props.edit
            ? <div className="targetDelete" onClick={() => this.setState({ delete: !this.state.delete })}><i className="fas fa-trash"></i></div>
            : null }
          { this.props.edit
            ? <div className="targetEdit" onClick={() => this.setState({ pause: !this.state.pause })}><i className="far fa-pause-circle"></i></div>
            : null }

          <div className={ target.dateTo? "targetItemStatus": "targetItemStatus activeTarget"}>{ target.dateTo ? 'Closed on ' + dateTo : 'Active '}</div>

          <div className="targetWrapper">
            <div className="targetItemLabel">Applicable deal(s)</div>
            <div className="targetItemData">{ target.deal? target.deal.dealName: 'All' }</div>
          </div>

          <div className="targetWrapper">
            <div className="targetItemLabel">Target</div>
            { target.category == 'Revenues'
              ? <div className="targetItemData">
                  { target.ccy }{ target.target } generated per { target.freq }
                </div>
              : null }
            { target.category == 'Sales' 
              ? <div className="targetItemData">
                  { target.target } new sales per { target.freq }
                </div>
              : null }
            { target.category == 'TeamSales' 
              ? <div className="targetItemData">
                  { target.target } team sales per { target.freq }
                </div>
              : null }
            { target.category == 'Utilisation'
              ? <div className="targetItemData">
                  { target.target }% utilisation rate
                </div>
              : null }
          </div>

          <div className="targetWrapper">
            <div className="targetItemLabel">Set on</div>
            <div className="targetItemData">{ dateFrom }</div>
          </div>

          <div className="targetWrapper">
            <div className="targetItemLabel"> Set by</div>
            <div className="targetItemData">{ target.createdBy && target.createdBy.profile
              ? target.createdBy.profile.firstName.slice(0,1) + '. ' +  target.createdBy.profile.lastName
              : '-' }
            </div>
          </div>
          
          { target.remunerated
            ? <div>
                <div className="targetWrapper">
                  <div className="targetItemLabel">Digital payoff</div>
                  { target.category == 'Sales' || target.category == 'Revenues' || target.category == 'TeamSales' && target.digital
                    ? <div className="targetItemData">{ target.ccy } { target.digital.toLocaleString()} if target achieved</div>
                    : null } 
                </div>
                { target.share > 0
                  ? <div className="targetWrapper">
                      <div className="targetItemLabel">Variable payoff</div>
                      { target.category == 'Sales' && target.share > 0
                        ? <div className="targetItemData">{ target.ccy } { target.share} per new sales</div>
                        : null } 
                      { target.category == 'TeamSales' && target.share > 0
                        ? <div className="targetItemData">{ target.ccy } { target.share} per team sales</div>
                        : null } 
                    </div> 
                  : null }
              </div>
            : <div className="targetWrapper">
                <div className="targetItemLabel">Soft target</div>
                <div className="targetItemData">No additional commission</div>
              </div>
          }

          { target.closedBy
            ? <div className="targetWrapper">
                <div className="targetItemLabel">Closed by</div>
                <div className="targetItemData">{ target.closedBy.profile
                  ? target.closedBy.profile.firstName.slice(0,1) + '. ' +  target.closedBy.profile.lastName
                  : '-' }
                </div>              
              </div>
            : null }

        </div>

        { this.state.delete
          ? <AgentTargetsDelete 
              show={this.state.delete} 
              user={ this.props.user._id }
              target={ target._id }
              onHide={this.close.bind(this)}
              supplier= { this.props.supplier }
            /> 
          : null }

        { this.state.pause
          ? <AgentTargetsEdit
              show={this.state.pause} 
              userNumber={ this.props.userNumber }
              target={ target._id }
              onHide={this.pause.bind(this)}
              supplier= { this.props.supplier }
            /> 
          : null }


      </div>
    )
  }  
}