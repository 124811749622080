

import axios from 'axios';
import { API_URL } from './../Actions/index';

export function findAgents( query, userId, supplierId, entity ){
  return new Promise ( function(resolve, reject){

  if( !query.role ) Object.assign( query, {
    role: { $ne: 'Disabled' }
  })

  axios
    .post(API_URL + '/agents/light-search/' + supplierId, {
      query,
      userId,
      entity
    })
    .then( ret => resolve(ret.data) )
    .catch(err => reject(err) )
  })
}