import React, { Component } from 'react';
import './../notes.css';
import EditTask from '../EditTask'
import history from './../../../../history';
import ConversationPopUp from './../Conversations/ConversationPopUp'

export class TasksListItem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      showModal: false, 
      showConvo: false,
      showDetails: false,
      showReply: false,
    }
  }; 
  
  handleNext(path){
    history.push(path)
  }

  close() {
    this.setState({ showModal: false });
  }
  closeHistory(){
    this.setState({ showConvo: false });
  }

  closeReply() {
    this.setState({ showReply: false });
    if(this.props.agent){
      this.props.refresh( this.props.noteView, this.props.userId, 1 )
    }
  }

  render() {
    let note = this.props.note
    var options = { month: 'short', day: 'numeric' };
    let date = new Date(note.date)
    date = date.toLocaleDateString("en-EN", options)
    let text = note.text
    if( this.props.noteView == 'Inbox' && note.lastReply ) text = note.lastReply

    return (
      <div>
        <div className='noteItem'>
          <div id="notesDate" >
            { date }
          </div>   
          <div id="notesExtraSmall" className='onlyLarge'>
            { note.type? note.type:-'' }
          </div>          
          
          { note.author == this.props.user._id && note.agentTo && !this.props.agent &&
            <div id="notesDest"  className='active' onClick={()=>this.handleNext('/agent/id='+note.agentTo.agentNumber)}>
              { note.agentTo.profile
                ? note.agentTo.profile.firstName.substring(0, 1) + '. ' + note.agentTo.profile.lastName 
                : '-' }
            </div> }

          { note.author !== this.props.user._id && !this.props.agent && !note.agentFrom &&
            <div id="notesDest">{ note.writtenBy }</div> }

          { note.author !== this.props.user._id && !this.props.agent && note.agentFrom &&
            <div id="notesDest" className='active' onClick={()=>this.handleNext('/agent/id='+note.agentFrom.agentNumber)}>
              { note.writtenBy }
            </div> }
          
          { note.author == this.props.user._id && this.props.agent &&
            <div id="notesDest">You</div> }

          <div id="notesExtra" >
            { note.title? note.title : '-'}
          </div> 

          <div id="notesLarge" className='onlyLarge'>
            { text }
          </div> 

          { note.contract
            ? <div id="notesSmall" className='active' onClick={()=>this.handleNext('/contract/id='+note.contract.contractNumber)}>
                { note.contract.contractNumber }
              </div> 
            : <div id="notesSmall">-</div> }

          <div id="notesTiny" className="active" onClick={()=>this.setState({ showConvo: !this.state.showConvo })}>
            <i className="fas fa-history"></i>
          </div>     

          { this.state.showModal
            ? <EditTask 
                show={ this.state.showModal } 
                onHide={ this.close.bind(this) }
                note={ note }
                noteView={ this.props.noteView }
                refresh = { this.props.refresh }
                user={ this.props.user }
                edit={ true }
              />  
            : null }

          { this.state.showConvo
            ? <ConversationPopUp 
                show={ this.state.showConvo } 
                onHide={ this.closeHistory.bind(this) }
                contract={ this.props.contract }
                notes={ this.props.notes }
                note={ note }
                user={ this.props.user }
                supplierId={ this.props.supplierId }
              /> 
            : null } 

        </div>
      </div>
    )
  }  
}