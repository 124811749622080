import React, { Component } from 'react';
import './products.css'
import EditDetails from './../Action/EditDetails'
import EditScanning from './../Action/EditScanning'
import AddAuxiliary from '../../Auxiliaries/Action/AddAuxiliary'
import Auxiliaries from './../../Auxiliaries/List/Auxiliaries'
import { setAssetFilter } from '../../../../Actions/index';
import { connect } from 'react-redux'; 
import history from './../../../../history';
import { hasPermission } from './../../../../services/UserService'

class Item extends Component {
  constructor(props) {
    super(props);
    this.state= {
      show: false,
      showModal: false, 
      details: false,
      scanning: false,
      parts: false,
      extensions: false
    }
  };   

  handleNext(path){
    let assetFilter = Object.assign({}, {
      product: this.props.product._id
    });
    this.props.setAssetFilter( assetFilter )
    history.push(path)
  }

  parts() {
    this.setState({ parts: false });
  }
  details() {
    this.setState({ details: false });
  }  
  scanning() {
    this.setState({ scanning: false });
  }  
  extensions() {
    this.setState({ extensions: false });
  }

  render() {
    let parts = 0;
    let extensions = 0;
    let { product, user } = this.props

    let aux = product.auxiliaries
    if( aux ) {
      parts = aux.filter( elem => elem.type == 'Part' )
      extensions = aux.filter( elem => elem.type == 'Extension' )
    }

    let permission = hasPermission( user, product )

    return (
      <div className="productItem">
        <div id="productsEntity" >{ 
          product.entity
          ? product.entity.name? product.entity.name:'-' 
          : 'Global'
        }</div>
        <div id="productsCategory" >{ product.category?product.category:'-' }</div>
        <div id="productsName" >{ product.name }</div>  
        <div id="productsManufacturer" >{ product.manufacturer }</div>  
        <div id="productsParts">{ product.lockable? 'Yes': 'No'}</div>   
        <div id="productsParts" className="onlyLarge">{ parts.length }</div>
        <div id="productsParts" className="onlyLarge">{ extensions.length }</div>
        <div id="productsSmall" className="active" onClick={()=>this.setState({show: !this.state.show})}>
          <i className="fas fa-eye"></i>
        </div>  

        <div id="productsSmall" className="active onlyLarge"  onClick={()=>this.handleNext('/assets-analytics')}>
          <i className="fas fa-chart-line"></i>
        </div> 

        { this.state.show &&
          <div className="productDetails">
            <div id="productSubTitle">Product details</div>
            { permission
              ? <div id="productSubAction" onClick={()=>this.setState({ details: true })}><i className="fas fa-pen" ></i></div>
              : null }
            <div id="productSection">
              <div id="productDetailLabel">Name</div>
              <div id="productDetail">{ product.name }</div>
            </div>   
            <div id="productSection">
              <div id="productDetailLabel">Manufacturer</div>
              <div id="productDetail">{ product.manufacturer }</div>
            </div>   
            <div id="productSection">
              <div id="productDetailLabel">Description</div>
              <div id="productDetail">{product.description}</div>
            </div>           

            <div id="productSubTitle">Scanning properties</div>
            { permission
              ? <div id="productSubAction" onClick={()=>this.setState({ scanning: true })}><i className="fas fa-pen" ></i></div>
              : null }
            
            <div id="productSection">
              <div id="productDetailLabel" className="scannableL">Length of scannable barcode</div>
              <div id="productDetail" className="scannableS" style={{textAlign:'right'}}>
                { product.scannableItemLength? product.scannableItemLength : 'Unspecified' }
                { product.alternativeLength
                  ? <span>&nbsp;or { product.alternativeLength }</span> 
                  : '' }
              </div>
              <div id="productDetailLabel" className="scannableS">digits</div>
            </div>  
            <div id="productSection">
              <div id="productDetailLabel">Create by scan</div>
              <div id="productDetail" className="creatableD">{ product.createByScan? 'True': 'False' }</div>
              <div id="productDetailLabel" className="creatableL">(if set to 'True', scanning an un-recognized serialNumber will add the unit to stock)</div>
            </div>   
            
            <div id="productSubTitle">Parts</div>
            { permission
              ? <div id="productSubAction" onClick={()=>this.setState({ parts: true })}><i className="fas fa-plus-circle" ></i></div>
              : null }
           
            { parts.length > 0
              ? <Auxiliaries auxiliaries={ parts }/>
              : <div id="productNoParts">This product has no parts or components.</div>}

            <div id="productSubTitle">Extensions</div>
            { permission
              ? <div id="productSubAction" onClick={()=>this.setState({ extensions: true })}><i className="fas fa-plus-circle" ></i></div>
              : null }
            { extensions.length > 0
              ? <Auxiliaries auxiliaries={ extensions }/>
              : <div id="productNoParts">This product has no extensions. Extensions are items, or sub-products, sold in relation to this product.</div>}
        
          </div> }
        
        { this.state.details &&
          <EditDetails
            show={ this.state.details } 
            onHide={ this.details.bind(this) }
            refresh={ this.props.refresh }
            product={ product }
          /> }
     
       { this.state.scanning &&
          <EditScanning
            show={ this.state.scanning } 
            onHide={ this.scanning.bind(this) }
            refresh={ this.props.refresh }
            product={ product }
          /> }

        { this.state.parts &&
          <AddAuxiliary
            show={ this.state.parts } 
            onHide={ this.parts.bind(this) }
            user={ this.props.user }
            refresh={ this.props.refresh }
            type={ 'Part' }
            product={ product }
          /> }

        { this.state.extensions &&
          <AddAuxiliary
            show={ this.state.extensions } 
            onHide={ this.extensions.bind(this) }
            user={ this.props.user }
            refresh={ this.props.refresh }
            type={ 'Extension' }
            product={ product }
          /> }


      </div>
    )
  }  
}

export const ProductsListItem = connect(
  function mapStateToProps(state) {
    return { 
      assetFilter: state.assetFilter
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      setAssetFilter: filter => dispatch(setAssetFilter(filter)),
    };
  },
)(Item);