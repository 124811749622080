import React, { Component } from 'react';
import { Shotsplit } from '../Graphs/shot-split';
import './finance.css'
import { Locking } from '../Graphs/locking'
import AnalyticsMenu from './../AnalyticsMenu';
import GraphFilter from './GraphFilter'
import { connect } from 'react-redux';
import history from './../../../history';

class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      showFilter: false
    };
  }

  goBack() {
    window.history.back();
  }

  handleNext(path){
    history.push(path)
  }
  isActive(filter){
    if( filter.product || filter.deal || filter.agent ) return true
    else return false
  }

  render() {    
    let user = this.props.user
    return (
      <div className='background'>
        <div id="pageTitle">Growth analytics</div>
        <AnalyticsMenu user={user}/>
        <div id="clientBackground">
          <div id="dash_menu">  
            <div id="dash_menu_item"  onClick={()=>this.handleNext('/analytics')}>Daily</div>
            <div id="dash_menu_item" onClick={()=>this.handleNext('/analytics')}>Monthly</div>
            <div id="dash_menu_item" className="active_dash_menu">Usage</div>
            <div className={ this.isActive(this.props.filter) ? "contractBackActive cBL": "contractBack cBL" }  onClick={()=>this.setState({ showFilter: !this.state.showFilter })}><i className="fas fa-filter"></i></div>
            <div className="contractBack" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>          
          </div>

          { this.state.showFilter || this.isActive(this.props.filter) 
          ? <GraphFilter user={ this.props.user } locked={true}/>
          : null }

        <div id="graphSection">        
          <div id="graphTitle">Utilisation rate</div>
          <div id="graphWrapper">
            <Shotsplit 
              user={ user } 
              filter={ this.props.filter }
              color= {'rgba(255, 255, 255, 0.7)'}
              width={ 100 }
              height={ 350 }
            />
          </div>
        </div>
        <div id="graphSection">        
          <div id="graphTitle">Lock events</div>
          <div id="graphWrapper">
            <Locking 
              supplier={this.props.user.supplier} 
              color= {'rgba(252, 198, 48, 0.9)'}
              hoverColor= {'rgba(252, 198, 48, 1)'}
              filter={ this.props.filter }
              type={ 'LOCKED' }
              width={ 100 }
              height={ 350 }
            />
          </div>
        </div>

      </div>

      </div>
    );
  }
}

const UsageController = connect(
  function mapStateToProps(state) {
    return { 
      filter: state.filter
    };
  }
)(Controller);

export default UsageController