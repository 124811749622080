import React, { Component } from 'react';
import { Item } from './Item'
import { Header } from './Header'
import './auxiliaryPricings.css'
import AddStandalonePricing from './../Action/AddStandalonePricing'

class AuxiliaryPricingsList extends Component {
  constructor(props) {
		super(props)
		this.state = {
      create: false
    };
  }

  create() {
    this.setState({ create: false });
    this.props.refresh();
  }

  render() {    
    let standalone, dealsRelated, paused = null;
    let { auxiliaryPricings, user } = this.props

    standalone = auxiliaryPricings && auxiliaryPricings
    .filter( elem => elem.status == 'Active' && !elem.deal && elem.auxiliaries[0])
      .sort((a, b) => a.auxiliaries[0].name.localeCompare(b.auxiliaries[0].name))  
      .map( auxiliaryPricing =>{ 
        return (
          <Item auxiliaryPricing={auxiliaryPricing} key={ auxiliaryPricing._id } user={ user } refresh={this.props.refresh}/>
        )
      })

    dealsRelated = auxiliaryPricings && auxiliaryPricings
      .filter( elem => elem.deal && elem.status == 'Active'  && elem.auxiliaries[0] && !elem.guarantee )
      .sort((a, b) => a.auxiliaries[0].name.localeCompare(b.auxiliaries[0].name))
      .map( auxiliaryPricing =>{ 
        return (
          <Item auxiliaryPricing={auxiliaryPricing} key={ auxiliaryPricing._id } user={ user } refresh={this.props.refresh}/>
        )
      })

    paused = auxiliaryPricings && auxiliaryPricings
      .filter( elem => elem.status == 'Paused' && elem.auxiliaries[0] )
      .sort((a, b) => a.auxiliaries[0].name.localeCompare(b.auxiliaries[0].name))
      .map( auxiliaryPricing =>{ 
        return (
          <Item auxiliaryPricing={auxiliaryPricing} key={ auxiliaryPricing._id } user={ user } refresh={this.props.refresh}/>
        )
      })

    return (
      <div>
        <div id="pausedListShort">Standalone pricings for parts and extensions</div>
        { ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1 &&
          <div id="pausedListAction" onClick={()=>this.setState({ create: !this.state.create })}>
            { ! this.state.create
              ? <i className="fas fa-plus-circle"></i>
              : <i className="fas fa-minus"></i> }
          </div> }
        { standalone  && standalone.length == 0 && 
          <div id="auxiliaryPricingExplaination">No standalone pricing set up yet. <br/>Standalone pricings can be accessed by any of your contracted clients.  <br/>When paying, clients should use reference <span style={{ fontWeight: 'bold'}}> pricingReference@contractNumber</span></div>}
        { standalone && standalone.length > 0 && <Header/>}
        { standalone }
        <div id="pausedList">Deal specific pricings</div>
        { dealsRelated  && dealsRelated.length == 0 && 
          <div id="auxiliaryPricingExplaination">No deal specific pricings set up yet. <br/>Deal specific pricings can be set up on the deal detailed view and can only be accessed by client with an active contract of the related deal.<br/>When paying, clients should use reference <span style={{ fontWeight: 'bold'}}> pricingReference@contractNumber</span></div>}
        { dealsRelated && dealsRelated.length > 0 && <Header/>}
        { dealsRelated }
        <div id="pausedList">Paused</div>
        { paused  && paused.length == 0 && 
          <div id="auxiliaryPricingExplaination">No paused pricing</div>}
        { paused }

        { this.state.create &&
          <AddStandalonePricing 
            show={ this.state.create } 
            auxiliaryPricings={ auxiliaryPricings }
            onHide={ this.create.bind(this) }
            user={ user }
          /> }

      </div>
    );
  }
}

export default AuxiliaryPricingsList