import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../../Actions/auth';
import Background2 from './Background2.jpg'
import Background3 from './Background3.jpg'
import Background0 from './Background0.jpg'
import Background5 from './Background5.jpg';
import { Carousel } from 'react-bootstrap/lib';
import './auth.css';

class ResetPassword extends Component {

  handleNext(){
    this.props.resetPassword(this.props.match.params.token);
  } 

  render() {
    return (
      <div className="carMain">

      <div id="login_zone">
        <div id="loginHeader">Reset credentials</div>
        <div id="resetInstructions">
          <div id="resetInstructionsLabel">Thank you, you can now reset your password, using the temporary password you can find in your email.</div>
          <button id="login_button" onClick={()=>this.handleNext('/update-password')}>Update password</button>
        </div>
      </div>

      <Carousel controls={false} interval={12000} slide={true} indicators={false}> 
        <Carousel.Item>
            <img className="carItem responsive" alt="900x500" src={Background2} />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carItem responsive" alt="900x500" src={Background0} />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carItem responsive" alt="900x500" src={Background5} />
          </Carousel.Item> 
          <Carousel.Item>
            <img className="carItem responsive" alt="900x500" src={Background3} />
          </Carousel.Item>             
        </Carousel>;

      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
    reset: state.auth.reset
  };
}

export default connect(mapStateToProps, { resetPassword })(ResetPassword);