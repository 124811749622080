import React, { Component } from 'react';
//import {EventHeader} from './EventHeader'
import { EventsListItem } from '././EventsListItem'
import './events.css';
import { AssetEventHeader } from './AssetEventHeader';
import { ContractEventHeader } from './ContractEventHeader';

class EventsList extends Component {
  
  render() {    
    let mappedEvents = null;
    let events = this.props.events
    if(events){
      events.sort(function(a, b) {
        return new Date(b.date) - new Date(a.date)
      })
      mappedEvents = events.map((event, index) =>{ 
        return (
          <EventsListItem 
            key={ event._id }
            event={ event } 
          />
        )
      })
    }

    return (
      <div>
        { this.props.category == 'Contracts'
          ? <ContractEventHeader/>
          : <AssetEventHeader/>
        }
        { mappedEvents }
      </div>
    );
  }
}

export default EventsList