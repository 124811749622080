import React, { Component } from 'react';
import { ContractHeader } from './ContractHeader'
import { SelectableContractsListItem } from './ContractsListItem'
import './contracts.css'

class ContractsList extends Component {
  
  render() {    
    let mappedContracts = null;
    let { contracts, deal, contractStatus, selectable } = this.props;
    
    if( deal && deal !== '' ) contracts = contracts.filter( elem => elem.deal._id == deal )
    if( contracts ){
      contracts.sort( (a, b) => {
        if( contractStatus == 'Rejected' ) return new Date(b.creditDecisionDate) - new Date(a.creditDecisionDate);
        else return new Date(b.updatedAt) - new Date(a.updatedAt);
      })
      mappedContracts = contracts.map( contract =>{ 
        return (
          <SelectableContractsListItem 
            key={contract._id}
            contract={contract} 
            selectable={ selectable} 
            contractStatus={ contractStatus}
          />
        )
      })
    }

    return (
      <div>
        { contracts && contracts.length > 0 && 
          <ContractHeader contractStatus={ contractStatus}/> }
        { mappedContracts }
      </div>
    );
  }
}

export default ContractsList