import React, { Component } from 'react';
import { SearchClients } from './SearchItems/SearchClients'
import { SearchAgents } from './SearchItems/SearchAgents'
import { SearchContracts } from './SearchItems/SearchContracts'
import { SearchAssets } from './SearchItems/SearchAssets'
import { SearchPayments } from './SearchItems/SearchPayments'
import { SearchEvents } from './SearchItems/SearchEvents'
import { chooseSearch } from './../../../Actions/index';
import { connect } from 'react-redux';
import ManageMenu from './../ManageMenu'
import './search.css';

class SearchController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      clients: undefined,
      dealers: undefined,
      contracts: undefined,
      search: 'clients',
    };
  }

  handleChangeSearch(search){
    this.props.chooseSearch(search)
    this.setState({ search: search })
  }

  componentDidMount() {
    if( this.props.search && this.props.search.length > 0 ) {
      this.setState({ search: this.props.search })
    } 
  }
  

  render() {    
    
    let navigate = null;
    let user = this.props.user
    let options = this.props.user.options
    let search = this.state.search
    
    if( search =='clients') navigate = <SearchClients user={ user } search={ search } options={ options }/>
    if( search =='agents') navigate = <SearchAgents supplier={ user.supplier } search={ search } options={ options }/>
    if( search =='contracts') navigate = <SearchContracts user={ user } search={ search } options={ options }/>
    if( search =='assets') navigate = <SearchAssets user={ user } search={ search } options={ options }/>
    if( search =='payments') navigate = <SearchPayments user={ user } search={ search } options={ options }/>
    if( search =='events') navigate = <SearchEvents user={ user } search={ search } options={ options } supplier={ user.supplier }/>
    
    
    return (
      <div className='background'>
        <div id="pageTitle">Search {search }</div>
        <ManageMenu user={ user }/>
        <div id="searchActionLine"> </div>
        <div id="clientBackground">
          <div id="searchMenu">
            <div id="searchMenuItem" onClick={()=>this.handleChangeSearch('clients')} className={ search =='clients'?"activeMenu leftMenu":"leftMenu"}>Clients</div>
            <div id="searchMenuItem" onClick={()=>this.handleChangeSearch('contracts')} className={ search =='contracts'?"activeMenu":""}>Contracts</div>
            <div id="searchMenuItem" onClick={()=>this.handleChangeSearch('assets')} className={ search =='assets'?"activeMenu":""}>Assets</div>
            <div id="searchMenuItem" onClick={()=>this.handleChangeSearch('agents')} className={ search =='agents'?"activeMenu":""}>Agents</div>
            <div id="searchMenuItem" onClick={()=>this.handleChangeSearch('payments')} className={ search =='payments'?"activeMenu":""}>Payments</div>
            <div id="searchMenuItem" onClick={()=>this.handleChangeSearch('events')} className={ search =='events'?"activeMenu rightMenu":"rightMenu"}>Events</div>
          </div>  
          <div id="searchSection">
            { navigate }
          </div>
        </div>
      </div>
    );
  }
}

export const Search = connect(
  function mapStateToProps(state) {
    return { 
      search: state.search, };
  },
  function mapDispatchToProps(dispatch) {
    return {
      chooseSearch: search => dispatch(chooseSearch(search)),
    };
  },
)(SearchController);