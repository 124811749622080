import React, { Component } from 'react';
import { PayrollsHeader } from './PayrollsHeader'
import { SelectablePayrollsListItem } from './PayrollsListItem'
import './payrolls.css';

class PayrollsList extends Component {
  
  render() {    
    let mappedPayrolls = null;
    let payrolls = this.props.payrolls
    if(payrolls){
      payrolls.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      mappedPayrolls = payrolls.map((payroll, index) =>{ 
        return (
          <SelectablePayrollsListItem 
            key={ payroll._id }
            payroll={ payroll } 
            fromAgentPage={ this.props.fromAgentPage }
            refresh={ this.props.refresh }
            user={ this.props.user }
          />
        )
      })
    }

    return (
      <div>
        <PayrollsHeader/>
        { mappedPayrolls }
      </div>
    );
  }
}

export default PayrollsList