import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index'
import './../item.css'
import { findAgents } from  '../../../../services/AgentService'

class TransferItem extends Component {

  constructor(props) {
    super(props);
    this.state= {
      item: {
        tracking:{
          enteredOn: new Date(),
          enteredBy: this.props.user._id
        },
        auxiliary: this.props.auxiliary._id,
        type: this.props.type,
        agent: this.props.agent._id,
        status: this.props.status,
        quality: this.props.quality,
        type: this.props.type,
        supplier: this.props.user.supplier,
      },       
      disabled: false
    }
  }; 
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }
  
  transfer() {
    this.setState({ disabled: true })
    axios.put(API_URL + '/items/transfer/'+ this.props.user.supplier, this.state.item)
      .then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        this.setState({status: err.data? err.data.message:'Internal error' })
      }); 
  }

  componentDidMount() {
    this.getAgents();
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  render() {    
    let item = this.state.item
    let { items, auxiliary, quality, agent, status } = this.props
    let filteredItems = items && items.find( elem => elem._id.agent + '' == agent._id + '' && elem._id.quality == quality )
    let currentStock = filteredItems? filteredItems.total : 0

    let isDisabled = ( 
      !item.auxiliary || 
      !item.quantity || 
      !item.quality || 
      !item.tracking.category || 
      ( item.tracking.category  == 'Agent_from_stock' && ( !item.agent || !item.tracking.receivedFrom ) ) ||
      ( item.tracking.category  == 'Client_from_agent' && !item.contractNumber ) ||
      ( item.tracking.category  == 'Agent_from_client' && !item.contractNumber ) ||
      ( item.tracking.category  == 'Agent_from_agent' && ( !item.agent || !item.tracking.receivedFrom ) ) ||
      item.quantity > currentStock ||
      ( item.tracking.category  == 'Change_in_quality' && !item.tracking.qualityFrom )
    )? true : false
    if( this.state.disabled ) isDisabled = true
    
    let mappedAgents = this.state.agents && this.state.agents
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return (
          <option key={agent._id} value={ agent._id }>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })
    console.log('original', quality)
    console.log('new', item.quality)
    console.log('old', item.tracking.qualityFrom)
    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Transfer items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="modalBody">
            
            <div className="transferItemLabel">Auxiliary</div>
            <div className="transferItemInputed">{ auxiliary.name }</div>

            <div className="transferItemLabel">Transfer type</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="transferItemInput"
              onChange={(e)=>{ 
                let item = Object.assign({}, this.state.item); 
                item.tracking.category = e.target.value; 
                item.agent = agent._id
                item.client = undefined
                item.tracking.receivedFrom = undefined
                if( e.target.value == 'Client_from_agent' ) {
                  item.tracking.qualityFrom = this.props.quality
                  item.status = 'DEPLOYED'
                  item.quality = 'InUse'
                } else if( e.target.value == 'Agent_from_client' ) {
                  item.tracking.qualityFrom = 'InUse'
                  item.status = 'WITH_AGENT'
                  item.quality = 'ToCheck'
                } else {
                  item.tracking.qualityFrom = undefined
                  item.quality = this.props.quality
                  item.status = undefined
                }
                this.setState({ item }) 
              }}
              > 
              <option value="">Select one</option>
              <option value="Change_in_quality">Change in quality</option>
              { status == 'INSTOCK' && <option value="Agent_from_stock">From stock to agent</option> }
              { status == 'WITH_AGENT' &&  <option value="Agent_from_agent">From agent to agent</option> }
              { status == 'WITH_AGENT' && quality != 'ToCheck' && <option value="Client_from_agent">From agent to client</option> }
              { status == 'DEPLOYED' && <option value="Agent_from_client">From client to agent</option> }
              { status == 'WITH_AGENT' && <option value="Stock_from_agent">From agent to stock</option> }
            </FormControl>

            <div className="transferItemLabel">Agent holding stock</div>
            <div className="transferItemInputed">{ agent.profile.firstName } { agent.profile.lastName }</div>

            <div className="transferItemLabel">Stock quality</div>
            <div className="transferItemInputed">{ quality }</div>

            { item.quality && ['Agent_from_stock', 'Stock_from_agent', 'Agent_from_agent'].indexOf( item.tracking.category )>-1 &&
            <div>

              <div className="transferItemLabel">To</div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="transferItemInput"
                onChange={(e)=>{ 
                  let item = Object.assign({}, this.state.item); 
                  item.agent = e.target.value; 
                  item.tracking.receivedFrom = agent._id; 
                  this.setState({ item }) 
                }}
                > 
                <option value="">Stock receiving agent</option>
                { mappedAgents }
              </FormControl>
            </div> }
              
            { ['Change_in_quality'].indexOf( item.tracking.category )>-1 &&
              <div>

                <div className="transferItemLabel">To</div>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="transferItemInput"
                  onChange={(e)=>{ 
                    let item = Object.assign({}, this.state.item); 
                    item.tracking.qualityFrom = quality; 
                    item.quality = e.target.value
                    item.status = status
                    this.setState({ item }) 
                  }}
                  > 
                  <option value="">New quality</option>
                  { item.quality !== 'New' && <option value="New">New</option> }
                  { item.quality !== 'SecondHand' && <option value="SecondHand">SecondHand</option> }
                  { item.quality !== 'ToCheck' && <option value="ToCheck">ToCheck</option> }
                  { item.quality !== 'Faulty' && item.status == 'INSTOCK' && <option value="Faulty">Faulty</option> }
                </FormControl>

              </div> }
              
            { ['Client_from_agent', 'Agent_from_client'].indexOf( item.tracking.category ) > -1 &&
              <div>
                <div className="transferItemLabel">Contract number</div>
                <FormControl 
                  type="text" 
                  className="transferItemInput addItemShort"
                  onChange={(e)=>{ 
                    let item = Object.assign({}, this.state.item); 
                    item.contractNumber = e.target.value; 
                    this.setState({ item }) 
                  }}
                />
              </div> }

            { item.tracking.category && ( 
              (item.tracking.category=='Change_in_quality' && item.tracking.qualityFrom) || 
              (['Agent_from_stock', 'Stock_from_agent', 'Agent_from_agent'].indexOf( item.tracking.category )>-1 && item.tracking.receivedFrom) ||
              ['Client_from_agent', 'Agent_from_client'].indexOf( item.tracking.category ) > -1 && item.contractNumber
              ) &&
            <div>
              <div className="transferItemLabel">Quantity</div>
              <FormControl 
                type="number" 
                className="transferItemInput addItemShort"
                onChange={(e)=>{ let item = Object.assign({}, this.state.item); item.quantity = e.target.value; this.setState({ item }) }}
              />
            </div> }

            { item.quantity > currentStock &&
            <div id="transferWarning">Value greater than agent's current stock</div> }
            
            <div id="dealSection">
              <div className="transferItemLabel">Note</div>
              <textarea 
                type="textera" 
                className="transferNote"
                placeholder={'Note (optional)'}
                onChange={(e)=>{ let item = Object.assign({}, this.state.item); item.description = e.target.value; this.setState({ item }) }}
              />

            </div>
  
            { this.renderMessage() }  

            { this.state.status !== 'Success' &&
              <div id="productsExtraSmall2">
                <Button id="createProduct" disabled={isDisabled} onClick={()=>this.transfer(this.state.item)}>Transfer</Button>
              </div> }

          </div>
        
        </Modal.Body>
      </Modal>
    );
  }
}

export default TransferItem