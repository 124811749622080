import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { I18n } from 'react-i18next';


class ReversePayment extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false,
    };
  } 

  reverse(){

    var query = {
      transactionId: this.props.transactionId,
      userId: this.props.user._id,
      recoredBy: this.props.user.userNumber,
      note: this.state.note
    }

    this.setState({disabled: true, status: 'One second...'})

    axios.post(API_URL + '/payments/reverse/' + this.props.supplierId, query)
			.then( ret => this.setState({ status: ret.data.message }) )
			.catch( err => {
        console.log(err)
      })
    }

  over(){
    this.props.onHide();
    this.setState({status: undefined, disabled: false});
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  render() {
    let disabled = true;
    if( !this.state.disabled ){
      disabled = false
    }
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Payment cancellation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">
              <div id="reversalDetails">
                Cancellation of payment { this.props.transactionId }
              </div>
              <div id="paymentRecapList">
                <div id="paymentRecapLabel" className="paymentHigh">Add note</div>
                <textarea
                  type="text" 
                  className="paymentRecapNote"     
                  placeholder={'Type'}
                  value={ this.state.note }
                  onChange={(e)=>{ this.setState({ note: e.target.value}) }}
                />
              </div>
              <div id="reversalDetails" style={{marginTop: '15px'}}>
                Upon successful cancellation, an offseting payment will be added to contract { this.props.contractNumber }. 
                <br/><br/> 
                Any activation associated to the cancelled payment will be invalidated and contract { this.props.contractNumber } will be reverted to its pre-payment state.
              </div>
            </div>
            {this.renderMessage()}            
            <br/>
            <div id="modal_action">

              { this.state.status==='Success!'
                ? null
                : <Button id="confirm_action" disabled={disabled} onClick={()=>this.reverse()}>Cancel</Button> }
              
              <Button id="action_cancel" onClick={() => this.over()}>
                { this.state.status==='Success!'
                  ?'Close'
                  :'Abort' }
              </Button>
           
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default ReversePayment;