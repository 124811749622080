import React from 'react';
import { Button } from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class SyncUnit extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status: undefined,
      disabled: false,
      codes: undefined,
      sendText: false
    };
  } 

  sync() {
    this.setState({ disabled: true })
    axios.put(API_URL + '/assets/sync/'+ this.props.user.supplier, {
      assetNumber: this.props.asset.assetNumber,
      contractNumber: this.props.contract.contractNumber,
      userNumber: this.props.user.userNumber,
      sendText: this.state.sendText
    })
      .then((ret) => { this.setState({ 
          codes: ret.data.codes,
          status: ret.data.message 
        }) 
      })
      .catch(err => this.setState({ status: err.message }) ); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'} className="long">
          { this.state.status }
          { this.state.codes && this.state.codes.syncCode
            ? <div>
                Sync code for unit = { this.state.codes.syncCode }
              </div>
            : null
          }    
          { this.state.codes && this.state.codes.lockCode
            ? <div>
                Please lock unit with code { this.state.codes.lockCode } <br/>
                Then enter the following activation code = { this.state.codes.newCode }
              </div>
            : null
          }      
        </div>
      );
    }
  }  

  render() {

    let isDisabled = false;

    return (
      <div>
        <div id="assetActionTitle">Sync unit</div>
        <div id="fullPayment">
          <div className="paymentInputLabel">Text client</div>
          <div
            className="paymentInput half"
            id={ this.state.sendText == true? 'activePaymentOption': '' }
            onClick={(e)=>{ 
              this.setState({ sendText: true });
              }}
            >
            Yes
          </div>
          <div
            className="paymentInput half marginleft"
            id={ this.state.sendText == false? 'activePaymentOption': '' }
            onClick={(e)=>{ 
              this.setState({ sendText: false });
              }}
            >
            No
          </div>
        </div>
        { this.renderMessage() }
        <div id="modal_action">
          { this.state.status
            ? null
            : <Button id="action_signup" disabled={isDisabled} onClick={this.sync.bind(this)}>Confirm</Button>
          }
        </div>
      </div>
    );
  }
}

export default SyncUnit;