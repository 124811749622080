import React, { Component } from 'react';
import './../List/assets.css'

export class AssetHeader extends Component {
  
  render() {
    return (
      <div className="assetsTitle long">
        
        <div id="assetsSmallLight" >
          Product
        </div> 
        <div id="assetsSmallLight" >
          PaygNumber
        </div>  
        <div id="assetsSmallLight" >
          Status
        </div>
        <div id="assetsSmallLight" >
          Serial #
        </div>  
        <div id="assetsSmallLight" >
          Held since
        </div>
        <div id="assetsExtraSmall">
          In stock
        </div>                       
      </div>
    )
  }  
}