import React, { Component } from 'react';
import './events.css'

export class AssetEventHeader extends Component {
  
  render() {


    return (
      <div className="eventsTitle">
        
        <div className="eventDate">Date</div>
        <div className="eventType">Type</div>
        <div className="eventUdl">Underlying</div>
        <div className="eventHolder">Prev. holder/owner</div>
        <div className="eventHolder">New holder/owner</div>
        <div className="eventAuthor">Author</div>
        <div className="eventNote">Note</div>
        
      </div>
    )
  }  
}