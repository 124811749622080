import React from 'react';
import {Button, Modal, FormControl} from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class AmendAuxiliary extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status: undefined,
      auxiliary: {},
      delete: undefined
    };
  } 
  
  componentDidMount() {
    let auxiliary = Object.assign( this.state.auxiliary, this.props.auxiliary )
    this.setState({ auxiliary })
  }

  amendAuxiliary() {
    axios
      .put(API_URL + '/auxiliaries/edit/'+ this.props.user.supplier + '/' + this.props.auxiliary._id, this.state.auxiliary)
      .then((ret) => { this.setState({ status: ret.data.message }) })
      .catch( err => {
        this.setState({ status: 'Error' })
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  over(){
    this.props.onHide();
    this.props.refresh();
    this.setState({
      status: undefined,
      auxiliary:{},
    })
  }

  render() {
    
    let isDisabled = ( 
      this.state.auxiliary.name == '' || 
      this.state.auxiliary.sku == ''  ||
      this.state.auxiliary.status == 'Delete' && this.state.delete != 'Delete me'
    )? true : false

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalEditTitle">Edit auxiliary item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modalBody">

            <div id="productSection">
              <div id="productEditLabel">Name</div>
              <div id="productEditDetail">
                <FormControl 
                  type="text" 
                  className="productDetailsInput"
                  value={ this.state.auxiliary.name }
                  onChange={(e)=>{ let auxiliary = Object.assign({}, this.state.auxiliary); auxiliary.name = e.target.value; this.setState({ auxiliary }) }}
                />
              </div>
            </div>   
          
            <div id="productSection">
              <div id="productEditLabel">SKU</div>
              <div id="productEditDetail">
                <FormControl 
                  type="text" 
                  className="productDetailsInput"
                  value={ this.state.auxiliary.sku }
                  onChange={(e)=>{ let auxiliary = Object.assign({}, this.state.auxiliary); auxiliary.sku = e.target.value; this.setState({ auxiliary }) }}
                />
              </div>
            </div>   

            <div id="productSection">
              <div id="productEditLabel">Serialized</div>
              <div id="productEditDetail">
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="productDetailsInput"
                  onChange={(e)=>{ let auxiliary = Object.assign({}, this.state.auxiliary); auxiliary.hasSerialNumber = e.target.value; this.setState({ auxiliary }) }}
                  > 
                  <option value={this.state.auxiliary.hasSerialNumber}>{this.state.auxiliary.hasSerialNumber? 'Serialized': 'Not serialized'}</option>
                  <option value={true}>Serialized</option>
                  <option value={false}>Not serialized</option>
                </FormControl>
              </div>
            </div>   

            <div id="productSection">
              <div id="productEditLabel">Description</div>
              <div id="productEditDetail">           
                <textarea 
                  type="textera" 
                  className="productFull"
                  placeholder={'Short description (Optional)'}
                  onChange={(e)=>{ let auxiliary = Object.assign({}, this.state.auxiliary); auxiliary.description = e.target.value; this.setState({ auxiliary }) }}
                />              
              </div>
            </div>   

            <div id="productSection">
              <div id="productEditLabel">Delete</div>
              <div id="productEditDetail">           
                <div 
                  id="auxiliaryEditDetailDelete" 
                  className={ this.state.auxiliary.status == 'Delete' ? 'activeDelete': ''}
                  onClick={()=>{
                  let auxiliary = Object.assign({}, this.state.auxiliary); 
                  if( !auxiliary.status ) auxiliary.status = 'Delete'; 
                  else auxiliary.status = undefined; 
                  this.setState({ auxiliary }) 
                }}>
                  <i className="fas fa-trash"></i>
                </div>
              </div>
              { this.state.auxiliary.status == 'Delete' &&
                <div>
                  <div id="reversalDetails" style={{marginTop: '5px', color: 'red'}}>
                    Warning. This is action cannot be undone. Please type "Delete me" to confirm deletion.
                  </div>
                  <FormControl 
                    type="text" 
                    className="contractEditInput"
                    placeholder="Delete me"
                    onChange={(e)=>{ this.setState({ delete: e.target.value }) }}
                  />
                </div> }
            </div>   


            { this.renderMessage() }

            <div id="modal_action">

            { this.state.status !=='Success' &&
              <Button id="action_signup" disabled={isDisabled} onClick={this.amendAuxiliary.bind(this)}>Edit</Button> }
              <Button id="action_cancel" onClick={() => this.over()}>Abort</Button>
            
            </div>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AmendAuxiliary;