import React, { Component } from 'react';
import QuestionnairesList from './List/QuestionnairesList'
import Create from './Action/Create'
import AdminMenu from './../AdminMenu'
import './questionnaires.css';
import { findQuestionnaires } from  '../../../services/QuestionnaireService'
import { hasPermission } from '../../../services/UserService'

class QuestionnairesController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      status: 'Questionnaires',
      create: false
    };
  }

  componentDidMount() {
    this.getQuestionnairesList()
  }
  
  close() {
    this.setState({ create: false });
    this.getQuestionnairesList()
  }

  getQuestionnairesList = async () => {
    try {
      let questionnaires = await findQuestionnaires( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ questionnaires })
    } catch(err){ console.log(err) }
  }
  
  render() {    
    let user = this.props.user
    let { create, status, questionnaires } = this.state
    let filtered = status == 'Surveys'
      ? questionnaires && questionnaires.filter( elem => elem.survey )
      : questionnaires && questionnaires.filter( elem => !elem.survey )

    let permission = hasPermission( user, undefined, 'createSurveys' )

    return (
      <div className="background">
        <div id="pageTitle">Data collection</div>
        { permission &&
            <div id="adminAction" onClick={()=>this.setState({create: !create})}>
              <i className="fas fa-plus"></i>
            </div> }
        
        <AdminMenu user={ user }/>

        <div id="clientBackground">

          <div id="clientSubMenu">  

            <div id="clientSubMenu_item" onClick={()=>this.setState({ status: 'Questionnaires' })} className={status == 'Questionnaires'?"active_clientSubMenu leftMenu":"leftMenu"}>Onboarding</div>  
            <div id="clientSubMenu_item" onClick={()=>this.setState({ status: 'Surveys' })} className={status == 'Surveys'?"active_clientSubMenu rightMenu":"rightMenu"}>Surveys</div>  

          </div>

          { filtered &&
            <QuestionnairesList 
              refresh={this.getQuestionnairesList} 
              user={ user }
              questionnaires={filtered}
              supplier={ user.supplier }
            /> }
        
        
        </div>

        { create &&
          <Create
            show={create} 
            onHide={this.close.bind(this)}
            user={ user }
            refresh={this.getQuestionnairesList}
          /> } 

      </div>
    );
  }
}

export default QuestionnairesController