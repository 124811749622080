import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap/lib';
import { IndexLinkContainer } from 'react-router-bootstrap'
import './header.css'

class HeaderIn extends Component {
  constructor(props) {
		super(props)
		this.state = {
      nav: 'analytics',
    };
  }
  render() {

    return (

      <Navbar collapseOnSelect fixedTop id="top">
        <Navbar.Header>
          <Navbar.Brand>
            <a href="/home"></a>
          </Navbar.Brand>
        <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight id="topLeft">       
            <IndexLinkContainer to="/analytics"  activeClassName="active" onClick={()=>this.setState({ nav: 'analytics' })}>
              <NavItem eventKey={1} href="#">
                <div className={this.state.nav == 'analytics'? 'activeHeader': ''}>
                  <i className="far fa-chart-bar"></i>&nbsp; Analyze
                </div>
              </NavItem>
            </IndexLinkContainer> 
            <IndexLinkContainer to="/home"  activeClassName="active" onClick={()=>this.setState({ nav: 'home' })}>
              <NavItem eventKey={2} href="#">
                <div className={this.state.nav == 'home'? 'activeHeader': ''}>
                  <i className="fas fa-users-cog"></i>&nbsp; Manage
                </div>
              </NavItem>
            </IndexLinkContainer> 
            <IndexLinkContainer to="/admin"  activeClassName="active"  onClick={()=>this.setState({ nav: 'admin' })}>
              <NavItem eventKey={3} href="#">
                <div className={this.state.nav == 'admin'? 'activeHeader': ''}>
                  <i className="fas fa-cog"></i>&nbsp; Set up
                </div>
              </NavItem>
            </IndexLinkContainer>
                  
            <IndexLinkContainer to="/logout"  activeClassName="active">
              <NavItem eventKey={7} href="#"><i className="fas fa-power-off"></i></NavItem>
            </IndexLinkContainer>                    
          </Nav>
        </Navbar.Collapse>
      </Navbar>

    );
  }
}

export default HeaderIn;