import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { API_URL, selectContracts, setTitle } from '../../../Actions/index';
import './graph.css'
import { connect } from 'react-redux';
import history from '../../../history';

var addDays = require('date-fns/add_days');
var differenceInDays = require("date-fns/difference_in_days");

class Graph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
      },
      data: undefined
    };
  };

  componentDidMount() {
    this.getGraph()
  }

  handleNext(path){
    history.push(path)
  }

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) this.getGraph();
  }

  getGraph = () => {
    let criteria = Object.assign( this.props.filter, {
      status: 'LOCKED',
      onboardingStatus: 'Signed'
    })
    let body = {
      criteria,
      userId: this.props.user._id
    }
    axios
      .post(API_URL + '/contracts/activity/' + this.props.user.supplier, body)
      .then((res) => {
        this.setState({
          data: res.data
        })
      })
  }

  click = async (item, data, label) => {
    let detailedContracts = []

    try { 

      if( item[0] ){

        let subtitle = data[item[0]._index] > 100 ? 'First 100' : 'All ' + data[item[0]._index]
        let title = subtitle + ` contracts`
        let timeframe = undefined
        if( item[0]._index == 0 ){
          timeframe = {
            $gte: addDays(new Date(), -5)
          }
        }
        if( item[0]._index == 1 ){
          timeframe = {
            $lte: addDays(new Date(), -5),
            $gte: addDays(new Date(), -15)
          }
        }        
        if( item[0]._index == 2 ){
          timeframe = {
            $lte: addDays(new Date(), -15),
            $gte: addDays(new Date(), -30)
          }
        }
        if( item[0]._index == 3 ){
          timeframe = {
            $lte: addDays(new Date(), -30),
            $gte: addDays(new Date(), -60)
          }
        }
        if( item[0]._index == 4 ){
          timeframe = {
            $lte: addDays(new Date(), -60),
            $gte: addDays(new Date(), -90)
          }
        }
        if( item[0]._index == 5 ){
          timeframe = {
            $lte: addDays(new Date(), -90)
          }
        }

        let query = this.props.filter? this.props.filter : {}
        query.status = 'LOCKED'
        query.lastStatusUpdate = timeframe

        var body = {
          query,
          fromPage: 1,
          pageSize: 100
        }

        let res = await axios.post(API_URL + '/contracts/get/' + this.props.user.supplier, body)
        detailedContracts = res.data? res.data.docs : []
        this.props.selectContracts( detailedContracts )
        this.props.setTitle( title )
        this.handleNext('/contracts-analyze')
      }
    } catch(err){ console.log(err) }

  }

  render() {
    let data = this.state.data
    let label = [ '<5days', '5 to 15 days', '15 to 30 days', '30 to 60 days', '60 to 90 days', '> 90 days' ]
    let dataset = []

    let l5 = 0
    let m5l15 = 0
    let m15l30 = 0
    let m30l60 = 0
    let m60l90 = 0
    let m90 = 0
    if( data ){
      l5 = data
        .filter( elem => differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) < 5 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      m5l15 = data
        .filter( elem => differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) >= 5 && differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) < 14 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      m15l30 = data
        .filter( elem => differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) >= 15 && differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) < 30 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      m30l60 = data
        .filter( elem => differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) >= 30 && differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) < 60 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      m60l90 = data
        .filter( elem => differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) >= 60 && differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) < 90 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      m90 = data
        .filter( elem => differenceInDays( new Date(), new Date(elem._id.lastUpdate ) ) >= 90 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      dataset = [ l5, m5l15, m15l30, m30l60, m60l90, m90 ]
    }
    let total = l5 + m5l15 + m15l30 + m30l60 + m60l90 + m90
      
    let options = {
      onClick: (e, item) => this.click(item, dataset, label),
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
    }


    return (
      <div>   
        { !this.props.standalone
          ? <div>
              <div id="graphTitle">Locked units</div>
              <div id="graphTitleData">{ total.toLocaleString() } units</div>
              <div id="graphWrapper">
                <Doughnut 
                  data={{
                    labels: label, 
                    datasets: [
                      {
                        label: '$',
                        backgroundColor: [
                          '#f4f754',
                          'rgb(249, 253, 24)',
                          'rgb(255,215,0)',
                          'rgb(252, 159, 53)',
                          'rgb(252, 139, 10)',
                          'red'
                        ],
                        borderColor: '#26455A',
                        borderWidth: 1,
                        hoverBackgroundColor: [
                          '#f4f754',
                          'rgb(249, 253, 24)',
                          'rgb(255,215,0)',
                          'rgb(252, 159, 53)',
                          'rgb(252, 139, 10)',
                          'red'
                        ],
                        hoverBorderColor: [
                          'white',
                          'rgba(252, 198, 48, 1)',
                          'rgba(0,139,139, 1)',
                          '#CA193F'
                        ],
                        data: dataset
                      }
                    ]
                  }}
                  width={ this.props.width }
                  height={ this.props.height }
                  options={ options }
                />
              </div>  
            </div>
          : <div><Doughnut 
              data={{
                labels: label, 
                datasets: [
                  {
                    label: '$',
                    backgroundColor: [
                      '#f4f754',
                      'rgb(249, 253, 24)',
                      'rgb(255,215,0)',
                      'rgb(252, 159, 53)',
                      'rgb(252, 139, 10)',
                      'red'
                    ],
                    borderColor: '#26455A',
                    borderWidth: 1,
                    hoverBackgroundColor: [
                      '#f4f754',
                      'rgb(249, 253, 24)',
                      'rgb(255,215,0)',
                      'rgb(252, 159, 53)',
                      'rgb(252, 139, 10)',
                      'red'
                    ],
                    hoverBorderColor: [
                      '#f4f754',
                      'rgb(249, 253, 24)',
                      'rgb(255,215,0)',
                      'rgb(252, 159, 53)',
                      'rgb(252, 139, 10)',
                      'red'
                    ],
                    data: dataset
                  }
                ]
              }}
              width={ this.props.width }
              height={ this.props.height }
              options={ options }
            /></div>
        }

      </div>      
    );
  }
}

const Activity = connect(
  function mapStateToProps(state) {
    return { 
      listOfContracts: state.listOfContracts,
      title: state.title
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      selectContracts: (list) => dispatch(selectContracts(list)),
      setTitle: (title) => dispatch( setTitle(title) )
    };
  },
)(Graph);

export default Activity