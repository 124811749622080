import React from 'react';
import { Bar } from 'react-chartjs-2';

export class AllPopulation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      label: [],
      data: [],
      result: undefined
    };
  };
  
  render() {
    let options = {
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          },
          
          ticks: {
            fontColor: 'rgba(255, 255, 255, 0.8)',
            fontSize: 10
          },
        }],
        yAxes: [{
          gridLines: {
            display:false
          }, 

          ticks: {
            fontColor: 'rgba(255, 255, 255, 0.8)',
            beginAtZero: true,
            fontSize: 10
          },
        }]
      }
    }

    let data = this.props.data.map( elem => elem * this.props.multiplier )
    let total = Math.round(data.reduce( function(acc, elem) { return acc + elem; }, 0), 0)

    return (
      <div id="impactGraph">
        <div id="impactGraphTitle">{ this.props.title }</div>
        <div id="impactGraphTitleTotal">{ total.toLocaleString() }</div>
        <div>
          <Bar
            data={{
              labels: this.props.label, 
              datasets: [
                {
                  label: 'Number',
                  backgroundColor: this.props.color,
                  borderColor: this.props.color,
                  borderWidth: 1,
                  hoverBackgroundColor: this.props.hoverColor,
                  hoverBorderColor: this.props.hoverColor,
                  data: data
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ options }
          /> 
        </div>
      </div>
    );
  }
}