import React from 'react';
import { Button, Modal } from 'react-bootstrap/lib';
import ContractSummary from '../Details/ContractSummary';
import ContractEditTerms from './ContractEditTerms';
import Reset from './Reset'
import SendBonus from './SendBonus'
import WriteOff from './WriteOff'
import UnLock from './UnLock'
import MoveDeal from './MoveDeal'
import Code from './Code'
import { hasPermission } from '../../../../services/UserService'

class ContractEdit extends React.Component {

  constructor(props) {
		super(props)
		this.state = { };
  } 
 
  render() {
    let { contract, user } = this.props

    let editPermissions = hasPermission( user, contract, 'editContract' )
    let permission = hasPermission( user, contract )
    let navigate = null

    if( this.state.action == 'bonus' ){
      navigate = <SendBonus contract={ contract } user={ user }/>
    }
    if( this.state.action == 'editTerms' ){
      navigate = <ContractEditTerms contract={ contract } user={ user }/>
    }
    if( this.state.action == 'writeOff' ){
      navigate = <WriteOff contract={ contract } user={ user }/>
    }
    if( this.state.action == 'moveDeal' ){
      navigate = <MoveDeal contract={ contract } user={ user }/>
    }
    if( this.state.action == 'unlock' ){
      navigate = <UnLock contract={ contract } user={ user }/>
    }
    if( this.state.action == 'reset' ){
      navigate = <Reset contract={ contract } user={ user }/>
    }
    if( this.state.action == 'code' ){
      navigate = <Code contract={ contract } user={ user }/>
    }

    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="large"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Edit contract </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">
          <div id="editSpace">
            <ContractSummary contract={this.props.contract}/>
            <div id="modalEditContract">
              <Button id="action_cancel" onClick={this.props.onHide}>Close</Button>
            </div>
          </div>
          <div id="editSpace">

            <div id="assetSummary">

              { editPermissions && 
                [ 'REPOSSESSED', 'WRITEOFF' ].indexOf( contract.status ) < 0 &&
                contract.onboardingStatus == 'Signed'
                ? <div id="assetOption" onClick={()=>this.setState({ action: 'bonus' })}>Send bonus</div>
                : null }

              { editPermissions && 
                [ 'PAIDOFF', 'REPOSSESSED', 'WRITEOFF' ].indexOf( contract.status ) < 0 &&
                contract.onboardingStatus == 'Signed'
                ? <div id="assetOption" onClick={()=>this.setState({ action: 'editTerms' })}>Edit terms</div>
                : null }
                
              { editPermissions && 
                [ 'PAIDOFF', 'REPOSSESSED', 'WRITEOFF' ].indexOf( contract.status ) < 0 &&
                contract.onboardingStatus == 'Signed'
                ? <div id="assetOption"  onClick={()=>this.setState({ action: 'moveDeal' })}>Change deal</div>
                : null }

              { editPermissions && 
                [ 'REPOSSESSED', 'WRITEOFF' ].indexOf( contract.status ) < 0 &&
                contract.onboardingStatus == 'Signed'
                ? <div id="assetOption"  onClick={()=>this.setState({ action: 'unlock' })}>Unlock</div>
                : null }

              { permission && 
                [ 'PAIDOFF', 'REPOSSESSED' ].indexOf( contract.status ) < 0 &&
                contract.onboardingStatus == 'Signed'
                ? <div id="assetOption"  onClick={()=>this.setState({ action: 'writeOff' })}>Write-off</div>
                : null }

              { permission && 
                contract.onboardingStatus == 'Signed' &&
                <div id="assetOption"  onClick={()=>this.setState({ action: 'reset' })}>Hard reset</div> }

              { permission && 
                contract.onboardingStatus == 'Signed' &&
                <div id="assetOption"  onClick={()=>this.setState({ action: 'code' })}>Code</div> }

              { navigate }

              </div>

            </div>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default ContractEdit;