import React from 'react';
import { Bar } from 'react-chartjs-2';
var getYear = require("date-fns/get_year");

class AgeEvolution extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      label: [],
      data: [],
      result: undefined
    };
  };

  render() {
    let data = this.props.data

    let l25 = []
    let l35 = []
    let l45 = []
    let l55 = []
    let l65 = []
    let m65 = []

    let label = []
    if( data && data.length ){
      let year = getYear(new Date())
      let allMonths = data
        .map( elem => elem._id.signingDate )
        .filter( elem => elem && elem != '2018-05')
        .sort(function(a, b) {
          return new Date(a) - new Date(b)
        })
      label = [ ...new Set(allMonths) ]

      label.forEach( month => {
        
        let l25Point = data
          .filter( elem => year - elem._id.dob < 25 && elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(l25Point) && l25Point > 0 ) l25.push(l25Point)
        else l25.push(0)

        let l35Point = data
          .filter( elem => year - elem._id.dob >= 25 && year - elem._id.dob < 35 && elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(l35Point) && l35Point > 0 ) l25.push(l35Point)
        else l35.push(0)

        let l45Point = data
          .filter( elem => year - elem._id.dob >= 35 && year - elem._id.dob < 45 && elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(l45Point) && l45Point > 0 ) l45.push(l45Point)
        else l45.push(0)

        let l55Point = data
          .filter( elem => year - elem._id.dob >= 45 && year - elem._id.dob < 55 && elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(l55Point) && l55Point > 0 ) l55.push(l55Point)
        else l55.push(0)

        let l65Point = data
          .filter( elem => year - elem._id.dob >= 55 && year - elem._id.dob < 65 && elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(l65Point) && l65Point > 0 ) l65.push(l65Point)
        else l65.push(0)

        let m65Point = data
          .filter( elem => year - elem._id.dob >= 65 && elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(m65Point) && m65Point > 0 ) m65.push(m65Point)
        else m65.push(0)

      })

    }

    let options = {
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            stacked: true,
            gridLines: {
              display: false
            },
            labels: label, 

            ticks: {
              fontColor: 'rgba(255, 255, 255, 0.8)',
              fontSize: 10
            },
          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            stacked: true,
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
              display: false
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                  return value.toLocaleString();
              },
              fontColor: 'rgba(255, 255, 255, 0.8)',
              beginAtZero: true,
              fontSize: 10
            },
            labels: {
              show: true
            }
          }
        ]
      }
    }
    
    return (
      <div>
        { this.props.data.length > 0
        ? <Bar
          data={{
            datasets: [
              {
                type: 'bar',
                label: '18 - 25 years',
                backgroundColor: 'white',
                borderColor: 'white',
                borderWidth: 1,
                data: l25,
                yAxisID: 'y-axis-1'
              },
              {
                type: 'bar',
                label: '25 - 35 years',
                backgroundColor: 'rgb(128, 255, 255)',
                borderColor: 'rgb(128, 255, 255)',
                borderWidth: 1,
                data: l35,
                yAxisID: 'y-axis-1'
              },
              {
                type: 'bar',
                label: '35 - 45 years',
                backgroundColor: 'rgb(65, 255, 255)',
                borderColor: 'rgb(65, 255, 255)',
                borderWidth: 1,
                data: l45,
                yAxisID: 'y-axis-1'
              },
              {
                type: 'bar',
                label: '45 - 55 years',
                backgroundColor: 'rgb(27, 218, 218)',
                borderColor: 'rgb(27, 218, 218)',
                borderWidth: 1,
                data: l55,
                yAxisID: 'y-axis-1'
              },
              {
                type: 'bar',
                label: '55 - 65 years',
                backgroundColor: 'rgb(11, 179, 179)',
                borderColor: 'rgb(11, 179, 179)',
                borderWidth: 1,
                data: l65,
                yAxisID: 'y-axis-1'
              },
              {
                type: 'bar',
                label: '65 years +',
                backgroundColor: 'rgba(0,139,139, 1)',
                borderColor: 'rgba(0,139,139, 1)',
                borderWidth: 1,
                data: m65,
                yAxisID: 'y-axis-1'
              }
            ]
          }}
          width={ this.props.width }
          height={ this.props.height }
          options={ options }
        /> 
        : null}
      </div>
    );
  }
}

export default AgeEvolution
