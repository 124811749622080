import React from 'react';
import { Doughnut } from 'react-chartjs-2';
var differenceInDays = require("date-fns/difference_in_days");

export class Activity extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
      },
    };
  };

  render() {
    let contract = this.props.contract;
    let activations = contract.activations;
    let data = [ 1, 0 ]
    let label = [ 'locked', 'enabled' ]
    let totalDays = 0
    let daysActivated = 0
    if( contract.signingDate ){
      totalDays = differenceInDays( new Date(), contract.signingDate )
      activations
        .filter( elem => elem.duration && elem.type !== 'Invalidated')
        .forEach( elem => daysActivated += elem.duration )
      let filteredActivations = activations.filter( elem => elem.type === 'Active' )
      filteredActivations.forEach( elem => {
        let toExclude = differenceInDays( new Date(), elem.startDate )
        if( toExclude < 1 ) totalDays += elem.duration
        else totalDays += elem.duration - toExclude
      })
      data[0] = Math.max( 0, totalDays - daysActivated);
      data[1] = daysActivated
    }

    return (
      <div className="contractGraph">
        <div className="contractGraphTitle">Activity</div>  
        <div className="contractGraphWrapper">
          <Doughnut 
            data={{
              labels: label, 
              datasets: [
                {
                  label: '$',
                  backgroundColor: [
                    'rgba(252, 198, 48, 0.9)',
                    'white'
                  ],
                  borderColor: this.props.color,
                  borderWidth: 1,
                  hoverBackgroundColor: [
                    'rgba(252, 198, 48, 1)',
                    'white'
                  ],
                  hoverBorderColor: 'rgba(252,203,57, 1)',
                  data: data
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ this.state.options }
          /> 
        </div>      
      </div>
    );
  }
}
