import React, { Component } from 'react';
import './../notes.css';
import EditNote from './../Actions/EditNote'
import ContractPhoto from './../../Images/ContractPhoto'
import ConversationPopUp from './../Conversations/ConversationPopUp'

export class NotesListItem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      showModal: false,
      showConvo: false 
    }
  }; 
  
  close() {
    this.setState({ showModal: false });
  }
  
  closeHistory(){
    this.setState({ showConvo: false });
  }

  render() {
    let note = this.props.note
    var options = { month: 'short', day: 'numeric' };
    let date = new Date(note.date)
    date = date.toLocaleDateString("en-EN", options)
        
    let update = ''
    
    if( note.type === 'Update' && note.data ){

      if( note.data.firstName ){ 
        let oldFirstName = note.data.oldFirstName
          ? note.data.oldFirstName
          : note.data.oldProfile? note.data.oldProfile.firstName : '-'
        update += 'FirstName changed from ' + oldFirstName + ' to ' + note.data.firstName + '. '
      }
      
      if( note.data.lastName ){ 
        let oldLastName = note.data.oldLastName
          ? note.data.oldFirstName
          : note.data.oldProfile? note.data.oldProfile.lastName : '-'
        update += 'LastName changed from ' + oldLastName + ' to ' + note.data.lastName + '. '
      }
      
      if( note.data.mobile ){ 
        let oldMobile = note.data.oldMobile
          ? note.data.oldMobile
          : note.data.oldContact? note.data.oldContact.mobile : '-'
        update += 'Mobile phone changed from ' + oldMobile + ' to ' + note.data.mobile + '. '
      }
      
      if( note.data.gps ){ 
        update += 'Location updated to: latitude ' + note.data.gps.latitude + '; longitude ' + note.data.gps.longitude 
      }
      
    }
    
    let writtenBy = note.writtenBy
    
    return (
      <div className="noteItem">
        <div id="notesSmall2" >{ date }</div>  

        <div id="notesType">{ note.type }</div>   

        { note.text === 'Picture of contract' &&
          <div id="notesMedium" >
            <ContractPhoto contract={ this.props.contract } user={ this.props.user }/>
          </div> }
         
        { note.text !== 'Picture of contract' && this.props.type !== 'Update' &&
          <div id="notesMedium" >{ note.text }</div> }

        { note.type === 'Update' &&
          <div id="notesMedium" >{ update }</div> }
        
        <div id="notesAuthor" >{ writtenBy? writtenBy : '-' }</div>  
        
        { note.type === 'Internal' && note.author == this.props.user._id && !note.partOfDiscussion &&
          <div id="notesTiny" className="active" onClick={()=>this.setState({ showModal:!this.state.showModal })}>
            <i className="fas fa-pencil-alt"></i>
          </div> }  

        { note.partOfDiscussion &&
          <div id="notesTiny" className="active" onClick={()=>this.setState({ showConvo: !this.state.showConvo })}>
            <i className="fas fa-history"></i>
          </div> }   

        { this.state.showModal &&
          <EditNote 
            show={this.state.showModal} 
            onHide={this.close.bind(this)}
            user={ this.props.user }
            note={note}
            edit={true}
            contract={this.props.contract}
            refresh={this.props.refresh}
          /> }

          { this.state.showConvo &&
            <ConversationPopUp 
              show={ this.state.showConvo } 
              onHide={ this.closeHistory.bind(this) }
              notes={ this.props.notes }
              note={ note }
              user={ this.props.user }
              supplierId={ this.props.user.supplier }
            /> } 

      </div>
    )
  }  
}