import React, { Component } from 'react';
import axios from 'axios';
import XLSX from 'xlsx'
import ManageMenu from './ManageMenu'


class Load extends Component {
  constructor() {
    super()
    this.state = {
      status: undefined,
      message: undefined,
      preview: undefined
    }
  }
  goBack() {
    window.history.back();
  }
   
  renderMessage() {
    if (this.state.message) {
      return (
        <div id="full">
          <div id={this.state.status==='Success!'?'uploadSucess':'uploadError'}>
            {this.state.message}
          </div>
        </div>
      );
    }
  }  

  parseFile(evt, type) {
    var files = evt.target.files;
    var i, f;
    this.setState({
      status: undefined,
      message: undefined,
      preview: undefined
    })
    //Loop through files
    for (i = 0, f = files[i]; i != files.length; ++i) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);
        /* Update state */
        this.setState({ preview: data })

        axios.post('http://localhost:5000/upload/'+ type +'/' + this.props.user.supplier, data)
          .then((ret) => {
            this.setState({ message: ret.data.message, status: ret.data.status });
          })
          .catch(error => {
            if(error.response){
              error.response && error.response.data
              ? this.setState({ message: error.response.data.message, status: error.response.status })
              : this.setState({ message: error.response.statusText, status: error.response.status })
            } else {
              this.setState({ message: 'Error', status: 500 })
            }

          });

      };
      reader.readAsBinaryString(f);
    }
  }
  
  preview(){
    if(this.state.preview){
      
      let mapped = this.state.preview.map((item, index) =>{ 
        var result = []
        var firstKey = Object.keys(item)[0];
        var value = item[firstKey];
        Object.keys(item).forEach(elem=>result = result + ' '+ elem + ': '+ item[elem])
        return (
          <div key={index}>
            {'Asset '+ (index +1) + ' = '+ result}
          </div> 
        )
      })   
    
      return (
        <div id="preview">
          <div style={{fontWeight: 'bold'}}>Uploaded data preview: </div>
          {mapped}
        </div>
      )
    }
  }

  render() {    

    return (
      <div className='background'>
        <div id="pageTitle">Load everything</div>  
        <ManageMenu user={this.props.user}/>
        <div id="clientActionLine">
          <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
        </div>
        <div id="loader">
          <div id="assetActionZone">
            <div>
              <div className="loaderZoneHeader">Load agents</div>
              <input id="drop" className="loaderZone" type="file" onChange={(e)=>this.parseFile(e, 'agents')}></input>

            </div>


            <div id="uploadOutcome">
              {this.renderMessage()}
              {this.state.message&&this.state.status !='Malformed'?
                <div id="uploadErrorDetails">Erros can occur if the serial number you are trying to upload is already in the database or if the product name you entered doesn't match the one you defined in your product list</div>
                :  null
              }
            </div>
          </div>       
        </div>


        <div id="loader">
          <div id="assetActionZone">
            <div>
              <div className="loaderZoneHeader">Execute MM transactions</div>
              <input id="drop" className="loaderZone" type="file" onChange={(e)=>this.parseFile(e, 'executemmpayments')}></input>

            </div>
          </div>       
        </div>





      </div>
    );
  }
}

export default Load