import React, { Component } from 'react';
import InfoWrapper from '../Utils/InfoWrapper'
import history from '../../../history';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import './asset.css';

class RepossessedAssetSummary extends Component {
  
  constructor(props) {
		super(props)
		this.state = {
      asset: undefined,
    };
  }

  componentDidMount() {
    this.getAssetDetails();
  }

   
	getAssetDetails = () => {
     axios
      .get(API_URL + '/assets/by-paygNumber/' + this.props.contract.supplier + '/' + this.props.contract.prevPaygNumber)
      .then((ret) => this.setState({ 
        asset: ret.data
      }))
  }


  handleNext(path){
    history.push(path)
  } 

  render() {    
    
    let asset = this.state.asset
    let image = <div id="assetPicture"><i className="fas fa-solar-panel"></i></div>
    
    if( asset && asset.productDetails.category == "Communication"){
      image = <div id="assetPicture"><i className="fas fa-mobile-alt"></i></div>
    }  
    if( asset && asset.productDetails.category == "Agri"){
      image = <div id="assetPicture"><i className="fas fa-tractor"></i></div>
    }    
    if( asset && asset.productDetails.category == "Entertainment"){
      image = <div id="assetPicture"><i className="fas fa-tv"></i></div>
    }  
    if( asset && asset.productDetails.category == "Cooking"){
      image = <div id="assetPicture"><i className="fas fa-temperature-high"></i></div>
    }      

    let fullImage =  image   

    return (
      <div>
        { asset
          ? <div id="assetSummary">
              <div id="repossedUnitTitle" >Unit previously linked to contract</div>
              <div className="active" onClick={()=>this.handleNext('/asset/id='+asset.assetNumber)}>
                <InfoWrapper 
                  label={"Asset number "}
                  isActive={ true }
                  data={ asset.assetNumber }
                />
              </div>
              <InfoWrapper 
                label={"Asset type"}
                data={asset.productDetails.name}
              />
              { asset.paygNumber
                ? <InfoWrapper 
                    label={"Payg number"}
                    data={ asset.paygNumber? asset.paygNumber: asset.shortNumber }
                  />
                : null 
              }
              <InfoWrapper 
                label={"Serial number"}
                data={ asset.serialNumber}
              />
              <InfoWrapper 
                label={"Manufacturer"}
                data={asset.productDetails.manufacturer}
              />
              { fullImage }
            </div>
   
          : <div id="assetSummary">
              <div className="loader">Loading... <br/><i className="fas fa-spinner"></i></div>
            </div> }
      </div>
   );
  }
}

export default RepossessedAssetSummary