import React, { Component } from 'react';
import './payrollcontracts.css'

export class TargetHeader extends Component {
  
  render() {

    return (
      <div className="payrollContractTitle">       
        <div className="payrollTarget">Target description</div>
        <div className="payrollTargetCommission">Commission</div>     
      </div>
    )
  }  
}