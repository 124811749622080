import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import './productAction.css';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';

class AddProduct extends Component {

  constructor(props) {
    super(props);
    this.state= {
      product: {
        createByScan: false,
        supplier: this.props.user.supplier,
        createdBy: this.props.user._id
      },       
      disabled: false
    }
  }; 
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }
  
  createProduct(product) {
    this.setState({disabled: true})
    axios.post(API_URL + '/products/create/'+ this.props.user.supplier, product)
      .then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        this.setState({status: err.data.message })
      }); 
  }

  render() {    
 
    let isDisabled = ( 
      this.state.product.typed !== undefined && 
      this.state.product.category && 
      this.state.product.name && 
      this.state.product.manufacturer 
    )? false : true
    if(this.state.disabled) isDisabled = true

    let user = this.props.user
    let entities = user.entityOptions
    let entity = undefined

    let mappedEntities = entities && entities
      .sort((a, b) => a.name.localeCompare(b.name))  
      .map( entity =>{ 
        return (
          <option key={ entity._id } value={ entity._id }>{entity.name}</option>
        )
      })
    
    if( this.state.product.entity && entities ) entity = entities.find( elem => elem._id + '' == this.state.product.entity + '' )

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">
            Create a new product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="modalBody">

            <div className="userLabel">Category</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="userInput"
              onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.category = e.target.value; this.setState({ product }) }}
              > 
              <option value="">Category</option>
              <option value="Agri">Agriculture</option>
              <option value="Communication">Communication</option>
              <option value="Consumables">Consumables</option>
              <option value="Cooking">Cooking</option>
              <option value="Energy">Energy</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Water">Water</option>
            </FormControl>  

            <div className="userLabel">Type</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="userInput"
              onChange={(e)=>{ 
                let product = Object.assign({}, this.state.product); 
                product.typed = e.target.value; 
                if( e.target.value === 'TRUE') product.lockable = true
                else if( e.target.value === 'FALSE') product.lockable = false
                else product.lockable = undefined
                this.setState({ product }) 
              }}
              > 
              <option value="">Is lockable?</option>
              <option value={ 'TRUE' }>Lockable</option>
              <option value={ 'FALSE' }>Non-lockable</option>
            </FormControl>

            <div className="userLabel">Manufacturer</div>
            { this.state.product.typed == 'TRUE'
              ? <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="userInput"
                  onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.manufacturer = e.target.value; this.setState({ product }) }}
                  > 
                  <option value="">Manufacturer</option>
                  <option value={'Amped'}>Amped</option>
                  <option value={'Biolite'}>Biolite</option>
                  <option value={'Cello'}>Cello</option>
                  <option value={'Omnivoltaic'}>Omnivoltaic</option>
                  <option value={'Schneider Electric'}>Schneider Electric</option>
                  <option value={'Biolite_test'}>Test</option>
                </FormControl>
              : <FormControl 
                  type="text" 
                  className="userInput"
                  placeholder={this.props.existingProduct?this.props.existingProduct.manufacturer:'Manufacturer'}
                  onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.manufacturer = e.target.value; this.setState({ product }) }}
                /> }

              <div className="userLabel">Name</div>
              <FormControl 
                type="text" 
                className="userInput"
                placeholder={this.props.existingProduct?this.props.existingProduct.name:'Product name'}
                onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.name = e.target.value; this.setState({ product }) }}
              />

            { entities && entities.length > 1 &&
              <div id="dealSection">
                <div className="userLabel">Entity</div>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="userInput"
                  onChange={(e)=>{ 
                    let product = Object.assign({}, this.state.product); 
                    product.entity = e.target.value
                    this.setState({ product }) 
                  }}
                > 
                  <option value="">Choose entity</option>
                  { mappedEntities }
                </FormControl>  
                { ! entity 
                  ? <div className="userLabelLong">
                      (leave empty to create a global product)
                    </div>
                  : <div className="userLabelLong redText">
                      will only be accessible to  {entity.name}
                    </div>
                }
              </div> }    
              
            <div id="dealSection">
              <textarea 
                type="textera" 
                className="productFull"
                placeholder={this.props.existingProduct?this.props.existingProduct.description:'Description (Optional)'}
                onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.description = e.target.value; this.setState({ product }) }}
              />
            </div>

            <div id="dealSection">
              <div id="addProductTitle">Scanning properties</div>
              <div id="pLabel">Length of scannable number (optional). Only serial numbers with this number of digits will be recognized by the scanner)</div>   
              <div id="pDetails">
                <FormControl 
                  type="number" 
                  className="productDetailsInput"
                  placeholder={'Number of digits'}
                  onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.scannableItemLength = e.target.value; this.setState({ product }) }}
                />
              </div>  

            { this.state.product.scannableItemLength > 0 &&
              <div>
                <div id="pLabel">Alternative length (optional)</div>   
                <div id="pDetails">
                  <FormControl 
                    type="number" 
                    className="productDetailsInput"
                    placeholder={'Enter number of digits'}
                    onChange={(e)=>{ 
                      let product = Object.assign({}, this.state.product); 
                      product.alternativeLength = e.target.value; 
                      this.setState({ product }) 
                    }}
                  />
                </div>   
              </div> } 
            
            </div> 

            <div id="pLabel">
              Scanning an unrecognized number will add units to stock (not advised if your product has a secret key)
            </div>  
            <div id="pDetails">
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="productDetailsInput"
                onChange={(e)=>{ 
                  let product = Object.assign({}, this.state.product); 
                  product.createByScan = e.target.value; 
                  this.setState({ product }) 
                }}
                > 
                <option value="">Creation by scan</option>
                <option value={ true }>Permitted</option>
                <option value={ false }>Disallowed</option>
              </FormControl>
            </div>  

            { this.state.status !== 'Success'?
              <div id="productsExtraSmall2">
                <Button id="createProduct" disabled={isDisabled} onClick={()=>this.createProduct(this.state.product)}>Create</Button>
              </div>
              : null }

            {this.renderMessage()}  
          </div>
        
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddProduct