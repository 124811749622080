import React from 'react';
import './userAction.css'

class EditPermission extends React.Component {

  constructor(props) {
		super(props)
		this.state = {};
  } 

  render() {
    let { user, title, setPermission, permissionToSet } = this.props
    let permission = this.state.permission

    return (
      <div id='permissionW'>
        <div id='permissionQ'>{title}</div>
        <div 
          id='permissionOption'
          className={ permission !== undefined
            ? ( permission == true? 'selected': 'unselected' )
            : ( user && user.permissions[permissionToSet] == true? 'selected': 'unselected' ) 
          }
          onClick={()=> {
            this.setState({ permission: true })
            setPermission( permissionToSet, true ) 
          }}
        >
          Yes
        </div>
        <div 
          id='permissionOption'
          className={ permission !== undefined
            ? ( permission !== true? 'selected': 'unselected' )
            : ( user && user.permissions[permissionToSet] !== true? 'selected': 'unselected' ) 
          }
          onClick={()=> {
            this.setState({ permission: false })
            setPermission( permissionToSet, false ) 
          }}
        >
          No
        </div>
      </div>
    );
  }
}

export default EditPermission;