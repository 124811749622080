import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { findProducts } from  '../../../../services/ProductService'

class CreateGroupPricing extends Component {

  constructor(props) {
    super(props);
    this.state= {   
      group:{
        solidary: false,
        eligibleDeals: [],
      },   
      ready: false,
      enableAgents: false,
      disabled: false
    }
  }; 

  componentDidMount() {
    this.loadData()
    this.setState({status: undefined})
  }
      
  getProducts = async () => {
    try {
      let products = await findProducts( { status: 'Active' }, this.props.user._id, this.props.user.supplier )
      this.setState({ products, filteredProducts: products })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getProducts();
  }  

  selectAgents = (restrictedTo) => {
    let deal = Object.assign({}, this.state.deal); 
    deal.restrictedTo = restrictedTo; 
    this.setState({ deal });    
  }

  selectDeal( id ){
    let group = Object.assign({}, this.state.group); 
    let list = []
    if( group.eligibleDeals.indexOf( id ) < 0 ) list = [...group.eligibleDeals, id]
    else list = group.eligibleDeals.filter( elem => elem !== id )
    group.eligibleDeals = list
    this.setState({ group })
  }

  createGroup(){
    axios
      .post(API_URL + '/groups/create-pricing/'+ this.props.user.supplier, this.state.group)
      .then((ret) => {
          this.setState({ status: ret.data.message });
          this.props.refresh()
      }).catch(err => {
        this.setState({status: err.message})
      });
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ? 'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  } 

  filterEntity = ( entities, entity ) => {
    let selectedEntities = entities.filter( elem => 
      elem._id === entity ||
      elem.country === entity ||
      elem.organisation === entity ||
      elem.branch === entity
    ).map( elem => elem._id )
    let filteredProducts = this.state.products.filter( elem => selectedEntities.indexOf( elem.entity ) > -1 || !elem.entity )
    this.setState({ filteredProducts })
  }

  render() {    

    let { deals, user } = this.props
    let options = user.options
    let { group, filteredProducts } = this.state
    
    let mappedProducts = filteredProducts && filteredProducts
      .filter( elem => elem.status != 'Paused' )
      .map( elem =>{ 
        return <option key={elem._id} value={elem._id}>{ elem.name }</option>
      })

    let entities = user.entityOptions
    let entity = undefined

    let mappedEntities = entities && entities
      .sort((a, b) => a.name.localeCompare(b.name))  
      .map( entity => { 
        return <option key={ entity._id } value={ entity._id }>{entity.name}</option>
      })
    
    if( group.entity && entities ) entity = entities.find( elem => elem._id + '' == group.entity + '' )

    let mappedDeals = deals && group && group.eligibleProduct && deals
      .filter( elem => elem.eligibleProduct._id+ '' == group.eligibleProduct +'' )
      .map( (deal, index) => { 
        return (
          <div 
            key={ index } 
            id="groupDealOption"
            className={ group.eligibleDeals.indexOf( deal._id ) > -1 ? 'selectedDeal': '' }
            onClick={ () => this.selectDeal( deal._id ) }
          >
            { deal.dealName }
          </div>
        )
      })

    let isDisabled = true

    if( 
      group.solidary != undefined 
      && group.minSize > 0
      && group.groupName 
      && group.eligibleDeals.length > 0
      && group.eligibleProduct 
    ) isDisabled = false

    if( this.state.disabled ) isDisabled = true  
    
    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">
            <div className="modalCreateTitle">Create new group pricing</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          { ( 
            ( entities && entities.length > 1 && !this.state.cat && !this.props.existingDeal ) || 
            options.restrictedDeals 
          ) &&
            <div  className="dealCreate">
              <div id="dealSubTitle">Accessibility</div>
              <div id="newGroupLabel">Entity</div>
              <div id="newDealHalf" className="dealCreateLeft">
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="dealDetailsInput"
                  onChange={(e)=>{ 
                    let group = Object.assign({}, this.state.group); 
                    group.entity = e.target.value
                    this.filterEntity(entities, e.target.value) 
                    this.setState({ group }) 
                  }}
                > 
                  <option value="">Choose entity</option>
                  { mappedEntities }
                </FormControl>  
              </div>
              { ! entity 
                ? <div id="newDealHalf" className="groupCreateLeft">
                    (leave empty to create a global group)
                  </div>
                : <div id="newDealHalf" className="groupCreateLeft redText">
                    will only be accessible to  {entity.name}
                  </div>
              }
            </div> }    

            <div className="dealCreate">
              <div id="dealSubTitle">Description</div>

              <div id="newGroupLabel">
                Group name
              </div>
              <div id="newGroup">
                <FormControl 
                  type="text" 
                  className="dealDetailsInput"
                  placeholder="Type group name"
                  onChange={(e)=>{ 
                    let group = Object.assign({}, this.state.group); 
                    group.groupName = e.target.value; 
                    this.setState({ group }) 
                  }}
                />
              </div>            
              
              <div id="dealSection">
                <div id="newGroupLabel">
                  Group min size
                </div> 
                <div id="newGroupQuarter">
                  <FormControl 
                    type="number" 
                    className="dealDetailsInput"
                    onChange={(e)=>{ 
                      let group = Object.assign({}, this.state.group); 
                      group.minSize = e.target.value; 
                      this.setState({ group }) 
                    }}
                  />
                </div>   
                <div id="newGroupLabel">
                  Group max size
                </div> 
                <div id="newGroupQuarter">
                  <FormControl 
                    type="number" 
                    className="dealDetailsInput"
                    onChange={(e)=>{ 
                      let group = Object.assign({}, this.state.group); 
                      group.maxSize = e.target.value; 
                      this.setState({ group }) 
                    }}
                  />
                </div>   
              </div>
              
              <div id="dealSection">
                <div id="newGroupLabel">
                  Solidary
                </div> 
                <div id="newGroupQuarter">
                  <FormControl 
                    className="dealDetailsInput"
                    controlId="formControlsSelect"
                    componentClass="select"               
                    onChange={(e)=>{ 
                      let group = Object.assign({}, this.state.group); 
                      group.type = e.target.value === 'True' ? 'Solidary' : 'Loose'; 
                      this.setState({ group }) 
                    }}
                  > 
                    <option value="">Choose</option>
                    <option value={'True'}>True</option>
                    <option value={'False'}>False</option>
                  </FormControl>
                </div>    
                <div id="newGroupHalf">
                  If "true", group members will collectively only receive activation codes when all members have paid
                </div>
              </div>
              
              <div id="dealSection">
                <div id="newGroupLabel">Eligeable product</div>
                <div id="newGroup">
                  <FormControl 
                    controlId="formControlsSelect"
                    componentClass="select" 
                    className="dealDetailsInput"
                    onChange={(e)=>{ 
                      let group = Object.assign({}, this.state.group); 
                      group.eligibleProduct = e.target.value; 
                      this.setState({ group });
                      }}
                    >
                    <option value="">Select one</option>
                    { mappedProducts }
                  </FormControl>
                </div>  
              </div>

              { group.eligibleProduct &&
                <div  id="dealSection">
                  <div id="newGroupLabel">Eligeable deals</div>
                  <div id="newGroup">
                    { mappedDeals }
                  </div>
                </div>  
              }

            </div>

          <div id="newDealAction">
              { ! this.state.status &&
                <Button id="action_signup" disabled={isDisabled} onClick={()=>this.createGroup()}>Create</Button>
              }
              <Button id="action_cancel" onClick={()=> this.props.onHide()}>Abort</Button>
            </div>  
            { this.renderMessage() }

        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateGroupPricing