import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import axios from 'axios';
import { API_URL } from '../../../Actions/index';

class Progress extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
      },
      data: undefined
    };
  };

  componentDidMount() {
    this.getGraph()
  }

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) this.getGraph();
  }

  getGraph = () => {
    let body = {
      criteria: this.props.filter,
      userId: this.props.user._id
    }
    axios
      .post(API_URL + '/contracts/progress/' + this.props.user.supplier, body )
      .then((res) => {
        this.setState({
          data: res.data
        })
      })
  }

  render() {
    let data = this.state.data
    let label = ['< 25%', ' 25% to 49%', '50% to 74%', '75% to 99%', '100%' ]
    let dataset = []

    let l25 = 0
    let m25l50 = 0
    let m50l75 = 0
    let m75l100 = 0
    let l100 = 0
    let avgl25 = 0
    let avgm25l50 = 0
    let avgm50l75 = 0
    let avgm75l100 = 0
    let avgl100 = 0
    if( data ){
      l25 = data.find( elem => elem._id == 0 ) ? data.find( elem => elem._id == 0 ).count : 0
      m25l50 = data.find( elem => elem._id == 0.25 )? data.find( elem => elem._id == 0.25 ).count : 0;
      m50l75 = data.find( elem => elem._id == 0.5 )? data.find( elem => elem._id == 0.5 ).count : 0
      m75l100 = data.find( elem => elem._id == 0.75 )? data.find( elem => elem._id == 0.75 ).count : 0
      l100 = data.find( elem => elem._id == 1 )? data.find( elem => elem._id == 1 ).count :0
     
      avgl25 = data.find( elem => elem._id == 0 ) ? data.find( elem => elem._id == 0 ).avg : 0
      avgm25l50 = data.find( elem => elem._id == 0.25 )? data.find( elem => elem._id == 0.25 ).avg : 0;
      avgm50l75 = data.find( elem => elem._id == 0.5 )? data.find( elem => elem._id == 0.5 ).avg : 0
      avgm75l100 = data.find( elem => elem._id == 0.75 )? data.find( elem => elem._id == 0.75 ).avg : 0
      avgl100 = data.find( elem => elem._id == 1 )? data.find( elem => elem._id == 1 ).avg :0

      dataset = [ l25, m25l50, m50l75, m75l100, l100 ]
    }
    let total = l25 + m25l50 + m50l75 + m75l100 + l100
    let divider = total > 0 ? total: 1
    let weighted = Math.round( ( avgl25 * l25 + avgm25l50 * m25l50 + avgm50l75 * m50l75 + avgm75l100 * m75l100 + avgl100 * l100 ) * 100 / divider )
      
    return (
      <div>
            
        <div id="graphTitle">Repayment progress</div>
        <div id="graphTitleData">{ weighted }%</div>
        <div id="graphWrapper">
          <Doughnut 
            data={{
              labels: label, 
              datasets: [
                {
                  label: '$',
                  backgroundColor: [
                    'rgb(128, 255, 255)',
                    'rgb(65, 255, 255)',
                    'rgb(27, 218, 218)',
                    'rgb(11, 179, 179)',
                    'rgba(0,139,139, 1)'
                  ],
                  borderColor: '#26455A',
                  borderWidth: 1,
                  hoverBackgroundColor: [
                    'rgb(128, 255, 255)',
                    'rgb(65, 255, 255)',
                    'rgb(27, 218, 218)',
                    'rgb(11, 179, 179)',
                    'rgba(0,139,139, 1)'
                  ],
                  hoverBorderColor: [
                    'rgb(128, 255, 255)',
                    'rgb(65, 255, 255)',
                    'rgb(27, 218, 218)',
                    'rgb(11, 179, 179)',
                    'rgba(0,139,139, 1)'
                  ],
                  data: dataset
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ this.state.options }
          />
        </div>
              
      </div>      
    );
  }
}

export default Progress