import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import InvoicesList from './List/InvoicesList';
import AdminMenu from './../AdminMenu'
import './invoice.css'

class InvoicesController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      invoices: undefined   
    };
  }

  componentDidMount() {
    this.loadData()
  }

	getInvoices = () => {
    axios.get(API_URL + '/invoices/' + this.props.user.supplier )
			.then((ret) => {
        this.setState({ invoices: ret.data })
      })
  }
  
  loadData() {
    this.getInvoices();
  }

  render() {    
    
    let user = this.props.user;

    return (
			<div className='background'>

        <div id="pageTitle">Invoices</div>
				<AdminMenu user={ user }/>
				
        <div id="clientBackground">
          
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" className="active_clientSubMenu">All invoices</div>
          </div>
          
          { this.state.invoices && this.state.invoices.length > 0
            ? <div id="assetsList">
                <InvoicesList invoices={ this.state.invoices }/>
              </div>
            : null } 

  
          </div>
			</div>
    );
  }
}

export default InvoicesController