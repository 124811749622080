

import { checkLevelPermission } from './EntityService'

export function hasPermission( user, object, permission, exclusive ){
 
  let userEntityType = user.entityLevel && user.entityLevel ? user.entityLevel : 'Global'
  let objectEntity = object? object.entity : undefined

  if( ['MainAdmin', 'Core'].indexOf(user.role) > -1 ) return true

  if( exclusive ) return false

  if( [ 'Admin', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin' ].indexOf(user.role) > -1 ) {
    if( object ) return checkLevelPermission( userEntityType, objectEntity ) 
    else return true
  }

  if( objectEntity && !checkLevelPermission( userEntityType, objectEntity ) ) return false

  if( !user.permissions ) return false

  return user.permissions[permission]

}

