import React, { Component } from 'react';
import axios from 'axios';
import { API_URL, emptyList } from './../../../Actions/index';
import AgentsList from './List/AgentsList'
import AgentProfile from './Details/AgentProfile'
import AgentAnalysis from './Details/AgentAnalysis';
import Performance from './Details/Performance'
import AllocateAgents from './Action/AllocateAgents'
import history from './../../../history';
import ManageMenu from './../ManageMenu'
import ReconcilePayments from '../Payments/Action/ReconcilePayments'
import AssetsList from './../Assets/AssetsListLight/AssetsList'
import PaymentsList from '../Payments/List/PaymentsList'
import TasksList from '../Notes/TasksList/TasksList'
import ContractsList from './../Contracts/ContractsListLight/ContractsList'
import AgentTargetsController from './../AgentTargets/AgentTargetsController'
import AgentInvoicesController from './../AgentInvoices/AgentInvoicesController'
import HitMap from './../Google-GeoLocation/HitMap'
import { connect } from 'react-redux';
import { hasPermission } from '../../../services/UserService'
import './agent.css';

class AgentWrapper extends Component {
  constructor(props) {
		super(props)
		this.state = {
      reconcile: false,
      showAssets: false,
      showCom: false,
      showAgents: false,
      showPayments: false,
      showContracts: false,
      showMap: false,
      showPerf: false,
      showBills: false,
      showTargets: false,
      allocateAgents: false
    };
  }
  
  componentDidMount() {
    this.getAgentDetails();
    this.getNotes();
    window.scrollTo(0, 0)
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.match.params.agentNumber !== prevProps.match.params.agentNumber) {
      this.getAgentDetails();
    }
  }

  goBack() {
    window.history.back();
  }

  closeAllocateAgents() {
    this.setState({ allocateAgents: false });
    this.getAgentDetails()
  }

	getAgentDetails = () => {
    let supplierId = this.props.user.supplier
    let agentNumber = this.props.match.params.agentNumber
    axios.get(API_URL + '/agents/bynumber/' + supplierId + '/'+ agentNumber)
			.then((ret) => {
        this.setState({
          agent: ret.data.agent,
          assets: ret.data.assets,
          agentAssets: ret.data.assets,
          contracts: ret.data.contracts,
          agentContracts: ret.data.contracts,
          agents: ret.data.agents,
        })
        if( ret.data.agent.role == 'Manager') return this.getAgentTeam( agentNumber, supplierId )
      }).catch( err => console.log(err) )
  }

  getAgentTeam = (agentNumber, supplierId ) => {
    axios.get(API_URL + '/agents/team/' + supplierId + '/'+ agentNumber)
			.then((ret) => {
        this.setState({
          teamAssets: ret.data.teamAssets,
          teamContracts: ret.data.teamContracts,
          agents: ret.data.agents,
        })
      }).catch( err => console.log(err) )
  }
   
	getNotes = () => {
    axios.get(API_URL + '/notes/foragent/' + this.props.user.supplier + '/'+ this.props.match.params.agentNumber)
			.then((ret) => { this.setState({ notes: ret.data }) })
  }

  getPaymentsList(agent) {
    let body = {
      query: { agent: agent._id },
      fromPage: 1,
      pageSize: 2000
    }
    axios.post(API_URL + '/payments/find/' + agent.supplier, body)
			.then((ret) =>  this.setState({ payments: ret.data.docs }) )
  }

  closeReconcile() {
    this.setState({ reconcile: false });
    this.getPaymentsList(this.state.agent);
    this.getAgentDetails()
    this.props.emptyList()
  }


  showPayments(agent, state){
    this.setState({ showPayments: !state })
    this.getPaymentsList(agent) 
    if( state ) this.props.emptyList()
  }

  loadData = () => {
    this.getAgentDetails();
    this.getNotes();
  }
  
  render() {    
    let user = this.props.user
    let options = user.options

    let { agent, agentContracts, teamContracts, agentAssets, teamAssets, agents } = this.state
    let permission = agent && hasPermission( user, agent, 'reconcileCash' )
    let manageAgentsPermission = agent && hasPermission( user, agent, 'manageAgents')

    let managedContracts = 0
    let managedAssets = 0
    if( agents ){
      agents.forEach( elem => {
        if( elem.contracts ) managedContracts += elem.contracts
        if( elem.assets ) managedAssets += elem.assets
       });
    }

    let stock = 0
    let defective = 0
    if( agentAssets ){
      agentAssets.forEach( elem => {
        if( elem.status == 'INSTOCK' ) stock += 1
        if( elem.status == 'DEFECTIVE' ) defective += 1
      })
    }
    
    return (
      <div className="background">

        <div id="pageTitle">Agent <span className="subTitle">{this.props.match.params.agentNumber}</span></div>  
        <ManageMenu user={user}/>

        <div id="clientActionLine">
          <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
        </div>

        <div id="clientBackground">

          <div id="agentShortSection">
            { agent
              ? <AgentProfile 
                  agent={ agent }
                  refresh={this.loadData}
                  user={ user }
                  options={ options }
              />
              : <div id="agentProfile" className="loader">Loading... <br/><i className="fas fa-spinner"></i></div> }
          </div>

          <div id="agentLongSection">   
            { agent
              ? <AgentAnalysis 
                  agent={ agent } 
                  options={ options } 
                  user={ user }
                  agentContracts={ agentContracts } 
                  teamContracts={ teamContracts } 
                />
              : <div id="agentAnalysis" className="loader">Loading... <br/><i className="fas fa-spinner"></i></div> }
          </div>

          { agent && agent.cashCollection == true &&
            <div id="agentRow">
              <div 
                id="agentSubSectionTitle" 
                onClick={()=>this.showPayments(agent, this.state.showPayments)}
                className={this.state.showPayments? "activeSubSection com" : "com" }
              >
                <div id="agentUP">Unreconciled payments</div>
                <div id="agentUP" className={agent.unreconciledAmount > 0? 'red': 'white'}>{ agent.ccy } { agent.unreconciledAmount? agent.unreconciledAmount.toLocaleString(): 0 }</div>
              </div>  
              { ! this.state.showPayments 
                ? null
                : this.state.payments && this.state.payments.length > 0
                  ? <div>
                      <div id={ this.props.selectedPayments && this.props.selectedPayments.length > 0? "agentPaymentList": ''}>
                        <PaymentsList 
                          payments={ this.state.payments } 
                          user={ user } 
                          fromAgentPage={ true }
                        />
                      </div>
                      { this.props.selectedPayments && this.props.selectedPayments.length > 0 && 
                        <div id="agentReconcile" onClick={()=>this.setState({ reconcile: true })}>Reconcile</div> }
                    </div>
                  : <div className='noManagedAgents'>No unreconciled payments at this time</div> }
            
            </div>
          }


          { agent && agent.role == 'Manager' && options.agentManagers &&
            <div id="agentRow">
              <div 
                id="agentSubSectionTitle" 
                onClick={()=>this.setState({ showAgents: !this.state.showAgents })}
                className={this.state.showAgents? "activeSubSection manager" : "manager" }
              >
                Managed agents <span id="agentFigure">{this.state.agents? this.state.agents.length: '0'}</span>
              </div>
              { this.state.showAgents
                ? <div>
                    { manageAgentsPermission &&
                      <div className="allocateAgents" onClick={() => this.setState({ allocateAgents: !this.state.allocateAgents })}>
                        <span style={{ paddingRight: '10px' }}><i className="fas fa-users-cog"></i></span>Edit 
                      </div> }
                    { this.state.agents && this.state.agents.length > 0
                      ? <AgentsList agents={this.state.agents} hideEdit={ true } />
                      : <div className='noManagedAgents'>No managed agents at this time</div> }
                  </div>
                : null }
            </div>
          }

          <div id="agentRow">

            <div id="agentSection">                    
              <div 
                id="agentSubSectionTitle" 
                className={this.state.showContracts? "activeSubSection subleft" : "subleft"}
                onClick={()=>{
                  if( this.state.contracts == agentContracts) this.setState({ showContracts: !this.state.showContracts, contracts: agentContracts })
                  else this.setState({ contracts: agentContracts })
                }}
              >
                <div className="agentHalf">
                  Contracts <span id="agentFigure">{ agentContracts ? agentContracts.length : '0' }</span>
                </div>
                { agent && agent.role == 'Manager' &&
                  <div className="agentHalf">
                    Team <span id="agentFigure">{ managedContracts }</span>
                  </div> }
              </div>

              { this.state.contracts && this.state.showContracts &&
                <ContractsList contracts={ this.state.contracts }/> }
                
              { teamContracts && this.state.showContracts && agent && agent.role == 'Manager' &&
                <div>
                  <div id="AgentSubTitle">Team contracts</div>
                  <ContractsList contracts={ teamContracts } hideHeader={ true }/>
                </div> }

            </div>

            <div id="agentSection">
              <div 
                id="agentSubSectionTitle" 
                className={this.state.showAssets? "activeSubSection subright" : "subright" }
                onClick={()=>{
                  if( this.state.assets == agentAssets) this.setState({ showAssets: !this.state.showAssets, assets: agentAssets })
                  else this.setState({ assets: agentAssets })
                }}
              >
                <div 
                  id="half"
                >
                  Stock <span id="agentFigure">{ stock }</span>{ defective > 0 ? <span id="agentDefaultFigure">+ { defective } defective</span> : null }
                </div>
                { agent && agent.role == 'Manager' &&
                  <div  id="half">
                    Team <span id="agentFigure">{ managedAssets }</span>
                  </div> }
              </div>              
              
              { this.state.agent && this.state.assets && this.state.showAssets &&
                <AssetsList assets={this.state.assets} /> }

              { this.state.showAssets && teamAssets && agent && agent.role == 'Manager' &&
                <div>
                  <div id="AgentSubTitle">Team assets</div>
                  <AssetsList assets={ teamAssets } hideHeader={ true }/>
                </div> }

            </div>
            
          </div>

          <div id="agentLargeSection">
            <div 
              id="agentSubSectionTitle" 
              onClick={()=>this.setState({ showMap: !this.state.showMap })}
              className={this.state.showMap? "activeSubSection com" : "com" }
            >
              Hit map
            </div>  
            { this.state.showMap && <HitMap agent={ agent } supplierId={ this.props.user.supplier }/> }
          </div>

          { agent && options && options.manageAgents &&
            <div> 
              <div id="agentRow">

                <div id="agentSection">                    
                  <div 
                    id="agentSubSectionTitle" 
                    onClick={()=>this.setState({ showTargets: !this.state.showTargets })}
                    className={this.state.showTargets? "activeSubSection subleft" : "subleft" }
                  >
                    Targets
                  </div>
                  { this.state.showTargets &&
                    <AgentTargetsController user={ user } agent={ agent }/> }
                </div>

                <div id="agentSection">
                  <div 
                    id="agentSubSectionTitle" 
                    onClick={()=>this.setState({ showBills: !this.state.showBills })}
                    className={this.state.showBills? "activeSubSection subright" : "subright" }
                  >
                    Payrolls
                  </div>              
                  { this.state.showBills &&
                    <AgentInvoicesController 
                      user={ user } 
                      agent={ agent }
                      contracts={ this.state.agentContracts }
                      teamContracts= { this.state.teamContracts }
                    /> }
                </div>

              </div>
              <div id="agentLargeSection">
                  <div 
                    id="agentSubSectionTitle" 
                    onClick={()=>this.setState({ showPerf: !this.state.showPerf })}
                    className={this.state.showCom? "activeSubSection com" : "com" }
                  >
                    Performance analysis
                  </div>  
                  { this.state.showPerf &&
                    <Performance 
                      agent={ agent } 
                      user={ user }
                    /> }
                </div>
            </div> }

          <div id="agentLargeSection">
            <div 
              id="agentSubSectionTitle" 
              onClick={()=>this.setState({ showCom: !this.state.showCom })}
              className={this.state.showCom? "activeSubSection com" : "com" }
            >
              Communication
            </div>  
            { this.state.notes && agent && this.state.showCom &&
              <TasksList 
                notes={ this.state.notes } 
                agent={ agent } 
                refresh={ this.getNotes } 
                user={ user }
              /> }
          </div>

        </div>

        { this.state.allocateAgents &&
          <AllocateAgents 
            show={ this.state.allocateAgents } 
            onHide={ this.closeAllocateAgents.bind(this) }
            agentNumber={ agent.agentNumber }
            user={ this.props.user }
            agents={ this.state.agents }
          />
        }

        { this.state.reconcile && permission &&
          <ReconcilePayments 
            show={ this.state.reconcile } 
            onHide={ this.closeReconcile.bind(this) }
            payments={ this.props.selectedPayments }
            user={ user} 
          />  }

      </div>
    );
  }
}

const Agent = connect(
  function mapStateToProps(state) {
    return { 
      selectedPayments: state.selectedPayments
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(AgentWrapper);

export default Agent