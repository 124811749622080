import React, { Component } from 'react';
import { Header } from './Header'
import { SelectableItem } from './Item'
import './groups.css';

class GroupsList extends Component {
  
  render() {    
    let mappedGroups = null;
    let groups = this.props.groups
    if(groups){
      groups.sort(function(a, b) {
        return new Date(b.date) - new Date(a.date)
      })
      mappedGroups = groups.map((group, index) =>{ 
        return (
          <SelectableItem 
            key={ group._id }
            group={ group } 
            supplierId={ this.props.supplierId } 
            fromAgentPage={ this.props.fromAgentPage }
            onboardingStatus={ this.props.onboardingStatus }
            user={ this.props.user }
            refresh={ this.props.refresh }
          />
        )
      })
    }

    return (
      <div>
        { groups && groups.length > 0 && <Header/> }
        { mappedGroups }
      </div>
    );
  }
}

export default GroupsList