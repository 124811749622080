import React, { Component } from 'react';
import { emptyList } from '../../../Actions/index';
import ManageMenu from '../ManageMenu'
import history from '../../../history';
import { connect } from 'react-redux';

class Page extends Component {
  constructor(props) {
		super(props)
		this.state = {

    };
  }
  
  handleNext(path){
    history.push(path)
  }

  render() {  

    let user = this.props.user

    return (
      <div className='background'>
        
        <div id="pageTitle">Assets</div>
        <ManageMenu user={ user }/>
        
        <div id="clientBackground">      

          <div id="landingMenu">
         
            <div id="landingMenuItem" onClick={() => this.handleNext('/assets-view')}>
              <div id="landingMenuItemIcon"><i className="far fa-eye"></i></div>
              <div id="landingMenuText">View</div>
            </div>

            <div id="landingMenuItem" onClick={() => this.handleNext('/assets-search')}>
              <div id="landingMenuItemIcon"><i className="fas fa-search"></i></div>
              <div id="landingMenuText">Search</div>
            </div>

            <div id="landingMenuItem" onClick={()=>this.handleNext('assets-add')}>
              <div id="landingMenuItemIcon"><i className="fas fa-barcode"></i></div>
              <div id="landingMenuText">Load</div>
            </div>

            <div id="landingMenuItem" onClick={()=>this.handleNext('/items/type=Part')}>
              <div id="landingMenuItemIcon"><i className="fas fa-wrench"></i></div>
              <div id="landingMenuText">Items</div>
            </div>

          </div>

        </div>

      </div>
    );
  }
}

const Assets = connect(
  function mapStateToProps(state) {
    return { 
      globalFilter: state.globalFilter
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Page);

export default Assets