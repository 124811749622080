import React, { Component } from 'react';
import './../event.css'
import history from '../../../../history';

export class EventsListItem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      show: false,
    }
  };   

  handleNext(path){
    history.push(path)
  }

  render() {
    let event = this.props.event
    
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let date = '-'

    if( event.date ){
      date = new Date(event.date)
      date = date.toLocaleDateString("en-EN", options)
    }

    return (
      <div className="eventItem" >
        <div className="eventDate">{ date }</div>
        <div className="eventType">{ event.type }</div>

        { event.asset
          ? <div>
              <div className="eventUdl active" onClick={() => this.handleNext('asset/id='+event.asset.assetNumber)}>{ event.asset.paygNumber }</div>
              
              { event.oldHolder
                ? <div className="eventHolder active" onClick={() => this.handleNext('agent/id='+event.oldHolder.agentNumber)}>
                    { event.oldHolder.profile.firstName.slice(0,1) + ' ' + event.oldHolder.profile.lastName }
                  </div>
                : null }
          

              { event.oldOwner
                ? <div className="eventHolder active" onClick={() => this.handleNext('client/id='+event.oldOwner.clientNumber)}>
                    { event.oldOwner.profile.firstName.slice(0,1) + ' ' + event.oldOwner.profile.lastName }
                  </div>
                : null }

              { !event.oldHolder && !event.oldOwner
                ? <div className="eventHolder">-</div>
                : null
              }

              { event.newHolder
                ? <div className="eventHolder active" onClick={() => this.handleNext('agent/id='+event.newHolder.agentNumber)}>
                    { event.newHolder.profile.firstName.slice(0,1) + ' ' + event.newHolder.profile.lastName }
                  </div>
                : null }

              { event.newOwner
                ? <div className="eventHolder active" onClick={() => this.handleNext('client/id='+event.newOwner.clientNumber)}>
                    { event.newOwner.profile.firstName.slice(0,1) + ' ' + event.newOwner.profile.lastName }
                  </div>
                : null }

              { !event.newHolder && !event.newOwner
                ? <div className="eventHolder">-</div>
                : null
              }

              <div className="eventAuthor">{ event.author }</div>
              <div className="eventNote">{ event.note }</div>
            </div>
          : null
        }

        { event.contract
          ? <div>
              <div className="eventUdl active" onClick={() => this.handleNext('contract/id='+event.contract.contractNumber)}>{ event.contract.contractNumber }</div>
              <div className="eventInfo">{ event.info }</div>
              <div className="eventInfo">{ event.additionalInfo }</div>
            </div>
          : null }

      </div>
      
    )
  }  
}