import React, { Component } from 'react';
import './../item.css'

class Item extends Component {

  select(){
    this.props.breakdown( this.props.agent, this.props.quality, this.props.status, this.props.auxiliary )
  }
  
  
  render() {
    var { agent, elem } = this.props
    return (
      <div className="itemItem active" onClick={()=>this.select()}>
        <div id="itemAgent">{ agent && agent.profile? agent.profile.firstName + ' ' + agent.profile.lastName: '-' }</div>
        <div id="itemQty">{ elem.total }</div>
      </div>
    )
  }  
}

export default Item 