import React, { Component } from 'react';

class Header extends Component {
  
  render() {
    
    return (
      <div>
        <div id="itemWarehouse">Warehouse</div>
        <div id="itemWithAgents">With agents</div>
        <div className="itemTitle">
          <div id="itemAux">Product -- auxiliary</div>
          <div id="itemTotal">New</div>
          <div id="itemTotal">Second</div>
          <div id="itemTotal">To-check</div>
          <div id="itemTotal">Faulty</div>
          <div id="itemTotal">New</div>
          <div id="itemTotal">Second</div>
          <div id="itemTotal">To-check</div>
        </div>
      </div>

    )
  }  
}

export default Header 