import React from 'react';
import { FormControl, Button } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import './../auxiliaryPricing.css'

class AddGuarantee extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      auxiliaryPricing: {
        guarantee: true,
        ccy: this.props.deal.ccy,
        deal: this.props.deal._id,
        product: this.props.deal.eligeableProduct,
        supplier: this.props.user.supplier
      },       
      auxIncluded: [],
      disabled: false
    };
  } 

  handleList( aux ){
    let list = this.state.auxIncluded
    if( list.indexOf( aux ) < 0 ) {
      this.setState({ 
        auxIncluded: [...this.state.auxIncluded, aux], 
      })
    } else this.setState({ 
        auxIncluded: list.filter( elem => elem !== aux ),
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }  

  edit = async () =>{
    try {
      let guarantee = Object.assign( this.state.auxiliaryPricing, {
        name: this.state.type + ' guarantee',
        lifeInMonths: this.state.lifeInMonths,
        type: this.state.type,
        auxiliaries: this.state.auxIncluded
      } )
      let ret = await axios
        .post(API_URL + '/auxiliaries/create-pricing/'+ this.props.user.supplier, guarantee )
      this.setState({ status: ret.data.message })
    } catch(err){
      console.log(err)
      this.setState({ status: 'Error' })
    }

  }

  render() {
    let { auxiliaries, auxiliaryPricings } = this.props

    let isDisabled = (
      this.state.lifeInMonths &&
      !isNaN(this.state.lifeInMonths) &&
      this.state.lifeInMonths > 0 &&
      ( this.state.type == 'Full' || ( this.state.type == 'Partial' && this.state.auxIncluded.length > 0 ) )
    )? false: true
    
    let mappedAux = auxiliaries && auxiliaries
      .sort((a, b) => a.name.localeCompare(b.name))
      .map( elem => { 
        return ( 
          <div 
            key={ elem._id } 
            className="auxPricing fullAux"
          >
            <div id="auxPricingName">{ elem.name }</div>
            <div id="mListStatus" className="auxPricingAction" onClick={() => this.handleList(elem._id)}>
              { this.state.auxIncluded.indexOf( elem._id ) > -1
                ? <i className="fas fa-check-square"></i>
                : <i className="far fa-check-square"></i> }
            </div>
          </div> 
        )
      })
    let fullCover = auxiliaryPricings.filter( elem => elem.type == 'Full')

    return (
      <div>
        
        <FormControl 
          type="text" 
          className="guaranteeInput"
          value={ this.state.guarantee }
          placeholder="months"
          onChange={(e)=>{ this.setState({ lifeInMonths: e.target.value }) }}
        />
        { this.state.lifeInMonths && 
          <div>  
            <div className="guaranteeLabel">months, covering</div> 
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="guaranteeInput"
              onChange={(e)=>{ 
                this.setState({ type: e.target.value }) 
              }}
              > 
              <option className={'agentOption'} value="">Select</option>
              { (!fullCover || fullCover.length === 0) && <option value={'Full'} className={'agentOption'}>Full cover</option> }
              <option value={'Partial'} className={'agentOption'}>Partial</option>
            </FormControl>
          </div>}
        
        { this.state.type == 'Partial' && 
        <div>
          <div className="guaranteeLabelLong">including only following parts</div>
          { mappedAux }
        </div> }
            
        <div id="dealSection">{ this.renderMessage() } </div>   
        
        <div id="addGuaranteeAction">        
          
          { this.state.status !== 'Success' &&
            <Button id="action_signup" disabled={isDisabled} onClick={this.edit.bind(this)}>Edit</Button> }

          <Button id="action_cancel" onClick={()=>this.props.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
        </div>

      </div>
  );
}
}

export default AddGuarantee;
