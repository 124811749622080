import React, { Component } from 'react';
import {Button, FormControl} from 'react-bootstrap/lib';
import './../contract.css';
import './../../Utils/utils.css'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class MoveDeal extends Component {

  constructor(props) {
    super(props);
    this.state= {
      dealId: undefined, 
      disabled: true,
      deals: undefined, 
      status: undefined,
    }
  }; 

  componentDidMount() {
    this.loadData()
  }

  amendContract() {
    var query = {
      contractId: this.props.contract._id,
      userId: this.props.user._id,
      dealId: this.state.dealId
    }
    axios.put(API_URL + '/contracts/moveDeal/'+ this.props.contract.supplier, query)
      .then((ret) => {
          this.setState({ 
            status: ret.data.message,
          });
      })
      .catch(err => {
        console.log(err)
        let message = err.data? err.data.message: 'Error'
        this.setState({
          status: message
        })
      }); 
  }

  getDeals() {
    axios.post(API_URL + '/deals/findForProduct/' + this.props.user.supplier, { 
      product: this.props.contract.product 
    })
      .then((ret) => this.setState({ deals: ret.data }) )
  }

  loadData() {
    this.getDeals();
  }

  renderMessage() {
    if( this.state.status ) {
      return (
        <div id={this.state.status == 'Success!'?'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  } 

  render() {    
    let mappedDeals = null
    if( this.state.deals ){
      mappedDeals = this.state.deals.map((deal, index) =>{ 
        return (
          <option key={deal._id} value={deal._id} className={'agentOption'}>{deal.dealName}</option>
        )
      })
    }

    return (
      <div>

        <div id="contractEditTitle">Move this contract to another deal</div>

        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="graphSelector dealSelector"
          onChange={(e)=>{ 
            this.setState({ dealId: e.target.value }) 
          }}                >
          <option value="" className={'agentOption'}>Choose deal</option>
          { mappedDeals }
        </FormControl>

        <div id="contractEditWrapper">
          <Button id="updateButton" onClick={()=>this.amendContract()}>Change deal</Button>
        </div>

        {this.renderMessage()} 
      </div>
    );
  }
}

export default MoveDeal