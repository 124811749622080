import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import './marker.css'

let options =[
  {
      "featureType": "landscape.natural",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#405c6eb3"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "hue": "#1900ff"
          },
          {
              "color": "#26455A"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
          {
              "lightness": 100
          },
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "lightness": 700
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "color": "#00253D"
          }
      ]
  }
]

const Marker = ({ contractNumber }) => <div className="marker"><i className="fas fa-solar-panel"></i></div>;
const Agent = () => <div className="marker-home"><i className="fas fa-laptop-house"></i></div>;

class HitMap extends Component {
  constructor(props) {
		super(props)
		this.state = {
      agent: undefined,
      contracts: undefined,
    };
  }
  
  componentDidMount() {
    this.getMap();
  }
  

	getMap = () => {
    let query = {
      agentNumber: this.props.agent.agentNumber
    }
    axios.post(API_URL + '/agents/hit-map/' + this.props.supplierId, query)
			.then((ret) => {
        this.setState({
          agent: ret.data.agent,
          contracts: ret.data.contracts
        })
      }).catch( err => console.log(err) )
  }
 
  render() {
    let contracts = this.state.contracts
    let agent = this.state.agent
    let count = 0
    let defaultCenter = {
      lat: 51.5074,
      lng: 0.1278
    }
    let mappedMvts = null;
    let points = contracts && contracts.filter( elem => elem.asset && elem.asset.gps )

    if( points && points.length > 0 ){
      count = points.length
      mappedMvts = points.map( (contract, index) => {
        return (
          <Marker 
            key={ index } 
            lng={ contract.asset.gps.longitude }
            lat={ contract.asset.gps.latitude }
            contractNumber={ contract.contractNumber }
          />
        )
      })
    }
    if( points && points[0] && points[0].asset.gps ){
      defaultCenter = {
        lat: parseFloat( points[0].asset.gps.latitude ),
        lng: parseFloat( points[0].asset.gps.longitude )
      }
    }
    if( agent && agent.gps ){
      defaultCenter = {
        lat: parseFloat( agent.gps.latitude ),
        lng: parseFloat( agent.gps.longitude )
      }
    }

    let agentHome = null
    if( agent && agent.gps ){
      agentHome = <Agent 
        lng={ parseFloat(agent.gps.longitude) }
        lat={ parseFloat(agent.gps.latitude) }
    />
    }

    return (
      // Important! Always set the container height explicitly
      <div id="hitMapWrapper">
        <div id="hitMapWrapperTitle">{ count } units tracked</div>
        <div id="assetMap">
          { contracts && agent &&
            <GoogleMapReact
              bootstrapURLKeys={{ 
                key: 'AIzaSyAZb4Vrv1pxm9-MEQrYLsq7oYqQNJvi7Es',
                language: 'en',
              }}
              options={{
                styles: options,
            }}
              defaultCenter={defaultCenter}
              defaultZoom={11}
            >
            
            { mappedMvts }   
            { agentHome }     
            </GoogleMapReact> }
        </div>
      </div>
    );
  }
}
 
export default HitMap;