import React, { Component } from 'react';

import * as firebase from 'firebase';

class ContractPhoto extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
      url: undefined
    };
  }

  componentDidMount() {
    this.getURL()
  }

  getURL = async () => {
    var storage = firebase.storage();
    var contract = this.props.contract;
    var storageRef = storage.ref();
    let user = this.props.user
    let URL, ref, number, oldRef, oldNumber = undefined;

    ref = 'Contracts/' + user.supplierReference + '_' + contract.contractAgentRef;
    number = 'Contracts/' + user.supplierReference + '_' + contract.contractNumber;

    oldRef = 'Contracts/' + contract.supplier + '_' + contract.contractAgentRef;
    oldNumber = 'Contracts/' + contract.supplier + '_' + contract.contractNumber;


    try { URL = await storageRef.child(ref).getDownloadURL()
    } catch(err){ console.log(err); }

    if( !URL && user.supplierReference){
      try { URL = await storageRef.child(number).getDownloadURL()
      } catch(err){ console.log(err); }
    }

    if( !URL && user.supplierReference){
      try { URL = await storageRef.child(oldRef).getDownloadURL()
      } catch(err){ }
    }  
    if( !URL ){
      try { URL = await storageRef.child(oldNumber).getDownloadURL()
      } catch(err){ }
    }


    if( URL ){ this.setState({ show: true, url: URL })
    } else return;

  }

  render() {   

    return (
      <div >
        <a href={this.state.url} id="pictureLink" target="_blank">Picture of contract</a>
      </div>
    );
  }
}

export default ContractPhoto