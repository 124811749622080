import React from 'react';
import {Bar} from 'react-chartjs-2';
import history from '../../../../history';

class GenderEvolution extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      label: [],
      data: [],
      result: undefined
    };
  };

  render() {
    let data = this.props.data

    let male = []
    let female = []
    let label = []
    if( data && data.length ){

      let allMonths = data
        .map( elem => elem._id.signingDate )
        .filter( elem => elem && elem != '2018-05' )
        .sort(function(a, b) {
          return new Date(a) - new Date(b)
        })
      label = [ ...new Set(allMonths) ]

      label.forEach( month => {
        
        let malePoint = data
          .filter( elem => ['male', 'Male'].indexOf( elem._id.gender ) > -1 && elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(malePoint) && malePoint > 0 ) male.push(malePoint)
        else male.push(0)

        let femalePoint = data
          .filter( elem => ['female', 'Female'].indexOf( elem._id.gender ) > -1 && elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(femalePoint) && femalePoint > 0 ) female.push(femalePoint)
        else female.push(0)

      })

    }

    let options = {
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            stacked: true,
            gridLines: {
              display: false
            },
            labels: label, 

            ticks: {
              fontColor: 'rgba(255, 255, 255, 0.8)',
              fontSize: 10
            },
          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            stacked: true,
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
              display: false
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                  return value.toLocaleString();
              },
              fontColor: 'rgba(255, 255, 255, 0.8)',
              beginAtZero: true,
              fontSize: 10
            },
            labels: {
              show: true
            }
          }
        ]
      }
    }

    return (
      <div>
        { this.props.data.length > 0
        ? <Bar
          data={{
            datasets: [
              {
                type: 'bar',
                label: 'Male',
                backgroundColor: '#007FBA',
                borderColor: '#007FBA',
                borderWidth: 1,
                data: male,
                yAxisID: 'y-axis-1'
              },
              {
                type: 'bar',
                label: 'Female',
                backgroundColor: 'rgb(255, 192, 203)',
                borderColor: 'rgb(255, 192, 203)',
                borderWidth: 1,
                data: female,
                yAxisID: 'y-axis-1'
              }
            ]
          }}
          width={ this.props.width }
          height={ this.props.height }
          options={ options }
        /> 
        : null}
      </div>
    );
  }
}

export default GenderEvolution
