

import axios from 'axios';
import { API_URL } from '../Actions/index';

export function findDeals( criteria, userId, supplierId ){
  let query = Object.assign( criteria, {
    supplier: supplierId, 
    status: { $nin: ['Old_version', 'Deleted'] } 
  })
  return new Promise ( function(resolve, reject){
    axios
      .post(API_URL + '/deals/search/' + supplierId, {
        query,
        userId
      })
      .then( ret => resolve(ret.data) )
      .catch(err => reject(err) )
    })
}

export function findGroupPricings( criteria, userId, supplierId ){
  let query = Object.assign( criteria, {
    supplier: supplierId, 
  })
  return new Promise ( function(resolve, reject){
  axios
    .post(API_URL + '/groups/pricings/' + supplierId, {
      query,
      userId
    })
    .then( ret => resolve(ret.data) )
    .catch(err => reject(err) )
  })
}
