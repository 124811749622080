import React, { Component } from 'react';
import { TemplateHeader } from './TemplateHeader'
import { TemplatesListItem } from './TemplatesListItem'
import './../templates.css';

class TemplatesList extends Component {
  
  render() {    

    let templates = this.props.templates

    let mappedTemplates = templates && templates
      .sort((a, b) => a.type.localeCompare(b.type))  
      .map((template, index) =>{ 
        return (
          <TemplatesListItem key={index} template={template} refresh={this.props.refresh}/>
        )
      })
    return (
      <div id="templatesList">
        { templates && templates.length > 0 ? <TemplateHeader/>:null}
        { mappedTemplates }
      </div>
    );
  }
}

export default TemplatesList