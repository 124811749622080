import React, { Component } from 'react';
import { findQuestionnaires } from  '../../../../services/QuestionnaireService'
import StartCreditScore from './StartCreditScore'
import './../credit.css';

class AddCreditScore extends Component {
  constructor(props) {
		super(props)
		this.state = {
      contract: this.props.client.contracts? this.props.client.contracts[0]: undefined,
      show: false
    };
  }

  componentDidMount() {
    this.getQuestionnairesList() 
  }

  getQuestionnairesList = async () => {
    try {
      let questionnaires = await findQuestionnaires( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ questionnaires })
    } catch(err){ console.log(err) }
  }

  selectQ(questionnaire){
    if( this.state.questionnaire === questionnaire ) this.setState({ questionnaire: undefined })
    else this.setState({questionnaire: questionnaire})
  }

  selectC(contract){
    if( this.state.contract === contract ) this.setState({ contract: undefined })
    else this.setState({ contract })
  }

  render() {  

    let client = this.props.client
    let { questionnaire, questionnaires, contract } = this.state

    let mappedQuestionnaires = questionnaires && questionnaires
      .filter( elem => elem.status == 'Active' )
      .map((elem, index) =>{ 
        return (
          <div 
            id="questionnaireChoiceItem" 
            key={ index } 
            onClick={ ()=> this.selectQ( elem ) }
            className={ questionnaire===elem?'chosenQuestionnaire':'' }
          >
            { elem.name }
          </div>            
        )
      })
        
    let mappedContracts = client.contracts && client.contracts.map((elem, index) =>{ 
      return (
        <div 
          id="questionnaireChoiceItem" 
          key={ index } 
          onClick={ ()=>this.selectC( elem._id ) }
          className={ contract === elem._id ?'chosenQuestionnaire':'' }
        >
          { elem.contractNumber }
        </div>            
      )
    })
      
    return (
      <div>
        <div id="questionnaireChoice">
          <div id="creditResultsSubTitle">Choose questionnaire</div>
          { mappedQuestionnaires }
        </div>
        { client.contracts && client.contracts.length > 1 &&
          <div id="questionnaireChoice">
            <div id="creditResultsSubTitle">Assign to specific contract (optional)</div>
            { mappedContracts }
          </div> }
        { questionnaire && contract &&
          <StartCreditScore 
            questionnaire={ questionnaire } 
            client={ client._id }
            contract={ contract._id }
            supplier={ client.supplier }
          />
        }
      </div>
    );
  }
}

export default AddCreditScore