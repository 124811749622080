import React, { Component } from 'react';
import axios from 'axios';
import { API_URL, emptyList } from './../../../Actions/index';
import PaymentsList from './List/PaymentsList';
import { FormControl } from 'react-bootstrap/lib';
import AddCash from './Action/AddCash'
import RetryPaymentMessages from './Action/RetryPaymentMessages'
import ReconcilePayments from './Action/ReconcilePayments'
import { connect } from 'react-redux';
import ManageMenu from './../ManageMenu'
import './payment.css'
import { findAgents } from  '../../../services/AgentService'
import { hasPermission } from './../../../services/UserService'

var DatePicker = require("react-bootstrap-date-picker");
var startOfDay = require("date-fns/start_of_day");
var endOfDay = require("date-fns/end_of_day");

class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      addCash: false,
      reconcile: false,
      retry: false,
      paymentStatus: 'ACCEPTED', 
      page: 1,
      total: 0,
      pages: 1    
    };
  }

  componentDidMount() {
    this.loadData()
  }

  close() {
    this.setState({ addCash: false });
    this.getPaymentsList( 'ACCEPTED', 1 )
  }

  retry() {
    this.setState({ retry: false });
    this.getPaymentsList( 'ACCEPTED', 1 )
  }

  closeReconcile() {
    this.setState({ reconcile: false });
    this.getPaymentsList(this.state.paymentStatus, 1);
    this.props.emptyList()
  }

  
  handleSelect(status){
    this.setState({ paymentStatus: status }); 
    this.getPaymentsList(status, 1, this.state.reconciliationStatus, this.state.agent, this.state.paidOn, this.state.deliveryStatus );
  }

	getPaymentsList = (status, fromPage, reconciled, agent, paidOn, deliveryStatus) => {
    
    let query = {
      status,
      reconciled,
      deliveryStatus,
      agent
    }
    if( paidOn ){
      query.date = {
        $gte: startOfDay(new Date(paidOn)),
        $lte: endOfDay(new Date(paidOn)),
      }
    }
    var body = {
      query,
      fromPage,
      pageSize: 75,
      user: this.props.user._id
    }

    axios.post(API_URL + '/payments/find/' + this.props.user.supplier, body)
			.then((ret) => {
        this.setState({
          payments: ret.data.docs,
          page: ret.data.page,
          pages: ret.data.pages,
          total: ret.data.total 
        })
      })
  }
  
  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getPaymentsList('ACCEPTED', 1);
    if( this.props.user.options && this.props.user.options.cashCollection ) this.getAgents()
  }

  open(){
    this.setState({ showModal: true });
  }
  
  render() {    
    
    let { page, pages, payments, paymentStatus, reconciliationStatus, deliveryStatus, agent, agents, paidOn, reconcile, addCash, retry } = this.state;
    let { user, selectedPayments } = this.props;
    let options = user.options
    let navigate = null    
    let addPermission = hasPermission( user, undefined, 'addPayments' )
    let transferPermission = hasPermission( user, undefined, 'transferPayments' )
    let reconcilePermission = hasPermission( user, undefined, 'reconcilePayments', true )

    let mappedAgents = agents && agents
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map((agent) =>{ 
        return (
          <option key={agent._id} className='agentOption' value={agent._id}>{agent.profile.lastName} {agent.profile.firstName} ({agent.agentNumber})</option>
        )
      })

  
      navigate = 
      <div className='onlyLarge'>

        <div className="messageDateSelectorWrapper"  >
          <DatePicker 
            className ="messageDateSelector"
            placeholder="Received on"
            value={ paidOn }  
            onChange={(e)=>{ 
              this.setState({ paidOn: e });
              this.getPaymentsList( paymentStatus, 1, reconciliationStatus, agent, e, deliveryStatus  )
            }}
            showClearButton= {false}
            calendarPlacement ={ 'left' }
            dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
            monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
          />          
        </div>  

        <div id="contractSelectorWrapper">
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="assetSelector"
            onChange={ (e) => {
              this.setState({ agent: e.target.value });
              this.getPaymentsList( paymentStatus, 1, reconciliationStatus, e.target.value, paidOn, deliveryStatus )
            }}
          >
            <option value={""} className='agentOption'>{ agent ? "Remove filter": "Filter on agent" }</option>
            { mappedAgents }
          </FormControl>
        </div>  

        <div id="contractSelectorWrapper" >
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="assetSelector"
            onChange={ (e) => {
              if( e.target.value != 'ALL' ){
                this.setState({ reconciliationStatus: e.target.value });
                this.getPaymentsList( paymentStatus, 1, e.target.value, agent, paidOn, deliveryStatus )
              } else {
                this.setState({ reconciliationStatus: undefined });
                this.getPaymentsList( paymentStatus, 1, undefined, agent, paidOn, deliveryStatus )
              }
            
            }}
          >
            <option value="ALL" className='agentOption'>{ reconciliationStatus != undefined ? "Remove filter" : "All" }</option>
            <option value={true} className='agentOption'>Reconciled</option>
            <option value={false} className='agentOption'>Not reconciled</option>
          </FormControl>
        </div>     
        
        <div id="contractSelectorWrapper" >
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="assetSelector"
            onChange={ (e) => {
              this.setState({ deliveryStatus: e.target.value });
              this.getPaymentsList( paymentStatus, 1, reconciliationStatus, agent, paidOn, e.target.value )
            }}
          >
            <option value={undefined} className='agentOption'>{ deliveryStatus != undefined ? "Remove filter" : "Delivery" }</option>
            <option value={'Delivered'} className='agentOption'>Delivered</option>
            <option value={'Pending'} className='agentOption'>Pending</option>
            <option value={'Failed'} className='agentOption'>Failed</option>
          </FormControl>
        </div>     

      </div>

    return (
			<div className='background'>

        <div id="pageTitle">Payments</div>
				<ManageMenu user={ user }/>
				
        <div id="clientBackground">
          
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('ACCEPTED')} className={paymentStatus == 'ACCEPTED'?"active_clientSubMenu leftMenu":"leftMenu"}>Accepted</div>

            { options.acceptFailedPayments &&
              <div id="clientSubMenu_item" onClick={()=>this.handleSelect('UNASSIGNED')} className={paymentStatus == 'UNASSIGNED'?"active_clientSubMenu":""}>Un-assigned</div> }

            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('FAILED')} className={paymentStatus == 'FAILED'?"active_clientSubMenu rightMenu":"rightMenu"}>Failed</div>
            
            { options.cashCollection && addPermission &&
              <div id="paymentAction" onClick={()=>this.setState({ addCash: !addCash })}>
                <i className="fas fa-plus"></i>
              </div> }

            { transferPermission &&
              <div id="paymentAction" onClick={()=>this.setState({ retry: !retry })}>
                <i className="fas fa-retweet"></i>
              </div> }
            
            { navigate }

            { options.cashCollection && selectedPayments.length > 0 &&
              <div id="reconcile" onClick={()=>this.setState({ reconcile: true })}>
                Reconcile payments
              </div> }

          </div>
          
          { payments && payments.length > 0 &&
            <div id="contractsList">
              <div className="paymentNavigator">
                <div className="navButton" onClick={() => {
                  this.setState({ page: 1 })
                  this.getPaymentsList( paymentStatus, 1, reconciliationStatus, agent, paidOn, deliveryStatus )
                }}>
                  <i className="fas fa-angle-double-left"></i>
                </div>
                <div className="navButton" onClick={() => {
                  this.setState({ page: Math.max( 1, page -1 ) })
                  this.getPaymentsList( paymentStatus, Math.max( 1, page -1 ), reconciliationStatus, agent, paidOn, deliveryStatus)
                }}>
                  <i className="fas fa-angle-left"></i>
                </div>
                <div className="navLegend">{ this.state.total } payments</div>
                <div className="navPage">page {page} / {pages} </div>
                <div className="navButton" onClick={() => {
                  this.setState({ page: Math.min( pages, page + 1 )})
                  this.getPaymentsList( paymentStatus, Math.min( pages, page + 1 ), reconciliationStatus, agent, paidOn, deliveryStatus)
                }}>
                  <i className="fas fa-angle-right"></i>
                </div>
                <div className="navButton" onClick={() => { 
                  this.setState({ page: pages })
                  this.getPaymentsList( paymentStatus, pages, reconciliationStatus, agent, paidOn, deliveryStatus)
                }}>
                  <i className="fas fa-angle-double-right"></i>
                </div>
              </div>
            </div>
          } 

          { payments
            ? <div id="paymentsList">
                <PaymentsList 
                  payments={ payments } 
                  paymentStatus={ paymentStatus }
                  supplierId={ user.supplier }
                  user={ user }
                  refresh = {this.getPaymentsList}
                />
              </div>
            : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }

          { addCash &&
            <AddCash 
              show={addCash} 
              onHide={this.close.bind(this)}
              user={ user }
            /> }

          { retry &&
            <RetryPaymentMessages 
              show={retry} 
              onHide={this.retry.bind(this)}
              supplierId={ user.supplier }
            /> }

          { reconcile && reconcilePermission &&
            <ReconcilePayments 
              show={ reconcile } 
              onHide={ this.closeReconcile.bind(this) }
              payments={ selectedPayments }
              agents={ agents }
              user={ user} 
            /> }

          </div>
			</div>
    );
  }
}

const PaymentsController = connect(
  function mapStateToProps(state) {
    return { 
      selectedPayments: state.selectedPayments
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Controller);


export default PaymentsController