import React, { Component } from 'react';
import EditAuxiliaryPricing from '../Action/EditAuxiliaryPricing'
import { hasPermission } from '../../../../services/UserService'
import './auxiliaryPricings.css'

export class Item extends Component {
  constructor(props) {
    super(props);
    this.state= {
      edit: false,
    }
  };  

  edit() {
    this.setState({ edit: false });
  }

  render() {
    var aux = this.props.auxiliaryPricing

    let permission = hasPermission( this.props.user, aux.entity, 'createDeals' )

    return (
      <div className="auxiliaryPricingItem">
        <div id="auxiliaryPricingReference">{aux.reference? aux.reference: '-'}</div>
        <div id="auxiliaryPricingType">{aux.auxiliaries[0] && aux.auxiliaries[0].type? aux.auxiliaries[0].type: '-'}</div>
        <div id="auxiliaryPricingAux">{aux.auxiliaries[0] && aux.auxiliaries[0].name? aux.auxiliaries[0].name: '-'}</div>
        <div id="auxiliaryPricingName">{aux.name? aux.name: '-'}</div>
        <div id="auxiliaryPricingProduct">{ aux.product && aux.product.name ? aux.product.name : '-' }</div>
        <div id="auxiliaryPricingDeal">{ aux.deal && aux.deal.dealName ? aux.deal.dealName : '-' }</div>
        <div id="auxiliaryPricingCost">{ aux.ccy } { aux.cost } </div>
        <div id="auxiliaryPricingCost">{ aux.ccy } { aux.totalCost } </div>
        
        { permission &&
          <div id="auxiliaryPricingEdit" className="active onlyLarge" onClick={()=>this.setState({ edit: true })}>
            <i className="fas fa-pen"></i>
          </div>  }

        { this.state.edit &&
          <EditAuxiliaryPricing
            show={ this.state.edit } 
            onHide={ this.edit.bind(this) }
            user={ this.props.user }
            refresh={ this.props.refresh }
            auxiliaryPricing={ aux }
          /> }

      </div>
    )
  }  
}
