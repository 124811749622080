import React, { Component } from 'react';
import {NotesListItem} from './NotesListItem'
import './../notes.css';

class NotesList extends Component {
  
  render() {    
    let mappedNotes = null;
    let notes = this.props.notes;
    let listOfDiscussions = [];

    if( this.props.filter ) notes = notes.filter( elem => elem.type == this.props.filter )
    notes.sort(function(a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt)
    })
    mappedNotes = notes.map((note, index) => {
      if( listOfDiscussions.indexOf( note.partOfDiscussion ) < 0 && note.partOfDiscussion ){
        listOfDiscussions.push( note.partOfDiscussion )
        let discussion = notes.filter( elem => elem._id == note.partOfDiscussion || elem.partOfDiscussion == note.partOfDiscussion )
        return (
          <NotesListItem 
            key={ note._id } 
            note={ note } 
            notes={ discussion }
            refresh={ this.props.refresh } 
            contract={ this.props.contract } 
            user={ this.props.user }
          />
        )
      } else if( !note.partOfDiscussion && listOfDiscussions.indexOf( note._id ) < 0 ){
        return (
          <NotesListItem 
            key={ note._id } 
            note={ note } 
            refresh={ this.props.refresh } 
            contract={ this.props.contract } 
            user={ this.props.user }
          />
        )
      }
     
    })

    return (
      <div >

        { mappedNotes }
        
      </div>
    );
  }
}

export default NotesList