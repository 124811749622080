import React, { Component } from 'react';
import {FormControl, Button} from 'react-bootstrap/lib';
import history from './../../../../history';
import './../client.css';
import './../../Utils/utils.css'
import './../../welcome.css'
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';
import ClientPhoto from './../../Images/ClientPhoto'
import countryDirectory from './../../../../config/countryDirectory'
import { SelectAgent } from './../../Agents/Action/SelectAgent'

var DatePicker = require("react-bootstrap-date-picker");

class ClientAction extends Component {

  constructor(props) {
    super(props);
    this.state= {
      client: {
        profile:{
          gps: {},
        },
        contact: {},
        createdBy: this.props.user._id,
        country: this.props.user.country,
        status: this.props.existingClient? this.props.existingClient.status:'Pending',
        supplier: this.props.user? this.props.user.supplier: this.props.supplier
      },   
      ready: false,
      disabled: false
    }
  }; 

  handleNext(path){
    history.push(path)
  }

  createClient(client) {
    this.setState({ disabled: true});

    let supplierId = this.props.user? this.props.user.supplier: this.props.supplier
    let body = {
      clientList: [ client ],
      userId: this.props.user._id,
      agent: client.agent
    }
    
    axios.post(API_URL + '/clients/create/'+ supplierId, body)
      .then((ret) => {
        this.setState({ status: 'Success' });
        this.setState({ clientNumber: ret.data.createdClients[0].clientNumber});
      })
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  selectAgent = (agent) =>{
    let client = Object.assign({}, this.state.client); 
    client.agent =agent; 
    this.setState({ client });
  }

  amendClient(client) {

    this.setState({ disabled: true});
    let supplierId = this.props.user? this.props.user.supplier: this.props.supplier
    let clientNumber = this.props.existingClient? this.props.existingClient.clientNumber: ''
    
    axios.put(API_URL + '/clients/amend/'+ supplierId + '/'+ clientNumber, client)
      .then( () => {
        this.setState({ status: 'Success'});
        this.props.refresh()
      })
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success'?'successfulAction':'successfulAction'} className='aligned'>
          {this.state.status}
        </div>
      );
    }
  }  

  handleStatusChange(option){
    var query = {
      action: option,
      clients: [this.props.existingClient._id]
    }
    let supplier = this.props.user? this.props.user.supplier: this.props.supplier
    axios.put(API_URL + '/clients/credit/' + supplier, query)
			.then((ret) => {
        this.setState({status: 'Success'});
        this.props.refresh()
      })
  }

  render() {    

    let codeOptions, mappedOptions, agent = null
    let { existingClient, user } = this.props
    let { client, status, clientNumber } = this.state

    let agentName = existingClient && existingClient.agent 
      ? existingClient.agent.profile.firstName + ' ' + existingClient.agent.profile.lastName 
      : undefined

    let { options, entityOptions } = user

    if( options ){
      codeOptions = options.countries.map( (country, index) => {
        return <option key={index} value={countryDirectory[country].countryCode} className={'agentOption'}>{ countryDirectory[country].countryCode }</option>
      })
    }

    if( entityOptions ){
      mappedOptions = entityOptions.map( elem => {
        return <option key={elem._id} value={elem._id}>{ elem.name }</option>
      })
    }

    let isDisabled = ( 
      client.contact.mobile && 
      client.contact.countryCode && 
      client.profile.firstName && 
      client.profile.firstName &&
      ( entityOptions.length < 2 || client.agent || client.entity)
    ) ? false : true
    if( this.state.disabled ) isDisabled = true
      
    return (
      <div>
        <div id="clientProfileWrapper">     
             
          <div id="clientProfile">
            <div id="clientPictureWrapper">
              <div id="clientPicture">
                <ClientPhoto client={ existingClient } user={ user }/>
              </div>

              { existingClient &&
                <div> 
                  <div id="clientCredit">

                  { existingClient.status !== 'Signed' &&
                    <div>
                      <div id="creditApprove" onClick={()=>this.handleStatusChange('Approve')} className={existingClient.status==='Approved'?'Selected':null}>
                        {existingClient.status == 'Approved'?'Approved':'Approve'}
                      </div>
                      <div id="creditReject" onClick={()=>this.handleStatusChange('Reject')} className={existingClient.status==='Rejected'?'Selected':null}>
                        { existingClient.status == 'Rejected'?'Rejected':'Reject' }
                      </div>
                    </div>
                  }

                  </div>
                  <div id="clientCredit">
                    <div id="clientCreditTitle">Rating </div>
                    <FormControl 
                      type="text" 
                      className="creditInput"
                      placeholder={existingClient.score? existingClient.score : 'Credit score'}
                      onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.score = e.target.value; this.setState({ client }) }}
                    />   
                  </div>
                </div>
              }
            </div>
            <div id="spacer">&nbsp;</div>
            <div id="full">
              <div className="detailsLabel">
                <p>FirstName (*)</p>
              </div>
              <FormControl 
                type="text" 
                className="detailsInput"
                placeholder={existingClient?existingClient.profile.firstName:'First name'}
                onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.profile.firstName = e.target.value; this.setState({ client }) }}
                />
            </div>
            <div id="full">
              <div className="detailsLabel">
                <p>LastName (*)</p>
              </div>
              <FormControl 
                type="text" 
                className="detailsInput"
                placeholder={existingClient?existingClient.profile.lastName:'Last name'}
                onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.profile.lastName = e.target.value; this.setState({ client }) }}
                />
            </div>
            <div id="full">
              <div className="detailsLabel">
                <p>Bday - gender</p>
              </div>
              <div className ="detailsInputH L">
                <DatePicker 
                  className ="dateInput"
                  value={ client.profile.birthday
                    ? client.profile.birthday
                    : ( existingClient && existingClient.profile.birthday? existingClient.profile.birthday : null ) 
                   }  
                  onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.profile.birthday = e; this.setState({ client }) }}
                  showClearButton= {false}
                  dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                  monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                />
              </div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="detailsInputH R"
                onChange={(e)=>{ 
                  let client = Object.assign({}, this.state.client); 
                  client.profile.gender = e.target.value; 
                  this.setState({ client });
                  }} 
                >
                <option value="" className={'agentOption'}>Gender</option>
                <option value={'Male'} className={'agentOption'}>Male</option>
                <option value={'Female'} className={'agentOption'}>Female</option>
              </FormControl>
            </div>
            <div id="full">
              <div className="detailsLabel">
                <p>Lat - lon</p>
              </div>
              <FormControl 
                type="text" 
                className="detailsInputH L"
                placeholder={ existingClient && existingClient.profile && existingClient.profile.gps && existingClient.profile.latitude
                  ? existingClient.profile.gps.latitude
                  : 'Latitude'
                }
                onChange={(e)=>{ 
                  let client = Object.assign({}, this.state.client); 
                  client.profile.gps.latitude = e.target.value; 
                  this.setState({ client }) 
                }}
              />
              <FormControl 
                type="text" 
                className="detailsInputH R"
                placeholder={ existingClient && existingClient.profile && existingClient.profile.gps && existingClient.profile.longitude
                  ? existingClient.profile.gps.longitude
                  : 'Longitude'
                }                
                onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.profile.gps.longitude = e.target.value; this.setState({ client }) }}
              />
            </div> 

            <div id="full">
              <div className="detailsLabel">
                <p>Mobile (*)</p>
              </div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="countryCode"
                onChange={(e)=>{ 
                  let client = Object.assign({}, this.state.client); 
                  client.contact.countryCode = e.target.value; 
                  this.setState({ client });
                  }}
                >
                <option value="" className={'agentOption'}>Code</option>
                { codeOptions }
              </FormControl>
              <FormControl 
                type="number" 
                className="phone1 I"
                placeholder={existingClient?existingClient.contact.mobile:'Primary (*)'}
                onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.contact.mobile = e.target.value; this.setState({ client }) }}
              />
              <FormControl 
                type="number" 
                className="phone2 I"
                placeholder={ existingClient && existingClient.contact && existingClient.contact.secondaryMobile
                  ? existingClient.contact.secondaryMobile
                  : 'Secondary'}
                onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.contact.secondaryMobile = e.target.value; this.setState({ client }) }}
              />
            </div> 

            <div id="full">
              <div className="detailsLabel">
                <p>Additional numbers</p>
              </div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="countryCode"
                onChange={(e)=>{ 
                  let client = Object.assign({}, this.state.client); 
                  client.contact.countryCode = e.target.value; 
                  this.setState({ client });
                  }}
                >
                <option value="">Code</option>
                { codeOptions }
              </FormControl>
              <FormControl 
                type="number" 
                className="phone1 I"
                placeholder={existingClient && existingClient.contact && existingClient.contact.neighbour
                  ? existingClient.contact.neighbour
                  :'Neighbour'
                }
                onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.contact.neighbour = e.target.value; this.setState({ client }) }}
              />
              <FormControl 
                type="number" 
                className="phone2 I"
                placeholder={existingClient && existingClient.contact && existingClient.contact.nextOfKin
                  ? existingClient.contact.nextOfKin
                  :'Next of kind'
                }                
                onChange={(e)=>{ let client = Object.assign({}, this.state.client); client.contact.nextOfKin = e.target.value; this.setState({ client }) }}
              />
            </div> 

          </div>

          { ( 
              ['Core', 'MainAdmin', 'Admin'].indexOf( user.role ) > -1 || 
              (user.permissions && user.permissions.transferClients ) ||
              ! existingClient 
            ) &&
            < div id="clientAdditionalInfo">
              <div id="full" className='chooseAgent'>
                {existingClient ? 'Transfer client to new agent' : 'Allocate to agent'}
              </div>
              <div id="full">
                <div className="detailsLabel">
                  <p>Agent</p>
                </div>
                <SelectAgent
                  selectAgent={ this.selectAgent } 
                  format={ "agentInput" }
                  dropDownFormat={ "agentOption" }
                  supplierId={ user.supplier }
                  userId={ user._id }
                  title={ !agentName ? 'Select responsible agent' : agentName }
                />  
              </div>
            </div> }

            { !existingClient && !client.agent && entityOptions && entityOptions.length > 1 && 
              < div id="clientAdditionalInfo">
                <div id="full" className='chooseAgent'>
                  Allocate to entity
                </div>
                <div id="full">
                  <div className="detailsLabel">
                    <p>Entity</p>
                  </div>
                  <FormControl 
                    controlId="formControlsSelect"
                    componentClass="select" 
                    className="agentInput"
                    onChange={(e)=>{ 
                      let client = Object.assign({}, this.state.client); 
                      client.entity = e.target.value
                      this.setState({ client });
                    }}
                  >
                    <option value="">Select one (*)</option>
                    { mappedOptions }
                  </FormControl>
                </div>
              </div>
            }

     
          { this.renderMessage() } 

          <div id="clientActionWrapper">
            { status !== 'Success'
              ? this.props.edit
                ? <Button id="createButton" onClick={()=>this.amendClient(client)}>Update client</Button>
                : <Button id="createButton" disabled={isDisabled} onClick={()=>this.createClient(client)}>Create client</Button>
              : null
            }
          </div>  
        </div>

        { ! existingClient &&
           <div id="clientContractsWrapper">
            <div id="clientContractsTitle">Contracts</div>
            { clientNumber &&
              <div id="contractAdder" onClick={()=>this.handleNext('/contract/add/id='+(existingClient?existingClient.client.clientNumber:this.state.clientNumber))}>
                <p>Create new contract<br/><i className="fas fa-folder-plus"></i></p>
              </div>
            }
          </div> }

      </div>
    );
  }
}

export default ClientAction