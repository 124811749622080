import React, { Component } from 'react';
import './users.css'

export class UserHeader extends Component {
  
  render() {
    return ( 
      <div className="usersTitle">
        <div id="usersMedium" className="onlyLarge" >
          Entity
        </div>
        <div id="usersMedium" className="onlyLarge">
          UserNumber
        </div>  
        <div id="usersMedium" >
          First name
        </div>  
        <div id="usersMedium" >
          Last name
        </div>
        <div id="usersLarge" >
          email
        </div>
        <div id="usersMedium" className="onlyLarge">
          username
        </div>
        <div id="usersMedium"className="onlyLarge" >
          Role
        </div>  
        <div id="usersMedium" className="onlyLarge">
          Created
        </div>  
        <div id="usersMedium">
          Last connected
        </div> 
      </div>
    )
  }  
}