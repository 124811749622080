import React, { Component } from 'react';
import {DealsListItem} from './DealsListItem'
import './deals.css';

class DealsList extends Component {
  
  render() {    
    let mappedDeals, pausedDeals = null;
    let deals = this.props.deals;
    mappedDeals = deals && deals
      .filter( elem => elem.status == 'Active')  
      .sort((a, b) => a.eligibleProduct.name.localeCompare(b.eligibleProduct.name))
      .map( (deal, index) => { 
        return (
          <DealsListItem 
            deal={ deal } 
            refresh={ this.props.refresh }
            user={ this.props.user }
            key={ index }
            questionnaires={ this.props.questionnaires }
          />        
        )
      })
    pausedDeals = deals && deals
      .filter( elem => elem.status == 'Paused')
      .sort((a, b) => a.eligibleProduct.name.localeCompare(b.eligibleProduct.name))
      .map( (deal, index) => { 
        return (
          <DealsListItem 
            deal={ deal } 
            refresh={ this.props.refresh }
            user={ this.props.user }
            key={ index }
            questionnaires={ this.props.questionnaires }
          />        
        )
      })

    return (
      <div id="dealsList">
        { mappedDeals && <div id="pausedList">Active deals</div>}
        { mappedDeals }
        { pausedDeals && <div id="pausedList">Paused deals</div>}
        { pausedDeals }
      </div>
    );
  }
}

export default DealsList