import React, { Component } from 'react';
import Questionnaire from './Questionnaire'
import './../questionnaires.css';

class QuestionnairesList extends Component {
  
  render() {    
    let questionnaires = this.props.questionnaires
    let mappedQuestionnaires = questionnaires && questionnaires
      .filter( elem => elem.status == 'Active')
      .map( questionnaire =>{ 
        return (
          <Questionnaire 
            key={ questionnaire._id }
            user={ this.props.user }
            questionnaire={ questionnaire } 
            refresh={ this.props.refresh }
            supplier={ this.props.supplier }
          />          
        )
      })

    let pausedQuestionnaires = questionnaires && questionnaires
      .filter( elem => elem.status == 'Paused')
      .map( questionnaire =>{ 
        return (
          <Questionnaire 
            key={ questionnaire._id }
            user={ this.props.user }
            questionnaire={ questionnaire } 
            refresh={ this.props.refresh }
            supplier={ this.props.supplier }
          />          
        )
      })

    return (
      <div id="questionnaireList"> 
        { pausedQuestionnaires && pausedQuestionnaires.length > 0 && <div id="listTitle">Active</div> }
        { mappedQuestionnaires }
        { pausedQuestionnaires && pausedQuestionnaires.length > 0 && <div id="listTitle">Paused</div> }
        { pausedQuestionnaires }
      </div>
    );
  }
}

export default QuestionnairesList