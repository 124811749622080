import React, { Component } from 'react';
import history from './../../../../history';
import './messages.css'

export class MessagesListItem extends Component {
  
  constructor(props) {
		super(props)
		this.state = {
      show: false
    }
  } 
  
  handleNext(path){
    history.push(path)
  }

  render() {

    var options = { month: 'short', day: 'numeric' };
    var optionsLong = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    var optionsTime = { hour: '2-digit', minute: '2-digit' };
    let message = this.props.message
    let sentDate = new Date( message.date )
    let sentDetailedDate = new Date( message.date )
    let confirmationDate = '-'
    let confirmationDateShort = '-'
    sentDate = sentDate.toLocaleDateString("en-EN", options)
    sentDetailedDate = sentDetailedDate.toLocaleDateString("en-EN", optionsLong)
    if( message.deliveryDate ){
      let confirmationBase = new Date( message.deliveryDate )
      confirmationDate = confirmationBase.toLocaleDateString("en-EN", optionsLong)
      confirmationDateShort = confirmationBase.toLocaleTimeString("en-EN", optionsTime)
    }

    let details = null
    if( this.state.show ){
      details = 
        <div id="messageDetails">
        
          <div id="messageDetailsBottom">
            <div id="messageDetailsWrapper">
              <div id="messageDetailsL">MessageId</div>
              <div id="messageDetailsD">{ message.messageId? message.messageId: '-' }</div>
            </div>

            { message.transactionId &&
              <div id="messageDetailsWrapper">
                <div id="messageDetailsL">Linked payment id</div>
                <div id="messageDetailsD">{ message.transactionId }</div>
              </div>
            }

            <div id="messageDetailsWrapper">
              <div id="messageDetailsL">ServiceProvider</div>
              <div id="messageDetailsD">{ message.serviceProvider? message.serviceProvider : '-' }</div>
            </div>
            <div id="messageDetailsWrapper">
              <div id="messageDetailsL">Sent on</div>
              <div id="messageDetailsD">{ sentDetailedDate }</div>
            </div>

            { message.cost && this.props.user && this.props.user.options.showTextCost &&
              <div id="messageDetailsWrapper">
                <div id="messageDetailsL">Cost</div>
                <div id="messageDetailsD">{ message.cost }</div>
              </div>
            }
            
            <div id="messageDetailsWrapper">
              <div id="messageDetailsL">Status</div>
              <div id="messageDetailsD">{ message.status }</div>
            </div>
            <div id="messageDetailsWrapper">
              <div id="messageDetailsL">Delivered on</div>
              <div id="messageDetailsD">{ confirmationDate }</div>
            </div>
            <div id="messageDetailsWrapper">
              <div id="messageDetailsL">Reason for failing</div>
              <div id="messageDetailsD">{ message.failureReason? message.failureReason : '-' }</div>
            </div>
            
            <div id="messageDetailsWrapper">
              <div id="messageDetailsL">Cost</div>
              <div id="messageDetailsD">{ message.cost? message.cost : '-' }</div>
            </div>
          </div>
          
        </div>
    }
    
    return (
      <div >
        <div className="messageItem" onClick={ () => this.setState({ show: !this.state.show })}>
          
          { this.props.all
          ? <div id="messagesDateLarge">{ sentDetailedDate }</div>  
          : <div id="messagesDate"> { sentDate } </div>  }
          
          { this.props.all && <div id="deliveryDate">{ confirmationDateShort }</div> }  

          <div id={ this.props.all ? "messagesStatusSmall" : "messagesStatus"} >
            { message.status == 'InvalidPhoneNumber' ? 'Invalid number': message.status }
          </div>
          
          <div id={ this.props.all? "messagesExtraLarge": "messagesLarge" } >
            { message.text }
          </div>     
          
          <div id={ this.props.all? "messagesExtraSmall": "messagesMedium" }>
            { message.receiver }
          </div>  
          
          { this.props.all && message.contract && message.contract[0] &&
            <div id="messagesExtraSmall"  className="active onlyLarge" onClick={()=>this.handleNext('/contract/id='+message.contract[0].contractNumber)}>
              { message.contract[0].contractNumber }
            </div> } 

          { this.props.all && <div id="messageCost">{ message.cost? message.cost : '-' }</div> }   

        </div>
        { details }
      </div>
    )
  }  
}