import React from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class EditDetails extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      product: {},       
      disabled: false
    };
  } 


  edit() {
    axios.put(API_URL + '/products/edit/'+ this.props.product.supplier+ '/' +this.props.product._id, 
        this.state.product
      ).then((ret) => this.setState({ 
        status: ret.data.message, 
        data: ret.data.data
      }) )      
      .catch(err => {
        this.setState({status: 'Error'})
      }); 
  }

  suspend() {
    axios.put(API_URL + '/products/suspend/'+ this.props.product.supplier, {
      productId: this.props.product._id
    }).then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        this.setState({status: 'Error'})
      }); 
  }
  
  
  over(){
    this.props.onHide();
    this.props.refresh()
    this.setState({
      product: {
        name: undefined,
        category: undefined,
        status: undefined,
        description: undefined,
        supplier: this.props.supplier
      },       
      status: undefined,
      disabled: false
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'} className='long'>
          { this.state.status } { this.state.data? this.state.data + ' contracts modified': '' }
        </div>
      );
    }
  }  

  render() {
    let product = this.props.product

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalEditTitle">Edit product details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
            <div id="productSection">
              <div id="productEditLabel">Name</div>
              <div id="productEditDetail">
                <FormControl 
                  type="text" 
                  className="productDetailsInput"
                  value={ this.state.product.name !== undefined
                    ? this.state.product.name
                    : product.name? product.name: '-'
                  }
                  placeholder={'Name'}                
                  onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.name = e.target.value; this.setState({ product }) }}
                />
              </div>
            </div>   
            
            <div id="productSection">
              <div id="productEditLabel">Category</div>
              <div id="productEditDetail">
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="productDetailsInput"
                  onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.category = e.target.value; this.setState({ product }) }}
                  > 
                  <option value={ product.category }>{ product.category }</option>
                  <option value="Energy">Energy</option>
                  <option value="Cooking">Cooking</option>
                  <option value="Entertainment">Entertainment</option>
                  <option value="Communication">Communication</option>
                  <option value="Agri">Agri</option>
                </FormControl>                
              </div>
            </div>   
            <div id="productSection">
              <div id="productEditLabel">Description</div>
              <div id="productEditDetail">
                <textarea 
                  type="textera" 
                  className="productFull"
                  value={ this.state.product.description !== undefined
                    ? this.state.product.description
                    : product.description? product.description: ''
                  }
                  placeholder={'Description (optional)'}
                  onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.description = e.target.value; this.setState({ product }) }}
                />
              </div>
            </div>    
            <div id="productSection">
              <div id="productEditLabel">Status</div>
              <div id="productEditDetail">
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="productDetailsInput"
                  onChange={(e)=>{ let product = Object.assign({}, this.state.product); product.status = e.target.value; this.setState({ product }) }}
                  > 
                  <option value={ product.status }>{ product.status }</option>
                  <option value="Paused">Pause</option>
                  <option value="Deleted">Delete</option>
                  { product.status == 'Paused' ? <option value="Active">Re-activate</option> : null }
                  { product.status !== 'Paused' ? <option value="Suspended">Suspend</option> : null }
                </FormControl>
              </div>
            </div>     

            { this.state.product.status == "Deleted" &&
              <div>
                <div id="productWarning">Warning: you can only deleted products for which there are no active contracts or deals.</div>
                <div id="productWarningLower">Warning: this action is not reversible and this product will no longer appear in your list.</div>
              </div> }

            { this.state.product.status == "Suspended" &&
              <div>
                <div id="productWarning">Warning: this action will move all approved contracts linked to this product back to "pending" status.</div>
                <div id="productWarningLower">You will need to re-approve them one by one.</div>
              </div> }

            { this.renderMessage() }

            <div id="modal_action">
              { this.state.status !== 'Success' &&
                <Button id="action_signup" onClick={this.edit.bind(this)}>Edit</Button> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EditDetails;