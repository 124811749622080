import React, { Component } from 'react';
import CreditScore from './CreditScore';
import AddCreditScore from './Action/AddCreditScore'
import './credit.css';

class CreditScoreController extends Component {
  constructor(props) {
		super(props)
		this.state = {};
  }  

  render() {    
    let client = this.props.client
    let mappedCreditScores, creditScore = null
    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    if( client.creditScores ){
      mappedCreditScores = client.creditScores
        .filter( elem => elem.deleted !== true )
        .sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
        .map((creditScore, index) => { 
        
          let date = new Date( creditScore.date )
          date = date.toLocaleDateString("en-EN", options)
          
          return (
            <div 
              id="creditScoreItem" 
              key={ index } 
              onClick={ ()=>this.setState({ creditScoreId: creditScore._id }) }
              className={ this.state.creditScoreId === creditScore._id ?'chosenQuestionnaire':'' }
            >
              <div id="creditScoreItemDate">{ date }</div>
              <div id="creditScoreItemName">{ creditScore.questionnaire? creditScore.questionnaire.name : '' }</div>

            </div>            
          )
        })
    }

    if( this.state.creditScoreId ){
      creditScore = 
        <CreditScore 
          creditScoreId={ this.state.creditScoreId } 
          supplier={ client.supplier } 
          client={ client } 
        />
    }

    return (
      <div id="creditScore">
        <div id="clientCreditsTitle">Data collection</div>
        <div id="creditResultsCreate" onClick={ () => this.setState({ show: !this.state.show })}>
          <i className="fas fa-plus-circle"></i>
        </div> 
  
        { this.state.show && 
          <AddCreditScore client={ client } user={ this.props.user }/> }
  
        <div id="creditScoreList">{ mappedCreditScores }</div>
        { creditScore }
  
      </div>
    );
  }
}

export default CreditScoreController