import React from 'react';
import {Bar} from 'react-chartjs-2';
import axios from 'axios';
import { API_URL, selectContracts, setTitle } from '../../../Actions/index';
import './graph.css'
import { connect } from 'react-redux';
import history from '../../../history';

var addDays = require('date-fns/add_days');
var eachDay = require('date-fns/each_day')
var format = require("date-fns/format")

export class Graph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.loadData = this.getGraph()
  };
 
  handleNext(path){
    history.push(path)
  }

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) {
      this.getGraph();
    }
  }

  getGraph = () => {
    let filter = Object.assign( this.props.filter, {
      status: 'LOCKED',
      onboardingStatus: 'Signed'
    })
    axios
      .post(API_URL + '/contracts/activity/' + this.props.supplier, filter)
      .then((res) => {
        this.setState({
          data: res.data
        })
      })
  }
 
  render() {

    let dataset = []
    let enrichedData = []
    let start = this.props.filter.startDate? new Date( this.props.filter.startDate ): addDays( new Date(), -30 ) 
    let end = this.props.filter.endDate? new Date( this.props.filter.startDate ): new Date()
    let allDays = eachDay(  start, end)
    let label = allDays.map( elem => format(elem, 'DD-MM') );
    let days = allDays.map( elem => format(elem, 'YYYY-MM-DD') );

    if( this.state.data ){
      days.forEach( day => {
        let item = this.state.data.find( elem => elem._id.lastUpdate == day )
        let value = item? item.count : 0
        dataset.push( value )
        enrichedData.push( item )
      })
    }


    let options = {
      onClick: async (e, item) => {

        if( item[0] && item[0]._index){
          let contracts = enrichedData[item[0]._index].items
          let title = `Contracts locked on ${label[item[0]._index]}`
          let detailedContracts = []
          let query = {
            _id: { $in: contracts }
          }
          try { 
            let res = await axios.put(API_URL + '/contracts/search/' + this.props.supplier, { query })
            detailedContracts = res.data
          } catch(err){ 
            console.log(err) 
          }
          this.props.selectContracts( detailedContracts )
          this.props.setTitle( title )
          this.handleNext('/contracts-analyze')
        }
      },
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          }
        }],
        yAxes: [{
          gridLines: {
            display:false
          }, 
          ticks: {
            min: 0,
            callback: function(value, index, values) {
              return  value;
            }
          }
        }]
      }
    }
    return (
      <div>
        <Bar
          data={{
            labels: label, 
            datasets: [
              {
                label: 'Locked',
                backgroundColor: this.props.color,
                borderColor: this.props.color,
                borderWidth: 1,
                hoverBackgroundColor: this.props.hoverColor,
                hoverBorderColor: this.props.hoverColor,
                data: dataset
              }
            ]
          }}
          width={ this.props.width }
          height={ this.props.height }
          options={ options }
        /> 
      </div>
    );
  }
}

export const Locking = connect(
  function mapStateToProps(state) {
    return { 
      listOfContracts: state.listOfContracts,
      title: state.title
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      selectContracts: (list) => dispatch(selectContracts(list)),
      setTitle: (title) => dispatch( setTitle(title) )
    };
  },
)(Graph);
