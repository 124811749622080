import React,{ Component }  from 'react';
import { Redirect } from 'react-router'
import { connect } from 'react-redux';
import {logoutUser} from '../../Actions/auth';

class Logout extends Component {

  constructor(props){
    super(props);

    this.props.logoutUser();
  }


  render() {
    return <Redirect to="/" />
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, {logoutUser})(Logout);