import React, { Component } from 'react';
import axios from 'axios';
import { API_URL, emptyList } from '../../../Actions/index';
import List from './GroupsList/List';
import ApproveGroup from './Action/ApproveGroup'
import { connect } from 'react-redux';
import ManageMenu from '../ManageMenu'
import './group.css'
import history from './../../../history';


class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      groups: undefined,
      show: false,
      onboardingStatus: 'Signed',
      page: 1,
      total: 0,
      pages: 1    
    };
  }

  componentDidMount() {
    this.loadData()
  }

  handleNext(path){
    history.push(path)
  }

  handleSelect(status){
    this.setState({ onboardingStatus: status }); 
    this.getGroupsList( status, 1 );
  }

	getGroupsList = ( onboardingStatus, fromPage ) => {
    
    var body = {
      query: {
        onboardingStatus: onboardingStatus
      },
      userId: this.props.user._id,
      fromPage: fromPage,
      pageSize: 75
    }

    axios.post(API_URL + '/groups/search/' + this.props.user.supplier, body)
			.then((ret) => {
        this.setState({
          groups: ret.data.docs,
          page: ret.data.page,
          pages: ret.data.pages,
          total: ret.data.total 
        })
      })
  }

  loadData() {
    this.getGroupsList('Signed', 1);
  }

  open(action){
    this.setState({ show: true, action: action });
  }
  close = () => {
    this.setState({ show: false });
    this.getGroupsList( this.state.onboardingStatus, 1 );
  }

  render() {    
    
    let { page, pages, onboardingStatus, groups, reconciliationStatus, agent, total, paidOn, show } = this.state;
    let user = this.props.user
    let permissions = user.permissions? user.permissions : {}
    let navigate = null
    
    if( onboardingStatus == 'Pending' && ( ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1 || permissions.approveClient != false ) ){
      navigate =     
        <div>        
          <div id="accept" onClick={()=>this.open('Approve')}>Approve</div>
          <div id="refuse" onClick={()=>this.open('Reject')} >Reject</div>
        </div>
    }

    return (
			<div className='background'>

        <div id="pageTitle">Groups</div>
				<ManageMenu user={ user }/>
				
        <div id="clientBackground">
          
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Signed')} className={ onboardingStatus == 'Signed'?"active_clientSubMenu leftMenu":"leftMenu"}>Signed</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Approved')} className={ onboardingStatus == 'Approved'?"active_clientSubMenu":""}>Approved</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Pending')} className={ onboardingStatus == 'Pending'?"active_clientSubMenu rightMenu":"rightMenu"}>Pending</div>
            <div id="clientAction" onClick={()=>this.handleNext('group/add')}>
              <i className="fas fa-user-plus"></i>
            </div>
            { navigate }
          </div>
          
          { groups && groups.length > 0
            ? <div id="contractsList">
                <div className="groupNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getGroupsList( onboardingStatus, 1, reconciliationStatus, agent, paidOn )
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getGroupsList( onboardingStatus, Math.max( 1, page -1 ), reconciliationStatus, agent, paidOn)
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="navLegend">{ total } groups</div>
                  <div className="navPage">page {page} / {pages} </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getGroupsList( onboardingStatus, Math.min( pages, page + 1 ), reconciliationStatus, agent, paidOn)
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getGroupsList( onboardingStatus, pages, reconciliationStatus, agent, paidOn )
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>
              </div>
            : null } 

          { groups
            ? <div id="groupsList">
                <List 
                  groups={ groups } 
                  onboardingStatus={ onboardingStatus }
                  supplierId={ user.supplier }
                  user={ user }
                  refresh = {this.getGroupsList}
                />
              </div>
            : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }

            { show &&
              <ApproveGroup
                show={ show } 
                onHide={this.close}
                selectedGroups={this.props.selectedGroups}
                action={this.state.action}
                user={ user} 
              /> 
            }
          </div>
			</div>
    );
  }
}

const GroupsController = connect(
  function mapStateToProps(state) {
    return { 
      selectedGroups: state.selectedGroups
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Controller);


export default GroupsController