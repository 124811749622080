import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { Button, FormControl } from 'react-bootstrap/lib';
import './../search.css';
import { connect } from 'react-redux';
import AgentsList from './../../Agents/List/AgentsList'
import { addToAgentList, emptyList } from './../../../../Actions/index';

class SearchFonction extends Component {
  constructor(props) {
		super(props)
		this.state = {
      query:{},
      agents: undefined,
      allSelected: false,
      page: 1,
      pages: 1,
      total: 0,
    };
  }
  
  componentWillUnmount(){
    this.props.emptyList()
  }

  selectAll(){
    if(this.state.agents){
      this.setState({ allSelected: !this.state.allSelected})
      this.state.agents.forEach( agent => {
        if(this.props.selectedAgents.indexOf(agent.agentNumber)<0){
          this.props.addToAgentList(agent.agentNumber)
        }
      })
    }
  }
  deselectAll(){
    this.setState({ allSelected: false })
    this.props.emptyList()
  }

  getResults(query, fromPage){
    var body = {
      query,
      user: this.props.user._id,
      fromPage,
      pageSize: 200
    }
    axios.post(API_URL + '/agents/find/'+ this.props.supplier, body)
    .then((ret) => {
      this.setState({
        agents: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total  
      })
    })
  }

  download(query){

    var body = Object.assign(query, {'export':true})

    axios.post(API_URL + '/agents/search/' + this.props.supplier, body)
      .then(response => {
        const url = window.URL.createObjectURL( new Blob([response.data], {type: 'text/csv'}) );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'agents.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.state.query.export = false
      })  
  }

  render() {    
    let { page, pages } = this.state
    return (
      <div> 
        <div className="activeSearchSection">   
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Agent number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.agentNumber = e.target.value; this.setState({ query }) }}
          />
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Firstname"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query["profile.firstName"] = { $regex: e.target.value, $options: 'i' }; 
              this.setState({ query }) 
            }}
          />
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Lastname"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query["profile.lastName"] = { $regex: e.target.value, $options: 'i' }; 
              this.setState({ query }) 
            }}
          />        
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.role = e.target.value; this.setState({ query }) }}
            >
            <option value="">Select a role</option>
            <option value="Agent">Agent</option>
            <option value="Operator">Operator</option>
            <option value="Administrator">Warehouse</option>
            <option value="Viewer">Viewer</option>
            <option value="Disabled">Disabled</option>
          </FormControl>
          <FormControl
            type="text"
            className="searchInput"
            placeholder="email"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query.email = { $regex: e.target.value, $options: 'i' }; 
              this.setState({ query }) 
            }}
          /> 
        
          <div id="searchZone">
            <Button id="searchButton" onClick={()=>this.getResults(this.state.query)}>Search</Button>
            
            { this.props.selectable || ( this.props.options && ( this.props.options.export == false || this.props.options.locked ) )
              ? null
              : <Button id="searchButton" className="downloadButton" onClick={()=>this.download(this.state.query)}>
                  <i className="fas fa-file-download"></i>
                </Button> }

            { this.state.allSelected ?
              <div id="bulkSection" className="onlyLarge" onClick={()=>this.deselectAll()}>
                <i className="fas fa-eraser"></i>
              </div>
              :
              <div id="bulkSection" className="onlyLarge" onClick={()=>this.selectAll()}>
                <i className="fas fa-check-double"></i>
              </div> }
          </div>
        
        </div>
        <div id="searchOutcome">
          <AgentsList agents={this.state.agents} selectable={true} supplier={ this.props.supplier }/>
        </div>
      </div>
    );
  }
}


export const SearchAgents = connect(
  function mapStateToProps(state) {
    return { 
      search: state.search,
      selectedAgents: state.selectedAgents
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToAgentList: agents => dispatch(addToAgentList(agents)),
      emptyList: () => dispatch(emptyList())
    };
  },
)(SearchFonction);
