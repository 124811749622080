import React, { Component } from 'react';
import axios from 'axios';
import {Button, Modal} from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { I18n } from 'react-i18next';
import { connect } from 'react-redux';


class Approve extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status:undefined,
      rejectClients: undefined,
      note: undefined,
      disabled: false
    };
  } 
 
  componentDidUpdate(prevProps) {
    if (this.props.selectedContracts !== prevProps.selectedContracts) {
      this.setState({
        status: undefined, 
        disabled: false,
        rejectClients: undefined,
        note: undefined,
      });
    }
  }
  

  componentDidMount() {
    this.setState({
      status: undefined, 
      disabled: false,
      rejectClients: undefined,
      note: undefined,
    });  
  }

  handleChange(){
    var body = {
      contracts: this.props.selectedContracts,
      rejectClients: this.state.rejectClients,
      note: this.state.note,
      user: this.props.user
    }
    this.setState({ disabled: true, status: 'One second...' })

    axios.put(API_URL + '/contracts/'+this.props.action+'/' + this.props.user.supplier, body)
			.then((ret) => {
        this.setState({status: ret.data})
      })
  }

  over(){
    this.props.onHide();
    this.setState({
      status: undefined, 
      disabled: false,
      rejectClients: undefined,
      note: undefined,
    });
  }

  renderMessage() {
    if (this.state.status && this.state.status !== 'One second...' ) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   

  render() {
    let disabled = true
    
    if( !this.state.disabled && 
      this.state.rejectClients !== undefined || this.props.action == 'Approve'
    ) disabled = false

    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">{this.props.action} these {this.props.selectedContracts.length} contracts</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            { this.props.action == 'Reject'
              ? <div id="approveClientsModal">
                  <div id="approveContractsQuestion">
                    Reject related clients?
                  </div>
                  <div
                    id="approveContracts"
                    className={ this.state.rejectClients == true? "approveSelected":"approveUnselected" } 
                    onClick={() => this.setState({ rejectClients: true })}
                  >
                    Yes
                  </div>
                  <div 
                    id="approveContracts"
                    className={ this.state.rejectClients == false? "approveSelected":"approveUnselected" } 
                    onClick={() => this.setState({ rejectClients: false })}
                  >
                    No
                  </div>
                </div>
              : null }

            { this.props.action == 'Reject' && this.state.rejectClients == false
              ? <div id="contractRejectWarning">Clients will remain pending until a seperate credit decision is made for them</div>
              : null }
            
            { this.props.action == 'Reject'
              ? <textarea
                  type="text"
                  className="refusalNote"
                  placeholder="Add note for agents"
                  onChange={(e)=> this.setState({ note: e.target.value }) }
                />
              : null }

            {this.renderMessage()}            
            
            <br/>
            <div id="modal_action">
              { this.state.status==='Success!'
                ? null
                : <Button id="confirm_action" disabled={ disabled } onClick={()=>this.handleChange()}>{this.props.action}</Button>
              }
              <Button id="action_cancel" onClick={() => this.over()}>
                {this.state.status==='Success!'?'Close':'Abort'}
              </Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}


const ApproveContract = connect(
  function mapStateToProps(state) {
    return { 
      selectedContracts:state.selectedContracts
    };
  }
)(Approve);

export default ApproveContract;