import React, { Component } from 'react';
import './../templates.css'

export class TemplateHeader extends Component {
  
  render() {
    return (
      <div className="templatesTitle">
        <div id="templatesMedium" >
          Code
        </div>  
        <div id="templatesSmall" >
          Type
        </div>  
        <div id="templatesExtraLarge" >
          Text
        </div>
        <div id="templatesExtraSmall">
          Length
        </div>
        <div id="templatesLarge" >
          Trigger
        </div>  
        <div id="templatesExtraSmall">
          Edit
        </div>  
      </div>
    )
  }  
}