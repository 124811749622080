import React, { Component } from 'react';

import * as firebase from 'firebase';

class PictureOfContract extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
      url: undefined
    };
  }

  componentDidMount() {
    this.getURL()
  }

  getURL = async () => {
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var pictureTag = this.props.pictureTag

    try { URL = await storageRef.child(pictureTag).getDownloadURL()
    } catch(err){ console.log(err); }

    if( URL ){ this.setState({ show: true, url: URL })
    } else return;

  }

  render() {   

    return (
      <div >
        { this.state.url
        ? <img  className="contractImg"  src={ this.state.url } />      
        : <div className="contractImg" >
            Laoding pciture
          </div> } 
      </div>
    );
  }
}

export default PictureOfContract