import React from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { I18n } from 'react-i18next';
import DefineCondition from './DefineCondition'
import axios from 'axios';
import { API_URL } from '../../../../../Actions/index';
import SelectableAnswer from './SelectableAnswer';

class QuestionAction extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      question:{}, 
      disabled: false
    };
  } 

  componentDidMount() {
    if( this.props.question ){
      let question = Object.assign({}, this.props.question)
      this.setState({ question })
    } else {
      let question = {
        supplier: this.props.supplier,
        possibleAnswers: [],
        options: undefined
      }
      this.setState({ question })
    }
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  createQuestion(){
    axios.post(API_URL + '/questionnaires/addquestion/' + this.props.questionnaireId, this.state.question)
			.then((ret) => {
        this.setState({status: 'Success'})
      })
  }

  editQuestion(){
    axios.put(API_URL + '/questionnaires/editquestion/' + this.props.question._id, this.state.question)
			.then((ret) => {
        this.setState({status: 'Success'})
      })
  }

  removeQ(){
    axios.put(API_URL + '/questionnaires/removequestion/' + this.props.questionnaireId, {
      questionToRemove: this.props.question._id
    })
    .then( ret  => {
      this.setState({status: ret.data.message})
    })
    .catch( err  => {
      console.log(err)
      this.setState({status: err.message})
    })
  }

  handleSubmit(){
   ! this.props.question
   ? this.createQuestion()
   : this.editQuestion()
  }

  removeAnswer(elem, answer, translatedAnswer){
    let question = Object.assign({}, this.state.question); 
    question.possibleAnswers = this.state.question.possibleAnswers.filter(element => element !== elem);
    question.options = this.state.question.options.filter(element => element.answer !== answer && element.translatedAnswer !== translatedAnswer );
    this.setState({ question }) 
  }

  set = (condition) => {
    let question = Object.assign({}, this.state.question); 
    question.condition = condition; 
    this.setState({ question })
  }

  removeCondition = () => {
    let question = Object.assign({}, this.state.question); 
    question.conditional = false; 
    question.condition = undefined
    this.setState({ question })
  }

  addAnswer = ( answer, translation, availabilityCondition ) => {

    let question = Object.assign({}, this.state.question); 
    if( !answer ) return;
    if( question.possibleAnswers.indexOf(answer) > -1 ) return;
    if( question.options && question.options.map( elem => elem.translatedAnswer).indexOf(translation) > -1 ) return;
    question.optionCount = question.optionCount? question.optionCount + 1 : 1
    question.possibleAnswers 
      ? question.possibleAnswers.push(answer )
      : question.possibleAnswers = [answer] ;
    let option = {
      translatedAnswer: translation? translation: answer,
      answer,
      availabilityCondition
    }
    if( question.options ){
      question.options.push(option)            
    } else question.options = [option]
    
    return this.setState({ question })
  }
  
  over(){
    this.props.onHide();
    this.setState({
      status: undefined,
      newAnswer: undefined,
      question:{
        question: undefined,
        category: undefined,
        bCode: undefined,
        isNumeric: undefined,
        selectable: undefined,
      }, 
      disabled: false
    })
  }

  selecType = ( isNumeric, selectable, isGPS, isPicture, isDate, isConfirmation, selectableWithConditionalAnswers, isSignature, isScan ) => {
    let question = Object.assign({}, this.state.question); 
    question.isNumeric = isNumeric; 
    question.selectable = selectable; 
    question.isGPS = isGPS; 
    question.isPicture = isPicture; 
    question.isDate = isDate; 
    question.isConfirmation = isConfirmation; 
    question.selectableWithConditionalAnswers = selectableWithConditionalAnswers
    question.isSignature = isSignature
    question.isScan = isScan
    this.setState({ question }) 
  }

  render() {

    let { question, questions, user } = this.props
    let scanQuestion = user && user.options && user.options.scanQuestion? true : undefined

    let canBeConditional = questions && questions.filter( elem => elem.selectable ) && questions.filter( elem => elem.selectable ).length
      ? true
      : undefined

    let stateQuestion = this.state.question
    let { possibleAnswers, options, selectableWithConditionalAnswers } = stateQuestion

    let type = 'Free text'
    if( question && question.isConfirmation ) type = 'Confirmation'
    else if( question && question.isDate ) type = 'Date'
    else if( question && question.isNumeric ) type = 'Numeric'
    else if( question && question.isPicture ) type = 'Picture'
    else if( question && question.selectable ) type = 'Selectable'
    else if( question && question.isGPS ) type = 'GPS'
    else if( question && question.isSignature ) type = 'Signature'
    else if( question && question.isScan ) type = 'Scan'

    let confirmationOf, optionsConditionalOn = undefined
    if( question && question.confirmationOf ){
      confirmationOf = questions.find( elem => elem._id == question.confirmationOf ).bCode
    }
    if( question && question.optionsConditionalOn ){
      optionsConditionalOn = questions.find( elem => elem._id == question.optionsConditionalOn ).bCode
    }
    
    let mappedAnswers = possibleAnswers && possibleAnswers.length > 0 && possibleAnswers.map( (elem, index) => {
      let answer = options? options[index].answer : elem
      let translatedAnswer = options? options[index].translatedAnswer : answer
      let availabilityCondition = options? options[index].availabilityCondition : undefined
      return (
        <div key={index} id="possibleAnswer"> 

          { answer == translatedAnswer && !availabilityCondition &&
            <div className="possibleAnswerLeft fullAnswer">{ answer }</div> }

          { answer !== translatedAnswer && !availabilityCondition &&
            <div>
              <div className="possibleAnswerLeft">{ answer }</div>
              <div className="possibleAnswerLeft localAnswer">{ translatedAnswer }</div> 
            </div> }

          { answer == translatedAnswer && availabilityCondition &&
            <div>
              <div className="possibleAnswerLeft">{ answer }</div>
              <div className="possibleAnswerLeft localAnswer">{ availabilityCondition }</div> 
            </div> }

          { answer !== translatedAnswer && availabilityCondition &&
            <div>
              <div className="conditionalAnswerLeft">{ answer }</div>
              <div className="conditionalAnswerLeft localAnswer">{ translatedAnswer }</div> 
              <div className="conditionalAnswerLeft condA">{ availabilityCondition }</div> 
            </div> }

          <div id="possibleAnswerRight" onClick={()=>this.removeAnswer(elem, answer, translatedAnswer)}><i className="fas fa-times"></i></div>
        </div>
      )
    })

    let answer = options && options[0]
      ? options[0].answer 
      : possibleAnswers? possibleAnswers[0] : undefined
    
    let translatedAnswer = options && options[0]
      ? options[0].translatedAnswer 
      : answer
    
    let mappedAnswersTitle = options && options.length > 0 && 
      <div id="possibleAnswer"> 

          <div className="questionLabel">Options</div>
        { answer == translatedAnswer && !selectableWithConditionalAnswers &&
          <div className="possibleAnswerLeft fullAnswer answerFullHeader">Answer</div> }

        { answer !== translatedAnswer && !selectableWithConditionalAnswers &&
          <div>
            <div className="possibleAnswerLeft localAnswer answerHeader">Answer</div>
            <div className="possibleAnswerLeft localAnswer answerHeader">Translation</div> 
          </div> }

        { answer == translatedAnswer && selectableWithConditionalAnswers &&
          <div>
            <div className="possibleAnswerLeft localAnswer answerHeader">Answer</div>
            <div className="possibleAnswerLeft localAnswer answerHeader">Conditional on</div> 
          </div> }

        { answer !== translatedAnswer && selectableWithConditionalAnswers &&
          <div>
            <div className="conditionalAnswerLeft localAnswer answerHeader">Answer</div>
            <div className="conditionalAnswerLeft localAnswer answerHeader">Translation</div> 
            <div className="conditionalAnswerLeft condA answerHeader">Conditional on</div> 
          </div> }

      </div>

    let mappedQuestions = questions &&  questions
      .sort((a, b) => a.rank - b.rank)
      .map( question =>{ 
        return <option className="agentOption" key={question._id} value={question._id}>{question.bCode}</option>
      })
    
    let mappedSelectableQuestions = questions &&  questions
      .filter( elem => elem.selectable || elem.selectableWithConditionalAnswers )
      .sort((a, b) => a.rank - b.rank)
      .map( question =>{ 
        return <option className="agentOption" key={question._id} value={question._id}>{question.bCode}</option>
      })
    
    let isDisabled = ( 
      question || 
      ( stateQuestion.category && 
        stateQuestion.bCode && 
        stateQuestion.question &&
        ( !stateQuestion.isConfirmation|| ( stateQuestion.isConfirmation && stateQuestion.confirmationOf ) ) )
    ) ? false : true

    if( this.state.disabled ) isDisabled = true

    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="medium"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header> 
            <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">{question? 'Edit question' : 'Create new question' }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modalBody">
              <div className="addQuestion">
                <div className="questionLabel">Question</div>
                <FormControl 
                  type="text" 
                  className="questionInput"
                  placeholder="Type question"
                  value = { stateQuestion.question !== undefined
                    ? stateQuestion.question
                    : (question? question.question: '')
                  }
                  onChange={(e)=>{ let question = Object.assign({}, stateQuestion); question.question = e.target.value; this.setState({ question }) }}
                />
              </div>

              <div className="addQuestion">
                <div className="questionLabel">English translation (optional)</div>
                <FormControl 
                  type="text" 
                  className="questionInput"
                  placeholder="English translation"
                  value = { stateQuestion.translatedQuestion !== undefined
                    ? stateQuestion.translatedQuestion
                    : (question? question.translatedQuestion: '')
                  }
                  onChange={(e)=>{ let question = Object.assign({}, stateQuestion); question.translatedQuestion = e.target.value; this.setState({ question }) }}
                />
              </div>

              <div className="addQuestion">
                <div className="questionLabel">Instructions for agent (optional)</div>
                <FormControl 
                  type="text" 
                  className="questionInput"
                  placeholder="Instructions"
                  value = { stateQuestion.instructions !== undefined
                    ? stateQuestion.instructions
                    : (question? question.instructions: '')
                  }
                  onChange={(e)=>{ let question = Object.assign({}, stateQuestion); question.instructions = e.target.value; this.setState({ question }) }}
                />
              </div>

              <div className="addQuestion">
                <div className="questionLabel">Code (*)</div>
                <FormControl 
                  type="text" 
                  className="questionInput"
                  placeholder="Type code (*)"
                  value = { stateQuestion.bCode !== undefined
                    ? stateQuestion.bCode
                    : (question? question.bCode: '')
                  }
                  onChange={(e)=>{ let question = Object.assign({}, stateQuestion); question.bCode = e.target.value; this.setState({ question }) }}
                />
              </div>

              <div className="addQuestion">
                <div className="questionLabel">Category</div>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="questionInput halfQ"
                  placeholder={'Category'}
                  onChange={(e)=>{ 
                    let question = Object.assign({}, stateQuestion); 
                    question.category = e.target.value; 
                    this.setState({ question }) 
                  }}
                  >
                  <option value=""className="agentOption">{question? question.category: 'Choose category'}</option>
                  <option value="Personal" className="agentOption">Personal</option>
                  <option value="Incomes"className="agentOption">Incomes</option>
                  <option value="Surroundings"className="agentOption">Surroundings</option>
                  <option value="Legal"className="agentOption">Legal</option>
                </FormControl>
              </div>

              <div className="addQuestion">
                <div className="questionLabel">Type</div>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="questionInput halfQ"
                  placeholder={'Category'}
                  onChange={(e)=>{ 
                    if( e.target.value == "Numeric" ){
                      this.selecType( true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined )
                    } else if ( e.target.value == "Selectable" ) {
                      this.selecType( undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined )
                    } else if ( e.target.value == "Picture" ) {
                      this.selecType( undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined )
                    } else if ( e.target.value == "GPS" ) {
                      this.selecType( undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined )
                    } else if ( e.target.value == "Date" ) {
                      this.selecType( undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined )
                    } else if ( e.target.value == "Confirmation" ) {
                      this.selecType( undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined )
                    } else if ( e.target.value == "Free_text" ) {
                      this.selecType( undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined )
                    } else if ( e.target.value == "Selectable_cond" ) {
                      this.selecType( undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined )
                    } else if ( e.target.value == "Signature" ) {
                      this.selecType( undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined )
                    } else if ( e.target.value == "Scan" ) {
                      this.selecType( undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true )
                    }
                  }}
                  >
                  <option value=""className="agentOption">{question? type: 'Choose type'}</option>
                  <option value="Free_text" className="agentOption">Free text</option>
                  <option value="Numeric" className="agentOption">Numeric</option>
                  <option value="Selectable" className="agentOption">Selectable - standard</option>
                  <option value="Selectable_cond" className="agentOption">Selectable with options conditional on previous answers</option>
                  <option value="Picture" className="agentOption">Picture</option>
                  <option value="GPS" className="agentOption">GPS</option>
                  <option value="Date" className="agentOption">Date</option>
                  <option value="Confirmation" className="agentOption">Confirmation</option>
                  <option value="Signature" className="agentOption">Signature</option>
                  { scanQuestion && <option value="Scan" className="agentOption">Scan</option> }
                </FormControl>
              </div>

              { ( stateQuestion.selectableWithConditionalAnswers || ( question && question.selectableWithConditionalAnswers ) ) &&
                <div className="addQuestion">
                  <div className="questionLabel">Options to depend on answer to</div>
                  <FormControl 
                    controlId="formControlsSelect"
                    componentClass="select" 
                    className="questionInput"
                    onChange={(e)=>{ 
                      let question = Object.assign({}, this.state.question); 
                      question.optionsConditionalOn = e.target.value; 
                      this.setState({ question }) 
                    }}
                  > 
                    <option value="" className="agentOption">{ !optionsConditionalOn ? 'Select one' : optionsConditionalOn }</option>
                    { mappedSelectableQuestions }
                  </FormControl> 
                </div> }              

              { mappedAnswersTitle } 
              <div id="questionSection">{ mappedAnswers }</div>

              { ( stateQuestion.selectable || 
                  ( stateQuestion.selectableWithConditionalAnswers && 
                    stateQuestion.optionsConditionalOn ) 
                  ) &&
                <SelectableAnswer 
                  addAnswer={ this.addAnswer } 
                  questions={ questions }
                  selectableWithConditionalAnswers={ stateQuestion.selectableWithConditionalAnswers }
                  count={ stateQuestion.optionCount }
                  optionsConditionalOn={ stateQuestion.optionsConditionalOn }
                /> }

              <div id="questionYesWrapper">
                <div id="questionYesLabel">This question is optional</div>
                <div 
                  id="questionYes" 
                  className={ stateQuestion.optional !== undefined
                    ? ( stateQuestion.optional ? 'questionTrue':'' )
                    : ( question && question.optional? 'questionTrue':'')
                  }  
                  onClick={()=>{ 
                    let question = Object.assign({}, stateQuestion); 
                    question.optional = !stateQuestion.optional; 
                    this.setState({ question }) 
                  }}
                >
                  { stateQuestion.optional !== undefined
                    ? ( stateQuestion.optional ? 'Yes':'No' )
                    : ( question && question.optional? 'Yes':'No')
                  }                
                </div>
              </div>

              { stateQuestion.optional &&
                <div id="questionWarning">Optionality warning: agents will be able to submit surveys even if this question is not answered</div>
              }

              <div id="questionYesWrapper">
                <div id="questionYesLabel">This question is pivot</div>
                <div 
                  id="questionYes" 
                  className={ stateQuestion.isPivot !== undefined
                    ? ( stateQuestion.isPivot ? 'questionTrue':'' )
                    : ( question && question.isPivot? 'questionTrue':'')
                  }  
                  onClick={()=>{ 
                    let question = Object.assign({}, stateQuestion); 
                    question.isPivot = !stateQuestion.isPivot; 
                    this.setState({ question }) 
                  }}
                >
                  { stateQuestion.isPivot !== undefined
                    ? ( stateQuestion.isPivot ? 'Yes':'No' )
                    : ( question && question.isPivot? 'Yes':'No')
                  }                
                </div>
              </div>

              { stateQuestion.isPivot &&
                <div id="questionWarning">Pivot warning: agents will not be able to proceed to the next question until this one is answered</div>
              }

              { canBeConditional &&
                <div id="questionYesWrapper">
                  <div id="questionYesLabel">This question is conditional</div>
                  <div 
                    id="questionYes" 
                    className={ stateQuestion.conditional !== undefined
                      ? ( stateQuestion.conditional ? 'questionTrue':'' )
                      : ( question && question.conditional? 'questionTrue':'')
                    }  
                    onClick={()=>{ 
                      let question = Object.assign({}, stateQuestion); 
                      question.conditional = !stateQuestion.conditional; 
                      if( !stateQuestion.conditional ) question.condition = undefined
                      this.setState({ question }) 
                    }}
                  >
                    { stateQuestion.conditional !== undefined
                      ? ( stateQuestion.conditional ? 'Yes':'No' )
                      : ( question && question.conditional? 'Yes':'No')
                    }                
                  </div>
                </div> }

                { (stateQuestion.isConfirmation || ( question && question.isConfirmation )) &&
                  <div>
                    <div className="questionLabel">Must be same answer than to question</div>
                    <FormControl 
                      controlId="formControlsSelect"
                      componentClass="select" 
                      className="questionInput"
                      onChange={(e)=>{ 
                        let question = Object.assign({}, this.state.question); 
                        question.confirmationOf = e.target.value; 
                        this.setState({ question }) 
                      }}
                    > 
                      <option value="" className="agentOption">{ !confirmationOf ? 'Select one' : confirmationOf }</option>
                      { mappedQuestions }
                    </FormControl> 
                  </div> }

              { canBeConditional && stateQuestion.conditional &&
                <DefineCondition 
                  questions={ questions } 
                  condition={ stateQuestion.condition }
                  set={ this.set }
                />}

              { canBeConditional && stateQuestion.conditional &&
                <div> 
                  <div id="questionRemoveLabel">Remove condition</div>
                  <div id="questionDelete" onClick={()=>this.removeCondition()}><i className="fas fa-trash"></i></div>
                </div> }

              <div id="questionHelper">
                (*) This code will be a column title in the xls exports of your credit applications. <br/>
                Use "District", "Village", "County", "Region", "SecondaryMobile", "NextOfKin", "Neighbour" or "Gender" to enrich your client data accordingly.
              </div>

              { question &&
                <div> 
                  <div id="questionRemoveLabel">Remove question</div>
                  <div id="questionDelete" onClick={()=>this.removeQ()}><i className="fas fa-trash"></i></div>
                </div> }

              { this.renderMessage() }
              
              <div id="modal_action">
                { this.state.status !== 'Success' &&
                  <Button id="action_signup" disabled={isDisabled} onClick={this.handleSubmit.bind(this)}>{question?'Edit':'Create'}</Button>
                }
                <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Back':'Close'}</Button>
              
              </div>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default QuestionAction;