import React, { Component } from 'react';
import './clientEvents.css'
import ConversationPopUp from './../../Notes/Conversations/ConversationPopUp'

export class Item extends Component {

  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }

  close() {
    this.setState({ show: false });
    this.props.refresh()
  }

  handleClick = () => {
    if( this.props.event.type == 'Task' ) return this.setState({ show: !this.state.show })
    else return;
  }

  render() {
    
    let event = this.props.event

    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let date = '-'
    if( event.date ){
      date = new Date(event.date)
      date = date.toLocaleDateString("en-EN", options)
    }

    let title = event.title
    if( event.title == 'Contract approval notification' ) title = 'CONTRACT APPROVED'
    if( event.title == 'Client text' ) title = 'MODIFIED BY AGENT'

    let text = event.text? event.text : ''

    if( event.data ){
      if( event.data.firstName ){ 
        let oldFirstName = event.data.oldFirstName
          ? event.data.oldFirstName
          : event.data.oldProfile? event.data.oldProfile.firstName : '-'
        text += 'Firstname changed from ' + oldFirstName + ' to ' + event.data.firstName + '. '
      }
      
      if( event.data.lastName ){ 
        let oldLastName = event.data.oldLastName
          ? event.data.oldFirstName
          : event.data.oldProfile? event.data.oldProfile.lastName : '-'
        text += 'Lastname changed from ' + oldLastName + ' to ' + event.data.lastName + '. '
      }
      
      if( event.data.mobile ){ 
        let oldMobile = event.data.oldMobile
          ? event.data.oldMobile
          : event.data.oldContact? event.data.oldContact.mobile : '-'
        text += 'Mobile phone changed from ' + oldMobile + ' to ' + event.data.mobile + '. '
      }

      if( event.data.neighbour ){ 
        let oldMobile = event.data.oldContact? event.data.oldContact.neighbour : '-'
        text += 'Neighbour number changed from ' + oldMobile + ' to ' + event.data.neighbour + '. '
      }
      
      if( event.data.nextOfKin ){ 
        let oldMobile = event.data.nextOfKin? event.data.oldContact.nextOfKin : '-'
        text += 'NextOfKin number changed from ' + oldMobile + ' to ' + event.data.nextOfKin + '. '
      }

      if( event.data.secondaryMobile ){ 
        let oldMobile = event.data.oldContact? event.data.oldContact.secondaryMobile : '-'
        text += 'SecondaryMobile changed from ' + oldMobile + ' to ' + event.data.secondaryMobile + '. '
      }

      if( event.data.gps ){ 
        text += 'Location textd to: latitude ' + event.data.gps.latitude + '; longitude ' + event.data.gps.longitude 
      }
      
    }

    let lead = undefined
    if( event.product ){
      lead = true
      text = 'Expressed interest for ' + event.product.name
    }

    return (
      <div 
        className={ lead
          ? "clientEvent leadEvent" 
          : event.type !=='Task' ? "clientEvent" : "clientEvent activeEvent" 
        }
        onClick={ () => this.handleClick() }
      >
        <div id="clientEventsDate">{ date }</div>
        <div id="clientEventsTitle">{ title? title.toLocaleUpperCase() : '-'}</div>
        <div id="clientEventsText">{ text }</div>

        { this.state.show &&
          <ConversationPopUp 
            show={ this.state.show } 
            onHide={ this.close.bind(this) }
            note={ event }
            user={ this.props.user }
            supplierId={ this.props.user.supplier }
          /> }

      </div>
    )
  }  
}
