import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { SelectAgent } from '../../Agents/Action/SelectAgent'

class AddNote extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      note: {
        contract: this.props.contract ? this.props.contract._id : undefined,
        author: this.props.user._id,
        writtenBy: this.props.user.profile.firstName.substring(0, 1) + ' ' + this.props.user.profile.lastName,
        title: this.props.contract? this.props.contract.contractNumber : undefined,
        status: 'Sent',
      },
    };
  } 
  componentDidMount() {
    this.setState({status: undefined})
  } 

  send(){

    let note = this.state.note
    if( ['Task', 'Ticket'].indexOf( this.state.note.type ) > -1 ) {
      let number = Date.now().toString()
      note = Object.assign( this.state.note, { title: 't-' + number.slice(5, 11) } )
    }
    var body = {
      note: note
    }
    if( this.props.edit ){

      axios.put(API_URL + '/notes/edit/' + this.props.contract.supplier + '/' + this.props.note._id, body)
      .then((ret) => { this.setState({status: 'Success!' }) })

    } else axios
        .put(API_URL + '/notes/sendnote/' + this.props.contract.supplier, this.state.note)
        .then((ret) => { this.setState({status: 'Success!' }) })

  }

  selectAgent = (agent) => {
    let note = Object.assign({}, this.state.note); 
    note.agentTo = agent._id
    this.setState({ note }) 
  }

  
  over(){
    this.setState({
      note: {
        text: undefined,
        title: undefined,
        status: 'Sent',
        type: undefined,
        subType: undefined,
        priority: undefined,
      },
      status: undefined,
    })
    this.props.onHide()
    this.props.refresh()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   

  render() {
    let { contract, note, user } = this.props

    let agent = contract.agent
    let agentName = agent.profile? agent.profile.firstName + ' ' + agent.profile.lastName : '-'
    let options = user.options
    
    console.log(options.customerServiceAgents)

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">

          { !this.state.note.type && !note
            ? <div>Create task or internal note</div>
            : null }
          { this.state.note.type == 'Internal' || (note && note.type == 'Internal')
            ? <div>Add an internal note</div>
            : null }
          { this.state.note.type == 'Ticket' || (note && note.type == 'Ticket')
            ? <div>Create a ticket</div>
            : null }
          { this.state.note.type == 'Task' || (note && note.type == 'Task')
            ? <div>Assign task to agent</div>
            : null }
          { this.state.note.type == 'Info' || (note && note.type == 'Info')
            ? <div>Send agent a message</div>
            : null }   
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div id="modal_highlight">

          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="agentEditInput"
            onChange={(e)=>{ 
              let note = Object.assign({}, this.state.note); 
              note.type = e.target.value;
              if( note.type !== 'Internal'){
                note.agentTo = agent._id
              } else note.agentTo = undefined
              this.setState({ note }) 
            }}
          > 
            <option value={ note? note.type : 'Select type' }>
              { note? note.type : 'Select type' }
            </option>
            <option value="Task">Task</option>
            <option value="Info">Info</option>
            <option value="Internal">Internal</option>
          </FormControl>

          { options.customerServiceAgents || !this.state.note.type || this.state.note.type === 'Internal' || (note && note.type === 'Internal') 
            ? null
            : <div className="agentEditInput">
                To {agentName}
              </div> }

          { options.customerServiceAgents && this.state.note.type == 'Task' && 
            <SelectAgent 
              selectAgent={ this.selectAgent } 
              type={ "{$in: ['CustomerService'] }" } 
              _id={ agent._id }
              format={ "agentEditInput" }
              supplierId={ user.supplier }
              userId={ user._id }
            /> }

          { this.state.note.type == 'Task' || (note && note.type == 'Task') 
            ? <div>

                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="agentEditInput"
                  onChange={(e)=>{ 
                    let note = Object.assign({}, this.state.note); 
                    note.priority = e.target.value;
                    this.setState({ note }) 
                  }}
                > 
                  <option value={ note? note.priority : 'Select priority' }>
                    { note? note.priority : 'Select priority' }
                  </option>
                  <option value={1}>1 (High)</option>
                  <option value={2}>2 (Medium)</option>
                  <option value={3}>3 (Low)</option>
                </FormControl>

              </div>
            : null }

          <textarea 
            type="text" 
            className="agentMessageInput"
            value ={ this.state.note.text !== undefined
              ? this.state.note.text 
              : ( note ? note.text : '' )
            }
            placeholder={"Type message"}
            onChange={(e)=>{ let note = Object.assign({}, this.state.note); note.text = e.target.value; this.setState({ note }) }}
          />

        </div>
        { this.renderMessage() }            
        <br/>
        <div id="modal_action">
          { this.state.status==='Success!'
            ? null
            : <Button id="action_signup" onClick={this.send.bind(this)}>Confirm</Button> }
          <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default AddNote;