import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import List from './List/AuxiliariesList'

class AuxiliariesController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      auxiliaries: undefined,
    };
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0);
  }

   
	getList = () => {
     axios
      .post(API_URL + '/auxiliaries/search/' + this.props.user.supplier, {})
      .then((ret) => this.setState({ auxiliaries: ret.data }))
  }

  loadData() {
    this.getList();
  }


  render() {    
    let auxiliaries = this.state.auxiliaries

    return (
      <div >  
        
        { auxiliaries != undefined
          ? <div id="auxiliariesList">
              <List auxiliaries={auxiliaries} user={this.props.user } refresh={this.getList} />
            </div>
          : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }

      </div>
    );
  }
}

export default AuxiliariesController