import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import TasksList from './TasksList/TasksList'
import ManageMenu from '../ManageMenu'
import GroupNotesAction from './GroupNotes/GroupNotesAction'
import { FormControl } from 'react-bootstrap/lib';

class TasksController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      notes: undefined,      
      noteView: "Inbox",
      filter: undefined,
      action: undefined,
      page: 1,
      pages: 1,
      total: 0,
      filterNext: 0,
      filterLast: 0,
    };
  }

  componentDidMount() {
    this.loadData()
  }

	getNotes = ( status, userId, fromPage, filter ) => {
    let query = {
      inbox: status == 'Inbox'? true : undefined,
      author: userId,
      fromPage,
      userId,
      filter,
      pageSize: 75
    }
    this.setState({ noteView: status});
    axios.post(API_URL + '/notes/getnotes/' + this.props.user.supplier, query)
			.then( ret => this.setState({ 
        notes: ret.data.docs,
        page: ret.data.page? ret.data.page: 1,
        pages: ret.data.pages? ret.data.pages: 1,
        total: ret.data.total 
      }))
  }


  choose(status){
    this.getNotes( status, this.props.user._id, 1 )
  }
  
  loadData() {
    this.getNotes( 'Inbox', this.props.user._id, 1 );
  }
  
  render() { 
    let page = this.state.page;
    let pages = this.state.pages;
    let userId = this.props.user._id
    return (
      <div className='background'>   
        <div id="pageTitle">Internal messages</div>
        <ManageMenu user={this.props.user}/>
        <div id="clientBackground">
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.choose('Inbox')} className={this.state.noteView==='Inbox'?"active_clientSubMenu":""}>
              Inbox
            </div>
            <div id="clientSubMenu_item" onClick={()=>this.choose('Sent')} className={this.state.noteView==='Sent'?"active_clientSubMenu":""}>
              Outbox
            </div>
            <div id="clientSubMenu_item" onClick={()=>this.setState({ noteView: 'Group'})} className={this.state.noteView==='Group'?"active_clientSubMenu":""}>
              Group
            </div>

            { this.state.noteView !== 'Group' &&
              <div id="assetSelectorWrapper" className='onlyLarge'>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="assetSelector"
                  onChange={ (e) => { 
                    this.setState({ filter: e.target.value }) 
                    this.getNotes( this.state.noteView, this.props.user._id, 1, e.target.value )
                  }}
                >
                  <option value='' className={'agentOption'}>{ this.state.filter? 'Clear filter':'Filter' }</option>
                  <option value="Task" className={'agentOption'}>Task</option>
                  <option value="Info" className={'agentOption'}>Info</option>
                  <option value="Ticket" className={'agentOption'}>Ticket</option>
                </FormControl>
              </div> }

          </div>
          
          { this.state.notes && this.state.notes.length > 0 && this.state.noteView != 'Group'
            ? <div id="assetsList" className='noMargin'>
                <div className="contractNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getNotes( this.state.noteView, userId, 1)
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getNotes( this.state.noteView, userId, Math.max( 1, page -1 ))
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="navLegend">{ this.state.total } notes</div>
                  <div className="navPage">page { page } / { pages } </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getNotes( this.state.noteView, userId, Math.min( pages, page + 1 ))
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getNotes( this.state.noteView, userId, pages)
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>
              </div>
            : null } 

          { ! this.state.notes
            ? <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div>
            : null }

          { this.state.notes && this.state.noteView === 'Inbox'
            ? <TasksList 
                notes={this.state.notes} 
                noteView={this.state.noteView} 
                all={true}
                filter={ this.state.filter }
                refresh={ this.getNotes }
                user={ this.props.user }
                supplierId={ this.props.user.supplier }
              />
            : null }

          { this.state.notes && this.state.noteView === 'Sent' ? 
            <TasksList 
              notes = {this.state.notes} 
              noteView = {this.state.noteView} 
              all={ true }
              filter={ this.state.filter }
              user={ this.props.user }
              refresh={ this.getNotes }
              supplierId={ this.props.user.supplier }
            />
            : null }

          { this.state.noteView === 'Group' ? 
            <GroupNotesAction 
              notes={this.state.notes} 
              user={ this.props.user }
              supplier={this.props.user.supplier}
              all={true}
              refresh={this.getNotes}
            />
            : null }

        </div>
      </div>
    );
  }
}

export default TasksController