import React, { Component } from 'react';
import history from '../../history';
import './home.css';
import { connect } from 'react-redux';
import { setActiveView } from '../../Actions/index';
import { hasPermission } from '../../services/UserService'

class Admin extends Component {

  handleNext(path){
    this.props.setActiveView( path );
    history.push( '/' + path )
  }
  
  render() {
    let user = this.props.user
    let options = user.options
    let permission = hasPermission( user, undefined )
    let exclusive = hasPermission( user, undefined, undefined, true )

    return ( 
      <div className="homeMenu">
        <div id="home_menu">  

          <div onClick={()=>this.handleNext('products')} className={ this.props.activeView==='products'?"home_menu_item active_home_menu topMenu":"home_menu_item topMenu" }>
            <i className="fas fa-solar-panel"></i>
          </div>
          <div onClick={()=>this.handleNext('questionnaires')} className={ this.props.activeView==='questionnaires'?"home_menu_item active_home_menu":"home_menu_item" }>
            <i className="fas fa-tablet-alt"></i>
          </div>
          <div onClick={()=>this.handleNext('deals')} className={ this.props.activeView==='deals'?"home_menu_item active_home_menu":"home_menu_item" }>
            <i className="fas fa-sticky-note"></i>
          </div>

          { options.floatingDeals &&
            <div onClick={()=>this.handleNext('rates')} className={this.props.activeView==='rates'?"home_menu_item active_home_menu":"home_menu_item"}>
              <i className="fas fa-pound-sign"></i>
            </div> }

          <div onClick={()=>this.handleNext('users')} className={this.props.activeView==='users'?"home_menu_item active_home_menu bottomMenu":"home_menu_item bottomMenu"}>
            <i className="fas fa-user-cog"></i>
          </div> 

          { permission && options.organisationalEntities &&
            <div onClick={()=>this.handleNext('org-structure')} className={this.props.activeView==='org-structure'?"home_menu_item active_home_menu spacedMenu topMenu bottomMenu":"home_menu_item spacedMenu topMenu bottomMenu"}>
              <i className="fas fa-sitemap"></i>
            </div> }

          { exclusive &&
            <div onClick={()=>this.handleNext('invoices')} className={this.props.activeView==='invoices'?"home_menu_item active_home_menu spacedMenu topMenu bottomMenu":"home_menu_item spacedMenu topMenu bottomMenu"}>
              <i className="fas fa-file-invoice-dollar"></i>
            </div> }

        </div>
      </div>
    );
  }
}


const ManageMenu = connect(
  function mapStateToProps(state) {
    return { 
      activeView: state.activeView
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      setActiveView: view => dispatch(setActiveView(view))
    };
  },
)(Admin);

export default ManageMenu
