import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { I18n } from 'react-i18next';
import { findAgents } from  './../../../../services/AgentService'

class TransferClients extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      agent: {},
    };
  } 

  componentDidMount() {
    this.getAgents()
  }

  handleChange(){
    axios.put(API_URL + '/agents/edit/'+ this.props.agent.supplier + '/' +this.props.agent.agentNumber, this.state.agent)
			.then((ret) => {
        this.setState({
          status: 'Success'
        })
      })      
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  
  
  over(){
    this.props.onHide()
    this.setState({
      agent: {
        newAgentId: undefined,
      },
      status: undefined,
    })
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  render() {
    let disabled = false;
    let mappedAgents = null;

    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })
    }

    return (

      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Transfer all clients to</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">
              <div id="full">
                <div id="transferclient">agent</div>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="agentInput"
                  onChange={(e)=>{ 
                    let agent = Object.assign({}, this.state.agent); 
                    agent.newAgentId = e.target.value; 
                    this.setState({ agent });
                    }}
                  >
                  <option value="">Choose agent</option>
                  {mappedAgents}
                </FormControl> 
              </div>
              <div id="warningTransferClients">Warning: this action will transfer ALL clients to the new agent</div>
            </div>
            { this.renderMessage() }            
            <div id="modal_action">
            { this.state.status==='Success'
              ? null
              : <Button id="action_signup" disabled={disabled} onClick={()=>this.handleChange()}>Transfer</Button>
              }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default TransferClients;