import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { Button, FormControl } from 'react-bootstrap/lib';
import './../search.css';
import { connect } from 'react-redux';
import ClientsList from './../../Clients/List/ClientsList'
import { addToClientList, emptyList } from './../../../../Actions/index';
import { findAgents } from  './../../../../services/AgentService'

var DatePicker = require("react-bootstrap-date-picker");
var startOfDay = require("date-fns/start_of_day")
var endOfDay = require("date-fns/end_of_day")

class SearchFonction extends Component {
  constructor(props) {
		super(props)
		this.state = {
      query:{},
      answers: [],
      allSelected: false,
      processing: false,
      page: 1,
      pages: 1,
      total: 0,
    };
  }
  componentDidMount() {
    this.loadData()
  }
  
  componentWillUnmount(){
    this.props.emptyList()
  }

  loadData() {
    this.getAgents();
    this.getPreAnswers()
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }
  
  getPreAnswers() {
    axios.get(API_URL + '/questionnaires/pre-answers/' + this.props.user.supplier)
			.then((ret) => this.setState({ answers: ret.data }) )
  } 

  getResults(fromPage){
    var body = {
      query: this.state.query,
      user: this.props.user._id,
      fromPage,
      pageSize: 200
    }
    axios
      .post(API_URL + '/clients/get/' + this.props.user.supplier, body)
      .then((ret) => this.setState({ 
        clients: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total  
      }) )
  }

  download(){
    var body ={ 
      query: this.state.query,
      user: this.props.user._id,
      export: true
    }
    this.setState({ processing: true })
    axios.put(API_URL + '/clients/search/'+this.props.user.supplier, body)
      .then(response => {
        const url = window.URL.createObjectURL( new Blob([response.data], {type: 'text/csv'}) );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.state.query.export = false
      })  
  }

  selectAll(){
    if(this.state.clients){
      this.setState({ allSelected: !this.state.allSelected})
      this.state.clients.forEach( client => {
        if(this.props.selectedClients.indexOf(client.clientNumber)<0){
          this.props.addToClientList(client.clientNumber)
        }
      })
    }
  }

  deselectAll(){
    this.setState({ allSelected: false })
    this.props.emptyList()
  }

  render() {    
    let { page, pages, agents, answers, processing, query, entryDateMax, entryDateMin } = this.state

    let mappedAgents = agents && agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map((agent) =>{ 
        return <option key={agent._id} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
      })

    let regions, counties, districts = undefined
    let mappedCounties, mappedRegions, mappedDistricts = undefined
    let codes = answers.length
      ? answers.map( elem => elem.bCode )
      : []

    let county = codes.indexOf('County')
    if( county > -1 ){
      counties = answers[county].possibleAnswers
      mappedCounties = counties.sort((a, b) => a.localeCompare(b))
      .map( (region, index) => { 
        return (
          <option key={index} value={region}>{ region }</option>
        )
      })
    }

    let region = codes.indexOf('Region')
    if( region > -1 ){
      regions = answers[region].possibleAnswers
      mappedRegions = regions.sort((a, b) => a.localeCompare(b))
      .map( (region, index) => { 
        return (
          <option key={index} value={region}>{ region }</option>
        )
      })
    }

    let district = codes.indexOf('District')
    if( district > -1 ){
      districts = answers[district].possibleAnswers
      mappedDistricts = districts.sort((a, b) => a.localeCompare(b))
      .map( (district, index) => { 
        return (
          <option key={index} value={district}>{ district }</option>
        )
      })
    }

    return (
      <div> 
        
        <div className="activeSearchSection">
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Client number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.clientNumber = e.target.value; this.setState({ query }) }}
          />
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Firstname"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query["profile.firstName"] = { $regex: e.target.value, $options: 'i' }; 
              this.setState({ query }) 
            }}
          />
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Lastname"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query["profile.lastName"] = { $regex: e.target.value, $options: 'i' }; 
              this.setState({ query }) 
            }}
          />     
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query.status = e.target.value; 
              this.setState({ query }) 
            }}
            >
            <option value="">Status</option>
            <option value="Signed">Signed</option>
            <option value="Approved">Approved</option>
            <option value="Pending">Pending</option>
            <option value="Rejected">Rejected</option>
          </FormControl>
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Entered after"
              value={entryDateMin}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.entryDate = { $gte: startOfDay(new Date(e)) }; 
                if( entryDateMax ) query.entryDate = { $gte: startOfDay(new Date(e)), $lte: endOfDay(new Date(entryDateMax)) }
                this.setState({ query, entryDateMin: e }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>   
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Entered before"
              value={entryDateMax}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.entryDate = { $lte: endOfDay(new Date(e)) }; 
                if( entryDateMin ) query.entryDate = { $lte: endOfDay(new Date(e)), $gte: startOfDay(new Date(entryDateMin)) }
                this.setState({ query, entryDateMax: e }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>                
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Mobile number"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query["contact.mobile"] = e.target.value; 
              this.setState({ query }) 
            }}
          />
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query.agent = e.target.value; 
              this.setState({ query }) 
            }}
            >
            <option value={undefined}>Responsible agent</option>
            { mappedAgents }
          </FormControl>
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Village / town"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query["profile.village"] = e.target.value; 
              this.setState({ query }) 
            }}
          />
          { mappedCounties &&
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select"   
              className="searchInput"
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query["profile.county"] = e.target.value; 
                this.setState({ query }) 
              }}
              >
              <option value="">County</option>
              { mappedCounties }
            </FormControl>
          }
          { mappedDistricts &&
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select"   
              className="searchInput"
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query["profile.district"] = e.target.value; 
                this.setState({ query }) 
              }}
              >
              <option value="">District</option>
              { mappedDistricts }
            </FormControl>
          }

          { mappedRegions &&
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select"   
              className="searchInput"
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query["profile.region"] = e.target.value; 
                this.setState({ query }) 
              }}
              >
              <option value="">Region</option>
              { mappedRegions }
            </FormControl>
          }

          <div id="searchZone">
            <Button id="searchButton" onClick={()=>this.getResults(this.state.page)}>Search</Button>
            { this.props.options && ( this.props.options.export == false || this.props.options.locked )
              ? null
              : <Button id="searchButton" className="downloadButton" onClick={()=>this.download(query)} disabled={processing }>
                  { processing
                    ? <i className="fas fa-clock"></i> 
                    : <i className="fas fa-file-download"></i> }
                </Button> }

            { this.state.allSelected ?
              <div id="bulkSectionClient" className="onlyLarge" onClick={()=>this.deselectAll()}>
                <i className="fas fa-eraser"></i>
              </div>
              :
              <div id="bulkSectionClient" className="onlyLarge"onClick={()=>this.selectAll()}>
                <i className="fas fa-check-double"></i>
              </div>
            }
          </div>
        
        </div>

        { this.state.clients && this.state.clients.length > 0 &&
          <div id="resultHeader" >
            <div className="clientNavigator">
              <div className="navButton" onClick={() => {
                this.setState({ page: 1 })
                this.getResults(1)
              }}>
                <i className="fas fa-angle-double-left"></i>
              </div>
              <div className="navButton" onClick={() => {
                this.setState({ page: Math.max( 1, page -1 ) })
                this.getResults( Math.max( 1, page -1 ))
              }}>
                <i className="fas fa-angle-left"></i>
              </div>
              <div className="navLegend">{ this.state.total } clients</div>
              <div className="navPage">page {page} / {pages} </div>
              <div className="navButton" onClick={() => {
                this.setState({ page: Math.min( pages, page + 1 )})
                this.getResults(Math.min( pages, page + 1 ))
              }}>
                <i className="fas fa-angle-right"></i>
              </div>
              <div className="navButton" onClick={() => { 
                this.setState({ page: pages })
                this.getResults(pages)
              }}>
                <i className="fas fa-angle-double-right"></i>
              </div>
            </div>
          </div>} 

        <div id="searchOutcome">
          { this.state.clients
            ? <ClientsList clients={this.state.clients } selectable={true}/>
            : null }
        </div>


      </div>
    );
  }
}


export const SearchClients = connect(
  function mapStateToProps(state) {
    return { 
      search: state.search,
      selectedClients: state.selectedClients
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToClientList: client => dispatch(addToClientList(client)),
      emptyList: () => dispatch(emptyList())    
    };
  },
)(SearchFonction);
