import React, { Component } from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import './../questionnaires.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class Edit extends Component {
  constructor(props) {
		super(props)
		this.state = { };
  }

  submit(){
    let status = this.props.questionnaire.status
    axios.put(API_URL + '/questionnaires/edit/' + this.props.user.supplier, {
      questionnaire: this.props.questionnaire._id,
      terminatedBy: status == 'Active' ? this.props.user._id : undefined,
      pausedOn: status == 'Active' ? new Date() : undefined,
      status: status == 'Active' ? 'Paused' : 'Active'
    })
			.then((ret) => {
        this.setState({
          status: 'Success'
        })
      })
  }
  over(){
    this.setState({
      status: undefined,
    })
    this.props.refresh()
    this.props.onHide()
  }
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  render() {   
    let disabled = true;
    let questionnaire = this.props.questionnaire
    let status = questionnaire.status
    let action = 'Pause'
    if( status == 'Paused' )  action = 'Re-activate'
    if( !this.state.disabled && ( this.state.delete == 'Pause me' || action == 'Re-activate' ) ){
      disabled = false
    }
    
    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-sm">{ action } {questionnaire.name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modalBody">
              
            { !questionnaire.survey && action == 'Pause' &&
              <div>
                <div id="reversalDetails">
                  Warning. If "{questionnaire.name}" is your primary questionnaire (first in your list of questionnaires), your deals will now default to your secondary questionnaire (second in your list).
                </div>

                <FormControl 
                  type="text" 
                  className="contractEditInput"
                  placeholder="Pause me"
                  onChange={(e)=>{ this.setState({ delete: e.target.value }) }}
                />
            </div> }
          
          </div>
          
          { this.renderMessage() }    

          <div id="modal_action">
            { this.state.status !== 'Success' &&
              <Button id="action_signup" disabled={disabled} onClick={()=>this.submit()}>{ action } </Button> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status=='Success'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Edit