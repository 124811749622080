import React, { Component } from 'react';
import ImpactOverview from './ImpactOverview'
import ImpactController from './ImpactController'
import './impact.css'
import AnalyticsMenu from './../AnalyticsMenu';


class ImpactDashboard extends Component {
  constructor(props) {
		super(props)
		this.state = {
      nav:1
    };
  }
  
  render() {    
    let navigate = null;
    if(this.state.nav===1){navigate = 
      <ImpactOverview 
        supplier={this.props.user.supplier} 
      />
    }
    if(this.state.nav===2){navigate = 
      <ImpactController 
        supplier={this.props.supplier} 
      />
    }
    return (
      <div className='background'>   
        <div id="pageTitle">Impact analytics</div>
        <AnalyticsMenu user={this.props.user}/>
        <div id="clientBackground">
          <div id="dash_menu">  
            <div id="dash_menu_item" onClick={()=>this.setState({nav: 1})} className={this.state.nav===1?"active_dash_menu":""}>Overview</div>

          </div>
          { navigate }
        </div>
      </div>
    );
  }
}

export default ImpactDashboard