import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap/lib';
import './dealAction.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import EnableAgents from './EnableAgents'

class EditAccessibility extends Component {

  constructor(props) {
    super(props);
    this.state= {
      deal: {},       
      status: undefined,
      enableAgents: false,
      agents: [],
      ready: false
    }
  }; 

  componentDidMount() {
    let deal = Object.assign( this.state.deal, this.props.deal )
    deal.dealVersion = this.props.deal.dealVersion? this.props.deal.dealVersion + 1 : 2
    this.setState({
      status: undefined,
      agents: this.props.agents,
      deal
    })
  }
  
  closeEnableAgents() {
    this.setState({ enableAgents: false });
  }

  selectAgents = (restrictedTo, agents) => {
    let deal = Object.assign({}, this.state.deal); 
    deal.restrictedTo = restrictedTo; 
    this.setState({ deal, agents });    
  }

  edit(){
    axios
      .put(API_URL + '/deals/amend/'+ this.props.deal.supplier + '/' + this.props.deal._id, {
        editedDeal: this.state.deal,
        newVersion: this.state.newVersion
      })
      .then( ret => this.setState({ status: ret.data.message }) )
      .catch( err => this.setState({ status: 'Error' }) )
  }

  over(){
    this.setState({ status: undefined })
    this.props.onHide()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'} className='long'>
          { this.state.status }
        </div>
      );
    }
  }   

  render() {    

    let disabled = (
      this.state.deal.commission < 0 ||
      this.state.deal.commission == '' ||
      this.state.deal.pctCommission < 0 ||
      this.state.deal.pctCommission == '' ||
      this.state.deal.pctCommission >=1
    )? true: false
    
    let mappedAgents = this.state.agents && this.state.agents
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return ( 
          <div 
            key={ agent._id } 
            className="allowedPopUp"
          >
            <div id="allowedNumberPopUp">{ agent.agentNumber }</div>
            <div id="allowedNamePopUp">{ agent.profile.firstName } { agent.profile.lastName }</div>
          </div> 
        )
      })

      return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Edit deal accessibility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
           
            <div id="dealEditSubTitle">Restrict deal</div>            
            <div 
              id="restrictDeal" 
              className={this.state.deal.restricted? 'restricted': ''}
              onClick={() => {
                let deal = Object.assign({}, this.state.deal); 
                deal.restricted = !this.state.deal.restricted; 
                this.setState({ deal }) 
            }}>
              Yes
            </div>  
            <div 
              id="restrictDeal" 
              className={!this.state.deal.restricted? 'restricted': ''}
              onClick={() => {
                let deal = Object.assign({}, this.state.deal); 
                deal.restricted = !this.state.deal.restricted; 
                this.setState({ deal }) 
            }}>
              No
            </div>  

            <div id="dealEditSubTitle" style={{ marginRight: '50%'}}>Permitted agents</div>            

            { mappedAgents }

            <div id="modifyList" onClick={() => {
              this.setState({ enableAgents: !this.state.enableAgents }) 
            }}>
              Modify list
            </div>

            { this.renderMessage() }            
            
            <div id="modal_action">
              
              { this.state.status !== 'Success' &&
                <Button id="action_signup" disabled={disabled} onClick={this.edit.bind(this)}>Edit</Button> }

              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
            </div>

            { this.state.enableAgents &&
              <EnableAgents 
                show={ this.state.enableAgents } 
                supplier={ this.props.deal.supplier } 
                agents={ this.state.agents }
                restrictedTo={ this.state.deal.restrictedTo }
                user={ this.props.user }
                selectAgents={ this.selectAgents }
                onHide={ this.closeEnableAgents.bind(this) }
              /> }

          </div>
        
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditAccessibility