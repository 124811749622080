import React, { Component } from 'react';
import { TasksListItem } from './TasksListItem'
import { TaskHeader } from './TaskHeader'
import './../notes.css';

class TasksList extends Component {
  
  constructor(props) {
		super(props)
		this.state = {
      show: false,
      status: undefined,
      note:'',
    };
  }

  close() {
    this.setState({ show: false });
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success!'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  } 

  render() {    
    let mappedNotes = <div id="NoMessages">No messages at this time</div>;
    let notes = this.props.notes;
    let listOfDiscussions = [];
    if( notes && notes.length > 0 ){
      if( this.props.filter ) notes = notes.filter( elem => elem.type == this.props.filter )
      notes.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })

      mappedNotes = notes.map((note, index) => {
        if( listOfDiscussions.indexOf( note.partOfDiscussion ) < 0 && note.partOfDiscussion ){
          listOfDiscussions.push( note.partOfDiscussion )
          let discussion = notes.filter( elem => elem._id == note.partOfDiscussion || elem.partOfDiscussion == note.partOfDiscussion )
          return (
            <TasksListItem 
              note={note} 
              key={index}
              refresh={ this.props.refresh } 
              notes={ discussion }
              noteView={ this.props.noteView }
              contract={ this.props.contract } 
              agent={ this.props.agent }
              all={this.props.all} 
              user={ this.props.user }
              supplierId={ this.props.supplierId }
            />
          )
        } else if( !note.partOfDiscussion ){
          return (
            <TasksListItem 
              note={note} 
              key={index}
              refresh={ this.props.refresh } 
              noteView={ this.props.noteView }
              contract={ this.props.contract } 
              agent={ this.props.agent }
              all={this.props.all} 
              user={ this.props.user }
              supplierId={ this.props.supplierId }
            />
          )
        }  
      })
    }

    return (
      <div id={this.props.all ? "allNotes":"notesList"}>
        <TaskHeader agent={this.props.agent} noteView={this.props.noteView}/>
        { mappedNotes }
      </div>
    );
  }
}

export default TasksList