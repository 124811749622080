import React, { Component } from 'react';
import './rates.css'

export class RateHeader extends Component {
  
  render() {
    return ( 
      <div className="ratesTitle">
        
        <div className="rateRate">Exchange rate</div>
        <div className="rateDate">Valid from</div>
        <div className="rateDate">Valid to</div>
        <div className="rateSet">Set by</div>
        <div className="ratePayments">Payments</div>
        
      </div>
    )
  }  
}