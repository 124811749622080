export const setActiveViewReducer = (state = '', action) =>{
    if(action.type === "VIEW"){
       return action.payload;
    }        
    else return state
}

export const chooseSearchReducer = (state = '', action) =>{
    if(action.type === "CHOOSE_SEARCH"){
       return action.payload;
    }        
    else return state
}

export const chooseMonthsReducer = (state = '', action) =>{
    if(action.type === "CHOOSE_MONTHS"){
       return action.payload;
    }        
    else return state
}

export const addToClientListReducer = (state = [], action) =>{
    if(action.type === "ADD_TO_CLIENTS"){
       return [...state, action.payload];
    }        
    if(action.type === "REMOVE_FROM_CLIENTS"){
        return state.filter(element => element !== action.payload);
     } 
     if(action.type === "EMPTY_LIST"){
        return [];
     } 
     else return state
}

export const addToPayrollListReducer = (state = [], action) =>{
   if(action.type === "ADD_TO_PAYROLLS"){
      return [...state, action.payload];
   }        
   if(action.type === "REMOVE_FROM_PAYROLLS"){
       return state.filter(element => element !== action.payload);
    } 
    if(action.type === "EMPTY_LIST"){
       return [];
    } 
    else return state
}


export const addToContractListReducer = (state = [], action) =>{
    if(action.type === "ADD_TO_CONTRACTS"){
       return [...state, action.payload];
    }        
    if(action.type === "REMOVE_FROM_CONTRACTS"){
        return state.filter(element => element !== action.payload);
     } 
     if(action.type === "EMPTY_LIST"){
        return [];
     } 
    else return state
}

export const selectContractsReducer = (state = [], action) =>{
   if(action.type === "SELECT_CONTRACTS"){
      return action.payload;
   }        
   if(action.type === "EMPTY_LIST"){
       return [];
    } 
   else return state
}

export const addToPaymentListReducer = (state = [], action) =>{
   if(action.type === "ADD_TO_PAYMENTS"){
      return [...state, action.payload];
   }        
   if(action.type === "REMOVE_FROM_PAYMENTS"){
       return state.filter(element => element !== action.payload);
    } 
    if(action.type === "EMPTY_LIST"){
       return [];
    } 
   else return state
}

export const addToAgentListReducer = (state = [], action) =>{
   if(action.type === "ADD_TO_AGENTS"){
      return [...state, action.payload];
   } 
   if(action.type === "SELECT_ALL_AGENTS"){
      return action.payload;
   }                
   if(action.type === "REMOVE_FROM_AGENTS"){
       return state.filter(element => element !== action.payload);
    } 
    if(action.type === "EMPTY_LIST"){
       return [];
    } 
   else return state
}

export const addToAssetListReducer = (state = [], action) =>{
   if(action.type === "ADD_TO_ASSETS"){
   return [...state, action.payload];
   }        
   if(action.type === "REMOVE_FROM_ASSETS"){
      return state.filter(element => element !== action.payload);
   } 
   if(action.type === "EMPTY_LIST"){
      return [];
   } 
   else return state
}

export const addToGroupListReducer = (state = [], action) =>{
  if(action.type === "ADD_TO_GROUPS"){
   return [...state, action.payload];
  }        
  if(action.type === "REMOVE_FROM_GROUPS"){
    return state.filter(element => element !== action.payload);
  } 
  if(action.type === "EMPTY_LIST"){
    return [];
  } 
  else return state
}


export const answerQuestionReducer = (state = '', action) =>{
   if(action.type === "ANSWER_QUESTION"){
      return action.payload;
   }        
   else return state
}

export const entitiesReducer = (state = '', action) =>{
   let entity = action.payload
   
   if( action.type === "SELECT_ENTITY" ) {

      if( entity.type == 'Country' ) return { country: entity }
      else if( entity.type == 'Organisation' ) {
         Object.assign( state, {
            organisation: entity,
            branch: undefined,
            shop: undefined
         })
         return state
      }
      else if( entity.type == 'Branch' ) {
         Object.assign( state, {
            branch: entity,
            shop: undefined
         })
         return state
      }
      else if( entity.type == 'Shop' ) {
         Object.assign( state, {
            shop: entity
         })
         return state
      }

   } else if( action.type === "DESELECT_ENTITY" ) {

      if( entity.type == 'Country' ) return ''
      if( entity.type == 'Organisation' ) {
         Object.assign( state, {
            organisation: undefined,
            branch: undefined,
            shop: undefined
         })
         return state
      }
      if( entity.type == 'Branch' ) {
         Object.assign( state, {
            branch: undefined,
            shop: undefined
         })
         return state
      }
      if( entity.type == 'Shop' ) {
         Object.assign( state, {
            shop: undefined
         })
         return state
      }


   } else return state
}

export const setTitleReducer = (state = '', action) =>{
   if(action.type === "SET_TITLE"){
      return action.payload;
   }      
   else return state
}

export const setFilterReducer = (state = {}, action) =>{
   if(action.type === "SET_FILTER"){
      return action.payload;
   }    
   if(action.type === "REMOVE_FILTER"){
      return '';
   }      
   else return state
}

export const setAssetFilterReducer = (state = {}, action) =>{
   if(action.type === "SET_ASSET_FILTER"){
      return action.payload;
   }      
   if(action.type === "REMOVE_ASSET_FILTER"){
      return '';
   }   
   else return state
}

export const loadDealsReducer = (state = {}, action) =>{
   if(action.type === "LOAD_DEALS"){
      return action.payload;
   }      
   else return state
}

export const loadProductsReducer = (state = {}, action) =>{
   if(action.type === "LOAD_PRODUCTS"){
      return action.payload;
   }      
   else return state
}