import React, { Component } from 'react';
import Item from './Item'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import './conversation.css';

class ItemsList extends Component {
  
  constructor(props) {
		super(props)
		this.state = {
      show:false,
      status: undefined,
      note:'',
    };
  }
  
  saveNote(){
    var body = {
      note: this.state.note
    }
    axios.put(API_URL + '/contracts/addnote/' + this.props.contractNumber, body)
    .then((ret) => {
      this.setState({
        status: 'Success!'
      })
    })
  }

  close() {
    this.setState({ show: false });
    this.props.refresh()
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success!'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  } 

  render() {    
    let mappedNotes = null;
    let notes = this.props.notes
    if( notes && notes.length > 0 ){
      notes.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      mappedNotes = notes.map((note, index) =>{ 
        return (
          <Item note={note} key={index}/>
        )
      })
    }

    return (
      <div>
        { mappedNotes }
      </div>
    );
  }
}

export default ItemsList