import React from 'react';
import { Bar } from 'react-chartjs-2';
import './invoice.css'

export class InvoiceGraph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                  return value.toLocaleString();
              },            
              fontColor: '#00253D',
              fontSize: 11
            },
            gridLines: {
              display:false
            }
          }],
          yAxes: [{
              stacked: true,            
              ticks: {
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                    return value.toLocaleString();
                },            
                fontColor: '#00253D',
                fontSize: 11
              },
              gridLines: {
                display:false
            }
          }]
        }
      },
      label: this.props.label,
      active: this.props.data.map( elem => elem.activeCount),
      inactive: this.props.data.map( elem => elem.inActiveCount),
      paidOff: this.props.data.map( elem => elem.paidOffCount),
      repossessed: this.props.data.map( elem => elem.repossessedCount)
    };
  };
  
  componentDidUpdate(prevProps) {
    if( this.props.data !== prevProps.data ) {
      this.getGraphData();
    }
  }

  getGraphData = () => {
    this.setState({
      label: this.props.label,
      active: this.props.data.map( elem => elem.activeCount),
      inactive: this.props.data.map( elem => elem.inActiveCount),
      paidOff: this.props.data.map( elem => elem.paidOffCount),
      repossessed: this.props.data.map( elem => elem.repossessedCount)
    })
  }

  render() {
    return (
      <div>
        <Bar
          data={{
            labels: this.state.label, 
            datasets: [
              {
                label: 'Total repossessed',
                stack: '1',
                backgroundColor: 'rgba(220,20,60, 0.9)',
                borderColor: 'rgba(220,20,60, 0.9)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(220,20,60, 1)',
                hoverBorderColor: 'rgba(220,20,60, 1)',
                data: this.state.repossessed
              },
              {
                label: 'Total paidOff',
                stack: '1',
                backgroundColor: 'rgba(0,139,139, 0.8)',
                borderColor: 'rgba(0,139,139, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(0,139,139, 1)',
                hoverBorderColor: 'rgba(0,139,139, 1)',
                data: this.state.paidOff
              },
              {
                label: 'Total locked',
                stack: '1',
                backgroundColor: 'rgba(255,215,0, 1)',
                borderColor: 'rgba(255,215,0, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,215,0, 1)',
                hoverBorderColor: 'rgba(255,215,0, 1)',
                data: this.state.inactive
              },
              {
                label: 'Total enabled',
                stack: '1',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderColor: 'rgba(255, 255, 255, 0.9)',
                borderWidth: 1,
                hoverBackgroundColor: 'white',
                hoverBorderColor: 'white',
                data: this.state.active
              }
            ]
          }}
          width={ this.props.width }
          height={ this.props.height }
          options={ this.state.options }
        /> 
      </div>
    );
  }
}
