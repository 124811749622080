import React, { Component } from 'react';
import history from '../../../../history';
import './payments.css'
import ReversePayment from '../Action/ReversePayment';
import ReverseDownPayment from '../Action/ReverseDownPayment';
import TransferPayment from '../Action/TransferPayment';
import CashTransfer from '../Action/CashTransfer';
import AssignPayment from '../Action/AssignPayment';
import DeletePayment from '../Action/DeletePayment';

import { addToPaymentList, removeFromPaymentList } from '../../../../Actions/index';
import { hasPermission } from './../../../../services/UserService'
import { connect } from 'react-redux';

class Item extends Component {

  constructor(props) {
		super(props)
		this.state = {
      show: false,
      transfer: false,
      reverseDownPayment: false,
      delete: false,
      assign: false,
      cash: false,
      cancel: false
    }
  }
  
  handleSelect(){
    let user = this.props.user
    if( ['Core', 'MainAdmin', 'Admin'].indexOf( user.role ) > -1 || (user.permissions && user.permissions.reconcileCash) ){
      if(this.props.selectedPayments.indexOf(this.props.payment) < 0){
        this.props.addToPaymentList(this.props.payment)
      } else this.props.removeFromPaymentList(this.props.payment)
    }

  }

  transfer() {
    this.setState({ transfer: false });
    this.props.refresh('ACCEPTED', 1)
  }

  cash() {
    this.setState({ cash: false });
    this.props.refresh('ACCEPTED', 1)
  }
  
  assign() {
    this.setState({ assign: false });
    this.props.refresh('ACCEPTED', 1)
  }

  cancel() {
    this.setState({ cancel: false });
    this.props.refresh('ACCEPTED', 1)
  }

  delete() {
    this.setState({ cancel: false });
    this.props.refresh('UNASSIGNED', 1)
  }

  reverseDownPayment() {
    this.setState({ reverseDownPayment: false });
    this.props.refresh('ACCEPTED', 1)
  }

  handleNext(path){
    history.push(path)
  }

  render() {
    let { payment, contract, general, user, fromAgentPage, supplierId } = this.props
    let message = payment.message
    let activation = payment.activation
    let contractNumber = contract? contract.contractNumber : undefined
    let agent = payment.agent? payment.agent: {}

    let assignPermission, transferPermission = undefined

    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    var longOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'  };

    let date = new Date(payment.date)
    let detailedDate = new Date(payment.date)
    let reconciliationDate = '-'
    let smsSentDate = '-'
    let smsDeliveredDate = ''
    let activationStartDate = ''
    let activationEndDate = ''
    
    if( !general) date = date.toLocaleDateString("en-EN", options)
    else date = date.toLocaleDateString("en-EN", longOptions)
    detailedDate = detailedDate.toLocaleDateString("en-EN", longOptions)

    if( payment.reconciliationDate ){
      reconciliationDate = new Date(payment.reconciliationDate)
      reconciliationDate = reconciliationDate.toLocaleDateString("en-EN", options)
    }
    if( message && message.date ){
      smsSentDate = new Date( message.date )
      smsSentDate = smsSentDate.toLocaleDateString("en-EN", longOptions)
    }
    if( message && message.deliveryDate ){
      smsDeliveredDate = new Date( message.deliveryDate )
      smsDeliveredDate = smsDeliveredDate.toLocaleDateString("en-EN", longOptions)
    }
    if( activation && activation.startDate ){
      activationStartDate = new Date( activation.startDate )
      activationStartDate = activationStartDate.toLocaleDateString("en-EN", longOptions)
    }
    if( activation && activation.endDate ){
      activationEndDate = new Date( activation.endDate )
      activationEndDate = activationEndDate.toLocaleDateString("en-EN", longOptions)
    }

    let details = null
    if( this.state.show ){

      assignPermission = hasPermission( user, undefined, 'assignPayments' )
      transferPermission = hasPermission( user, undefined, 'transferPayments' )

      details = 
        <div id="paymentDetails">
          <div>
            <div id="paymentDetailsP">

              <div id="paymentCodeWrapper">
                <div id="paymentCodeLabel">TransactionId</div>
                <div id="paymentCode">
                  { payment.transactionId ? payment.transactionId : "-" }
                </div>
              </div>
              <div id="paymentCodeWrapper">
                <div id="paymentCodeLabel">Received on</div>
                <div id="paymentCode">{ detailedDate }</div>
              </div>
              <div id="paymentCodeWrapper">
                <div id="paymentCodeLabel">Reference</div>
                <div id="paymentCode">
                  { payment.paymentReference ? payment.paymentReference : "-" }
                </div>
              </div>

              { payment.reconciledBy &&
                <div>
                  <div id="paymentCodeWrapper">
                    <div id="paymentCodeLabel">Reconciled on</div>
                    <div id="paymentCode">{ reconciliationDate }</div>
                  </div>
                  <div id="paymentCodeWrapper">
                    <div id="paymentCodeLabel">Reconciled by</div>
                    <div id="paymentCode"> { payment.reconciledBy }</div>
                  </div>
                </div> }

              { activation &&
                <div id="paymentDetailsA">
                  <div id="paymentCodeWrapper">
                    <div id="paymentCodeLabel">Activation</div>
                    <div id="paymentCode">{ activation.type ? activation.type :"-" }</div>  
                  </div>
                  <div id="paymentCodeWrapper">
                    <div id="paymentCodeLabel">Valid for</div>
                    <div id="paymentCode">{ activation.duration ? activation.duration :"-" } days</div>  
                  </div>
                  <div id="paymentCodeWrapper">
                    <div id="paymentCodeLabel">Starting on</div>
                    <div id="paymentCode">{ activationStartDate }</div>  
                  </div>
                  <div id="paymentCodeWrapper">
                    <div id="paymentCodeLabel">Ending on</div>
                    <div id="paymentCode">{ activationEndDate }</div>  
                  </div>
                </div> }

            </div>
          
            <div id="paymentDetailsM">
              <div id="paymentCodeWrapper">
                <div id="paymentCodeLabelM">MessageId </div>
                <div id="paymentCodeM">{ payment.messageId ? payment.messageId :"-" }</div>  
              </div>
              <div id="paymentCodeWrapper">
                <div id="paymentCodeLabelM">Status</div>
                <div id="paymentCodeM">{ message? message.status: '-' }</div>  
              </div>
              <div id="paymentCodeWrapper">
                <div id="paymentCodeLabelM">Sent on </div>
                <div id="paymentCodeM">{ smsSentDate }</div>  
              </div>
              <div id="paymentCodeWrapper">
                <div id="paymentCodeLabelM">Delivered on </div>
                <div id="paymentCodeM">{ smsDeliveredDate }</div>  
              </div>
              <div id="paymentCodeWrapper">
                <div id="paymentCodeLabelM">SMS</div>
                <div id="paymentCodeM">{ message? message.text: '-' }</div>  
              </div>
            </div>
          </div>

          <div id="paymentDetailsN">
            <div id="paymentCodeWrapper">
              <div id="paymentCodeLabelN">Note</div>
              <div id="paymentCodeN">{ payment.note? payment.note: "-" }</div>            
            </div>
          </div> 
          
          { !transferPermission || payment.status == 'UNASSIGNED' ||
            ( payment.paymentReference && 
              ( payment.paymentReference.indexOf('@') > -1 || payment.paymentReference.indexOf('at') > -1 || payment.paymentReference.indexOf('AT') > -1 ) && 
              payment.contract && 
              payment.contract.payments && 
              payment.contract.payments.length > 1
            )
            ? null
            : <div>
                <div id="paymentDetailsP">

                  { payment.status == 'ACCEPTED' && 
                    payment.contract.lockable && payment.amount !== payment.contract.totalPaid && 
                    ['REVERSAL', 'TRANSFER'].indexOf( payment.type ) < 0 && 
                    !fromAgentPage &&
                    <div>
                      <div className="paymentReversal" onClick={()=>this.setState({ transfer: !this.state.transfer })}>
                        Transfer
                      </div>  
                      <div className="paymentReversal cancel" onClick={()=>this.setState({ cancel: !this.state.cancel })}>
                        Cancel
                      </div>
                    </div> } 

                  { payment.status == 'ACCEPTED' && 
                    payment.contract.lockable && 
                    payment.amount == payment.contract.totalPaid && 
                    ['REVERSAL', 'TRANSFER'].indexOf( payment.type ) < 0 && 
                    !fromAgentPage &&
                    <div className="paymentReversal" onClick={()=>this.setState({ reverseDownPayment: !this.state.reverseDownPayment })}>
                      Reverse down-payment
                    </div>  } 
                  
                  { payment.status == 'ACCEPTED' && 
                    !payment.contract.lockable && ['REVERSAL', 'TRANSFER'].indexOf( payment.type ) < 0 && 
                    !fromAgentPage &&
                    <div>
                      <div className="paymentReversal" onClick={()=>this.setState({ cash: !this.state.cash })}>
                        Cash transfer
                      </div>  
                      <div className="paymentReversal cancel" onClick={()=>this.setState({ cancel: !this.state.cancel })}>
                        Cancel
                      </div>
                    </div> }

                </div>
              
              </div>  
            }

          { payment.status == 'UNASSIGNED' && assignPermission && !fromAgentPage &&
            <div id="paymentDetailsP">
              <div className="paymentReversal" onClick={()=>this.setState({ assign: !this.state.assign })}>
                Assign payment
              </div> 
              { ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1 && 
                <div id="deletePayment" onClick={()=>this.setState({delete: true})}><i className="fas fa-trash"></i></div> }
            </div> } 
        </div>
    }

    return (
      <div>
        <div className="paymentItem">
          
          <div id={ general? "paymentsType": "paymentsTypeC"} className="onlyLarge">{ payment.type? payment.type : '-' }</div> 

          <div id={ general? "paymentsStatus": "paymentsStatusS"} className="onlyLarge"> { payment.status }</div> 

          <div id={ general? "paymentsDate": "paymentsMS"} >{ date }</div> 

          { general && payment.type !== 'CASH' && [ "Search", "FAILED", "UNASSIGNED" ].indexOf( this.props.paymentStatus) < 0 &&
            <div id="paymentsReconciled" className="onlyLarge">AUTOMATIC</div> }  

          { general && payment.reconciled != false && payment.type == 'CASH' && [ "Search", "FAILED", "UNASSIGNED" ].indexOf( this.props.paymentStatus) < 0 &&
            <div id="paymentsReconciled" className="onlyLarge">
              { payment.reconciledBy 
                ? payment.reconciledBy 
                : payment.recordedBy? payment.recordedBy : '-'}
            </div> }

          { general && payment.reconciled == false && payment.type == 'CASH' && [ "Search", "FAILED", "UNASSIGNED" ].indexOf( this.props.paymentStatus) < 0 &&
            <div 
              id="paymentsNotReconciled" 
              onClick={()=>this.handleSelect()}
              className={this.props.selectedPayments.indexOf(payment) > -1 ? 'selectedPayment onlyLarge' :'onlyLarge'}
            >
              { this.props.selectedPayments.indexOf(payment) > -1 ? user.userNumber: 'Not reconciled'}
            </div> } 

          <div id={ general ? "paymentsTransaction": "paymentsLarge"} >{ payment.transactionId }</div> 

          { ( this.props.paymentStatus == "FAILED" || this.props.paymentStatus == "UNASSIGNED" || payment.status == "FAILED" ) && general
          ? <div id={this.props.paymentStatus == 'Search'? "paymentsContract" : "paymentsReason" }>{ payment.reasonForFailing ? payment.reasonForFailing  : '-'}</div>  
          : null }
          
        { this.props.paymentStatus !== "FAILED" && general && payment.contract
          ? <div id="paymentsContract" className='active' onClick={() => this.handleNext('/contract/id='+ contractNumber)}>{ contractNumber } </div>  
          : null } 

          <div id={ general? "paymentsAmount": "paymentsMS"} >{ payment.ccy } { payment.amount? payment.amount.toLocaleString():'-' }</div>

          { payment.mobile
            ? <div id="paymentsOrigin" className="onlyLarge">{ payment.mobile }</div>  
              : payment.recordedBy
                ? <div id="paymentsOrigin" className="onlyLarge">{ payment.recordedBy } </div>
                : <div id="paymentsOrigin" onClick={() => this.handleNext('/agent/id='+ agent.agentNumber)} className="onlyLarge active">
                    { agent && agent.profile ? agent.profile.firstName.slice(0,1) + ' '+ agent.profile.lastName : '-' } 
                  </div>
            } 

          { general && <div id="paymentsRef">{ payment.paymentReference } </div> } 

          { general &&<div id="paymentsMessage" >{ message? message.status: '-' } </div> } 
          
          <div  id={ general? "paymentsXS": "paymentsXSS"} className="active" onClick={()=>this.setState({show: !this.state.show})}>
            <i className="far fa-eye"></i>
          </div>  

        </div>

        { details }
        
        { this.state.transfer &&
          <TransferPayment 
            show={this.state.transfer} 
            onHide={this.transfer.bind(this)}
            supplierId={supplierId}
            user={ user }
            contractNumber={ contractNumber }
            transactionId={ payment.transactionId}
          /> }

        { this.state.cash &&
          <CashTransfer 
            show={this.state.cash} 
            onHide={this.cash.bind(this)}
            supplierId={supplierId}
            user={ user }
            contractNumber={ contractNumber }
            transactionId={ payment.transactionId}
          /> }

        { this.state.cancel &&
          <ReversePayment 
            show={this.state.cancel} 
            onHide={this.cancel.bind(this)}
            supplierId={supplierId}
            user={ user }
            contractNumber={ contractNumber }
            transactionId={ payment.transactionId}
          /> }

        { this.state.assign &&
          <AssignPayment 
            show={this.state.assign} 
            onHide={this.assign.bind(this)}
            supplierId={supplierId}
            user={ user }
            transactionId={ payment.transactionId}
          /> }

        { this.state.reverseDownPayment &&
          <ReverseDownPayment 
            show={this.state.reverseDownPayment} 
            onHide={this.reverseDownPayment.bind(this)}
            supplierId={supplierId}
            user={ user }
            contractNumber={ contractNumber }
            transactionId={ payment.transactionId}
          /> }

        { this.state.delete &&
          <DeletePayment 
            show={this.state.delete} 
            onHide={this.delete.bind(this)}
            supplierId={supplierId}
            user={ user }
            transactionId={ payment.transactionId}
          /> }

      </div>
    )
  }  
}

export const PaymentsListItem = connect(
  function mapStateToProps(state) {
    return { 
      selectedPayments: state.selectedPayments
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToPaymentList: payment => dispatch(addToPaymentList(payment)),
      removeFromPaymentList: payment => dispatch(removeFromPaymentList(payment))
    };
  },
)(Item);