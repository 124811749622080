import React from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import AgeEvolution from './ageEvolution'
import AgeShot from './ageShot'

class Age extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: undefined
    };
  };

  componentDidMount() {
    this.getGraph()
  }

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) this.getGraph();
  }

  getGraph = () => {
    let body = {
      criteria: {},
      userId: this.props.user._id
    }
    axios
      .post(API_URL + '/clients/age-analysis/' + this.props.supplier, body)
      .then((res) => {
        this.setState({
          data: res.data
        })
      })
  }

  render() {
    let data = this.state.data

    return (
      <div>   
        <div id="graphTitle">Age groups</div>
        <div id="graphWrapper">
         { data
          ? <div>
              <div>
                <AgeShot data={ data }/>
              </div>
              <div>
                <AgeEvolution data={ data }/>
              </div>
            </div>
          : <div>Loading</div>
         }
        </div>  
      </div>      
    );
  }
}

export default Age