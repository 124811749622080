import React, { Component } from 'react';
import {Button, Modal, FormControl} from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';
import { findAgents } from  './../../../../services/AgentService'

class TransferAsset extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status: undefined,
      transfer:{
        newHolder: undefined,
        oldHodlwer: undefined
      },
      assets: undefined,
      agents: undefined,
      disabled: false
    };
    this.loadData.bind(this)
  } 
  componentDidMount() {
    this.loadData()
  }

  transferAsset() {
    this.setState({disabled: true})
    axios.put(API_URL + '/assets/transfer/' + this.props.asset.supplier + '/' + this.props.asset.assetNumber, this.state.transfer)
			.then((ret) => {
        this.setState({
          status: 'Success!'
        })
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getAgents();
  }  
  over(){
    this.props.onHide();
    this.props.refresh()
  }
  render() {
    
    let mappedAgents = null
    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })
    }
    let isDisabled = (this.state.transfer.newHolder)?false:true
    if(this.state.disabled){isDisabled=true}
    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-sm">Transfer asset to new agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="contractInput"
              onChange={(e)=>{ 
                let transfer = Object.assign({}, this.state.transfer); 
                transfer.newHolder = e.target.value;
                this.setState({ transfer });
                }}
              >
              <option value="">Choose a new agent</option>
              {mappedAgents}
            </FormControl>
            {this.renderMessage()}
            <div id="modal_action">
              {this.state.status==='Success!'?
                null
                :
                <Button id="action_signup" disabled={isDisabled} onClick={this.transferAsset.bind(this)}>Confirm</Button>
              }
              <Button id="action_cancel" onClick={() => this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
            </div>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default TransferAsset;