import React, { Component } from 'react';
import FillCreditScore from './FillCreditScore'
import { FormControl, Button } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL, answerQuestion } from '../../../../Actions/index';
import { connect } from 'react-redux';
import './../credit.css';

class Start extends Component {
  constructor(props) {
		super(props)
		this.state = {
      creditItem: {
        question: this.props.questionnaire.questions[0],
        questionnaire: this.props.questionnaire,
        name: this.props.questionnaire.name,
        contract: this.props.contract,
        client: this.props.client,
      },
    };
  }  

  componentDidMount() {
    this.props.answerQuestion( 0 )
  }

  startCreditScore(){
    axios.post(API_URL + '/creditscores/startCreditScore/' + this.props.supplier, this.state.creditItem)
			.then((ret) => {
        this.setState({ status: 'Success!', creditScoreId: ret.data })
      })
  }

  handleSubmit(){
    this.startCreditScore();
    this.props.answerQuestion( 1 )
  }

  render() {    

    let navigate = null;
    let mappedOptions = null;
    let disabled = true
    if( this.state.creditItem.answer ) disabled = false

    //let question = this.props.questionnaire.questions[0]
    let minRanking = Math.min(...this.props.questionnaire.questions.map( elem => elem.rank ) )
    let question = this.props.questionnaire.questions.find( elem => elem.rank === minRanking )

    if( question && question.selectable && question.possibleAnswers ){
      mappedOptions = question.possibleAnswers.map((answer,index) =>{
        return (
          <option key={index} value={answer}>{answer}</option>
        )
      })
    }

    if( question && question.isNumeric ){
      navigate =           
        <FormControl 
          type="number" 
          className="modalInput"
          placeholder="Type answer"
          onChange={(e)=>{ 
            let creditItem = Object.assign({}, this.state.creditItem); 
            creditItem.answer = e.target.value; 
            this.setState({ creditItem });
            }}
        />
    }
    if( question && question.selectable && question.possibleAnswers ){
      navigate =           
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="modalInput"
          onChange={(e)=>{ 
            let creditItem = Object.assign({}, this.state.creditItem); 
            creditItem.answer = e.target.value; 
            if( question.options ){
              let pair = question.options.find( elem => elem.answer == e.target.value )
              let translatedAnswer = pair? pair.translatedAnswer : undefined
              creditItem.translatedAnswer = translatedAnswer
            }
            this.setState({ creditItem });
            }}        >
          <option value="">Choose</option>
          { mappedOptions }
        </FormControl>
    }
    else navigate =           
      <FormControl 
        type="text" 
        className="modalInput"
        placeholder="Type answer"
        onChange={(e)=>{ 
          let creditItem = Object.assign({}, this.state.creditItem); 
          creditItem.answer = e.target.value; 
          this.setState({ creditItem });
          }}
      />
      
    return (
      <div id="createCreditScore">
        { question &&
          <div>
            <div id="cat">{ question? question.category:'' }</div>
            <div id="que">{ question? question.question:'' }</div>
            <div id="ans">{ navigate }</div>
            
            { this.props.turn === 0
              ? <Button disabled={disabled} id="next" onClick={()=>this.handleSubmit()}>Answer</Button>
              : <div id="done">Done</div> }
          </div>
        }

        { this.state.creditScoreId &&
          <FillCreditScore 
            questionnaire={ this.props.questionnaire } 
            client={ this.props.client }
            supplier={ this.props.supplier }
            contract={ this.props.contract }
            creditScoreId={ this.state.creditScoreId }
          />
        }
      </div>
    );
  }
}


const StartCreditScore = connect(
  function mapStateToProps(state) {
    return { 
      turn:state.turn
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      answerQuestion: turn => dispatch(answerQuestion(turn)),
    };
  },
)(Start);

export default StartCreditScore

