import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import './rate.css';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';
var DatePicker = require("react-bootstrap-date-picker");

class RatesAddOne extends Component {

  constructor(props) {
    super(props);
    this.state= {
      rate: {
        rate: undefined,
        setBy: this.props.user._id,
        baseCCY: this.props.options.ccys[1],
        validFrom: undefined,
        secondaryCCY: this.props.options.ccys[0],
        sendNotification: undefined,
        message: undefined,
        supplier: this.props.user.supplier
      },       
      status: undefined,
      ready: false,
      options: undefined,
      disabled: false
    }
  }; 

  createRate() {
    axios
      .post(API_URL + '/rates/'+ this.props.user.supplier, this.state.rate)
      .then((ret) => {
          this.setState({ status: ret.data.message });
      }).catch(err => {
        this.setState({ status: 'Error' })
      }); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  } 
  
  over(){
    this.props.onHide();
  }

  render() {    
    let isDisabled = true

    if( this.state.rate.rate && this.state.rate.rate > 0 && !isNaN(this.state.rate.rate) ) isDisabled = false
    if( this.state.disabled ){ isDisabled=true }   
    let message = this.state.rate.message
    let preview = message? message : ''
    let converted = this.state.rate.rate? 10 * this.state.rate.rate : 0
    if( preview && preview.indexOf('{%ccy}') > -1 ) preview = preview.replace( /{%ccy}/g, this.props.options.ccys[1])
    if( preview && preview.indexOf('{%secondccy}') > -1 ) preview = preview.replace( /{%secondccy}/g, this.props.options.ccys[0])
    if( preview && preview.indexOf('{%amount}') > -1 ) preview = preview.replace( '{%amount}', '10' )
    if( preview && preview.indexOf('{%converted}') > -1 ) preview = preview.replace( '{%converted}', converted )
    if( preview && preview.indexOf('{%paygNumber}') > -1 ) preview = preview.replace( '{%paygNumber}', '847921' )
    if( preview && preview.indexOf('{%balance}') > -1 ) preview = preview.replace( '{%balance}', '100' )
    if( preview && preview.indexOf('{%convertedBalance}') > -1 ) preview = preview.replace( '{%convertedBalance}', 10 * converted )

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">
            <div id="rateTitle">Create new rate</div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="rateCreate">
            <div id="newRateLabel">
              1 { this.props.options.ccys[1] } =
            </div>
            <FormControl 
              type="number" 
              className="rateDetailsInput"
              placeholder={ this.props.existingRate? this.props.existingRate.rate:'xx'}
              onChange={(e)=>{ let rate = Object.assign({}, this.state.rate); rate.rate = e.target.value; this.setState({ rate }) }}
            />
            <div id="newRateLabelRight">
              { this.props.options.ccys[0] }
            </div>

            <div id="fullPayment">
              <div id="newRateLabel">Valid from</div>
              <div className ="rateDetailsInput">
                <DatePicker 
                  className ="dateInput"
                  value={ this.state.rate.validFrom }  
                  onChange={(e)=>{ 
                    let rate = Object.assign({}, this.state.rate); 
                    rate.validFrom = e; 
                    this.setState({ rate }) 
                  }}
                  showClearButton= {false}
                  dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                  monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                />
              </div>
            </div>

            <div id="fullPayment">
              <div id="newRateLabel">Send notification</div>
              <div
                className="rateInput"
                id={ this.state.rate.sendNotification == true? 'activePaymentOption': '' }
                onClick={(e)=>{ 
                  let rate = Object.assign({}, this.state.rate); 
                  rate.sendNotification = true; 
                  rate.message = "Message regarding your contract {%paygNumber}: to get your next 30-day code, please pay {%ccy}{%amount} or {%secondccy}{%converted}. Your balance is {%ccy}{%balance} or {%secondccy}{%convertedBalance}. Thank you."
                  this.setState({ rate: rate, sendNotification: true });
                  }}
                >
                Yes
              </div>
              <div
                className="rateInput marginleft"
                id={ this.state.rate.sendNotification == false? 'activePaymentOption': '' }
                onClick={(e)=>{ 
                  let rate = Object.assign({}, this.state.rate); 
                  rate.sendNotification = false; 
                  this.setState({ rate: rate, sendNotification: false });
                  }}
                >
                No
              </div>
            </div>

            { ! this.state.rate.sendNotification
              ? null
              : <div>
                  <textarea 
                    className="templateDetailsTextInput"
                    value={ this.state.rate.message }
                    onChange={(e)=>{ 
                      let rate = Object.assign({}, this.state.rate); 
                      rate.message = e.target.value; 
                      this.setState({ rate }) 
                    }}
                  />
                  <div id="messageVariables">
                    <div id="messageVariableTitle">Available variables</div>
                    <div id="messageVariable">&#123;%paygNumber&#125;</div>
                    <div id="messageVariableLabel">Reminder to the client of his/her paygNumber</div>
                    <div id="messageVariable">&#123;%balance&#125;</div>
                    <div id="messageVariableLabel">Contract's remaining balance</div>
                    <div id="messageVariable">&#123;%amount&#125;</div>
                    <div id="messageVariableLabel">Monthly payment</div>
                    <div id="messageVariable">&#123;%ccy&#125;</div>
                    <div id="messageVariableLabel">Main contract currency</div>
                    <div id="messageVariable">&#123;%secondccy&#125;</div>
                    <div id="messageVariableLabel">Secondary contract currency</div>
                    <div id="messageVariable">&#123;%converted&#125;</div>
                    <div id="messageVariableLabel">Converted monthly payment</div>
                    <div id="messageVariable">&#123;%convertedBalance&#125;</div>
                    <div id="messageVariableLabel">Converted balance</div>
                    <div id="messageVariableTitle">Message preview</div>
                    <div id="messagePreview">{ preview }</div>
                  </div> 
                </div> }


            {this.renderMessage()}
            <div id="modal_action">
              { ! this.state.status
                ? <Button id="action_signup" disabled={isDisabled} onClick={()=>this.createRate(this.state.rate)}>Create</Button>
                : null }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
            </div>  
          </div>
      
        </Modal.Body>
      </Modal>
    );
  }
}

export default RatesAddOne