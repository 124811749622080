import React, { Component } from 'react';
import './utils.css';
import history from './../../../history';


class InfoWrapper extends Component {
  
  select(){
    if( this.props.agentLink ) history.push('/agent/id=' + this.props.data )
  }

  render() {    

    return (
      <div 
        className= "infoWrapper"
        onClick={ () => this.select() }
      >
        <p id="infoLabel">{this.props.label}</p>
        <p id="infoData" className={ this.props.isActive ? "activeWrapper" : ""}>{this.props.data}</p>
      </div>
    );
  }
}

export default InfoWrapper