import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import './dealAction.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class EditCommission extends Component {

  constructor(props) {
    super(props);
    this.state= {
      deal: {},       
      status: undefined,
      ready: false
    }
  }; 

  componentDidMount() {
    let deal = Object.assign( this.state.deal, this.props.deal )
    deal.dealVersion = this.props.deal.dealVersion? this.props.deal.dealVersion + 1 : 2
    this.setState({
      status: undefined,
      deal
    })
  }
  
  edit(){
    axios
      .put(API_URL + '/deals/amend/'+ this.props.deal.supplier + '/' + this.props.deal._id, {
        editedDeal: this.state.deal,
        newVersion: this.state.newVersion
      })
      .then( ret => this.setState({ status: ret.data.message }) )
      .catch( err => this.setState({ status: 'Error' }) )
  }

  over(){
    this.setState({ status: undefined })
    this.props.onHide()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'} className='long'>
          { this.state.status }
        </div>
      );
    }
  }   

  render() {    
    let user = this.props.user;
    let disabled = (
      this.state.deal.commission < 0 ||
      this.state.deal.commission == '' ||
      this.state.deal.pctCommission < 0 ||
      this.state.deal.pctCommission == '' ||
      this.state.deal.pctCommission >=1 ||
      ( this.state.deal.pctCommission && isNaN(this.state.deal.pctCommission) ) ||
      ( this.state.deal.commission && isNaN(this.state.deal.commission) )
    )? true: false
    
    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Edit deal commission structure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
           
            <div id="dealEditL">Commission</div>   
            <div id="dealEditShorter">
              <FormControl 
                type="text" 
                className="dealEditInput"
                value={ this.state.deal.commission || this.state.deal.commission == ''
                  ? user.options.ccys[0] + ' ' + this.state.deal.commission
                  :  user.options.ccys[0] + ' ' + 0 
                }
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.commission = e.target.value.replace( /^\D+/g, ''); 
                  this.setState({ deal }) 
                }}
              />
            </div> 
            <div className="dealEditInputLabel">per new signing</div>
            <div id="dealEditShorter">
              <FormControl 
                type="text" 
                className="dealEditInput"
                value={ this.state.deal.pctCommission || this.state.deal.pctCommission == ''
                  ? this.state.deal.pctCommission
                  : '0.00' 
                }
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.pctCommission = e.target.value.replace( /^\D+/g, ''); 
                  this.setState({ deal }) 
                }}
              />
            </div> 
            <div className="dealEditInputLabel">of revenues</div>

            { this.renderMessage() }            
            
            <div id="modal_action">
              
              { this.state.status !== 'Success' &&
                <Button id="action_signup" disabled={disabled} onClick={this.edit.bind(this)}>Edit</Button> }

              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditCommission