import { SEND_CONTACT_FORM } from '../Actions/types';

const INITIAL_STATE = { conversations: [], message: '', messages: [], recipients: [], error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEND_CONTACT_FORM:
      return { ...state, message: action.payload };
  }
  return state;
}