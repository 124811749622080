import React, { Component } from 'react';
import axios from 'axios';
import { FormControl } from 'react-bootstrap/lib';
import { API_URL, emptyList } from './../../../Actions/index';
import AssetsList from './List/AssetsList'
import ManageMenu from './../ManageMenu'
import history from './../../../history';
import ConfirmAsset from './Action/ConfirmAsset'
import RestoreAsset from './Action/RestoreAsset'
import { connect } from 'react-redux';
import { findProducts } from  './../../../services/ProductService'

class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      nav: 1,
      assetStatus: 'DEPLOYED',
      showModal: false,
      showBack : false,
      page: 1,
      total: 0,
      pages: 1
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeView !== prevProps.activeView) {
      this.getAssetsList('DEPLOYED', 1);
    }
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0);
  }

  handleSelect(assetStatus){
    this.setState({ assetStatus, page: 1, assets: undefined }, () =>{
      this.getAssetsList( assetStatus, 1, this.state.product )
    });
  }
   
	getAssetsList = (status, fromPage, product) => {
    let query = { status, product }
     axios
      .post(API_URL + '/assets/all/' + this.props.user.supplier, {
        query,
        user: this.props.user._id,
        fromPage,
        pageSize: 50
      })
      .then((ret) => this.setState({ 
        assets: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total 
      }))
  }
  
  getProducts = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products })
    } catch(err){ console.log(err) }
  }

  handleNext(path){
    history.push(path)
  }

  loadData() {
    this.getAssetsList('DEPLOYED', 1);
    this.getProducts()
  }

  close() {
    this.setState({ showModal: false, assetStatus: 'INSTOCK', page: 1 });
    this.getAssetsList('INSTOCK', 1);
    this.props.emptyList()
  }
  
  closeBack() {
    this.setState({ showBack: false, assetStatus: 'INSTOCK', page: 1 });
    this.getAssetsList('INSTOCK', 1);
    this.props.emptyList()
  }

  render() {    
    let page = this.state.page;
    let pages = this.state.pages
    let user = this.props.user
    let permission = false
    if( user.permissions.transferAssets !== false ) permission = true

    let navigate = null
    if(this.state.assetStatus == 'PENDING' && ['Admin', 'Core', 'MainAdmin', 'OrgAdmin', 'CountryAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf(user.role) > -1 ){
      navigate =     
      <div>        
        <div id="assetSubMenuSpacer">&nbsp;</div>
        <div id="accept" onClick={()=>this.setState({ action: 'confirm', showModal: true })}>Scan</div>
        <div id="refuse" onClick={()=>this.setState({ action: 'delete', showModal: true })} >Delete</div>
      </div>
    }

    if(
      this.state.assetStatus == 'DEFECTIVE' &&
      ( ['Admin', 'Core', 'MainAdmin'].indexOf(user.role) > -1 || permission )
    ){
      navigate =     
      <div>        
        <div id="assetSubMenuSpacer">&nbsp;</div>
        <div id="accept" onClick={()=>this.setState({ action: 'restore', showBack: true })}>Restore</div>
      </div>
    }
    
    let mappedProducts = null
    if(this.state.products){
      mappedProducts = this.state.products.map((product, index) =>{ 
        return (
          <option key={product._id} value={product._id} className={'agentOption'}>{product.name} by {product.manufacturer}</option>
        )
      })
    }

    return (
      <div className='background'>  

        <div id="pageTitle">Assets</div>      
        <ManageMenu user={ user }/>
        
        <div id="clientBackground">
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('DEPLOYED')} className={this.state.assetStatus==='DEPLOYED'?"active_clientSubMenu leftMenu":"leftMenu"}>Deployed</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('INSTOCK')} className={this.state.assetStatus==='INSTOCK'?"active_clientSubMenu":""}>In stock</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('PENDING')} className={this.state.assetStatus==='PENDING'?"active_clientSubMenu":""}>Pending</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('DEFECTIVE')} className={this.state.assetStatus==='DEFECTIVE'?"active_clientSubMenu rightMenu":"rightMenu"}>Defective</div>
            <div id="assetSelectorWrapper" className='onlyLarge'>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="assetSelector"
                onChange={ (e) => { 
                  this.setState({ product: e.target.value }) 
                  this.getAssetsList(this.state.assetStatus, 1, e.target.value)
                }}
              >
                <option value="" className={'agentOption'}>All products</option>
                { mappedProducts }
              </FormControl>
            </div>
            
            { navigate }
            
          </div>

          { this.state.assets && this.state.assets.length > 0
            ? <div id="assetsList">
                <div className="assetNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getAssetsList(this.state.assetStatus, 1, this.state.product)
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getAssetsList(this.state.assetStatus, Math.max( 1, page -1 ), this.state.product)
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="navLegend">{ this.state.total } assets</div>
                  <div className="navPage">page {page} / {pages} </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getAssetsList(this.state.assetStatus, Math.min( pages, page + 1 ), this.state.product)
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getAssetsList(this.state.assetStatus, pages, this.state.product)
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>
              </div>
            : null } 

          { this.state.assets
            ? <div id="assetsList">
                <AssetsList assets={this.state.assets} assetStatus={this.state.assetStatus}/>
              </div>
            : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }

        </div>

        { this.state.showModal &&
          <ConfirmAsset 
            show={this.state.showModal} 
            onHide={this.close.bind(this)}
            selectedAssets={this.props.selectedAssets}
            action={this.state.action}
            user={ user }
          /> }

        { this.state.showBack
          ? <RestoreAsset 
            show={this.state.showBack} 
            onHide={this.closeBack.bind(this)}
            selectedAssets={this.props.selectedAssets}
            action={this.state.action}
            user={ user }
            supplier={ user.supplier }
          /> 
          : null }

      </div>
    );
  }
}

const AssetsController = connect(
  function mapStateToProps(state) {
    return { 
      selectedAssets: state.selectedAssets,
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Controller);


export default AssetsController