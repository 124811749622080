import React, { Component } from 'react';
import {ContractHeader} from './ContractHeader'
import {ContractsListItem} from './ContractsListItem'
import './../List/contracts.css';

class ContractsList extends Component {
  
  render() {    
    let mappedContracts = null;
    if(this.props.contracts){
      mappedContracts = this.props.contracts
        .filter( elem => elem != undefined )
        .map((contract, index) =>{ 
          return (
            <ContractsListItem contract={ contract } key={index}/>
          )
        })
    }

    return (
      <div className="lightList">
        { this.props.contracts && !this.props.hideHeader
          ? <ContractHeader/>
          : null }
        { mappedContracts }
      </div>
    );
  }
}

export default ContractsList