import React, { Component } from 'react';
import { MessagesListItem } from './MessagesListItem'
import { MessageHeader } from './MessageHeader'
import './messages.css';
import SendMessage from '../Action/SendMessage';

class MessagesList extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }  

  close() {
    this.setState({ show: false });
    this.props.refresh()
  }

  render() {
    let mappedMessages = null; 
    let messages = this.props.messages
    if( messages ){
      messages.sort(function(a, b) {
        return new Date(b.date) - new Date(a.date)
      })
      mappedMessages = messages.map((message, index) =>{ 
        return (
          <MessagesListItem 
            message={message} 
            key={index} 
            all={ this.props.all } 
            user={ this.props.user } 
          />
        )
      })
    }

    return (
      <div id={this.props.all? "messagesLongList" : "messagesList"}>
        
        { this.props.all && 
          <MessageHeader all={ this.props.all }/>
        }

        { this.props.all
          ? null
          : <div className="sendM">
              <div id="noteText" onClick={()=>this.setState({show: !this.state.show})}>Send a message</div>
              <div id="noteAddOne" onClick={()=>this.setState({show: !this.state.show})}><i className="fas fa-paper-plane"></i></div>
            </div>
          }
        
        { mappedMessages }

        { this.props.all
          ? null
          : <SendMessage 
              show={this.state.show} 
              onHide={this.close.bind(this)}
              contract={this.props.contract}
            /> }
      </div>
    );
  }
}

export default MessagesList