import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import PaymentsList from './List/PaymentsList'

class PaymentsListController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      payments: undefined,
    };
  }
  componentDidMount() {
    this.loadData()
  }

	getPaymentsList() {
    let body = {
      query: { contract: this.props.contract._id },
      fromPage: 1,
      pageSize: 2000
    }
    axios.post(API_URL + '/payments/find/' + this.props.contract.supplier, body)
			.then((ret) =>  this.setState({ payments: ret.data.docs }) )
  }
  
  loadData() {
    this.getPaymentsList();
  }
  
  render() {    

    return (
      <div id="paymentsList" className='forContract'>
        { this.state.payments &&
          <PaymentsList 
            contract={ this.props.contract } 
            payments={this.state.payments}
            refresh={ this.props.refresh }
            supplierId={this.props.contract.supplier}
            user={ this.props.user }
            paymentStatus={'ALL'}
          /> }
      </div>
    );
  }
}

export default PaymentsListController