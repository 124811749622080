import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap/lib';
import './dealAction.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import AddAuxiliaryPricing from '../../AuxiliaryPricings/Action/AddAuxiliaryPricing'
import AddGuarantee from '../../AuxiliaryPricings/Action/AddGuarantee'


class EditPricings extends Component {

  constructor(props) {
    super(props);
    this.state= {
      auxiliaryPricings: this.props.deal.auxiliaryPricings,
      auxIncluded: [],
      addPricing: false,
      addGurantee: false,
      editPricing: false,
      ready: false
    }
  }; 

  componentDidMount() {
    this.getAuxiliaries()
    this.setState({ auxiliaryPricings: this.props.deal.auxiliaryPricings })
  }
  
  addPricing(auxiliary) {
    this.setState({ auxiliary }, () => {
      this.setState({ addPricing: true })
    });
  }

  editPricing(auxiliary){
    this.setState({ auxiliary }, () => {
      this.setState({ editPricing: true })
    }); 
  }

  addPricingClose() {
    this.setState({ addPricing: false });
    this.props.refresh();
    this.getAuxiliariesPricings()
  }

  close = () => {
    this.setState({ addGurantee: false }, () => this.getAuxiliariesPricings() )
  }

  getAuxiliaries = () => {
    axios.post(API_URL + '/auxiliaries/search/'+ this.props.user.supplier, {
      product: this.props.deal.eligibleProduct._id
    })
    .then( ret => this.setState({ auxiliaries: ret.data }) )
  }

  getAuxiliariesPricings = () => {
    axios.post(API_URL + '/auxiliaries/search-pricing/'+ this.props.user.supplier, {
      deal: this.props.deal._id
    })
    .then( ret => this.setState({ auxiliaryPricings: ret.data }) )
  }

  over(){
    this.setState({ status: undefined })
    this.props.onHide()
  }

  render() {    
    let { auxiliaries, auxiliaryPricings } = this.state ;
    let deal = this.props.deal

    let mappedPricings = <div id="dealPricingWarning">No auxiliary part or extension has yet been defined for { deal.eligibleProduct.name }. You can add auxiliaries to products on its detailed view.</div>
    let mappedGuarantees = <div id="dealPricingWarning">No guarantee</div>
    
    if( auxiliaries && auxiliaries.length > 0 ) {
      mappedPricings = auxiliaries && auxiliaries
      .sort((a, b) => a.name.localeCompare(b.name))
      .map( elem => { 
        let current = auxiliaryPricings && auxiliaryPricings.find( pricing => !pricing.guarantee && pricing.auxiliaries[0] && pricing.auxiliaries[0].reference == elem.reference )
        return ( 
          <div 
            key={ elem._id } 
            className="auxPricing fullAux"
          >
            <div id="auxPricingName">{ elem.name }</div>
            <div id="auxPricingCost"><span id="auxPricingLabel">Upfront</span>{ current? current.ccy + ' ' + current.cost : 'n/a' }</div>
            <div id="auxPricingCost"><span id="auxPricingLabel">Total</span>{ current && current.totalCost? current.ccy + ' ' + current.totalCost : 'n/a' }</div>
            
            { current
              ? <div className="auxPricingAction" onClick={()=>this.editPricing(elem)}><i className="fas fa-pen"></i> </div>
              : <div className="auxPricingAction" onClick={()=>this.addPricing(elem)}><i className="fas fa-plus-circle"></i> </div>}
            
          </div> 
        )
      })
    }

    mappedGuarantees = auxiliaryPricings && auxiliaryPricings
      .filter( elem => elem.guarantee )
      .map( elem => { 
        let parts = <span id="auxPricingGuranteeParts">All</span>
        if( elem.auxiliaries && elem.type == 'Partial' ) parts = elem.auxiliaries.map( aux => {
          return (
            <span id="auxPricingGuranteeParts">{aux.name}</span>
          )
        })
        return ( 
          <div 
            key={ elem._id } 
            className="auxPricingGuarantee fullAux"
          >
            <div id="auxPricingGuarantee">{ elem.lifeInMonths } months</div>
            <div id="auxPricingGuranteeLabel">covering parts:</div>
            { parts }
          </div> 
        )
      })

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Deal guarantees and costs of auxiliaries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">

            <div id="dealSubTitleLong">Guarantees</div>   
            <div className="auxPricingAction" onClick={()=>this.setState({ addGurantee: !this.state.addGurantee })}><i className="fas fa-plus-circle"></i> </div>
            
            { mappedGuarantees }

            { this.state.addGurantee && <AddGuarantee 
              auxiliaries={auxiliaries} 
              deal={deal} 
              user={this.props.user} 
              auxiliaryPricings={auxiliaryPricings}
              over={ this.close }  
            /> }
            
            <div id="dealSubTitleLong">Deal specific costs of auxiliaries</div>   
            
            { mappedPricings }
  
            <div id="modal_action">
              <Button id="action_cancel" onClick={() => this.over()}>Abort</Button>
            </div>


          </div>
        
        { this.state.addPricing && this.state.auxiliary &&
          <AddAuxiliaryPricing 
            show={ this.state.addPricing } 
            onHide={ this.addPricingClose.bind(this) }
            user={ this.props.user }
            deal={ this.props.deal }
            product={ this.props.deal.eligibleProduct._id }
            auxiliary={ this.state.auxiliary }
          /> }

        </Modal.Body>
      </Modal>
    );
  }
}

export default EditPricings