import React, { Component } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap/lib';
import './../agentInvoice.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
var DatePicker = require("react-bootstrap-date-picker");

class AgentInvoicesAction extends Component {

  constructor(props) {
    super(props);
    this.state= {
      agentInvoice: {
        dateFrom: this.props.agentInvoice? this.props.agentInvoice.dateFrom: undefined,
        dateTo: this.props.agentInvoice? this.props.agentInvoice.dateTo: undefined,
        paid: undefined,
        freq: undefined,
        agent: this.props.agent? this.props.agent._id : this.props.agentInvoice.agent,
        createdBy: this.props.agentInvoice? undefined : this.props.userNumber,
        editedBy: this.props.userNumber,
        comment: this.props.agentInvoice? this.props.agentInvoice.comment: undefined,
        amount: this.props.agentInvoice? this.props.agentInvoice.amount: undefined,
        ccy: this.props.agent? this.props.agent.ccy : this.props.agentInvoice.ccy,
        agentInvoiceNumber: this.props.agentInvoice? this.props.agentInvoice.agentInvoiceNumber : undefined,
      },       
      status: undefined,
      isDisabled: false,
    }
  }; 

  submit() {
    let supplier = this.props.supplier
    let action = 'createInvoice/'
    if( this.props.edit ) action = 'editInvoice/'
    axios.post(API_URL + '/agents/'+ action + supplier, this.state.agentInvoice)
      .then((ret) => this.setState({ status: 'Success!'}) )
      .catch(err => {
        console.error(err);
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success!'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  }  
  
  over(){
    this.props.onHide()
    this.setState({
      agentInvoice: {
        dateFrom: undefined,
        dateTo: undefined,
        freq: undefined,
        paid: undefined,
        agent: undefined,
        createdBy: undefined,
        amount: undefined,
        comment: undefined,
        ccy: undefined
      },       
      status: undefined,
      isDisabled: false,
    })
  }

  selectFreq = (ref) => {
    let agentInvoice = Object.assign({}, this.state.agentInvoice); 
    agentInvoice.freq = ref; 
    this.setState({ agentInvoice })
  }

  selectPaid = (bool) => {
    let agentInvoice = Object.assign({}, this.state.agentInvoice); 
    agentInvoice.paid = bool; 
    this.setState({ agentInvoice })
  }

  render() {
    
    let isDisabled = true    
    let item = this.state.agentInvoice
    if( item.freq && item.dateFrom && item.dateTo && item.amount ) isDisabled = false
    if( this.state.disabled ) isDisabled=true

    let navigate = <Button id="action_signup" disabled={isDisabled} onClick={this.submit.bind(this)}>Create</Button>
    if( this.props.edit ) navigate = <Button id="action_signup" onClick={this.submit.bind(this)}>Edit</Button>

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">
            { this.props.edit
              ? 'Edit item'
              : <div>Create new payroll item for agent { this.props.agent.agentNumber }</div> }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">

            <div className="agentTargetInputLabel">Type</div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentInvoice.freq == 'day'?'activeTOption': ''}
              onClick={()=>this.selectFreq('day')}
            >
              Daily
            </div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentInvoice.freq == 'week'?'activeTOption': ''}
              onClick={()=>this.selectFreq('week')}
            >
              Weekly
            </div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentInvoice.freq == 'month'?'activeTOption': ''}
              onClick={()=>this.selectFreq('month')}
            >
              Monthly
            </div>

            <div className="agentInvoiceInputLabel">Period</div>
            <div className="dateInvoice"  >
              <DatePicker 
                className ="agentInvoiceDateInput"
                placeholder="From"
                value={ this.state.agentInvoice.dateFrom }  
                onChange={(e)=>{ 
                  let agentInvoice = Object.assign({}, this.state.agentInvoice); 
                  agentInvoice.dateFrom = e; 
                  this.setState({ agentInvoice }) 
                }}
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />          
            </div>  
            <div className="dateInvoice"  >
              <DatePicker 
                className ="agentInvoiceDateInput"
                placeholder="To"
                value={ this.state.agentInvoice.dateTo }  
                onChange={(e)=>{ 
                  let agentInvoice = Object.assign({}, this.state.agentInvoice); 
                  agentInvoice.dateTo = e;  
                  this.setState({ agentInvoice }) 
                }}
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />          
            </div>   

            <div className="agentInvoiceInputLabel">Amount</div>
            <FormControl 
              type="number" 
              className="agentInvoiceInputMedium"     
              placeholder={'Type'}
              value={ this.state.agentInvoice.amount }
              onChange={(e)=>{ let agentInvoice = Object.assign({}, this.state.agentInvoice); agentInvoice.amount = e.target.value; this.setState({ agentInvoice }) }}
            />
            <div className="agentInvoiceInputMedium">{ this.props.edit? this.props.agentInvoice.ccy: this.props.agent.ccy }</div>

            <div className="agentTargetInputLabel">Paid</div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentInvoice.paid == true?'activeTOption halfInvoice': 'halfInvoice'}
              onClick={()=>this.selectPaid(true)}
            >
              True
            </div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentInvoice.paid == false?'activeTOption halfInvoice': 'halfInvoice'}
              onClick={()=>this.selectPaid(false)}
            >
              False
            </div>

            <div className="agentInvoiceInputLabel">Comment</div>
            <textarea 
              type="number" 
              className="agentInvoiceInputLong"     
              placeholder={'Type'}
              value={ this.state.agentInvoice.comment }
              onChange={(e)=>{ let agentInvoice = Object.assign({}, this.state.agentInvoice); agentInvoice.comment = e.target.value; this.setState({ agentInvoice }) }}
            />

          </div>


          { this.renderMessage() }            
          <br/>
          <div id="modal_action">
            { this.state.status==='Success!'
              ? null
              : <div>{ navigate }</div> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AgentInvoicesAction