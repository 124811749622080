import React, { Component } from 'react';
import InfoWrapper from './../Utils/InfoWrapper'
import history from './../../../history';

import './asset.css';

class AssetSummary extends Component {
  
  handleNext(path){
    history.push(path)
  } 

  findLogo = ( asset ) => {

    let image = <div id="assetPicture"><i className="fas fa-solar-panel"></i></div>
    if(asset.productDetails.category === "Communication"){
      image = <div id="assetPicture"><i className="fas fa-mobile-alt"></i></div>
    }  
    if(asset.productDetails.category === "Agri"){
      image = <div id="assetPicture"><i className="fas fa-tractor"></i></div>
    }    
    if(asset.productDetails.category === "Entertainment"){
      image = <div id="assetPicture"><i className="fas fa-tv"></i></div>
    }  
    if(asset.productDetails.category === "Cooking"){
      image = <div id="assetPicture"><i className="fas fa-temperature-high"></i></div>
    }    
    if(asset.productDetails.category === "Water"){
      image = <div id="assetPicture"><i className="fas fa-tint"></i></div>
    }    
    if(asset.productDetails.category === "Consumables"){
      image = <div id="assetPicture"><i className="fas fa-wine-bottle"></i></div>
    }  

    return image

  }

  render() {    
    
    let { asset, assets, linkedContracts } = this.props

   

    let fullImage = this.findLogo(asset)
    
    if( linkedContracts && linkedContracts[0] ) {
      fullImage = 
        <div id='fullImage'>
          <div id='halfImage'>{this.findLogo(asset)}</div>
          <div id='halfImage'>
            {this.findLogo(linkedContracts[0].asset)}
            <div onClick={()=>this.handleNext('/contract/id='+linkedContracts[0].contractNumber)} id='linkedImageLink'>
              {linkedContracts[0].contractNumber}
            </div>
          </div>
        </div>
    } else if( assets && assets[1] ) {
      fullImage = 
        <div id='fullImage'>
          <div id='halfImage'>{this.findLogo(asset)}</div>
          <div id='halfImage'>
            {this.findLogo(assets[1])}
          </div>
        </div>
    }

    return (
      <div id="assetSummary">
        <div className="active" onClick={()=>this.handleNext('/asset/id='+asset.assetNumber)}>
          <InfoWrapper 
            label={"Asset number "}
            isActive={ true }
            data={ asset.assetNumber }
          />
        </div>
        <InfoWrapper 
          label={"Asset type"}
          data={asset.productDetails.name}
        />

        { asset && asset.paygNumber && (!assets || !assets.length) &&
         <div className="infoWrapper">
            <p id="infoLabel">Payg number</p>
            <p id="infoDataMain">{ asset.paygNumber? asset.paygNumber: asset.shortNumber } </p>
          </div> }

        { assets && assets[0] && assets[0].paygNumber &&
         <div className="infoWrapper">
            <p id="infoLabel">Payg number 1</p>
            <p id="infoDataMain">{ assets[0].paygNumber } </p>
          </div> }

        { assets && assets[1] && assets[1].paygNumber &&
         <div className="infoWrapper">
            <p id="infoLabel">Payg number 2</p>
            <p id="infoDataMain">{ assets[1].paygNumber } </p>
          </div> }
        
        {!assets &&
          <InfoWrapper 
            label={"Serial number"}
            data={ asset.serialNumber}
          /> }

        <InfoWrapper 
          label={"Manufacturer"}
          data={asset.productDetails.manufacturer}
        />
        <InfoWrapper 
          label={"Status"}
          data={asset.status}
        />
        { asset.heldBy && asset.heldBy.profile && asset.heldBy.profile.firstName
          ? <div className="active" onClick={()=>this.handleNext('/agent/id='+asset.heldBy.agentNumber)}>
            <InfoWrapper 
                label={"Held by"}
                isActive={ true }
                data={asset.heldBy.profile.firstName.slice(0,1) + '. ' + asset.heldBy.profile.lastName}
              />
            </div>
          : null }
        {fullImage}
      </div>
    );
  }
}

export default AssetSummary