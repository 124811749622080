import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index'
import './../item.css'

class Build extends Component {

  constructor(props) {
    super(props);
    this.state= {
      item: {
        auxiliary: undefined,
        status: undefined,
        type: this.props.type,
        quantity: undefined,
        note: undefined,
        supplier: this.props.user.supplier,
        "mgmt.enteredBy": this.props.user._id
      },       
      status: undefined,
      disabled: false
    }
  }; 
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }
  
  createItem() {
    this.setState({disabled: true})
    axios.post(API_URL + '/items/create/'+ this.props.user.supplier, this.state.item)
      .then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        this.setState({status: err.data.message })
      }); 
  }

  render() {    
 
    let isDisabled = ( !this.state.item.auxiliary || !this.state.item.quantity || !this.state.item.status )? true : false
    if( this.state.disabled ) isDisabled = true

    let mappedAux = this.props.auxiliaries && this.props.auxiliaries
      .sort((a, b) => a.product.name.localeCompare(b.product.name))
      .map( aux =>{ 
        return (
          <option key={aux._id} value={aux._id} >{ aux.product.name } -- { aux.name }</option>
        )
      })

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">
            Build new asset from parts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="modalBody">
            
            <textarea 
              type="textera" 
              className="productFull"
              placeholder={'Note (optional)'}
              onChange={(e)=>{ let item = Object.assign({}, this.state.item); item.description = e.target.value; this.setState({ item }) }}
            />

            { this.renderMessage() }  

            { this.state.status !== 'Success' &&
              <div id="productsExtraSmall2">
                <Button id="createProduct" disabled={isDisabled} onClick={()=>this.createItem(this.state.item)}>Create</Button>
              </div> }

          </div>
        
        </Modal.Body>
      </Modal>
    );
  }
}

export default Build