import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';

class TransferPayment extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false,
    };
  } 

  reverse(){

    var query = {
      newContractNumber: this.state.newContractNumber,
      reference: this.state.reference,
      transactionId: this.props.transactionId,
      userId: this.props.user._id,
      recordedBy: this.props.user.userNumber,
      note: this.state.note
    }

    this.setState({disabled: true, status: 'One second...'})

    axios.post(API_URL + '/payments/reverse/' + this.props.supplierId, query)
			.then( ret => this.setState({ status: ret.data.message }) )
			.catch( err => {
        console.log(err)
      })
    }

  over(){
    this.props.onHide();
    this.setState({status: undefined, disabled: false});
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  render() {
    let disabled = true;
    if( !this.state.disabled && this.state.newContractNumber){
      disabled = false
    }
    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Payment transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">
          <div id="reversalDetails">
            Please enter the contractNumber of the contract to which payment { this.props.transactionId } should be transfered
          </div>
          <FormControl 
            type="text" 
            className="contractEditInput"
            placeholder="Type contractNumber"
            onChange={(e)=>{ this.setState({ newContractNumber: e.target.value }) }}
          />
        </div>
        <div id="reversalDetails" style={{marginTop: '15px'}}>
          Upon successful transfer, a new activation code will be sent to the mobile number who originally made this payment.
          <br/><br/>
          The activation code will activate the device associated with the above-entered contract
          <br/><br/>
          Contract { this.props.contractNumber } will revert to its pre-payment state.
        </div>
        <div id="paymentRecapList">
            <div id="paymentRecapLabel" className="paymentHigh">Add note</div>
            <textarea
              type="text" 
              className="paymentRecapNote"     
              placeholder={'Type'}
              value={ this.state.note }
              onChange={(e)=>{ this.setState({ note: e.target.value}) }}
            />
          </div>
        {this.renderMessage()}            
        <br/>
        <div id="modal_action">
          { this.state.status
            ? null
            : <Button id="confirm_action" disabled={disabled} onClick={()=>this.reverse()}>Transfer</Button>
          }
          <Button id="action_cancel" onClick={() => this.over()}>
            { this.state.status? 'Close' : 'Abort' }
          </Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default TransferPayment;