import React, { Component } from 'react';
var addDays = require('date-fns/add_days');
var format = require("date-fns/format");

class Item extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }

  select( timeframe, qualityFrom ){
    this.props.breakdown( undefined, 'InUse', 'DEPLOYED', this.props.auxiliary._id, timeframe, qualityFrom )
  }
  
  render() {
    var { items, auxiliary } = this.props
    let current = format( new Date(), 'YYYY-MM')
    let last = format( addDays(new Date(), -30), 'YYYY-MM')
    let currentNew = items
      .filter( elem => elem._id.date == current &&  elem._id.qualityFrom == 'New')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let currentSecond = items
      .filter( elem => elem._id.date == current && elem._id.qualityFrom == 'SecondHand')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let lastNew = items
      .filter( elem => elem._id.date == last && elem._id.qualityFrom == 'New')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let lastSecond = items
      .filter( elem => elem._id.date == last && elem._id.qualityFrom == 'SecondHand')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let allNew = items
      .filter( elem => elem._id.qualityFrom == 'New')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let allSecond = items
      .filter( elem => elem._id.qualityFrom == 'SecondHand')
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
    let all = items
      .filter( elem => elem )
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)

    return (
      <div className="itemItem" >
        <div id="itemAux">{ auxiliary? auxiliary.product.name + ' -- ' + auxiliary.name: '-' }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select( 'Current', 'New' )}>{ currentNew }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select( 'Current', 'SecondHand' )}>{currentSecond}</div>
        <div id="itemTotal" className="active" onClick={()=>this.select( 'Last', 'New' )}>{ lastNew }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select( 'Last', 'SecondHand' )}>{lastSecond}</div>
        <div id="itemTotal" className="active" onClick={()=>this.select( undefined, 'New' )}>{ allNew }</div>
        <div id="itemTotal" className="active" onClick={()=>this.select( undefined, 'SecondHand' )}>{allSecond}</div>
        <div id="itemTotal" className="active" onClick={()=>this.select( undefined, 'All' )}>{all}</div>
      </div>
    )
  }  
}

export default Item 