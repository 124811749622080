import React, { Component } from 'react';
var addDays = require('date-fns/add_days');
var format = require("date-fns/format");

class Item extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }

  select( timeframe ){
    this.props.breakdown( undefined, 'InUse', 'DEPLOYED', this.props.auxiliary._id, timeframe )
  }
  
  render() {
    var { items, auxiliary } = this.props
    let current = format( new Date(), 'YYYY-MM')
    let last = format( addDays(new Date(), -30), 'YYYY-MM')
    let currentNew = items
      .filter( elem => elem._id.date == current )
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
   
    let lastNew = items
      .filter( elem => elem._id.date == last)
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)
   
    let all = items
      .filter( elem => elem )
      .reduce( function(acc, elem) { return acc + elem.total; }, 0)

    return (
      <div className="itemItem" >
        <div id="itemAux">{ auxiliary? auxiliary.product.name + ' -- ' + auxiliary.name: '-' }</div>
        <div id="itemLargeTotal" className="active" onClick={()=>this.select( 'Current' )}>{ currentNew }</div>
        <div id="itemLargeTotal" className="active" onClick={()=>this.select( 'Last' )}>{ lastNew }</div>
        <div id="itemLargeTotal" className="active" onClick={()=>this.select( undefined )}>{all}</div>
      </div>
    )
  }  
}

export default Item 