import React, { Component } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap/lib';
import './../agent.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import countryDirectory from './../../../../config/countryDirectory'
import { SelectEntity } from './../../Entities/Action/SelectEntity'

class CreateAgent extends Component {

  constructor(props) {
    super(props);
    this.state= {
      agent: {
        profile: {},
        contact: {},
        supplier: this.props.user.supplier,
        createdBy: this.props.user._id,
        entity: this.props.user.entity
      },       
      isDisabled: false,
    }
  }; 

  submit() {
    let supplier = this.props.user.supplier
    axios.post(API_URL + '/agents/create/'+ supplier, this.state.agent)
      .then((ret) => {
        this.setState({ status: ret.data.message }) 
      }).catch(err => {
        this.setState({ status: err.message })
      }); 
  }

  renderMessage() {
    if( this.state.status ) {
      return (
        <div id={this.state.status==='Success'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  }  
  
  over(){
    this.props.onHide()
    this.setState({
      status: undefined, 
      isDisabled: false, 
      agent: {
        profile:{
          firstName: undefined,
          lastName:undefined,
          language: undefined
        },
        country: undefined,
        organisation: undefined,
        email: undefined,
        contact:{
          mobile:undefined,
          email:undefined
        },
        ccy: undefined,
        cashCollection: undefined,
        cashLimit: undefined,
        canRegisterAgents: undefined,
        assignedPwd: undefined,
        role: undefined,
      }
    })
  }

  warning() {
    if (this.state.agent.assignedPwd != this.state.confirmPwd) {
      return (
        <div id={'red'}>
          Please confirm temporary password
        </div>
      );
    }
  } 

  selectEntity = (entity) => {
    let agent = Object.assign({}, this.state.agent); 
    agent.entity = entity
    this.setState({ agent }) 
  }

  render() {
    
    let { agent } = this.state
    let user = this.props.user
    let entities = user.entityOptions
    let options = user.options

    let isDisabled = (
      agent.email && 
      agent.role && 
      agent.contact.countryCode && 
      agent.contact.mobile && 
      !isNaN(agent.contact.mobile) && 
      agent.assignedPwd && 
      (agent.assignedPwd == this.state.confirmPwd) && 
      agent.profile.lastName && 
      agent.profile.firstName && 
      agent.country &&
      ( !entities || entities.length < 2 || agent.entity )
    )  ? false : true

    if(this.state.disabled) isDisabled = true   

    let warning = null
    if( isNaN(agent.contact.mobile) && agent.contact.mobile ){
      warning = <div id='failedAction'>Agent phone number must only include digits (no space)</div>
    }
    let wrongError = null
    if( agent.assignedPwd !== this.state.confirmPwd && this.state.confirmPwd){
      wrongError = <div id='failedAction'>Passwords are different</div>
    }
    let navigate = <Button id="action_signup" disabled={isDisabled} onClick={this.submit.bind(this)}>Create</Button>
    if(this.props.edit){
      navigate = <Button id="action_signup" onClick={this.submit.bind(this)}>Edit</Button>
    }

    let codeOptions = options && options.countries.map( (country, index) => {
        return <option key={index} value={country}>{ country }</option>
      })

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">{this.props.edit?'Edit':'Create'} agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">

            <div className="userLabel">Firstname</div>
            <FormControl 
              type="text" 
              className="userInput"
              placeholder={'First name'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.profile.firstName = e.target.value; this.setState({ agent }) }}
            />

            <div className="userLabel">Lastname</div>
            <FormControl 
              type="text" 
              className="userInput"           
              placeholder={'Last name'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.profile.lastName = e.target.value; this.setState({ agent }) }}
            />

            <div className="userLabel">Email</div>
            <FormControl 
              type="text" 
              className="userInput"             
              placeholder={'Email'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.email = e.target.value; this.setState({ agent }) }}
            />

            <div className="userLabel">Role</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="userInput"
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.role = e.target.value; this.setState({ agent }) }}
            > 
              <option value="">Choose role</option>
              <option value="Agent">Agent</option>
              { options && options.customerServiceAgents && <option value="CustomerService">Customer Service</option> }
              <option value="Operator">Operator</option>
              { options && options.agentManagers && <option value="Manager">Manager</option> }
              <option value="Administrator">Warehouse</option>
              <option value="Disabled">Disable</option>
            </FormControl>

            <div className="userLabel">Country code</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="userInput"
              onChange={(e)=>{ 
                let agent = Object.assign({}, this.state.agent); 
                agent.country = e.target.value; 
                agent.contact.countryCode = countryDirectory[e.target.value].countryCode

                this.setState({ agent });
                }}
              >
              <option value="">Code</option>
              { codeOptions }
            </FormControl>

            <div className="userLabel">Mobile</div>
            <FormControl 
              type="text" 
              className="userInput"     
              placeholder={'Mobile'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.contact.mobile = e.target.value; this.setState({ agent }) }}
            />

            <div className="userLabel">Temp. password</div>
            <FormControl 
              type="password" 
              className="userInput"
              placeholder={'Assign'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.assignedPwd = e.target.value; this.setState({ agent }) }}
            />
            <div className="userLabel">Confirm</div>
            <FormControl 
              type="password" 
              className="userInput"
              placeholder={'Confirm password'}
              onChange={(e)=>this.setState({confirmPwd: e.target.value})}
            />

            { entities && entities.length > 1 &&
              <div>
                <div className="userLabel">Entity</div>
                <SelectEntity
                  selectEntity={ this.selectEntity } 
                  format={ "userInput" }
                  supplierId={ this.props.user.supplier }
                  userId={ this.props.user._id }
                />
              </div> }
          
          </div>

          { options && options.agentManagers && agent.role == 'Manager' &&
              <div>
                <div className="userLabel">Agent registration</div>
                <FormControl 
                    controlId="formControlsSelect"
                    componentClass="select" 
                    className="userInput"
                    onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.canRegisterAgents = e.target.value; this.setState({ agent }) }}
                  > 
                    <option value="">Select</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </FormControl>  
              </div> }

            { options && agent.country && options.cashCollection == true
            ? <div id="dealSection">
                <div className="userLabel">Cash collection</div>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="userInput"
                  onChange={(e)=>{ 
                    let agent = Object.assign({}, this.state.agent); 
                    agent.cashCollection = e.target.value; 
                    agent.ccy = countryDirectory[this.state.agent.country].ccy
                    this.setState({ agent }) 
                  }}
                > 
                  <option value="">Choose</option>
                  <option value={true}>Permitted</option>
                  <option value={false}>Not permitted</option>
                </FormControl>

                <div className="userLabel">with limit</div>
                <FormControl 
                  type="number" 
                  className="userInput"
                  placeholder={'Leave blank if no limit'}
                  onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.cashLimit = e.target.value; this.setState({ agent }) }}
                />
              
              </div>
            : null
            }

            { warning }
            { wrongError }

            { this.renderMessage() }            

            <div id="modal_action">
              { this.state.status !=='Success' &&
                <div>{navigate}</div> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>

        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateAgent