import React from 'react';
import { Button, FormControl } from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { findAgents } from  '../../../../services/AgentService'

var addMonths = require('date-fns/add_months');
var differenceInDays = require("date-fns/difference_in_days");

class ReplacePart extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      replace:{
        asset: this.props.asset._id,
        agent: this.props.contract.agent? this.props.contract.agent: undefined,
        contract: this.props.contract._id,
        user: this.props.user._id,
      },
      disabled: false,
    };
  } 

  componentDidMount() {
    this.loadData()
  }

  replace() {
    this.setState({ disabled: true }, () => {
      axios.put(API_URL + '/items/replace/'+ this.props.user.supplier, this.state.replace)
			.then((ret) => {
        this.setState({ status: ret.data.message })
      })
      .catch( err => {
        this.setState({  status: 'Error! Please try again.' })
      })
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'} >
          {this.state.status}
        </div>
      );
    }
  }  

	getParts = () => {
     axios
      .post(API_URL + '/auxiliaries/search/' + this.props.user.supplier, {
        product: this.props.contract.product
      })
      .then((ret) => this.setState({ parts: ret.data }))
  }

	getPricings = (auxiliary) => {
    axios
     .post(API_URL + '/auxiliaries/search-pricing/' + this.props.user.supplier, {
        "$or": [{
          auxiliaries: auxiliary,
          deal: this.props.contract.deal
        }, {
          auxiliaries: auxiliary,
          deal: { $exists: false }
        }, {
          type: "Full",
          deal: this.props.contract.deal
        }]
     })
     .then((ret) => this.setState({ pricings: ret.data }))
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getParts();
    this.getAgents()
  }  

  render() {
    
    let { parts, pricings, replace, agents, guaranteeIsValid } = this.state
    let contract = this.props.contract

    let mappedParts = parts && parts.sort((a, b) => a.name.localeCompare(b.name)).map( elem =>{ 
      return (
        <option key={elem._id} value={elem._id}>{ elem.name }</option>
      )
    })

    let mappedPricings = pricings && replace.auxiliary && pricings.map( pricing =>{ 
      return (
        <option key={pricing._id} value={pricing.reference}>{ pricing.name }</option>
      )
    })

    let mappedAgents = agents && agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
      return (
        <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
      )
    })

    let isDisabled = ( replace.auxiliaryPricing && replace.replacedPart ) ? false : true
    if( this.state.disabled ) isDisabled = true 
  
    let auxPricing = pricings && pricings.find( elem => elem.reference + '' == replace.auxiliaryPricing + '' )
    let agentProfile = contract.agent? contract.agent.profile : {}

    return (
      <div id="assetEditSection">
        
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          placeholder="Confirm agent"
          className="contractXSInput"
          onChange={(e)=>{ 
            let replace = Object.assign({}, this.state.replace); 
            replace.auxiliary = e.target.value
            let guaranteeIsValid = undefined
            this.getPricings(e.target.value)
            this.setState({ replace, guaranteeIsValid })
          }}
          >
          <option value="">Select part</option>
          { mappedParts }
        </FormControl>

        { replace.auxiliary && pricings &&
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="contractXSInput"
            onChange={(e)=>{ 
              let replace = Object.assign({}, this.state.replace); 
              replace.auxiliaryPricing = e.target.value;
              let chosenPricing = pricings.find( elem => elem.reference + '' == e.target.value + '' )
              let guaranteeIsValid = undefined
              if( 
                chosenPricing && 
                differenceInDays( new Date(), addMonths( new Date( contract.signingDate ), chosenPricing.lifeInMonths ))  <= 0
              ) guaranteeIsValid = true
              this.setState({ replace, guaranteeIsValid });
              }}
            >
            <option value="">Select pricing</option>
            { mappedPricings }
          </FormControl> }

        { auxPricing && replace.auxiliary && auxPricing.guarantee && guaranteeIsValid === true &&
          <div>
            <div id="replacementWrapper">
              <div id="contractXSLabel">Agent replacing part</div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                placeholder="Confirm agent"
                className="contractXSInput"
                onChange={(e)=>{ 
                  let replace = Object.assign({}, this.state.replace); 
                  replace.agent = e.target.value
                  this.setState({ replace });
                  }}
                >
                <option value="">Confirm agent</option>
                <option value={ contract.agent._id }>{agentProfile.firstName} {agentProfile.lastName}</option>
                { mappedAgents }
              </FormControl>
            </div>
            <div id="replacementWrapper">
              <div id="contractXSLabel">State of replaced part</div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                placeholder="Confirm agent"
                className="contractXSInput"
                onChange={(e)=>{ 
                  let replace = Object.assign({}, this.state.replace); 
                  replace.replacedPart = e.target.value
                  this.setState({ replace });
                  }}
                >
                <option value="">Select one</option>
                <option value="Missing">Missing</option>
                <option value="ToCheck">To check</option>
              </FormControl>
            </div> 
          </div>}
        
        { auxPricing && replace.auxiliary &&
          <div>
            { auxPricing.cost > 0 &&
              <div>
                <div id="replacementRes">Client needs to pay { auxPricing.ccy } {auxPricing.cost } with reference</div>
                <div className="replacementResPayment">
                  { auxPricing.reference }@{contract.contractNumber}
                </div>
              </div> }
            { auxPricing.totalCost > 0 && auxPricing.totalCost > auxPricing.cost &&
              <div>
                <div id="replacementRes">After this payment, the debt linked to this contract will increase by</div>
                <div className="replacementResPayment">
                    { auxPricing.ccy } { auxPricing.totalCost - auxPricing.cost }
                </div>
              </div> }
            { auxPricing.totalCost == 0 &&
              <div id="replacementRes">Replacement free of charge</div> }

            { auxPricing.guarantee && guaranteeIsValid !== true &&
              <div id="replacementRes" className="replacementResRed">Guarantee has expired!</div> }
          
          </div> }

        { this.renderMessage() }
        
        <div id="modal_action">
          { !this.state.status && 
            guaranteeIsValid &&
            auxPricing && 
            ( auxPricing.guarantee || auxPricing.totalCost == 0 ) && replace.agent &&
            <Button id="action_signup" disabled={isDisabled} onClick={this.replace.bind(this)}>Use guarantee</Button> }
        </div>

      </div>
    );
  }
}

export default ReplacePart;