import React from 'react';
import {Button, Modal, FormControl} from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';
import { emptyList } from './../../../../Actions/index';
import { connect } from 'react-redux';
import { findAgents } from  './../../../../services/AgentService'

class Scan extends React.Component {

  constructor(props) {
		super(props)
		this.state = {};
  } 
  
  componentDidMount() {
    this.loadData()
  }

  scanAsset() {
    var body = {
      assets: [this.props.asset.assetNumber],
      agent: this.state.agent,
      author: this.props.user.userNumber
    }
    axios.put(API_URL + '/assets/confirm/' + this.props.asset.supplier, body)
			.then((ret) => {
        this.setState({
          status: ret.data.message
        })
      })
      .then(()=>{
        this.props.emptyList()
      })
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getAgents();
  }  

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  
  
  over(){
    this.props.onHide();
    this.props.getEvents();
    this.setState({
      status: undefined,
      agents: undefined,
      agent: undefined
    })
  }

  render() {
    let mappedAgents = null
    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })
    }
    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Confirm asset is in stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">  
            <div>Assign to an agent? (optional)</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="contractInput"
              onChange={(e)=>{ 
                this.setState({ agent: e.target.value });
                }}
              >
              <option value="">Choose a new agent</option>
              { mappedAgents }
            </FormControl>        
          {this.renderMessage()}
            <div id="modal_action">
              { this.state.status == 'Success'
                ? null
                : <Button id="action_signup" onClick={this.scanAsset.bind(this)}>Confirm</Button> }
              <Button id="action_cancel" onClick={()=> this.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
            </div>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const ScanAsset = connect(
  function mapStateToProps(state) {
    return { 
      selectedAssets: state.selectedAssets
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Scan);


export default ScanAsset;