import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../Actions/index';
import { I18n } from 'react-i18next';

class EditTask extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      note: {
        text: undefined,
        title: undefined,
        type: undefined,
        author: this.props.user._id
      },
      status:undefined,
    };
  } 
  componentDidMount() {
    this.setState({status: undefined})
  } 

  send(){
    var body = {
      note: this.state.note
    }

    axios.put(API_URL + '/notes/edit/' + this.props.note.supplier + '/' + this.props.note._id, body)
      .then((ret) => {
        this.setState({status: 'Success!' })
      })
  }
  
  over(){
    this.setState({
      note: {
        text: undefined,
        title: undefined,
        type: undefined
      },
      status:undefined,      
    })
    this.props.refresh( this.props.noteView, this.props.user._id, 1 )
    this.props.onHide()
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  render() {

    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header >
            <Modal.Title id="contained-modal-title-sm">Edt message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">
            <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="agentEditInput"
                onChange={(e)=>{ let note = Object.assign({}, this.state.note); note.type = e.target.value; this.setState({ note }) }}
              > 
                <option value={this.props.note.type}>{this.props.note.type}</option>
                <option value="Task">Task</option>
                <option value="Info">Info</option>
              </FormControl>
              <FormControl 
                type="text" 
                className="agentEditInput"
                value={this.state.note?this.state.note.title:this.props.note.title}
                placeholder={this.props.note.title}
                onChange={(e)=>{ let note = Object.assign({}, this.state.note); note.title = e.target.value; this.setState({ note }) }}
              />
              <textarea 
                type="text" 
                className="agentMessageInput"
                value ={
                  this.state.note&&this.state.note.text ? this.state.note.text : (
                    this.props.note ? this.props.note.text : 'type'
                  )
                }
                placeholder={this.props.note ? this.props.note.text : "Type message"}
                onChange={(e)=>{ let note = Object.assign({}, this.state.note); note.text = e.target.value; this.setState({ note }) }}
              />   
            </div>
            {this.renderMessage()}            
            <br/>
            <div id="modal_action">
              {this.state.status==='Success!'
                ? null
                : <Button id="action_signup" onClick={this.send.bind(this)}>Confirm</Button>
              }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default EditTask;