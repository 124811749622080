import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { I18n } from 'react-i18next';

class SendMessage extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      message: {
        message: undefined,
        contractId: this.props.contract._id
      },
      status: undefined,
      answer: undefined
    };
  } 
  
  handleChange(){
    axios.post(API_URL + '/messages/sendone/' + this.props.contract.supplier, this.state.message)
			.then((ret) => {
        this.setState({
          status: 'Success',
          answer: ret.data.message
        })
      })
      .catch(err => this.setState({ status: 'Subscription not on' }) )
  }

  renderMessage() {
    if ( this.state.status ) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status} {this.state.answer}
        </div>
      );
    }
  }  

  over(){
    this.props.onHide()
    this.setState({
      message: {
        message: undefined,
        contractId: this.props.contract._id
      },
      status: undefined,
      answer: undefined
    })
  }
  
  render() {
    let disabled = true;
    if( this.state.message.message && !this.state.status ){
      disabled = false
    }
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Send a message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">
              <textarea 
                className="editMessageInput"
                placeholder="Type"
                onChange={(e)=>{ 
                  let message = Object.assign({}, this.state.message); 
                  message.message = e.target.value; 
                  this.setState({ message }) 
                }}
              />   
            </div>
            {this.renderMessage()}            
            <br/> 
            <div id="modal_action">
              { this.state.status == 'Success'
                  ? null
                  : <Button id="action_signup" disabled={disabled} onClick={()=>this.handleChange(this.state.message)}>Send</Button> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default SendMessage;