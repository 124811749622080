import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { I18n } from 'react-i18next';
import { connect } from 'react-redux';


class Pay extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status: undefined
    };
  } 
 
  componentDidUpdate(prevProps) {
    if (this.props.selectedPayrolls !== prevProps.selectedPayrolls) {
      this.setState({
        status: undefined
      });
    }
  }
  

  componentDidMount() {
    this.setState({
      status: undefined, 
    });  
  }

  handleChange(){
    var body = {
      listOfIds: this.props.selectedPayrolls,
      user: this.props.user
    }
    this.setState({ disabled: true, status: 'One second...' })

    axios.put(API_URL + '/agents/pay-invoices/' + this.props.user.supplier, body)
			.then((ret) => {
        this.setState({ status: ret.data.message })
      })
  }

  over(){
    this.props.onHide();
    this.setState({
      status: undefined, 
    });
  }

  renderMessage() {
    if (this.state.status && this.state.status !== 'One second...' ) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }   

  render() {
    
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Mark as paid these {this.props.selectedPayrolls.length} payrolls</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            { this.renderMessage() }            
            
            <br/>
            <div id="modal_action">
              { this.state.status =='Success'
                ? null
                : <Button id="action_signup"  onClick={()=>this.handleChange()}>Paid</Button>
              }
              <Button id="action_cancel" onClick={() => this.over()}>
                {this.state.status == 'Success'?'Close':'Abort'}
              </Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}


const PayPayrolls = connect(
  function mapStateToProps(state) {
    return { 
      selectedPayrolls:state.selectedPayrolls
    };
  }
)(Pay);

export default PayPayrolls;