import React, { Component } from 'react';
import { AgentInvoicesListItem } from './AgentInvoicesListItem'
import './agentInvoices.css';

class AgentInvoicesList extends Component {
  
  render() {    
    let mappedAgentInvoices = null;
    let data = this.props.agentInvoices
    if( data ){
      mappedAgentInvoices = data.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      }).map((agentInvoice, index) =>{ 
        return (
          <AgentInvoicesListItem 
            agentInvoice={ agentInvoice } 
            key={ index }
            refresh={ this.props.refresh } 
            supplier={ this.props.supplier }
            user={ this.props.user }
            edit={ this.props.edit }
          />
        )
      })
    }

    return (
      <div>
        { mappedAgentInvoices }
      </div>
    );
  }
}

export default AgentInvoicesList