import React, { Component } from 'react';
import Item from './Item'
import DeployedItem from './DeployedItem'
import ExtensionItem from './ExtensionItem'
import Header from './Header'
import DeployedHeader from './DeployedHeader'
import ExtensionHeader from './ExtensionHeader'


class ItemsList extends Component {

  render() {    

    let { items, auxiliaries, view, showDetails, breakdown, type } = this.props

    let mappedItems = auxiliaries && auxiliaries.map( (auxiliary, index) =>{ 
      let filterItems = items && items.filter( elem => elem._id.auxiliary + '' == auxiliary._id + '')
      return (
        <div>
          { view !== 'DEPLOYED' && <Item items={filterItems} key={ index } auxiliary={auxiliary} showDetails={showDetails}/> }
          { view == 'DEPLOYED' && type == 'Part' && <DeployedItem items={filterItems} key={ index } auxiliary={auxiliary} breakdown={breakdown}/> }
          { view == 'DEPLOYED' && type == 'Extension' && <ExtensionItem items={filterItems} key={ index } auxiliary={auxiliary} breakdown={breakdown}/> }
        </div>
      )
    })
    
    return (
      <div id="itemsList">
        { view !== 'DEPLOYED' && <Header/> }
        { view == 'DEPLOYED' && type == 'Part' && <DeployedHeader/> }
        { view == 'DEPLOYED' && type == 'Extension' && <ExtensionHeader/> }
        { mappedItems }
        { view == 'DEPLOYED' && type == 'Part' && mappedItems && mappedItems.length > 0 && <div id="partAst">(*) All parts affected to 'New' unless specified otherwise</div>}
      </div>
    );
  }
}

export default ItemsList