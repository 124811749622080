import React, { Component } from 'react';
import {AssetHeader} from './AssetHeader'
import {AssetsListItem} from './AssetsListItem'
import './../List/assets.css'


class AssetsList extends Component {

  render() {    
    let mappedAssets = null;
    if(this.props.assets){
      mappedAssets = this.props.assets
        .sort(function(a, b) {
          return new Date(b.heldSince) - new Date(a.heldSince)
        })
        .map((asset, index) =>{ 
          return (
            <AssetsListItem asset={asset} key={index}/>
          )
        })
    }

    return (
      <div className="lightList subright">
        { this.props.assets && !this.props.hideHeader
          ? <AssetHeader/> 
          : null }
        { mappedAssets }
      </div>
    );
  }
}

export default AssetsList