import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL, emptyList } from '../../../../Actions/index';
import './../../Utils/utils.css';
import { connect } from 'react-redux';
import { SelectAgent } from './../../Agents/Action/SelectAgent'

class AssetAction extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false,
    };
  } 

  componentDidMount() {}
  
  handleChange(){
    var query = { 
      assets: this.props.selectedAssets,
      agent: this.state.agent,
      //all: true,
      author: this.props.user.userNumber
    }
    this.setState({ disabled: true, status: 'One second...'})
    axios.put(API_URL + '/assets/'+this.props.action +'/'+this.props.user.supplier, query)
			.then((ret) => {
        this.setState({status: ret.data.message })
      })
      .then(()=>{
        this.props.emptyList()
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  
  selectAgent = (agent) => this.setState({ agent }) 

  over(){
    this.props.onHide();
    this.setState({ disabled: false, status: undefined })
  }

  render() {
    let { agent, status } = this.state
    let { user, action, selectedAssets } = this.props

    let disabled = !agent? true: false

    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Are you sure to { action } these { selectedAssets.length } asset(s)?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">
        { this.props.action == 'delete'
          ? <div id="warningTransferClients" style={{ marginTop: '-25px', marginBottom: '10px' }}>
              Warning: this action is irreversible. All information regarding this unit will be lost.
            </div>
          : <div>
              <div id="assetQuestion">Assign asset(s) to an agent? (*)</div>
              <SelectAgent
                selectAgent={ this.selectAgent } 
                format={ "contractInput" }
                dropDownFormat={ "agentOption" }
                supplierId={ user.supplier }
                userId={ user._id }
                title={ "Select agent" }
              />  
            </div> }
        </div>

        {this.renderMessage()}            

        <div id="modal_action">
          { status !== 'Success' &&
            <Button id="confirm_action" disabled={disabled} onClick={()=>this.handleChange()}>Confirm</Button> }

          <Button id="action_cancel" onClick={()=>this.over()}>{status=='Success'?'Close':'Abort'}</Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

const ConfirmAsset = connect(
  function mapStateToProps(state) {
    return { 
      selectedAssets:state.selectedAssets
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(AssetAction);

export default ConfirmAsset;