import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import UsersList from './UsersList/UsersList'
import UserAction from './UsersAction/UserAction'
import AdminMenu from './../AdminMenu'
import './user.css';

class UsersController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      users: undefined,
      showModal: false
    };
  }

  componentDidMount() {
    this.loadData()
  }

	getUsersList = () => {
    axios.get(API_URL + '/users/adminView/' + this.props.user.supplier + '/userId/' + this.props.user._id)
			.then((ret) => {
        this.setState({
          users: ret.data
        })
      })
  }

  close() {
    this.setState({ showModal: false });
    this.getUsersList()
  }  

  loadData() {
    this.getUsersList();
  }
  
  render() {    
    let user = this.props.user
    let users = this.state.users
        
    return (
      <div className="background">
        { ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1
          ? <div>
              <div id="pageTitle">Users</div>
              <div id="adminAction" onClick={()=>this.setState({showModal: !this.state.showModal})}>
                <i className="fas fa-plus"></i>
              </div>
              <AdminMenu user={ user }/>

              <div id="adminBackground">
                { users &&
                  <UsersList 
                    supplier={user.supplier} 
                    users={ users }
                    activeUser={ user }
                    refresh={this.getUsersList}
                    refresh={this.getUsersList}
                    viewerRole={ user.role }
                  />  }
                </div>
            </div>
          : <div>
              <div id="pageTitle">User details</div>
              <div id="adminAction">
                <i className="fas fa-user-cog"></i>
              </div>
              <AdminMenu user={ user }/>

              <div id="adminBackground">
                { users &&
                  <UsersList 
                    supplier={user.supplier} 
                    users={ users }
                    activeUser={ user }
                    refresh={this.getUsersList}
                    viewerRole={ user.role }
                  /> }
                </div>
            </div>
        } 

        { this.state.showModal &&
          <UserAction 
            show={ this.state.showModal } 
            onHide={ this.close.bind(this) }
            viewerRole={ user.role }
            activeUser={ user }
            supplier= { user.supplier }
            edit={ false }
          /> }

      </div>
    );
  }
}

export default UsersController