import React from 'react';
import { Button, FormControl } from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class UnRepossessAsset extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status:undefined,
      disabled: false,
      agents: undefined,
      agent: undefined
    };
  } 

  unrepossess() {
    this.setState({ disabled: true })
    axios.put(API_URL + '/assets/unrepossess/'+ this.props.contract.supplier, {
      contractId: this.props.contract._id,
      paygNumber: this.props.contract.prevPaygNumber,
      agent: this.state.agent? this.state.agent: this.props.contract.agent,
      userId: this.props.userId
    })
      .then((ret) => { this.setState({ status: ret.data.message }) })
      .catch(err => this.setState({ status: err.message }) ); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div className="full">
          <div id={this.state.status == 'Success!' ?'successfulCreation':'unSuccessfulCreation'}>
            {this.state.status}
          </div>
        </div>
      );
    }
  }  

  render() {

    let isDisabled = true;
    if( !this.state.disabled ) isDisabled = false 

    return (
      <div>
        <div id="assetActionTitle">Unrepossess unit</div>

        {this.renderMessage()}

        { this.state.status
            ? null
            : <Button id="action_signup" disabled={isDisabled} onClick={this.unrepossess.bind(this)}>Confirm</Button>}

      </div>
    );
  }
}

export default UnRepossessAsset;