import React, { Component } from 'react';
import './activations.css';

export class ActivationsListItem extends Component {

  render() {
    let activation = this.props.activation
    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let startDate = '-'
    let issueDate = new Date(activation.issueDate)
    issueDate = issueDate.toLocaleDateString("en-EN", options)
    if( activation.startDate ){
      startDate = new Date(activation.startDate)
      startDate = startDate.toLocaleDateString("en-EN", options)
    }

    return (
      <div className="activationItem">
        <div id="activationsMed" >
          { issueDate }
        </div>   
        <div id="activationsMed" >
          { startDate }
        </div>      
        <div id="activationsMed" >
          { activation.duration 
            ? activation.duration + ' days'
            : '-'
          }
        </div>   
        <div id="activationsSmall" >
          { activation.type }
        </div>  
        <div id="activationsLarge">
          {activation.code? activation.code: '-'}
        </div> 
        <div id="activationsLarge">
          {activation.contact? activation.contact.mobile : '-'}
        </div>        
      </div>
    )
  }  
}