import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../../Actions/index';

class EditTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state= {
      template: {
        code: this.props.template.code,
        text: this.props.template.text,
        description: this.props.template.description,
        localText: this.props.template.localText,
        days: this.props.template.days,
        validFor: this.props.template.validFor,
        paused: this.props.template.paused? this.props.template.paused: false,
        supplier: this.props.supplier
      },       
      status: undefined,
    }
  }; 
  
  handleChange(pause){
    let template = this.state.template
    if( pause ) {
      template = Object.assign( template, {
        paused: !this.state.template.paused
      })
    }
    axios.put(API_URL + '/templates/amend/' + this.props.template.supplier + '/' + this.props.template._id, template )
			.then(() => this.setState({ status: 'Success' }) )
  }

  handleDelete(){
    axios.put(API_URL + '/templates/delete/' + this.props.template.supplier + '/' + this.props.template._id)
			.then(() => this.setState({ status: 'Success' }) )
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }   

  render() {
    let template = this.props.template
    let showWarning = false
    let disabled = false
    if( template.type === 'Core' && template.baseText && template.baseText.indexOf('{%code}') > -1 ){
      if( this.state.template.text.indexOf('{%code}') < 0 || ( this.state.template.localText && this.state.template.localText.indexOf('{%code}')) < 0 ) {
        disabled = true
        showWarning = true
      }
    }

    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Edit template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">
              <div id="newTemplateLabel">
                Internal code
              </div>
              <div id="newTemplate">
                { template.type === 'Core'
                  ? <div className="templateDetailsInputCore">{ template.code }</div>
                  : <FormControl 
                      type="text" 
                      className="templateDetailsInput"
                      value={ this.state.template.code !== undefined
                        ? this.state.template.code 
                        : template.code }
                      onChange={(e)=>{ let template = Object.assign({}, this.state.template); template.code = e.target.value; this.setState({ template }) }}
                    /> }
              </div>                            
              <div id="newTemplateLabel">Text</div>
              <div id="newTemplate">
                <textarea 
                  type="text" 
                  className="templateDetailsTextInput"
                  value={ this.state.template.text !== undefined
                    ? this.state.template.text 
                    : template.text }
                  onChange={(e)=>{ let template = Object.assign({}, this.state.template); template.text = e.target.value; this.setState({ template }) }}
                />
              </div>  
              <div id="newTemplateLabel">Local translation</div>
              <div id="newTemplate">
                <textarea 
                  type="text" 
                  className="templateDetailsTextInput"
                  value={ this.state.template.localText !== undefined
                    ? this.state.template.localText 
                    : template.localText }
                  onChange={(e)=>{ let template = Object.assign({}, this.state.template); template.localText = e.target.value; this.setState({ template }) }}
                />
              </div>  

              <div id="newTemplateLabel">Internal description</div>
              <div id="newTemplate">
                <textarea 
                  type="text" 
                  className="templateDetailsTextInput"
                  value={ this.state.template.description !== undefined
                    ? this.state.template.description 
                    : template.description }
                  onChange={(e)=>{ let template = Object.assign({}, this.state.template); template.description = e.target.value; this.setState({ template }) }}
                />
              </div>  
              { template.type !== 'Automated'
                ? null
                : <div>
                    <div id="newTemplateLabel">
                      Trigger: days { template.direction } contract locks
                    </div>
                    <div id="newTemplate">
                    <FormControl 
                      type="text" 
                      className="templateDetailsInput"
                      value={ this.state.template.days !== undefined
                        ? this.state.template.days 
                        : template.days }
                      onChange={(e)=>{ let template = Object.assign({}, this.state.template); template.days = e.target.value; this.setState({ template }) }}
                      />
                  </div>    
                  <div id="newTemplateLabel">
                    Number of days after signing date SMS is sent to client:
                  </div>
                  <div id="newTemplate">
                    <FormControl 
                      type="text" 
                      className="templateDetailsInput"
                      value={ this.state.template.validFor !== undefined
                        ? this.state.template.validFor 
                        : template.validFor }
                      onChange={(e)=>{ let template = Object.assign({}, this.state.template); template.validFor = e.target.value; this.setState({ template }) }}
                    />
                  </div>    
                </div> }

          </div>

          { template.type == 'Core' &&
            <div id="messageVariables">
              <div id="messageVariableTitle">Available variables</div>
              <div id="messageVariable">&#123;%reference&#125;</div>
              <div id="messageVariableLabel">Payment reference used by client</div>
              <div id="messageVariable">&#123;%amount&#125;</div>
              <div id="messageVariableLabel">Amound of payment</div>
              <div id="messageVariable">&#123;%ccy&#125;</div>
              <div id="messageVariableLabel">Currency of payment</div>
              <div id="messageVariable">&#123;%code&#125;</div>
              <div id="messageVariableLabel">Activation code sent</div>
              <div id="messageVariable">&#123;%balance&#125;</div>
              <div id="messageVariableLabel">Contract's remaining balance</div>
            </div> 
          }

          { template.type == 'Automated' &&
            <div id="messageVariables">
                  <div id="messageVariableTitle">Available variables</div>
                  <div id="messageVariable">&#123;%paygNumber&#125;</div>
                  <div id="messageVariableLabel">Reminder to the client of his/her paygNumber</div>
                  <div id="messageVariable">&#123;%balance&#125;</div>
                  <div id="messageVariableLabel">Contract's remaining balance</div>
                  <div id="messageVariable">&#123;%amount&#125;</div>
                  <div id="messageVariableLabel">Monthly payment due</div>
                  <div id="messageVariable">&#123;%ccy&#125;</div>
                  <div id="messageVariableLabel">Currency of the payment</div>
            </div> 
          }

          { showWarning
            ? <div id="templateWarning">Your message must include &#123;%code&#125; for your clients to receive their activation code</div>
            : null }
          {this.renderMessage()}            
          <br/>
          <div id="modal_action">

            { this.state.status == 'Success'
              ? null
              : <Button id="action_signup" disabled={disabled} onClick={()=>this.handleChange()}>Edit</Button>  }

            { this.state.status == 'Success' || template.type === 'Core'
              ? null
              : <Button id="message_delete" onClick={()=>this.handleDelete()}>
                <i className="fas fa-trash"></i>
              </Button>
            }

            { this.state.status == 'Success' || template.type === 'Core'
              ? null
              : <Button id="message_pause" onClick={()=>this.handleChange(true)}>
                  { this.state.template.paused
                    ? <i className="far fa-play-circle"></i>
                    : <i className="far fa-pause-circle"></i> }
                </Button>
            }

            <Button id="action_cancel" onClick={this.props.onHide}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
    
      </Modal>
 
    );
  }
}

export default EditTemplate;