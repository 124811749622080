import React, { Component } from 'react';
import ClientAction from './ClientAction'
import ManageMenu from './../../ManageMenu'
import history from './../../../../history';
import './../client.css';

class ContractActionController extends Component {

  goBack() {
    window.history.back();
  }
 
  render() {    

    return (
      <div className='background'>
        <div id="pageTitle">Create new client</div>
        <ManageMenu user={this.props.user}/>
        <div id="clientActionLine">
          <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
        </div>
        <div id="clientBackground">
          <ClientAction user={this.props.user}/>
        </div>
      </div>
    );
  }
}

export default ContractActionController