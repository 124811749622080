import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { SelectAgent } from '../../Agents/Action/SelectAgent'

class AddNote extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      note: {
        contract: this.props.contract ? this.props.contract._id : undefined,
        client: this.props.client ? this.props.client._id : undefined,
        author: this.props.user._id,
        writtenBy: this.props.user.profile.firstName.substring(0, 1) + ' ' + this.props.user.profile.lastName,
        title: this.props.contract? this.props.contract.contractNumber : undefined,
        status: 'Sent',
      },
    };
  } 

  componentDidMount() {
    this.setState({status: undefined})
  } 

  send(){

    let note = this.state.note

    if( ['Task', 'Ticket'].indexOf( note.type ) > -1 ) {
      let number = Date.now().toString()
      Object.assign( note, { title: 'TASK-' + number.slice(5, 10) } )
    } else Object.assign( note, { title: 'INTERNAL' } )

    axios
      .put(API_URL + '/notes/sendnote/' + this.props.user.supplier, note)
      .then((ret) => { this.setState({status: 'Success' }) })

  }

  selectAgent = (agent) => {
    let note = Object.assign({}, this.state.note); 
    note.agentTo = agent
    if( agent !== this.props.agent ) note.tempAgent = agent
    this.setState({ note }) 
  }

  over(){
    this.setState({
      note: {
        text: undefined,
        title: undefined,
        status: 'Sent',
        type: undefined,
        subType: undefined,
        priority: undefined,
      },
      status: undefined,
    })
    this.props.onHide()
    this.props.refresh()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   

  render() {
    let { contract, client, user, agent } = this.props

    let respAgent = contract ? contract.agent : client.agent
    let agentName = respAgent&& respAgent.profile? respAgent.profile.firstName + ' ' + respAgent.profile.lastName : '-'
    let options = user.options

    let note = this.state.note

    let disabled = true
    if( note.text && note.type && ( note.type == 'Internal' || note.agentTo ) ) disabled = false
    
    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">

          { !note.type &&  <div>Create task or internal note</div> }
          { note.type == 'Internal' && <div>Add an internal note</div> }
          { note.type == 'Ticket' && <div>Create a ticket</div> }
          { note.type == 'Task' && <div>Assign task to agent</div> }
          { note.type == 'Info' && <div>Send agent a message</div> }   

        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div id="modal_highlight">

          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="agentEditInput"
            onChange={(e)=>{ 
              let note = Object.assign({}, this.state.note); 
              note.type = e.target.value;
              if( note.type == 'Internal' ) note.agentTo = undefined
              else if( !options.customerServiceAgents ) note.agentTo = agent._id
              this.setState({ note }) 
            }}
          > 
            <option value={ '' }>Select type</option>
            { agent && <option value="Task">Task</option> }
            { agent && <option value="Info">Info</option> }
            <option value="Internal">Internal</option>
          </FormControl>

          { options.customerServiceAgents || !note.type || note.type === 'Internal'
            ? null
            : <div className="agentEditInput">
                To {agentName}
              </div> }

          { options.customerServiceAgents && note.type == 'Task' && 
            <SelectAgent 
              selectAgent={ this.selectAgent } 
              role={ 'CustomerService' } 
              _id={ agent._id }
              format={ "agentEditInput" }
              supplierId={ user.supplier }
              userId={ user._id }
            /> }

          { note.type == 'Task' &&
            <div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="agentEditInput"
                onChange={(e)=>{ 
                  let note = Object.assign({}, this.state.note); 
                  note.priority = e.target.value;
                  this.setState({ note }) 
                }}
              > 
                <option value={ '' }> Select priority</option>
                <option value={1}>1 (High)</option>
                <option value={2}>2 (Medium)</option>
                <option value={3}>3 (Low)</option>
              </FormControl>

            </div> }

          <textarea 
            type="text" 
            className="agentMessageInput"
            value ={  note.text }
            placeholder={"Type message"}
            onChange={(e)=>{ let note = Object.assign({}, this.state.note); note.text = e.target.value; this.setState({ note }) }}
          />

        </div>

        { this.renderMessage() }            

        <div id="modal_action">
          { this.state.status !== 'Success' && <Button id="action_signup" disabled={disabled} onClick={this.send.bind(this)}>Confirm</Button> }
          <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default AddNote;