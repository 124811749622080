import React, { Component } from 'react';
import './../List/auxiliaries.css'

class List extends Component {

  render() {    

    let { auxiliaries } = this.props
    let mapped = auxiliaries && auxiliaries
      .sort((a, b) => a.name.localeCompare(b.name))  
      .map( (elem, index) =>{ 
        return (
          <div key={ index } id='aux'>
            <div id="auxRef">{ elem.reference }</div> 
            <div id="auxName">{ elem.name }</div>
            <div id="auxSKU">{ elem.sku }</div>
            <div id="auxSeri">{ elem.hasSerialNumber ? 'Serialized' : 'Non-serialized' }</div>
          </div>
        )
      })
      
    return (
      <div>
        { mapped }
      </div>
    );
  }
}

export default List