import React, { Component } from 'react';
import { Item } from './Item'
import './clientEvents.css';
import AddNote from './../../Notes/Actions/AddNote'

class List extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }
  
  close() {
    this.setState({ show: false });
    this.props.refresh()
  }

  render() {    
    let { events, user, refresh, client } = this.props

    let mappedEvents = events && events.length > 0 && events
      .sort(function(a, b) {
        return new Date(b.date) - new Date(a.date)
      })
      .map( event => { 
        return <Item key={ event._id } event={event} user={ user } refresh={ refresh }/>
      })

    return (
      <div>
        <div className="clientEventsTitle negMarginTop">Events</div> 
        <div id="creditResultsCreate" className="actionNegMarginTop" onClick={ () => this.setState({ show: !this.state.show })}>
          <i className="fas fa-plus-circle"></i>
        </div> 
        <div id="clientEventList">
          { mappedEvents }
        </div>
        { this.state.show &&
          <AddNote 
            show={ this.state.show } 
            onHide={ this.close.bind(this) }
            refresh={ refresh }
            user={ user }
            client={ client }
            agent={ client.agent }
          /> }
          
      </div>
    );
  }
}

export default List