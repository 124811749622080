import React, { Component } from 'react';
import AuxiliariesController from './../Auxiliaries/AuxiliariesController'
import ProductsList from './List/ProductsList'
import AddProduct from './Action/AddProduct'
import AdminMenu from './../AdminMenu'
import { findProducts } from  './../../../services/ProductService'
import { hasPermission } from '../../../services/UserService'

import './product.css';

class ProductsController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      status: 'Products',
      create: false
    };
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0);
  }

  close(){
    this.setState({ create: false });
    this.getProductsList()
  }

  getProductsList = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products })
    } catch(err){ console.log(err) }
  }

  
  loadData() {
    this.getProductsList();
  }
  
  render() {    
    let user = this.props.user
    let permission = hasPermission( user, undefined, 'createProducts' )

    return (
      <div className="background">
        <div id="pageTitle">Products and auxiliaries</div>

        { permission && this.state.status == 'Products' &&
          <div id="adminAction" onClick={()=>this.setState({ create: !this.state.create })}>
            <i className="fas fa-plus"></i>
          </div> }
          
        <AdminMenu user={ user }/>

        <div id="clientBackground">

          <div id="clientSubMenu">  

            <div id="clientSubMenu_item" onClick={()=>this.setState({ status: 'Products' })} className={this.state.status == 'Products'?"active_clientSubMenu leftMenu":"leftMenu"}>Products</div>  
            <div id="clientSubMenu_item" onClick={()=>this.setState({ status: 'Auxiliaries' })} className={this.state.status == 'Auxiliaries'?"active_clientSubMenu rightMenu":"rightMenu"}>Auxiliaries</div>  

          </div>

          { this.state.products && this.state.status == 'Products' &&
            <ProductsList 
              supplier={ user.supplier } 
              products={ this.state.products } 
              refresh={ this.getProductsList } 
              user={ user }
            /> }
        
          { this.state.status == 'Auxiliaries' &&
            <AuxiliariesController user={ user } /> }

        </div>

        { this.state.create && ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1 &&
          <AddProduct 
            user={ user }
            options={ user.options }
            refresh={this.getProductsList}
            show={this.state.create} 
            onHide={this.close.bind(this)}
          /> }

      </div>
    );
  }
}

export default ProductsController