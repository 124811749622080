import React, { Component } from 'react';
import history from '../../../../history';

class Item extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }

  handleNext(path){
    history.push(path)
  }
  
  render() {
    var { item } = this.props

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let dateAdded = new Date(item.createdAt)
    dateAdded = dateAdded.toLocaleDateString("en-EN", options)

    let from = '-'
    if( item && item.tracking ){
      let info = item.tracking
      if( info.category == 'Change_in_quality' &&  item.quantity > 0 ) from = 'from ' + info.qualityFrom
      else if(info.category == 'Change_in_quality' &&  item.quantity < 0 ) from = 'to ' + info.qualityTo
      else if( ['Agent_from_stock', 'Agent_from_agent', 'Stock_from_agent'].indexOf(info.category) > -1 && item.quantity > 0 ) {
        from = 'from ' + info.receivedFrom.profile.lastName  
      }
      else if( ['Agent_from_stock', 'Agent_from_agent', 'Stock_from_agent'].indexOf(info.category) > -1 && item.quantity < 0 ) {
        from = 'to ' + info.sentTo.profile.lastName  
      }
    }
   
    return (
      <div className="itemItem" onClick={()=>this.setState({ show: !this.state.show })}>
        <div id="itemDate">{ dateAdded }</div>
        <div id="itemFrom">{ from }</div>
        <div id="itemQty">{ item.quantity }</div>
        { this.state.show && 
          <div id="itemDes">
            <div id="itemDesLabel">Note</div>
            <div id="itemDesData">{ item.note? item.note : '-' }</div>
            <div id="itemDesLabel">Entered by</div>
            <div id="itemDesData">{ item.tracking.enteredBy? item.tracking.enteredBy.userNumber : '-' }</div>
          </div>}
      </div>
    )
  }  
}

export default Item 