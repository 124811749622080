import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import './marker.css'

let options =[
  {
      "featureType": "landscape.natural",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#405c6eb3"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "hue": "#1900ff"
          },
          {
              "color": "#26455A"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
          {
              "lightness": 100
          },
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "lightness": 700
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "color": "#00253D"
          }
      ]
  }
]

const Marker = ({ text }) => <div className="marker"><i className="fas fa-solar-panel"></i></div>;
const Transfer = ({ date }) => <div className="marker-light"><i className="fas fa-map-pin"></i></div>;


class AssetMap extends Component {
  static defaultProps = {
    center: {
      lng: -0.1934,
      lat: 51.51
    },
    zoom: 9
  }
 
  render() {
    let points = this.props.events.filter( elem => elem.gps )
    let asset = this.props.asset

    let mappedMvts = null;

    if( points && points.length > 0 ){
      mappedMvts = points.map( (event, index) => {
        return (
          <Transfer 
            key={ index } 
            lng={ event.longitude }
            lat={ event.latitude }
            date={ event.date }
          />
        )
      })
    }
    return (
      // Important! Always set the container height explicitly
      <div id="assetMap">
        { asset.gps && asset.gps.latitude && asset.gps.longitude &&
          <GoogleMapReact
            bootstrapURLKeys={{ 
              key: 'AIzaSyAZb4Vrv1pxm9-MEQrYLsq7oYqQNJvi7Es',
              language: 'en',
            }}
            options={{
              styles: options,
          }}
            defaultCenter={{
              lat: parseFloat(this.props.asset.gps.latitude),
              lng: parseFloat(this.props.asset.gps.longitude)
            }}
            defaultZoom={this.props.zoom}
          >

          { mappedMvts }      
          <Marker 
            lng={ asset.gps.longitude }
            lat={ asset.gps.latitude }
          />  
          </GoogleMapReact> }
      </div>
    );
  }
}
 
export default AssetMap;