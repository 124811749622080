import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import TemplatesList from './TemplatesList/TemplatesList'
import CreateTemplate from './TemplateAction/CreateTemplate'
import './templates.css';
import { timingSafeEqual } from 'crypto';

class TemplateController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      templates: undefined,
      create: false
    };
  }

  componentDidMount() {
    this.loadData()
  }

  close() {
    this.setState({ create: false });
    this.getTemplatesList()
  }

	getTemplatesList = () => {
    axios.post(API_URL + '/templates/search/' + this.props.supplier, {
      query: {},
      user: this.props.user._id
    })
			.then((ret) => this.setState({ templates: ret.data }) )
  }
  
  loadData() {
    this.getTemplatesList();
  }
  
  render() {    

    return (
      <div id="messagesLongList">
        <div id="templateAction" onClick={()=>this.setState({ create: !this.state.create })}>
          <i className="fas fa-plus"></i>
        </div> 
          
        { this.state.templates &&
          <TemplatesList templates={this.state.templates} refresh={this.getTemplatesList}/> }
            
        { this.state.create &&
          <CreateTemplate 
            refresh={ this.getTemplatesList }
            user={ this.props.user }
            show={ this.state.create } 
            onHide={ this.close.bind(this) }
          /> } 
      </div>
    );
  }
}

export default TemplateController