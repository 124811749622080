import React, { Component } from 'react';
import './../agent.css';
import PerfGraph from './PerfGraph'
import { FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL, setFilter } from '../../../../Actions/index';
import { connect } from 'react-redux'; 
import history from '../../../../history';
import { findDeals } from  '../../../../services/DealService'
import { findProducts } from  '../../../../services/ProductService'

var DatePicker = require("react-bootstrap-date-picker");
var addDays = require('date-fns/add_days');

class Element extends Component {
  constructor(props) {
		super(props)
		this.state = {
      filter: {
        agent: this.props.agent._id,
        agentNumber: this.props.agent.agentNumber,
        endDate: (new Date()).toISOString(),
        startDate: (addDays(new Date(), -30)).toISOString(),
      },
    };
  }
  
  componentDidMount() {
    this.loadData()
  }
    
  getProducts = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products })
    } catch(err){ console.log(err) }
  }

  getDeals = async () => {
    try {
      let deals = await findDeals( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ deals })
    } catch(err){ console.log(err) }
  }


  getData( ){

    axios.post(API_URL + '/agents/performance/' + this.props.agent.supplier, this.state.filter )
      .then((ret) => { 
        this.setState({ 
          monthlydata: ret.data.monthlydata,
          weeklydata: ret.data.weeklydata
         }) 
      })
  }

  loadData() {
    this.getProducts();
    this.getDeals();
    this.getData()
  }

  select(contract){
    let include = true
    if( this.state.filter.product && contract.product != this.state.filter.product ) include = false
    if( this.state.filter.deal && contract.deal._id != this.state.filter.deal ) include = false
    return include
  }

  render() {    

    let mappedProducts, mappedDeals = null
    let agent = this.props.agent

    if(this.state.products){
      mappedProducts = this.state.products.map((product, index) =>{ 
        return (
          <option key={index} value={product._id} className={'agentOption'}>{product.name} by {product.manufacturer}</option>
        )
      })
    }

    if( this.state.deals ){
      mappedDeals = this.state.deals.map((deal, index) =>{ 
        return (
          <option key={index} value={deal._id} className={'agentOption'}>{deal.dealName}</option>
        )
      })
    }

    let weeklydata = this.state.weeklydata
    let monthlydata = this.state.monthlydata

    return (
      <div id="agentPerformance">

        <div className='full'>
          <div id="agentAnalysisTitle">
            { agent.role !== 'Manager'
              ? "Detailed analysis"
              : !this.state.filter.manager? "Individual analysis" : "Team analysis" }
          </div>

          <div id="performanceSelect">

            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="graphSelector agentSelector"
              onChange={(e)=>{ 
                let filter = Object.assign({}, this.state.filter); 
                filter.product = e.target.value; 
                this.setState({ filter }) 
              }}              >
              <option value="" className={'agentOption'}>All products</option>
              { mappedProducts }
            </FormControl>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="graphSelector agentSelector"
              onChange={(e)=>{ 
                let filter = Object.assign({}, this.state.filter); 
                filter.deal = e.target.value; 
                this.setState({ filter }) 
              }}                >
              <option value="" className={'agentOption'}>All deals</option>
              { mappedDeals }
            </FormControl>
            <div className="agentSelectorDate">
              <DatePicker 
                className ="graphSelector"
                value={this.state.filter.startDate}  
                onChange={(e)=>{ 
                  let filter = Object.assign({}, this.state.filter); 
                  filter.startDate = e; 
                  this.setState({ filter }) 
                }}  
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />
            </div>
            <div className="agentSelectorDate">
              <DatePicker 
                className ="graphSelector"
                value={this.state.filter.endDate}  
                onChange={(e)=>{ 
                  let filter = Object.assign({}, this.state.filter); 
                  filter.endDate = e; 
                  this.setState({ filter }) 
                }}                    
                showClearButton= {false}
                calendarPlacement ={'left'}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />
            </div>

          </div>

          { agent.role == 'Manager'
            ? <div id="agentAnalysisLink" onClick={ () => {
                let filter = Object.assign({}, this.state.filter); 
                filter.manager = filter.manager? undefined: agent.agentNumber ; 
                filter.agent = filter.manager? undefined: agent._id ;
                this.setState({ filter }) 
              }}>
                { !this.state.filter.manager? <i className="fas fa-users"></i>:<i className="fas fa-user"></i> }
              </div> 
            : null }

        </div>

        <div id="perfGraphWrapper">
          { weeklydata && Math.max( ...weeklydata.salesTargets) > 0
            ? <div id="perfGraphTitle">Sales &amp; revenues weekly data vs targets</div>
            : null
          }
          { weeklydata && Math.max( ...weeklydata.salesTargets) == 0
            ? <div id="perfGraphTitle">Sales &amp; revenues weekly</div>
            : null
          }
          
          { weeklydata
          ? <PerfGraph 
              data={ weeklydata.sales }
              targets={ weeklydata.salesTargets }
              labels={ weeklydata.labels } 
              title={ 'New sales' }
            />
          : null }

          { weeklydata
          ? <PerfGraph 
              data={ weeklydata.revenues }
              targets={ weeklydata.revenuesTargets }
              labels={ weeklydata.labels } 
              title={ 'Revenues' }
            />
          : null }
        </div>

        <div id="perfGraphWrapper">
        { monthlydata && Math.max( ...monthlydata.salesTargets) > 0
            ? <div id="perfGraphTitle">Sales &amp; revenues monthly data vs targets</div>
            : null
          }
          { monthlydata && Math.max( ...monthlydata.salesTargets) == 0
            ? <div id="perfGraphTitle">Sales &amp; revenues monthly data</div>
            : null
          }

          { monthlydata
          ? <PerfGraph 
              data={ monthlydata.sales }
              targets={ monthlydata.salesTargets }
              labels={ monthlydata.labels } 
              title={ 'New sales' }
            />
          : null }

          { monthlydata
          ? <PerfGraph 
              data={ monthlydata.revenues }
              targets={ monthlydata.revenuesTargets }
              labels={ monthlydata.labels } 
              title={ 'Revenues' }
            />
          : null }
        </div>
        
       
      </div>
    );
  }
}

const Performance = connect(
  function mapStateToProps(state) {
    return { 
      filter: state.filter
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      setFilter: filter => dispatch(setFilter(filter)),
    };
  },
)(Element);

export default Performance