import React, { Component } from 'react';
import Header from './Header'
import Item from './Item'

class SituationsList extends Component {

  render() {    
    let { items, quality, status, agents, auxiliary } = this.props
    let filterItems = items && items.filter( elem => elem._id.status == status && elem._id.quality == quality )
    let mappedItems = filterItems && filterItems.map( (elem, index) =>{ 
      let agent = agents && agents.find( agent => agent._id + '' == elem._id.agent + '')
      return (
        <Item 
          elem={elem} 
          auxiliary={auxiliary}
          quality={quality} 
          status={status} 
          key={ index }
          agent={agent} 
          breakdown={this.props.breakdown}
        />
      )
    })
    
    return (
      <div id="subItemsList">
        { filterItems && <Header auxiliary={auxiliary} quality={quality}/> }
        { mappedItems }
      </div>
    );
  }
}

export default SituationsList