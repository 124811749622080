import React, { Component } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap/lib';
import './../payroll.css';
import './../../AgentInvoices/agentInvoice.css'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
var DatePicker = require("react-bootstrap-date-picker");

class PayrollsAction extends Component {

  constructor(props) {
    super(props);
    this.state= {
      payroll: {
        paid: undefined,
        dateTo: this.props.payroll? this.props.payroll.dateTo: undefined,
        dateFrom: this.props.payroll? this.props.payroll.dateFrom: undefined,
        paidBy: undefined,
        closedBy: undefined,
        comment: this.props.payroll? this.props.payroll.comment: undefined,
        amount: this.props.payroll? this.props.payroll.amount: undefined,
        ccy: this.props.payroll.ccy,
        agentInvoiceNumber: this.props.payroll? this.props.payroll.agentInvoiceNumber : undefined,
      },       
      status: undefined,
      isDisabled: false,
    }
  }; 

  submit() {
    let supplier = this.props.user.supplier
    axios.post(API_URL + '/agents/editInvoice/' + supplier, this.state.payroll)
      .then((ret) => this.setState({ status: 'Success'}) )
      .catch(err => {
        console.error(err);
        this.setState({ status: err.message })
      }); 
  }

  delete() {
    let supplier = this.props.user.supplier
    let query = {
      deleted: true,
      deletedBy: this.props.user.userNumber,
      agentInvoiceNumber: this.props.payroll? this.props.payroll.agentInvoiceNumber : undefined,
    }
    axios.post(API_URL + '/agents/editInvoice/' + supplier, query)
      .then((ret) => this.setState({ status: 'Success'}) )
      .catch(err => {
        console.error(err);
        this.setState({ status: err.message })
      }); 
  }


  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  }  
  
  over(){
    this.props.onHide()
    this.setState({
      payroll: {
        paid: undefined,
        amount: undefined,
        comment: undefined,
        ccy: undefined
      },       
      status: undefined,
      isDisabled: false,
    })
  }


  selectPaid = (bool) => {
    let payroll = Object.assign({}, this.state.payroll); 
    payroll.paid = bool;
    payroll.paidBy = this.props.user.userNumber;
    payroll.closedBy = this.props.suer._id 
    this.setState({ payroll })
  }

  render() {
    
    let isDisabled = true    
    let item = this.state.payroll
    if( item.freq && item.dateFrom && item.dateTo && item.amount ) isDisabled = false
    if( this.state.disabled ) isDisabled=true

    var options = {year: 'numeric', month: 'short', day: 'numeric' };

    let dateFrom = '-'
    if(item.dateFrom){
      dateFrom = new Date(item.dateFrom)
      dateFrom = dateFrom.toLocaleDateString("en-EN", options)
    }

    let dateTo = '-'
    if(item.dateTo){
      dateTo = new Date(item.dateTo)
      dateTo = dateTo.toLocaleDateString("en-EN", options)
    }


    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Edit payroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">

            <div className="agentTargetInputLabel">Period</div>
            <div className="dateInvoice"  >
              <DatePicker 
                className ="invoiceDateInput"
                placeholder={ dateFrom }
                value={ this.state.payroll.dateFrom }  
                onChange={(e)=>{ 
                  let payroll = Object.assign({}, this.state.payroll); 
                  payroll.dateFrom = e; 
                  this.setState({ payroll }) 
                }}
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />          
            </div>  
            <div className="dateInvoice"  >
              <DatePicker 
                className ="invoiceDateInput"
                placeholder={ dateTo }
                value={ this.state.payroll.dateTo }  
                onChange={(e)=>{ 
                  let payroll = Object.assign({}, this.state.payroll); 
                  payroll.dateTo = e;  
                  this.setState({ payroll }) 
                }}
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />          
            </div>   

            <div className="agentInvoiceInputLabel">Amount</div>
            <FormControl 
              type="number" 
              className="agentInvoiceInputMedium"     
              placeholder={'Type'}
              value={ this.state.payroll.amount }
              onChange={(e)=>{ let payroll = Object.assign({}, this.state.payroll); payroll.amount = e.target.value; this.setState({ payroll }) }}
            />
            <div className="agentInvoiceInputMedium">{ this.props.payroll.ccy }</div>

            <div className="agentTargetInputLabel">Paid</div>
            <div 
              id="agentTargetOption" 
              className={ this.state.payroll.paid == true?'activeTOption halfInvoice': 'halfInvoice'}
              onClick={()=>this.selectPaid(true)}
            >
              True
            </div>
            <div 
              id="agentTargetOption" 
              className={ this.state.payroll.paid == false?'activeTOption halfInvoice': 'halfInvoice'}
              onClick={()=>this.selectPaid(false)}
            >
              False
            </div>

            <div className="agentInvoiceInputLabel">Comment</div>
            <textarea 
              type="number" 
              className="agentInvoiceInputLong"     
              placeholder={'Type'}
              value={ this.state.payroll.comment }
              onChange={(e)=>{ let payroll = Object.assign({}, this.state.payroll); payroll.comment = e.target.value; this.setState({ payroll }) }}
            />

          </div>


          { this.renderMessage() }            
          <br/>
          <div id="modal_action">
            { this.state.status =='Success'
              ? null
              : <div>
                  <Button id="action_signup" onClick={this.submit.bind(this)}>Edit</Button> 
                  <Button id="confirm_action" onClick={this.delete.bind(this)}>Delete</Button> 
                </div> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PayrollsAction