import React from 'react';
import { Doughnut } from 'react-chartjs-2';

class Progress extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
      },
      data: undefined
    };
  };

  render() {
    let group = this.props.group
    let contracts = group.contracts 
    let label = []
    let dataset = []

    if( group.type == 'Solidary'){
      dataset = [ group.target - group.paid, group.paid ]
      label = [ 'not paid', 'paid' ]
    } else {
      label = ['< 25%', ' 25% to 50%', '50% to 75%', '75% to 100%', '100%' ]
      
      let l25 = 0
      let m25l50 = 0
      let m50l75 = 0
      let m75l100 = 0
      let l100 = 0
      if( contracts ){
        contracts.map( elem => {
          let pct = elem.totalPaid / elem.totalCost
          if(  pct < 0.25 ) l25 +=1
          if( pct >= 0.25 && pct < 0.5 ) m25l50 +=1
          if( pct >= 0.5 && pct < 0.75 ) m50l75 +=1
          if( pct >= 0.75 && pct < 1 ) m75l100 +=1
          if( pct >= 1 ) l100 +=1
        })
      }
      dataset = [ l25, m25l50, m50l75, m75l100, l100 ]
    }
   
    return (
      <div id="groupSummary">
        <div id="groupGraphTitle">{ this.props.title }</div>
        <div id="groupGraph">
        <Doughnut 
          data={{
            labels: label, 
            datasets: [
              {
                label: '$',
                backgroundColor: [
                  'rgba(255, 255, 255, 0.1)',
                  'rgb(65, 255, 255)',
                  'rgb(27, 218, 218)',
                  'rgb(11, 179, 179)',
                  'rgba(0,139,139, 1)',
                  'white'
                ],
                borderColor: '#26455A',
                borderWidth: 1,
                hoverBackgroundColor: [
                  'rgba(255, 255, 255, 0.15)',
                  'rgb(65, 255, 255)',
                  'rgb(27, 218, 218)',
                  'rgb(11, 179, 179)',
                  'rgba(0,139,139, 1)',
                  'white'
                ],
                hoverBorderColor: [
                  'white',
                  'rgba(252, 198, 48, 1)',
                  'rgba(0,139,139, 1)',
                  '#CA193F',
                  'red'
                ],
                data: dataset
              }
            ]
          }}
          width={ this.props.width }
          height={ this.props.height }
          options={ this.state.options }
        /> 
        </div>
    
              
      </div>      
    );
  }
}

export default Progress