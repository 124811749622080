import React, { Component } from 'react';
import { PaymentHeader } from './PaymentHeader'
import { PaymentsListItem } from './PaymentsListItem'
import './payments.css';

class PaymentsList extends Component {
  
  render() {    

    let { payments, supplierId, contract, fromAgentPage, paymentStatus, refresh, user } = this.props
    let mappedPayments = payments && payments
      .sort(function(a, b) {
        return new Date(b.date) - new Date(a.date)
      })
      .map((payment) =>{ 
        return (
          <PaymentsListItem 
            key={ payment._id }
            payment={ payment } 
            supplierId={ supplierId } 
            general={ contract? false : true }
            fromAgentPage={ fromAgentPage }
            paymentStatus = { paymentStatus}
            contract={ contract
              ? contract
              : payment.contract? payment.contract : undefined }
            user={ user }
            refresh={ refresh }
          />
        )
      })

    return (
      <div>
        { payments && payments.length > 0 &&
          <PaymentHeader paymentStatus = {paymentStatus} general={ contract? false : true }/> }
        { mappedPayments }
      </div>
    );
  }
}

export default PaymentsList