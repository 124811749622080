import React, { Component } from 'react';
import { Header } from './Header'
import Item from './Item'
import './groupPricing.css';

class GroupPricingsList extends Component {
  
  render() {    
    let groupPricings = this.props.groupPricings

    let mappedPricings = groupPricings && groupPricings
      .sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      .map( groupPricing =>{ 
        return (
          <Item 
            key={ groupPricing._id }
            groupPricing={ groupPricing } 
            user={ this.props.user }
            refresh={ this.props.refresh }
          />
        )
    })

    return (
      <div id="dealsList">
        { groupPricings && groupPricings.length > 0 && <Header/> }
        { mappedPricings }
      </div>
    );
  }
}

export default GroupPricingsList