import React, { Component } from 'react';
import EditAuxiliary from '../Action/EditAuxiliary'
import { hasPermission } from '../../../../services/UserService'
import './auxiliaries.css'

export class Item extends Component {
  constructor(props) {
    super(props);
    this.state= {
      edit: false,
    }
  };  

  edit() {
    this.setState({ edit: false });
  }

  render() {
    var { user, auxiliary } = this.props
    let permission = hasPermission( user, auxiliary, 'createProducts' )

    return (
      <div className="auxiliaryItem">
        <div id="auxiliaryName">{auxiliary.name? auxiliary.name: '-'}</div>
        <div id="auxiliaryReference">{auxiliary.reference? auxiliary.reference: '-'}</div>
        <div id="auxiliarySKU">{auxiliary.sku? auxiliary.sku: '-'}</div>
        <div id="auxiliaryProduct">{ auxiliary.product && auxiliary.product.name ? auxiliary.product.name : '-' }</div>
        <div id="auxiliarySerial">{auxiliary.hasSerialNumber == true ? 'Yes': 'No'}</div>
        
        { permission &&
          <div id="auxiliaryEdit" className="active onlyLarge" onClick={()=>this.setState({ edit: true })}>
            <i className="fas fa-pen"></i>
          </div>  }

        { this.state.edit &&
          <EditAuxiliary
            show={ this.state.edit } 
            onHide={ this.edit.bind(this) }
            user={ this.props.user }
            refresh={ this.props.refresh }
            auxiliary={ auxiliary }
          /> }

      </div>
    )
  }  
}
