import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { Button, FormControl } from 'react-bootstrap/lib';
import './../search.css';
import { chooseSearch } from '../../../../Actions/actions';
import { connect } from 'react-redux';
import PaymentsList from '../../Payments/List/PaymentsList'

var DatePicker = require("react-bootstrap-date-picker");
var startOfDay = require("date-fns/start_of_day")
var endOfDay = require("date-fns/end_of_day")

class SearchFonction extends Component {
  constructor(props) {
		super(props)
		this.state = {
      query: {},
      page: 1,
      pages: 1,
      total: 0,
    };
  }

  getResults(fromPage){
    var body = {
      query: this.state.query,
      user: this.props.user._id,
      fromPage,
      pageSize: 200
    }
    axios.post(API_URL + '/payments/find/'+ this.props.user.supplier, body)
    .then((ret) => {
      this.setState({
        payments: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total 
      })
    })
  }

  download(){

    var body = { 
      query: this.state.query,
      user: this.props.user._id,
      export: true,
    }

    axios.post(API_URL + '/payments/search/' + this.props.user.supplier, body)
      .then(response => {
        const url = window.URL.createObjectURL( new Blob([response.data], {type: 'text/csv'}) );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'payments.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.state.query.export = false
      })  
  }

  render() {    
    let { payments, pages, page, receivedBefore, receivedAfter } = this.state

    return (
      <div> 
        <div className="activeSearchSection">   
          <FormControl
            type="text"
            className="searchInput"
            placeholder="TransactionId"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query.transactionId = e.target.value; 
              this.setState({ query }) 
            }}
          />
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Reference"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.paymentReference = e.target.value; this.setState({ query }) }}
          />        
          <FormControl
            type="number"
            className="searchInput"
            placeholder="Mobile number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query["contact.mobile"] = e.target.value; this.setState({ query }) }}
          />
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Contract number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.contractNumber = e.target.value; this.setState({ query }) }}
          />
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Received after"
              value={receivedAfter}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.date = { $gte: startOfDay(new Date(e)) }; 
                if( receivedBefore ) query.date = { $gte: startOfDay(new Date(e)), $lte: endOfDay(new Date(receivedBefore)) }
                this.setState({ query, receivedAfter: e }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>   
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Received before"
              value={receivedBefore}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.date = { $lte: endOfDay(new Date(e)) }; 
                if( receivedAfter ) query.date = { $lte: endOfDay(new Date(e)), $gte: startOfDay(new Date(receivedAfter)) }
                this.setState({ query, receivedBefore: e }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>   
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.status = e.target.value; this.setState({ query }) }}
            >
            <option value="">Status</option>
            <option value="ACCEPTED">ACCEPTED</option>
            <option value="FAILED">FAILED</option>
          </FormControl>

          { this.state.query.status == 'FAILED'
            ? <FormControl 
                controlId="formControlsSelect"
                componentClass="select"   
                className="searchInput"
                onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.reasonForFailing = e.target.value; this.setState({ query }) }}
                >
                <option value="">Reason for failing</option>
                <option value="INVALID_REFERENCE">INVALID_REFERENCE</option>
                <option value="MISSING_REFERENCE">MISSING_REFERENCE</option>
                <option value="AMOUNT_TOO_LARGE">AMOUNT_TOO_LARGE</option>
                <option value="INVALID_ASSET">INVALID_ASSET</option>
                <option value="INVALID_CONTRACT">INVALID_CONTRACT</option>
                <option value="CODE_CREATION_FAILED">CODE_CREATION_FAILED</option>
              </FormControl>        
            : null }

          <div id="searchZone">
            <Button id="searchButton" onClick={()=>this.getResults(this.state.page)}>Search</Button>
            { this.props.options && ( this.props.options.export == false || this.props.options.locked )
              ? null
              : <Button id="searchButton" className="downloadButton" onClick={()=>this.download(this.state.query)}>
                  <i className="fas fa-file-download"></i>
                </Button> }
          </div>
        
        </div>

        { payments && payments.length > 0
            ? <div id="resultHeader">
                <div className="paymentNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getResults( 1 )
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getResults( Math.max( 1, page -1 ) )
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="navLegend">{ this.state.total } payments</div>
                  <div className="navPage">page {page} / {pages} </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getResults( Math.min( pages, page + 1 ) )
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getResults( pages )
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>
              </div>
            : null } 

        <div id="searchOutcome">
          { payments &&
            <PaymentsList
                payments={ payments } 
                paymentStatus={ this.state.query.status == 'FAILED'? 'FAILED' : 'Search' } 
                user={ this.props.user }
              /> }
        </div>

      </div>
    );
  }
}


export const SearchPayments = connect(
  function mapStateToProps(state) {
    return { 
      search: state.search, };
  },
  function mapDispatchToProps(dispatch) {
    return {
      chooseSearch: results => dispatch(chooseSearch(results)),
    };
  },
)(SearchFonction);
