import React from 'react';
import { FormControl, Button } from 'react-bootstrap/lib';

class SelectableAnswer extends React.Component {

  constructor(props) {
		super(props)
		this.state = {};
  } 

  componentDidUpdate(prevProps) {
    if( this.props.count !== prevProps.count ) this.setState({ newAnswer: undefined, translatedAnswer: undefined });
  }

  render() {
    let disabled = true
    if( 
      this.state.newAnswer && 
      ( !this.props.hasAvailabilityCondition || ( this.props.hasAvailabilityCondition && this.state.availabilityCondition ) ) 
    ) disabled = false

    let { questions, selectableWithConditionalAnswers, addAnswer, optionsConditionalOn } = this.props
    let question = questions.find( elem => elem._id + '' == optionsConditionalOn + '')
    
    let answers = question && question.options && question.options.length > 0
      ? question.options.map( elem => elem.translatedAnswer )
      : question? question.possibleAnswers : undefined

    let mappedAnswers = answers && answers
      .sort( (a, b) => a.rank - b.rank)
      .map( (elem, index) =>{ 
        return <option className="agentOption" key={index} value={elem}>{elem}</option>
      })

    return (
      <div>
        <FormControl
          type="text" 
          className={ ! selectableWithConditionalAnswers ? "addAnswer" : "conditionalAnswer"}
          placeholder="Type answer"
          onChange={(e)=> this.setState({ newAnswer: e.target.value }) }
        />
        <FormControl
          type="text" 
          className={ ! selectableWithConditionalAnswers ? "addAnswer localAnswer" : "conditionalAnswer localAnswer"}
          placeholder="English translation"
          onChange={(e)=>{ this.setState({ translatedAnswer: e.target.value }) }}
        />

        { selectableWithConditionalAnswers &&
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="conditionalAnswer condA"
            onChange={(e)=> this.setState({ availabilityCondition: e.target.value }) }
          > 
            <option value="" className="agentOption">Only available if previous answer is</option>
            { mappedAnswers }
          </FormControl> }

        <Button disabled={disabled} className="addQuestionAction" onClick={()=>{
         addAnswer( this.state.newAnswer, this.state.translatedAnswer, this.state.availabilityCondition ) 
        }}>
          Add
        </Button>
      </div>

    );
  }
}

export default SelectableAnswer;