import React, { Component } from 'react';
import InfoWrapper from './../../Utils/InfoWrapper'
import DoubleWrapper from './../../Utils/DoubleWrapper';
import history from './../../../../history';

var differenceInDays = require("date-fns/difference_in_days");


class Summary extends Component {
  
  handleNext(path){
    history.push(path)
  }

  render() {    

    let group = this.props.group

    var options = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'  };
    let signingDate = '-'
    let lastStatusUpdate = '-'
    let nextStatusUpdate = '-'
    let days = null
    let count = 0

    if(group.adminDates.signingDate){
      signingDate = new Date(group.adminDates.signingDate)
      signingDate = signingDate.toLocaleDateString("en-EN", options)
    }
    
    if(group.lastStatusUpdate){
      lastStatusUpdate = new Date(group.lastStatusUpdate)
      lastStatusUpdate = lastStatusUpdate.toLocaleDateString("en-EN", options)
    }
    
    if(group.nextStatusUpdate){
      nextStatusUpdate = new Date(group.nextStatusUpdate)
      nextStatusUpdate = nextStatusUpdate.toLocaleDateString("en-EN", options)
      count = differenceInDays( nextStatusUpdate, new Date() )
      if( count >= 0 ) days = 'In ' + count + ' days'
      else if( count < 0 ) days = -count + ' days ago' 
    }

    let status = group.onboardingStatus == 'Signed'? group.status : group.onboardingStatus 

    let colorCode = 'egreen'
    if(['LOCKED', 'Approved'].indexOf(status) > -1) colorCode = 'ewarning'
    if(['REPOSSESSED', 'WRITEOFF', 'Rejected'].indexOf(status) > -1) colorCode = 'edanger'
    if(['Pending'].indexOf(status) > -1) colorCode = 'eneutral'

    let totalPaid = 0
    let totalCost = 0
    if( group.type != 'Solidary' ){
      group.contracts.map( contract => {
        totalCost += contract.totalCost
        totalPaid += contract.totalPaid
      })
    }

    return (
      <div id="groupSummary">
        
        { group.type == 'Solidary' 
          ? <div>
              <div id="contractStatus" className={colorCode}>{status}</div>
              <div id="contractPct">{ group.paid && group.target? (Math.round(group.paid * 100 / group.target)):'0'} % paid</div>
            </div>
          : <div>
              <div id="contractStatus">Overall repayment</div>
              <div id="contractPct">{ Math.round(totalPaid * 100 / totalCost) }% paid </div>
            </div>
        }

        <InfoWrapper 
          label={"Group number"}
          data={ group.groupNumber }
        />
        <InfoWrapper 
          label={ "Size" }
          data={ group.contracts.length }
        />
        <InfoWrapper 
          label={ "Signing date" }
          data={ signingDate }
        />
        <InfoWrapper 
          label={ "Group name" }
          data={ group.groupName?group.groupName: '-' }
        />
        
        <InfoWrapper 
          label={ "Group type" }
          data={ group.type?group.type: '-' }
        />
      
        <InfoWrapper 
          label={"Already paid"}
          data={group.ccy + ' '+ totalPaid.toLocaleString() + ' paid'}
        />
        { group.type == 'Solidary' &&
          <div>
            <div className="infoWrapper">
              <p id="infoLabel">Next update</p>
              <p id="infoDataTwoThird">{ nextStatusUpdate }</p>
              { ['LOCKED', 'ENABLED'].indexOf(group.status) > -1 && group.onboardingStatus == 'Signed'
                ? <p id="infoDataOneThird" className={ count >= 0? 'EnabledWarning': 'LockedWarning' }>{ days }</p>
                : null }
            </div>
            <InfoWrapper 
              label={"Last update"}
              data={lastStatusUpdate}
            /> 
          </div> }

        { group.agent &&
          <div className="active" onClick={()=>this.handleNext('/agent/id='+group.agent.agentNumber)}>
            <InfoWrapper 
              label={"Agent"}
              isActive={ true }
              data={group.agent.profile.firstName + ' ' +group.agent.profile.lastName}
            />
          </div>
        }

        { group.type == 'Solidary' &&
          <div>
            <DoubleWrapper 
              label={"Pricing schedule"}
              data1={ "KES 3,000" }
              data2={' for 30 days'}
              />
          </div>
        }
        
       
      </div>
    );
  }
}

export default Summary