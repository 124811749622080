import React, { Component } from 'react';
import './../rate.css'
import history from '../../../../history';

export class RatesListItem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      show: false,
    }
  };   

  handleNext(path){
    history.push(path)
  }

  render() {
    let rate = this.props.rate
    
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let validFrom = '-'
    let validTo = '-'

    if( rate.validFrom ){
      validFrom = new Date(rate.validFrom)
      validFrom = validFrom.toLocaleDateString("en-EN", options)
    }
    if( rate.validTo ){
      validTo = new Date(rate.validTo)
      validTo = validTo.toLocaleDateString("en-EN", options)
    }

    let setBy = rate.setBy && rate.setBy.profile 
      ? rate.setBy.profile.firstName.slice(0,1) + ' ' + rate.setBy.profile.lastName
      : '-'
    
    return (
      <div className="rateItem">
        <div className="rateRate">1 {rate.baseCCY} = { rate.rate } {rate.secondaryCCY}</div>
        <div className="rateDate">{ validFrom }</div>
        <div className="rateDate">{ validTo }</div>
        <div className="rateSet">{ setBy }</div>
        <div className="ratePayments">{ rate.payments? rate.payments : 0 }</div>

      </div>
    )
  }  
}