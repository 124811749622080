import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from './../../history';
import { setActiveView } from './../../Actions/index';
import './home.css';

class Menu extends Component {

  handleNext(path){
    this.props.setActiveView( path );
    history.push( '/' + path )
  }
  
  render() {
    return ( 
      <div className="homeMenu">
        <div id="home_menu">  
          <div 
            onClick={()=>this.handleNext('analytics')} 
            className={ this.props.activeView == 'analytics'? "home_menu_item active_home_menu topMenu" : "home_menu_item topMenu" }
          >
            <i className="fas fa-chart-line"></i>
          </div>

          <div 
            onClick={()=>this.handleNext('portfolio-analytics')} 
            className={ this.props.activeView == 'portfolio-analytics'? "home_menu_item active_home_menu" : "home_menu_item" }
          >
            <i className="fas fa-wallet"></i>
          </div>

          <div 
            onClick={()=>this.handleNext('maps')} 
            className={ this.props.activeView == 'maps'? "home_menu_item active_home_menu" : "home_menu_item" }
          >
            <i className="fas fa-map-marker-alt"></i>
          </div>


          <div 
            onClick={()=>this.handleNext('population-analytics')} 
            className={ this.props.activeView == 'population-analytics'? "home_menu_item active_home_menu" : "home_menu_item" }
          >
            <i className="fas fa-house-user"></i>
          </div>

          <div 
            onClick={()=>this.handleNext('impact-analytics')} 
            className={ this.props.activeView == 'impact-analytics'? "home_menu_item active_home_menu bottonMenu" : "home_menu_item bottomMenu" }
          >
            <i className="fas fa-universal-access"></i>
          </div>

        </div>
      </div>
    );
  }
}

const AnalyticsMenu = connect(
  function mapStateToProps(state) {
    return { 
      activeView:state.activeView
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      setActiveView: view => dispatch(setActiveView(view))
    };
  },
)(Menu);

export default AnalyticsMenu
