import React, { Component } from 'react';
import PayrollsAction from './../Action/PayrollsAcion'
import './payrolls.css';
import history from './../../../../history';
import List from './Details/List';
import ListOfTargets from './Details/ListOfTargets';
import Agent from './Details/Agent';
import { API_URL, addToPayrolltList, removeFromPayrollList } from './../../../../Actions/index';
import { connect } from 'react-redux';
import axios from 'axios';

export class PayrollsListItem extends Component {
  
  constructor(props) {
		super(props)
		this.state = {
      edit: false,
      show: false,
      contracts: undefined
    }
  }
  
  edit() {
    this.setState({ edit: false });
    this.props.refresh()
  }

  handleNext(path){
    history.push(path)
  }

  select(){
    if(this.props.selectedPayrolls.indexOf(this.props.payroll._id) < 0){
      this.props.addToPayrolltList(this.props.payroll._id)
    } else this.props.removeFromPayrollList(this.props.payroll._id)
  }

  show(){
    this.setState({ show: !this.state.show });
    let body = {
      supplierId: this.props.payroll.supplier,
      contractNumbers: this.props.payroll.split.map( elem => elem.contract )
    }
    axios.post(API_URL + '/contracts/invoice-details/', body)
    .then((ret) => {
      this.setState({
        contracts: ret.data
      })
    })
  }

  render() {    
   
    let payroll = this.props.payroll
    let agent = payroll.agent

    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let dateAdded = new Date(payroll.dateAdded)
    dateAdded = dateAdded.toLocaleDateString("en-EN", options)

    let dateFrom = '-'
    if( payroll.dateFrom ){
      dateFrom = new Date(payroll.dateFrom)
      dateFrom = dateFrom.toLocaleDateString("en-EN", options)
    }

    let dateTo = '-'
    if( payroll.dateTo ){
      dateTo = new Date(payroll.dateTo)
      dateTo = dateTo.toLocaleDateString("en-EN", options)
    }

    let comment = ''
    if( payroll.comment ) comment = payroll.comment + '; '
    if( payroll.items && payroll.items.length ){
      payroll.items.forEach( elem => {
        comment += elem + '; '
      })
    }
    
    return (
      <div>
        <div className="payrollItem">
          <div id="payrollNumber" className='active' onClick={() => this.handleNext('/agent/id='+ agent.agentNumber)}>{ agent.agentNumber }</div>
          <div id="payrollName" className='active' onClick={() => this.handleNext('/agent/id='+ agent.agentNumber)}>{ agent.profile.firstName.slice(0,1) }. { agent.profile.lastName }</div>
          <div id="payrollAmount">{ payroll.ccy } { payroll.amount.toLocaleString() }</div>
          <div id="payrollDate">{ dateFrom }</div>
          <div id="payrollDate">{ dateTo }</div>
          <div id="payrollComment">{ comment }</div>
          
          { payroll.paid
            ? <div id="payrollPaid">Paid</div>
            : <div>
                <div id="payrollAction" className="active" onClick={()=>this.select()}>
                  { this.props.selectedPayrolls.indexOf(payroll._id) > -1 
                    ? <i className="fas fa-check-square"></i>
                    : <i className="far fa-check-square"></i> }              
                </div>  
                <div id="payrollAction" className="active" onClick={ () => this.setState({ edit: !this.state.edit }) }>
                  <i className="fas fa-pen-square"></i>
                </div>
              </div> }

          <div id="payrollAction" className="active" onClick={ () => this.show({ show: !this.state.show }) }>
            <i className="fas fa-eye"></i>
          </div>

        </div>
        
        { this.state.show && this.state.contracts &&
          <div id="payrollDetails">
            <div id="payrollDetailsNumber">Payroll number</div>
            <div id="payrollDetailsNumberData">{ payroll.agentInvoiceNumber }</div>
            <Agent agent={ agent }/>
            <List contracts={ this.state.contracts } payroll={ payroll }/>
            { payroll.hitTargets && payroll.hitTargets.length > 0 && <ListOfTargets payroll={ payroll }/> }
          </div> }
        
        { this.state.edit &&
          <PayrollsAction 
              show={this.state.edit} 
              onHide={this.edit.bind(this)}
              user={ this.props.user }
              payroll={ payroll }
          /> }

      </div>  
    );
  }
}

export const SelectablePayrollsListItem = connect(
  function mapStateToProps(state) {
    return { 
      selectedPayrolls: state.selectedPayrolls
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToPayrolltList: payroll => dispatch(addToPayrolltList(payroll)),
      removeFromPayrollList: payroll => dispatch(removeFromPayrollList(payroll))
    };
  },
)(PayrollsListItem);
