import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import './dealAction.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class EditDetails extends Component {

  constructor(props) {
    super(props);
    this.state= {
      deal: {},       
      ready: false
    }
  }; 

  componentDidMount() {
    let deal = Object.assign( this.state.deal, this.props.deal )
    this.setState({
      status: undefined,
      deal
    })
  }
  
  edit(){
    axios
      .put(API_URL + '/deals/amend/'+ this.props.deal.supplier + '/' + this.props.deal._id, {
        editedDeal: this.state.deal,
      })
      .then( ret => this.setState({ status: ret.data.message }) )
      .catch( err => this.setState({ status: 'Error' }) )
  }

  over(){
    this.setState({ status: undefined })
    this.props.onHide()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'} className='long'>
          { this.state.status }
        </div>
      );
    }
  }   

  render() {    
    let { deal, questionnaires } = this.props;

    let q = undefined
    let mappedQuestionnaires = this.props.questionnaires
      .filter( elem => !elem.survey && elem.status == 'Active' )
      .map((q, index) =>{ 
        return (
          <option key={index} value={q._id}>{ q.name }</option>
        )
      })
    if( deal.questionnaire ) q = questionnaires.find( elem => elem._id == deal.questionnaire ).name

    
    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Edit deal details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
           
            <div id="dealEditL">Name</div>
            <div id="dealEdit">
              <FormControl 
                type="text" 
                className="dealEditInput"
                value={ this.state.deal.dealName }
                onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.dealName = e.target.value; this.setState({ deal }) }}
              />
            </div>

            <div id="dealEditL">Questionnaire</div>            
            <div id="dealEdit">
              <FormControl 
                  className="dealEditInput"
                  controlId="formControlsSelect"
                  componentClass="select"               
                  onChange={(e)=>{ 
                    let deal = Object.assign({}, this.state.deal); 
                    deal.questionnaire = e.target.value; 
                    this.setState({ deal }) 
                  }}
                > 
                <option value="">{ deal.questionnaire ? q : 'Choose questionnaire' }</option>
                { mappedQuestionnaires }
              </FormControl>
            </div> 
            <div id="dealEditL">Approval step</div>            
            <div id="dealEdit">
              <FormControl 
                  className="dealEditInput"
                  controlId="formControlsSelect"
                  componentClass="select"               
                  onChange={(e)=>{ 
                    let deal = Object.assign({}, this.state.deal); 
                    if( e.target.value == 'Needed' ) deal.noApprovalNeeded = false
                    else deal.noApprovalNeeded = true
                    this.setState({ deal }) 
                  }}
                > 
                <option value="">{ deal.noApprovalNeeded ? 'Not needed' : 'Needed' }</option>
                <option value="Needed">Needed</option>
                <option value="Not needed">Not needed</option>
              </FormControl>
            </div> 


            <div id="dealEditL">Status</div>            
            <div id="dealEditShort">
              <FormControl 
                  className="dealEditInput"
                  controlId="formControlsSelect"
                  componentClass="select"               
                  onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.status = e.target.value; this.setState({ deal }) }}
                > 
                <option value={ this.props.deal.status }>{ this.props.deal.status }</option>
                <option value="Paused">Pause</option>
                <option value="Deleted">Delete</option>
                { this.props.deal.status == 'Paused' ? <option value="Active">Re-activate</option> : null }
              </FormControl>
            </div>  

            { this.state.deal.status == "Deleted" &&
              <div>
                <div id="dealWarning">You can only deleted deals for which there are no active contracts.
                <br/>Warning: this action is not reversible and this deal will no longer appear in your list.</div>
              </div>
            }

            { this.renderMessage() }            
            
            <div id="modal_action">
              
              { this.state.status !== 'Success' &&
                <Button id="action_signup" onClick={this.edit.bind(this)}>Edit</Button> }

              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditDetails