import React, { Component } from 'react';
import history from '../../../../history';

class Item extends Component {
  constructor(props) {
		super(props)
		this.state = {
      show: false,
    };
  }

  handleNext(path){
    history.push(path)
  }
  
  render() {
    var { item } = this.props

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let dateAdded = new Date(item.createdAt)
    dateAdded = dateAdded.toLocaleDateString("en-EN", options)  
    let from = item.tracking && item.tracking.receivedFrom && item.tracking.receivedFrom.profile
      ? item.tracking.receivedFrom.profile.firstName.slice(0, 1) + ' ' + item.tracking.receivedFrom.profile.lastName
      : '-'
   
    return (
      <div className="itemItem" >
        <div id="itemDeployDate" onClick={()=>this.setState({ show: !this.state.show })}>{ dateAdded }</div>
        <div id="itemDeploy" className="active" onClick={()=>this.handleNext('/contract/id=' + item.contract.contractNumber )}>{ item.contract? item.contract.contractNumber : '-' }</div>
        <div id="itemDeploy">{ item.asset? item.asset.paygNumber : '-' }</div>
        <div id="itemDeploy">{ from }</div>
        <div id="itemDeployQty">{ item.quantity }</div>
        { this.state.show && 
          <div id="itemDes">
            <div id="itemDesLabel">Note</div>
            <div id="itemDesData">{ item.note? item.note : '-' }</div>
            <div id="itemDesLabel">Entered by</div>
            <div id="itemDesData">{ item.tracking.enteredBy? item.tracking.enteredBy.userNumber : '-' }</div>
          </div>}
      </div>
    )
  }  
}

export default Item 