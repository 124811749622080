import React, { Component } from 'react';
import AnalyticsMenu from './AnalyticsMenu';

class Dashboard extends Component {
  
  render() {

    return ( 
      <div className='background'>
        <AnalyticsMenu user={this.props.user}/>
        <div id="home_background">
        </div>
      </div>
    );
  }
}

export default Dashboard
