import React, { Component } from 'react';
import Item from './Item'
import Header from './Header'

class ItemTransfersList extends Component {

  render() {    
    let { items, auxiliary } = this.props
    let mappedItems = items && items.map( (item, index) =>{ 
      return (
        <Item item={item} key={ index } auxiliary={auxiliary} />
      )
    })
    
    return (
      <div id="subItemsList">
        { items && <Header/>  }
        { mappedItems }
      </div>
    );
  }
}

export default ItemTransfersList