import React, { Component } from 'react';
import { Header } from './Header'
import { Item } from './Item'
import './payrollcontracts.css';

class List extends Component {
  
  render() {    
    let mappedContracts = null;
    let contracts = this.props.contracts
    if( contracts ){
      contracts.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      mappedContracts = contracts.map((contract, index) =>{ 
        return (
          <Item 
            key={ contract._id }
            contract={ contract } 
            payroll={ this.props.payroll }
          />
        )
      })
    }

    return (
      <div id="payrollLargeWrapper">
        <div id="payrollDetailsTitle">Details of contracts</div>
        <div id="payrollContractList">
          <Header/>
          { mappedContracts }
        </div>
      </div>
    );
  }
}

export default List