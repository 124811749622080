import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { findContracts } from  './../../../../services/ContractService'

class SyncApplications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
    }
  }; 

  componentDidMount() {
    this.getContracts();
  }

  getContracts = async () => {
    try {    
      let query =  {
        dealName: this.props.deal.dealName,
        onboardingStatus: { $in: [ 'Pending', 'Approved' ] }
      }
      let contracts = await findContracts( query, this.props.user._id, this.props.user.supplier )
      this.setState({ contracts })
    } catch(err){ console.log(err) }
  }

  sync = () => {
    let contractIds = this.state.contracts
      ? this.state.contracts.map( elem => elem._id )
      : []
    axios.put(API_URL + '/contracts/sync/'+ this.props.user.supplier, {
      contractIds: contractIds,
      dealName: this.props.deal.dealName,
      userId: this.props.user._id
    })
      .then((ret) => this.setState({ status: ret.data.message }) )
  }

  over(){
    this.props.onHide()
    this.setState({
      contracts: [],
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ? 'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  } 

  render() {
    
    let contracts  = null;
    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    if( this.state.contracts && this.state.contracts.length > 0 ){
      contracts = this.state.contracts
        .map( contract => { 
          return ( 
            <div 
              key={ contract._id } 
              className="contractSyncItem" 
            >
             { contract.contractNumber }
            </div> 
        )
      })
      
    }

    return (
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="large"
          aria-labelledby="contained-modal-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Update all pending { this.props.deal.dealName } applications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight" className='shiftedUp'>

              <div id="contractSyncListTitle">{ this.state.contracts? this.state.contracts.length : 'Loading' } applications found</div>
              <div id="contractSyncList">{ contracts }</div>
              <div id="contractSyncWarning">Warning: this action is irreversible</div>

              <div id="newDealAction">
                { ! this.state.status &&
                  <Button id="action_signup" onClick={this.sync.bind(this)}>Update applications</Button>
                }
                <Button id="action_cancel" onClick={()=> this.props.onHide()}>Close</Button>
              </div>  

              { this.renderMessage() }

            </div>
            
          </Modal.Body>
        </Modal>
    );
  }
}

export default SyncApplications