import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import TransfersList from './TransfersList/TransfersList'
import DeploysList from './DeploysList/DeploysList'
import './item.css'
var startOfMonth = require('date-fns/start_of_month')
var addDays = require('date-fns/add_days');

class TransfersController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      page: 1,
      total: 0,
      pages: 1
    };
  }

  componentDidUpdate(prevProps) {
    if( 
      this.props.status !== prevProps.status ||
      this.props.count !== prevProps.count ||
      this.props.quality !== prevProps.quality ||
      this.props.quality !== prevProps.quality ||
      this.props.auxiliary !== prevProps.auxiliary ||
      this.props.agent !== prevProps.agent ||
      this.props.type !== prevProps.type ||
      this.props.timeframe !== prevProps.timeframe
    ) this.getItemsList(this.props.status, 1, this.props.quality, this.props.auxiliary, this.props.agent, this.props.timeframe )
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0);
  }
   
	getItemsList = (status, fromPage, quality, auxiliary, agent, timeframe) => {
    let strt = startOfMonth(new Date())
    let end = addDays( strt, -1 )
    let timeFilter = timeframe
    if( timeframe == 'Current') timeFilter = { $gte: strt }
    if( timeframe == 'Last') timeFilter = {
      $gte: startOfMonth(end),
      $lte: end
    }
    axios
      .post(API_URL + '/items/search/' + this.props.user.supplier, {
        query: {
          status,
          quality,
          auxiliary,
          agent,
          "tracking.enteredOn": timeFilter
        },
        fromPage,
        pageSize: 25
      })
      .then((ret) => this.setState({ 
        items: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total 
      }))
  }

  loadData(){
    this.getItemsList(this.props.status, 1, this.props.quality, this.props.auxiliary, this.props.agent )
  }

  render() {    
    let { page, pages, items } = this.state;
    let { quality, auxiliary, status } = this.props

    return (  
      <div>
        { items && items.length > 0 &&
          <div className={ status == 'DEPLOYED'? "itemNavigator deploy": "itemNavigator" } >
            <div className="itemNavButton" onClick={() => {
              this.setState({ page: 1 })
              this.getItemsList(status, 1, quality, auxiliary)
            }}>
              <i className="fas fa-angle-double-left"></i>
            </div>
            <div className="itemNavButton" onClick={() => {
              this.setState({ page: Math.max( 1, page -1 ) })
              this.getItemsList(status, Math.max( 1, page -1 ), quality, auxiliary)
            }}>
              <i className="fas fa-angle-left"></i>
            </div>
            <div className="itemLegend">{ this.state.total } items</div>
            <div className="itemPage">page {page} / {pages} </div>
            <div className="itemNavButton" onClick={() => {
              this.setState({ page: Math.min( pages, page + 1 )})
              this.getItemsList(status, Math.min( pages, page + 1 ), quality, auxiliary)
            }}>
              <i className="fas fa-angle-right"></i>
            </div>
            <div className="itemNavButton" onClick={() => { 
              this.setState({ page: pages })
              this.getItemsList(status, pages, quality, auxiliary)
            }}>
              <i className="fas fa-angle-double-right"></i>
            </div>
          </div>} 
          
        { items && status !== 'DEPLOYED' && <TransfersList items={items} auxiliary={auxiliary} /> }
        { items && status == 'DEPLOYED' && <DeploysList items={items} auxiliary={auxiliary} /> }

      </div>

    );
  }
}

export default TransfersController