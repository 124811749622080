import React, { Component } from 'react';
import './conversation.css';

class Item extends Component {

  render() {    
    let note = this.props.note
    let author = note.writtenBy
      ? note.writtenBy
      : note.agentFrom && note.agentFrom.profile? note.agentFrom.profile.firstName + ' ' + note.agentFrom.profile.lastName : 'Automatic'
    var options = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'  };
    let date = '-'
    if(note.date){
      date = new Date(note.date)
      date = date.toLocaleDateString("en-EN", options)
    }

    return (
      <div id="convoItemWrapper" >
        <div className={ note.agentFrom? 'fromAgent' : 'fromAdmin' }>
          <div id="convoItemAuthor">{ author }</div>
          <div id="convoItemDate">{ date }</div>
          <div id="convoItemTxt" className={ note.agentFrom? 'TxtFromAgent' : 'TxtFromAdmin' }>{ note.text }</div>
        </div>
      </div>
    );
  }
}

export default Item