import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import {Button, FormControl} from 'react-bootstrap/lib';
import './../search.css';
import { chooseSearch } from '../../../../Actions/actions';
import { connect } from 'react-redux';
import AssetsList from './../../Assets/List/AssetsList'
import { findProducts } from  './../../../../services/ProductService'

var DatePicker = require("react-bootstrap-date-picker");

class SearchFonction extends Component {
  constructor(props) {
		super(props)
		this.state = {
      query: {},
      inStock:{},
      distributed:{},
      defective:{},
      page: 1,
      total: 0,
      pages: 1
    };
  }
  componentDidMount() {
    this.loadData()
  }


  loadData() {
    this.getProducts();
    this.getBatches();
  }

  getProducts = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products })
    } catch(err){ console.log(err) }
  }


  getBatches() {
    axios.get(API_URL + '/assets/getbatches/' + this.props.user.supplier)
			.then((ret) => { this.setState({ batches: ret.data }) })
  }

  getResults(fromPage){
    var body = {
      fromPage,
      user: this.props.user._id,
      pageSize: 200,
      query: this.state.query
    }
    axios.post(API_URL + '/assets/all/'+ this.props.user.supplier, body)
    .then((ret) => {
      this.setState({
        assets: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total  
      });
    })
  }

  download(){
    
    var body = {
      query: this.state.query,
      user: this.props.user._id,
      export: true
    }

    axios.post(API_URL + '/assets/search/'+ this.props.user.supplier, body)
      .then(response => {
        const url = window.URL.createObjectURL( new Blob([response.data], {
            type: 'text/csv'
            //type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          }) 
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'assets.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.state.query.export = false
      })  
  }

  render() {    
    let { page, pages, products, batches } = this.state


    let mappedProducts = products && products.map((product, index) =>{ 
      return (
        <option key={index} value={product._id}>{product.name} by {product.manufacturer}</option>
      )
    })

    let mappedBatches = batches && batches.map((batch, index) =>{ 
      return (
        <option key={index} value={batch.batchReference}>{batch.batchNumber}</option>
      )
    })

    return (
      <div>  
        <div className="activeSearchSection">         
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Payg number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.paygNumber = e.target.value; this.setState({ query }) }}
          />
          <FormControl
            type="text"
            className="searchInput"
            placeholder="Serial number"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.serialNumber = e.target.value; this.setState({ query }) }}
          />
          <FormControl
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.batchReference = e.target.value; this.setState({ query }) }}
            >
            <option value="">Select a batch</option>
            {mappedBatches}
          </FormControl>
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.product = e.target.value; this.setState({ query }) }}
            >
            <option value="">Select a product</option>
            {mappedProducts}
          </FormControl>
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.status = e.target.value; this.setState({ query }) }}
            >
            <option value="">Choose status</option>
            <option value="PENDING">Pending</option>
            <option value="INSTOCK">In stock</option>
            <option value="DEPLOYED">Deployed</option>
            <option value="DEFECTIVE">Defective</option>
            <option value="REPOSSESSED">Repossessed</option>
            <option value="WRITEOFF">Written off</option>
          </FormControl>
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Entered stock after"
              value={this.state.inStock.dateMin}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query["inStock.date"] = { $gte : new Date(e) };
                if( this.state.inStock.dateMax ) query["inStock.date"] = { $gte: new Date(e), $lte: new Date(this.state.inStock.dateMax ) }
                this.setState({ query }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>      
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Entered stock before"
              value={this.state.inStock.dateMax}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query["inStock.date"] = { $lte : new Date(e) };
                if( this.state.inStock.dateMin ) query["inStock.date"] = { $gte: new Date(this.state.inStock.dateMin), $lte: new Date(e ) }
                this.setState({ query }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>  
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Distributed after"
              value={this.state.distributed.dateMin}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query["distributed.date"]= { $gte : new Date(e)}
                if( this.state.distributed.dateMax ) query["distributed.date"] = { $gte: new Date(e), $lte: new Date(this.state.distributed.dateMax ) }
                this.setState({ query }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>      
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Distributed before"
              value={this.state.distributed.dateMax}  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query["distributed.date"] = { $lte : new Date(e) };
                if( this.state.distributed.dateMin ) query["distributed.date"] = { $gte: new Date(this.state.distributed.dateMin), $lte: new Date(e ) }
                this.setState({ query }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>    

          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query.stockEntryCount = e.target.value; 
              this.setState({ query }) 
            }}
            >
            <option value="">History</option>
            <option value={'Has been'}>Repossessed or replaced</option>
          </FormControl>

          <div id="searchZone">
            <Button id="searchButton" onClick={()=>this.getResults(this.state.page)}>Search</Button>
            { this.props.options && ( this.props.options.export == false || this.props.options.locked )
              ? null
              : <Button id="searchButton" className="downloadButton" onClick={()=>this.download()}>
                  <i className="fas fa-file-download"></i>
                </Button> }
          </div>
        </div>

        { this.state.assets && this.state.assets.length > 0 &&
          <div id="resultHeader">
            <div className="assetNavigator">
              <div className="navButton" onClick={() => {
                this.setState({ page: 1 })
                this.getResults( 1 )
              }}>
                <i className="fas fa-angle-double-left"></i>
              </div>
              <div className="navButton" onClick={() => {
                this.setState({ page: Math.max( 1, page -1 ) })
                this.getResults(Math.max( 1, page -1 ) )
              }}>
                <i className="fas fa-angle-left"></i>
              </div>
              <div className="navLegend">{ this.state.total } assets</div>
              <div className="navPage">page {page} / {pages} </div>
              <div className="navButton" onClick={() => {
                this.setState({ page: Math.min( pages, page + 1 )})
                this.getResults( Math.min( pages, page + 1 ) )
              }}>
                <i className="fas fa-angle-right"></i>
              </div>
              <div className="navButton" onClick={() => { 
                this.setState({ page: pages })
                this.getResults(pages )
              }}>
                <i className="fas fa-angle-double-right"></i>
              </div>
            </div>
          </div> } 


        <div id="searchOutcome">
          { this.state.assets &&<AssetsList assets={this.state.assets.slice(0, 500) } selectable={true} search={ true } /> }
        </div>
      </div>
    );
  }
}


export const SearchAssets = connect(
  function mapStateToProps(state) {
    return { 
      search: state.search, };
  },
  function mapDispatchToProps(dispatch) {
    return {
      chooseSearch: search => dispatch(chooseSearch(search)),
    };
  },
)(SearchFonction);
