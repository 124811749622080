import React, { Component } from 'react';
import { 
  Modal,  
} from 'react-bootstrap/lib';
import './../group.css';
import AddToGroup from './AddToGroup'
import RemoveFromGroup from './RemoveFromGroup'

class EditGroup extends Component {

  constructor(props) {
    super(props);
    this.state= {
      ready: false,
      disabled: false
    }
  }; 
  
 
  over(){
    this.props.onHide();
  }

  render() {    
    let { group, user } = this.props
    let action = this.state.action

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Edit group { group.groupNumber }</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div id="groupModal">          
          <div id="groupAction" onClick={()=>this.setState({ action: 'add' })}>Add to group</div>
          <div id="groupAction" onClick={()=>this.setState({ action: 'remove' })}>Remove from group</div>
          <div id="groupAction" onClick={()=>this.setState({ action: 'text' })}>Text group</div>
          
          { action == 'add' && <AddToGroup group={group} user={user}/>}
          { action == 'remove' && <RemoveFromGroup group={group} user={user}/>}

        </div>
      
      </Modal.Body>
    </Modal>
    );
  }
}

export default EditGroup