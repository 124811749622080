import React from 'react';
import {Button, Modal, FormControl} from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { findAgents } from  '../../../../services/AgentService'

class DefectiveAsset extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status: undefined,
      agentId: undefined,
      agents: undefined,
      disabled: false
    };
    this.loadData.bind(this)
  } 
  componentDidMount() {
    this.loadData()
  }

  faulty() {
    this.setState({disabled: true})
    let body = {
      assetId: this.props.asset._id,
      agentId: this.state.agentId,
      userNumber: this.props.user.userNumber
    }
    axios.put(API_URL + '/assets/faulty/'+ this.props.asset.supplier, body)
			.then((ret) => {
        this.setState({
          status: ret.data
        })
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getAgents();
  } 
  
  over(){
    this.props.onHide();
  }

  render() {

    let mappedAgents = null
    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })
    }
    
    let disabled = true
    if( !this.state.disabled && this.state.agentId ) disabled = false

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-sm">Defective asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">

            <div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="contractInput"
                onChange={(e)=>{ 
                  this.setState({ agentId: e.target.value });
                }}
              >
                <option value="">New holder (*)</option>
                { mappedAgents }
              </FormControl>
            </div>

            {this.renderMessage()}

            <div id="modal_action">

            { this.state.status !== 'Success' &&
              <Button id="action_signup" disabled={disabled} onClick={this.faulty.bind(this)}>Confirm</Button> }

              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DefectiveAsset;