import React, { Component } from 'react';
import {EnergyAccess} from './Graphs/EnergyAccess'
import {Generation} from './Graphs/Generation'
import {CO2} from './Graphs/CO2'

import './impact.css'

class ImpactController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      nav:1
    };
  }

  
  render() {  
    let navigate = null;
    if(this.state.nav===1) navigate = <EnergyAccess/> 
    if(this.state.nav===2) navigate = <CO2/>
    if(this.state.nav===3) navigate = <Generation/>
    
    return (
      <div id="impactPage">
        <div id="impactMenu">
          <div id="impactMenu_item" onClick={()=>this.setState({nav:1})} className={this.state.nav===1?"active_impactMenu":""}>Energy access</div>
          <div id="impactMenu_item" onClick={()=>this.setState({nav:2})} className={this.state.nav===2?"active_impactMenu":""}>CO2 emission</div>
          <div id="impactMenu_item" onClick={()=>this.setState({nav:3})} className={this.state.nav===3?"active_impactMenu":""}>Income generation</div>
        </div>
        <div id="impactMain">
          {navigate}
        </div>
      </div>
    );
  }
}

export default ImpactController