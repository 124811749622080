import React, { Component } from 'react';
import AnalyticsMenu from '../AnalyticsMenu'
import { Shotsplit } from '../Graphs/shot-split';
import Activity from '../Graphs/activity';
import GraphFilter from './GraphFilter'
import Progress from '../Graphs/progress'
import './finance.css'
import { connect } from 'react-redux';
import history from '../../../history'

class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      granularity: 'daily',
      showFilter: false,
    };
  }
  
  goBack() {
    window.history.back();
  }

  handleNext(path){
    history.push(path)
  }
  isActive(filter){
    if( filter.product || filter.deal || filter.agent || filter.manager ) return true
    else return false
  }
  
  render() {    
    let user = this.props.user

    return (
      <div className='background'>
        <div id="pageTitle">Portfolio analysis</div>
        <AnalyticsMenu user={ user}/>
        <div id="clientBackground">
          <div id="dash_menu">  
            <div id="dash_menu_item"  onClick={()=>this.setState({granularity: 'daily'})} className={this.state.granularity == 'daily'?"active_dash_menu leftMenu rightMenu":"leftMenu rightMenu"}>Overview</div>
            <div className={ this.isActive(this.props.filter) ? "contractBackActive cBL": "contractBack cBL" }  onClick={()=>this.setState({ showFilter: !this.state.showFilter })}><i className="fas fa-filter"></i></div>
            <div className="contractBack" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
          </div>

          { this.state.showFilter || this.isActive(this.props.filter)
            ? <GraphFilter user={ user } noDate={true} />
            : null }

          <div id="graphSection">    
            <Progress 
              user={ user } 
              filter={ this.props.filter }
              width={ 100 }
              height={ 350 }
            />
          </div>

          <div id="graphSection">     
            <Activity 
              user={ user } 
              filter={ this.props.filter }
              color= {'rgba(255, 255, 255, 0.7)'}
              width={ 100 }
              height={ 350 }
            />
          </div>

          <div id="graphSection">        
            <div id="graphTitle">Utilisation rate</div>
            <div id="graphWrapper">
              <Shotsplit 
                user={ user } 
                filter={ this.props.filter }
                color= {'rgba(255, 255, 255, 0.7)'}
                width={ 100 }
                height={ 350 }
              />
            </div>
          </div>

       

        

        </div>
      </div>
    );
  }
}

const RevenuesController = connect(
  function mapStateToProps(state) {
    return { 
      filter: state.filter
    };
  }
)(Controller);

export default RevenuesController