import React, { Component } from 'react';
import { SearchAssets } from '../Search/SearchItems/SearchAssets'
import ManageMenu from '../ManageMenu'
import history from '../../../history';

class AssetsSearch extends Component {
  constructor(props) {
		super(props)
		this.state = {
      assets: undefined,
      dealers: undefined,
      assets: undefined,
      search: 'assets',
    };
  }

  handleChangeSearch(search){
    this.props.chooseSearch(search)
    this.setState({ search: search })
  }

  componentDidMount() {
    if( this.props.search && this.props.search.length > 0 ) {
      this.setState({ search: this.props.search })
    } 
  }
  
  goBack() {
    window.history.back();
  }

  render() {    
    
    let user = this.props.user
    let options = this.props.user.options
      
    return (
      <div className='background'>
        <div id="pageTitle">
          Search assets
          <span id="returnButton" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></span>
        </div>
        <ManageMenu user={ user }/>
        <div id="clientBackground">
          <div id="individualSearchMenu">  
            <SearchAssets supplier={ user.supplier } search={ 'assets' } options={ options } user={ user }/>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetsSearch