import React, { Component } from 'react';
import { Revenues } from '../Graphs/revenues'
import { Analysis } from '../Graphs/analysis'
import { AssetAnalysis } from '../DashboardAsset/Graphs/assetAnalysis'
import AnalyticsMenu from './../AnalyticsMenu';
import GraphFilter from './GraphFilter'
import './finance.css'
import { connect } from 'react-redux';
import history from './../../../history';


class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      granularity: 'daily',
      showFilter: false,
    };
  }
  
  goBack() {
    window.history.back();
  }

  handleNext(path){
    history.push(path)
  }
  isActive(filter){
    if( filter.product || filter.deal || filter.agent || filter.manager ) return true
    else return false
  }
  
  render() {    
    let user = this.props.user
    let options = user.options
    let entities = user.entityOptions

    let ccy = options.ccys && options.ccys.length > 0? '(' + options.ccys[0] + ')': ''

    return (
      <div className='background'>
        <div id="pageTitle">Growth analytics</div>
        <AnalyticsMenu user={ user}/>
        <div id="clientBackground">
          <div id="dash_menu">  
            <div id="dash_menu_item"  onClick={()=>this.setState({granularity: 'daily'})} className={this.state.granularity == 'daily'?"active_dash_menu leftMenu":"leftMenu"}>Daily</div>
            <div id="dash_menu_item" onClick={()=>this.setState({granularity: 'monthly'})} className={this.state.granularity == 'monthly'?"active_dash_menu":""}>Monthly</div>
            <div id="dash_menu_item"  onClick={()=> this.handleNext('/analytics/usage')} className={this.state.granularity == 'Usage'?"active_dash_menu rightMenu":"rightMenu"}>Usage</div>
            <div className={ this.isActive(this.props.filter) ? "contractBackActive cBL": "contractBack cBL" }  onClick={()=>this.setState({ showFilter: !this.state.showFilter })}><i className="fas fa-filter"></i></div>
            <div className="contractBack" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
          </div>

          { this.state.showFilter || this.isActive(this.props.filter)
            ? <GraphFilter user={ user } entities={ entities } />
            : null }

          <div id="graphSection">        
            <div id="graphTitle">Revenues {ccy}</div>
            <div id="graphWrapper">
              <Revenues 
                user={ user} 
                filter={ this.props.filter }
                granularity={this.state.granularity}
                option={'revenue'}
                color= {'rgba(255,255,255, 0.9)'}
                hoverColor={'rgb(255, 255, 255)'}
                width={ 100 }
                height={ 350 }
              />
            </div>
          </div>
          <div id="graphSection">        
            <div id="graphTitle">New signings</div>
            <div id="graphWrapper">
              <Analysis 
                user={ user } 
                freq={ this.state.granularity }
                filter={ this.props.filter }
                type={ 'DEPLOYMENT' }
                color= {'rgba(255,255,255, 0.9)'}
                hoverColor={'rgb(255, 255, 255)'}
                width={ 100 }
                height={ 350 }
              />           
            </div>
          </div>
          <div id="graphSection">        
            <div id="graphTitle">Full repayments</div>
            <div id="graphWrapper">
              <Analysis 
                user={ user } 
                freq={this.state.granularity}
                filter={ this.props.filter }
                type={'PAIDOFF'}
                color= {'rgb(0,139,139)'}
                hoverColor={'rgb(255, 255, 255)'}
                width={ 100 }
                height={ 350 }
              /> 
            </div>
          </div>               

          <div id="graphSection">        
            <div id="graphTitle">Repossessions</div>
            <div id="graphWrapper">
              <Analysis 
                user={ user} 
                freq={this.state.granularity}
                filter={ this.props.filter }
                type={'REPOSSESSED'}
                color= {'rgba(220,20,60, 0.9)'}
                hoverColor={'rgb(255, 255, 255)'}
                width={ 100 }
                height={ 350 }
              /> 
            </div>
          </div>  

          <div id="graphSection">        
          <div id="graphTitle">Defective units</div>
          <div id="graphWrapper">
            <AssetAnalysis 
              user={ user }
              filter={ this.props.filter }
              freq={ this.state.granularity }
              color= {'rgba(220,20,60, 0.9)'}
              hoverColor={'rgb(255, 255, 255)'}
              type={ 'DEFECTIVE' }
              width={ 100 }
              height={ 350 }
            />
          </div>
        </div>   

        </div>
      </div>
    );
  }
}

const RevenuesController = connect(
  function mapStateToProps(state) {
    return { 
      filter: state.filter
    };
  }
)(Controller);

export default RevenuesController