import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderIn from './HeaderLoggedIn';
import HeaderOut from './HeaderLoggedOut';


class ChangingHeader extends Component {
  render() {
    let navigate;
    if(this.props.authenticated){ navigate = <HeaderIn/>}
    else navigate = <HeaderOut/>
    
    return (
      <div>
        {navigate}
      </div>
    );
  }
}

const Header = connect(
  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  },
)(ChangingHeader);

export default Header;