import React from 'react';
import { Doughnut } from 'react-chartjs-2';

class Split extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
      },
      data: undefined
    };
  };

  render() {
    let contracts = this.props.contracts 
    let label = ['Enabled', 'Locked', 'Paidoff', 'Repossessed', 'Writeoff']
    let enabled = 0
    let locked = 0
    let paidoff = 0
    let repossessed = 0
    let writeoff = 0
    if( contracts ){
      contracts.map( elem => {
        if( elem.status == 'ENABLED' ) enabled +=1
        if( elem.status == 'LOCKED' ) locked +=1
        if( elem.status == 'PAIDOFF' ) paidoff +=1
        if( elem.status == 'REPOSSESSED' ) repossessed +=1
        if( elem.status == 'WRITEOFF' ) writeoff +=1
      })
    }
    let dataset = [ enabled, locked, paidoff, repossessed, writeoff ]

    return (
      <div id="groupSummary">
        <div id="groupGraphTitle">{ this.props.title }</div>
        <div id="groupGraph">
          <Doughnut 
            data={{
              labels: label, 
              datasets: [
                {
                  label: '$',
                  backgroundColor: [
                    'white',
                    'rgba(252, 198, 48, 0.9)',
                    'rgba(0,139,139, 0.8)',
                    '#CA193F',
                    'red'
                  ],
                  borderColor: '#26455A',
                  borderWidth: 1,
                  hoverBackgroundColor: [
                    'white',
                    'rgba(252, 198, 48, 1)',
                    'rgba(0,139,139, 1)',
                    '#CA193F',
                    'red'
                  ],
                  hoverBorderColor: [
                    'white',
                    'rgba(252, 198, 48, 1)',
                    'rgba(0,139,139, 1)',
                    '#CA193F',
                    'red'
                  ],
                  data: dataset
                }
              ]
            }}
            width={ this.props.width }
            height={ this.props.height }
            options={ this.state.options }
          /> 
        </div>
      </div>      
    );
  }
}

export default Split