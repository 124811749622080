import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import { connect } from 'react-redux';
import './dealAction.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import MonthChooser from './MonthChooser'
import EnableAgents from './EnableAgents'
import { findProducts } from  '../../../../services/ProductService'
import { findQuestionnaires } from  '../../../../services/QuestionnaireService'
import { findEntitiesList, filterByEntity } from  '../../../../services/EntityService'
import { SelectEntity } from './../../Entities/Action/SelectEntity'

class Deals extends Component {

  constructor(props) {
    super(props);
    this.state= {
      deal: {
        pricingSchedule:{
          flexibleRates:{},
          flexibleMonths:{},
        },
        backPay: 'Off',
        floating: false,
        restricted: false,
        restrictedTo: [],
        createdBy: this.props.user._id,
        supplier: this.props.user.supplier
      },    
      group:{
        solidary: false,
        eligibleDeals: [],
      },   
      ready: false,
      enableAgents: false,
      disabled: false
    }
  }; 

  componentDidMount() {
    this.loadData()
    this.setState({status: undefined})
  }
      
  getProducts = async () => {
    try {
      let products = await findProducts( { status: 'Active' }, this.props.user._id, this.props.user.supplier )
      this.setState({ products, filteredProducts: products })
    } catch(err){ console.log(err) }
  }

  getQuestionnaires = async () => {
    try {
      let questionnaires = await findQuestionnaires( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ questionnaires, filteredQuestionnaires: questionnaires })
    } catch(err){ console.log(err) }
  }

  getEntities = async () => {
    try {
      let entities = await findEntitiesList( this.props.user._id, this.props.user.supplier )
      this.setState({ entities })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getProducts();
    this.getQuestionnaires();
    if( this.props.user.options.entities ) this.getEntities()
  }  

  selectAgents = (restrictedTo) => {
    let deal = Object.assign({}, this.state.deal); 
    deal.restrictedTo = restrictedTo; 
    this.setState({ deal });    
  }

  selectDeal( id ){
    let group = Object.assign({}, this.state.group); 
    let list = []
    if( group.eligibleDeals.indexOf( id ) < 0 ) list = [...group.eligibleDeals, id]
    else list = group.eligibleDeals.filter( elem => elem !== id )
    group.eligibleDeals = list
    this.setState({ group })
  }

  create() {
    let deal = Object.assign({}, this.state.deal); 
    deal.pricingSchedule.flexibleMonths.highRateMonths = this.props.selectedMonths.highRateMonths; 
    deal.pricingSchedule.flexibleMonths.mediumRateMonths = this.props.selectedMonths.mediumRateMonths; 
    deal.pricingSchedule.flexibleMonths.lowRateMonths = this.props.selectedMonths.lowRateMonths; 
    this.setState({ deal });
    axios
      .post(API_URL + '/deals/create/'+ this.props.user.supplier, this.state.deal)
      .then((ret) => {
          this.setState({ status: ret.data.message });
          this.props.refresh()
      }).catch(err => {
        this.setState({status: err.message})
      }); 
  }

  closeEnableAgents() {
    this.setState({ enableAgents: false });
  }

  selectEntity = (entity) => {
    let { questionnaires, products, entities } = this.state
    let deal = Object.assign({}, this.state.deal); 
    deal.entity = entity
    let filteredQuestionnaires = filterByEntity( questionnaires, entities, entity) 
    let filteredProducts = filterByEntity( products, entities, entity) 
    this.setState({ deal, filteredQuestionnaires, filteredProducts }) 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ? 'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  } 

  render() {    

    let user = this.props.user
    let options = user.options
    let { deal, filteredProducts, filteredQuestionnaires, entities } = this.state
    
    let mappedProducts = filteredProducts && filteredProducts
      .filter( elem => elem.status != 'Paused' )
      .map( elem =>{ 
        return <option key={elem._id} value={elem._id}>{ elem.name }</option>
      })

    let entity = undefined
    if( deal.entity && entities ) entity = entities.find( elem => elem._id + '' == deal.entity + '' )

    let mappedQuestionnaires = filteredQuestionnaires && filteredQuestionnaires
      .filter( elem => !elem.survey && elem.status == 'Active' )
      .map( q =>{ 
        return <option key={q._id} value={q._id}>{ q.name }</option>
      })

    let isDisabled = true

    if( 
      deal.type == 'FULL' 
      && deal.dealName 
      && deal.totalCost >= 0
      && deal.ccy 
    ) isDisabled = false

    else if( 
      deal.type == 'PERPETUITY' 
      && deal.dealName 
      && deal.ccy 
      && deal.pricingSchedule.minPayment 
      && deal.pricingSchedule.recurrentPayment 
      && deal.pricingSchedule.upfrontPayment 
    ) isDisabled = false
      
    if( 
      deal.type == 'INSTALMENTS' 
      && deal.dealName 
      && deal.totalCost 
      && deal.ccy 
      && deal.pricingSchedule.minPayment 
      && deal.pricingSchedule.recurrentPayment 
      && deal.pricingSchedule.upfrontPayment 
    ) isDisabled = false

    else if(       
      ( this.state.deal.type == 'PAYG' || this.state.deal.type == 'ADD-ON' )
      && deal.dealName 
      && deal.totalCost >= 0
      && deal.ccy 
      && deal.pricingSchedule.recurrentPayment >= 0
      && deal.pricingSchedule.upfrontPayment >= 0
      && deal.pricingSchedule.upfrontDays >= 0
      && deal.pricingSchedule.freq >= 0
      && deal.pricingSchedule.minPayment >= 0
    ) isDisabled = false

    if( 
      deal.floating
      && ( !deal.secondaryCCY || deal.secondaryCCY == deal.ccy )
    ) isDisabled = true

    if( this.state.disabled ) isDisabled = true  

    let ccyOptions = null;
    if( options ){
      ccyOptions = options.ccys.map( (ccy, index) =>{ 
        return <option key={index} value={ccy}>{ccy}</option>
      })
    }

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">
            <div className="modalCreateTitle">Create new deal</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          { ( 
            ( entities && entities.length > 1 && !this.state.cat && !this.props.existingDeal ) || 
            options.restrictedDeals 
          ) &&
            <div  className="dealCreate">
              <div id="dealSubTitle">Accessibility</div>
              <div id="newDealLabel">Entity</div>
              <div id="newDealHalf" className="dealCreateLeft">
                <SelectEntity
                  selectEntity={ this.selectEntity } 
                  entities={ entities }
                  format={ "dealDetailsInput" }
                  supplierId={ this.props.user.supplier }
                  userId={ this.props.user._id }
                />
              </div>
              { ! entity 
                ? <div id="newDealHalf" className="dealComment">
                    (leave empty to create a global deal)
                  </div>
                : <div id="newDealHalf" className="dealComment redText">
                    will only be accessible to  {entity.name}
                  </div>
              }

              { options.restrictedDeals &&
                <div id="dealSection">
                  <div id="newDealLabel">
                    Restricted deal
                  </div>   
                  <div id="newDealWithComment">
                    <FormControl 
                      controlId="formControlsSelect"
                      componentClass="select" 
                      className="dealDetailsInput"
                      onChange={(e)=>{ 
                        let deal = Object.assign({}, this.state.deal); 
                        deal.restricted = e.target.value; 
                        this.setState({ deal: deal, enableAgents: true });
                        }}
                      >
                      <option value="">False </option>
                      <option value={ false }>False</option>
                      <option value={ true }>True</option>              
                    </FormControl>
                  </div>  
                  <div id="longComment">
                    If "True", only accessible to selected agents
                  </div>
                </div>}

            </div> }    

          <div className="dealCreate">
            <div id="dealSubTitle">Description</div>
            <div id="newDealLabel">
              Deal name
            </div>
            <div id="newDeal">
              <FormControl 
                type="text" 
                className="dealDetailsInput"
                placeholder={this.props.existingDeal?this.props.existingDeal.dealName:'xx'}
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.dealName = e.target.value; 
                  this.setState({ deal }) 
                }}
              />
            </div>           
            <div id="newDealLabel">Product</div>
            <div id="newDeal">
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="dealDetailsInputChooser"
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.eligibleProduct = e.target.value; 
                  let product = this.state.products.filter( elem => 
                    elem._id === e.target.value
                  );
                  deal.lockable = product[0] && product[0].lockable? product[0].lockable : false;
                  if( deal.type == 'PAYG' && !deal.lockable ) deal.type = 'INSTALMENTS'
                  if( deal.type == 'INSTALMENTS' && deal.lockable ) deal.type = 'PAYG'
                  this.setState({ deal });
                  }}
                >
                <option value="">Select one</option>
                { mappedProducts }
              </FormControl>
            </div>   

            <div id="newDealLabel">Pricing scheme</div> 
            
            <div id="newDeal">
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="dealDetailsInputChooser"
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.type = e.target.value; 
                  if( e.target.value == 'FULL' ) {
                    deal.noQuestionnaireNeeded = true
                    deal.noApprovalNeeded = true
                  } else {
                    deal.noQuestionnaireNeeded = undefined
                    deal.noApprovalNeeded = undefined
                  }
                  this.setState({ deal }) 
                }}
              >
                <option value="">Select one</option>
                <option value={this.state.deal.lockable? 'PAYG': 'INSTALMENTS'}>{this.state.deal.lockable? 'PAYG': 'Instalments'} </option>
                <option value="FULL">Full payment</option>
                { !this.state.deal.lockable? <option value="ADD-ON">Add-on</option> : null }
                <option value="PERPETUITY">Perpetuity</option>
              </FormControl>
            </div>  

            { this.state.deal.type !== "PERPETUITY"
              ? <div>
                  <div id="newDealLabel">Total cost</div>
                  <div id="newDealHalf" className="dealCreateLeft">
                    <FormControl 
                      type="text" 
                      className="dealDetailsInput"
                      placeholder={this.props.existingDeal?this.props.existingDeal.totalCost:'Total cost'}
                      onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.totalCost = e.target.value; this.setState({ deal }) }}
                    />
                  </div>  
                  <div id="newDealHalf">
                    <FormControl 
                      className="dealDetailsInputChooser"
                      controlId="formControlsSelect"
                      componentClass="select"               
                      onChange={(e)=>{ 
                        let deal = Object.assign({}, this.state.deal); 
                        deal.ccy = e.target.value; 
                        this.setState({ deal }) 
                      }}
                    > 
                      <option value="">Choose</option>
                      {ccyOptions}
                    </FormControl>
                  </div>  
                </div>
              : <div>
                  <div id="newDealLabel">
                    Currency
                  </div> 
                  <div id="newDealHalf">
                    <FormControl 
                      className="dealDetailsInputChooser"
                      controlId="formControlsSelect"
                      componentClass="select"               
                      onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.ccy = e.target.value; this.setState({ deal }) }}
                    > 
                      <option value="">Choose</option>
                      {ccyOptions}
                    </FormControl>
                  </div>  
                </div> 
                }
          
            { deal.type == 'FULL'
              ? null
              : <div>
                  <div id="dealSubTitle">Pricing schedule</div>
                  <div id="newDealLabel">Upfront payment</div>    
                  <div id="newDealWithComment">
                    <FormControl 
                      type="text" 
                      className="dealDetailsInput"
                      placeholder={this.props.existingDeal?this.props.existingDeal.pricingSchedule.upfrontPayment:'Upfront'}
                      onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.pricingSchedule.upfrontPayment = e.target.value; this.setState({ deal }) }}
                    />
                  </div>

                  { deal.lockable || ['ADD-ON', 'INSTALMENTS', 'PERPETUITY'].indexOf(deal.type) > -1 ?
                    <div>
                      <div id="newDealComment">
                        with 
                      </div>  
                      <div id="newDealWithComment">
                        <FormControl 
                          type="text" 
                          className="dealDetailsInput"
                          placeholder={this.props.existingDeal?this.props.existingDeal.pricingSchedule.upfrontDays:'x'}
                          onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.pricingSchedule.upfrontDays = e.target.value; this.setState({ deal }) }}
                        />
                      </div>   
                      <div id="newDealCommentLong">
                        days 
                      </div> 
                    </div>  
                    : <div id="dealWhite">&nbsp;</div> }

                  <div id="newDealLabel">
                    Recurring payment
                  </div>
                  <div id="newDealWithComment">
                    <FormControl 
                      type="text" 
                      className="dealDetailsInput"
                      placeholder={this.props.existingDeal?this.props.existingDeal.pricingSchedule.recurrentPayment:'Recurring'}
                      onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.pricingSchedule.recurrentPayment = e.target.value; this.setState({ deal }) }}
                    />
                  </div>  

                  { deal.lockable || ['ADD-ON', 'INSTALMENTS', 'PERPETUITY'].indexOf(deal.type) > -1 ?
                    <div>           
                      <div id="newDealComment">
                        for 
                      </div>  
                      <div id="newDealWithComment">
                        <FormControl 
                          type="text" 
                          className="dealDetailsInput"
                          placeholder={this.props.existingDeal?this.props.existingDeal.pricingSchedule.freq:'x'}
                          onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.pricingSchedule.freq = e.target.value; this.setState({ deal }) }}
                        />
                      </div> 
                      <div id="newDealCommentLong">
                        days 
                      </div> 
                    </div>
                    : <div id="dealWhite">&nbsp;</div> }
                          
                  <div id="newDealLabel">
                    Min payment
                  </div>
                  <div id="newDealHalf" className="dealCreateLeft">
                    <FormControl 
                      type="text" 
                      className="dealDetailsInput"
                      placeholder={this.props.existingDeal?this.props.existingDeal.pricingSchedule.minPayment:'Minimum'}
                      onChange={(e)=>{ let deal = Object.assign({}, this.state.deal); deal.pricingSchedule.minPayment = e.target.value; this.setState({ deal }) }}
                    />
                  </div>  
                  <div id="newDealHalf">
                    <FormControl 
                      controlId="formControlsSelect"
                      componentClass="select" 
                      className="dealDetailsInput"
                      onChange={(e)=>{ 
                        let deal = Object.assign({}, this.state.deal); 
                        deal.backPay = e.target.value; 
                        this.setState({ deal }) 
                      }}
                    >
                      <option value="">Backpay option</option>
                      <option value="On">With backpay</option>
                      <option value="Off">No backpay</option>
                    </FormControl>
                  </div>  

                  { options.floatingDeals &&
                    <div>
                      <div id="newDealLabel">
                        Variable pricing
                      </div>   
                      <div id="newDeal">
                        <FormControl 
                          controlId="formControlsSelect"
                          componentClass="select" 
                          className="dealDetailsInput"
                          onChange={(e)=>{ 
                            let deal = Object.assign({}, this.state.deal); 
                            deal.floating = e.target.value; 
                            this.setState({ deal });
                            }}
                          >
                          <option value="">Variable exchange rate</option>
                          <option value={false}>False </option>
                          <option value={true}>True </option>              
                        </FormControl>
                      </div>  
                    </div>
                  }
                  
                  { options.floatingDeals && deal.floating &&
                    <div>
                      <div id="newDealLabel">
                        Secondary currency
                      </div>   
                      <div id="newDealHalf">
                        <FormControl 
                          className="dealDetailsInput"
                          controlId="formControlsSelect"
                          componentClass="select"               
                          onChange={(e)=>{ 
                            let deal = Object.assign({}, this.state.deal); 
                            deal.secondaryCCY = e.target.value; 
                            this.setState({ deal }) 
                          }}
                        > 
                          <option value="">Choose ccy</option>
                          {ccyOptions}
                        </FormControl>
                      </div> 
                    </div>
                  }

                  { options.flexibleDeals &&
                    <div>
                      <div id="newDealLabel">
                        Flexible schedule
                      </div>   
                      <div id="newDeal">
                        <FormControl 
                          controlId="formControlsSelect"
                          componentClass="select" 
                          className="dealDetailsInput"
                          onChange={(e)=>{ 
                            let deal = Object.assign({}, this.state.deal); 
                            deal.pricingSchedule.isFlexible = e.target.value; 
                            this.setState({ deal });
                            }}
                          >
                          <option value={false}>False </option>
                          <option value={true}>True </option>              
                        </FormControl>
                      </div>  
                    </div>
                  }
    
                  { deal.pricingSchedule.isFlexible &&
                    <div >
                      <div id="flexPricing">
                        <div id="flexLabel">
                          High rate
                        </div>
                        <div id="flexInput">
                          <FormControl 
                            type="text" 
                            className="dealDetailsInput"
                            placeholder={'High rate'}
                            onChange={(e)=>{ 
                              let deal = Object.assign({}, this.state.deal); 
                              deal.pricingSchedule.flexibleRates.highRate = e.target.value; 
                              this.setState({ deal }) }}
                          />
                        </div>      
                        <div id="flexLabel">
                          Medium rate
                        </div>
                        <div id="flexInput">
                          <FormControl 
                            type="text" 
                            className="dealDetailsInput"
                            placeholder={'Medium rate'}
                            onChange={(e)=>{ 
                              let deal = Object.assign({}, this.state.deal); 
                              deal.pricingSchedule.flexibleRates.mediumRate = e.target.value; 
                              this.setState({ deal }) }}
                          />
                        </div>       
                        <div id="flexLabel">
                          Low rate
                        </div>
                        <div id="flexInput"> 
                          <FormControl 
                            type="text" 
                            className="dealDetailsInput"
                            placeholder={'Low rate'}
                            onChange={(e)=>{ 
                              let deal = Object.assign({}, this.state.deal); 
                              deal.pricingSchedule.flexibleRates.lowRate = e.target.value; 
                              this.setState({ deal }) }}
                          />
                        </div> 
                      </div>   
                      <div id="flexMonths">   
                        <MonthChooser/>
                      </div>
                    </div>} 

              </div> }

              <div id="dealSubTitle">Data collection</div>


              { filteredQuestionnaires && filteredQuestionnaires.length > 0 &&
                <div>
                  <div id="newDealLabel">
                    On-boarding questionnaire
                  </div>
                  <div id="newDeal">
                    <FormControl 
                      className="dealDetailsInput"
                      controlId="formControlsSelect"
                      componentClass="select"               
                      onChange={(e)=>{ 
                        let deal = Object.assign({}, this.state.deal); 
                        if( e.target.value == 'None') deal.noQuestionnaireNeeded = true; 
                        else {
                          deal.questionnaire = e.target.value; 
                          deal.noQuestionnaireNeeded = undefined
                        }
                        this.setState({ deal }) 
                      }}
                    > 
                      <option value="">Choose (optional)</option>
                      <option value="None">No on-boarding questionnaire needed</option>
                      { mappedQuestionnaires }
                    </FormControl>
                  </div>  

                  <div id="newDealLabel">
                    Approval step
                  </div>
                  <div id="newDeal">
                    <FormControl 
                      className="dealDetailsInput"
                      controlId="formControlsSelect"
                      componentClass="select"               
                      onChange={(e)=>{ 
                        let deal = Object.assign({}, this.state.deal); 
                        if( e.target.value == 'None') deal.noApprovalNeeded = true; 
                        else deal.noApprovalNeeded = undefined; 
                        this.setState({ deal }) 
                      }}
                    > 
                      <option value="">
                        { this.state.deal.type !== 'FULL' ? 'Needed': 'Not needed'}
                      </option>
                      <option value="Needed">Needed</option>
                      <option value="None">Not needed</option>
                    </FormControl>
                  </div>  
              
                  { this.state.deal.type !== 'FULL'
                  ? <div>
                      <div id="newDealDes">An approval step will be required by default</div>
                      <div id="newDealDes">If no questionnaire is specified, the first questionnaire in your list of questionnaires will be used by default.</div>
                    </div>
                  : <div id="newDealDes">For full upfront deals, the default is to have no onboarding questionnaire and no approval step.</div> }

                </div>}

                
          { options.manageAgents &&
            <div>
                  <div id="dealSubTitle">Commission structure</div>
                  <div id="newDealLabel">
                    Attached agent commission
                  </div>   
                  <div id="newDealComment">
                    { options.ccys[0] } 
                  </div>  
                  <div id="newDealWithComment">
                    <FormControl 
                      type="text" 
                      className="dealDetailsInput"
                      placeholder={'xx (optional)'}
                      onChange={(e)=>{ 
                        let deal = Object.assign({}, this.state.deal); 
                        deal.commission = e.target.value; 
                        this.setState({ deal }) 
                      }}
                    />
                  </div> 
                  <div id="newDealCommentLonger">
                    per new signing 
                  </div>
                  <div id="newDealLabel">&nbsp;</div>   
                  <div id="newDealComment">or</div>  
                  <div id="newDealWithComment">
                    <FormControl 
                      type="text" 
                      className="dealDetailsInput"
                      placeholder={'0.01 (optional)'}
                      onChange={(e)=>{ 
                        let deal = Object.assign({}, this.state.deal); 
                        deal.pctCommission = e.target.value; 
                        this.setState({ deal }) 
                      }}
                    />
                  </div> 
                  <div id="newDealCommentLonger">
                    of generated revenues
                  </div>  
                </div>}
        
        </div>

          <div id="newDealAction">
              { ! this.state.status &&
                <Button id="action_signup" disabled={isDisabled} onClick={()=>this.create()}>Create</Button>
              }
              <Button id="action_cancel" onClick={()=> this.props.onHide()}>Abort</Button>
            </div>  
            { this.renderMessage() }

            { this.state.enableAgents &&
              <EnableAgents 
                show={ this.state.enableAgents } 
                restrictedTo={[]}
                user={ this.props.user }
                selectAgents={ this.selectAgents }
                onHide={ this.closeEnableAgents.bind(this) }
              />
            }

        </Modal.Body>
      </Modal>
    );
  }
}

const DealsAddOne = connect(
  function mapStateToProps(state) {
    return { 
      selectedMonths: state.selectedMonths
    };
  }
)(Deals);

export default DealsAddOne