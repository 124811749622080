

import axios from 'axios';
import { API_URL } from '../Actions/index';

export function findEntitiesList( userId, supplierId ){
  return new Promise ( function(resolve, reject){

  axios
    .get(API_URL + '/entities/options/' + supplierId + '/' + userId )
    .then( ret => resolve(ret.data) )
    .catch(err => reject(err) )
  })
}

export function checkLevelPermission( userEntityType, objectEntity, strict ){
 
  let objectEntityType = objectEntity? objectEntity.type : 'Global'

  let userLevel = [ 'Shop', 'Branch', 'Division', 'Organisation', 'Country', 'Global' ].indexOf(userEntityType) 
  let objectLevel = [ 'Shop', 'Branch','Division', 'Organisation', 'Country', 'Global' ].indexOf(objectEntityType) 

  if( !strict ) return userLevel >= objectLevel
  else return userLevel > objectLevel
  
}

export function filterByEntity( list, entities, filteringEntity ){

  let entity = entities.find( elem => elem._id == filteringEntity )
  let possibleEntities = entities.filter( elem => 
    elem._id === entity._id || //filtering entity
    elem.country === entity._id || //filtering downwards, case filtering entity = a country
    elem.organisation === entity._id ||
    elem.division === entity._id ||
    elem.branch === entity._id ||
    elem.shop === entity._id ||
    entity.country && elem._id === entity.country && checkLevelPermission( entity.type, elem.type, true ) || //filtering upwards
    entity.organisation && elem._id === entity.organisation && checkLevelPermission( entity.type, elem.type, true ) || //filtering upwards
    entity.division && elem._id === entity.division && checkLevelPermission( entity.type, elem.type, true ) || //filtering upwards
    entity.branch && elem._id === entity.branch && checkLevelPermission( entity.type, elem.type, true ) //filtering upwards
  ).map( elem => elem._id )

  let filteredList = list.filter( elem => 
    !elem.entity || 
    possibleEntities.indexOf( elem.entity._id ) > -1
  )
  
  return filteredList

}