import React from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import { AllPopulation } from './Graphs/total'

class SDG1 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      base: undefined,
      error: undefined
    };
  };

  componentDidMount() {
    this.getSignings();
  }

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) this.getSignings();
  }



  getSignings = () => {
    let query = Object.assign( {}, this.props.filter)
    query.freq = 'monthly'
    axios
      .post(API_URL + '/contracts/analysis/' + this.props.supplier, query)
      .then((res) => {
        this.setState({ signings: res.data })
      })
  }

  render() {

    let signings = this.state.signings
    let labelSignings = []
    let datasetSignings = []

    if(signings){
      
      let allMonths = signings
        .map( elem => elem._id.date )
        .filter( elem => elem && elem != '2018-05' )
        .sort(function(a, b) {
          return new Date(a) - new Date(b)
        })
      labelSignings = [ ...new Set(allMonths) ]

      labelSignings.forEach( month => {
        
        let newclients = signings
          .filter( elem => elem._id.date == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(newclients) && newclients > 0 ) datasetSignings.push(newclients)
        else datasetSignings.push(0)

      })
    }

    return (
      <div >   
        <div id="impactGraphWrapper">
         { datasetSignings
          ? <AllPopulation 
              data={ datasetSignings } 
              label={ labelSignings } 
              title={"Anual energy savings (USD)"} 
              multiplier={127} 
              color={"#E5243B"}
            />             
          : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div>
         }
        </div>  
       
      </div>      
    );
  }
}

export default SDG1