import React, { Component } from 'react';
import {Button, FormControl} from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { findAgents } from  '../../../../services/AgentService'

class ReplaceAsset extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      replace:{
        assetNumber: this.props.asset.assetNumber,
        agentNumber: this.props.contract.agent? this.props.contract.agent.agentNumber: undefined,
        userId: this.props.user._id
      },
      disabled: false
    };
    this.loadData.bind(this)
  } 
  componentDidMount() {
    this.loadData()
  }

  replace() {
    this.setState({disabled: true})
    axios.put(API_URL + '/assets/replace/'+ this.props.user.supplier + '/' + this.props.asset.assetNumber, this.state.replace)
			.then((ret) => {
        this.setState({
          codes: ret.data,
          status: 'Success!'
        })
      })
      .catch( err => {
        this.setState({  status: 'Error! Please try again.' })
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success!'?'successfulCreation':'unSuccessfulCreation'} className="long">
          {this.state.status}
          { this.state.codes 
            ? <div>
                Activation code for new device = {this.state.codes.activationCode} <br/>
                Locking code for old device = { this.state.codes.lockCode? this.state.codes.lockCode: '-' }
              </div>
            : null
          }
        </div>
      );
    }
  }  

  getSerialNumbers(agent) {
    var query = {
      product: this.props.asset.product,
      heldBy: agent,
    }

    axios.post(API_URL + '/assets/search/' + this.props.user.supplier, { query })
			.then((ret) => { this.setState({ assets: ret.data }) })
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getSerialNumbers();
    this.getAgents()
  }  

  render() {
    
    let mappedSerialNumbers, mappedAgents = null
    
    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })
    }

    if(this.state.assets && this.state.agent){
      mappedSerialNumbers = this.state.assets.map((asset, index) =>{ 
        return (
          <option key={index} value={asset.serialNumber}>{asset.serialNumber}</option>
        )
      })
    }

    let isDisabled = (this.state.replace.serialNumber)?false:true
    if(this.state.disabled){isDisabled=true}

    let contract = this.props.contract
    let agentProfile = contract.agent? contract.agent.profile : {}
    let clientContact = contract.client? contract.client.contact : {}
  
    return (
      <div>
        <div id="assetActionTitle">Choose replacement asset</div>
        <div id="assetActionSubTitle">Asset {this.props.asset.assetNumber} will be marked as defective</div>
        <div id="assetActionSubTitle">A new activation code will be sent to client's number: +{clientContact.fullNumber}</div>
        <div id="assetActionSubTitle">A locking code will be sent to the agent by text</div>
        
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          placeholder="Confirm agent"
          className="contractXSInput"
          onChange={(e)=>{ 
            this.setState({ agent: e.target.value })
            let replace = Object.assign({}, this.state.replace); 
            replace.serialNumber = undefined;
            replace.agent = e.target.value
            this.setState({ replace });
            this.getSerialNumbers(e.target.value)
            }}
          >
          <option value="">Confirm agent</option>
          <option value={ contract.agent._id }>{agentProfile.firstName} {agentProfile.lastName}</option>
          { mappedAgents }
        </FormControl>

        { this.state.agent  && this.state.assets &&
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="contractXSInput"
            onChange={(e)=>{ 
              let replace = Object.assign({}, this.state.replace); 
              replace.serialNumber = e.target.value;
              this.setState({ replace });
              }}
            >
            <option value="">Choose a new asset</option>
            { mappedSerialNumbers }
          </FormControl> }
        
        { this.renderMessage() }
        
        <div id="modal_action">
          { this.state.status || ! this.state.replace.serialNumber
            ? null
            : <Button id="action_signup" disabled={isDisabled} onClick={this.replace.bind(this)}>Confirm</Button> }
        </div>

      </div>
    );
  }
}

export default ReplaceAsset;