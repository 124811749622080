import React, { Component } from 'react';
import { 
  Button 
} from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index'
import { findContracts } from  '../../../../services/ContractService'

class AddToGroup extends Component {

  constructor(props) {
    super(props);
    this.state= {
      group: {
        _id: this.props.group._id,
        contracts: []
      },       
      ready: false,
      disabled: false
    }
  }; 
  
  componentDidMount() {
    this.loadData()
  }

  edit() {
    this.setState({ disabled: true });
    axios.put(API_URL + '/groups/add/'+ this.props.group.supplier, this.state.group)
      .then((ret) => {
        this.setState({ status: ret.data.message });
      })
      .catch(err => {
        this.setState({ status: err.message })
      }); 
  }

  getContracts = async () => {
    try {    
      let query = {
        onboardingStatus: { $in: ['Approved', 'Signed'] },
        group: { $exists: false },
        deal: { $in: this.props.group.pricing.eligibleDeals }
      }
      let contracts = await findContracts( query, this.props.user._id, this.props.user.supplier )
      this.setState({ contracts })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getContracts();
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulAction':'successfulAction'} >
          {this.state.status}
        </div>
      );
    }
  }  

  select(status){
    this.setState({ add: status })
    if( status ) this.getContracts()
  }

  selectContract( id ){
    let group = Object.assign({}, this.state.group); 
    let list = []
    if( group.contracts.indexOf( id ) < 0 ) list = [...group.contracts, id]
    else list = group.contracts.filter( elem => elem !== id )
    group.contracts = list
    this.setState({ group })
  }

  render() {    

    let contracts = this.state.contracts

    let mappedContracts = contracts && contracts
      .map( contract =>{ 
        let profile = contract.client.profile
        return (
          <div 
            key={ contract._id } 
            id="groupContractOption"
            className={ this.state.group.contracts.indexOf( contract._id ) > -1 ? 'selectedDeal fullLineGroup': 'fullLineGroup' }
            onClick={ () => this.selectContract( contract._id ) }
          >
            <div id="groupContractNumber">{ contract.contractNumber }</div>
            <div id="groupContractDeal">{ contract.dealName }</div>
            <div id="groupContractClient">{ profile.firstName } { profile.lastName }</div>
          </div>
        )
      })

    let isDisabled = this.state.group.contracts.length > 0 ? false : true
    if( this.state.disabled ) isDisabled = true

    return (
      <div> 

        <div className="fullLength">
          <div id="groupActionTitle">Choose contracts</div>
          { mappedContracts }
        </div>

        {this.renderMessage()}   

        { !this.state.status && 
          <Button id="confirm_action" disabled={ isDisabled } onClick={()=>this.edit()}>Add contracts</Button> }

      </div>
    );
  }
}

export default AddToGroup