import React from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import './../auxiliaryPricing.css'

class AddAuxiliaryPricing extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      auxiliaryPricing: {
        ccy: this.props.deal.ccy,
        auxiliaries: [this.props.auxiliary._id],
        deal: this.props.deal._id,
        product: this.props.product,
        supplier: this.props.user.supplier
      },       
      disabled: false
    };
  } 

  create() {
    axios.post(API_URL + '/auxiliaries/create-pricing/'+ this.props.user.supplier, 
        this.state.auxiliaryPricing
      ).then((ret) => this.setState({ status: ret.data.message }) )      
      .catch(err => {
        console.log(err)
        this.setState({status: 'Error'})
      }); 
  }
  
  over(){
    this.props.onHide();
    this.setState({
      auxiliaryPricing: {},       
      status: undefined,
      disabled: false
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }  

  render() {
    let { deal, auxiliary } = this.props
    let auxiliaryPricing = this.state.auxiliaryPricing
    let isDisabled = ( !auxiliaryPricing.cost || !auxiliaryPricing.name )? true : false

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="medium"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm" className="modalCreateTitle">
            Create new pricing for { auxiliary.name }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
            <div className="itemPricingLabal">Upfront cost</div>
            <FormControl 
              type="text" 
              className="itemPricingInput"
              value={ this.state.auxiliaryPricing.cost || this.state.auxiliaryPricing.cost == ''
              ? deal.ccy + ' ' + this.state.auxiliaryPricing.cost
              : deal.ccy + ' ' + 0 
            }
              onChange={(e)=>{ 
                let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); 
                auxiliaryPricing.cost = e.target.value.replace( /^\D+/g, ''); 
                auxiliaryPricing.totalCost = e.target.value.replace( /^\D+/g, ''); 
                this.setState({ auxiliaryPricing }) 
              }}
            />   
            <div className="itemPricingLabal">Total cost</div>
            <FormControl 
              type="text" 
              className="itemPricingInput"
              value={ this.state.auxiliaryPricing.totalCost || this.state.auxiliaryPricing.totalCost == ''
              ? deal.ccy + ' ' + this.state.auxiliaryPricing.totalCost
              : deal.ccy + ' ' + 0 
            }
              onChange={(e)=>{ 
                let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); 
                auxiliaryPricing.totalCost = e.target.value.replace( /^\D+/g, ''); 
                this.setState({ auxiliaryPricing }) 
              }}
            /> 

            <div className="itemPricingLabal">Name</div>
            <FormControl 
              type="text" 
              className="itemPricingInputLong"
              value={ this.state.auxiliaryPricing.name }
              placeholder={'Name'}
              onChange={(e)=>{ let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); auxiliaryPricing.name = e.target.value; this.setState({ auxiliaryPricing }) }}
            />

            <div className="itemPricingLabal">Description</div>
            <textarea 
              type="textera" 
              className="itemPricingInputLong highInput"
              placeholder={'Short description (Optional)'}
              onChange={(e)=>{ let auxiliaryPricing = Object.assign({}, this.state.auxiliaryPricing); auxiliaryPricing.description = e.target.value; this.setState({ auxiliaryPricing }) }}
            />

            { this.renderMessage() }

            <div id="modal_action">
              { this.state.status !== 'Success' &&
                <Button id="action_signup" disabled={isDisabled} onClick={this.create.bind(this)}>Create</Button> }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AddAuxiliaryPricing;