import React, { Component } from 'react';
//import {InvoiceHeader} from './InvoiceHeader'
import { InvoicesListItem } from './InvoicesListItem'
import './invoices.css';

class InvoicesList extends Component {
  
  render() {    
    let mappedInvoices = null;
    let invoices = this.props.invoices
    if(invoices){
      invoices.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      mappedInvoices = invoices.map((invoice, index) =>{ 
        return (
          <InvoicesListItem 
            key={ invoice._id }
            invoice={ invoice } 
          />
        )
      })
    }

    return (
      <div>
        { mappedInvoices }
      </div>
    );
  }
}

export default InvoicesList