import React, { Component } from 'react';
import { Shotsplit } from '../Graphs/shot-split';
import Activity from '../Graphs/activity';
import Progress from '../Graphs/progress'
import Triptic from '../Graphs/triptic'
import ManageMenu from '../ManageMenu'
import history from '../../../history';

class ContractsAnalytics extends Component {
  constructor(props) {
		super(props)
		this.state = {
      contracts: undefined,
      dealers: undefined,
      contracts: undefined,
      search: 'contracts',
    };
  }

  handleChangeSearch(search){
    this.props.chooseSearch(search)
    this.setState({ search: search })
  }

  componentDidMount() {
    if( this.props.search && this.props.search.length > 0 ) {
      this.setState({ search: this.props.search })
    } 
  }
  
  goBack() {
    window.history.back();
  }

  render() {    
    
    let user = this.props.user
    let filter = {}
      
    return (
      <div className='background'>
        <div id="pageTitle">
          Contracts overview
          <span id="returnButton" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></span>
        </div>
        <ManageMenu user={ user }/>
        <div id="clientBackground">
         
          <div id="graphWrappers" className="topWrapper">

            <Triptic 
              user={ user } 
              filter={ filter }
              color= {'rgba(255,255,255, 0.9)'}
              hoverColor={'rgb(255, 255, 255)'}
              width={ 125 }
              height={ 250 }
            />

          </div>

          <div id="graphWrappers">
            <div id="graphSectionThird">    
              <Progress 
                user={ user } 
                filter={ filter }
                width={ 125 }
                height={ 250 }
              />
            </div>

            <div id="graphSectionThird">     
              <Activity 
                user={ user } 
                filter={ filter }
                color= {'rgba(255, 255, 255, 0.7)'}
                width={ 125 }
                height={ 250 }
              />
            </div>

            <div id="graphSectionThird">        
              <div id="graphTitle">Utilisation rate</div>
              <div id="graphWrapper">
                <Shotsplit 
                  user={ user } 
                  filter={ filter }
                  color= {'rgba(255, 255, 255, 0.7)'}
                  width={ 125 }
                  height={ 250 }
                />
              </div>
            </div>
          </div>


        </div>
      </div>
    );
  }
}

export default ContractsAnalytics