import React, { Component } from 'react';
import axios from 'axios';
import { FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../Actions/index';
import AddItem from './Action/AddItem'
import TransferItem from './Action/TransferItem'
import Build from './Action/Build'
import ItemsList from './List/ItemsList'
import SituationsController from './SituationsController'
import TransfersController from './TransfersController'
import ManageMenu from '../ManageMenu'
import './item.css'
import { findAgents } from  '../../../services/AgentService'
import { findProducts } from  '../../../services/ProductService'

class ItemsController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      status: this.props.match.params.type == 'Part'? 'INSTOCK': 'DEPLOYED',
      transfer: false,
      count: 0,
      create: false,
      build: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeView !== prevProps.activeView) this.getStock();
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0);
  }

  handleSelect(status){
    this.setState({ 
      page: 1, 
      items: undefined, 
      auxiliary: undefined, 
      agent: undefined, 
      quality: undefined, 
      status: status
    }, () => {
      this.getStock( this.state.product, status )
    })
  }
  
  getStock = ( product, searchStatus, auxiliary, agent ) => {
    let status = searchStatus == 'DEPLOYED' || this.props.match.params.type == 'Extension'
      ? 'DEPLOYED'
      : { $ne: 'DEPLOYED' }
    axios.post(API_URL + '/items/find/' + this.props.user.supplier, {
        product,
        auxiliary: auxiliary? auxiliary._id: undefined,
        agent,
        status,
        type: this.props.match.params.type,
        deployed:  searchStatus == 'DEPLOYED' || this.props.match.params.type == 'Extension' ? true: undefined
     }).then((ret) => this.setState({ items: ret.data }))
 }
  
  getProducts = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products })
    } catch(err){ console.log(err) }
  }

  getAuxiliaries(product) {
    axios.post(API_URL + '/auxiliaries/search/' + this.props.user.supplier, {
        type: this.props.match.params.type,
        product
      }).then((ret) => this.setState({ 
        auxiliaries: ret.data 
      }) )
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData(){
    this.getStock()
    this.getProducts();
    this.getAuxiliaries();
    this.getAgents()
  }

  create() {
    this.setState({ create: false });
    this.getStock();
  }
  transfer() {
    this.setState({ transfer: false, count: this.state.count+1 });
    this.getStock();
  }
  build() {
    this.setState({ build: false });
    this.getStock();
  }

  showDetails = ( auxiliary, status, quality ) => {
    this.setState({ 
      auxiliary, 
      status, 
      quality,
      agent: undefined
    })
  }

  breakdown = ( agent, quality, status, auxiliary, timeframe ) => {
    this.setState({ agent, quality, status, auxiliary, timeframe })
  }

  render() {    
    let { products, auxiliaries, items, status, quality, auxiliary, product, agent, agents, count, timeframe } = this.state;
    let user = this.props.user
    let permission = false
    if( user.permissions.transferItems !== false ) permission = true
    
    let mappedProducts = products && products
      .sort((a, b) => a.name.localeCompare(b.name))
      .map( product =>{ 
        return (
          <option key={product._id} value={product._id} className={'agentOption'}>{product.name} by {product.manufacturer}</option>
        )
      })

    let mappedAgents = this.state.agents && this.state.agents
      .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
      .map( agent =>{ 
        return (
          <option key={agent._id} className={'agentOption'}  value={ agent._id }>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })

    return (
      <div className='background'>  

        <div id="pageTitle">Items:{this.props.match.params.type}s</div>      
        <ManageMenu user={ user }/>
        
        <div id="clientBackground">
          <div id="clientSubMenu">  
            { this.props.match.params.type == 'Part' &&
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('INSTOCK')} className={status == 'INSTOCK'?"active_clientSubMenu leftMenu":"leftMenu"}>Instock</div> }
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('DEPLOYED')} className={status == 'DEPLOYED'?"active_clientSubMenu rightMenu":"rightMenu"}>Deployed</div>
            { permission && 
              <div>

                <div id="clientAction" onClick={()=>this.setState({ create: !this.state.create })}>
                  <i className="fas fa-plus-circle"></i>
                </div> 
                
                { this.props.match.params.type == 'Part' &&
                  <div id="clientAction" onClick={()=>this.setState({ build: !this.state.build })}>
                    <i className="fas fa-hammer"></i>
                  </div> }
              
              </div>}

            <div id="itemSelectorWrapper" className='onlyLarge'>

              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="itemSelector"
                onChange={ (e) => { 
                  this.setState({ product: e.target.value }) 
                  this.getStock( e.target.value, undefined, undefined, agent )
                }}
              >
                <option value="" className={'agentOption'}>All products</option>
                { mappedProducts }
              </FormControl>

              { status !== 'DEPLOYED' &&
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="itemSelector"
                  onChange={ (e) => { 
                    this.setState({ agent: e.target.value }) 
                    this.getStock(product, undefined, undefined, e.target.value)
                  }}
                >
                  <option value="" className={'agentOption'}>All agents</option>
                  { mappedAgents }
                </FormControl>
              }
                     
            </div>
 
            { permission && ( auxiliary && agent && quality  ) &&
              <div id="clientAction" onClick={()=>this.setState({ transfer: !this.state.transfer })}>
                <i className="fas fa-exchange-alt"></i>
              </div> }

          </div>

          { items && auxiliaries &&
            <ItemsList 
              items={ items } 
              auxiliaries={ auxiliaries } 
              showDetails={ this.showDetails } 
              view={ status }
              breakdown={ this.breakdown }
              type={ this.props.match.params.type }
            /> }

          { quality && status && auxiliary && agents && status !== 'DEPLOYED' &&
            <SituationsController 
              status={status}
              agents={agents}
              quality={quality}
              count={count}
              auxiliary={auxiliary} 
              breakdown={this.breakdown}
              user={user} 
            /> }

          { auxiliary && ( ( agent && quality ) || status == 'DEPLOYED' ) &&
            <TransfersController 
              user={user} 
              quality={quality}
              agent={agent}
              count={count}
              auxiliary={auxiliary}
              status={status}
              timeframe={timeframe}
              type={this.props.match.params.type}
            /> }

          { this.state.create && auxiliaries &&
            <AddItem 
              show={this.state.create} 
              auxiliaries={ auxiliaries }
              type={this.props.match.params.type}
              onHide={this.create.bind(this)}
              user={ user }
            /> }

          { this.state.transfer && agent && auxiliaries &&
            <TransferItem 
              show={this.state.transfer} 
              auxiliary={ auxiliary }
              quality={ quality }
              type={this.props.match.params.type}
              agent={ agent }
              status={ status }
              items={items}
              onHide={this.transfer.bind(this)}
              user={ user }
            /> }

          { this.state.build && auxiliaries &&
            <Build 
              show={this.state.build} 
              auxiliaries={ auxiliaries }
              onHide={this.build.bind(this)}
              user={ user }
            /> }

        </div>

      </div>
    );
  }
}

export default ItemsController