import React, { Component } from 'react';
import QuestionAction from './Action/QuestionAction'
import './questions.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { hasPermission } from './../../../../services/UserService'

class Question extends Component {
  constructor(props) {
		super(props)
		this.state = {
      edit: false
    };
  }  

  close() {
    this.setState({ edit: false });
    this.props.refresh()
  }

  moveQ = (inc) => {
    axios.put(API_URL + '/questionnaires/movequestion/' + this.props.question.questionnaire, {
      inc: inc,
      questionToMove: this.props.question._id
    })
    .then( ret => this.props.refresh() )
    .catch( err => console.log(err))
  }

  render() {    
    
    let { question, user, questionnaire, refresh } = this.props
    let permission = hasPermission( user, questionnaire )

    return (
      <div id="questionItem">
        <div id="questionCategory">{ question.category? question.category:'-'}</div>
        <div id="questionQuestion">{ question.question}</div>
        
        { permission &&
          <div>
            <div id="editQuestion" className='active' onClick={ ()=> this.setState({ edit: !this.state.edit}) }> <i className="far fa-edit"></i> </div>
            <div id="moveQuestion" className='active' onClick={ ()=> this.moveQ(-1) }> <i className="fas fa-long-arrow-alt-up"></i> </div>
            <div id="moveQuestion" className='active' onClick={ ()=> this.moveQ(1) }> <i className="fas fa-long-arrow-alt-down"></i> </div>
          </div> }

        { question.conditional && <div id="questionConditional">COND</div> }
        { question.optional && <div id="questionOptional">OPT</div> }

        { this.state.edit &&
          <QuestionAction
            show={ this.state.edit } 
            onHide={ this.close.bind(this) }
            question={ question }
            supplier={ user.supplier }
            user={ user }
            questionnaireId={ questionnaire._id }
            questions={ questionnaire.questions }
            refresh={ refresh }
          /> } 

      </div>
    );
  }
}

export default Question