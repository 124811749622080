import React, { Component } from 'react';

class Header extends Component {
  
  render() {
    
    return (

      <div className="itemTitle">
        <div id="itemAux">Product -- auxiliary</div>
        <div className="itemMonth itemTop">Current month</div>
        <div className="itemMonth itemTop">Last month</div>
        <div className="itemTotal itemTop">Total</div>
      </div>

    )
  }  
}

export default Header 