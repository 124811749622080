import React, { Component } from 'react';
import { Button } from 'react-bootstrap/lib';
import './../contract.css';
import './../../Utils/utils.css'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class WriteOff extends Component {

  constructor(props) {
    super(props);
    this.state= {
      disabled: true, 
      activationCode: undefined,    
      status: undefined,
    }
  }; 

  writeOff() {
    this.setState({ disabled: true })
    axios.put(API_URL + '/contracts/writeOff/'+ this.props.contract.supplier+ '/' + this.props.contract.contractNumber, {
      userId: this.props.user._id,
    })
      .then((ret) => this.setState({ status: ret.data.message }) )
      .catch(err => this.setState({ status: err.message }) ); 
  }  

  renderMessage() {
    if( this.state.status ) {
      return (
        <div id={this.state.status == 'Success!'?'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  } 

  render() {    

    return (
      <div>

        <div id="contractEditTitle">Confirm contract write-off</div>
        <Button id="writteOff" onClick={()=>this.writeOff()}>Writte-off</Button>

        {this.renderMessage()} 
      </div>
    );
  }
}

export default WriteOff