import React, { Component } from 'react';
import './clients.css'

export class ClientHeader extends Component {
  
  render() {
    return (
      <div className="clientsTitle">
        <div className="clientsSmall" >
          Client number
        </div>  
        <div className="clientsMedium" >
          First Name
        </div>
        <div className="clientsMedium" >
          Last Name
        </div>
        <div className="clientsEntity onlyLarge" >
          Entity
        </div>  
        <div className="clientsExtraSmall onlyLarge" >
          Credit
        </div>  
        <div className="clientsExtraSmall onlyLarge" >
          Status
        </div>  

        { this.props.status==='Pending'
          ? <div className="clientsSmall onlyLarge" >Entry date</div>  
          : null }

        { this.props.status==='Approved'
          ? <div className="clientsSmall onlyLarge" > Approval date </div>  
          : null }

        { this.props.status==='Rejected'
          ? <div className="clientsSmall onlyLarge" > Rejection date </div>  
          : null }

        { this.props.status==='Signed'
          ? <div className="clientsSmall onlyLarge" >Signing date </div>   
          : null }
      
        <div className="clientsMedium" >
          Contract(s)
        </div>             
        <div className="clientsMedium onlyLarge">
          Agent
        </div>  
      </div>
    )
  }  
}