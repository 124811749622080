import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import history from './../../../history';
import ManageMenu from './../ManageMenu'
import CreateAgent from './Action/CreateAgent'
import AgentsList from './List/AgentsList'
import { FormControl } from 'react-bootstrap/lib';
import { hasPermission } from './../../../services/UserService'

class AgentsController extends Component {

  constructor(props) {
		super(props)
		this.state = {
      agentRole: 'Agent',
      showModal: false,
      page: 1,
      pages: 1,
      total: 0,
    };
  }
  
  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0)
  }

  handleNext(path){
    history.push(path)
  }

	getAgentsList = (role, fromPage, lastName ) => {
    let query = { role }
    if( lastName ) query = {
      role,
      "profile.lastName" : { $regex: lastName, $options: 'i' }
    }
    var body={
      query,
      fromPage,
      pageSize: 50,
      user: this.props.user._id
    }
    axios
      .post(API_URL + '/agents/find/'+ this.props.user.supplier, body)
      .then((ret) => this.setState({ 
        agents: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total   
      }) )
  }

  close(){
    this.setState({ agentRole: 'Agent', showModal: false })
    this.getAgentsList('Agent', 1);
  }

  loadData() {
    this.getAgentsList('Agent', 1);
  }

  view(role){
    this.setState({ agentRole: role, agents: undefined});
    this.getAgentsList(role, 1);
  }
  
  render() {    
    let user = this.props.user
    let permissions = user.permissions? user.permissions : {}
    let permission = hasPermission( user, undefined, 'createAgents' )
    let options = user.options
    let { page, pages, agents, agentRole, lastName } = this.state;

    return (
      <div className='background'>
        <div id="pageTitle">Agents</div>
        <ManageMenu user={ user }/>
        <div id="clientBackground">
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.view('Agent')} className={agentRole==='Agent'?"active_clientSubMenu leftMenu":"leftMenu"}>Agents</div>
            <div id="clientSubMenu_item" onClick={()=>this.view('Operator')} className={agentRole==='Operator'?"active_clientSubMenu":""}>Operators</div>

            { options && options.customerServiceAgents &&
              <div id="clientSubMenu_item" onClick={()=>this.view('CustomerService')} className={agentRole==='CustomerService'?"active_clientSubMenu":""}>Cust. service</div> }

            { options && options.agentManagers &&
              <div id="clientSubMenu_item" onClick={()=>this.view('Manager')} className={agentRole==='Manager'?"active_clientSubMenu":""}>Managers</div> }
            
            <div id="clientSubMenu_item" onClick={()=>this.view('Administrator')} className={agentRole==='Administrator'?"active_clientSubMenu":""}>Warehouses</div>
            <div id="clientSubMenu_item" onClick={()=>this.view('Disabled')} className={agentRole==='Disabled'?"active_clientSubMenu rightMenu":"rightMenu"}>Disabled</div>
            
            { permission || 
              permissions.createAgents != false 
              ? <div id="clientAction" onClick={()=>this.setState({showModal: !this.state.showModal})}>
                  <i className="fas fa-user-plus"></i>
                </div>
              : null }

            <div className="quickSearch onlyLarge">
              <FormControl
                type="text"
                className="quickSearchInput"
                placeholder="Search lastname"
                onChange={(e)=> this.setState({ lastName: e.target.value }) }
              />
              <div className="searchAction" onClick={ ()=>this.getAgentsList( agentRole, page, lastName )}>
                <i className="fas fa-search"></i>
              </div>
            </div>

          </div>

          { agents && agents.length > 0
            ? <div id="assetsList" >
                <div className="clientNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getAgentsList(agentRole, 1, lastName)
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getAgentsList(agentRole, Math.max( 1, page -1 ), lastName)
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="navLegend">{ this.state.total } agents</div>
                  <div className="navPage">page {page} / {pages} </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getAgentsList(agentRole, Math.min( pages, page + 1 ), lastName)
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getAgentsList(agentRole, pages, lastName)
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>
              </div>
            : null } 

          <div id="assetsList">
            { agents
              ? <AgentsList 
                  agents={ agents } 
                  refresh={this.getAgentsList} 
                  supplier={ user.supplier } 
                  agentRole={ agentRole }
                  page={ page }
                />
              : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }
          </div>

        </div>

        { this.state.showModal &&
          <CreateAgent 
            show={this.state.showModal} 
            onHide={this.close.bind(this)}
            user={ user }
            edit={false}
          /> }

      </div>
    );
  }
}

export default AgentsController