import React, { Component } from 'react';
import { API_URL } from '../../../../Actions/index';
import axios from 'axios';
import { FormControl, Button } from 'react-bootstrap/lib';
import { SearchAgents } from '../../Search/SearchItems/SearchAgents'
import { emptyList } from '../../../../Actions/index';
import { connect } from 'react-redux';
import './sender.css';

class GroupNotes extends Component {
  constructor(props) {
		super(props)
		this.state = {
      note: {
        text: undefined,
        title: undefined,
        type: 'Info',
        status: 'Sent',
        author: this.props.user._id,
        writtenBy: this.props.user.profile.firstName.substring(0, 1) + ' ' + this.props.user.profile.lastName,
        supplier: this.props.supplier
      },
    };
  }
  
  sendMany = () => {
    let body = {
      listOfAgents: this.props.selectedAgents,
      note: this.state.note,
      user: this.props.userId
    }

    axios.post(API_URL + '/notes/sendMany/' + this.props.supplier, body)
			.then((ret) => {
        this.setState({
          status: 'Success!',
          answer: ret.data
        })
      })
      .then( () => this.props.emptyList() )
      .catch( err =>  this.setState({ status: 'Error' }) )
  }
  
  renderMessage() {
    if (this.state.status) {
      return (
        <div className='successSent' id={this.state.status === 'Success!' ?'successfulSent':'unSuccessfulCreation'}>
          Messages sent!
        </div>
      );
    }
  }  

  render() {    
    return (
      <div id="groupMessageWrapper">

        <div id="groupMessageWrapperLeft">
          <div id="groupMessageWrapperBottom">
            <div id="groupMessageTitle">1. Compose your group message</div>
            <div id="groupMessageCat">Type</div>
            <div className="groupMessageInputS shaded" >Info</div>
            <div id="groupMessageCat">Title</div>
            <FormControl 
              type="text" 
              className="groupMessageInputS"
              placeholder={'Title'}
              onChange={(e)=>{ 
                let note = Object.assign({}, this.state.note); 
                note.title = e.target.value; 
                this.setState({ note }) 
              }}
            />
            <div id="groupMessageCat">Message</div>
            <textarea
              type="text" 
              className="groupMessageInput"
              placeholder="Type message"
              onChange={(e)=>{ 
                let note = Object.assign({}, this.state.note); 
                note.text = e.target.value; 
                this.setState({ note }) 
              }}
            />   
          
          </div>

          { this.props.selectedAgents.length > 0 && this.state.note.text && this.state.note.title
              ? <div id="groupMessageWrapperBottom">
                  <div id="groupMessageTitle">3. Send message to  {this.props.selectedAgents.length} agents</div>
                  <Button 
                    disabled={ this.props.selectedAgents.length===0 || this.state.status ? true : false } 
                    id="sendToAgent"
                    onClick={ ()=> this.sendMany()}
                  >
                    Send
                  </Button>
                </div>
              : null }

          </div>

        { this.state.note && this.state.note.text && this.state.note.title && !this.state.status
          ? <div id="groupMessageWrapperRight">
              <div id="groupMessageTitle">2. Select your audience</div>
              <SearchAgents supplier={this.props.supplier} selectable={true}/>
            </div> 
          : null }

        { this.renderMessage() }

      </div>
    );
  }
}

const GroupNotesAction = connect(
  function mapStateToProps(state) {
    return { 
      selectedAgents: state.selectedAgents
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(GroupNotes);

export default GroupNotesAction