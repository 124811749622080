import axios from 'axios';
//import cookie from 'react-cookie';
import { API_URL, errorHandler } from './index';
import { AUTH_ERROR, REGISTER_ERROR, FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR,
         LOGIN_FAILURE, UPDATE_PASSWORD_ERROR, LOGIN_SUCCESS, TOKEN_EXPIRED, LOGOUT_SUCCESS } from './types';
import history from './../history';
import config from '../config/main';

//= ===============================
// Authentication actions
//= ===============================

export function loginUser({ email, username, password, location }) {
  return function (dispatch) {
    axios.post(`${API_URL}/auth/login`, { email, username, password })
    .then((response) => {

      if (response.data && response.data.exception) {

        let loginError = {
          type: LOGIN_FAILURE,
          message: response.errorMessage
        };

        dispatch(loginError);
        
        return Promise.reject(response);

      } else {
        // If login was successful, set the token in local storage

        // Dispatch the success action
        let dispatchData = {
          token: response.data.token,
          user: response.data.user,
          expires: response.data.expires,
          isFetching: false,
          authenticated: true,
        };
        
        saveLocalStorage(dispatchData.token, dispatchData.user);
  
        // Dispatch the success action
        dispatch({
          type: LOGIN_SUCCESS,
          payload: dispatchData
        })

        
        if(response.data.user && response.data.user.passwordNeedUpdate) {
          return history.push('/update-password');
        }

        if(location) {
          return history.push(location)
        } else {
          return history.push('/analytics');
        }
      }      
      
    })
    .catch((error) => {
      console.log("login error", error);
      errorHandler(dispatch, error, AUTH_ERROR);
    });
  };
}

export function registerUser(user, location) {
  return function (dispatch) {
    axios.post(`${API_URL}/auth/register`, user)
    .then((response) => {
      if (response.data && response.data.exception) {
        let registerError = {
          type: REGISTER_ERROR,
          message: response.data.errorCode
        };

        dispatch(registerError)
        return Promise.reject(registerError)
      } else {
        // If login was successful, set the token in local storage
        
        let dispatchData = {
          token: response.data.token,
          user: response.data.user,
          expires: response.data.expires
        };
        
        saveLocalStorage(dispatchData.token, dispatchData.user);
        
        dispatchData.type = LOGIN_SUCCESS;
        dispatchData.isFetching = false;
        dispatchData.authenticated = true;
    
        // Dispatch the success action
        dispatch({
          type: LOGIN_SUCCESS,
          payload: dispatchData
        })

        //if (location) {
        //  history.push(location)
        //} 
        
      }      
      
    })
    .catch((error) => {
      let errorMessage = "Error while registering";
      if (error.response && error.response.data ) {
        errorMessage = error.response.data.errorCode;
      }

      let registerError = {
        type: REGISTER_ERROR,
        message: errorMessage
      };

      dispatch({
        type: REGISTER_ERROR,
        payload: { errorMessage},
      });

     // dispatch(registerError)

      console.log("error #####", errorMessage);
      //errorHandler(dispatch, message, REGISTER_ERROR);
    });
  };
}

export function logoutUser(error) {

  return function (dispatch) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    dispatch({ type: LOGOUT_SUCCESS });
    history.push('/')
  };
}

export function errorUser(error) {
  return function (dispatch) {
    dispatch({ type: LOGIN_FAILURE, authenticated: false, payload: {error} || '' });
    
  };
}
    
export function tokenExpired() {
  return function (dispatch) {
    dispatch({
      type: TOKEN_EXPIRED,
      authenticated: false,
    })
  }
}

export function getForgotPasswordToken({ email }) {
  return function (dispatch) {
    axios.post(`${API_URL}/auth/forgot-password`, { email })
    .then((response) => {
      dispatch({
        type: FORGOT_PASSWORD_REQUEST,
        payload: {message: response.data.message},
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, AUTH_ERROR);
    });
  };
}

export function resetPassword(token) {
  
  return function (dispatch) {
    axios.get(`${API_URL}/auth/reset-password/${token}`)
    .then((response) => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: {
          message: response.data.message,
          user: response.data.user
        },
      });

      saveLocalStorage(token, response.data.user);

      // Redirect to login page on successful password reset
      history.push('/update-password');
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, RESET_PASSWORD_ERROR);
    });
  };
}

export function updatePassword(oldPassword, newPassword) {
  return function (dispatch) {

    let headers = new Headers();
    headers.append('Content-Type','application/json')
    headers.append(config.tokenHeaderKey, localStorage.getItem('token'));

    let url = `${API_URL}/auth/user-pass`;
    axios({
      method: 'POST',
      url,
      headers,
      data: {
        oldPassword, newPassword
      }
    })
    .then((response) => {

      let dispatchData = {
        token: response.data.newToken,
        user: response.data.user,
        expires: response.data.expires,
        isFetching: false,
        authenticated: true,
      };
      
      saveLocalStorage(dispatchData.token, dispatchData.user);

      // Dispatch the success action
      dispatch({
        type: LOGIN_SUCCESS,
        payload: dispatchData
      })

      history.push('/analytics');
    })
    .catch((error) => {
      console.error(error);
      errorHandler(dispatch, error.response, UPDATE_PASSWORD_ERROR);
    });
  };
}

function saveLocalStorage(token, user) {
  if(token) {
    localStorage.setItem('token', token)
  }
  
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  
}