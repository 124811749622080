import React, { Component } from 'react';
import {Button, FormControl} from 'react-bootstrap/lib';
import './../contract.css';
import './../../Utils/utils.css'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class Code extends Component {

  constructor(props) {
    super(props);
    this.state= {
      code:{
        days: undefined,
        action: 'add_time',
        contractNumber: this.props.contract.contractNumber,
        userNumber: this.props.user.userNumber
      }, 
      disabled: true, 
      activationCode: undefined,    
      status: undefined,
    }
  }; 

  code() {
    this.setState({ disabled: true })
    axios.put(API_URL + '/contracts/generate-code/'+ this.props.contract.supplier, this.state.code)
      .then((ret) => {
          this.setState({ 
            status: ret.data.status,
            activationCode: ret.data.activationCode
          });
      })
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if( this.state.status ) {
      return (
        <div id={this.state.status == 'Success'?'successfulAction':'failedAction'} className="fullLine">
          Your code is { this.state.activationCode }
        </div>
      );
    }
  } 

  render() {    

    return (
      <div>
        <div>
          <div id="contractEditTitle">Generate code</div>
          <div id="bonusEditInput">
            <FormControl 
              type="number" 
              className="contractEditInput"
              placeholder="Type"
              onChange={(e)=>{ 
                let code = Object.assign({}, this.state.code); 
                code.days = e.target.value; 
                code.action = 'add_time';
                this.setState({ code });
                this.setState({ disabled: false }) 
              }}
            />
          </div>
          <div id="bonusEditLabel2">days - or</div>
          <div id="bonusEditInput">
            <div 
              id="editUnlock"
              className={ this.state.code.action == 'unlock'? 'editUnlockActive':'' }
              onClick={(e)=>{ 
                let code = Object.assign({}, this.state.code); 
                code.days = undefined; 
                code.action = 'unlock';
                this.setState({ code });
                this.setState({ disabled: false }) 
              }}
            >
              Unlock code
            </div>
          </div>
          <div id="contractEditWarning">The code will NOT be automatically sent to the client and the contract will remain unchanged by this code generation.</div>
          <div id="bonusEditLabel" style={{marginRight: '30%'}}>
            <Button id="bonusSend" disabled={this.state.disabled} onClick={()=>this.code()}>Generate</Button>
          </div>
        </div>

        <div id="dealSection">
          {this.renderMessage()} 

        </div>
      </div>
    );
  }
}

export default Code