import { LOGIN_SUCCESS, REGISTER_ERROR, LOGIN_FAILURE, LOGOUT_SUCCESS, AUTH_ERROR, FORGOT_PASSWORD_REQUEST, 
    RESET_PASSWORD_SUCCESS, PROTECTED_TEST, TOKEN_EXPIRED } from '../Actions/types';

const INITIAL_STATE = { error: '', message: '', content: '', authenticated: false, userID: '', role:'', profile: null };

export default function (state = INITIAL_STATE, action) {
  console.log("action.type", action.type);
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true, 
        user: action.payload.user,
        errorMessage: ''
        };
    case LOGIN_FAILURE:
      return { ...state,
        authenticated: false,
        errorMessage: 'Failed to login'
      }
    case TOKEN_EXPIRED:
      return { ...state,
        authenticated: false, 
        errorMessage: ''
      }

    case REGISTER_ERROR: 
      console.log("state #######", state, action.payload);
      return { ...state,
        authenticated: false, 
        error: action.payload.errorMessage
      }
  
    case LOGOUT_SUCCESS:
      return { ...state, authenticated: false };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    case FORGOT_PASSWORD_REQUEST:
      return { ...state, message: action.payload.message };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, message: action.payload.message, user: action.payload.user,  };
    case PROTECTED_TEST:
      return { ...state, content: action.payload.message };
  }

  return state;
}