import React from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import countryDirectory from './../../../../config/countryDirectory'

class EditAgent extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      agent:{
        profile:{},
        contact:{},
        payment:{},
        cashCollection: this.props.agent.cashCollection,
        cashLimit: this.props.agent.cashLimit,
      },
    };
  } 

  componentDidMount() {
    this.getOptions()
    if( this.props.agent ){
      let agent = Object.assign( this.state.agent, this.props.agent )
      this.setState({ agent })
    }

  }

  getOptions() {
    axios.get(API_URL + '/users/supplieroptions/'+ this.props.supplierId )
			.then((ret) => { this.setState({ options: ret.data })})  
  }

  edit() {
    axios.put(API_URL + '/agents/edit/'+ this.props.supplierId+ '/' +this.props.agent.agentNumber, this.state.agent)
			.then((ret) => this.setState({ status: 'Success!' }) )      
      .catch(err => {
        console.error(err);
        this.setState({status: err.message})
      }); 
  }
  
  over(){
    this.props.onHide();
    this.setState({
      status:undefined,
      agent:{
        profile:{
          firstName: undefined,
          lastName:undefined,
          language: undefined
        },
        email:undefined,
        contact:{
          mobile:undefined,
          countryCode: undefined
        },
        countryCode: undefined,
        ccy: undefined,
        cashCollection: undefined,
        cashLimit: undefined,
        canRegisterAgents: undefined,
        assignedPwd: undefined,
        role: undefined
      },
      confirmPwd: undefined,
      supplier: undefined
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  render() {
    let agent = this.props.agent
    let options = this.state.options
    let isDisabled = (
      this.state.agent.assignedPwd !== this.props.agent.assignedPwd
      && this.state.agent.assignedPwd !== this.state.confirmPwd
    ) ? true : false
    
    let codeOptions = options && options.countries.map( (country, index) => {
      return <option key={index} value={countryDirectory[country].countryCode}>{ countryDirectory[country].countryCode }</option>
    })

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Amend agent details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">
            <FormControl 
              type="text" 
              className="agentEditInput"
              value={
                this.state.agent.profile.firstName
                  ?this.state.agent.profile.firstName
                  :(agent&&agent.profile?agent.profile.firstName:'-')
              }              
              placeholder={agent?agent.profile.firstName:'First name'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.profile.firstName = e.target.value; this.setState({ agent }) }}
            />
            <FormControl 
              type="text" 
              className="agentEditInput"
              value={
                this.state.agent.profile.lastName
                  ?this.state.agent.profile.lastName
                  :(agent&&agent.profile?agent.profile.lastName:'-')
              }                
              placeholder={agent?agent.profile.lastName:'Last name'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.profile.lastName = e.target.value; this.setState({ agent }) }}
            />
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="agentEditInputShort"
              value={
                this.state.agent.contact.countryCode
                  ? this.state.agent.contact.countryCode
                  : (agent&&agent.contact?agent.contact.countryCode:'-')
              }  
              onChange={(e)=>{ 
                let agent = Object.assign({}, this.state.agent); 
                agent.contact.countryCode = e.target.value; 
                this.setState({ agent });
                }}
              >
              <option value="">Code</option>
              { codeOptions }
            </FormControl>
            <FormControl 
              type="number" 
              className="agentEditInputLong"
              value={
                this.state.agent.contact.mobile
                  ? this.state.agent.contact.mobile
                  : (agent&&agent.contact?agent.contact.mobile:'-')
              } 
              placeholder={ agent && agent.contact && agent.contact.mobile ? agent.contact.mobile : 'Mobile' }
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.contact.mobile = e.target.value; this.setState({ agent }) }}
            />  
            <FormControl 
              type="text" 
              className="agentEditInput"
              value={
                this.state.agent.email
                  ? this.state.agent.email
                  : (agent&&agent.email? agent.email : '-')
              }               
              placeholder={agent?agent.email:'Email'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.email = e.target.value; this.setState({ agent }) }}
            />
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="agentEditInput"
              value={
                this.state.agent.role
                  ? this.state.agent.role
                  : (agent && agent.role? agent.role : '-')
              }  
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.role = e.target.value; this.setState({ agent }) }}
            > 
              <option value="">Choose one</option>
              <option value="Agent">Agent</option>
              <option value="Operator">Operator</option>
              { options && options.agentManagers 
                ? <option value="Manager">Manager</option>
                : null }
              <option value="Administrator">Warehouse</option>
              <option value="Disabled">Disable</option>
            </FormControl>

            { options && options.agentManagers && agent && agent.role == 'Manager'
              ? <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="agentEditInput"
                  onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.canRegisterAgents = e.target.value; this.setState({ agent }) }}
                > 
                  <option value="">{ agent && agent.canRegisterAgents ? 'Can register agents' : 'Cannot register agents' }</option>
                  <option value={ true }>Can register</option>
                  <option value={ false }>Cannot</option>
                </FormControl>  
              : null }

            <FormControl 
              type="password" 
              className="agentEditInput"
              placeholder={'Reset password'}
              onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.assignedPwd = e.target.value; this.setState({ agent }) }}
            />
            <FormControl 
              type="password" 
              className="agentEditInput"
              placeholder={'Confirm password'}
              onChange={(e)=>this.setState({confirmPwd: e.target.value})}
              />

            { options && options.cashCollection === true
             ? <div>
                <div className="agentEditInputLabel">Cash collection</div>
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="agentEditInputHalf"

                  onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.cashCollection = e.target.value; this.setState({ agent }) }}
                > 
                  <option value={''}>{ agent && agent.cashCollection !== undefined
                    ? ( agent.cashCollection === true ? 'Permitted' : 'Not permitted' ) 
                    : 'Not permitted'}
                  </option>
                  <option value={ true }>Permitted</option>
                  <option value={ false }>Not permitted</option>
                </FormControl>

                { this.state.agent.cashCollection
                  ? <div>
                      <div className="agentEditInputLabel">Within limit</div>
                      <FormControl 
                        type="text" 
                        className="agentEditInputHalf"
                        value={
                          this.state.agent.cashLimit != undefined
                            ? this.state.agent.cashLimit
                            : agent.cashLimit ? agent.cashLimit: 'Uncapped'
                        }
                        onChange={(e)=>{ let agent = Object.assign({}, this.state.agent); agent.cashLimit = e.target.value; this.setState({ agent }) }}
                      />
                    </div>
                  : null }

              { options && options.manageAgents
              ? <div>
                  <div className="agentEditInputLabel fullLine">Account details</div>
                  <FormControl 
                    type="text" 
                    className="agentEditInput"
                    placeholder="Type"
                    value={
                      this.state.agent.payment.bankAccount
                        ? this.state.agent.payment.bankAccount
                        : undefined
                    }
                    onChange={(e)=>{ 
                      let agent = Object.assign({}, this.state.agent); 
                      agent.payment.bankAccount = e.target.value; 
                      this.setState({ agent }) 
                    }}
                  />
                  <div className="agentEditInputLabel fullLine">Mobile money details</div>
                  <FormControl 
                    type="text" 
                    className="agentEditInput"
                    placeholder="Type"
                    value={
                      this.state.agent.payment.mmAccount
                        ? this.state.agent.payment.mmAccount
                        : undefined
                    }
                    onChange={(e)=>{ 
                      let agent = Object.assign({}, this.state.agent); 
                      agent.payment.mmAccount = e.target.value; 
                      this.setState({ agent }) 
                    }}
                  />
                </div>  
              : null }

              </div>
             : null
            }

            {this.renderMessage()}
            <div id="modal_action">
            {this.state.status==='Success!'?
                null
                :
                <Button id="action_signup" disabled={isDisabled} onClick={this.edit.bind(this)}>Confirm</Button>

              }
              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
            </div>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EditAgent;