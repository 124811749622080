import axios from 'axios';
import { errorUser } from './auth';
import { STATIC_ERROR, FETCH_USER, SEND_CONTACT_FORM } from './types';
//export const API_URL = 'http://localhost:5000/api';
export const API_URL = "https://dev.api.upya.io/api"; 
//export const SOCKET_URL = 'http://api.upya.io:8000'

//= ===============================
// Utility actions
//= ===============================
 
export function fetchUser(uid) {
  return function (dispatch) {
    axios.get(`${API_URL}/user/${uid}`, //{
//      headers: { Authorization: cookie.load('token') },
//    }
    )
    .then((response) => {
      dispatch({
        type: FETCH_USER,
        payload: response.data.user,
      });
    })
    .catch(response => dispatch(errorHandler(response.data.error)));
  };
}

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error);

  let errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : error;
  
  return dispatch(errorUser(errorMessage));

  dispatch({
    type,
    payload: { errorMessage},
  });
}


//= ===============================
// Selection actions
//= ===============================


export function setActiveView(results){
    return {
        type: 'VIEW',
        payload: results,
    }
}

export function chooseSearch(search){
  return {
      type: 'CHOOSE_SEARCH',
      payload: search,
  }
}

export function filterAssets(results){
    return {
        type: 'FILTER_ASSETS',
        payload: results,
    }
}

export function chooseMonths(results){
    return {
        type: 'CHOOSE_MONTHS',
        payload: results,
    }
}

export function addToClientList(client){
  return {
      type: 'ADD_TO_CLIENTS',
      payload: client,
  }
}

export function addToContractList(contract){
  return {
      type: 'ADD_TO_CONTRACTS',
      payload: contract,
  }
}

export function addToPayrolltList(payroll){
  return {
      type: 'ADD_TO_PAYROLLS',
      payload: payroll,
  }
}

export function addToPaymentList(payment){
  return {
      type: 'ADD_TO_PAYMENTS',
      payload: payment,
  }
}

export function loadDeals(deals){
  return {
      type: 'LOAD_DEALS',
      payload: deals,
  }
}

export function loadProducts(products){
  return {
      type: 'LOAD_PRODUCTS',
      payload: products,
  }
}

export function selectContracts(contracts){
  return {
      type: 'SELECT_CONTRACTS',
      payload: contracts,
  }
}

export function addToAgentList(agent){
  return {
      type: 'ADD_TO_AGENTS',
      payload: agent,
  }
}

export function emptyList(){
  return {
      type: 'EMPTY_LIST'
  }
}

export function addToAssetList(asset){
  return {
      type: 'ADD_TO_ASSETS',
      payload: asset,
  }
}

export function removeFromClientList(client){
  return {
      type: 'REMOVE_FROM_CLIENTS',
      payload: client,
  }
}
export function removeFromAssetList(asset){
  return {
      type: 'REMOVE_FROM_ASSETS',
      payload: asset,
  }
}

export function removeFromContractList(contract){
  return {
      type: 'REMOVE_FROM_CONTRACTS',
      payload: contract,
  }
}

export function removeFromPayrollList(payroll){
  return {
      type: 'REMOVE_FROM_PAYROLLS',
      payload: payroll,
  }
}

export function removeFromPaymentList(payment){
  return {
      type: 'REMOVE_FROM_PAYMENTS',
      payload: payment,
  }
}

export function removeFromAgentList(agent){
  return {
      type: 'REMOVE_FROM_AGENTS',
      payload: agent,
  }
}
export function addToGroupList(group){
  return {
      type: 'ADD_TO_GROUPS',
      payload: group,
  }
}

export function removeFromGroupList(group){
  return {
      type: 'REMOVE_FROM_GROUPS',
      payload: group,
  }
}
export function answerQuestion(num){
  return {
      type: 'ANSWER_QUESTION',
      payload: num,
  }
}

export function setTitle( string ){
  return {
      type: 'SET_TITLE',
      payload: string,
  }
}

export function setFilter( filter ){
  return {
      type: 'SET_FILTER',
      payload: filter,
  }
}

export function setAssetFilter( filter ){
  return {
      type: 'SET_ASSET_FILTER',
      payload: filter,
  }
}

export function removeFilter( filter ){
  return {
      type: 'REMOVE_FILTER',
      payload: filter,
  }
}

export function removeAssetFilter( filter ){
  return {
      type: 'REMOVE_ASSET_FILTER',
      payload: filter,
  }
}

export function selectEntity( entity ){
  return {
      type: 'SELECT_ENTITY',
      payload: entity,
  }
}

export function deselectEntity( entity ){
  return {
      type: 'DESELECT_ENTITY',
      payload: entity,
  }
}