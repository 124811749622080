import React, { Component } from 'react';
import history from '../../../../history';
import './../contract.css';

import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class ContractTeaser extends Component {

  handleNext(path){
    history.push(path)
  }
  
  handleChange(action){
    var query = {
      contracts: [this.props.contract._id],
      user: this.props.user
    }
    axios.put(API_URL + '/contracts/'+ action +'/' + this.props.user.supplier, query)
			.then((ret) => {
        this.setState({status: ret.data})
        this.props.refresh()
      })
  }

  render() {    

    let contract = this.props.contract
    let status = contract.onboardingStatus==='Signed'? contract.status : contract.onboardingStatus 

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let signingDate = '-'
    if(contract.signingDate){
      signingDate = new Date(contract.signingDate)
      signingDate = signingDate.toLocaleDateString("en-EN", options)
    }

    let colorCode = 'egreen'
    if(['LOCKED', 'Approved'].indexOf(status) > -1){colorCode = 'ewarning'}
    if(['REPOSSESSED', 'WRITEOFF', 'Rejected'].indexOf(status) > -1){colorCode = 'edanger'}
    if(['Pending'].indexOf(status) > -1){colorCode = 'eneutral'}

    return (
      <div id="contractTeaserWrapper">
        <div id="contractTeaser" onClick={()=>this.handleNext('/contract/id='+contract.contractNumber)}>
          <div id="cTNumber">Contract {contract.contractNumber}</div>
          <div id="cTLabel">Product</div>
          <div id="cTName">{contract.product? contract.product.name: '-'}</div>
          <div id="cDLabel">Deal</div>
          <div id="cTDeal">{contract.deal.dealName}</div>
          <div id="cTLabel">Status</div>
          <div id="cTStatus" className={colorCode}>{status}</div>
          <div id="cTLabel">Signed</div>
          <div id="cTName">{signingDate}</div>
          <div id="cTLabel">Progress</div>
          <div id="cTProgress">{ contract.totalCost !== 0 ? Math.round( contract.totalPaid * 100 / contract.totalCost) : '-'}%</div>        
        </div>
        
        { status == 'Pending' &&
          <div>
            <div id="ctApprove" onClick={()=>this.handleChange('approve')}>Approve</div>
            <div id="ctReject" onClick={()=>this.handleChange('reject')}>Reject</div>
          </div>}
      
      </div>
    );
  }
}

export default ContractTeaser