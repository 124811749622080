import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import EntitiesList from './List/EntitiesList'
import AdminMenu from '../AdminMenu'

import './entity.css';

class EntitiesController extends Component {
  constructor(props) {
		super(props)
		this.state = {
    };
  }

  componentDidMount() {
    this.getEntitiesList();
    window.scrollTo(0, 0);
  }

  selectEntity = (entity) => this.setState({ entity, secondEntity: undefined, thirdEntity: undefined })
  selectSecondEntity = (secondEntity) => this.setState({ secondEntity, thirdEntity: undefined })
  selectThirdEntity = (thirdEntity) => this.setState({ thirdEntity })

	getEntitiesList = () => {
    axios.post(API_URL + '/entities/search/' + this.props.user.supplier, {
      user: this.props.user._id,
      query: {}
    })
			.then((ret) => { this.setState({ entities: ret.data }) })
  }
  
  render() {    
    let user = this.props.user
    let entityOptions = user.options.entities
    let { entity, secondEntity, thirdEntity, entities } = this.state
    let orgStructure = entities && entities.map( elem => elem.type )

    let type, secondType, thirdType = undefined
    if( (!entities || !entities.length ) && entityOptions.countries ) type = 'Country'
    else if( (!entities || !entities.length ) && entityOptions.organisations ) type = 'Organisation'
    else if( (!entities || !entities.length ) && entityOptions.branches ) type = 'Branch'
    else if( entities && entities.length ){
      orgStructure = [ ... new Set(orgStructure) ]
      if( orgStructure.indexOf( 'Country' ) > -1 ) type = 'Country'
      else if( orgStructure.indexOf( 'Organisation' ) > -1 ) type = 'Organisation'
      else if( orgStructure.indexOf( 'Branch' ) > -1 ) type = 'Branch'
      else if( orgStructure.indexOf( 'Shop' ) > -1 ) type = 'Shop'
    }

    if( entity && entity.type == 'Country' ) secondType = 'Organisation'
    if( entity && entity.type == 'Organisation' ) secondType = 'Branch'
    if( entity && entity.type == 'Branch' ) secondType = 'Shop'

    if( secondEntity && secondEntity.type == 'Organisation' ) thirdType = 'Branch'
    if( secondEntity && secondEntity.type == 'Branch' ) thirdType = 'Shop'

    return (
      <div className="background">
        <div id="pageTitle">Organisational structure</div>
          
        <AdminMenu user={ user }/>

        <div id="clientBackground">

          <div id="clientSubMenu">  

          </div>

          { entities &&
            <EntitiesList 
              supplier={ user.supplier } 
              entities={ entities } 
              type={ type }
              select={ this.selectEntity }
              refresh={ this.getEntitiesList } 
              user={ user }
            /> }

          { entities && entity &&
            <EntitiesList 
              supplier={ user.supplier } 
              entity={ entity }
              entities={ entities } 
              type={ secondType }
              secondEntity={ secondEntity }
              select={ this.selectSecondEntity }
              refresh={ this.getEntitiesList } 
              user={ user }
            /> }

          { entities && secondEntity &&
            <EntitiesList 
              supplier={ user.supplier } 
              entity={ secondEntity }
              entities={ entities } 
              type={ thirdType }
              select={ this.selectThirdEntity }
              refresh={ this.getEntitiesList } 
              user={ user }
            /> }

        </div>

      </div>
    );
  }
}

export default EntitiesController