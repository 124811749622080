import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import Summary from './Details/Summary'
import Progress from './Details/Progress'
import Split from './Details/Split'
import EditGroup from './Action/EditGroup'
import ContractsList from './../Contracts/List/ContractsList'
import ManageMenu from '../ManageMenu'
import './group.css'

class Group extends Component {
  constructor(props) {
		super(props)
		this.state = {
      edit: false
    };
  }

  componentDidMount() {
    this.loadData()
  }
  
  getGroupDetails = () => {
    axios.get(API_URL + '/groups/byNumber/' + this.props.user.supplier + '/' + this.props.match.params.groupNumber)
			.then((ret) => this.setState({ group: ret.data.group }))
      .catch(err => console.log(err))
  }

  loadData() {
    this.getGroupDetails()
  }

  goBack() {
    window.history.back();
  }
  edit = () => {
    this.setState({ edit: false });
    this.getGroupDetails()
  }

  render() {    
    
    let user = this.props.user;
    let { group, edit } = this.state
    
    return (
			<div className="background">
          <div>
            <div id="pageTitle">Group  <span className="subTitle">{ group ? group.groupNumber : '-'}</span></div>
            <ManageMenu user={ user }/>
            <div id="clientActionLine">
              <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
              <div id="editClient" onClick={()=>this.setState({ edit: !edit })}><i className="fas fa-pen-square"></i></div>
            </div>

            { group && 
              <div id="clientBackground">

                <div id="contractSection">
                  <Progress 
                    title={ 'Repayment progress' } 
                    group={ group }
                    user={ user }
                    width={ 100 }
                    height={ 250 }
                  />
                </div>
                <div id="contractSection">
                  <Split 
                    title={ 'Status breakdown' } 
                    contracts={ group.contracts }
                    width={ 100 }
                    height={ 250 }
                  />
                </div>
                <div id="contractSection">
                  <Summary group={ group }/>
                </div>

                <div id="contractsList" style={{ marginTop: '15px' }}>
                  <ContractsList contracts={ group.contracts } contractStatus={ group.onboardingStatus == 'Pending' ? 'Pending' : 'Signed' }/>
                </div>
              </div>
            }
            { edit && <EditGroup 
              show={ edit } 
              onHide={ this.edit }
              group={ group }
              user={ user} 
            /> }

          </div>

			</div>
    );
  }
}


export default Group