import React, { Component } from 'react';
import { Item } from './Item'
import './../List/auxiliaries.css'

class List extends Component {

  render() {    
    let parts, extensions = null;
    let { auxiliaries, product, user } = this.props
    parts = auxiliaries && auxiliaries
      .sort((a, b) => a.product.name.localeCompare(b.product.name))  
      .filter( elem => elem.type == 'Part').map( auxiliary =>{ 
        return (
          <Item auxiliary={auxiliary} key={ auxiliary._id } user={ user } refresh={this.props.refresh}/>
        )
      })

    extensions = auxiliaries && auxiliaries
      .sort((a, b) => a.product.name.localeCompare(b.product.name))
      .filter( elem => elem.type == 'Extension').map( auxiliary =>{ 
        return (
          <Item auxiliary={auxiliary} key={ auxiliary._id } user={ user } refresh={this.props.refresh}/>
        )
      })

    return (
      <div>
        { parts && !product && 
          <div id="pausedList">
            <div  id="pausedListTitle">Parts</div>
            <div id="auxiliaryReference" className="pausedListTitle onlyLarge pausedListAuxiliary">Reference</div>
            <div id="auxiliarySKU" className="pausedListTitle onlyLarge">SKU</div>
            <div id="auxiliaryProduct" className="pausedListTitle onlyLarge">Associated product</div>
            <div id="auxiliarySerial" className="pausedListTitle onlyLarge">Serialized</div>
          </div>
        }
        { parts && !product && parts.length == 0 && 
          <div id="auxiliaryExplaination">No part set up yet. <br/>You can set up extensions on detailed view of the related product.</div>}
        { parts }
        { extensions && !product && <div id="pausedList">Extensions</div>}
        { extensions && !product && extensions.length == 0 && 
          <div id="auxiliaryExplaination">No extension set up yet. <br/>Extensions are sub-products or items that you sell in relation to a main product but that do not constitute standalone assets (extra lamps, data add-ons, cookstove refills, etc...).<br/>You can set up extensions on detailed view of the related product.</div>}
        { extensions }
      </div>
    );
  }
}

export default List