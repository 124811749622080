import React, { Component } from 'react';
import './../List/contracts.css';

export class ContractHeader extends Component {
  
  render() {
    return (
      <div className="contractsTitle">
        <div id="contractsSmallLight" >
          Contract
        </div>  
        <div id="contractsMedLight" >
          Deal
        </div>    
        <div id="contractsSmallLight" >
          Total paid
        </div>  
        <div id="contractsExtraSmallLight" >
          %
        </div>    
        <div id="contractsSmallLight" >
          Client
        </div>          
        <div id="contractsSmallLight">
          Status
        </div>  
      </div>
    )
  }  
}