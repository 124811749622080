import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import AgentTargetsAction from './Action/AgentTargetsAction'
import AgentTargetsList from './List/AgentTargetsList'
import './agentTarget.css'

class AgentTargetsController extends Component {

  constructor(props) {
		super(props)
		this.state = {
      agentTargets: undefined,
      edit: false,
      showModal: false
    };
  }
  
  componentDidMount() {
    this.loadData();
  }
  
	getAgentTargetsList = () => {
    axios.post(API_URL + '/agents/getTargets/'+ this.props.agent.supplier, {
      agent: this.props.agent._id
    }).then((ret) => this.setState({ agentTargets: ret.data }) )
  }
  
  close(){
    this.setState({ showModal: false })
    this.getAgentTargetsList();
  }

  loadData() {
    this.getAgentTargetsList()
  }

  render() {    
    let user = this.props.user
    let options = user.options
    let permission = false
    if( options.manageAgents && ( ['MainAdmin', 'Core', 'Admin'].indexOf(user.role ) > -1 || user.permissions.manageAgents ) ) permission = true
    return (
      <div >
        <div>
          { permission
            ? <div className="addTargets" onClick={() => this.setState({ edit: !this.state.edit })}>
                <span style={{ paddingRight: '10px' }}><i className="fas fa-pen"></i></span>Manage 
              </div>
            : null }
          { this.state.edit
            ? <div className="createItem" onClick={() => this.setState({ showModal: !this.state.showModal })}>
                <span style={{ paddingRight: '10px' }}><i className="fas fa-plus-square"></i></span>Create new target 
              </div>
            : null }
            
          <div id="targetWarning">
            Warning: target remunerations are added to any standard commissions that may be defined at the deal level
          </div>

          { this.state.agentTargets && this.state.agentTargets.length > 0
            ? <AgentTargetsList 
                agentTargets={ this.state.agentTargets } 
                refresh={ this.getAgentTargetsList } 
                supplier= { user.supplier } 
                user={ user }
                edit={ this.state.edit }
                />
            : <div className='noManagedAgents'>No target set at this time</div> }
        </div>

        { this.state.showModal
          ? <AgentTargetsAction 
              show={this.state.showModal} 
              agent={ this.props.agent }
              onHide={this.close.bind(this)}
              user={ user }
              supplier= { user.supplier }
            /> 
          : null }

      </div>
    );
  }
}

export default AgentTargetsController