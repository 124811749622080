import React from 'react';
import { FormControl } from 'react-bootstrap/lib';

class DefineCondition extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      condition:{}, 
    };
  } 

  componentDidMount() {
    if( this.props.condition ){
      let condition = Object.assign({}, this.props.condition)
      this.setState({ condition })
    }
  }


  render() {
    let { questions } = this.props
    let condition = this.state.condition

    let mappedQuestions = questions &&  questions
      .sort((a, b) => a.rank - b.rank)
      .filter( elem => elem.selectable )
      .map( question =>{ 
        return <option className="agentOption" key={question._id} value={question._id}>{question.bCode}</option>
      })
    
    let mappedAnswers = undefined
    if( condition.question ){
      let question = questions.find( elem => elem._id == condition.question )
      if( !question.options ){
        let possibleAnswers = question.possibleAnswers
        mappedAnswers = possibleAnswers && possibleAnswers
        .map( (answer, index) =>{ 
          return <option key={index} value={answer} className="agentOption">{answer}</option>
        })
      } else {
        let possibleAnswers = question.options
        mappedAnswers = possibleAnswers && possibleAnswers
        .map( (option, index) =>{ 
          return <option key={index} value={option.answer} className="agentOption">{option.translatedAnswer}</option>
        })
      }

    }

    let question, answer = undefined
    if( this.props.condition ){
      question = questions.find( elem => elem._id == this.props.condition.question ).bCode
      answer = this.props.condition.answer
    }

    return (

      <div>

        <div className="questionLabel">Depends on question</div>
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="questionInput"
          onChange={(e)=>{ 
            let condition = Object.assign({}, this.state.condition); 
            condition.question = e.target.value; 
            this.setState({ condition }) 
          }}
        > 
          <option value="" className="agentOption">{ !question ? 'Select one' : question }</option>
          { mappedQuestions }
        </FormControl> 

        { (condition.question || question) && 
          <div>
            <div className="questionLabel">Conditional on answer being</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="questionInput"
              onChange={(e)=>{ 
                let condition = Object.assign({}, this.state.condition); 
                condition.answer = e.target.value; 
                this.setState({ condition }, () => this.props.set(condition) ) 
              }}
            > 
              <option value="" className="agentOption">{ !answer ? 'Select one' : answer }</option>
              { mappedAnswers }
            </FormControl> 
          </div> }
     
      </div>

    );
  }
}

export default DefineCondition;