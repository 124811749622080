import React from 'react';
import {Button, Modal, FormControl} from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from './../../../../Actions/index';

class AmendAsset extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status: undefined,
      asset:{
        serialNumber: undefined,
      },
    };
  } 

  amendAsset() {
    axios
      .put(API_URL + '/assets/amend/'+ this.props.asset.supplier + '/' + this.props.asset.assetNumber, this.state.asset)
      .then((ret) => { this.setState({ status: ret.data }) })
      .catch( err => {
        this.setState({ status: 'Error' })
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status === 'Success!' ?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  over(){
    this.props.onHide();
    this.props.getEvents();
    this.props.refresh();
    this.setState({
      status: undefined,
      asset:{
        serialNumber: undefined,
      },
    })
  }

  render() {

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Change serial number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">
            <FormControl 
              type="text" 
              className="contractEditInput"
              placeholder="Type new serial number"
              onChange={(e)=>{ let asset = Object.assign({}, this.state.asset); asset.serialNumber = e.target.value; this.setState({ asset }) }}
            />
            {this.renderMessage()}
            <div id="modal_action">
            {this.state.status==='Success!'?
                null
                :
                <Button id="action_signup" onClick={this.amendAsset.bind(this)}>Confirm</Button>

              }
              <Button id="action_cancel" onClick={() => this.over()}>Abort</Button>
            </div>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AmendAsset;