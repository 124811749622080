import React, { Component } from 'react';
import {UsersListItem} from './UsersListItem'
import {UserHeader} from './UserHeader' 

import './users.css';

class UsersList extends Component {
  
  render() {    
    let mappedUsers = null;
    if(this.props.users){
      mappedUsers = this.props.users.map((user, index) =>{ 
        return (
          <UsersListItem key={index} user={user} refresh={this.props.refresh} viewerRole={ this.props.viewerRole } activeUser={ this.props.activeUser } />
        )
      })
    }

    return (
      <div id="usersList">
        <UserHeader/>
        { mappedUsers }
      </div>
    );
  }
}

export default UsersList