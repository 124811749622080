import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
//import registerServiceWorker from './registerServiceWorker';

import history from './history';
import store from './store';
import axios from "axios";

import { tokenExpired } from './Actions/auth';

axios.interceptors.request.use(function (config) {
  config.headers = { 'x-access-token': localStorage.getItem("token") };

  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  
  if(response && (response.status === 403 
    || response.status === 401 
    || response.data.name === "JsonWebTokenError"
    || response.data.name === "TokenExpiredError" 
    || response.data.status === "Access Denied"
  )) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    tokenExpired();
    history.push('/login')
    return Promise.reject({status: 403, message: "Access denied"});
  }else {
    return response;
  }

}, function (error) {
  return Promise.reject(error);
});

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
//registerServiceWorker();
