import React, { Component } from 'react';
import {FormControl, Button} from 'react-bootstrap/lib';
import history from '../../../../history'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index'
import { SelectAgent } from './../../Agents/Action/SelectAgent'
import { findGroupPricings } from  './../../../../services/DealService'
import { findContracts } from  './../../../../services/ContractService'

class CreateGroup extends Component {

  constructor(props) {
    super(props);
    this.state= {
      group: {
        adminDates:{
          entryDate: new Date()
        },
        contracts: [],
        supplier: this.props.user? this.props.user.supplier: this.props.supplier
      },       
      ready: false,
      disabled: false
    }
  }; 
  
  componentDidMount() {
    this.loadData()
  }

  handleNext(path){
    history.push(path)
  }

  createGroup() {
    this.setState({ disabled: true});

    let supplierId = this.props.user? this.props.user.supplier: this.props.supplier

    axios.post(API_URL + '/groups/create/'+ supplierId, this.state.group)
      .then((ret) => {
        this.setState({ status: ret.data.message });
      })
      .catch(err => {
        this.setState({ status: err.message })
      }); 
  }

  getTemplates = async () => {
    try {
      let groupPricings = await findGroupPricings( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ groupPricings })
    } catch(err){ console.log(err) }
  }

  getContracts = async () => {
    try {    
      let deal = this.state.groupPricings.find( elem => elem._id == this.state.group.groupPricing )
      let query = {
        onboardingStatus: { $in: ['Approved', 'Signed'] },
        group: { $exists: false },
        deal: { $in: deal.eligibleDeals }
      }
      let contracts = await findContracts( query, this.props.user._id, this.props.user.supplier )
      this.setState({ contracts })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getTemplates();
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulAction':'successfulAction'}>
          {this.state.status}
        </div>
      );
    }
  }  

  select(status){
    this.setState({ add: status })
    if( status ) this.getContracts()
  }

  selectAgent = (agent) => {
    let group = Object.assign({}, this.state.group); 
    group.agent = agent
    this.setState({ group }) 
  }

  selectContract( id ){
    let group = Object.assign({}, this.state.group); 
    let list = []
    if( group.contracts.indexOf( id ) < 0 ) list = [...group.contracts, id]
    else list = group.contracts.filter( elem => elem !== id )
    group.contracts = list
    this.setState({ group })
  }

  render() {    

    let { groupPricings, contracts, group, add, status } = this.state

    let mappedPricings = groupPricings && groupPricings.filter( elem => elem.active ).map( elem =>{ 
      return <option key={ elem._id } value={ elem._id }>{ elem.groupName }</option>
    })

    let mappedContracts = contracts && contracts
      .map( contract =>{ 
        let profile = contract.client.profile
        return (
          <div 
            key={ contract._id } 
            id="groupContractOption"
            className={ this.state.group.contracts.indexOf( contract._id ) > -1 ? 'selectedDeal gCCO': 'gCCO' }
            onClick={ () => this.selectContract( contract._id ) }
          >
            <div id="groupContractNumber">{ contract.contractNumber }</div>
            <div id="groupContractDeal">{ contract.dealName }</div>
            <div id="groupContractClient">{ profile.firstName } { profile.lastName }</div>
          </div>
        )
      })

    let isDisabled = group.groupPricing? false : true
    if( this.state.disabled ) isDisabled = true

    return (
     <div id="contract"> 
        <div id="contractSection">
          <div id="assetSummary">
            <div id="contractActionTitle">Select group pricing</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="contractInput"
              onChange={(e)=>{ 
                let group = Object.assign({}, this.state.group); 
                group.groupPricing = e.target.value; 
                this.setState({ group });
                }}
              >
              <option value="">Choose one</option>
              { mappedPricings }
            </FormControl>

            { group.groupPricing &&
              <div>
                <div id="contractActionTitle" style={{ marginTop: '15px'}}>Responsible agent</div>
                <SelectAgent
                  selectAgent={ this.selectAgent } 
                  format={ "contractInput" }
                  supplierId={ this.props.user.supplier }
                  userId={ this.props.user._id }
                />
              </div>
            }

            { group.groupPricing && group.agent &&
              <div>
                <div id="contractActionTitle" style={{ marginTop: '15px'}}>Add contracts to group</div>
                <div 
                  id="groupContractsOption" 
                  className={ add == true? 'activeGroupContractOption gCCO' : 'gCCO' }
                  onClick={() => this.select(true)}
                >
                  Add existing
                </div>
                
              </div>
            }
            
            { group.groupPricing && group.agent && group.contracts.length > 0 && status != 'Success' &&
              <Button disabled={isDisabled} onClick={()=>this.createGroup()} id="createGroup">Create</Button>
            }

            <div id="dealSection">
              { this.renderMessage() }
            </div>

          </div>

        </div>

        { group.groupPricing && group.agent && add == true &&
          <div className="groupContracts">
            <div id="contractActionTitle">Choose contracts</div>
            { mappedContracts }
          </div>
        }

      </div>
    );
  }
}

export default CreateGroup