import React, { Component } from 'react';
import './payrollcontracts.css'

export class Header extends Component {
  
  render() {

    return (
      <div className="payrollContractTitle">       
        <div className="payrollContractNumber">Contract number</div>
        <div className="payrollContractDate">Date signed</div>
        <div className="payrollContractDeal">Deal</div>
        <div className="payrollContractAmount">During period</div>
        <div className="payrollContractAmount">Total paid</div>
        <div className="payrollContractAmount">Total cost</div> 
        <div className="payrollContractAmount">Commission</div>       
      </div>
    )
  }  
}