import React, { Component } from 'react';
import { TargetHeader } from './TargetHeader'
import { TargetItem } from './TargetItem'
import './payrollcontracts.css';

class ListOfTargets extends Component {
  
  render() {    
    let mappedTargets = null;

    let targets = this.props.payroll.hitTargets
    if( targets ){
      targets.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      mappedTargets = targets.map((target, index) =>{ 
        return (
          <TargetItem 
            key={ index }
            payroll={ this.props.payroll }
            target={ target } 
          />
        )
      })
    }

    return (
      <div id="payrollLargeWrapper">
        <div id="payrollDetailsTitle">Details of reached targets</div>
        <div id="payrollContractList">
          <TargetHeader/>
          { mappedTargets }
        </div>
      </div>
    );
  }
}

export default ListOfTargets