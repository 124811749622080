import React from 'react';
import axios from 'axios';
import {Button, Modal, FormControl} from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import EditPermission from './EditPermission'
import './userAction.css'

class UserAction extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      user: {
        profile: {},
        entities: {},
        permissions:{},
      },
      registerError: {
        lastName: false,
        firstName: false,
        password: false,
        password2: false,
        role: false,
        passwordConfirm: false
      },
      editError: {
        email: false,
        password: false,
        passwordConfirm: false
      },
      disabled: false
    };
  } 

  componentDidMount() {
    if( this.props.user ) {
      let user = Object.assign( this.state.user, this.props.user )
      this.setState({ user })
    }
    if( ['Core', 'MainAdmin', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin'].indexOf(this.props.activeUser.role) > -1 ){
      this.getEntitiesList()
    }
  }

  submit(){
    if( this.props.edit && this.editValid() ){
      axios
      .put(API_URL + '/users/edit/' + this.props.user._id, this.state.user)
      .then((ret) => {
        this.setState({ status: 'Success' })
      })
      .catch(err => {
        console.log(err)
        this.setState({ status: "Error - username or email already taken." })
      }); 
    }
    else if (this.registerValid()) {
      this.setState({disabled: true})
      axios.put(API_URL + '/users/create/'+ this.props.supplier, this.state.user)
        .then((ret) => {
            this.setState({ status: ret.data.message });
        })
        .catch(err => {
          this.setState({ status: "Error - username or email already taken." })
        });       
    }
  }

	getEntitiesList = () => {
    axios.post(API_URL + '/entities/search/' + this.props.activeUser.supplier, {
      user: this.props.activeUser._id,
      query: {}
    })
			.then((ret) => { this.setState({ entities: ret.data }) })
  }

  registerValid() {
    let user = Object.assign({}, this.state.user);

    let registerError = {
      lastName: false,
      firstName: false,
      email: false,
      role: false,
      password: false,
      passwordConfirm: false
    };
    
    let isValid = true;

    if (!user.password || user.password.length < 7) {
      registerError.password = true;
      isValid = false;
    } 
    let marker = 0
    if( user.password ){
      user.password.split('').forEach( car => {
        if( ['!', ',', '.', ':', '?', '#', '%' , '(', ')', '@', '*', '&', '_', '-' ].indexOf(car) > -1){
          marker = 1
        }
      })
    }

    if( marker === 0 ){
      registerError.password2 = true;
      isValid = false;      
    }
    if( user.password !== user.passwordConfirm ) {
      registerError.passwordConfirm = true;
      isValid = false;
    }
    if( !user.role ) {
      registerError.role = true
      isValid = false;
    }
    this.setState({registerError});
    
    return isValid;
  }

  editValid() {
    let user = Object.assign({}, this.state.user);

    let editError = {
      lastName: false,
      firstName: false,
      email: false,
      password: false,
      passwordConfirm: false
    };
    
    let isValid = true;
    if ( user.password && user.password.length < 7) {
      editError.password = true;
      isValid = false;
    } 

    let marker = 0
    if( user.password ){
      user.password.split('').forEach( car => {
        if( ['!', ',', '.', ':', '?', '#', '%' , '(', ')', '@', '*', '&' ].indexOf(car) > -1){
          marker = 1
        }
      })
    }

    if( user.password && marker === 0){
      editError.password2 = true;
      isValid = false;      
    }

    if (user.password && user.password !== this.state.user.passwordConfirm) {
      editError.passwordConfirm = true;
      isValid = false;
    }
      
    this.setState({ editError });
    return isValid;
  }
  
  over(){
    this.props.onHide();
    this.setState({
      status: undefined,
      disabled: false,
      registerError: {
        password: false,
        password2: false,
        passwordConfirm: false,
        role: false
      },
      user: {
        profile: {
          firstName: undefined,
          lastName: undefined
        },
        permissions:{
          replaceAsset: undefined,
          approveClient: undefined,
          approveContract: undefined,
          editContract: undefined,
          sendBonus: undefined,
          repossessAsset: undefined,
          writeOffContract: undefined,
          createAgents: undefined,
          transferClients: undefined,
          transferAssets: undefined,
          transferPayments: undefined,
          addPayments: undefined,
          
        },
        email: undefined,
        username: undefined,
        country: undefined,
        password: undefined,
        passwordConfirm: undefined,
        status: undefined
      },
      editError: {
        lastName: false,
        firstName: false,
        email: false,
        password: false,
        passwordConfirm: false
      },
    })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status === 'Success'? 'successfulCreation' : 'unSuccessfulCreation' }>
          { this.state.status }
        </div>
      );
    }
  }   

  setPermission = ( permission, decision ) => {
    let user = Object.assign({}, this.state.user); 
    user.permissions[permission] = decision; 
    this.setState({ user })
  }


  render() {
    let { user, activeUser } = this.props
    let options = activeUser.options

    let viewerRole = activeUser.role
    let entities = this.state.entities
    let entity = this.state.user.entities[0]
    let selectedEntity = entities && entity && entities.find( elem => elem._id == entity )

    let isDisabled = (
      !this.state.user.email ||
      !this.state.user.password ||
      !this.state.user.passwordConfirm ||
      !this.state.user.profile.lastName ||
      !this.state.user.profile.firstName ||
      ( this.state.user.role == 'OrgAdmin' && ( !selectedEntity || selectedEntity.type !== 'Organisation') ) ||
      ( this.state.user.role == 'CountryAdmin' && ( !selectedEntity || selectedEntity.type !== 'Country') ) ||
      ( this.state.user.role == 'BranchAdmin' && ( !selectedEntity || selectedEntity.type !== 'Branch') )
    ) ? true : false
    if( this.state.disabled ) isDisabled=true     

    let navigate = <Button id="action_signup" disabled={isDisabled} onClick={this.submit.bind(this)}>Create</Button>
    
    if(this.props.edit){
      navigate = <Button id="action_signup" onClick={this.submit.bind(this)}>Edit</Button>
    }

    let organisations = entities && entities
      .sort((a, b) => a.name.localeCompare(b.name))  
      .filter( elem => elem.type == 'Organisation').map( entity =>{ 
        return <option key={ entity._id } value={ entity._id }>{entity.name}</option>
      })

    let countries = entities && entities
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter( elem => elem.type == 'Country').map( entity =>{ 
        return <option key={ entity._id } value={ entity._id }>{entity.name}</option>
      })

    let branches = entities && entities
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter( elem => elem.type == 'Branch').map( entity =>{ 
        return <option key={ entity._id } value={ entity._id }>{entity.name}</option>
      })

    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">{this.props.edit?'Edit':'Create'} User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">

        <div className="signin_error">{ ( this.state.registerError.firstName ) && <label>FirstName is compulsory</label> }</div>

        <div className="userLabel">Firstname</div>
        <FormControl 
            type="text" 
            className="userInput"
            value={
              this.state.user.profile.firstName !== undefined
                ? this.state.user.profile.firstName
                : (user && user.profile ? user.profile.firstName:'')
            } 
            placeholder={'First name'}
            onChange={(e)=>{ let user = Object.assign({}, this.state.user); user.profile.firstName = e.target.value; this.setState({ user }) }}
          />

          <div className="signin_error">{ ( this.state.registerError.lastName ) && <label>Last name is compulsory</label> }</div>
          <div className="userLabel">Lastname</div>
          <FormControl 
            type="text" 
            className="userInput"
            value={
              this.state.user.profile.lastName !== undefined
                ? this.state.user.profile.lastName
                : (user && user.profile ? user.profile.lastName:'')
            }                 
            placeholder={'Last name'}
            onChange={(e)=>{ let user = Object.assign({}, this.state.user); user.profile.lastName = e.target.value; this.setState({ user }) }}
          />     
          
          <div className="signin_error">{ ( this.state.registerError.email ) && <label>You must enter a valid email address</label> }</div>
          <div className="userLabel">Email</div>
          <FormControl 
            type="text" 
            className="userInput"
            value={
              this.state.user.email !== undefined
                ? this.state.user.email
                : (user ? user.email:'')
            }                    
            placeholder={'Email'}
            onChange={(e)=>{ let user = Object.assign({}, this.state.user); user.email = e.target.value; this.setState({ user }) }}
          />
          <div className="userLabel">Username</div>
          <FormControl 
            type="text" 
            className="userInput"
            value={
              this.state.user.username !== undefined
                ? this.state.user.username
                : (user ? user.username:'')
            }                    
            placeholder={'Username'}
            onChange={(e)=>{ let user = Object.assign({}, this.state.user); user.username = e.target.value; this.setState({ user }) }}
          />

          <div className="signin_error">{ ( this.state.registerError.role ) && <label>Please specify role of new user</label> }</div>
          <div className="userLabel">Role</div>
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select" 
            className="userInput"
            onChange={(e)=>{ 
              let user = Object.assign({}, this.state.user); 
              user.role = e.target.value; 
              user.entities = {}
              this.setState({ user }) 
            }}
            > 
            <option value={ this.props.user? this.props.user.role : 'Select role' }>
              { this.props.user? this.props.user.role : 'Select role' }
            </option>
            { ['Core', 'MainAdmin' ].indexOf(viewerRole) > -1 && <option value="MainAdmin">Main admin</option> }
            { ['Core', 'MainAdmin' ].indexOf(viewerRole) > -1 && countries && countries.length && <option value="CountryAdmin">Country admin</option> }
            { ['Core', 'MainAdmin', 'CountryAdmin' ].indexOf(viewerRole) > -1 && organisations && organisations.length && <option value="OrgAdmin">Org. admin</option> }
            { ['Core', 'MainAdmin', 'OrgAdmin' ].indexOf(viewerRole) > -1 && branches && branches.length && <option value="BranchAdmin">Branch admin</option> }
            <option value="User">User</option>
            <option value="Disabled">Disabled</option>
          </FormControl>

          { countries && countries.length > 0 && this.state.user.role && 
            ['User', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'Viewer' ].indexOf(this.state.user.role) > -1 &&
            <div>
              <div className="userLabel">Entity</div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="userInput"
                onChange={(e)=>{ 
                  let user = Object.assign({}, this.state.user); 
                  user.entities = [];
                  user.entities.push(e.target.value); 
                  this.setState({ user }) 
                }}
              > 
                <option value="">{ 'Choose entity' }</option>
                { ['User', 'CountryAdmin', 'Viewer' ].indexOf(this.state.user.role) > -1 && countries }
                { ['User', 'OrgAdmin', 'Viewer' ].indexOf(this.state.user.role) > -1 && organisations }
                { ['User', 'BranchAdmin', 'Viewer' ].indexOf(this.state.user.role) > -1 && branches }
              </FormControl>  
            </div> }
            
        
          <div id="dealSection">
            <div className="signin_error">{ ( this.state.registerError.password || this.state.editError.password ) && <label>Your passsword needs to be at least 7 characters long</label> }</div>
            <div className="signin_error">{ ( this.state.registerError.password2 || this.state.editError.password2 ) && <label>Your passsword needs to include at least one special character</label> }</div>
            <div className="userLabel">Assigned pwd</div>
            <FormControl 
              type="password" 
              className="userInput"
              placeholder={this.props.edit? 'Set new password' : 'Choose password'}
              onChange={(e)=>{ let user = Object.assign({}, this.state.user); user.password = e.target.value; this.setState({ user }) }}
            />
            <div className="userLabel">Confirm</div>
            <FormControl 
              type="password" 
              className="userInput"
              placeholder='Confirm password'
              onChange={(e)=>{ let user = Object.assign({}, this.state.user); user.passwordConfirm = e.target.value; this.setState({ user }) }}
            />
          </div>

          <div id="dealSection">

            { ['Core', 'MainAdmin', 'Admin', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin' ].indexOf(viewerRole) > -1 && 
              ['Core', 'MainAdmin', 'Admin', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin' ].indexOf( this.state.user.role ) < 0 &&
              <div>
                <div id='permissionT'>{this.props.edit? 'Edit permissions': 'Define permissions'}</div>

                <EditPermission
                  user={ this.props.user }
                  title="Can set up new agents" 
                  setPermission={ this.setPermission }
                  permissionToSet="createAgents"
                />

                <EditPermission
                  user={ this.props.user }
                  title="Can approve clients and contracts" 
                  setPermission={ this.setPermission }
                  permissionToSet="approveClient"
                />

                <EditPermission
                  user={ this.props.user }
                  title="Can edit contract terms" 
                  setPermission={ this.setPermission }
                  permissionToSet="editContract"
                />                
                
                <EditPermission
                  user={ this.props.user }
                  title="Can send bonuses" 
                  setPermission={ this.setPermission }
                  permissionToSet="sendBonus"
                />   

                <EditPermission
                  user={ this.props.user }
                  title="Can write-off/pay-off contracts" 
                  setPermission={ this.setPermission }
                  permissionToSet="writeOffContract"
                />

                <EditPermission
                  user={ this.props.user }
                  title="Can replace units" 
                  setPermission={ this.setPermission }
                  permissionToSet="replaceAsset"
                />                
              
                <EditPermission
                  user={ this.props.user }
                  title="Can repossess units" 
                  setPermission={ this.setPermission }
                  permissionToSet="repossessAsset"
                />
                
                <EditPermission
                  user={ this.props.user }
                  title="Can transfer and restore units" 
                  setPermission={ this.setPermission }
                  permissionToSet="transferAssets"
                />

                <EditPermission
                  user={ this.props.user }
                  title="Can transfer clients" 
                  setPermission={ this.setPermission }
                  permissionToSet="transferClients"
                />
                
                <EditPermission
                  user={ this.props.user }
                  title="Can transfer payments" 
                  setPermission={ this.setPermission }
                  permissionToSet="transferPayments"
                />

                <EditPermission
                  user={ this.props.user }
                  title="Can add payments" 
                  setPermission={ this.setPermission }
                  permissionToSet="addPayments"
                />    

                { options && options.acceptFailedPayments &&
                  <EditPermission
                    user={ this.props.user }
                    title="Can assign payments" 
                    setPermission={ this.setPermission }
                    permissionToSet="assignPayments"
                  /> }
                
              </div>}
          
          </div>
        </div>
        {this.renderMessage()}            
        <br/>
        <div id="modal_action">
          { this.state.status==='Success'
            ? null
            : <div>{navigate}</div>
          }
          <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default UserAction;