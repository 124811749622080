import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { LOGIN_SUCCESS, TOKEN_EXPIRED,  } from './../../Actions/types';

export default function (ComposedComponent) {
  class Authentication extends Component {

    constructor(props) {
      super(props); 

      let token = localStorage.getItem('token');
      let isAuthenticated = (token && token !== null && token !== undefined ) ? true : false;

      if (isAuthenticated) {
        this.props.dispatch({type: LOGIN_SUCCESS, payload: { user: props.user }});
      } else {
        this.props.dispatch({type: TOKEN_EXPIRED, authenticated: false});
      }

      this.state = {
        isAuthenticated
      }
    }

    render() {

      if(this.props.user && this.props.user.passwordNeedUpdate) {
        return <Redirect to='/update-password' />
      }

      if(this.state.isAuthenticated) {
        return <ComposedComponent {...this.props} />;
      } else {
        return <Redirect to='/login' />
      }
    }
  }

  function mapStateToProps(state) {
    let user = undefined;

    if (state.auth && state.auth.user) {
      user = state.auth.user;
    } else {
      user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
      }
    }

    return { 
      authenticated: state.auth.authenticated,
      user
    };
  }

  return connect(mapStateToProps)(Authentication);
}