import React, { Component } from 'react';
import './../notes.css'

export class TaskHeader extends Component {
  
  render() {
    return (
      <div className="notesTitle">
        <div id="notesDate" >
          Date
        </div>  
        <div id="notesExtraSmall" className='onlyLarge'>
          Cat.
        </div>         
        <div id="notesDest" >
          { this.props.noteView === 'Inbox' || this.props.agent ? 'From' : 'To' }
        </div> 
        <div id="notesExtra" >
          Title
        </div> 
        <div id="notesLarge" className='onlyLarge'>
          Body
        </div> 
        <div id="notesSmall" >
          Contract
        </div> 
      </div>
    )
  }  
}