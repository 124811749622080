import React, { Component } from 'react';
import ContractsList from './../Contracts/List/ContractsList'
import AnalyticsMenu from '../AnalyticsMenu'

import { connect } from 'react-redux';

import { emptyList } from '../../../Actions/index';

class Selection extends Component {
  constructor(props) {
		super(props)
		this.state = {
      contracts:undefined,      
      contractStatus:'Signed',
      showModal: false,
      action: undefined,
      nav:1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  goBack() {
    window.history.back();
  }

  render() { 

    return (
      <div className='background'>   
        <div id="pageTitle">Contracts analysis</div>
        <AnalyticsMenu user={this.props.user}/>
        <div id="clientBackground">
          <div id="clientSubMenu">  
            <div id="contractAnalyticsSubTitle">
              <i className="fas fa-filter"></i>
              <span>&nbsp;&nbsp; { this.props.title } &nbsp;&nbsp;({this.props.listOfContracts.length})</span>
            </div>
            <div className="contractBack" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
          </div>
          { this.props.listOfContracts 
            ? <div id="contractsList">
                <ContractsList 
                  contracts={this.props.listOfContracts} 
                  contractStatus={this.state.contractStatus} 
                  refresh={this.getContractsList}
                />
              </div>
            : null }
        </div>
        
      </div>
    );
  }
}

const ContractsSelection = connect(
  function mapStateToProps(state) {
    return { 
      listOfContracts: state.listOfContracts,
      title: state.title
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Selection);

export default ContractsSelection