import React, { Component } from 'react';
import {ProductsListItem} from './ProductsListItem'
import './products.css';

class ProductsList extends Component {
  
  render() {    
    let mappedProducts, pausedProducts = null;
    let products = this.props.products
    mappedProducts = products && products.filter( elem => elem.status == 'Active').map((product, index) =>{ 
      return (
        <ProductsListItem key={index} product={product} refresh={ this.props.refresh } user={ this.props.user }/>
      )
    })
    pausedProducts = products && products.filter( elem => elem.status == 'Paused').map((product, index) =>{ 
      return (
        <ProductsListItem key={index} product={product} refresh={ this.props.refresh } user={ this.props.user }/>
      )
    })

    return (
      <div id="productsList">
        <div id="pausedList">
          <div id="pausedListTitle">Active products</div>
          { mappedProducts && 
          <div>
            <div id="productsParts" className="pausedListTitle onlyLarge pausedListProduct">Lockable</div>
            <div id="productsParts" className="pausedListTitle onlyLarge">Parts</div>
            <div id="productsParts" className="pausedListTitle onlyLarge">Extensions</div>
          </div>}
        </div>
        { mappedProducts }
        { pausedProducts && <div id="pausedList">Paused products</div>}
        { pausedProducts }
      </div>
    );
  }
}

export default ProductsList