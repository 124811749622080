import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk'; 
import rootReducer from './Reducers/index';
import { routerMiddleware } from 'react-router-redux';
import history from './history';

const middleware = routerMiddleware(history)
const createStoreWithMiddleware = applyMiddleware(reduxThunk, middleware)(createStore);  
const store = createStoreWithMiddleware(rootReducer);

export default store;
    