import React, { Component } from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import './../questionnaires.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class Copy extends Component {
  constructor(props) {
		super(props)
		this.state = {
      query: {
        questionnaireId: this.props.questionnaire._id,
        supplierId: this.props.supplier
      }
    };
  }

  submit(){

    axios.post(API_URL + '/questionnaires/copy/' + this.props.supplier, this.state.query)
			.then((ret) => {
        this.setState({
          status: 'Success'
        })
      })
  }

  over(){
    this.setState({
      status: undefined,
    })
    this.props.refresh()
    this.props.onHide()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  render() {   
    let disabled = false;

    return (
      <div>
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="small"
          aria-labelledby="contained-modal-title-sm"
        >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-sm">Copy questionnaire {this.props.questionnaire.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modalBody">
            <FormControl 
              type="text" 
              className="contractEditInput"
              placeholder="Choose new name"
              onChange={(e)=>{ let query = Object.assign({}, this.state.query); query.name = e.target.value; this.setState({ query }) }}
              />
          </div>
          {this.renderMessage()}            
          <div id="modal_action">
            { this.state.status !=='Success' &&
              <Button id="action_signup" disabled={disabled} onClick={()=>this.submit()}>Copy</Button> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Copy