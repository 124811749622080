import React, { Component } from 'react';

class LastItem extends Component {
  
  render() {    
    let item = this.props.item;

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let dateFrom = '-'
    let dateTo = '-'
    
    if(item.dateTo){
      dateTo = new Date(item.dateTo)
      dateTo = dateTo.toLocaleDateString("en-EN", options)
    }

    if(item.dateFrom){
      dateFrom = new Date(item.dateFrom)
      dateFrom = dateFrom.toLocaleDateString("en-EN", options)
    }

    return (
      <div className='lastItemWrapper'>
        <div id="lastItemLabel">From</div>
        <div id="lastItemDate">{ dateFrom }</div>
        <div id="lastItemLabel">To</div>
        <div id="lastItemDate">{ dateTo }</div>
        <div id="lastItemAmount">{ item.ccy }{ item.amount }</div>
      </div>
    );
  }
}

export default LastItem