import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
var DatePicker = require("react-bootstrap-date-picker");
var startOfDay = require("date-fns/start_of_day")


class RetryPaymentMessages extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false,
    };
  } 

  retry(){

    this.setState({disabled: true, status: 'One second...'})
    let query = {
      date: startOfDay(this.state.date)
    }
    axios.post(API_URL + '/messages/sendTextsToContractsAfterPayment/' + this.props.supplierId, query)
			.then( ret => this.setState({ 
        status: ret.data.status, 
        message: ret.data.message 
      }) )
			.catch( err => {
        console.log(err)
      })
    }

  over(){
    this.props.onHide();
    this.setState({status: undefined, disabled: false});
  }
  
  renderMessage() {
    if( this.state.status ) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status } { this.state.message }
        </div>
      );
    }
  }   
  render() {
    let disabled = true;
    if( !this.state.disabled && this.state.date) disabled = false

    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Retry failed payment messages</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">
          <div id="reversalDetails" style={{ marginTop: "-25px" }}>Payments after</div>
            <DatePicker 
              className ="agentInvoiceDateInput"
              placeholder="Date from"
              value={ this.state.date }  
              onChange={(e)=>{ 
                this.setState({ date: e }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
        </div>

        { this.renderMessage() }            
        <br/>

        <div id="modal_action">
          { this.state.status
            ? null
            : <Button id="confirm_action" disabled={disabled} onClick={()=>this.retry()}>Retry</Button>
          }
          <Button id="action_cancel" onClick={() => this.over()}>
            { this.state.status? 'Close' : 'Abort' }
          </Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default RetryPaymentMessages;