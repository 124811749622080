import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap/lib';
import DealsList from './List/DealsList'
import GroupPricingsList from './../Groups/GroupPricingsList/List'
import DealsAddOne from './Action/DealsAddOne'
import CreateGroupPricing from './../Groups/Action/CreateGroupPricing'
import AdminMenu from './../AdminMenu'
import './deal.css';
import AuxiliaryPricingsController from './../AuxiliaryPricings/AuxiliaryPricingsController';
import { findDeals, findGroupPricings } from  './../../../services/DealService'
import { findProducts } from  './../../../services/ProductService'
import { findQuestionnaires } from  '../../../services/QuestionnaireService'
import { hasPermission } from '../../../services/UserService'

class DealsController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      status: 'Deals',
      create: false,
      createGroup: false
    };
  }

  componentDidMount() {
    this.loadData()
  }

  getDealsList = async () => {
    try {
      let deals = await findDeals( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ deals, filteredDeals: deals })
    } catch(err){ console.log(err) }
  }

  getQuestionnairesList = async () => {
    try {
      let questionnaires = await findQuestionnaires( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ questionnaires })
    } catch(err){ console.log(err) }
  }

  getGroupPricings = async () => {
    try {
      let groupPricings = await findGroupPricings( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ groupPricings })
    } catch(err){ console.log(err) }
  }

  getProducts = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products })
    } catch(err){ console.log(err) }
  }

  close(){
    this.setState({ create: false });
    this.getDealsList()
  }

  closeGroup(){
    this.setState({ createGroup: false });
    this.getGroupPricings()
  }

  create(create){
    if( this.state.status == 'Deals' ) {
      this.setState({ create: !create });
      this.getDealsList();
    }
    if( this.state.status == 'Groups' ) this.setState({ createGroup: !create });
  }

  loadData() {
    this.getDealsList();
    this.getProducts();
    this.getQuestionnairesList();
  }

  groupPricings = () => {
    this.setState({ status: 'Groups' })
    this.getGroupPricings()
  }

  filter( status, product ){
    let filteredDeals = product && this.state.deals 
      ? this.state.deals.filter( elem => elem.eligibleProduct._id == product ) 
      : this.state.deals
    this.setState({ filteredDeals, status })
  }
  
  render() {    
    let user = this.props.user
    let { products, deals, groupPricings, status, filteredDeals, questionnaires, create, createGroup } = this.state

    let mappedProducts = products && products.map( product =>{ 
      return (
        <option key={product._id} value={product._id} className={'agentOption'}>{ product.name } by {product.manufacturer}</option>
      )
    })

    let permission = hasPermission( user, undefined, 'createDeals' )

    return (
      <div className="background">
        <div id="pageTitle">{
          status == 'Groups' ? 'Group pricings' : 'Pricing & Registration'
        }</div>

        { permission && status !== 'Aux' &&
          <div id="adminAction" onClick={()=>this.create(create)}>
            { ! create
              ? <i className="fas fa-plus"></i>
              : <i className="fas fa-minus"></i> }
          </div> }
          
        <AdminMenu user={ user }/>

        <div id="clientBackground">

          <div id="clientSubMenu">  

            <div id="clientSubMenu_item" onClick={()=>this.filter('Deals')} className={status == 'Deals'?"active_clientSubMenu leftMenu":"leftMenu"}>Deals</div>  
            
            { user.options.groups &&
              <div 
                id="clientSubMenu_item" 
                onClick={()=>this.groupPricings()} 
                className={ status == 'Groups'?"active_clientSubMenu":""}
              >
                Group pricings
              </div> }

            <div id="clientSubMenu_item" onClick={()=>this.filter('Aux')} className={ status == 'Aux'?"active_clientSubMenu rightMenu":"rightMenu"}>Auxiliaries</div>  


            <div id="assetSelectorWrapper" className='onlyLarge'>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="assetSelector"
                onChange={ (e) => { 
                  this.filter( status, e.target.value ) 
                }}
              >
                <option value="" className={'agentOption'}>All products</option>
                { mappedProducts }
              </FormControl>
            </div>

          </div>

          { filteredDeals && status == 'Deals' && questionnaires &&
            <DealsList 
              deals={ filteredDeals }
              refresh={ this.getDealsList }
              user={ user }  
              questionnaires={ questionnaires }
            /> }

          { status == 'Aux' &&
            <AuxiliaryPricingsController user={ user }/> }

          { groupPricings && status == 'Groups' && questionnaires &&
            <GroupPricingsList 
              groupPricings={ groupPricings }
              user={ user }  
              refresh={ this.getGroupPricings }
              questionnaires={ questionnaires }
            /> }

        </div>
        
        { create && permission && 
          <DealsAddOne 
            user={ user }
            refresh={this.getDealsList}
            deals={ deals }
            show={ create } 
            onHide={this.close.bind(this)}
          /> }

        { createGroup && permission && 
          <CreateGroupPricing 
            user={ user }
            refresh={this.getGroupPricings}
            show={ createGroup } 
            deals={ deals }
            onHide={this.closeGroup.bind(this)}
          /> }


      </div>
    );
  }
}

export default DealsController