//= =====================
// Auth Actions
//= =====================
export const AUTH_USER = 'auth_user',
  AUTH_ERROR = 'auth_error',
  REGISTER_ERROR = "REGISTER_ERROR",
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',
  PROTECTED_TEST = 'protected_test', 
  OUT_USER = 'out_user',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGOUT_SUCCESS= 'LOGOUT_SUCCESS',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED';

//= =====================
// User Profile Actions
//= =====================
export const FETCH_USER = 'fetch_user';

//= =====================
// Page Actions
//= =====================
export const SEND_CONTACT_FORM = 'send_contact_form',
  STATIC_ERROR = 'static_error';