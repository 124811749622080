import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import AssetsList from './../Assets/List/AssetsList'
import ManageMenu from './../ManageMenu'
import history from './../../../history';

class BatchesController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      batch: undefined,
      assetStatus: 'DEPLOYED'
    };
  }

  componentDidMount() {
    this.loadData();
    window.scrollTo(0, 0);
  }
   
	getBatchDetails = () => {
    axios.get(API_URL + '/batches/' + this.props.user.supplier + '/'+this.props.match.params.batchNumber)
			.then((ret) => this.setState({ batch: ret.data }) )
  }
  
  handleNext(path){
    history.push(path)
  }

  loadData() {
    this.getBatchDetails();
  }

  render() {    

    return (
      <div className='background'>  
        <div id="pageTitle">Batch <span className="subTitle">{this.props.match.params.batchNumber}</span></div>      
        <ManageMenu user={this.props.user}/>
        <div id="clientBackground">
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.setState({ assetStatus: 'DEPLOYED' })} className={this.state.assetStatus==='DEPLOYED'?"active_clientSubMenu":""}>Deployed</div>
            <div id="clientSubMenu_item" onClick={()=>this.setState({ assetStatus: 'INSTOCK' })} className={this.state.assetStatus==='INSTOCK'?"active_clientSubMenu":""}>In stock</div>
            <div id="clientSubMenu_item" onClick={()=>this.setState({ assetStatus: 'PENDING' })} className={this.state.assetStatus==='PENDING'?"active_clientSubMenu":""}>Pending</div>
            <div id="clientSubMenu_item" onClick={()=>this.setState({ assetStatus: 'DEFECTIVE' })} className={this.state.assetStatus==='DEFECTIVE'?"active_clientSubMenu":""}>Defective</div>
          </div>
          { this.state.batch
            ? <div id="assetsList">
                <AssetsList assets={this.state.batch.assets} assetStatus={this.state.assetStatus}/> 
              </div>
            : null }
        </div>       
      </div>
    );
  }
}



export default BatchesController