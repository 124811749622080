import React, { Component } from 'react';
import { Button } from 'react-bootstrap/lib';
import './../contract.css';
import './../../Utils/utils.css'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class UnLock extends Component {

  constructor(props) {
    super(props);
    this.state= {
      disabled: true, 
      activationCode: undefined,    
      status: undefined,
      message: undefined
    }
  }; 

  unlock() {
    this.setState({ disabled: true })
    axios.put(API_URL + '/contracts/unlock/'+ this.props.contract.supplier+ '/' + this.props.contract.contractNumber, {
      userId: this.props.user._id
    })
      .then((ret) => this.setState({ 
        status: ret.data.status,  
        message: ret.data.message
      }) )
      .catch(err => this.setState({ status: err.message }) ); 
  }  

  renderMessage() {
    if( this.state.status ) {
      return (
        <div>
          <div id={this.state.status == 'Success!'?'successfulAction':'failedAction'} className="full">
            { this.state.message }
          </div>
        </div>
      );
    }
  } 

  render() {    

    return (
      <div>

        <div id="contractEditTitle">Pay-off contract and send unlock code</div>
        <Button id="unlockContract" onClick={()=>this.unlock()}>Unlock</Button>

        {this.renderMessage()} 
      </div>
    );
  }
}

export default UnLock