import React from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import { AllPopulation } from './Graphs/total'

class SDG7 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      base: undefined,
      error: undefined
    };
  };

  componentDidMount() {
    this.getSignings();
    this.getGraph();
  }

  componentDidUpdate(prevProps) {
    if( this.props.filter !== prevProps.filter ) this.getGraph();
  }

  getGraph = () => {
    //"children_under_18_in_household"
    axios
      .post(API_URL + '/creditScores/analysis/' + this.props.supplier, {
        codes: [
          'children_younger_than_18_in_household', "children_under_18_in_household", "Number_of_children_under_18",
          'adults_over_55_in_household', "Adults_over_55",
          'adults_between_18_and_55_in_the_household', "adults_18_to_55_in_the_household", "Adults_between_18_55"
        ]
      }) 
      .then((res) => {
        this.setState({ data: res.data })
      })
      .catch(err => this.setState({ error: err.message }))
  }

  getSignings = () => {
    let query = Object.assign( {}, this.props.filter)
    query.freq = 'monthly'
    axios
      .post(API_URL + '/contracts/analysis/' + this.props.supplier, query)
      .then((res) => {
        this.setState({ signings: res.data })
      })
  }

  render() {
    let data = this.state.data
    let signings = this.state.signings

    let label = []
    let labelSignings = []
    let dataset = []
    let datasetSignings = []

    if( data && !this.state.error ){

      let signed = data.filter( elem => elem._id.status == 'Signed' )
      
      let allMonths = data
        .map( elem => elem._id.signingDate )
        .filter( elem => elem && elem != '2018-05' )
        .sort(function(a, b) {
          return new Date(a) - new Date(b)
        })
      label = [ ...new Set(allMonths) ]

      label.forEach( month => {
        
        let kids = signed
          .filter( elem => elem._id.signingDate == month )
          .reduce( function(acc, elem) { return acc + elem.sum; }, 0)
        if( !isNaN(kids) && kids > 0 ) dataset.push(kids)
        else dataset.push(0)

      })
    }

    if(signings){
      
      let allMonths = signings
        .map( elem => elem._id.date )
        .filter( elem => elem && elem != '2018-05' )
        .sort(function(a, b) {
          return new Date(a) - new Date(b)
        })
      labelSignings = [ ...new Set(allMonths) ]

      labelSignings.forEach( month => {
        
        let newclients = signings
          .filter( elem => elem._id.date == month )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        if( !isNaN(newclients) && newclients > 0 ) datasetSignings.push(newclients)
        else datasetSignings.push(0)

      })
    }

    return (
      <div >   
        <div id="impactGraphWrapper">
         { datasetSignings
          ? <AllPopulation 
              data={ datasetSignings } 
              label={ labelSignings } 
              title={"CO2 emission avoided (tons)"} 
              multiplier={0.25} 
              color={"#FCC30B"}
            />             
          : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div>
         }
        </div>  
        <div id="impactGraphWrapper">
        { !this.state.error
            ? data
              ? <AllPopulation 
                  data={ dataset } 
                  label={ label } 
                  title={"Total population impacted"} 
                  multiplier={1}               
                  color={"#FCC30B"}
                />             
              : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div>
            : <div id="impactError"> 
                <div>Data unavailable</div>
                <div id="impactErrorLogo"><i className="fas fa-exclamation-circle"></i></div>
                <div id="impactErrorInfo">To report on SDG 7, add questions with code "Number_of_children_under_18", "Adults_over_55" and "Adults_between_18_55" in your questionnaire</div>
            </div> }
        </div>  
      </div>      
    );
  }
}

export default SDG7