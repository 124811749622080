import React, { Component } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap/lib';
import './dealAction.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class EditSchedule extends Component {

  constructor(props) {
    super(props);
    this.state= {
      deal: {
        pricingSchedule:{}
      },       
      status: undefined,
      ready: false
    }
  }; 

  componentDidMount() {
    let deal = Object.assign( this.state.deal, this.props.deal )
    deal.dealVersion = this.props.deal.dealVersion? this.props.deal.dealVersion + 1 : 2
    this.setState({
      status: undefined,
      deal
    })
  }
  
  edit(){
    axios
      .put(API_URL + '/deals/amend/'+ this.props.deal.supplier + '/' + this.props.deal._id, {
        editedDeal: this.state.deal,
        newVersion: true
      })
      .then( ret => this.setState({ status: ret.data.message }) )
      .catch( err => this.setState({ status: 'Error' }) )
  }

  over(){
    this.setState({ status: undefined })
    this.props.onHide()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'} className='long'>
          { this.state.status }
        </div>
      );
    }
  }   

  render() {    
    let { deal, user } = this.props;
    
    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Edit deal schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
           
            <div id="dealEditL">Total cost</div>   
            <div id="dealEditShort">
              <FormControl 
                type="text" 
                className="dealEditInput"
                value={ this.state.deal.totalCost
                  ? user.options.ccys[0] + ' ' + this.state.deal.totalCost
                  :  user.options.ccys[0] + ' ' + 0 
                }
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.totalCost = e.target.value.replace( /^\D+/g, ''); 
                  this.setState({ deal }) 
                }}
              />
            </div>
            <div id="dealEditLleft">Upfront</div>   
            <div id="dealEditShort">
              <FormControl 
                type="text" 
                className="dealEditInput"
                value={ this.state.deal.pricingSchedule.upfrontPayment
                  ? user.options.ccys[0] + ' ' + this.state.deal.pricingSchedule.upfrontPayment
                  :  user.options.ccys[0] + ' ' + 0 
                }
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.pricingSchedule.upfrontPayment = e.target.value.replace( /^\D+/g, ''); 
                  this.setState({ deal }) 
                }}
              />
            </div>
            <div id="dealEditL">Recurring</div>   
            <div id="dealEditShort">
              <FormControl 
                type="text" 
                className="dealEditInput"
                value={ this.state.deal.pricingSchedule.recurrentPayment
                  ? user.options.ccys[0] + ' ' + this.state.deal.pricingSchedule.recurrentPayment
                  :  user.options.ccys[0] + ' ' + 0 
                }
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.pricingSchedule.recurrentPayment = e.target.value.replace( /^\D+/g, ''); 
                  this.setState({ deal }) 
                }}
              />
            </div>
            <div id="dealEditLleft">Minimum</div>   
            <div id="dealEditShort">
              <FormControl 
                type="text" 
                className="dealEditInput"
                value={ this.state.deal.pricingSchedule.minPayment
                  ? user.options.ccys[0] + ' ' + this.state.deal.pricingSchedule.minPayment
                  :  user.options.ccys[0] + ' ' + 0 
                }
                onChange={(e)=>{ 
                  let deal = Object.assign({}, this.state.deal); 
                  deal.pricingSchedule.minPayment = e.target.value.replace( /^\D+/g, ''); 
                  this.setState({ deal }) 
                }}
              />
            </div>

            <div id="productWarning">Proceeding with this action will create a new version of the deal.</div>
            <div id="productWarningLower">Active contracts will not be affected. You will have an option to roll out this modification to PENDING contracts.</div>

            { this.renderMessage() }            
            
            <div id="modal_action">
              
              { this.state.status !== 'Success' &&
                <Button id="action_signup" onClick={this.edit.bind(this)}>Edit</Button> }

              <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status == 'Success'?'Close':'Abort'}</Button>
            </div>

          </div>
        
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditSchedule