import React, { Component } from 'react';
import AnalyticsMenu from './../AnalyticsMenu';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import AssetListMap from './AssetListMap';
import AssetListMapEnabled from './AssetListMapEnabled';
import AssetListMapLocked from './AssetListMapLocked';


class MapsController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      assets: undefined,
      GPScenter: undefined,
      nav: 1,
      filteredAssets: undefined,
    };
  }

  componentDidMount() {
    this.getAssets()
  }

  getAssets() {
    let query = Object.assign( { status: 'DEPLOYED' }, this.props.filter )
    axios.post(API_URL + '/assets/location/' + this.props.user.supplier, query)
			.then((ret) => this.setState({ 
        assets: ret.data.assets,
        GPScenter: ret.data.GPScenter,
        filteredAssets: ret.data.assets
      }) )
  } 	

  select(nav){
    let filteredAssets = undefined
    if( nav === 1 && this.state.assets ) filteredAssets = this.state.assets
    if( nav === 2 && this.state.assets ) filteredAssets = this.state.assets.filter( elem => elem.contract && elem.contract.status == 'ENABLED')
    if( nav === 3 && this.state.assets ) filteredAssets = this.state.assets.filter( elem => elem.contract && elem.contract.status == 'LOCKED')
    this.setState({ nav, filteredAssets })

  }

  render() {    
    let navigate = undefined
    if( !this.state.assets ) navigate = <div id="assetMapPending">Loading all units... <br/><i className="fas fa-spinner"></i></div>
    else if( this.state.nav === 1 ) navigate = <div id="assetMapList"><AssetListMap assets={ this.state.filteredAssets } GPScenter={ this.state.GPScenter }/></div>
    else if( this.state.nav === 2 ) navigate = <div id="assetMapList"><AssetListMapEnabled assets={ this.state.filteredAssets } GPScenter={ this.state.GPScenter } status={'ENABLED'}/></div>
    else if( this.state.nav === 3 ) navigate = <div id="assetMapList"><AssetListMapLocked assets={ this.state.filteredAssets } GPScenter={ this.state.GPScenter } status={'ENABLED'}/></div>


    return (
      <div className='background'>   
        <div id="pageTitle">Units geo-location</div>
        <AnalyticsMenu user={this.props.user}/>
        <div id="clientBackground">
          <div id="dash_menu">  
            <div id="dash_menu_item" onClick={()=>this.select(1)} className={this.state.nav===1?"active_dash_menu leftMenu":"leftMenu"}>All</div>
            <div id="dash_menu_item" onClick={()=>this.select(2)} className={this.state.nav===2?"active_dash_menu":""}>Enabled</div>
            <div id="dash_menu_item" onClick={()=>this.select(3)} className={this.state.nav===3?"active_dash_menu rightMenu":"rightMenu"}>Locked</div>
          </div>
          <div id="fullMap">
            { navigate  }
          </div>
        </div>
      </div>
    );
  }
}

export default MapsController
