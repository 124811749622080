import React, { Component } from 'react';
import { chooseMonths } from '../../../../Actions/index';
import { connect } from 'react-redux';
var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

class Month extends Component {

  constructor(props) {
    super(props);
    this.state= {
      highRateMonths: [],
      mediumRateMonths: [],
      lowRateMonths: [],
      submitted: false
    }
  }; 

  selectHighMonth(month){
    if(this.state.highRateMonths.indexOf(month) < 0){
      let filteredMedium = this.state.mediumRateMonths.filter(item => item !== month)
      let filteredLow = this.state.lowRateMonths.filter(item => item !== month)
      return this.setState({
        highRateMonths: [...this.state.highRateMonths, month], 
        lowRateMonths: filteredLow,
        mediumRateMonths: filteredMedium
      })
    }
    if(this.state.highRateMonths.indexOf(month) >= 0){
      let filteredArray = this.state.highRateMonths.filter(item => item !== month)
      return (
        this.setState({highRateMonths: filteredArray})
      )
    }
  }


  selectMediumMonth(month){
    if(this.state.mediumRateMonths.indexOf(month) < 0){
      let filteredHigh = this.state.highRateMonths.filter(item => item !== month)
      let filteredLow = this.state.lowRateMonths.filter(item => item !== month)
      return this.setState({
        mediumRateMonths: [...this.state.mediumRateMonths, month], 
        lowRateMonths: filteredLow,
        highRateMonths: filteredHigh
      })
    }
    if(this.state.mediumRateMonths.indexOf(month) >= 0){
      let filteredArray = this.state.mediumRateMonths.filter(item => item !== month)
      return (
        this.setState({mediumRateMonths: filteredArray})
      )
    }  
  }


  selectLowMonth(month){
    if(this.state.lowRateMonths.indexOf(month) < 0){
      let filteredMedium = this.state.mediumRateMonths.filter(item => item !== month)
      let filteredHigh = this.state.highRateMonths.filter(item => item !== month)
      return this.setState({
        lowRateMonths: [...this.state.lowRateMonths, month], 
        highRateMonths: filteredHigh,
        mediumRateMonths: filteredMedium
      })
    }
    if(this.state.lowRateMonths.indexOf(month) >= 0){
      let filteredArray = this.state.lowRateMonths.filter(item => item !== month)
      return (
        this.setState({lowRateMonths: filteredArray})
      )
    }
  }
  
  handleSubmit(){
    this.props.chooseMonths(this.state)
    this.setState({submitted: true})
  }
  renderMessage(){
    if (this.state.submitted) {
      return (
        <div id="monthReponse">
          Schedule submitted
        </div>
      );
    }    
  }

  render() {    
    let mappedHighMonths = months.map((month, index) =>{ 
      return (
        <div 
          key={index}
          id="month" 
          onClick={()=>this.selectHighMonth(months[index])}
          className={this.state.highRateMonths.indexOf(months[index]) >= 0?'selectedMonth':null}
        >
          {months[index]}
        </div>          
      )
    })
    let mappedMediumMonths = months.map((month, index) =>{ 
      return (
        <div 
          key={index}
          id="month" 
          onClick={()=>this.selectMediumMonth(months[index])}
          className={this.state.mediumRateMonths.indexOf(months[index]) >= 0?'selectedMonth':null}
        >
          {months[index]}
        </div>          
      )
    })    
    let mappedLowMonths = months.map((month, index) =>{ 
      return (
        <div 
          key={index}
          id="month" 
          onClick={()=>this.selectLowMonth(months[index])}
          className={this.state.lowRateMonths.indexOf(months[index]) >= 0?'selectedMonth':null}
        >
          {months[index]}
        </div>          
      )
    })
    return (
      <div id="monthWrapper">
        <div id="monthSerie">{mappedHighMonths}</div>
        <div id="monthSerie">{mappedMediumMonths}</div>
        <div id="monthSerie">{mappedLowMonths}</div>
        <div id="monthAction" onClick={()=>this.handleSubmit()}>{this.state.submitted?'Resubmit':'Validate schedule'}</div>
        {this.renderMessage()}
      </div>
    );
  }
}

const MonthChooser = connect(
  function mapStateToProps(state) {
    return { 
      selectedMonths:state.selectedMonths
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      chooseMonths: months => dispatch(chooseMonths(months))
    };
  },
)(Month);

export default MonthChooser