import React, { Component } from 'react';
import history from '../../../../history';
import './groupPricing.css'

class Item extends Component {

  constructor(props) {
		super(props)
		this.state = {
      show: false,
      transfer: false,
      reverseDownGroup: false,
      assign: false,
      cash: false,
      cancel: false
    }
  }
  
  handleNext(path){
    history.push(path)
  }

  render() {
    let groupPricing = this.props.groupPricing
    
    return (
      <div className="groupPricingItem">       
        <div id="groupPricingName">{ groupPricing.groupName?  groupPricing.groupName : '-' }</div>
        <div id="groupPricingSize">{ groupPricing.minSize? groupPricing.minSize : 0 }</div>
        <div id="groupPricingSize">{ groupPricing.maxSize? groupPricing.maxSize: '-' }</div>
        <div id="groupPricingSize">{ groupPricing.type == 'Solidary'? 'true': 'false' }</div>
        <div id="groupPricingSize">{ groupPricing.ccy } { groupPricing.target ? groupPricing.target : '-' }</div>
        <div id="groupPricingProduct">{ groupPricing.eligibleProduct.name }</div>
      </div>
    )
  }  
}

export default Item