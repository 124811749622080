import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { I18n } from 'react-i18next';

class DeletePayment extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false,
    };
  } 

  delete(){

    var query = {
      transactionId: this.props.transactionId,
    }

    axios.put(API_URL + '/payments/delete/' + this.props.supplierId, query)
			.then( ret => this.setState({ status: ret.data.message }) )
			.catch( err => {
        console.log(err)
      })
    }

  over(){
    this.props.onHide();
    this.setState({status: undefined, disabled: false});
  }
  
  renderMessage() {
    if (this.state.status ) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  render() {
    let disabled = true;
    if( !this.state.disabled && this.state.delete == 'Delete me'){
      disabled = false
    }
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Payment deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">
              <div id="reversalDetails" style={{marginTop: '-15px'}}>
                Delete payment { this.props.transactionId }
              </div>
            
              <div id="reversalDetails" style={{marginTop: '5px'}}>
                Warning. This is action cannot be undone. Please type "Delete me" to confirm deletion.
              </div>
              <FormControl 
                type="text" 
                className="contractEditInput"
                placeholder="Delete me"
                onChange={(e)=>{ this.setState({ delete: e.target.value }) }}
              />
            </div>
            { this.renderMessage() }            
            <div id="modal_action">

              { this.state.status !=='Success' &&
                <Button id="confirm_action" disabled={disabled} onClick={()=>this.delete()}>Delete</Button> }
              
              <Button id="action_cancel" onClick={() => this.over()}>
                { this.state.status=='Success' ?'Close' :'Abort' }
              </Button>
           
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default DeletePayment;