import React, { Component } from 'react';
import ContractAction from './ContractAction'
import history from './../../../../history';
import ManageMenu from './../../ManageMenu'


class ContractActionController extends Component {


  goBack() {
    window.history.back();
  }
  render() {    

    return ( 
      <div className='background'>   
        <div id="pageTitle">Create new contract</div>
        <ManageMenu user={this.props.user}/>
        <div id="clientActionLine">
          <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
        </div>
        <div id="clientBackground">
          <ContractAction 
            user={ this.props.user } 
            clientNumber={ this.props.match.params.clientNumber }
          />
        </div>
      </div>
    );
  }
}

export default ContractActionController