import React, { Component } from 'react';
import { AgentHeader } from './AgentHeader'
import { AgentsListItem } from './AgentsListItem'
import './agents.css';

class AgentsList extends Component {
  
  render() {    

    let { agents, hideEdit, supplierId, refresh } = this.props

    let mappedAgents = agents && agents.map((agent, index) =>{ 
      return (
        <AgentsListItem 
          agent={ agent } 
          key={ index }
          refresh={ refresh} 
          supplier={ this.props.supplier }
          agentRole={ this.props.agentRole }
          page={ this.props.page }
          selectable={this.props.selectable}
          supplierId={ supplierId }
          hideEdit={ hideEdit }
        />
      )
    })

    return (
      <div>
        { agents && agents.length? <AgentHeader hideEdit={ hideEdit }/>:null}
        { mappedAgents }
      </div>
    );
  }
}

export default AgentsList