import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL, SOCKET_URL } from '../../../Actions/index';
import { FormControl } from 'react-bootstrap/lib';
import { I18n } from 'react-i18next';

class MessageAgent extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      note: {
        text: undefined,
        title: this.props.note && this.props.note.title
          ? ('Re: ' + this.props.note.title) 
          : undefined,
        type: this.props.note
          ? this.props.note.type 
          : undefined,
        status: 'Sent',
        contract: this.props.note && this.props.note.contract
          ? this.props.note.contract._id
          : undefined,
        supplier: this.props.supplierId,
        writtenBy: this.props.user.profile.firstName.substring(0, 1) + ' ' + this.props.user.profile.lastName,
        author: this.props.user._id,
        agentTo: this.props.agent._id,
        replyTo: this.props.note 
          ? this.props.note._id 
          : undefined,
        partOfDiscussion: this.props.note && this.props.note.partOfDiscussion
          ? this.props.note.partOfDiscussion
          : ( this.props.note? this.props.note._id : undefined )
      },
      status:undefined,
    };
  } 
  
  send(){
    axios
      .put(API_URL + '/notes/sendnote/' + this.props.agent.supplier, this.state.note)
			.then((ret) => {
        this.setState({status: 'Success!'})
      })
      .catch(err => this.setState({status: err}))
  }

  over = () => {
    this.setState({
      note: {
        text: undefined,
        title: undefined,
        type: undefined,
        status: 'Sent',
        supplier: this.props.agent.supplier,
        replyTo: undefined,
        partOfDiscussion: undefined
      },
      status:undefined,
    })
    this.props.onHide()
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   
  render() {

    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">{ this.props.note? 'Reply to agent' : 'Send a note to agent' }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight">
              { this.props.note?
                <div className="agentEditInput">
                  Reply
                </div>
                :
                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="agentEditInput"
                  onChange={(e)=>{ let note = Object.assign({}, this.state.note); note.type = e.target.value; this.setState({ note }) }}
                > 
                  <option value="">Choose category</option>
                  <option value="Task">Task</option>
                  <option value="Info">Info</option>
                </FormControl>
              }
              { this.props.note ?
                <div className="agentEditInput">
                  Re: {this.props.note.title}
                </div>
                :
                <FormControl 
                  type="text" 
                  className="agentEditInput"
                  placeholder={'Title'}
                  onChange={(e)=>{ let note = Object.assign({}, this.state.note); note.title = e.target.value; this.setState({ note }) }}
                />
              }

              <textarea 
                type="text" 
                className="agentMessageInput"
                placeholder="Type message"
                onChange={(e)=>{ let note = Object.assign({}, this.state.note); note.text = e.target.value; this.setState({ note }) }}
              />   
            </div>
            {this.renderMessage()}            
            <br/>
            <div id="modal_action">
              {this.state.status?
                  null
                  :
                  <Button id="action_signup" onClick={this.send.bind(this)}>Confirm</Button>
              }
              <Button id="action_cancel" onClick={() => this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
            </div>
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default MessageAgent;