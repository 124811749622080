import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap/lib';
import './../agent.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { findAgents } from  '../../../../services/AgentService'

class AllocateAgents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      agentsToAdd: [],
      agentsToRemove: [],   
      isDisabled: false,
    }
  }; 

  componentDidMount() {
    this.getAgentsList();
    window.scrollTo(0, 0)
  }

	getAgentsList = async () => {
    try {
      let allAgents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ allAgents })
    } catch(err){ console.log(err) }
  }

  submit() {
    let supplier = this.props.user.supplier
    let action = {
      agentNumber: this.props.agentNumber,
      agentsToAdd: this.state.agentsToAdd,
      agentsToRemove: this.state.agentsToRemove
    }
    axios.put(API_URL + '/agents/allocate/'+ supplier, action)
      .then((ret) => this.setState({ status: 'Success'}) )
      .catch(err => {
        console.error(err);
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success'?'successfulAction':'failedAction'}>
          { this.state.status }
        </div>
      );
    }
  }  
  
  over(){
    this.props.onHide()
    this.setState({
      user: this.props.userNumber,
      agentsToAdd: [],
      agentsToRemove: [],       
      status: undefined,
      isDisabled: false
    })
  }

  handleRemoveList( agentNumber ){
    let list = this.state.agentsToRemove
    if( list.indexOf( agentNumber ) < 0 ) this.setState({ agentsToRemove: [...this.state.agentsToRemove, agentNumber] })
    else this.setState({ agentsToRemove: list.filter( elem => elem !== agentNumber ) })
  }

  handleAddList( agentNumber ){
    let list = this.state.agentsToAdd
    if( list.indexOf( agentNumber ) < 0 ) this.setState({ agentsToAdd: [...this.state.agentsToAdd, agentNumber] })
    else this.setState({ agentsToAdd: list.filter( elem => elem !== agentNumber ) })
  }

  render() {
    
    let agentsToAdd, agentsToRemove  = null;

    if( this.props.agents ){
    
      agentsToRemove = this.props.agents
        .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
        .map( agent =>{ 
          return ( 
            <div key={agent._id} className="managerList" onClick={ () => this.handleRemoveList( agent.agentNumber ) }>
              
              <div id="mListNumber">{ agent.agentNumber }</div>
              <div id="mListFirstName">{ agent.profile.firstName }</div>
              <div id="mListLastName">{ agent.profile.lastName }</div>
              <div id="mListRole">{ agent.role }</div>
              <div id="mListStatus">
                { this.state.agentsToRemove.indexOf( agent.agentNumber ) > -1
                  ? <i className="fas fa-check-square"></i>
                  : <i className="far fa-check-square"></i> }
              </div>
            
            </div> 
          )
        })    
    } else agentsToRemove = <div className="noManagedAgents">No agents allocated at this time</div>
        
    if( this.state.allAgents ){
      let ids = this.props.agents.map(e => e._id)
      agentsToAdd = this.state.allAgents
        .filter( elem => elem.role !== 'Disabled' && ids.indexOf(elem._id) < 0 )
        .sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))
        .map( (agent) =>{ 
        return ( 
          <div key={agent._id} className="managerList" onClick={ () => this.handleAddList( agent.agentNumber ) }>
            
            <div id="mListNumber">{ agent.agentNumber }</div>
            <div id="mListFirstName">{ agent.profile.firstName }</div>
            <div id="mListLastName">{ agent.profile.lastName }</div>
            <div id="mListRole">{ agent.role }</div>
            <div id="mListStatus">
              { this.state.agentsToAdd.indexOf( agent.agentNumber ) > -1
                ? <i className="fas fa-check-square"></i>
                : <i className="far fa-check-square"></i> }
            </div>
          
          </div> 
        )
      })
      
    }

    return (
        <Modal
          dialogClassName="confirmModal"
          {...this.props}
          bsSize="large"
          aria-labelledby="contained-modal-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Agents managed by agent { this.props.agentNumber }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal_highlight" className='shiftedUp'>

              <div id="manageActionTitle"> Remove agents from team</div>
              { agentsToRemove }

              <div id="manageActionTitle"> Add agents to team </div>
              { agentsToAdd }

            </div>

            { this.renderMessage() }            
            <br/>
            <div id="modal_action">
              { this.state.status == 'Success'
                  ? null
                  : <Button id="action_signup" className='small' onClick={this.submit.bind(this)}>Edit list</Button> }
              <Button id="action_cancel" className='small' onClick={()=>this.over()}>{this.state.status==='Success!'?'Close':'Abort'}</Button>
            </div>
          </Modal.Body>
        </Modal>
    );
  }
}

export default AllocateAgents