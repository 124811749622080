import React, { Component } from 'react';
import './../agent.css';
import { Revenues } from '../../Graphs/revenues'
import { Analysis } from '../../Graphs/analysis'
import { Shotsplit } from '../../Graphs/shot-split'
import Activity from '../../Graphs/activity'
import { FormControl } from 'react-bootstrap/lib';
import { setFilter } from '../../../../Actions/index';
import { connect } from 'react-redux'; 
import history from '../../../../history';
import { findDeals } from  '../../../../services/DealService'
import { findProducts } from  '../../../../services/ProductService'

var DatePicker = require("react-bootstrap-date-picker");
var addDays = require('date-fns/add_days');
var isAfter = require('date-fns/is_after')

class Element extends Component {
  constructor(props) {
		super(props)
		this.state = {
      filter: {
        agent: this.props.agent._id,
        endDate: (new Date()).toISOString(),
        startDate: (addDays(new Date(), -30)).toISOString(),
      },
    };
  }
  
  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.agent !== prevProps.agent ) {
      this.seeDetails();
    }
  }

  seeDetails(path){
    this.props.setFilter( this.state.filter )
    history.push(path)
  }
    
  getProducts = async () => {
    try {
      let products = await findProducts( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ products })
    } catch(err){ console.log(err) }
  }

  getDeals = async () => {
    try {
      let deals = await findDeals( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ deals })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getProducts();
    this.getDeals();
  }

  select(contract){
    let include = true
    if( this.state.filter.product && contract.product != this.state.filter.product ) include = false
    if( this.state.filter.deal && contract.deal._id != this.state.filter.deal ) include = false
    return include
  }

  render() {    

    let mappedProducts, mappedDeals = null
    let { agent, user } = this.props

    if(this.state.products){
      mappedProducts = this.state.products.map((product, index) =>{ 
        return (
          <option key={index} value={product._id} className={'agentOption'}>{product.name} by {product.manufacturer}</option>
        )
      })
    }

    if( this.state.deals ){
      mappedDeals = this.state.deals.map((deal, index) =>{ 
        return (
          <option key={index} value={deal._id} className={'agentOption'}>{deal.dealName}</option>
        )
      })
    }

    let revenues7d = 0
    let revenues30d = 0
    let revenuesP = 0
    let revenuesEver = 0
    let sales7d = 0
    let sales30d = 0
    let salesP = 0
    let salesEver = 0
    let teamContracts = this.props.teamContracts
    let agentContracts = this.props.agentContracts
    let agentPayments = []
    let teamPayments = []

    
    if( agentContracts && agentContracts.length ){
      agentContracts.filter( elem => this.select(elem) ).forEach( elem => {
        if( isAfter( new Date( elem.signingDate ), addDays(new Date(), -7)) ) sales7d += 1
        if( isAfter( new Date( elem.signingDate ), addDays(new Date(), -30)) ) sales30d += 1
        if( isAfter( new Date( elem.signingDate ), new Date(this.state.filter.startDate) ) && isAfter( new Date( this.state.filter.endDate ), new Date(elem.signingDate) )) salesP += 1
        if( elem.signingDate ) salesEver += 1
        if(elem.payments) elem.payments.forEach( p => agentPayments.push(p) )
      })
      agentPayments.forEach( elem => {
        if( isAfter( new Date( elem.date ), addDays(new Date(), -7)) ) revenues7d += elem.amount
        if( isAfter( new Date( elem.date ), addDays(new Date(), -30)) ) revenues30d += elem.amount
        if( isAfter( new Date( elem.date ), new Date(this.state.filter.startDate) ) && isAfter( new Date( this.state.filter.endDate ), new Date(elem.date) )) revenuesP += elem.amount
        revenuesEver += elem.amount
      })

    }

    if( teamContracts && teamContracts.length && this.state.filter.manager ){
      teamContracts.filter( elem => this.select(elem) ).forEach( elem => {
        if( isAfter( new Date( elem.signingDate ), addDays(new Date(), -7)) ) sales7d += 1
        if( isAfter( new Date( elem.signingDate ), addDays(new Date(), -30)) ) sales30d += 1
        if( isAfter( new Date( elem.signingDate ), new Date(this.state.filter.startDate) ) && isAfter( new Date( this.state.filter.endDate ), new Date(elem.signingDate) )) salesP += 1
        if( new Date( elem.signingDate ) ) salesEver += 1
        if(elem.payments) elem.payments.forEach( p => teamPayments.push(p) )
      })
      teamPayments.forEach( elem => {
        if( isAfter( new Date( elem.date ), addDays(new Date(), -7)) ) revenues7d += elem.amount
        if( isAfter( new Date( elem.date ), addDays(new Date(), -30)) ) revenues30d += elem.amount
        if( isAfter( new Date( elem.date ), new Date(this.state.filter.startDate) ) && isAfter( new Date( this.state.filter.endDate ), new Date(elem.date) )) revenuesP += elem.amount
        revenuesEver += elem.amount
      })
    }


    return (
      <div id="agentAnalysis">

        <div className='full'>
          <div id="agentAnalysisTitle">
            { agent.role !== 'Manager'
              ? "Dashboard"
              : !this.state.filter.manager? "Individual dashboard" : "Team dashboard" }
          </div>

          <div id="agentViewSelect">

            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="graphSelector agentSelector"
              onChange={(e)=>{ 
                let filter = Object.assign({}, this.state.filter); 
                filter.product = e.target.value; 
                this.setState({ filter }) 
              }}              >
              <option value="" className={'agentOption'}>All products</option>
              {mappedProducts}
            </FormControl>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="graphSelector agentSelector"
              onChange={(e)=>{ 
                let filter = Object.assign({}, this.state.filter); 
                filter.deal = e.target.value; 
                this.setState({ filter }) 
              }}                >
              <option value="" className={'agentOption'}>All deals</option>
              { mappedDeals }
            </FormControl>
            <div className="agentSelectorDate">
              <DatePicker 
                className ="graphSelector"
                value={this.state.filter.startDate}  
                onChange={(e)=>{ 
                  let filter = Object.assign({}, this.state.filter); 
                  filter.startDate = e; 
                  this.setState({ filter }) 
                }}  
                showClearButton= {false}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />
            </div>
            <div className="agentSelectorDate">
              <DatePicker 
                className ="graphSelector"
                value={this.state.filter.endDate}  
                onChange={(e)=>{ 
                  let filter = Object.assign({}, this.state.filter); 
                  filter.endDate = e; 
                  this.setState({ filter }) 
                }}                    
                showClearButton= {false}
                calendarPlacement ={'left'}
                dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
              />
            </div>

          </div>

          { agent.role == 'Manager'
            ? <div id="agentAnalysisLink" onClick={ () => {
                let filter = Object.assign({}, this.state.filter); 
                filter.manager = filter.manager? undefined: agent.agentNumber ; 
                filter.agent = filter.manager? undefined: agent._id ;
                this.setState({ filter }) 
              }}>
                { !this.state.filter.manager? <i className="fas fa-users"></i>:<i className="fas fa-user"></i> }
              </div> 
            : null }

          <div id="agentAnalysisLink" onClick={()=>this.seeDetails('/analytics')}>
            <i className="fas fa-chart-line"></i>
          </div> 

        </div>

        <div id="agentGraphSection">        
          <div id="agentGraphWrapper">
            <div id="agentGraphTitle">New signings</div>
            <Analysis 
              user={ user } 
              freq={ 'daily' }
              filter={ this.state.filter }
              color= {'rgba(255, 255, 255, 0.95)'}
              type={ 'DEPLOYMENT' }
              width={ 100 }
              height={ 75 }
            />
            <div id="agentGraphTitle" className="agentMarginTop" style={{paddinTop: "5px"}}>Revenues ({agent.ccy})</div>
            <Revenues 
              user={ user } 
              granularity={ 'daily' }
              option={ 'revenue' }
              filter={ this.state.filter }
              color= {'rgba(255, 255, 255, 0.95)'}
              width={ 100 }
              height={ 75 }
            />
          </div>
        </div>

        <div id="graphSection">        
          <div id="agentGraphWrapper">
            <div id="halfGraph">
              <div id="agentGraphTitle">Usage analysis</div>
              <Shotsplit 
                user={ user } 
                filter={ this.state.filter }
                color= {'rgba(255, 255, 255, 0.4)'}
                width={ 100 }
                height={ 75 }
              />
            </div>
            <div id="halfGraph">
              <div id="agentGraphTitle">Inactivity snapshot</div>
              <Activity 
                user={ user } 
                standalone={ true }
                filter={ this.state.filter }
                width={ 100 }
                height={ 75 }
              />
            </div>
            <div id="agentGraphTitle" className="agentMarginTop">Metrics</div>
            <div id="agentViewMetrics">  

              <div id='analysisColumn'>
                <div className="analysisHeader clear">{this.state.filter.manager? 'Team' : 'Individual'}</div>
                <div className='analysisHeader'>{ agent.ccy }</div>
                <div className='analysisHeader'>Signings</div>
              </div>
              
              <div id='analysisColumn'>
                <div className='analysisHeader'>Last 7d</div>
                <div id='analysisPoint'>{ revenues7d.toLocaleString() }</div>
                <div id='analysisPoint'>{ sales7d.toLocaleString() }</div>
              </div>
              
              <div id='analysisColumn'>
                <div className='analysisHeader'>Last 30d</div>
                <div id='analysisPoint'>{ revenues30d.toLocaleString() }</div>
                <div id='analysisPoint'>{ sales30d.toLocaleString() }</div>
              </div>
              
              <div id='analysisColumn'>
                <div className='analysisHeader'>Over period</div>
                <div id='analysisPoint'>{ revenuesP.toLocaleString() }</div>
                <div id='analysisPoint'> { salesP.toLocaleString() } </div>
              </div>
              
              <div id='analysisColumn'>
                <div className='analysisHeader'>Since joining</div>
                <div id='analysisPoint'>{ revenuesEver.toLocaleString() }</div>
                <div id='analysisPoint'>{ salesEver.toLocaleString() }</div>
              </div>
             
            </div>
          </div>
        </div>                
      </div>
    );
  }
}

const AgentAnalysis = connect(
  function mapStateToProps(state) {
    return { 
      filter: state.filter
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      setFilter: filter => dispatch(setFilter(filter)),
    };
  },
)(Element);

export default AgentAnalysis