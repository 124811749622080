import React, { Component } from 'react';
import history from './../../../../history';
import './../List/contracts.css';


export class ContractsListItem extends Component {
  
  handleNext(path){
    history.push(path)
  }

  render() {
    var contract = this.props.contract
    let status = contract.status
    status = status.toLowerCase();
    status = status.charAt(0).toUpperCase() + status.slice(1);
    return (
      <div className="contractItem">
        <div id="contractsSmallLight"  className="active" onClick={()=>this.handleNext('/contract/id='+this.props.contract.contractNumber)}>
          {contract.contractNumber}
        </div>  
        <div id="contractsMedLight" >
          {contract.dealName? contract.dealName : '-'}
        </div>     
        <div id="contractsSmallLight" >
        {contract.ccy} {contract.totalPaid.toLocaleString()}
        </div>  
        <div id="contractsExtraSmallLight" >
          {Math.round(100 * contract.totalPaid/contract.totalCost)}%
        </div>    
        { contract.client && contract.client.profile
          ? <div id="contractsSmallLight"  className="active" onClick={()=>this.handleNext('/client/id='+this.props.contract.client.clientNumber)}>
              {contract.client.profile.lastName}
            </div>  
          : <div>No client</div> }      
        <div id="contractsSmallLight">
          {contract.onboardingStatus==='Signed'? status : contract.onboardingStatus}
        </div>  
      </div>
    )
  }  
}