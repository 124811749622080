import React, { Component } from 'react';
import axios from 'axios';
import {Button, Modal} from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { connect } from 'react-redux';

class Approve extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status:undefined,
      note: undefined,
      disabled: false
    };
  } 
 
  componentDidUpdate(prevProps) {
    if (this.props.selectedGroups !== prevProps.selectedGroups) {
      this.setState({
        status: undefined, 
        disabled: false,
        note: undefined,
      });
    }
  }
  

  componentDidMount() {
    this.setState({
      status: undefined, 
      disabled: false,
      note: undefined,
    });  
  }

  handleChange(){
    var body = {
      groups: this.props.selectedGroups,
      note: this.state.note,
      action: this.props.action,
      user: this.props.user
    }
    this.setState({ disabled: true, status: 'One second...' })

    axios.put(API_URL + '/groups/credit/' + this.props.user.supplier, body)
			.then((ret) => {
        this.setState({ status: ret.data.message })
      })
  }

  over(){
    this.props.onHide();
    this.setState({
      status: undefined, 
      disabled: false,
      note: undefined,
    });
  }

  renderMessage() {
    if (this.state.status && this.state.status !== 'One second...' ) {
      return (
        <div id={this.state.status == 'Success' ?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }   

  render() {
    let disabled = true
    
    if( !this.state.disabled && 
      this.state.rejectClients !== undefined || this.props.action == 'Approve'
    ) disabled = false

    return (
      
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">{this.props.action} {this.props.selectedGroups.length} groups</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        { this.props.action == 'Reject'
          ? <textarea
              type="text"
              className="refusalNote"
              placeholder="Add note for agents"
              onChange={(e)=> this.setState({ note: e.target.value }) }
            />
          : null }

        {this.renderMessage()}            
        
        <br/>
        <div id="modal_action">
          { this.state.status == 'Success'
            ? null
            : <Button id="confirm_action" disabled={ disabled } onClick={()=>this.handleChange()}>{this.props.action}</Button>
          }
          <Button id="action_cancel" onClick={() => this.over()}>
            {this.state.status == 'Success'?'Close':'Abort'}
          </Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}


const ApproveGroup = connect(
  function mapStateToProps(state) {
    return { 
      selectedGroups: state.selectedGroups
    };
  }
)(Approve);

export default ApproveGroup;