import React, { Component } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap/lib';
import './../agentTarget.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { findDeals } from  '../../../../services/DealService'

class AgentTargetsAction extends Component {

  constructor(props) {
    super(props);
    this.state= {
      agentTarget: {
        dateFrom: new Date(),
        agent: this.props.agent._id,
        ccy: this.props.agent.ccy,
        createdBy: this.props.user._id,
      },       
      isDisabled: false,
    }
  }; 

  componentDidMount() {
    this.loadData()
  }

  submit() {
    let supplier = this.props.supplier
    axios.post(API_URL + '/agents/createTarget/'+ supplier, this.state.agentTarget)
      .then((ret) => this.setState({ status: 'Success'}) )
      .catch(err => this.setState({status: err.message}) ); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status==='Success'?'successfulAction':'failedAction'}>
          {this.state.status}
        </div>
      );
    }
  }  

  getDeals = async () => {
    try {
      let deals = await findDeals( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ deals })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getDeals();
  }

  
  over(){
    this.props.onHide()
    this.setState({
      agentTarget: {
        dateFrom: new Date(),
        assignedBy: this.props.user.userNumber,
        category: undefined,
        freq: undefined,
        otherType: undefined,
        target: undefined,
        remunerated: undefined,
        agent: this.props.agent._id,
        floor: undefined,
        cap: undefined,
        share: undefined,
        base: undefined,
        digital: undefined,
      },       
      status: undefined,
      showIllustration: false,
      isDisabled: false,
    })
  }

  selectReference = (ref) => {
    let agentTarget = Object.assign({}, this.state.agentTarget); 
    agentTarget.category = ref; 
    agentTarget.digital = undefined;
    agentTarget.base = undefined;
    agentTarget.share = undefined
    this.setState({ agentTarget })
  }

  selectFreq = (ref) => {
    let agentTarget = Object.assign({}, this.state.agentTarget); 
    agentTarget.freq = ref; 
    this.setState({ agentTarget })
  }

  selectStake = (stake) => {
    let agentTarget = Object.assign({}, this.state.agentTarget); 
    agentTarget.remunerated = stake; 
    if( stake == false ){
      agentTarget.digital = undefined;
      agentTarget.base = undefined;
      agentTarget.share = undefined
    }
    this.setState({ agentTarget })
  }

  render() {
    let target = this.state.agentTarget
    let agent = this.props.agent
    let isDisabled = true
    if( target.category && target.freq && target.target && ( target.remunerated == false || ( target.remunerated && target.digital && target.category != 'Utilisation')) ) isDisabled = false
    if( target.category && target.freq && target.target && ( target.remunerated == false || ( target.remunerated && target.digital && target.category == 'Utilisation')) ) isDisabled = false
    if( this.state.disabled ) isDisabled = true

    let navigate = <Button id="action_signup" disabled={isDisabled} onClick={this.submit.bind(this)}>Create</Button>
    if(this.props.edit){
      navigate = <Button id="action_signup" onClick={this.submit.bind(this)}>Edit</Button>
    }

    let mappedDeals = null

    if( this.state.deals ){
      mappedDeals = this.state.deals.map((deal, index) =>{ 
        return (
          <option key={index} value={deal._id} className={'agentOption'}>{deal.dealName}</option>
        )
      })
    }

    return (
      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="medium"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Set new target for agent { agent.agentNumber }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="modal_highlight">


            <div className="agentTargetInputLabel top">Target reference</div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentTarget.category == 'Revenues'?'activeTOption': ''}
              onClick={()=>this.selectReference('Revenues')}
            >
              Revenues
            </div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentTarget.category == 'Sales'?'activeTOption': ''}
              onClick={()=>this.selectReference('Sales')}
            >
              New sales
            </div>
            { agent.role == 'Manager'
              ? <div 
                  id="agentTargetOption" 
                  className={ this.state.agentTarget.category == 'TeamSales'?'activeTOption': ''}
                  onClick={()=>this.selectReference('TeamSales')}
                >
                  Team sales
                </div>
              : <div id="agentTargetEmptyOption" >&nbsp;</div> }
            
            <div className="agentTargetInputLabel">Target frequency</div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentTarget.freq == 'week'?'activeTOption': ''}
              onClick={()=>this.selectFreq('week')}
            >
              Weekly
            </div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentTarget.freq == 'month'?'activeTOption last': 'last'}
              onClick={()=>this.selectFreq('month')}
            >
              Monthly
            </div>

            <div className='fullLine'>
              <div className="agentTargetInputLabel">Target</div>
              <FormControl 
                type="text" 
                className="agentTargetInputShort"     
                placeholder={'Set number'}
                onChange={(e)=>{ let agentTarget = Object.assign({}, this.state.agentTarget); agentTarget.target = e.target.value; this.setState({ agentTarget }) }}
              />
              
              { this.state.agentTarget.category == 'Revenues' && this.state.agentTarget.freq
                ? <div className="agentTargetUnit">revenues (in { agent.ccy }) per { this.state.agentTarget.freq }</div> 
                : null }
              { this.state.agentTarget.category == 'Sales' && this.state.agentTarget.freq
                ? <div className="agentTargetUnit">new sales per { this.state.agentTarget.freq }</div> 
                : null }
              { this.state.agentTarget.category == 'Utilisation' && this.state.agentTarget.freq
                ? <div className="agentTargetUnit">% average utilisation rate over the { this.state.agentTarget.freq }</div> 
                : null }
            </div>

            <div className='targetLine'>
              <div className="agentTargetInputLabel">Deal</div>
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="agentTargetInputLong"
                onChange={(e)=>{ 
                  let agentTarget = Object.assign({}, this.state.agentTarget); 
                  agentTarget.deal = e.target.value; 
                  this.setState({ agentTarget }) 
                }}                >
                <option value="" className={'agentOption'}>Any deals</option>
                { mappedDeals }
              </FormControl>
            </div>

            <div className="agentTargetInputLabel">Stake</div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentTarget.remunerated == true?'activeTOption': ''}
              onClick={()=>this.selectStake(true)}
            >
              Remunerated
            </div>
            <div 
              id="agentTargetOption" 
              className={ this.state.agentTarget.remunerated == false ?'activeTOption': ''}
              onClick={()=>this.selectStake(false)}
            >
              Soft target
            </div>

            { this.state.agentTarget.remunerated == true && this.state.agentTarget.category !== 'Utilisation'
              ? <div>
                  <div className="agentTargetInputTitle">Remuneration details</div>

                  <div className='fullLine'>
                    <div className="agentTargetInputLabel">Digital</div>
                    <FormControl 
                      type="text" 
                      className="agentTargetInputShort"     
                      placeholder={'Type'}
                      onChange={(e)=>{ 
                        let agentTarget = Object.assign({}, this.state.agentTarget); 
                        agentTarget.digital = e.target.value; 
                        this.setState({ agentTarget }) 
                      }}
                      />
                    { this.state.agentTarget.category == 'Revenues'
                      ? <div className="agentTargetUnit">{agent.ccy} if target achieved</div> 
                      : null }
                    { ['Sales', 'TeamSales'].indexOf(this.state.agentTarget.category) > -1
                      ? <div className="agentTargetUnit">{agent.ccy} if target achieved</div> 
                      : null }
                  </div>

                  { ['Sales', 'TeamSales'].indexOf(this.state.agentTarget.category) > -1
                    ? <div className='fullLine'>
                        <div className="agentTargetInputLabel">Share</div>
                        <FormControl 
                          type="text" 
                          className="agentTargetInputShort"     
                          placeholder={'Type'}
                          onChange={(e)=>{ 
                            let agentTarget = Object.assign({}, this.state.agentTarget); 
                            agentTarget.share = e.target.value; 
                            this.setState({ agentTarget }) 
                          }}
                          />
                      <div className="agentTargetUnit">{agent.ccy} per new sale</div> 
                    </div>
                    : null }

                </div>
              : null }


          </div>


          { this.renderMessage() }            
          <br/>
          <div id="modal_action">
            { this.state.status==='Success'
              ? null
              : <div>{navigate}</div> }
            <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AgentTargetsAction