import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap/lib';
import PicturePopUp from '../../Images/PicturePopUp'
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import './../credit.css';
import './../../Questionnaires/Questions/questions.css';

import * as firebase from 'firebase';

class CreditScoreItem extends Component {
  constructor(props) {
		super(props)
		this.state = {
      edit: false,
      showPic: false,
      creditItem: {
        question: this.props.item.question? this.props.item.question._id : null,
      },
    };
  }  

  close() {
    this.setState({ showPic: false })
  }

  getURL = async (fbRef) => {
    var storage = firebase.storage();
    var storageRef = storage.ref();
    let item = this.props.item
    let question = item.question

    try { 

      if( question.isSignature && item.answer ) {
        var image = new Image();
        image.src = "data:image/jpg;base64," +item.answer;
        var w = window.open("");
        w.document.write(image.outerHTML);
        return;
      }

      URL = await storageRef.child(fbRef).getDownloadURL()
    } catch(err){ console.log(err); }

    if( URL ){
      
      var win = window.open(URL, '_blank');
      win.focus();

    } else return;

  }

  editAnswer(){
    axios.put(API_URL + '/creditscores/editItem/' + this.props.supplier + '/'+ this.props.item._id, this.state.creditItem)
			.then((ret) => {
        this.setState({ status: 'Success!', edit: false })
        this.props.refresh()
        this.setState({
          creditItem: {
            question: this.props.item.question._id,
            answer: undefined
          },
        })
      })
    
  }


  render() {    
    let item = this.props.item;
    let question = item.question

    let translatedAnswer = undefined;
    if( question.options ){
      let pair = question.options.find( elem => elem.answer == item.answer )
      translatedAnswer = pair? pair.translatedAnswer : undefined
    }

    let navigate = null;
    let mappedOptions = null;

    if( question && question.selectable && question.possibleAnswers ){
      mappedOptions = question.possibleAnswers.map((answer, index) =>{
        return <option key={index} value={answer}>{answer}</option>
      })
    }

    if( question && question.isNumeric ){
      navigate =           
      <FormControl 
        type="number" 
        className="modalInput"
        value = { this.state.creditItem.answer !== undefined
          ? this.state.creditItem.answer
          : this.props.item.answer
        }
        onChange={(e)=>{ 
          let creditItem = Object.assign({}, this.state.creditItem); 
          creditItem.answer = e.target.value; 
          this.setState({ creditItem });
          }}
      />
    }
    if( question && question.selectable && question.possibleAnswers){
      navigate =           
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="modalInput"
          onChange={(e)=>{ 
            let creditItem = Object.assign({}, this.state.creditItem); 
            creditItem.answer = e.target.value; 
            this.setState({ creditItem });
            }}        >
          <option value="">
            { item.answer !== undefined
              ? item.answer
              : 'Choose'
            }        
          </option>
          {mappedOptions}
        </FormControl>
    }
    else {
      navigate =           
        <FormControl 
          type="text" 
          className="modalInput"
          value = { this.state.creditItem.answer !== undefined
            ? this.state.creditItem.answer
            : this.props.item.answer
          }        
          onChange={(e)=>{ 
            let creditItem = Object.assign({}, this.state.creditItem); 
            creditItem.answer = e.target.value; 
            this.setState({ creditItem });
            }}
        />
    }

    return (
      <div>

        { ! this.state.edit  &&
          <div id="answerWrapper">
            <div id="answerCategory">{ this.props.rank }. { question && question.category? question.category: '-' }</div>
            <div id="answerQuestion">{ question
              ? question.translatedQuestion? question.translatedQuestion : question.question
              : '-' }
            </div>
            <div id="answerAnswer" className="answerAnswer">
              { question.isPicture || question.isSignature
                  ? <div id="answerPicture" onClick={() => this.getURL(item.fbRef)}>{ question.isSignature? 'View signature':'View picture'}</div>
                  : translatedAnswer? translatedAnswer: item.answer }
            </div>
            <div id="aD"  onClick={()=>this.setState({ edit: !this.state.edit})}> <i className="far fa-edit"></i> </div>
           </div> } 

        { this.state.edit &&
          <div id="answerWrapper">
            <div id="answerCategory">{ question && question.category? question.category: '-' }</div>
            <div id="answerQuestion">{ question? question.question: '-'}</div>
            <div id="answerAnswer">{navigate}</div>
            <div id="aC"  onClick={()=>this.editAnswer()}> Publish </div>
            <div id="aC"  onClick={()=>this.setState({ edit: !this.state.edit})}> Close </div>
          </div> }

        { this.state.showPic &&
          <PicturePopUp 
            show={this.state.showPic} 
            onHide={this.close.bind(this)}
            url={ item.fbRef }
          /> }

      </div>

    );
  }
}

export default CreditScoreItem