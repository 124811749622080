import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import RatesList from './RateList/RatesList';
import RatesAddOne from './Action/RatesAddOne'
import AdminMenu from '../AdminMenu'
import './rate.css'

class RatesController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      rates: undefined,
      create: false   
    };
  }

  componentDidMount() {
    this.loadData()
  }

	getRates = () => {
    axios.get(API_URL + '/rates/' + this.props.user.supplier )
			.then((ret) => {
        this.setState({ rates: ret.data })
      })
  }
  
  loadData() {
    this.getRates();
  }

  close(){
    this.setState({ create: false });
    this.getRates()
  }

  render() {    
    
    let user = this.props.user;
    let options = user.options

    return (
			<div className='background'>

        <div id="pageTitle">Rates</div>
        { ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1
          ? <div id="adminAction" onClick={()=>this.setState({ create: !this.state.create })}>
              { ! this.state.create
                ? <i className="fas fa-plus"></i>
                : <i className="fas fa-minus"></i> }
            </div>
          : null }

				<AdminMenu user={ user }/>
				
        <div id="clientBackground">
          
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" className="active_clientSubMenu">All rates</div>
          </div>
          
          { this.state.rates && this.state.rates.length > 0
            ? <div id="assetsList">
                <RatesList rates={ this.state.rates }/>
              </div>
            : null } 

        { this.state.create && ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( user.role ) > -1
          ? <RatesAddOne 
              user={ user }
              show={ this.state.create } 
              options={ options }
              onHide={ this.close.bind(this) }
            />
          : null }
  
          </div>
			</div>
    );
  }
}

export default RatesController