import React from 'react';
import axios from 'axios';
import { Button, Modal, FormControl } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { findAgents } from  '../../../../services/AgentService'

class AddCash extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      disabled: false,
      hide: false,
      payment: {
        transactionId: 'CASH-' + Date.now().toString().slice(2, 12),
        recordedBy: this.props.user.userNumber,
        date: new Date(),
        sendText: true
      },
      sendText: true,
    };
  } 
  
  componentDidMount() {
    this.loadData()
  }

  submit( payment ){
    this.setState({ hide: true, disabled: true })
    let body = {
      payments: [ payment ]
    }
    axios.post(API_URL + '/agents/pay/' + this.props.user.supplier + '/' + payment.agent, body )
			.then( ret => {
        if( ret.data.successes && ret.data.successes[0] ) this.setState({ status: ret.data.successes[0] }) 
        else if( ret.data.errors && ret.data.errors[0] ) this.setState({ status: ret.data.errors[0] }) 
        else if( ret.data.message ) this.setState({ status:  ret.data.message }) 
        else this.setState({ status: 'Internal error, try again.' })
      })
			.catch( err => this.setState({ status: err.message }) )
    }

  over(){
    this.props.onHide();
    this.setState({
      status: undefined,
      disabled: false,
      hide: false,
      payment: {
        reference: undefined,
        ccy: undefined,
        date: new Date(),
        agent: undefined,
        sendText: true
      },
      sendText: true
    });
  }
  
  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getAgents();
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          { this.state.status }
        </div>
      );
    }
  }   

  render() {
    let disabled = true;
    let mappedAgents, ccyOptions = null
    let user = this.props.user

    if( user.options ){
      ccyOptions = user.options.ccys.map( (ccy, index) =>{ 
        return ( <option key={index} value={ccy}>{ccy}</option> )
      })
    }

    if( !this.state.disabled && this.state.payment.reference && this.state.payment.amount && this.state.payment.ccy && this.state.payment.agent ){
      disabled = false
    }
    
    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })
    }

    return (

      <Modal
        dialogClassName="confirmModal"
        {...this.props}
        bsSize="small"
        aria-labelledby="contained-modal-title-sm"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm">Record cash payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="modal_highlight">
          
          <div id="fullPayment">
            <div className="paymentInputLabel">Reference</div>
            <FormControl 
              type="text" 
              className="paymentInput"
              placeholder="Reference"
              onChange={(e)=>{ 
                let payment = Object.assign({}, this.state.payment); 
                payment.reference = e.target.value; 
                this.setState({ payment });
              }}          
            />
          </div>

          <div id="fullPayment">
            <div className="paymentInputLabel">Currency</div>
            <FormControl 
              className="paymentInput"
              controlId="formControlsSelect"
              componentClass="select"               
              onChange={(e)=>{ 
                let payment = Object.assign({}, this.state.payment); 
                payment.ccy = e.target.value; 
                this.setState({ payment }) 
              }}
            > 
              <option value="">Choose currency</option>
              {ccyOptions}
            </FormControl>
          </div>

          <div id="fullPayment">
            <div className="paymentInputLabel">Amount</div>
            <FormControl 
              type="text" 
              className="paymentInput"
              placeholder="Amount"
              onChange={(e)=>{ 
                let payment = Object.assign({}, this.state.payment); 
                payment.amount = e.target.value; 
                this.setState({ payment });
              }}          
            />
          </div>
          
          <div id="fullPayment">
            <div className="paymentInputLabel">On behalf of</div>
            <FormControl 
              controlId="formControlsSelect"
              componentClass="select" 
              className="paymentInput"
              onChange={(e)=>{ 
                let payment = Object.assign({}, this.state.payment); 
                payment.agent = e.target.value; 
                this.setState({ payment });
                }}
              >
              <option value="">Agent</option>
              { mappedAgents }
            </FormControl>
          </div>

          <div className="paymentInputLabel">Recorded by</div>
          <div className="paymentPreInput">{ user.profile.firstName } { user.profile.lastName }</div>

          <div id="fullPayment">
            <div className="paymentInputLabel">Text client</div>
            <div
              className="paymentInput half"
              id={ this.state.payment.sendText == true? 'activePaymentOption': '' }
              onClick={(e)=>{ 
                let payment = Object.assign({}, this.state.payment); 
                payment.sendText = true; 
                this.setState({ payment: payment, sendText: true });
                }}
              >
              Yes
            </div>
            <div
              className="paymentInput half marginleft"
              id={ this.state.payment.sendText == false? 'activePaymentOption': '' }
              onClick={(e)=>{ 
                let payment = Object.assign({}, this.state.payment); 
                payment.sendText = false; 
                this.setState({ payment: payment, sendText: false });
                }}
              >
              No
            </div>
          </div>

          <div id="fullPayment">
            <div className="paymentInputLabel">Note (optional)</div>
            <textarea 
              type="text" 
              className="paymentInput high"
              placeholder="Internal note"
              onChange={(e)=>{ 
                let payment = Object.assign({}, this.state.payment); 
                payment.note = e.target.value; 
                this.setState({ payment });
              }}          
            />
          </div>

        </div>
        
        { this.renderMessage() }            
        <br/>
        <div id="modal_action">
          { this.state.status
            ? null
            : <Button id="confirm_action" disabled={disabled} onClick={()=>this.submit( this.state.payment )}> Record </Button>
          }
          <Button id="action_cancel" onClick={() => this.over()}>
            { this.state.status ?'Close':'Abort' }
          </Button>
        </div>
      </Modal.Body>
      </Modal>

    );
  }
}

export default AddCash;