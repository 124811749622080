import React, { Component } from 'react';
import './payrollcontracts.css';
import history from '../../../../../history';
var differenceInSeconds = require("date-fns/difference_in_seconds");

export class TargetItem extends Component {
  
  handleNext(path){
    history.push(path)
  } 

  render() {    
   
    let target = this.props.target
    
    return (
      <div className="payrollContractItem">       
        <div className="payrollTarget">{ target.target }</div>
        <div className="payrollTargetCommission">{ this.props.payroll.ccy } { target.commission }</div>      
      </div>
    );
  }
}