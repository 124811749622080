import React, { Component } from 'react';
import { ActivationsListItem } from './ActivationsListItem'
import { ActivationHeader } from './ActivationHeader'
import './activations.css';

class ActivationsList extends Component {

  render() {    
    let mappedActivations = null;
    let activations = this.props.activations
    if( activations && activations.length>0){
      mappedActivations = activations && activations.sort(function(a, b) {
        return new Date(b.issueDate) - new Date(a.issueDate)
      }).map( activation =>{ 
        return (
          <ActivationsListItem activation={activation} key={activation._id}/>
        )
      })
    }

    return (
      <div id="activationsList">
        <ActivationHeader/>
        {mappedActivations}
      </div>
    );
  }
}

export default ActivationsList