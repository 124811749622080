import React from 'react';
import {Bar} from 'react-chartjs-2';
import axios from 'axios';
import { API_URL } from '../../../Actions/index';
import './graph.css'

var differenceInCalendarMonths = require("date-fns/difference_in_months");

class Triptic extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  };

  componentDidMount() {
    this.getGraph()
  }

  getGraph = () => {
    let body = {
      userId: this.props.user._id
    }
    axios
      .post(API_URL +'/contracts/growth/' + this.props.user.supplier, body)
      .then((res) => this.setState({  data: res.data  }) )
  }

  render() { 
    
    let data = this.state.data

    let signings = []
    let cumulativeSignings = []
    let monthEntries = []
    let notSigned = []
    let monthSignings = []
    let avg = 0
    let ratios = []

    let label = []
    let graphLabel = []

    if( data && data.length ){

      let allEntryDates = data.map( elem => elem._id.entryDate )
      let allSigningDates = data.map( elem => elem._id.signingDate )
      let allDates = [ ...allEntryDates, ...allSigningDates ]
      
      label = [ ...new Set(allDates) ]  
      graphLabel = label
        .filter(elem => elem && differenceInCalendarMonths( new Date(elem), new Date('01-01-2020') ) >= 0)
        .sort(function(a, b) {
          return new Date(a) - new Date(b)
        })

      graphLabel.forEach( date => {
        
        let signingsPoint = data
          .filter( elem => elem._id.onboardingStatus == 'Signed' && differenceInCalendarMonths( new Date(elem._id.signingDate), new Date(date) ) <= 0 )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        let monthSigning = data
          .filter( elem => elem._id.onboardingStatus == 'Signed' && elem._id.signingDate == date )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        let entriesPoint = data
          .filter( elem => elem._id.entryDate == date )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        let monthSigningsPoint = data
          .filter( elem => elem._id.entryDate == date && elem._id.onboardingStatus == 'Signed' )
          .reduce( function(acc, elem) { return acc + elem.count; }, 0)
        
        let notSignedPoint = entriesPoint - monthSigningsPoint
        let ratio = entriesPoint > 0? monthSigningsPoint / entriesPoint : 0
        ratios.push( ratio )

        if( !isNaN(signingsPoint) && signingsPoint > 0 ) cumulativeSignings.push(signingsPoint)
        else cumulativeSignings.push(0)

        if( !isNaN(entriesPoint) && entriesPoint > 0 ) monthEntries.push(entriesPoint)
        else monthEntries.push(0)

        if( !isNaN(monthSigningsPoint) && monthSigningsPoint > 0 ) monthSignings.push(monthSigningsPoint)
        else monthSignings.push(0)

        if( !isNaN(monthSigning) && monthSigning > 0 ) signings.push(monthSigning)
        else signings.push(0)

        if( !isNaN(notSignedPoint) && notSignedPoint > 0 ) notSigned.push(notSignedPoint)
        else notSigned.push(0)

      })

    }    

    if( ratios.length > 0 ){
      avg = Math.round( ratios.reduce((p,c,_,a) => p + c/a.length, 0) * 100, 2) 
    }

    let options = {
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          },
          
          ticks: {
            fontColor: 'rgba(255, 255, 255, 0.8)',
            fontSize: 10
          },
        }],
        yAxes: [{
          gridLines: {
            display:false
          }, 

          ticks: {
            callback: function(value, index, values) {
              return value.toLocaleString();
            },
            fontColor: 'rgba(255, 255, 255, 0.8)',
            beginAtZero: true,
            fontSize: 10
          },
        }]
      }
    }

    let options2 = {
      maintainAspectRatio: false,        
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }},
      legend: {
        display: false
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            stacked: true,
            gridLines: {
              display: false
            },
            labels: graphLabel, 

            ticks: {
              fontColor: 'rgba(255, 255, 255, 0.8)',
              fontSize: 10
            },
          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            stacked: true,
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
              display: false
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                  return value.toLocaleString();
              },
              fontColor: 'rgba(255, 255, 255, 0.8)',
              beginAtZero: true,
              fontSize: 10
            },
            labels: {
              show: true
            }
          }
        ]
      }
    }

    let total = cumulativeSignings && cumulativeSignings.length
      ? cumulativeSignings[cumulativeSignings.length - 1]
      : 0

    return (
      <div>
        <div id="graphSectionThird">  
          <div id="graphTitle">Active contracts</div>
          <div id="graphTitleData">{ total.toLocaleString() }</div>
          <div id="graphWrapper">
            { data
              ? <Bar
                  data={{
                    labels: graphLabel, 
                    datasets: [
                      {
                        label: 'Cumulative',
                        backgroundColor: this.props.color,
                        borderColor: this.props.color,
                        borderWidth: 1,
                        hoverBackgroundColor: this.props.hoverColor,
                        hoverBorderColor: this.props.hoverColor,
                        data: cumulativeSignings
                      }
                    ]
                  }}
                  width={ this.props.width }
                  height={ this.props.height }
                  options={ options }
                /> 
              : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> } 
          </div>
        </div>
        <div id="graphSectionThird">  
          <div id="graphTitle">Monthly increases</div>
          <div id="graphTitleData"></div>
          <div id="graphWrapper">
            { data
              ? <Bar
              data={{
                labels: graphLabel, 
                datasets: [
                  {
                    label: 'Monthly signings',
                    backgroundColor: this.props.color,
                    borderColor: this.props.color,
                    borderWidth: 1,
                    hoverBackgroundColor: this.props.hoverColor,
                    hoverBorderColor: this.props.hoverColor,
                    data: signings
                  }
                ]
              }}
              width={ this.props.width }
              height={ this.props.height }
              options={ options }
            /> 
            : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }
          </div>
        </div>
        <div id="graphSectionThird">  
          <div id="graphTitle">Conversion rate</div>
          <div id="graphTitleData">{ avg }%</div>
          <div id="graphWrapper">
          { data
              ? <Bar
                  data={{
                    labels: graphLabel, 
                    datasets: [
                      {
                        type: 'bar',
                        label: 'Approved never signed',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        borderColor: 'rgba(255, 255, 255, 0.2)',
                        borderWidth: 1,
                        data: notSigned,
                        yAxisID: 'y-axis-1'
                      }, {
                        label: 'Eventually signed',
                        backgroundColor: this.props.color,
                        borderColor: this.props.color,
                        borderWidth: 1,
                        hoverBackgroundColor: this.props.hoverColor,
                        hoverBorderColor: this.props.hoverColor,
                        data: monthSignings
                      }
                    ]
                  }}
                  width={ this.props.width }
                  height={ this.props.height }
                  options={ options2 }
                /> 
            : <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div> }
          </div>
        </div>
      </div>
    );
  }
}

export default Triptic 