import React, { Component } from 'react';
import ContractEdit from './ContractEdit'
import AssetEdit from './../../Assets/Action/AssetEdit'
import SignContract from './SignContract'
import ApproveOneContract from './ApproveOneContract'
import './../contract.css';

class ContractShadow extends Component {
  constructor(props) {
		super(props)
		this.state = {
      showContract: false,
      action: undefined,
      showAsset: false,
      signContract: false,
      approve: false
    };
  }

  closeContract() {
    this.setState({ showContract: false });
    this.props.refresh();
    
  }

  openContract() {
    this.setState({ showContract: true });
  }

  approve() {
    this.setState({ approve: false });
    this.props.refresh()
  }

  closeAsset() {
    this.setState({ showAsset: false });
    this.props.refresh()
  }

  openAsset() {
    this.setState({ showAsset: true });
  }

  closeSignature() {
    this.setState({ signContract: false });
    this.props.refresh()
  }
  
  openSignature() {
    this.setState({ signContract: true });
  }

  handleClick(){
    if( this.props.udl == 'contract' )  this.openContract()
    else if( this.props.asset ) this.openAsset()
    else if( !this.props.asset && this.props.contract && this.props.contract.onboardingStatus =='Signed' ) this.openAsset()
    else if( this.props.contract && this.props.contract.onboardingStatus =='Approved') this.openSignature()
  }

  render() {    

    let user = this.props.user
    let contract = this.props.contract
    let udl = this.props.udl
    if( udl == 'asset' ) udl = 'unit'
    let permissioned = false
    let permissions = this.props.user.permissions
    if ( 
      ['Admin', 'MainAdmin', 'Core', 'CountryAdmin', 'OrgAdmin', 'BranchAdmin', 'ShopAdmin'].indexOf( this.props.user.role ) > -1 || 
      permissions.approveClient != false 
    ) permissioned = true

    let canDelete = false
    if( 
      ['Core', 'MainAdmin' ].indexOf( user.role ) > -1 || 
      ( permissions.writeOffContract )
    ) canDelete = true

    return (
      <div >
        { contract.onboardingStatus == 'Signed' &&
          <div id="contractShadow" onClick={()=>this.handleClick()}>

            { this.props.udl =='asset' && 
              ( this.props.asset || ( !this.props.asset && contract.onboardingStatus == 'Signed' ) ) &&
              <div>
                <div><i className="fas fa-pen-square"></i></div>
                Amend unit in use
              </div> }

            { this.props.udl =='asset' && !this.props.asset && contract.onboardingStatus == 'Pending' &&
              <div>
                <div><i className="fas fa-exclamation-triangle"></i></div>
                Contract needs approval
              </div> }

            { this.props.udl =='asset' && 
              !this.props.asset && 
              contract.onboardingStatus == 'Approved' && 
              canDelete &&
              <div>
                <div><i className="fas fa-pen-square"></i></div>
                Manually sign contract
              </div> }

            { this.props.udl =='contract' &&
              <div>
                <div><i className="fas fa-pen-square"></i></div>
                Edit contract
              </div> }

          </div> }

        { contract.onboardingStatus == 'Approved' && ['MainAdmin', 'Core'].indexOf( this.props.user.role ) > -1 &&
          <div onClick={()=>this.handleClick()} id="contractShadowHalf">
            <i className="fas fa-pen-square"></i><span>&nbsp;&nbsp;Manually sign contract</span>
          </div> }

        { contract.onboardingStatus == 'Approved' && permissioned &&
          <div onClick={()=>this.setState({ approve: !this.state.approve, action: 'Reject' })} id="contractShadowHalf">
            <i className="fas fa-times-circle"></i><span>&nbsp;&nbsp;Reject contract</span>
          </div> }

        { contract.onboardingStatus == 'Rejected' && permissioned &&
          <div onClick={()=>this.setState({ approve: !this.state.approve, action: 'Approve' })} id="contractShadowHalf">
            <i className="fas fa-pen-square"></i><span>&nbsp;&nbsp;Re-approve contract</span>
          </div> }

        { contract.onboardingStatus == 'Pending' && permissioned &&
          <div onClick={()=>this.setState({ approve: !this.state.approve, action: 'Approve' })} id="contractShadowHalf">
            <i className="fas fa-pen-square"></i><span>&nbsp;&nbsp;Approve contract</span>
          </div> }

        { ['Rejected', 'Pending', 'Approved'].indexOf(contract.onboardingStatus) > -1 && canDelete &&
          <div onClick={()=>this.setState({ approve: !this.state.approve, action: 'Delete' })} id="contractShadowHalf">
            <i className="fas fa-skull-crossbones"></i><span>&nbsp;&nbsp;Delete contract</span>
          </div> }

        { this.state.showAsset &&
          <AssetEdit
            show={ this.state.showAsset } 
            onHide={ this.closeAsset.bind(this) }
            contract={ contract }
            user={ user }
            supplierID={ contract.supplier }
          /> }     

        { this.state.approve &&
          <ApproveOneContract
            show={ this.state.approve } 
            action={ this.state.action }
            onHide={ this.approve.bind(this) }
            contract={ contract }
            user={ user }
          />  }   

        { this.state.signContract &&
          <SignContract
            show={this.state.signContract} 
            onHide={this.closeSignature.bind(this)}
            contract={contract}
            userID={ user._id }
            supplierID={contract.supplier}
          /> }

        { this.state.showContract &&
          <ContractEdit
            show={this.state.showContract} 
            onHide={this.closeContract.bind(this)}
            contract={contract}
            user={ user }
            supplierID={contract.supplier}
          /> }     
      </div>
    );
  }
}

export default ContractShadow