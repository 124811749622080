import React, { Component } from 'react';
import Question from './Question';
import QuestionAction from './Action/QuestionAction'
import Edit from '../Action/Edit'
import './questions.css';
import { hasPermission } from './../../../../services/UserService'

class QuestionsList extends Component {
  constructor(props) {
		super(props)
		this.state = {
      create: false, 
      edit: false
    };
  }  

  close() {
    this.setState({ create: false });
    this.props.refresh()
  }

  edit() {
    this.setState({ edit: false });
    this.props.refresh()
  }

  render() {    
    let { questionnaire, user, supplier, refresh } = this.props
    let { create, edit } = this.state
    let mappedQuestions = questionnaire.questions &&  questionnaire.questions
      .sort((a, b) => a.rank - b.rank)
      .map( question =>{ 
        return (
          <Question 
            question={ question } 
            key={ question._id }
            refresh={ refresh } 
            questionnaire={ questionnaire }
            supplier={ supplier }
            user={ user }
          />
        )
      })
    
    let permission = hasPermission( user, questionnaire )

    return (
      <div id="questionsList">

        { permission &&
          <div id="questionsListAction" onClick={()=>this.setState({create: !create})}>
            <i className="fas fa-plus"></i>
          </div> }
        
        <div id="questionsListTitle">Questions in questionnaire</div>

        { mappedQuestions }

        { permission &&
          <div id="questionsListActionPause" onClick={()=>this.setState({ edit: !edit})}>
            <i className="far fa-pause-circle"></i>
          </div> }

        { create &&
          <QuestionAction
            show={ create } 
            onHide={this.close.bind(this)}
            questionnaireId={questionnaire._id}
            questions={questionnaire.questions}
            supplier={ supplier }
            user={ user }
            refresh={ refresh}
          />  }

        { edit &&
          <Edit
            show={ edit } 
            onHide={this.edit.bind(this)}
            questionnaire={questionnaire}
            questions={questionnaire.questions}
            user={ user }
            refresh={ refresh}
          />  }


      </div>
    );
  }
}

export default QuestionsList