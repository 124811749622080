import React, { Component } from 'react';
import './groups.css'

export class Header extends Component {
  
  render() {

    return (
      <div className="groupsTitle">       
       
        <div id="groupsNumber">Group #</div> 
        <div id="groupsName">Group name</div>     
        <div id="groupsData">Group type</div>
        <div id="groupsData">Size</div> 
        <div id="groupsProgress">Target</div>        
        <div id="groupsProgress">Paid</div>
        <div id="groupsProgress">Total paid</div>
        <div id="groupsDate">Last update</div>
        <div id="groupsDate">Next update</div>
      </div>
    )
  }  
}