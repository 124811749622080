import React, { Component } from 'react';
import './payments.css'

export class PaymentHeader extends Component {
  
  render() {
    let general = this.props.general
    return (
      <div className="paymentsTitle">    

        <div id={ general? "paymentsType": "paymentsTypeC"} className="onlyLarge">Type</div>
        <div id={ general? "paymentsStatus": "paymentsStatusS"} className="onlyLarge">Status</div> 
        <div id={ general? "paymentsDate": "paymentsMS"} >Received on</div> 
        
        { [ "Search", "FAILED", "UNASSIGNED" ].indexOf( this.props.paymentStatus) < 0 && general &&
          <div id="paymentsReconciled" className="onlyLarge">Reconciled by</div> }

        <div id={ general? "paymentsTransaction": "paymentsLarge"} >Transaction Id</div> 

        { [ "FAILED", "UNASSIGNED" ].indexOf( this.props.paymentStatus) > -1 && general &&
          <div id="paymentsReason" >Reason</div> }
          
        { this.props.paymentStatus == "Search" && general &&
          <div id={ general? "paymentsContract": "paymentsMedium"}>Reason / contract </div> } 

        { [ "Search", "FAILED", "UNASSIGNED" ].indexOf( this.props.paymentStatus) < 0 && general &&
          <div id={ general? "paymentsContract": "paymentsMedium"} > Contract Id </div> } 

        <div id={ general? "paymentsAmount": "paymentsMS"} >Amount</div>
        <div id="paymentsOrigin" className="onlyLarge">Origin</div>
        { general && <div id="paymentsRef" className="onlyLarge">Reference</div> }
        { general && <div id="paymentsMessage" className="onlyLarge">Delivery</div> }  
                                     
      </div>
    )
  }  
}