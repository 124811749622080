import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { loginUser } from '../../Actions/auth';
import { Carousel } from 'react-bootstrap/lib';
import './auth.css';

import history from './../../history';

import Background2 from './Background2.jpg'
import Background3 from './Background3.jpg'
import Background0 from './Background0.jpg'
import Background5 from './Background5.jpg'

const form = reduxForm({
  form: 'login',
});

class Login extends Component {
  constructor(props) {
		super(props)
		this.state = {
      useEmail: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleFormSubmit(formProps) {
    this.props.loginUser(formProps);
  }
  handleNext(path){
    history.push(path)
  } 

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div id="loginError">
          <span>Error! {this.props.errorMessage}</span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      
        <div className="carMain">

          <div id="login_zone">
            <div id="loginHeader">Login credentials</div>
            <form id="loginForm" onSubmit={ handleSubmit(this.handleFormSubmit.bind(this)) }>
              <p className="text-center"> {this.renderAlert()}</p>
              { this.state.useEmail  
                ? <div id="loginLine">
                    <div 
                      id="loginIcon" 
                      onClick={()=>this.setState({ useEmail: !this.state.useEmail })}
                    >
                      <i className="fas fa-at"></i>
                    </div>
                    <Field 
                      name="email" 
                      className="form-control"  
                      component="input" 
                      type="text"
                      placeholder={'Email'}
                      style={{
                        height:"35px",                         
                        width:"85%",
                        float:"left",
                        border: "0.5px solid white",
                        marginBottom: "15px",
                        paddingLeft: "15px",
                        fontSize: "14px"
                      }}
                    />
                  </div>
                : <div id="loginLine">
                    <div 
                      id="loginIcon" 
                      onClick={()=>this.setState({ useEmail: !this.state.useEmail })}
                      >
                      <i className="fas fa-user-circle"></i>
                    </div>
                    <Field 
                      name="username" 
                      className="form-control"  
                      component="input" 
                      type="text"
                      placeholder={'Username'}
                      style={{
                        height:"35px", 
                        border: "0.5px solid white",
                        width:"85%",
                        float:"left",
                        marginBottom: "15px",
                        paddingLeft: "15px",
                        fontSize: "14px"
                      }}
                    />
                  </div>
              }
              <div id="loginLine">
                <div id="loginIcon"><i className="fas fa-lock"></i></div>
                <Field 
                  name="password" 
                  className="form-control"  
                  component="input" 
                  type="password"
                  placeholder={'Password'} 
                  style={{
                    height:"35px", 
                    marginBottom: "15px",
                    width:"85%",
                    float:"left",
                    border: "0.5px solid white",
                    paddingLeft: "15px",
                    fontSize: "14px"
                  }}
                />
              </div>
              
              <div>
                <button type="submit" id="login_button">Login</button>
              </div>  
            </form> 
            <div id="forgotPwd" onClick={()=>this.handleNext('forgot-password')}>Forgot password?</div>
          </div>
          
          <Carousel controls={false} interval={12000} slide={true} indicators={false}> 
          <Carousel.Item>
              <img className="carItem responsive" alt="900x500" src={Background2} />
            </Carousel.Item>
            <Carousel.Item>
              <img className="carItem responsive" alt="900x500" src={Background0} />
            </Carousel.Item>
            <Carousel.Item>
              <img className="carItem responsive" alt="900x500" src={Background5} />
            </Carousel.Item> 
            <Carousel.Item>
              <img className="carItem responsive" alt="900x500" src={Background3} />
            </Carousel.Item>             
          </Carousel>;

      </div>

    );
  }
}
 
function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
    user: state.auth.user
  };
}

export default connect(mapStateToProps, { loginUser })(form(Login));