import React, { Component } from 'react';
import axios from 'axios';
import ManageMenu from './../ManageMenu'
import history from './../../../history';
import { API_URL } from './../../../Actions/index';
import ClientProfile from './ClientProfile'
import ClientAction from './Action/ClientAction'
import CreditScoreController from './../Credit/CreditScoreController';
import ClientEventsList from './ClientEvents/List'
import ContractTeaser from '../Contracts/Details/ContractTeaser'
import './client.css';

class Client extends Component {
  constructor(props) {
		super(props)
		this.state = {
      edit: false,
    };
  }
  componentDidMount() {
    this.getClientDetails();
    window.scrollTo(0, 0)
  }
  
  getClientDetails = () =>{
    let supplier = this.props.user.supplier
    axios
      .get(API_URL + '/clients/byNumber/' + supplier  + '/'+ this.props.match.params.clientNumber)
			.then( ret => this.setState({ 
        client: ret.data.client,
        events: ret.data.events
      }))
      .catch(err => {
        console.log(err)
      })
  }

  refresh = () => {
    this.setState({ edit: false });
    this.getClientDetails();
  }

  handleNext = (path) => history.push(path)

  goBack = () => window.history.back()
  
  render() {    

    let client = this.state.client
    let user = this.props.user

    let mappedContracts = client && client.contracts && client.contracts.map((contract, index) =>{ 
      return (
        <ContractTeaser 
          key={ index } 
          contract={ contract } 
          user={ user }
          refresh={ this.getClientDetails }
        />        
      )
    })

    return (
      <div className="background">       
        { client &&
          <div>
            <div id="pageTitle">Client <span className="subTitle">{ client.clientNumber}</span></div>  
            <ManageMenu user={ user }/>
            <div id="clientActionLine">
              <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
              <div id="editClient" onClick={()=>this.setState({edit: !this.state.edit})}><i className="fas fa-user-edit"></i></div>
            </div>

            <div id="clientBackground">
              { ! this.state.edit
                ? <div id="clientProfileWrapper">

                    <ClientProfile client={ client } user={ user }/>

                    < div id="clientAdditionalInfo">
                        
                      { client.agent && client.agent.profile &&
                        <div id="iW">
                          <div id="iL">Responsible agent</div>
                          <div className="iD">{ client.agent.profile.firstName } { client.agent.profile.lastName }</div>    
                        </div> }

                        { client.tempAgent && client.tempAgent.profile &&
                          <div id="iW">
                            <div id="iL">Customer service agent</div>
                            <div className="iD">{ client.tempAgent.profile.firstName } { client.tempAgent.profile.lastName }</div>    
                          </div> }

                        <div id="iW">
                          <div id="iL">Entity</div>                            
                          { client.entity
                            ? <div className="iD">{ client.entity.name }</div> 
                            : <div className="iD">{ client.country? client.country : '-' }</div>}
                        </div>

                        { client.createdBy && client.createdBy.profile &&
                          <div id="iW">
                            <div id="iL">Createdby</div>
                            <div className="iD">{ client.createdBy.profile.firstName } { client.createdBy.profile.lastName }</div>   
                          </div> }
                        
                      </div>

                    { client.creditDecisionNote &&
                      <div id="creditDecisionNote">{ client.creditDecisionNote }</div> }

                    <CreditScoreController client={ client } user={ user }/>

                    { this.state.events && this.state.events.length > 0 &&
                      <ClientEventsList 
                        events={ this.state.events } 
                        user={ user } 
                        refresh={ this.getClientDetails }
                        client={ client }
                      /> }

                  </div>
                : <ClientAction 
                    edit={ this.state.edit } 
                    existingClient={ client }
                    refresh={ this.refresh } 
                    user={ user }
                    supplier={ user.supplier }
                  /> }

              <div id="clientContractsWrapper">
                <div id="clientContractsTitle">Contracts</div>
                { mappedContracts }
                <div id="contractAdder" onClick={()=>this.handleNext('/contract/add/id=' + client.clientNumber)}>
                  <p>Create new contract<br/><i className="fas fa-folder-plus"></i></p>
                </div>
              </div>

            </div>

          </div>
        }
      </div>
    );
  }
}

export default Client