import React, { Component } from 'react';
import AssetsList from './../Assets/List/AssetsList'
import AnalyticsMenu from '../AnalyticsMenu'
import { connect } from 'react-redux';
import { emptyList } from '../../../Actions/index';

class Selection extends Component {
  constructor(props) {
		super(props)
		this.state = {
      assets: undefined,      
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  goBack() {
    window.history.back();
  }

  render() { 
    return (
      <div className='background'>   
        <div id="pageTitle">Assets analysis</div>
        <AnalyticsMenu user={this.props.user}/>
        <div id="clientBackground">
          <div id="clientSubMenu">  
            <div id="contractAnalyticsSubTitle">
              <i className="fas fa-filter"></i>
              <span>&nbsp;&nbsp; { this.props.title }</span>
            </div>
            <div className="contractBack" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
          </div>
          { this.props.listOfContracts 
            ? <div id="contractsList">
                <AssetsList 
                  assets={this.props.listOfContracts}
                  assetStatus={ this.props.title.indexOf('deployed') > -1 ? 'DEPLOYED' : 'DEFECTIVE' } 
                />
              </div>
            : null }
        </div>
        
      </div>
    );
  }
}

const AssetsSelection = connect(
  function mapStateToProps(state) {
    return { 
      listOfContracts: state.listOfContracts,
      title: state.title
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Selection);

export default AssetsSelection