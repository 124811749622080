import React, { Component } from 'react';

class Header extends Component {
  
  render() {
    
    return (
      <div>
        <div className="itemMonth itemLeft">Current month</div>
        <div className="itemMonth">Last month</div>
        <div className="itemMonth">Overall</div>
        <div className="itemTotal">Total</div>
        <div className="itemTitle">
          <div id="itemAux">Product -- auxiliary</div>
          <div id="itemTotal">New (*)</div>
          <div id="itemTotal">Second</div>
          <div id="itemTotal">New</div>
          <div id="itemTotal">Second</div>
          <div id="itemTotal">New</div>
          <div id="itemTotal">Second</div>
          <div id="itemAll">All</div>
        </div>
      </div>

    )
  }  
}

export default Header 