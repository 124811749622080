import React, { Component } from 'react';
import './assetTransfer.css';
import history from './../../../../history';

class AssetMvt extends Component {
 
  handleNext(path){
    history.push(path)
  }

  render() {    
    let mvt = this.props.mvt;

    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    let eventDay = new Date(mvt.date)
    eventDay = eventDay.toLocaleDateString("en-EN", options)

    return (
      <div id="assetMvt">
        <div id="mvtHeader">          
          <div id="mvtDate">{eventDay}</div>
          <div id="mvtType">{mvt.type}</div>
        </div>
        <div id="mvt">

        { mvt.newOwner
          ? <div id="mvtWrapper">
              <div id="mvtTitleTo">To</div>
              <div id="mvtTo">
                <div id="mvtName" onClick={()=>this.handleNext('/client/id='+mvt.newOwner.clientNumber)}>
                  { mvt.newOwner.profile.firstName + ' ' + mvt.newOwner.profile.lastName }
                </div>
                { this.props.contractNumber
                  ? <div id="mvtName" onClick={()=>this.handleNext('/contract/id='+this.props.contractNumber)}>
                    <div id="mvtRole">Contract</div>
                    <div id="mvtNumber" >{ this.props.contractNumber }</div>
                  </div>
                  : null } 
              </div>  
            </div>
          : null }

        { mvt.newHolder
          ? <div id="mvtWrapper" onClick={()=>this.handleNext('/agent/id='+mvt.newHolder.agentNumber)}> 
              <div id="mvtTitleTo">To</div>
              <div id="mvtTo">
                <div id="mvtName" >{ mvt.newHolder.profile.firstName + ' ' + mvt.newHolder.profile.lastName }</div>
                <div id="mvtRole">{mvt.newHolder?mvt.newHolder.role:'-'}</div>
                <div id="mvtNumber">{mvt.newHolder.agentNumber}</div>
              </div>  
            </div>
          : null }    

        { mvt.oldHolder          
          ? <div id="mvtWrapper" onClick={()=>this.handleNext('/agent/id='+mvt.oldHolder.agentNumber)}>
              <div id="mvtTitleFrom">From</div>
              <div id="mvtFrom">
                <div id="mvtName"  >{ mvt.oldHolder.profile.firstName + ' ' + mvt.oldHolder.profile.lastName } </div>
                <div id="mvtRole">{ mvt.oldHolder.role }</div>
                <div id="mvtNumber" >{mvt.oldHolder.agentNumber}</div>
              </div>
            </div>
          : null }

        { mvt.oldOwner
          ? <div id="mvtWrapper">
              <div id="mvtTitleFrom">From</div>
              <div id="mvtFrom">
                <div id="mvtName"  className="active" onClick={()=>this.handleNext('/client/id='+mvt.oldOwner.clientNumber)}>
                  { mvt.oldOwner.profile.firstName + ' ' + mvt.oldOwner.profile.lastName }
                </div>
                { this.props.contractNumber
                  ? <div id="mvtName" className="active" onClick={()=>this.handleNext('/contract/id='+this.props.contractNumber)}>
                    <div id="mvtRole">Contract</div>
                    <div id="mvtNumber" >{ this.props.contractNumber }</div>
                  </div>
                  : <div id="mvtClient">Client</div> } 
              </div>  
            </div>
          : null }

        { mvt.prevContractNumber
          ? <div id="mvtWrapper" onClick={()=>this.handleNext('/contract/id='+mvt.prevContractNumber)}> 
              <div id="mvtTitleContract">Previous contract</div>
              <div id="mvtContract" className="active" >{mvt.prevContractNumber}</div>
            </div>
          : null }  


        { mvt.author
          ? <div id="mvtWrapper"> 
              <div id="mvtTitleContract">Origin</div>
              <div id="mvtContract">{mvt.author}</div>
            </div>
          : null }    
      
        { mvt.note
          ? <div id="mvtWrapper"> 
              <div id="mvtTitleTo">Note</div>
              <div id="mvtTo">
                <div id="mvtDouble" >{mvt.note}</div>
              </div>  
            </div>
          : null }  

        </div>
      </div>
    );
  }
}

export default AssetMvt