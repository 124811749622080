import React from 'react';
import { Button, FormControl } from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { findAgents } from  '../../../../services/AgentService'

class RepossessAsset extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status:undefined,
      disabled: false,
      agents: undefined,
      codes: undefined,
      agent: undefined
    };
  } 

  componentDidMount() {
    this.loadData()
  }

  repossess() {
    this.setState({ disabled: true })
    axios.put(API_URL + '/assets/repossess/'+ this.props.user.supplier + '/' + this.props.asset.assetNumber, {
      agent: this.state.agent? this.state.agent: this.props.contract.agent,
      userId: this.props.user._id 
    })
      .then((ret) => { this.setState({ 
          codes: ret.data.codes,
          status: ret.data.message 
        }) 
      })
      .catch(err => this.setState({ status: err.message }) ); 
  }

  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getAgents();
  } 

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status == 'Success!' ?'successfulCreation':'unSuccessfulCreation'} className="long">
          { this.state.status }
          { this.state.codes 
            ? <div>
                Locking code for unit = { this.state.codes.lockCode? this.state.codes.lockCode: '-' }
              </div>
            : null
          }        
        </div>
      );
    }
  }  

  render() {

    let mappedAgents = null
    let respAgent = []
    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} value={agent._id}>{agent.profile.lastName} {agent.profile.firstName}</option>
        )
      })
      respAgent = this.state.agents.filter( agent => agent._id === this.props.contract.agent )
    }

    let isDisabled = true;
    if( !this.state.disabled && ( respAgent[0] || this.state.agent )){ 
      isDisabled = false 
    }

    return (
      <div>
        <div id="assetActionTitle">Are you sure you want to repossess this unit?</div>
        <div id="assetActionTitle">
          {respAgent[0] && respAgent[0].profile 
            ? 'The asset will be added back to the stocks of agent' + respAgent[0].profile.firstName + respAgent[0].profile.lastName
            : 'No agent recorded for this contract'
          }
        </div>
        <div id="assetActionTitle">
        {respAgent[0] && respAgent[0].profile 
            ? 'Choose a different agent (optional)'
            : 'Choose an agent (mandatory)'
          }          
        </div>
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="contractInput"
          onChange={(e)=>{ 
            this.setState({ agent: e.target.value });
            }}
          >
          <option value="">Choose a new agent</option>
          {mappedAgents}
        </FormControl>  
        {this.renderMessage()}
        <div id="modal_action">
          { this.state.status
            ? null
            : <Button id="action_signup" disabled={isDisabled} onClick={this.repossess.bind(this)}>Confirm</Button>
          }
        </div>
      </div>
    );
  }
}

export default RepossessAsset;