import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';
import { Button, FormControl } from 'react-bootstrap/lib';
import './../search.css';
import { connect } from 'react-redux';
import EventsList from '../../Events/EventsList/EventsList'
import { addToContractList, emptyList } from '../../../../Actions/index';

var DatePicker = require("react-bootstrap-date-picker");

const assetEventsType = [
  'TRANSFERED', 
  'REPOSSESSED', 
  'UNREPOSSESSED',
  'INSTOCK', 
  'CORRECTION',
  'DEFECTIVE',
  'RESTORED', 
  'SYNC',
  'DEPLOYED',  
  'PAIDOFF', 
  'LOCKED', 
  'WRITEOFF'
]

const contractEventsType = [
  'SIGNED',
  'SUBMITTED',
  'REJECTED',
  'APPROVED',
  'WRITEOFF',
  'REPOSSESSED', 
  'UNREPOSSESSED',  
  'ENABLED', 
  'LOCKED', 
  'DEAL_UPDATE',
  'DEAL_CHANGE',
  'REPLACED', 
  'PAIDOFF', 
  'MODIFIED',
  'REVERSAL',
  'TRANSFER',
  'CANCELLED',
  'EXTENDED',
  'LINKED',
  'BONUS_SENT'
]

class SearchFonction extends Component {
  constructor(props) {
		super(props)
		this.state = {
      query:{
        supplierId: this.props.supplier,
      },
      page: 1,
      pages: 1,
      total: 0,
      disabled: false,
    };
  }
  
  componentWillUnmount(){
    this.props.emptyList()
  }

  getResults(fromPage){

    var body = Object.assign(this.state.query, {
      fromPage,
      pageSize: 200,
      user: this.props.user._id,

    })
    axios.post(API_URL + '/events/search', body)
      .then((ret) => {
        this.setState({ 
          events: ret.data.docs,
          page: ret.data.page,
          pages: ret.data.pages,
          total: ret.data.total  
         })
      })
      .catch(err => this.setState({ message: 'Error' }))
  }

  download(){

    this.setState({ disabled: true })
    var body = { 
      query: this.state.query,
      user: this.props.user._id,
      export: true,
    }
    axios.post(API_URL + '/events/search', body)
      .then(response => {
        const url = window.URL.createObjectURL( new Blob([response.data], {type: 'text/csv'}) );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'events.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.state.query.export = false
      })  
  }

  render() {    
    let { page, pages, events, query } = this.state

    let mappedAssetsEvents = assetEventsType.map( (ccy, index) =>{ 
      return <option key={index} value={ccy}>{ccy}</option> 
    })

    let mappedContractsEvents = contractEventsType.map( (ccy, index) =>{ 
      return <option key={index} value={ccy}>{ccy}</option> 
    })
    
    let disabled = true

    if( query.category && query.type && !this.state.disabled ) disabled = false

    return (
      <div> 
        <div className="activeSearchSection">   
          <FormControl 
            controlId="formControlsSelect"
            componentClass="select"   
            className="searchInput"
            onChange={(e)=>{ 
              let query = Object.assign({}, this.state.query); 
              query.category = e.target.value; 
              this.setState({ query }) 
            }}
            >
            <option value="">Select event category</option>
            <option value="Contracts">Contracts</option>
            <option value="Assets">Assets</option>
          </FormControl>

          { query.category == 'Contracts'
            ? <FormControl 
                controlId="formControlsSelect"
                componentClass="select"   
                className="searchInput"
                onChange={(e)=>{ 
                  let query = Object.assign({}, this.state.query); 
                  query.type = e.target.value; 
                  this.setState({ query }) 
                }}
                >
                <option value="">Select event type</option>
                { mappedContractsEvents }
              </FormControl>
            : <div className="searchInput inactive">Contract event type</div>
          }

          { query.category == 'Assets'
            ? <FormControl 
                controlId="formControlsSelect"
                componentClass="select"   
                className="searchInput"
                onChange={(e)=>{ 
                  let query = Object.assign({}, this.state.query); 
                  query.type = e.target.value; 
                  this.setState({ query }) 
                }}
                >
                <option value="">Select event type</option>
                { mappedAssetsEvents }
              </FormControl>
            : <div className="searchInput inactive">Asset event type</div>
          }
          
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="After"
              value={ query.after }  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.after = e; 
                this.setState({ query }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div>   
          <div className="dateSearchZone"  >
            <DatePicker 
              className ="dateSearchInput"
              placeholder="Before"
              value={ query.before }  
              onChange={(e)=>{ 
                let query = Object.assign({}, this.state.query); 
                query.before = e; 
                this.setState({ query }) 
              }}
              showClearButton= {false}
              dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
            />          
          </div> 

          <div id="searchZone">
            <Button id="searchButton" disabled={disabled} onClick={()=>this.getResults(page)}>Search</Button>
            
            { this.props.selectable || ( this.props.options && ( this.props.options.export == false || this.props.options.locked ) )
              ? null
              : <Button id="searchButton" className="downloadButton" disabled={disabled} onClick={()=>this.download()}>
                  <i className="fas fa-file-download"></i>
                </Button> }

          </div>
        </div>

        { events && events.length > 0
            ? <div id="resultHeader">
                <div className="contractNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getResults( 1 )
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getResults( Math.max( 1, page -1 ) )
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="navLegend">{ this.state.total } events</div>
                  <div className="navPage">page {page} / {pages} </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getResults( Math.min( pages, page + 1 ) )
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getResults( pages )
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>
                
                <div id="searchOutcome">
                  <EventsList 
                    events={ events } 
                    supplier={ this.props.supplier }
                    category={ query.category }
                  />
                </div>
              </div>
            : null } 

      </div>
    );
  }
}


export const SearchEvents = connect(
  function mapStateToProps(state) {
    return { 
      search: state.search,
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      addToContractList: contracts => dispatch(addToContractList(contracts)),
      emptyList: () => dispatch(emptyList())
    };
  },
)(SearchFonction);
