import React from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap/lib';
import { API_URL } from '../../../../Actions/index';
import { I18n } from 'react-i18next';
import { SelectEntity } from './../../Entities/Action/SelectEntity'

class TransferClients extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      agent: {},
    };
  } 

  handleChange(){
    axios.put(API_URL + '/agents/edit/'+ this.props.agent.supplier + '/' +this.props.agent.agentNumber, this.state.agent)
			.then((ret) => {
        this.setState({
          status: 'Success'
        })
      })      
      .catch(err => {
        this.setState({status: err.message})
      }); 
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  
  
  selectEntity = (entity) => {
    let agent = Object.assign({}, this.state.agent); 
    agent.entity = entity
    this.setState({ agent }) 
  }

  over(){
    this.props.onHide()
    this.setState({
      agent: {
        newAgentId: undefined,
      },
      status: undefined,
    })
  }

  render() {
    let disabled = false;

    return (

      <I18n ns="translations">
        {(t, { i18n }) => (
          <Modal
            dialogClassName="confirmModal"
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Transfer agent to new entity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <div id="modal_highlight">
              <div id="full">
                <SelectEntity
                  selectEntity={ this.selectEntity } 
                  format={ "contractInput" }
                  supplierId={ this.props.user.supplier }
                  userId={ this.props.user._id }
                />
              </div>
              <div id="warningTransferClients">Warning: this action will NOT move this agent's existing clients to the new entity</div>
            </div>
            
            { this.renderMessage() }            
            
            <div id="modal_action">

              { this.state.status !=='Success' &&
                <Button id="action_signup" disabled={disabled} onClick={()=>this.handleChange()}>Move</Button> }
                <Button id="action_cancel" onClick={()=>this.over()}>{this.state.status==='Success'?'Close':'Abort'}</Button>
            
            </div>
          
          </Modal.Body>
          </Modal>
        )}
      </I18n>    
    );
  }
}

export default TransferClients;