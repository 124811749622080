import React, { Component } from 'react';
import './payrollcontracts.css';
import history from './../../../../../history';
var differenceInSeconds = require("date-fns/difference_in_seconds");

export class Item extends Component {
  
  handleNext(path){
    history.push(path)
  } 

  render() {    
   
    let contract = this.props.contract
    let signingDate = ''

    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    if( contract.signingDate ){
      signingDate = new Date( contract.signingDate )
      signingDate = signingDate.toLocaleDateString("en-EN", options)
    }
    let amount = 0
    let payments = contract.payments

    let payroll = this.props.payroll
    if( payments && payments.length ){
      payments.forEach( payment => {
        if( 
          differenceInSeconds( payment.date, payroll.dateFrom) > 0 && 
          differenceInSeconds( payment.date, payroll.dateTo) < 0
         ) amount += payment.amount
      })
    }
    let commission = 0
    if( payroll.split && payroll.split.length > 0 ){
      let item = payroll.split.find( elem => elem.contract == contract.contractNumber )
      if( item ) commission = item.commission
    }

    return (
      <div>
        <div className="payrollContractItem">       
          <div className="payrollContractNumber active" onClick={ () => this.handleNext('/contract/id=' + contract.contractNumber )}>{ contract.contractNumber }</div>
          <div className="payrollContractDate">{ signingDate }</div>
          <div className="payrollContractDeal">{ contract.dealName }</div>
          <div className="payrollContractAmount">{ contract.ccy }{ amount.toLocaleString() }</div>
          <div className="payrollContractAmount">{ contract.ccy }{ contract.totalPaid.toLocaleString() }</div>
          <div className="payrollContractAmount">{ contract.ccy }{ contract.totalCost.toLocaleString() }</div>  
          <div className="payrollContractAmount">{ contract.ccy }{ commission.toLocaleString() }</div>      
        </div>
      </div>  
    );
  }
}