import React, { Component } from 'react';
import { AgentTargetsListItem } from './AgentTargetsListItem'
import './agentTargets.css';

class AgentTargetsList extends Component {
  
  render() {    
    let mappedAgentTargets = null;
    let data = this.props.agentTargets
    if( data ){
      mappedAgentTargets = data.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      }).map((agentTarget, index) =>{ 
        return (
          <AgentTargetsListItem 
            agentTarget={ agentTarget } 
            key={ index }
            user={ this.props.user }
            refresh={this.props.refresh} 
            supplier={ this.props.supplier }
            selectable={ this.props.selectable }
            supplierId={ this.props.supplierId }
            edit={ this.props.edit }
          />
        )
      })
    }

    return (
      <div>
        { mappedAgentTargets }
      </div>
    );
  }
}

export default AgentTargetsList