import React, { Component } from 'react';
import SenderController from './Sender/SenderController'
import TemplateController from './Templates/TemplateController'
import MessagesList from './List/MessagesList'
import ManageMenu from './../ManageMenu'
import './message.css';
import axios from 'axios';
import { FormControl } from 'react-bootstrap/lib';
import { API_URL } from './../../../Actions/index';
var DatePicker = require("react-bootstrap-date-picker");

class MessagesController extends Component {
  constructor(props) {
		super(props)
		this.state = {
      nav: 3,
      page: 1,
      pages: 1,
      total: 0,
      filterNext: 0,
      filterLast: 0,
    };
  }
  
  getMessages( fromPage, messageId, sentOn, status ){
    this.setState({ nav: 3 })
    var body = {
      fromPage,
      pageSize: 50,
      status,
      user: this.props.user._id,
      messageId,
      sentOn
    }
    axios.post(API_URL + '/messages/all/' + this.props.user.supplier, body)
      .then((ret) => this.setState({ 
        messages: ret.data.docs,
        page: ret.data.page,
        pages: ret.data.pages,
        total: ret.data.total   
      }))
  }

  componentDidMount() {
    this.getMessages(1);
  }

  render() {    
    let navigate = <div className="genericLoader">Loading... <br/><i className="fas fa-spinner"></i></div>;
    let user = this.props.user
    if( this.state.nav === 1 ) navigate = <SenderController supplier={ user.supplier} user={ user }/>
    if( this.state.nav === 2 ) navigate = <TemplateController supplier={ user.supplier} user={user}/>
    if( this.state.nav === 3 && this.state.messages ){
      navigate = <MessagesList supplier={ user.supplier} all={true} messages={ this.state.messages } user={ user }/>
    }

    let page = this.state.page;
    let pages = this.state.pages;

    return (
      <div className='background'>
        <div id="pageTitle">Texts</div>
        <ManageMenu user={ user}/>
        <div id="clientBackground">
          
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={() => this.getMessages( this.state.page )} className={this.state.nav===3?"active_clientSubMenu leftMenu":"leftMenu"}>Sent</div>
            <div id="clientSubMenu_item" onClick={() => this.setState({ nav: 2 })} className={this.state.nav===2?"active_clientSubMenu":""}>Templates</div>
            <div id="clientSubMenu_item" onClick={() => this.setState({ nav: 1 })} className={this.state.nav===1?"active_clientSubMenu rightMenu":"rightMenu"}>Group send</div>

            { this.state.nav === 3 &&
              <div className='onlyLarge'>
      
                <div className="messageDateSelectorWrapper"  >
                  <DatePicker 
                    className ="messageDateSelector"
                    placeholder="Sent on"
                    value={ this.state.sentOn }  
                    onChange={(e)=>{ 
                      this.setState({ sentOn: e });
                      this.getMessages( this.state.page, this.state.messageId, e, this.state.status )
                    }}
                    showClearButton= {false}
                    calendarPlacement ={ 'left' }
                    dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                    monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                  />          
                </div>  

                <FormControl 
                  controlId="formControlsSelect"
                  componentClass="select" 
                  className="messageSelector statusSelector"
                  placeholder="Delivery status"
                  onChange={ (e) => {
                    this.setState({ status: e.target.value });
                    this.getMessages( this.state.page, this.state.messageId , this.state.sentOn, e.target.value )
                  }}
                >
                  <option value="ALL" className='agentOption'>{ this.state.status != undefined ? "Remove filter" : "All" }</option>
                  <option value={"Success"} className='agentOption'>Success</option>
                  <option value={"Expired"} className='agentOption'>Expired</option>
                  <option value={"Failed"} className='agentOption'>Failed</option>
                </FormControl>

                <FormControl 
                  type="text"
                  className="messageSelector slector"
                  placeholder="Search by messageId"
                  onChange={ (e) => {
                    this.setState({ messageId: e.target.value });
                    this.getMessages( this.state.page, e.target.value , this.state.sentOn, this.state.status )
                  }}
                />

              </div> }

          </div>

          { this.state.messages && this.state.messages.length > 0 && this.state.nav === 3
            ? <div id="assetsList" className='noMargin'>
                <div className="contractNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getMessages( 1, this.state.messageId , this.state.sentOn, this.state.status )
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getMessages( Math.max( 1, page -1 ), this.state.messageId , this.state.sentOn, this.state.status )
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="navLegend">{ this.state.total } messages</div>
                  <div className="navPage">page { page } / { pages } </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getMessages( Math.min( pages, page + 1 ), this.state.messageId , this.state.sentOn, this.state.status )
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getMessages( pages, this.state.messageId , this.state.sentOn, this.state.status )
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>
              </div>
            : null } 

          { navigate }
        </div>
      </div>
    );
  }
}

export default MessagesController