import React, { Component } from 'react';
import './../payroll.css'

export class PayrollsHeader extends Component {
  
  render() {

    return (
      <div id="payrollsTitle">       
        <div id="payrollNumber">AgentNumber</div>
        <div id="payrollName">Agent name</div>
        <div id="payrollAmount">Amout due</div>
        <div id="payrollDate">Period start</div>
        <div id="payrollDate">Period end</div>
        <div id="payrollComment">Details</div>        
      </div>
    )
  }  
}