import React from 'react';
import { Doughnut } from 'react-chartjs-2';

var getYear = require("date-fns/get_year");

class AgeShot extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options : {
        maintainAspectRatio: false,        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }},
        legend: {
          display: false
        },
      },
      data: undefined
    };
  };


  render() {
    let data = this.props.data.filter( elem => elem._id.dob )

    let label = [ '18 - 25', '25 - 35', '35 - 45', '45 - 55', '55 - 65', '65+' ]
    let dataset = []

    let l25 = 0
    let l35 = 0
    let l45 = 0
    let l55 = 0
    let l65 = 0
    let m65 = 0
    
    if( data ){
      let year = getYear(new Date())
      l25 = data
        .filter( elem => year - elem._id.dob < 25 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      l35 = data
        .filter( elem => year - elem._id.dob >= 25 && year - elem._id.dob < 35 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      l45 = data
        .filter( elem => year - elem._id.dob >= 35 && year - elem._id.dob < 45 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      l55 = data
        .filter( elem => year - elem._id.dob >= 45 && year - elem._id.dob < 55 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      l65 = data
        .filter( elem => year - elem._id.dob >= 55 && year - elem._id.dob < 65 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      m65 = data
        .filter( elem => year - elem._id.dob >= 65 )
        .reduce( function(acc, elem) { return acc + elem.count; }, 0)
      dataset = [ l25, l35, l45, l55, l65, m65 ]
    }
      
    return (
      <div>   
        <Doughnut 
          data={{
            labels: label, 
            datasets: [
              {
                label: 'Age (years)',
                backgroundColor: [
                  'white',
                  'rgb(128, 255, 255)',
                  'rgb(65, 255, 255)',
                  'rgb(27, 218, 218)',
                  'rgb(11, 179, 179)',
                  'rgba(0,139,139, 1)'
                ],
                borderColor: '#26455A',
                borderWidth: 1,
                hoverBackgroundColor: [
                  'white',
                  'rgb(128, 255, 255)',
                  'rgb(65, 255, 255)',
                  'rgb(27, 218, 218)',
                  'rgb(11, 179, 179)',
                  'rgba(0,139,139, 1)'
                ],
                hoverBorderColor: [
                  'white',
                  'rgb(128, 255, 255)',
                  'rgb(65, 255, 255)',
                  'rgb(27, 218, 218)',
                  'rgb(11, 179, 179)',
                  'rgba(0,139,139, 1)'
                ],
                data: dataset
              }
            ]
          }}
          width={ this.props.width }
          height={ this.props.height }
          options={ this.state.options }
        />
      </div>      
    );
  }
}

export default AgeShot