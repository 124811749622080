import React, { Component } from 'react';
import axios from 'axios';
import { API_URL, emptyList } from '../../../Actions/index';
import PayrollsList from './List/PayrollsList';
import { FormControl } from 'react-bootstrap/lib';
import PayPayrolls from './Action/PayPayrolls'
import { connect } from 'react-redux';
import ManageMenu from '../ManageMenu'
import './payroll.css'
import { findAgents } from  '../../../services/AgentService'

var DatePicker = require("react-bootstrap-date-picker");

class Controller extends Component {
  constructor(props) {
		super(props)
		this.state = {
      pay: false,
      status: 'Estimate',
      totalDue: 0,
      page: 1,
      total: 0,
      pages: 1    
    };
  }

  componentDidMount() {
    this.loadData()
  }
  
  handleSelect(status){
    this.setState({ status: status }); 
    this.getPayrollsList(status, 1);
  }

	getPayrollsList = (status, fromPage, agent, dateFrom, dateTo, agentInvoiceNumber) => {
    var body = {
      status,
      agent,
      agentInvoiceNumber,
      dateFrom,
      dateTo,
      supplierId: this.props.user.supplier,
      fromPage,
      pageSize: 75,
    }
    axios.post(API_URL + '/agents/getAllInvoices/', body)
			.then((ret) => {
        this.setState({
          payrolls: ret.data.invoices.docs,
          page: ret.data.invoices.page,
          pages: ret.data.invoices.pages,
          total: ret.data.invoices.total,
          totalDue: ret.data.totalDue 
        })
      })
  }
  
  getAgents = async () => {
    try {
      let agents = await findAgents( {}, this.props.user._id, this.props.user.supplier )
      this.setState({ agents })
    } catch(err){ console.log(err) }
  }

  loadData() {
    this.getPayrollsList('Estimate', 1);
    this.getAgents()
  }

  pay(){
    this.setState({ pay: false });
    this.props.emptyList()
    this.getPayrollsList('Estimate', 1);
  }

  download(){
    var query = {
      status: this.state.status,
      agent: this.state.agent,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      export: true,
      supplierId: this.props.user.supplier
    }

    axios.post(API_URL + '/agents/download-invoices/', query)
      .then(response => {
        const url = window.URL.createObjectURL( new Blob([response.data], {type: 'text/csv'}) );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'payrolls.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })  
  }
  
  render() {    
    
    let page = this.state.page;
    let pages = this.state.pages
    let user = this.props.user;
    let mappedAgents = null

    if(this.state.agents){
      mappedAgents = this.state.agents.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName)).map((agent, index) =>{ 
        return (
          <option key={index} className='agentOption' value={agent._id}>{agent.profile.lastName} {agent.profile.firstName} ({agent.agentNumber})</option>
        )
      })
    }
  

    return (
			<div className='background'>

        <div id="pageTitle">Payrolls</div>
				<ManageMenu user={ user }/>
				
        <div id="clientBackground">
          
          <div id="clientSubMenu">  
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Estimate')} className={this.state.status==='Estimate'?"active_clientSubMenu leftMenu":"leftMenu"}>Estimates</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Pending')} className={this.state.status==='Pending'?"active_clientSubMenu":""}>Pending</div>
            <div id="clientSubMenu_item" onClick={()=>this.handleSelect('Paid')} className={this.state.status==='Paid'?"active_clientSubMenu rightMenu":"rightMenu"}>Paid</div>

            { this.props.selectedPayrolls && 
              this.props.selectedPayrolls.length > 0 &&
              ['Core', 'Admin', 'MainAdmin'].indexOf( user.role ) > -1
              ? <div id="markAsPaid" onClick={()=>this.setState({ pay: !this.state.pay })}>Mark as paid</div>
              : null }

            { ['Core', 'Admin', 'MainAdmin'].indexOf( user.role ) > -1
              ? <div id="payrollDownload" onClick={() => this.download()}>
                  <i className="fas fa-file-download"></i>
                </div>
              : null
            }

            <div className="quickSearch onlyLarge">
              <FormControl
                type="text"
                className="quickSearchInput"
                placeholder="Payroll number"
                onChange={(e)=> this.setState({ agentInvoiceNumber: e.target.value }) }
              />
              <div className="searchAction" onClick={ 
                () => this.getPayrollsList( undefined, 1, undefined, undefined, undefined, this.state.agentInvoiceNumber )
              }>
                <i className="fas fa-search"></i>
              </div>
            </div>


            <div id="payrollSelectorWrapper">

              <div className="payrollDateSelectorWrapper"  >
                <DatePicker 
                  className ="messageDateSelector"
                  placeholder="Period ending"
                  value={ this.state.dateTo }  
                  onChange={(e)=>{ 
                    this.setState({ dateTo: e });
                    this.getPayrollsList( this.state.status, 1,this.state.agent, this.state.dateFrom, e )
                  }}
                  showClearButton= {false}
                  calendarPlacement ={ 'left' }
                  dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                  monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                />          
              </div>  
              <div className="payrollDateSelectorWrapper"  >
                <DatePicker 
                  className ="messageDateSelector"
                  placeholder="Period starting"
                  value={ this.state.dateFrom }  
                  onChange={(e)=>{ 
                    this.setState({ dateFrom: e });
                    this.getPayrollsList( this.state.status, 1,this.state.agent, e, this.state.dateTo )
                  }}
                  showClearButton= {false}
                  calendarPlacement ={ 'left' }
                  dayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                  monthLabels ={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                />          
              </div>  
              <FormControl 
                controlId="formControlsSelect"
                componentClass="select" 
                className="payrollSelector"
                onChange={ (e) => {
                  this.setState({ agent: e.target.value });
                  this.getPayrollsList( this.state.status, 1, e.target.value, this.state.dateFrom, this.state.dateTo )
                }}
              >
                <option value={""} className='agentOption'>{ this.state.agent ? "Remove filter": "Filter on agent" }</option>
                { mappedAgents }
              </FormControl>

            </div>    


          </div>
          
          { this.state.payrolls && this.state.payrolls.length > 0
            ? <div id="payrollsList">
                <div className="payrollNavigator">
                  <div className="navButton" onClick={() => {
                    this.setState({ page: 1 })
                    this.getPayrollsList( this.state.status, 1, this.state.agent, this.state.dateFrom, this.state.dateTo )
                  }}>
                    <i className="fas fa-angle-double-left"></i>
                  </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.max( 1, page -1 ) })
                    this.getPayrollsList(this.state.status, Math.max( 1, page -1 ), this.state.agent, this.state.dateFrom, this.state.dateTo)
                  }}>
                    <i className="fas fa-angle-left"></i>
                  </div>
                  <div className="payrollTotal">
                    <div className="payrollTotalLabel">Total due over period</div>
                    <div className="payrollTotalData">{ user.options.ccys[0] } { this.state.totalDue.toLocaleString() }</div>
                  </div>
                  <div className="payrollLegend">{ this.state.total } payrolls</div>
                  <div className="navPage">page {page} / {pages} </div>
                  <div className="navButton" onClick={() => {
                    this.setState({ page: Math.min( pages, page + 1 )})
                    this.getPayrollsList(this.state.status, Math.min( pages, page + 1 ), this.state.agent, this.state.dateFrom, this.state.dateTo)
                  }}>
                    <i className="fas fa-angle-right"></i>
                  </div> 
                  <div className="navButton" onClick={() => { 
                    this.setState({ page: pages })
                    this.getPayrollsList(this.state.status, pages, this.state.agent, this.state.dateFrom, this.state.dateTo)
                  }}>
                    <i className="fas fa-angle-double-right"></i>
                  </div>
                </div>

                <PayrollsList 
                  payrolls={ this.state.payrolls }
                  user={ this.props.user } 
                  refresh={ this.getPayrollsList }
                />

              </div>
            : null } 

          { this.state.pay
            ? <PayPayrolls
                show={ this.state.pay } 
                onHide={ this.pay.bind(this) }
                selectedPayrolls={ this.props.selectedPayrolls }
                user={ user} 
              /> 
            : null }


          </div>
			</div>
    );
  }
}

const PayrollsController = connect(
  function mapStateToProps(state) {
    return { 
      selectedPayrolls: state.selectedPayrolls
    };
  },
  function mapDispatchToProps(dispatch) {
    return {
      emptyList: () => dispatch(emptyList()),
    };
  },
)(Controller);


export default PayrollsController