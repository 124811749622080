import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from './../../../Actions/index';
import ClientProfile from './../Clients/ClientProfile'
import Picture from './../Images/Picture'
import AssetSummary from './../Assets/AssetSummary'
import RepossessedAssetSummary from './../Assets/RepossessedAssetSummary'
import ContractSummary from './Details/ContractSummary'
import ContractShadow from './Action/ContractShadow'
import NotesController from '../Notes/NotesController'
import MessagesList from './../Messages/List/MessagesList'
import ActivationsList from './../Activations/ActivationsList'
import PaymentsListController from './../Payments/PaymentsListController'
import EventController from './Events/EventController'
import ManageMenu from './../ManageMenu'
import { Payments } from './Graphs/Payments';
import { Activity } from './Graphs/Activity';
import history from './../../../history';
import './contract.css';


class Contract extends Component {
  constructor(props) {
		super(props)
		this.state = {
      showPayments: false,
      showActivations: false,
      showPicture: false,
      showMessages:false,
      showNotes: false,
      events: false,
      edit: false
    };
  }

  componentDidMount() {
    this.refresh();
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    if( this.props.match.params.contractNumber !== prevProps.match.params.contractNumber ) {
      this.getContractDetails();
      this.getEvents()
    }
  }

  goBack() {
    window.history.back();
  }

	getContractDetails = () => {
    axios.post(API_URL + '/contracts/byNumber/' + this.props.user.supplier + '/' + this.props.match.params.contractNumber)
			.then((ret) => this.setState({ contract: ret.data }))
      .catch(err => console.log(err))
  }

	getEvents() {
    axios.get(API_URL + '/events/contracts/' + this.props.user.supplier + '/' + this.props.match.params.contractNumber)
			.then((ret) => {
        let byDate = ret.data
        byDate.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
        this.setState({events: byDate})
      })
      .catch(err => console.log(err))
  }  
  
  refresh = () => {
    this.getContractDetails();
    this.getEvents();
    this.setState({ edit: false })
  }
  
  render() {    
    let user = this.props.user
    let options = user.options
    let contract = this.state.contract
    
    return (
      <div className="background">
        { contract &&
          <div>
            <div id="pageTitle">Contract <span className="subTitle">{contract.contractNumber}</span></div>  
            <ManageMenu user={ user }/>
            <div id="clientActionLine">
              <div id="returnClient" onClick={()=>this.goBack()}><i className="fas fa-undo-alt"></i></div>
              <div id="editClient" onClick={()=>this.setState({edit:!this.state.edit})}><i className="fas fa-pen-square"></i></div>
            </div>
            <div id="clientBackground">

              { ! this.state.edit && contract.asset &&
                <div id="contractSection">
                  <AssetSummary 
                    asset={ contract.asset }
                    assets={ contract.assets }
                    linkedContracts={ contract.linkedContracts }
                  />
                </div> }

              { ! this.state.edit && ! contract.asset && ['Approved', 'Rejected', 'Pending'].indexOf(contract.onboardingStatus) < 0 &&
                <div id="contractSection">
                  <RepossessedAssetSummary 
                    contract={ contract }
                  />
                </div> }

              { ( this.state.edit || ['Approved', 'Rejected', 'Pending'].indexOf(contract.onboardingStatus) > -1 ) &&
                <div id="contractSection">
                  <ContractShadow 
                    asset={ contract.asset } 
                    user={ user }
                    contract={ contract } 
                    udl={'asset'}
                    refresh={ this.refresh }
                  />
                </div>  }

              <div id="contractSection">
                <ClientProfile client={contract.client} user={ user }/>
              </div>

              { ! this.state.edit
                ? <div id="contractSection">
                    <ContractSummary contract={contract}/>
                  </div>
                : <div id="contractSection">
                    <ContractShadow 
                      contract={contract} 
                      user={ user }
                      udl={'contract'}
                      refresh={this.refresh}
                    />
                  </div> }

              { this.state.events &&
                <EventController events={this.state.events}/> }

              { contract && !contract.linkedTo && 
                <div>
                  
                  { options && options.payments !== false &&
                    <div>
                      <div id="contractBottomSection">
                        <div 
                          id="contractSubSection" 
                          onClick={()=>this.setState({showPayments:!this.state.showPayments})}
                          className={this.state.showPayments?"ActiveSubSection":""}
                        >
                          Payments
                        </div>
                        { this.state.showPayments &&
                          <PaymentsListController 
                            contract={contract} 
                            refresh={this.refresh}
                            user={ user }
                          />
                        }
                      </div>

                      <div id="contractBottomSection">
                        <div 
                          id="contractSubSection" 
                          onClick={()=>this.setState({showActivations:!this.state.showActivations})}
                          className={this.state.showActivations?"ActiveSubSection":""}
                        >
                          Activations
                        </div>
                        { this.state.showActivations &&
                          <ActivationsList 
                            activations={contract.activations}
                          />
                        }
                      </div>
                    </div> }

                  { options && options.internalMessaging !== false &&
                    <div id="contractBottomSection">
                      <div               
                        id="contractSubSection" 
                        onClick={()=>this.setState({showNotes:!this.state.showNotes})}
                        className={this.state.showNotes?"ActiveSubSection":""}
                      >
                        Notes, tickets &amp; tasks
                      </div>     
                      { this.state.showNotes &&
                        <NotesController 
                          contract={ contract } 
                          user={ user }
                          refresh={ this.refresh }
                        /> }     
                    </div>   }

                { options && options.texts !== false && 
                  <div id="contractBottomSection">
                    <div 
                      id="contractSubSection" 
                      onClick={()=>this.setState({showMessages:!this.state.showMessages})}
                      className={this.state.showMessages?"ActiveSubSection":""}
                    >
                      Texts
                    </div>

                    { this.state.showMessages && contract &&
                      <MessagesList 
                        messages={contract.messages}
                        contract={contract}
                        refresh={this.refresh}
                      />
                    }

                  </div> }

                { options && options.payments !== false &&
                  <div id="contractBottomSection">
                    <Payments contract={ contract } />
                  </div> }

                { options && options.payments !== false &&
                  <div id="contractBottomSection">
                    <Activity 
                      contract={ contract }
                      user={ user }
                    />  
                  </div> }


                  { contract && contract.pictureTag &&
                    <div id="contractBottomSection">
                      <div id="contractSubSection" onClick={() => this.setState({ showPicture: !this.state.showPicture })}>
                        Picture of contract
                      </div>
                      { this.state.showPicture && <Picture pictureTag={ contract.pictureTag }/> }
                    </div>
                  }

                  { contract && !contract.pictureTag &&
                    <div id="contractBottomSection">
                      <div id="contractSubSection" className="redPictureFlag">
                        Picture of contract unavailable
                      </div>
                    </div>
                  }

                  { contract.payrollNumber &&
                    <div id="contractBottomSection">
                      <div id="contractSubSection">
                        Payroll number: { contract.payrollNumber }
                      </div>
                    </div>
                  }

                </div>
              } 

            </div>
          </div>
        }
      </div>
    );
  }
}

export default Contract