import React, { Component } from 'react';
import './../invoice.css'
import history from '../../../../history';
import { InvoiceGraph } from './../InvoiceGraph';
import { BreakDownGraph } from './../BreakDownGraph'

export class InvoicesListItem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      show: false,
    }
  };   

  handleNext(path){
    history.push(path)
  }

  render() {
    let invoice = this.props.invoice
    let validPricing = invoice.validPricing
    let billing = validPricing.billing
    let data = invoice.data
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    let periodStart = '-'
    let periodEnd = '-'

    if( invoice.periodStart ){
      periodStart = new Date(invoice.periodStart)
      periodStart = periodStart.toLocaleDateString("en-EN", options)
    }
    if( invoice.periodEnd ){
      periodEnd = new Date(invoice.periodEnd)
      periodEnd = periodEnd.toLocaleDateString("en-EN", options)
    }
    let count = data.activeCount + data.inActiveCount + data.repossessedCount + data.paidOffCount + data.writeOffCount

    return (
      <div>
        <div className="invoiceItem" onClick={ ()=>this.setState({ show: !this.state.show }) }>
          <div className="invoiceName">{ periodStart }</div>
          <div className="invoiceName">{ periodEnd }</div>
          <div className="invoiceName">{ invoice.status }</div>
          <div className="invoiceAmount">{ invoice.ccy } { invoice.amount.toLocaleString() }</div>
        </div>
        { this.state.show
          ? <div className="invoiceDetails">
              <div id="invoiceDataSide">
                <div id="invoiceTitle">Supporting data</div>
                <div id="invoiceDataWrapper">
                  <div id="invoiceDataLabel">Units paid during period</div>
                  <div id="invoiceDataData">{ invoice.data.activeCount? invoice.data.activeCount.toLocaleString(): 0 }</div>
                </div>
                <div id="invoiceDataWrapper">
                  <div id="invoiceDataLabel">Units active but not paid</div>
                  <div id="invoiceDataData">{ invoice.data.inActiveCount? invoice.data.inActiveCount.toLocaleString(): 0 }</div>
                </div>
                <div id="invoiceDataWrapper">
                  <div id="invoiceDataLabel">Units repossessed during period</div>
                  <div id="invoiceDataData">{ invoice.data.repossessedCount? invoice.data.repossessedCount.toLocaleString() :0 }</div>
                </div>
                <div id="invoiceDataWrapper">
                  <div id="invoiceDataLabel">Units paid-off during period</div>
                  <div id="invoiceDataData">{ invoice.data.paidOffCount? invoice.data.paidOffCount.toLocaleString() : 0 }</div>
                </div>
                <div id="invoiceDataWrapper">
                  <div id="invoiceDataLabel">Units written-off during period</div>
                  <div id="invoiceDataData">{ invoice.data.writeOffCount ? invoice.data.writeOffCount.toLocaleString() : 0}</div>
                </div>
                
                <div id="invoiceDataWrapperMain">
                  <div id="invoiceDataLabelMain">Total active units during period</div>
                  <div id="invoiceDataDataMain">{ count.toLocaleString() }</div>
                </div>

                <div id="invoiceTitle">Applicable pricing</div>
                <div id="invoiceDataWrapper">
                  <div id="invoiceDataLabel">Base fee</div>
                  <div id="invoiceDataData">{ invoice.ccy } { billing && billing.monthlyBaseFee ? billing.monthlyBaseFee.toLocaleString() : 0 }</div>
                </div>
                <div id="invoiceDataWrapper">
                  <div id="invoiceDataLabel">Monthly cost per active unit</div>
                  <div id="invoiceDataData">{ invoice.ccy } { billing && billing.costPerActiveAsset ? billing.costPerActiveAsset.toLocaleString() : 0}</div>
                </div>
                <div id="invoiceDataWrapper">
                  <div id="invoiceDataLabel">Monthly cost per cash unit</div>
                  <div id="invoiceDataData">{ invoice.ccy } { billing && billing.costPerCashAsset ? billing.costPerCashAsset.toLocaleString() : 'n/a'}</div>
                </div>

                { validPricing.countRebate &&
                  <div id="invoiceDataWrapper">
                    <div id="invoiceDataLabel">Unit count rebate</div>
                    <div id="invoiceDataData">{ validPricing.countRebate ? validPricing.countRebate.toLocaleString() : '0'}</div>
                  </div> }

                { invoice.texts &&
                  <div>
                    <div id="invoiceTitle">Texts</div>
                    <div id="invoiceDataWrapper">
                      <div id="invoiceDataLabel">Texts sent</div>
                      <div id="invoiceDataData">{ invoice.texts.numberOfTexts ? invoice.texts.numberOfTexts.toLocaleString() : 0 }</div>
                    </div>
                    <div id="invoiceDataWrapper">
                      <div id="invoiceDataLabel">Cost per 1,000 texts</div>
                      <div id="invoiceDataData">{ invoice.ccy } { invoice.costPerThousandTexts }</div>
                    </div>
                  </div> }

              </div>

              <div id="invoiceGraphSide">
                <div id="invoiceGraphWrapper">
                  <div id="invoiceGraphTitle">Daily activity breakdown</div>
                  <BreakDownGraph label={ invoice.label } data={ invoice.breakdown }/>
                </div>

              </div>

            </div>
          : null }
      </div>
    )
  }  
}