import React from 'react';
import {Button, FormControl} from 'react-bootstrap/lib';
import './../../Utils/utils.css';
import axios from 'axios';
import { API_URL } from '../../../../Actions/index';

class AddAsset extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
      status:undefined,
      add:{
        newAsset: undefined
      },
      assets: undefined,
      disabled: false
    };
    this.loadData.bind(this)
  } 
  componentDidMount() {
    this.loadData()
  }

  add() {
    this.setState({disabled: true})
    axios.post(API_URL + '/assets/add/' + this.props.asset.supplier + '/' + this.props.asset._id, this.state.add)
			.then((ret) => {
        this.setState({
          status: ret.data
        })
      })
  }

  renderMessage() {
    if (this.state.status) {
      return (
        <div id={this.state.status?'successfulCreation':'unSuccessfulCreation'}>
          {this.state.status}
        </div>
      );
    }
  }  

  getSerialNumbers(product) {
    var body = {
      product: this.props.asset.product,
      supplier: this.props.asset.supplier
    }

    axios.post(API_URL + '/products/byProduct/'+ this.props.asset.supplier, body)
			.then((ret) => {
        this.setState({
          assets: ret.data
        })
      })
  }

  loadData() {
    this.getSerialNumbers();
  }  

  render() {
    
    let mappedSerialNumbers = null
    if(this.state.assets){
      mappedSerialNumbers = this.state.assets.map((asset, index) =>{ 
        return (
          <option key={index} value={asset._id}>{asset.serialNumber}</option>
        )
      })
    }

    let isDisabled = (this.state.add.newAsset)?false:true
    if(this.state.disabled){isDisabled=true}
    
    return (
      <div>
        <div id="assetActionTitle">Add an asset to this contract</div>
        <div id="assetActionSubTitle">This is a contract refinancing</div>
        <FormControl 
          controlId="formControlsSelect"
          componentClass="select" 
          className="contractInput"
          onChange={(e)=>{ 
            let add = Object.assign({}, this.state.add); 
            add.newAsset = e.target.value;
            this.setState({ add });
            }}
          >
          <option value="">Choose a new asset</option>
          {mappedSerialNumbers}
        </FormControl>
        {this.renderMessage()}
        <div id="modal_action">
          {this.state.status==='Success!'?
            null
            :
            <Button id="action_signup" disabled={isDisabled} onClick={this.add.bind(this)}>Confirm</Button>
          }
        </div>
      </div>
    );
  }
}

export default AddAsset;