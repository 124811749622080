import React, { Component } from 'react';
import QuestionsList from '../Questions/QuestionsList';
import Delete from '../Action/Delete'
import Copy from '../Action/Copy'
import './../questionnaires.css';
import { hasPermission } from './../../../../services/UserService'

class Questionnaire extends Component {
  constructor(props) {
		super(props)
		this.state = {
      showDetails: false,
      delete: false,
      copy: false
    };
  }

  close() {
    this.setState({ delete: false });
  }

  copy() {
    this.setState({ copy: false });
  }
  
  render() {   
    let { user, questionnaire, supplier, refresh } = this.props
    let permission = hasPermission( user, questionnaire )

    return (
      <div>
        <div id="questionnaireItem" className={ this.state.showDetails? 'activeQuestionnaire' : '' }>
          
          <div id="questionnaireEntity" >{ 
            questionnaire.entity
            ? questionnaire.entity.name? questionnaire.entity.name:'-' 
            : 'Global'
          }</div>

          <div id="questionnaireTitle" className="active" onClick={()=>this.setState({ showDetails:!this.state.showDetails })}>{questionnaire.name}</div>
          
          <div id="questionnaireDetails" className="active" onClick={()=>this.setState({ showDetails:!this.state.showDetails })}>
            { !this.state.showDetails ? <i className="fas fa-chevron-down"></i>:<i className="fas fa-chevron-up"></i> }
          </div>

          { permission &&
            <div id="questionnaireDetails"className="active" onClick={()=>this.setState({ delete: !this.state.delete })}>
              <i className="far fa-trash-alt"></i>
            </div> }
          
          { permission &&
            <div id="questionnaireDetails"className="active" onClick={()=>this.setState({ copy: !this.state.copy })}>
              <i className="far fa-copy"></i>
            </div> }
            
        </div>

        { this.state.showDetails && 
          <div id="questionnaireWrapper">
            <QuestionsList 
              questionnaire={ questionnaire } 
              refresh={ refresh }
              user={ user }
              supplier={ supplier }
            /> 
          </div>}

        { this.state.delete &&
          <Delete
            show={ this.state.delete } 
            onHide = {this.close.bind(this)}
            questionnaire = { questionnaire}
            refresh = { refresh}
          /> }

        { this.state.copy &&
          <Copy
            show={ this.state.copy } 
            onHide = {this.copy.bind(this)}
            questionnaire = {this.props.questionnaire}
            supplier={ this.props.supplier }
            refresh = {this.props.refresh}
          /> }

      </div>
    );
  }
}

export default Questionnaire